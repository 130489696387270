// eslint-disable-next-line import/named
import { AxiosHeaders, AxiosResponse } from 'axios';
import { ExternalAttendee } from '../model';
import { parseLinkHeader } from '@jooxter/utils';
import { axiosManager } from '../config';

export interface ISearchExternalAttendeesParameters {
  email?: string;
  name?: string;
  corporation?: string;
  globalSearch?: string;
  page?: string;
  size?: number;
}

export const ExternalAttendeesService = {
  path: 'external_attendees',

  postExternalAttendee(payload: ExternalAttendee): Promise<ExternalAttendee> {
    const url = `${this.path}`;

    return axiosManager
      .getInstance()
      .post(url, payload)
      .then((res) => res.data);
  },

  getExternalAttendeesWithPagination(options: ISearchExternalAttendeesParameters): Promise<{
    data: ExternalAttendee[];
    nextPage: string;
  }> {
    const params = this.createSearchExternalAttendeesParams(options);
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(this.onNextPage);
    }

    const url = `${this.path}`;

    return axiosManager.getInstance().get(url, { params }).then(this.onNextPage);
  },

  async onNextPage(res: AxiosResponse) {
    if (res.headers?.get && res.headers instanceof AxiosHeaders) {
      const linkHeader = res.headers.get('Link')?.toString() ?? null;
      const parsed = parseLinkHeader(linkHeader);
      const nextPage = parsed?.next.url ?? '';

      return {
        data: await res.data,
        nextPage,
      };
    }

    return {
      data: await res.data,
      nextPage: '',
    };
  },

  createSearchExternalAttendeesParams(options: ISearchExternalAttendeesParameters): URLSearchParams {
    const params = new URLSearchParams();

    if (options.email) {
      params.append('email', options.email);
    }

    if (options.name) {
      params.append('name', options.name);
    }

    if (options.corporation) {
      params.append('corporation', options.corporation);
    }

    if (options.globalSearch) {
      params.append('globalSearch', options.globalSearch);
    }

    if (options.page) {
      params.append('page', options.page);
    }

    if (options.size) {
      params.append('size', options.size.toString());
    }

    return params;
  },
};
