// eslint-disable-next-line import/named
import axios, { AxiosInstance } from 'axios';

export const getBaseUrl = (): string => import.meta.env.VITE_API_URL;
export const getBaseApiUrl = (v4 = false): string => `${import.meta.env.VITE_API_URL}/v${v4 ? '4' : '3'}`;
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// https://stackoverflow.com/a/70765722
export const axiosInstanceV3 = axios.create({
  baseURL: getBaseApiUrl(),
  headers,
});

export const axiosInstanceV4 = axios.create({
  baseURL: getBaseApiUrl(true),
  headers,
});

export class AxiosInstanceManager {
  public instance: AxiosInstance;
  private readonly instances = new Map<string, AxiosInstance>([
    ['axiosInstanceV3', axiosInstanceV3],
    ['axiosInstanceV4', axiosInstanceV4],
  ]);
  constructor(instance: 'axiosInstanceV3' | 'axiosInstanceV4') {
    this.instance = this.instances.get(instance) ?? axiosInstanceV3;
  }
  public setInstance(instance: 'axiosInstanceV3' | 'axiosInstanceV4'): void {
    this.instance = this.instances.get(instance) ?? axiosInstanceV3;
  }
  public getInstance(): AxiosInstance {
    return this.instance;
  }
  public getInstances(): Map<string, AxiosInstance> {
    return this.instances;
  }
}

export const axiosManager = new AxiosInstanceManager('axiosInstanceV3');
