import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { JxtButton, ButtonVariantEnum, ButtonSizeEnum } from '@jooxter/ui';
import { JxtLoginBase, LOGIN_ROUTE } from '@jooxter/core';
import { createGTMUpdateVirtualPathEvent } from '@jooxter/utils';
import { useNavigate } from 'react-router';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const button = (
    <JxtButton
      size={ButtonSizeEnum.Large}
      variant={ButtonVariantEnum.Primary}
      type={'button'}
      onClick={() => navigate(LOGIN_ROUTE)}
    >
      {t('forgot-passwd-btn-back')}
    </JxtButton>
  );

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('Forgot Password');
  }, []);

  return (
    <JxtLoginBase title={t('forgot-passwd-title')} instructions={t('forgot-passwd-instructions')}>
      {button}
    </JxtLoginBase>
  );
};
