import { JxtWorkplaceStatusButtonTypeEnum } from '@jooxter/utils';

export const JxtWorkplaceStatusButtonInfo = {
  [JxtWorkplaceStatusButtonTypeEnum.Office]: {
    iconName: 'fa-building',
    colors: 'text-green-150 bg-green-20',
    disabledColors: 'text-green-40 bg-green-10',
    translationKey: 'workplace.office',
  },
  [JxtWorkplaceStatusButtonTypeEnum.WorkFromHome]: {
    iconName: 'fa-home',
    colors: 'text-primary-150 bg-primary-20',
    disabledColors: 'text-primary-40 bg-primary-10',
    translationKey: 'workplace.teleworking',
  },
  [JxtWorkplaceStatusButtonTypeEnum.Mission]: {
    iconName: 'fa-car',
    colors: 'text-orange-150 bg-orange-20',
    disabledColors: 'text-orange-40 bg-orange-10',
    translationKey: 'workplace.mission',
  },
  [JxtWorkplaceStatusButtonTypeEnum.Off]: {
    iconName: 'fa-times-circle',
    colors: 'text-red-150 bg-red-20',
    disabledColors: 'text-red-40 bg-red-10',
    translationKey: 'workplace.off',
  },
  [JxtWorkplaceStatusButtonTypeEnum.Unknown]: {
    iconName: 'fa-question-circle',
    colors: 'text-neutral-150 bg-neutral-20',
    disabledColors: 'text-neutral-40 bg-neutral-10',
    translationKey: 'workplace.type.unknown',
  },
};
