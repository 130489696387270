import { Dialog } from '@headlessui/react';
import clsx from 'clsx';
import JxtCloseButton from '../JxtCloseButton';
import { IJxtModalHeader } from './types';
import { JxtIconButtonSizeEnum } from '../JxtIconButton/types';

export const JxtModalHeader = ({
  icon,
  title,
  className,
  showCloseIcon = true,
  onHide,
  iconColor = 'neutral-60',
}: IJxtModalHeader) => (
  <Dialog.Title
    className={clsx(
      className,
      'flex items-center text-center gap-2 justify-between font-medium border-b border-neutral-20 p-4 h-[72px]'
    )}
  >
    {showCloseIcon && <JxtCloseButton size={JxtIconButtonSizeEnum.L} onClick={onHide} />}
    {!showCloseIcon && <i className="fas fa-fw ml-2 invisible" />}
    {title}
    {icon && <i className={clsx('fas fa-fw fa-lg mr-2', `fa-${icon}`, `text-${iconColor}`)} />}
    {!icon && <i className="fas fa-fw mr-2 invisible" />}
  </Dialog.Title>
);
