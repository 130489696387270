import { DateTimeToLocalTime, getWeekdayName, isToday } from '@jooxter/utils';
import { IJxtDisplayOpeningHours } from './types';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash-es';

const JxtDisplayOpeningHours = ({ openingHours }: IJxtDisplayOpeningHours) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="grid items-center gap-x-3 gap-y-2 grid-rows-[auto_auto] grid-cols-[auto_1fr] mb-4">
      <i aria-hidden="true" className="fas fa-clock fa-fw text-neutral-60" />
      <h3 className="text-title-m text-neutral-140 font-medium">{t('resource.opening-hours')}</h3>
      <table className="col-start-2 col-end-2">
        <thead>
          <tr className="sr-only">
            <th scope="col">{t<string>('opening-hours-day-table-row-header')}</th>
            <th scope="col">{t<string>('opening-hours-table-row-header')}</th>
          </tr>
        </thead>
        <tbody>
          {openingHours?.map((day) => {
            return (
              <tr key={JSON.stringify(day)} className="text-left">
                <th scope="row" className="font-medium text-body-s text-neutral-140">
                  {capitalize(getWeekdayName(day.day || day.slots[0].start, i18n.language))}
                  {isToday(day.day || day.slots[0].start) && (
                    <span className="text-neutral-100 font-normal">
                      &nbsp;({t<string>('opening-hours-today-label')})
                    </span>
                  )}
                </th>

                <td className="text-neutral-100 text-body-s pb-2">
                  <ul>
                    <>
                      {day.slots.length === 0 && <li className="capitalize">{t<string>('opening-hours-closed')}</li>}
                      {day.slots.length === 1 && day.day && <li>{t<string>('opening-hours-opened-all-day')}</li>}

                      {day.slots.length >= 1 &&
                        !day.day &&
                        day.slots.map((range) => {
                          return (
                            <li key={JSON.stringify(range)}>
                              {DateTimeToLocalTime(range.start)} - {DateTimeToLocalTime(range.end)}
                            </li>
                          );
                        })}
                    </>
                  </ul>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default JxtDisplayOpeningHours;
