import { useMemo } from 'react';
import JxtCheckbox from '../JxtCheckbox';
import { IJxtCheckboxList, TJxtCheckboxListItem } from './types';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import { useTranslation } from 'react-i18next';

const JxtCheckboxList = ({
  options,
  selectedOptions,
  hasNextPage,
  label,
  onChange,
  onClickShowMore,
}: IJxtCheckboxList) => {
  const { t } = useTranslation();
  const sortedOptions = useMemo(
    () => options.sort((a, b) => parseFloat(a.label) - parseFloat(b.label) || a.label.localeCompare(b.label)),
    [options]
  );

  const onChangeSelectedValues = (option: TJxtCheckboxListItem) => {
    let newSelectedOptions = [...selectedOptions];
    if (newSelectedOptions.some((item) => item.value === option.value)) {
      newSelectedOptions = newSelectedOptions.filter((selectedOption) => selectedOption.value !== option.value);
    } else {
      newSelectedOptions.push(option);
    }
    if (onChange) {
      onChange(newSelectedOptions);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {label && <span className="text-body-s text-neutral-140 font-medium">{label}</span>}
      {sortedOptions.map((option) => {
        return (
          <JxtCheckbox
            key={`${option.value}-${option.label}`}
            value={selectedOptions.some((item) => item.value === option.value)}
            name={option.label}
            label={option.label}
            onChange={() => onChangeSelectedValues(option)}
          />
        );
      })}
      {hasNextPage && (
        <div>
          <JxtButton variant={ButtonVariantEnum.LinkDark} onClick={() => onClickShowMore && onClickShowMore()}>
            {t('show-more')}
          </JxtButton>
        </div>
      )}
    </div>
  );
};

export default JxtCheckboxList;
