// eslint-disable-next-line import/named
import { CalendarApi, DatesSetArg, EventApi, DateSpanApi } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import luxonPlugin from '@fullcalendar/luxon3';
import { IEventChange, IJxtResourceCalendar } from './types';
import timeGridPlugin from '@fullcalendar/timegrid';
import './styles.scss';
import { Popover } from '@headlessui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  createGTMUpdateVirtualPathEvent,
  dateToISO,
  getButtonText,
  ISOToLocalDate,
  ISOToLocalTime,
  now,
  prependCalendarIconToDatePickerBtn,
  toISO,
} from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useClickOutside } from '@mantine/hooks';
import { reverseLangMapping } from '@jooxter/i18n';
import { useTranslation } from 'react-i18next';
import {
  BookingCompressedListItemAdapter,
  useFetchClosingHours,
  useFetchResource,
  useFetchResourceTypes,
  useFetchUser,
  useResourceDurationSlotGenerator,
  useResourceSlotGenerator,
  useSearchBookings,
  useUpdateBooking,
} from '@jooxter/core';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import JxtDatePicker from '@jooxter/ui/src/components/JxtDatePicker';
import { IFullCalendarConfig, renderEventClassNames, useAllowSelect, useFullCalendar } from '@jooxter/fullcalendar';
import { useBookingCompressedLogisticTimesAdapter } from '../../adapters/bookings/useBookingCompressedLogisticTimesAdapter';
import { OpeningHoursToEventInputAdapter } from '../../adapters/resources/opening-hours-to-event-input.adapter';
import {
  BookingDisplayModeEnum,
  JxtSmartSlotSelectorContainer,
  JxtToast,
  JxtToastVariantEnum,
  JxtUpdateBookingModal,
} from '@jooxter/ui';
import toast from 'react-hot-toast';
import { useBookingCompressedToBookingRequestAdapter } from '../../adapters/bookings/useBookingCompressedToBookingRequestAdapter';
import { Booking, SlotsTypeEnum } from '@jooxter/api';

const JxtResourceCalendar = ({
  resourceId,
  resourceTypeId,
  initialDate,
  isBookable,
  timezone = 'system',
  locale,
  onSelect,
  onEventClick,
  bookSlot,
}: IJxtResourceCalendar) => {
  const [datePickerButtonText, setDatePickerButtonText] = useState<string>();
  const [date, setDate] = useState<DateTime>(initialDate);
  const fullCalendar = useRef<FullCalendar>(null);
  const [calendarApi, setCalendarApi] = useState<CalendarApi | undefined>();
  const { allowSelect } = useAllowSelect(calendarApi);
  const [datePickerValue, setDatePickerValue] = useState<Date | undefined>(initialDate.toJSDate());
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const toggleDatePicker = useCallback(() => setShowDatePicker(!showDatePicker), [showDatePicker]);
  const datePickerRef = useClickOutside(() => setShowDatePicker(false));
  const { resourceTypes } = useFetchResourceTypes();
  const resourceType = resourceTypes?.find((rt) => rt.id === resourceTypeId);
  const { t, i18n } = useTranslation();
  const searchBookingOptions = useMemo(
    () => ({
      from: date.startOf('day'),
      to: date.endOf('day'),
      resourceId: [resourceId],
    }),
    [resourceId, toISO(date)]
  );

  const { bookings, invalidateSearchBookings } = useSearchBookings(searchBookingOptions);
  const { adapt: adaptToLogisticTimes } = useBookingCompressedLogisticTimesAdapter(fullCalendar.current?.getApi().view);
  const logisticTimes = useMemo(() => bookings?.map((b) => adaptToLogisticTimes(b)) ?? [], [bookings]);
  const { openingHours } = useFetchClosingHours({
    resourceId,
    from: date.setZone(timezone).startOf('day'),
    to: date.setZone(timezone).endOf('day'),
  });
  const logisticTimesEvents = useMemo(
    () => OpeningHoursToEventInputAdapter.adapt(openingHours ?? [], timezone),
    [openingHours, timezone]
  );
  const events = useMemo(
    () => [
      ...(bookings?.map((b) => BookingCompressedListItemAdapter.adapt(b, { title: 'organizer', editable: true })) ??
        []),
      ...logisticTimes.flat(),
      ...logisticTimesEvents,
    ],
    [bookings, logisticTimes, logisticTimesEvents]
  );
  const [showUpdateBookingModal, setShowUpdateBookingModal] = useState<boolean>(false);
  const [eventToUpdate, setEventToUpdate] = useState<IEventChange | null>(null);
  const { mutate: mutationUpdateRecurrences } = useUpdateBooking(true);
  const { mutate: mutationUpdate } = useUpdateBooking();
  const { adapt: adaptBookingCompressedToBookingRequest } = useBookingCompressedToBookingRequestAdapter(bookings);
  const { resource } = useFetchResource(resourceId);
  const todaySlotsRange = useMemo(
    () => ({
      start: toISO(now(timezone)),
      end: toISO(now(timezone).endOf('day')),
    }),
    []
  );
  const resourceSlotGeneratorOptions = useMemo(
    () => ({
      ...todaySlotsRange,
      options: {
        resourceId,
        timezone,
        seatRequested: 1,
      },
    }),
    [resourceId, timezone, todaySlotsRange]
  );

  const { slots: classicSlots } = useResourceSlotGenerator(resourceSlotGeneratorOptions);
  const { durationSlots } = useResourceDurationSlotGenerator({
    date: todaySlotsRange.start,
    options: {
      resourceId: resourceId,
      slots: resource?.slots,
      timezone: timezone,
      seatRequested: 1,
    },
  });
  const slots = useMemo(
    () => (resource?.slots?.type === SlotsTypeEnum.Duration ? durationSlots : classicSlots),
    [resource, durationSlots, classicSlots]
  );
  const { user } = useFetchUser();
  const fullCalendarConfig: IFullCalendarConfig = {
    user: user,
    view: fullCalendar.current?.getApi().view.type,
    showTitle: true,
    showInfoIcons: true,
    showOptions: true,
    bookingMode: BookingDisplayModeEnum.NoPadding,
    callbacks: { onClick: onEventClick },
  };
  const { renderEventContent } = useFullCalendar(fullCalendarConfig);

  useEffect(() => {
    if (fullCalendar.current?.getApi()) {
      setCalendarApi(fullCalendar.current.getApi());
    }
  }, [resource, fullCalendar]);

  useEffect(() => {
    if (calendarApi) {
      const from = DateTime.fromJSDate(calendarApi?.getDate(), { zone: calendarApi.getOption('timeZone') }).startOf(
        'day'
      );
      const to = DateTime.fromJSDate(calendarApi?.getDate(), { zone: calendarApi.getOption('timeZone') })
        .plus({ days: 1 })
        .startOf('day');
      setDatePickerButtonText(getButtonText({ from, to }, calendarApi.view.type));
    }
  }, [calendarApi?.getDate().toISOString()]);

  useEffect(() => {
    if (datePickerButtonText) {
      prependCalendarIconToDatePickerBtn();
    }
  }, [datePickerButtonText]);

  const handleDateChange = (e: Date) => {
    setDatePickerValue(e);
    if (fullCalendar.current?.getApi().getOption('timeZone')) {
      // rebuilding date from JS Date because of a weird fullcalendar behavior
      const dt = DateTime.fromObject(
        {
          year: e.getFullYear(),
          month: e.getMonth() + 1,
          day: e.getDate(),
        },
        { zone: 'utc' }
      ).setZone(fullCalendar.current?.getApi().getOption('timeZone'));
      fullCalendar.current?.getApi().gotoDate(dt.toJSDate());
      toggleDatePicker();
    }
  };

  const onDatesSet = (args: DatesSetArg) => {
    setDate(DateTime.fromISO(args.startStr, { zone: timezone }));
  };

  const eventResizedOrDragged = (arg: any) => {
    setEventToUpdate(arg);
    setShowUpdateBookingModal(true);
    createGTMUpdateVirtualPathEvent('/BookingUpdateConfirmation');
  };

  const onConfirmUpdate = (event?: EventApi, recurrence?: boolean, revert?: () => void) => {
    if (!event) {
      return;
    }
    let bookingRequest = adaptBookingCompressedToBookingRequest(event.extendedProps.originalBooking);
    if (!bookingRequest) {
      return;
    }
    const newStart = event?.start
      ? dateToISO(event.start, event.extendedProps.originalBooking.resource.timezone)
      : undefined;
    const newEnd = event?.end ? dateToISO(event.end, event.extendedProps.originalBooking.resource.timezone) : undefined;
    if (!(newStart && newEnd)) {
      return;
    }
    bookingRequest = {
      ...bookingRequest,
      start: {
        dateTime: newStart,
      },
      end: {
        dateTime: newEnd,
      },
    };
    const onSuccess = (booking: Booking) => {
      toast.custom(
        (newToast) => (
          <JxtToast
            variant={JxtToastVariantEnum.Success}
            onHide={() => toast.remove(newToast.id)}
            title={recurrence ? t<string>('booking-and-following-occurrences-updated') : t<string>('update-saved')}
            subtitle={t<string>('booking-updated-message', {
              resourceName: booking.resource.name,
              bookingDate: ISOToLocalDate(booking.start.dateTime, booking.resource.timezone),
              startTime: ISOToLocalTime(booking.start.dateTime, booking.resource.timezone),
              endTime: ISOToLocalTime(booking.end.dateTime, booking.resource.timezone),
            })}
          />
        ),
        { position: 'bottom-left' }
      );
    };
    const onError = () => {
      revert && revert();
      toast.custom(
        (newToast) => (
          <JxtToast
            onHide={() => toast.remove(newToast.id)}
            variant={JxtToastVariantEnum.Failure}
            title={t<string>('booking-update-failed')}
          />
        ),
        { position: 'bottom-left' }
      );
    };
    const onSettled = () => {
      invalidateSearchBookings();
    };
    if (recurrence) {
      mutationUpdateRecurrences(
        { id: parseInt(event.id), payload: bookingRequest },
        {
          onSuccess,
          onError,
          onSettled,
        }
      );
    } else {
      mutationUpdate(
        { id: parseInt(event.id), payload: bookingRequest },
        {
          onSuccess,
          onError,
          onSettled,
        }
      );
    }
  };

  /**
   * This guard prevents events from overlapping.
   * The events with inverse-background are the open hours, so they should be allow
   * to overlap with our booking events
   */
  const eventOverlapGuard = (stillEvent: EventApi | null, movingEvent: EventApi | null): boolean => {
    return stillEvent?.display === 'inverse-background' && movingEvent?.display === 'auto';
  };

  /**
   * This guard prevents events from being resized in the past
   * The adapter sets at the event level whether it should be constraint or editable
   */
  const resizeGuard = (dropInfo: DateSpanApi): boolean => {
    return DateTime.fromJSDate(dropInfo.start) > now() && DateTime.fromJSDate(dropInfo.end) > now();
  };

  return (
    <div className="flex flex-col h-full justify-between overflow-hidden max-sm:h-[calc(88vh-181px)]">
      <div className="grow overflow-auto">
        <Popover>
          {showDatePicker && (
            <Popover.Panel ref={datePickerRef} static className="absolute top-14 right-0 z-10">
              <JxtDatePicker
                onChange={handleDateChange}
                defaultDate={datePickerValue}
                value={datePickerValue}
                withCellSpacing={false}
                locale={reverseLangMapping[i18n.language]}
                weekendDays={[]}
              />
            </Popover.Panel>
          )}
        </Popover>
        <FullCalendar
          ref={fullCalendar}
          timeZone={timezone}
          locale={locale}
          initialView="timeGridDay"
          plugins={[timeGridPlugin, interactionPlugin, luxonPlugin]}
          allDaySlot={false}
          dayHeaders={false}
          selectable={isBookable}
          editable={isBookable}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short',
          }}
          headerToolbar={{
            left: 'prev,next today',
            center: '',
            right: 'datePickerButton',
          }}
          slotDuration="01:00:00"
          customButtons={{
            datePickerButton: {
              text: datePickerButtonText,
              click: toggleDatePicker,
            },
          }}
          events={events}
          select={onSelect}
          selectAllow={(selectInfo) =>
            allowSelect(selectInfo, {
              fgEvent: !resourceType?.allowOverlap,
              inverseBgEventMissing: true,
            })
          }
          eventClassNames={renderEventClassNames}
          eventOrder="startdurationallDaytitle"
          slotMinWidth={20}
          eventMinHeight={5}
          aspectRatio={1.5}
          height="auto"
          initialDate={initialDate.toJSDate()}
          nowIndicator={true}
          stickyHeaderDates={true}
          firstDay={1}
          eventContent={renderEventContent}
          datesSet={onDatesSet}
          eventResize={eventResizedOrDragged}
          eventDrop={eventResizedOrDragged}
          eventOverlap={eventOverlapGuard}
          eventAllow={resizeGuard}
        />
      </div>
      {now(timezone).startOf('day').equals(date) && (
        <footer className="py-4 px-0 border-t border-neutral-20 flex gap-2 items-center">
          {slots.length > 0 && <div className="text-body-s font-medium">{t('booking-form.submit-create')}</div>}
          <JxtSmartSlotSelectorContainer
            range={{
              from: now(timezone).startOf('day'),
              to: now(timezone).endOf('day'),
            }}
            slots={slots}
            onSlotSelection={(slot) => {
              bookSlot && bookSlot(slot);
            }}
          />
        </footer>
      )}
      <JxtUpdateBookingModal
        oldEvent={{
          start: eventToUpdate?.oldEvent.start ? DateTime.fromJSDate(eventToUpdate?.oldEvent.start) : undefined,
          end: eventToUpdate?.oldEvent.end ? DateTime.fromJSDate(eventToUpdate?.oldEvent.end) : undefined,
        }}
        event={{
          start: eventToUpdate?.event.start ? DateTime.fromJSDate(eventToUpdate?.event.start) : undefined,
          end: eventToUpdate?.event.end ? DateTime.fromJSDate(eventToUpdate?.event.end) : undefined,
        }}
        timezone={eventToUpdate?.event.extendedProps.originalBooking.resource.timezone}
        recurrence={!!eventToUpdate?.event.extendedProps.originalBooking.rrule}
        onHide={(e) => {
          if (e) {
            onConfirmUpdate(eventToUpdate?.event, e?.recurrence, eventToUpdate?.revert);
          } else {
            eventToUpdate?.revert();
          }

          setShowUpdateBookingModal(false);
          setEventToUpdate(null);
        }}
        show={showUpdateBookingModal}
      />
    </div>
  );
};

export default JxtResourceCalendar;
