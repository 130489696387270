import { useResourceManagerOffCanvas } from './useResourceManagerOffCanvas';
import { useOffCanvas } from '../useOffCanvasContext';
import { offCanvasContainerEnum } from '@jooxter/ui';

export const useExceptionalOpeningHoursCallbacks = () => {
  const offCanvasContext = useOffCanvas();
  const { offCanvasConfiguration, goToExceptionalOpeningHoursView, setContainer } = useResourceManagerOffCanvas();

  const openResourceManagerCanvas = () => {
    offCanvasContext?.open(offCanvasConfiguration);
  };

  const onShowExceptionalOpeningHoursDetailsClick = (
    id: number,
    resourceId: number,
    container?: offCanvasContainerEnum
  ): void => {
    if (container) {
      setContainer(container);
    }
    goToExceptionalOpeningHoursView(id, resourceId);
    openResourceManagerCanvas();
  };

  return { onShowExceptionalOpeningHoursDetailsClick };
};
