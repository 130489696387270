import { IJxtMyBookingsCalendar } from './types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import luxonPlugin from '@fullcalendar/luxon3';
import { DatesSetArg, DayHeaderContentArg, EventClickArg, MoreLinkContentArg } from '@fullcalendar/core';
import {
  IFullCalendarConfig,
  renderEventClassNames,
  useFullCalendar,
  useFullCalendarLocale,
  FullCalendarViewEnum,
} from '@jooxter/fullcalendar';
import { useTranslation } from 'react-i18next';
import { BookingDisplayModeEnum, JxtMonthPicker, JxtWeekDatePicker, useIsSmallScreen } from '@jooxter/ui';
import {
  IRange,
  SCREEN_SIZE,
  createGTMGAEvent,
  fromISO,
  getButtonText,
  prependCalendarIconToDatePickerBtn,
} from '@jooxter/utils';
import { DateTime } from 'luxon';
import './styles.scss';
import { useFetchUser } from '../../queries';
import { useBookingCallbacks } from '../../hooks';
import { useClickOutside, useDebouncedValue, useViewportSize } from '@mantine/hooks';
import { Popover } from '@headlessui/react';
import { useStore } from '@jooxter/core';
import { useShallow } from 'zustand/shallow';

const JxtMyBookingsCalendar = ({
  timezone = 'system',
  events = [],
  initialDate,
  onFullCalendarRangeChange,
}: IJxtMyBookingsCalendar) => {
  const { i18n, t } = useTranslation();
  const { user } = useFetchUser();
  const { locale } = useFullCalendarLocale(i18n.language);
  const [range, setRange] = useState<IRange>();
  const [debouncedRange] = useDebouncedValue(range, 300);
  const isSmallScreen = useIsSmallScreen(SCREEN_SIZE.SM);
  const { height, width } = useViewportSize();
  const calendarRef = useRef<FullCalendar>(null);
  const {
    onClickCheckIn,
    onClickCheckOut,
    onClickUpdate,
    onClickDelete,
    onShowBookingDetailsClick,
    deleteBookingModal,
  } = useBookingCallbacks();
  const fullCalendarConfig: IFullCalendarConfig = (() => {
    let config: Partial<IFullCalendarConfig>;

    switch (calendarRef.current?.getApi().view.type) {
      case 'dayGridMonth':
        config = {
          showHours: true,
          bookingMode: BookingDisplayModeEnum.Compact,
        };
        break;
      case 'timeGridWeek':
        config = {
          showInfoIcons: true,
          showOptions: true,
          bookingMode: BookingDisplayModeEnum.Compact,
        };
        break;
      case 'listMonth':
        config = {
          showHours: true,
          showActions: true,
          bookingMode: BookingDisplayModeEnum.Regular,
        };
        break;
      default:
        config = {};
    }

    return {
      ...config,
      showTitle: true,
      showPopover: true,
      user: user,
      view: calendarRef.current?.getApi().view.type,
      callbacks: {
        onClickCheckIn,
        onClickCheckOut,
        onClickUpdate,
        onClickDelete,
      },
    };
  })();
  const [myBookingsView, setMyBookingsView] = useStore(
    useShallow((state) => [state.myBookingsView, state.setMyBookingsView])
  );
  const { renderEventContent } = useFullCalendar(fullCalendarConfig);
  const datePickerRef = useClickOutside(() => setShowDatePicker(false));
  const [datePickerValue, setDatePickerValue] = useState<Date | undefined>(
    initialDate ? new Date(initialDate) : undefined
  );
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [internalRange, setInternalRange] = useState<IRange | null>(null);
  const datepickerButton = document.querySelector('.fc-datePickerButton-button');

  const datepickerOverlayPosition: { top: number; left?: number; right?: number } | undefined = useMemo(() => {
    return datepickerButton
      ? {
          top: datepickerButton.getBoundingClientRect().height + 8,
          left: datepickerButton.getBoundingClientRect().left - 24 - datepickerButton.getBoundingClientRect().width / 2,
        }
      : undefined;
  }, [
    width,
    height,
    isSmallScreen,
    datepickerButton?.getBoundingClientRect().top,
    datepickerButton?.getBoundingClientRect().height,
    datepickerButton?.getBoundingClientRect().left,
    datepickerButton?.getBoundingClientRect().right,
  ]);

  const toggleDatePicker = useCallback(() => {
    setShowDatePicker(!showDatePicker);
  }, [showDatePicker]);
  const [datePickerButtonText, setDatePickerButtonText] = useState<string>();

  const initialView = isSmallScreen ? 'listMonth' : myBookingsView;

  const headerToolbar = {
    left: isSmallScreen ? 'prev,next' : 'prev,next today',
    center: 'datePickerButton',
    right: isSmallScreen ? 'today' : 'timeGridWeek,dayGridMonth',
  };

  const viewOptions = {
    listMonth: {
      listDaySideFormat: false as const,
      listDayFormat: {
        weekday: 'short' as const,
        day: '2-digit' as const,
        month: 'long' as const,
      },
      stickyHeaderDates: false,
      nowIndicator: true,
    },
    timeGridWeek: {
      slotLabelFormat: DateTime.TIME_SIMPLE,
      slotDuration: '01:00:00',
      dayHeaderFormat: {
        weekday: 'long' as const,
      },
      dayHeaderContent: (arg: DayHeaderContentArg) => `${arg.text} ${arg.date.getDate()}`,
      nowIndicator: true,
      allDaySlot: false,
    },
    dayGridMonth: {
      dayMaxEvents: 2,
      nowIndicator: true,
      dayHeaderFormat: {
        weekday: 'long' as const,
      },
    },
  };

  const plugins = [
    dayGridPlugin,
    interactionPlugin,
    listPlugin,
    luxonPlugin,
    resourceTimeGridPlugin,
    resourceTimelinePlugin,
    scrollGridPlugin,
  ];

  useEffect(() => {
    if (isSmallScreen) {
      calendarRef.current?.getApi().changeView('listMonth');
    } else {
      calendarRef.current?.getApi().changeView(myBookingsView);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (calendarRef.current && initialDate) {
      calendarRef.current.getApi().gotoDate(initialDate);
    }
  }, [initialDate]);

  useEffect(() => {
    if (internalRange && calendarRef.current?.getApi()) {
      setDatePickerButtonText(getButtonText(internalRange, calendarRef.current?.getApi().view.type));
      setDatePickerValue(internalRange.from.toJSDate());
    }
  }, [internalRange?.from.toISO(), internalRange?.to.toISO(), calendarRef.current?.getApi()]);

  useEffect(() => {
    if (onFullCalendarRangeChange && debouncedRange) {
      onFullCalendarRangeChange({
        from: debouncedRange.from,
        to: debouncedRange.to,
      });
    }
  }, [debouncedRange]);

  useEffect(() => {
    if (datePickerButtonText) {
      prependCalendarIconToDatePickerBtn();
    }
  }, [datePickerButtonText]);

  const handleDateChange = (e: Date) => {
    setDatePickerValue(e);
    if (calendarRef.current?.getApi().getOption('timeZone')) {
      // rebuilding date from JS Date because of a weird fullcalendar behavior
      const dt = DateTime.fromObject(
        {
          year: e.getFullYear(),
          month: e.getMonth() + 1,
          day: e.getDate(),
        },
        { zone: 'utc' }
      ).setZone(calendarRef.current?.getApi().getOption('timeZone'));
      calendarRef.current?.getApi().gotoDate(dt.toJSDate());
      toggleDatePicker();
    }
  };

  const datesSet = useCallback(
    (args: DatesSetArg) => {
      if (calendarRef.current) {
        setInternalRange({
          from: DateTime.fromJSDate(calendarRef.current?.getApi().view.currentStart),
          to: DateTime.fromJSDate(calendarRef.current?.getApi().view.currentEnd),
        });
        setRange({ from: fromISO(args.startStr, timezone), to: fromISO(args.endStr, timezone) });
      }
    },
    [timezone]
  );

  const moreLinkWrapper = (args: MoreLinkContentArg) => {
    const text = t('calendar.more', { num: args.num });
    return <span title={text}>{text}</span>;
  };

  const handleViewDidMount = ({ view }: any) => {
    if (['dayGridMonth', 'timeGridWeek'].includes(view.type)) {
      setMyBookingsView(view.type as FullCalendarViewEnum);
    }
  };

  const onEventClick = (event: EventClickArg) => {
    onShowBookingDetailsClick(parseInt(event.event.id, 10));
    createGTMGAEvent('My bookings', 'Booking', 'Show Booking');
  };

  return (
    <div id="fc-myBookings" className="relative h-[90%]">
      <Popover>
        {showDatePicker && datepickerOverlayPosition && (
          <Popover.Panel
            ref={datePickerRef}
            static
            className="absolute z-10"
            style={{
              top: datepickerOverlayPosition.top,
              left: datepickerOverlayPosition.left,
              right: datepickerOverlayPosition.right,
            }}
          >
            {calendarRef.current?.getApi().view.type === 'timeGridWeek' ? (
              <JxtWeekDatePicker initialDate={datePickerValue} onDateChange={(date: Date) => handleDateChange(date)} />
            ) : (
              <JxtMonthPicker initialDate={datePickerValue} onDateChange={(date: Date) => handleDateChange(date)} />
            )}
          </Popover.Panel>
        )}
      </Popover>
      <FullCalendar
        initialView={initialView}
        viewDidMount={handleViewDidMount}
        ref={calendarRef}
        plugins={plugins}
        events={events}
        stickyHeaderDates={true}
        displayEventTime={false}
        height="100%"
        datesSet={datesSet}
        eventClassNames={renderEventClassNames}
        eventContent={renderEventContent}
        eventClick={onEventClick}
        views={viewOptions}
        headerToolbar={headerToolbar}
        timeZone={timezone}
        locale={locale}
        nowIndicator={true}
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        moreLinkClassNames="text-body-s text-neutral-100 font-medium"
        slotLabelClassNames="text-body-s text-neutral-60"
        moreLinkContent={moreLinkWrapper}
        editable={false}
        firstDay={1}
        customButtons={{
          datePickerButton: {
            text: datePickerButtonText,
            click: toggleDatePicker,
          },
        }}
      />
      {deleteBookingModal}
    </div>
  );
};
export default JxtMyBookingsCalendar;
