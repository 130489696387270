import { JxtWorkplaceTypeWithoutUnknownEnum } from '@jooxter/utils';

export const JxtWorkplaceClasses = {
  [JxtWorkplaceTypeWithoutUnknownEnum.Office]: {
    iconName: 'fa-building',
    iconColor: 'text-green-100',
    disabledIconColor: 'text-green-40',
    translationKey: 'workplace.office',
  },
  [JxtWorkplaceTypeWithoutUnknownEnum.WorkFromHome]: {
    iconName: 'fa-house',
    iconColor: 'text-primary-100',
    disabledIconColor: 'text-green-40',
    translationKey: 'workplace.teleworking',
  },
  [JxtWorkplaceTypeWithoutUnknownEnum.Mission]: {
    iconName: 'fa-car',
    iconColor: 'text-orange-100',
    disabledIconColor: 'text-green-40',
    translationKey: 'workplace.mission',
  },
  [JxtWorkplaceTypeWithoutUnknownEnum.Off]: {
    iconName: 'fa-times-circle',
    iconColor: 'text-red-100',
    disabledIconColor: 'text-green-40',
    translationKey: 'workplace.off',
  },
};
