import { Resource } from '@jooxter/api';
import { IJxtResourceDetails } from '@jooxter/ui';
import { JxtResourcesTypeEnum, JxtLogisticTimeRuleEnum } from '@jooxter/utils';
import { useFetchResource, useFetchResourceGroups, useFetchResourceOptions } from '../../queries';

export const useFetchResourceDetails = (resourceId: number): IJxtResourceDetails | null => {
  const { resource, isLoading: isResourceLoading } = useFetchResource(resourceId);
  const { options, isLoading: isOptionsLoading } = useFetchResourceOptions(resourceId);
  const { groups, isLoading: isGroupsLoading } = useFetchResourceGroups(resourceId);

  if (!resource || !options || !groups) {
    return null;
  }

  return {
    resource: resource as unknown as Omit<Resource, 'resourceType' | 'logisticTimeRule'> & {
      resourceType: {
        metaType?: JxtResourcesTypeEnum;
        recurrency: boolean;
        maxDuration?: number;
        minDuration?: number;
      };
      logisticTimeRule: JxtLogisticTimeRuleEnum;
    },
    options,
    groups,
    isLoading: isResourceLoading || isOptionsLoading || isGroupsLoading,
  };
};
