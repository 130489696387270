import clsx from 'clsx';
import JxtAlert from '../JxtAlert';
import JxtBookingAttendeesList from '../JxtBookingAttendeesList';
import JxtEventDate from '../JxtEventDate';
import JxtBookingDescription from '../JxtBookingDescription';
import JxtBookingOptions from '../JxtBookingOptions';
import JxtBookingOrganizer from '../JxtBookingOrganizer';
import JxtBookingRecurrence from '../JxtBookingRecurrence';
import JxtEventSummary from '../JxtEventSummary';
import JxtButton from '../JxtButton';
import JxtResourceInfos from '../JxtResourceInfos';
import { IJxtBookingDetails } from './types';

const JxtBookingDetails = ({
  summary,
  date,
  organizer,
  resource,
  attendees,
  options,
  description,
  alerts,
  buttons,
  physicalAttendees,
  recurrence,
  plan,
  footer,
  className = '',
}: IJxtBookingDetails) => {
  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className={clsx('flex flex-col gap-4 overflow-y-auto h-full px-1', footer ? 'pb-4' : '', className)}>
        {alerts?.map((alert) => <JxtAlert key={JSON.stringify(alert)} {...alert} />)}
        {buttons?.map((button) => <JxtButton key={JSON.stringify(button)} {...button} />)}
        <JxtEventSummary {...summary} />
        <JxtEventDate {...date} />
        {recurrence && <JxtBookingRecurrence {...recurrence} />}
        <JxtBookingOrganizer {...organizer} />
        <JxtResourceInfos {...resource} />
        {plan}
        {!!(
          attendees?.internalAttendees?.length ||
          attendees?.externalAttendees?.length ||
          (physicalAttendees && physicalAttendees > 0)
        ) && <JxtBookingAttendeesList {...attendees} />}
        {!!options?.options.length && <JxtBookingOptions {...options} />}
        {description?.text && <JxtBookingDescription {...description} />}
      </div>
      {footer && <div className="border-t border-neutral-20 pt-4">{footer}</div>}
    </div>
  );
};

export default JxtBookingDetails;
