import {
  Booking,
  BookingCompressed,
  BookingVisibilityEnum,
  isBookingCompressed,
  User,
  UserTypeEnum,
} from '@jooxter/api';
import { DateTime } from 'luxon';

export const sortBookingsByDate = (bookings: BookingCompressed[]) => {
  return bookings.sort((a, b) => {
    if (DateTime.fromISO(a.start.dateTime) < DateTime.fromISO(b.start.dateTime)) {
      return -1;
    } else if (DateTime.fromISO(a.start.dateTime) > DateTime.fromISO(b.start.dateTime)) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const shouldShowSensibleInformation = (user?: User, booking?: Booking | null | BookingCompressed): boolean => {
  if (!(booking && user)) {
    return false;
  }

  if (booking.visibility === BookingVisibilityEnum.Public) {
    return true;
  }

  if (user?.type === UserTypeEnum.Admin) {
    return true;
  }

  if (isBookingCompressed(booking)) {
    return booking.organizer.id === user?.id;
  }

  return (
    booking.organizer.id === user?.id ||
    booking.created.by.id === user?.id ||
    booking.attendees?.internal.some((attendee) => attendee.id === user?.id) ||
    booking.attendees?.external.some((attendee) => attendee.id === user?.id)
  );
};
