import { Booking, ResourceStateDto } from '@jooxter/api';
import { TResourceConfiguration } from '../types/booking-form.types';

export const DEFAULT_MIN_PEOPLE_FOR_ZONE_OR_PHYSICAL_ATTENDEES_REQUIRED = 1;
export const DEFAULT_MIN_PEOPLE_OTHER = 0;
export const DEFAULT_MAX_PEOPLE = 99999;

export const useMinMaxAttendees = (
  config?: TResourceConfiguration,
  resourceState?: ResourceStateDto,
  bookingToEdit?: Booking | null
) => {
  return config?.isZone
    ? {
        minPhysicalAttendees: DEFAULT_MIN_PEOPLE_FOR_ZONE_OR_PHYSICAL_ATTENDEES_REQUIRED,
        maxPhysicalAttendees:
          (config?.capacity ?? DEFAULT_MIN_PEOPLE_FOR_ZONE_OR_PHYSICAL_ATTENDEES_REQUIRED) -
          (resourceState?.occupantsCount ?? 0) +
          (bookingToEdit?.physicalAttendees ?? 0),
      }
    : {
        minPhysicalAttendees: config?.physicalAttendeesRequired
          ? DEFAULT_MIN_PEOPLE_FOR_ZONE_OR_PHYSICAL_ATTENDEES_REQUIRED
          : DEFAULT_MIN_PEOPLE_OTHER,
        maxPhysicalAttendees: DEFAULT_MAX_PEOPLE,
      };
};
