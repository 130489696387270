import { JxtSelect } from '@jooxter/ui';
import { JxtWorkplaceTypeWithoutUnknownEnum } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import { WorkplaceOption, IJxtWorkplaceSelect } from './types';
//eslint-disable-next-line import/named
import { SingleValue } from 'react-select';
import { JxtWorkplaceClasses } from './constants';
import clsx from 'clsx';

const JxtWorkplaceSelect = ({
  onWorkplaceSelect,
  value,
  label,
  helperText,
  placeholder,
  isClearable,
  disabled,
}: IJxtWorkplaceSelect) => {
  const { t } = useTranslation();
  const options: WorkplaceOption[] = Object.values(JxtWorkplaceTypeWithoutUnknownEnum).map((workplace) => ({
    value: workplace,
    label: t(JxtWorkplaceClasses[workplace].translationKey),
  }));

  const handleWorkplaceSelect = (e: SingleValue<WorkplaceOption>) => {
    if (onWorkplaceSelect) {
      e === null ? onWorkplaceSelect(null) : onWorkplaceSelect(e.value);
    }
  };

  const formatOptionLabel = ({ value, label }: WorkplaceOption) => (
    <div className="flex items-center gap-2">
      <i
        className={clsx(
          'fas fa-fw',
          JxtWorkplaceClasses[value].iconName,
          disabled ? JxtWorkplaceClasses[value].disabledIconColor : JxtWorkplaceClasses[value].iconColor
        )}
      />
      {label}
    </div>
  );

  return (
    <JxtSelect
      value={value === null ? null : options.find((option) => option.value === value)}
      label={label}
      helperText={helperText}
      isSearchable={false}
      options={options}
      handleSelection={handleWorkplaceSelect}
      formatOptionLabel={formatOptionLabel}
      disabled={disabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

export default JxtWorkplaceSelect;
