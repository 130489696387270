import { useTranslation } from 'react-i18next';
import JxtOnboardingLayout from '../../../layouts/OnboardingLayout';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  JxtAvatar,
  JxtButton,
  JxtLoader,
  JxtSearchBar,
  JxtStar,
  JxtStarSizeEnum,
  JxtStarTypeEnum,
} from '@jooxter/ui';
import { useNavigate } from 'react-router';
import {
  JxtTeamsSelect,
  useFetchUser,
  useFetchUsersSearch,
  useMutateDeleteFavoriteUser,
  useMutatePutFavoriteUser,
  UserQueryKeys,
} from '@jooxter/core';
import { useMemo, useState } from 'react';
import { ISearchUsersParameters, UserGroupCompressed } from '@jooxter/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebouncedValue } from '@mantine/hooks';
import { debounce } from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import { useOnboardingNavigation } from '../hooks';
import { OnboardingPagesEnum } from '../types';
import { createGTMGAEvent, FAVORITE_DEBOUNCE_DELAY, generateToast } from '@jooxter/utils';

export const OnboardingColleagues = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getPrevLink, getNextLink } = useOnboardingNavigation(OnboardingPagesEnum.COLLEAGUES);
  const queryClient = useQueryClient();
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [searchName, setSearchName] = useState<string>('');
  const [shouldTriggerGTM, setShouldTriggerGTM] = useState(false);
  const [debounceSelectedGroups] = useDebouncedValue(selectedGroups, 300);
  const [debounceSearchName] = useDebouncedValue(searchName, 300);
  const debounceOptionsAdapted: ISearchUsersParameters = useMemo(
    () => ({
      globalSearch: debounceSearchName,
      groupIds: debounceSelectedGroups,
      size: 25,
    }),
    [debounceSelectedGroups, debounceSearchName]
  );
  const { users, fetchNextPage, hasNextPage } = useFetchUsersSearch(debounceOptionsAdapted);
  const { user } = useFetchUser();
  const putFavorite = useMutatePutFavoriteUser();
  const deleteFavorite = useMutateDeleteFavoriteUser();
  const filteredUsers = users?.filter((u) => u.id !== user?.id);

  const handleLocationSelection = (groups: UserGroupCompressed[]) => {
    setSelectedGroups(groups.map((g) => g.id as number));
  };

  const handleSearchName = (name: string) => {
    setSearchName(name);
  };

  const invalidateUsers = () => {
    queryClient.invalidateQueries({ queryKey: [UserQueryKeys.GetUsersSearch] });
  };

  const onFavoriteSuccess = () => {
    invalidateUsers();
  };

  const onFavoriteError = () => {
    generateToast(t('unknown-error'), true);
  };

  const handleToggleFavorite = (favorite: boolean, id: number) => {
    if (putFavorite.isPending || deleteFavorite.isPending) {
      return;
    }

    setShouldTriggerGTM(true);

    if (favorite) {
      putFavorite.mutate(id, {
        onSuccess: onFavoriteSuccess,
        onError: onFavoriteError,
      });
    } else {
      deleteFavorite.mutate(id, {
        onSuccess: onFavoriteSuccess,
        onError: onFavoriteError,
      });
    }
  };

  const handleClickRouteButton = (route: string) => {
    if (shouldTriggerGTM) {
      createGTMGAEvent('Onboarding', 'Favorite Colleagues', 'Select favorites colleagues');
    }
    navigate(route);
  };

  return (
    <JxtOnboardingLayout
      title={t<string>('onboarding.favorites.title')}
      instructions={t<string>('onboarding.favorites.explanation')}
    >
      <div className="flex grow shrink min-h-0 h-[100vh] flex-col gap-6">
        <JxtTeamsSelect
          placeholder={t<string>('select-user-group-placeholder')}
          noOptionsMessage={t<string>('no-matching-user-group')}
          isMulti={true}
          isClearable={true}
          onTeamSelect={handleLocationSelection}
        />
        <JxtSearchBar
          name="searchbar-onboarding-colleagues"
          onChange={handleSearchName}
          placeholder={t<string>('placeholder.search-username')}
        />
        {filteredUsers?.length && filteredUsers.length > 0 ? (
          <div id="scrollable-colleagues-list" className="grow shrink overflow-auto">
            <InfiniteScroll
              className="flex flex-col gap-2"
              dataLength={filteredUsers.length}
              next={() => fetchNextPage()}
              hasMore={hasNextPage ?? false}
              loader={
                <div className="flex flex-1 items-center justify-center">
                  <JxtLoader />
                </div>
              }
              scrollableTarget="scrollable-colleagues-list"
            >
              {filteredUsers.map((u) => (
                <div className="flex gap-2 px-2 items-center" key={u.id}>
                  <JxtAvatar
                    picture={u.picture}
                    text={`${u.firstname.charAt(0)}${u.lastname.charAt(0)}`}
                    alt={t('avatar-of', {
                      name: `${u.firstname} ${u.lastname}`,
                    })}
                    className="shrink-0"
                  />
                  <p className="grow truncate">
                    {u.firstname} {u.lastname}
                  </p>
                  <span className="shrink-0">
                    <JxtStar
                      checked={!!u.favorite}
                      type={JxtStarTypeEnum.Light}
                      size={JxtStarSizeEnum.Medium}
                      disabled={false}
                      onClick={debounce(
                        (favorite: boolean) => handleToggleFavorite(favorite, u.id),
                        FAVORITE_DEBOUNCE_DELAY
                      )}
                    />
                  </span>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className="flex flex-col justify-center grow items-center text-center gap-8">
            <div className="w-10 h-8 flex items-center">
              <i className="fas fa-fw fa-2xl fa-users text-neutral-60" />
            </div>
            <p className="text-title-m text-neutral-140 font-medium">
              {t('no-colleagues-were-found-matching-these-criterias')}
            </p>
          </div>
        )}
        <div className="flex justify-between">
          <JxtButton
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => handleClickRouteButton(getPrevLink())}
          >
            {t('previous')}
          </JxtButton>
          <JxtButton size={ButtonSizeEnum.Large} onClick={() => handleClickRouteButton(getNextLink())}>
            {t('next')}
          </JxtButton>
        </div>
      </div>
    </JxtOnboardingLayout>
  );
};
