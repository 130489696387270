import { Navigate, Outlet, useLocation, useNavigate } from 'react-router';
import { useStore } from '@jooxter/core';
import { CookieService } from '@jooxter/storage';
import { JSX, useEffect } from 'react';
import { REDIRECT_AFTER_LOGIN } from './constants';
import { useShallow } from 'zustand/shallow';

interface GuardedRouteProps {
  /**
   * Permission check for route
   * @default false
   */
  isRouteAccessible?: boolean;
  /**
   * Route to be redirected to
   * @default '/'
   */
  redirectRoute?: string;
}

/**
 * Component for guarding restricted routes
 *
 * @example Default usage
 * ```ts
 * <GuardedRoute
 *	 isRouteAccessible={true}
 * />
 * ```
 *
 * @example Usage with custom redirected route
 * ```ts
 * <GuardedRoute
 *	 isRouteAccessible={false}
 *	 redirectRoute={'/login'}
 * />
 * ```
 */
export const GuardedRoute = ({ isRouteAccessible = false, redirectRoute = '/' }: GuardedRouteProps): JSX.Element => {
  const location = useLocation();
  const token = useStore(useShallow((state) => state.token));
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !isRouteAccessible) {
      CookieService.setCookie(REDIRECT_AFTER_LOGIN, location.pathname);
    }
    if (token) {
      const redirectUrl = CookieService.getCookie(REDIRECT_AFTER_LOGIN);
      if (redirectUrl) {
        CookieService.deleteCookie(REDIRECT_AFTER_LOGIN);
        navigate(redirectUrl);
      }
    }
  }, []);

  return isRouteAccessible ? <Outlet /> : <Navigate to={redirectRoute} replace />;
};
