import { JxtMeetUpPeriodEnum, UserCompressed, WorkTogetherDto } from '@jooxter/api';
import { JxtUserSelect, useButtonStatus, useFetchUser, useGetSlots } from '@jooxter/core';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  JxtButton,
  JxtContainer,
  JxtSmartSlotSelector,
  JxtTag,
  JxtTagSizeEnum,
} from '@jooxter/ui';
import { createGTMGAEvent, createGTMUpdateVirtualPathEvent, generateToast, JxtTagColorsEnum } from '@jooxter/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeetUpSlot } from './MeetUpSlot';
import clsx from 'clsx';
import { MeetUpPlaceholder } from './MeetUpPlaceholder';

const MAX_USERS = 5;

export const MeetUp = () => {
  const { t } = useTranslation();
  const { user } = useFetchUser();
  const [users, setUsers] = useState<UserCompressed[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<JxtMeetUpPeriodEnum>(JxtMeetUpPeriodEnum.DAY);
  const [slots, setSlots] = useState<WorkTogetherDto[]>();
  const { children, setLoading, loading } = useButtonStatus();
  const disableUserSelect = users.length >= MAX_USERS;
  const disableSubmitButton = users.length === 0 || users.length > MAX_USERS;

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('Together');
  }, []);

  const onSuccessSubmit = (slots: WorkTogetherDto[]) => {
    setSlots(slots);
  };

  const onErrorSubmit = () => {
    generateToast(t('unknown-error'), true);
  };

  const onSettledSubmit = () => {
    setLoading(false);
  };

  const mutationGetSlots = useGetSlots(onSuccessSubmit, onErrorSubmit, onSettledSubmit);

  const handleSelectUser = (selectedUser: UserCompressed) => {
    if (!users.find((user) => user.id === selectedUser.id)) {
      createGTMGAEvent('Together', 'Filter', 'Choose Users');
      setUsers([...users, selectedUser]);
    }
  };

  const handleRemoveUser = (userId: number) => {
    createGTMGAEvent('Together', 'Filter', 'Remove Users');
    setUsers(users.filter((user) => user.id !== userId));
  };

  const handleSelectPeriod = (period: JxtMeetUpPeriodEnum) => {
    if (period !== selectedPeriod) {
      createGTMGAEvent('Together', 'Filter', 'Choose Slot');
      setSelectedPeriod(period);
    }
  };

  const searchSlots = () => {
    if (user?.id) {
      setLoading(true);
      mutationGetSlots.mutateAsync({
        userIds: [user.id, ...users.map((u) => u.id)],
        length: selectedPeriod,
      });
    }
  };

  const handleSearch = () => {
    createGTMGAEvent('Together', 'Search Button', 'Launch Search');
    searchSlots();
  };

  const handleRetrySearch = () => {
    createGTMGAEvent('Together', 'Try Again Button', 'Retry Search');
    searchSlots();
  };

  if (!user?.id) {
    return null;
  }

  return (
    <div className="flex max-lg:flex-col p-4 gap-6">
      <JxtContainer className="lg:min-w-[320px] lg:max-w-[320px]">
        <div className="flex flex-col grow gap-6">
          <h2 className="text-neutral-100 text-title-xl font-bold">{t('together.your-research')}</h2>
          <div className="flex flex-col gap-4">
            <JxtUserSelect
              label={t<string>('together.work-with')}
              placeholder={t<string>('together.work-with-placeholder')}
              helperText={t<string>('together.max-people', { maxPeople: MAX_USERS })}
              value={null}
              onUserSelect={handleSelectUser}
              excludedUsersId={[user.id]}
              disabled={disableUserSelect}
            />
            {users.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {users.map((u) => (
                  <JxtTag
                    key={u.id}
                    text={`${u.firstname} ${u.lastname}`}
                    color={JxtTagColorsEnum.NEUTRAL}
                    size={JxtTagSizeEnum.LARGE}
                    closeable
                    onClose={() => handleRemoveUser(u.id)}
                  />
                ))}
              </div>
            )}
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <label className="text-neutral-120 text-title-m font-medium">{t('together.duration')}</label>
                <small className="text-neutral-100 text-body-s">{t('together.how-much-time')}</small>
              </div>
              <div className="flex gap-2">
                <JxtSmartSlotSelector
                  onClick={() => handleSelectPeriod(JxtMeetUpPeriodEnum.DAY)}
                  selected={selectedPeriod === JxtMeetUpPeriodEnum.DAY}
                >
                  {t('quick-time-label-select-am-morning-pm-afternoon-day-all-day', { quickTimeLabel: 'DAY' })}
                </JxtSmartSlotSelector>
                <JxtSmartSlotSelector
                  onClick={() => handleSelectPeriod(JxtMeetUpPeriodEnum.HALF_DAY)}
                  selected={selectedPeriod === JxtMeetUpPeriodEnum.HALF_DAY}
                >
                  {t('quick-time-label-select-am-morning-pm-afternoon-day-all-day', { quickTimeLabel: 'HALF_DAY' })}
                </JxtSmartSlotSelector>
              </div>
            </div>
          </div>
          <JxtButton
            variant={loading ? ButtonVariantEnum.OutlinePrimary : ButtonVariantEnum.Primary}
            size={ButtonSizeEnum.Large}
            disabled={loading || disableSubmitButton}
            onClick={handleSearch}
          >
            {loading ? children : t('together.search')}
          </JxtButton>
        </div>
      </JxtContainer>
      {!slots && (
        <MeetUpPlaceholder
          icon="users"
          title={t('together.find-best-moment')}
          description={t('together.fill-colleagues')}
        />
      )}
      {slots && slots.length === 0 && (
        <MeetUpPlaceholder
          icon="times-circle"
          title={t('together.no-results.title')}
          description={t('together.no-results')}
        />
      )}
      {slots && slots.length > 0 && (
        <div className={clsx('grid grid-cols-1 grow gap-6', slots.length === 2 ? 'lg:grid-cols-2' : '')}>
          {slots.map((slot, i) => (
            <MeetUpSlot
              key={`${slot.interval?.start}${slot.interval?.end}${slot.bestPlace?.location?.id}${slot.bestPlace?.floor?.id}`}
              slot={slot}
              onRetry={handleRetrySearch}
              slotIndex={i}
            />
          ))}
        </div>
      )}
    </div>
  );
};
