export const openInNewTab = (url: string) => window.open(url, '_blank', 'noopener,noreferrer');

const ADDIN_HOST = 'addin';

export enum WindowNameEnum {
  TEAMS = 'TEAMS',
  ADDIN = 'ADDIN',
  WEBAPP = 'WEBAPP',
}
export const getWindowName = (): WindowNameEnum => {
  if (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  ) {
    return WindowNameEnum.TEAMS;
  }

  if (window.location.hostname.includes(ADDIN_HOST)) {
    return WindowNameEnum.ADDIN;
  }

  return WindowNameEnum.WEBAPP;
};
