import { AxiosInstanceEnum, axiosManager } from '@jooxter/api';
import { FullCalendarViewEnum } from '@jooxter/fullcalendar';
import { create, StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { FilterContext, FoldedFilterContext, JooxterState } from './types';
import { isoToDateTimeAdapter } from '../adapters';
import { fromISO, getMinBetweenNextHourAndEndOfDay, isDiffOneHour, now, toISO } from '@jooxter/utils';
import { FILTERS_STORAGE_TOKEN, STORAGE_VERSION } from './constants';
import { SwitchViewEnum } from '../components/JxtViewSwitch/types';
import { queryClient } from '../react-query.client';
import { CookieService, CookieOptions } from '@jooxter/storage';

const defaultResourceFiltersRange = () => ({
  from: toISO(now()),
  to: toISO(getMinBetweenNextHourAndEndOfDay(now())),
});

const defaultColleagueFiltersRange = () => ({
  from: toISO(now().startOf('week')),
  to: toISO(now().endOf('week').minus({ days: 2 })),
});

const defaultFilters: FilterContext = {
  from: isoToDateTimeAdapter.revert(now().startOf('week')),
  to: isoToDateTimeAdapter.revert(now().endOf('week')),
  resource: {
    date: defaultResourceFiltersRange(),
    now: true,
    labels: [],
    resourceName: '',
    capacity: 1,
    organizerId: null,
    resourceTypesIds: [],
    location: {
      floorIds: [],
      locationIds: [],
    },
    favoritesOnly: false,
  },
  colleague: {
    date: defaultColleagueFiltersRange(),
    locationIds: [],
    groupIds: [],
    globalSearch: '',
    favoritesOnly: false,
    type: null,
  },
};

const defaultState = (set: Parameters<StateCreator<JooxterState>>[0]): JooxterState => ({
  filter: defaultFilters,
  setFilter: (filter: FilterContext) => set(() => ({ filter })),
  token: undefined,
  setToken: (token: string) => {
    set(() => ({ token }));
  },
  deleteToken: () => {
    if (Array.isArray(window.jimo) && window.jimoKill) {
      // call kill on logout
      window.jimoKill();
    }
    localStorage.removeItem(FILTERS_STORAGE_TOKEN);
    CookieService.deleteCookie('email');
    queryClient.removeQueries();
    set(() => ({
      token: undefined,
      axiosInstance: AxiosInstanceEnum.axiosInstanceV3,
      email: '',
      filter: defaultFilters,
      spaceSearchView: SwitchViewEnum.Floorplan,
    }));
  },
  email: '',
  setEmail: (email?: string, options?: CookieOptions) =>
    set(() => {
      if (email) {
        // we save email in cookie to be able to retrieve it after a subdomain change (ie fr.app.jooxter.com to en.app.jooxter.com)
        CookieService.setCookie('email', email, options);
      } else {
        CookieService.deleteCookie('email', options);
      }
      return { email };
    }),
  foldedFilters: {
    spaces: {
      date: false,
      spaceType: true,
      labels: true,
    },
    colleagues: {
      teams: false,
    },
  },
  setFoldedFilters: (foldedFilters: FoldedFilterContext) => set(() => ({ foldedFilters })),
  spaceSearchView: SwitchViewEnum.Floorplan,
  setSpaceSearchView: (spaceSearchView: SwitchViewEnum) => set(() => ({ spaceSearchView })),
  spaceSchedulerView: FullCalendarViewEnum.ResourceTimelineDay,
  setSpaceSchedulerView: (spaceSchedulerView: FullCalendarViewEnum) => set(() => ({ spaceSchedulerView })),
  myBookingsView: FullCalendarViewEnum.DayGridMonth,
  setMyBookingsView: (myBookingsView: FullCalendarViewEnum) => set(() => ({ myBookingsView })),
  resetResourceFilter: (userId: number) =>
    set((state: JooxterState) => ({
      filter: {
        ...state.filter,
        resource: {
          ...defaultFilters.resource,
          date: defaultResourceFiltersRange(),
          now: true,
          organizerId: userId,
        },
      },
    })),
  resetResourceFilterDate: () =>
    set((state: JooxterState) => ({
      filter: {
        ...state.filter,
        resource: {
          ...state.filter.resource,
          date: defaultResourceFiltersRange(),
          now: true,
        },
      },
    })),
  resetColleagueFilter: () =>
    set((state: JooxterState) => ({
      filter: {
        ...state.filter,
        colleague: {
          ...defaultFilters.colleague,
          date: defaultColleagueFiltersRange(),
        },
      },
    })),
  highligthedFloorplanPicto: undefined,
  setHighligthedFloorplanPicto: (floorplanPictoId?: number) =>
    set(() => ({ highligthedFloorplanPicto: floorplanPictoId })),
  axiosInstanceBlocked: false,
  blockAxiosInstance: (block: boolean) => set(() => ({ axiosInstanceBlocked: block })),
  axiosInstance: AxiosInstanceEnum.axiosInstanceV3,
  setAxiosInstance: (axiosInstance: AxiosInstanceEnum) =>
    set((state: JooxterState) => {
      if (!state.axiosInstanceBlocked) {
        axiosManager.setInstance(axiosInstance);

        return { axiosInstance };
      }

      return { axiosInstance: state.axiosInstance };
    }),
});

export const useStore = import.meta.env.DEV
  ? create<JooxterState>()(
      devtools(
        persist((set) => defaultState(set), {
          name: FILTERS_STORAGE_TOKEN,
          partialize: (state) => ({
            filter: {
              resource: state.filter.resource,
              colleague: Object.fromEntries(
                Object.entries(state.filter.colleague).filter(([key]) => !['date'].includes(key))
              ),
            },
            spaceSearchView: state.spaceSearchView,
            spaceSchedulerView: state.spaceSchedulerView,
            myBookingsView: state.myBookingsView,
            foldedFilters: state.foldedFilters,
            email: state.email,
            axiosInstance: state.axiosInstance,
          }),
          version: STORAGE_VERSION,
          onRehydrateStorage: () => {
            return (_state, error) => {
              if (error) {
                return;
              }

              const nowSnapshot = now();

              if (_state?.filter?.from) {
                const fromDateTime = fromISO(_state.filter.from);
                const toDateTime = fromISO(_state.filter.to);
                const isPast = fromDateTime < nowSnapshot;

                if (isPast) {
                  _state.setFilter({
                    ..._state.filter,
                    from: toISO(nowSnapshot),
                    to: isDiffOneHour(fromDateTime, toDateTime)
                      ? toISO(getMinBetweenNextHourAndEndOfDay(fromDateTime))
                      : _state.filter.to,
                  });
                }
              }

              if (_state?.filter?.resource.date.from) {
                const fromResourceFilterDateTime = fromISO(_state.filter.resource.date.from);
                const toResourceFilterDateTime = fromISO(_state.filter.resource.date.to);
                const isPastResourceFilter = fromResourceFilterDateTime < nowSnapshot;

                if (isPastResourceFilter) {
                  _state.setFilter({
                    ..._state.filter,
                    resource: {
                      ..._state.filter.resource,
                      date: {
                        ..._state.filter.resource.date,
                        from: toISO(nowSnapshot),
                        to: isDiffOneHour(fromResourceFilterDateTime, toResourceFilterDateTime)
                          ? toISO(getMinBetweenNextHourAndEndOfDay(nowSnapshot))
                          : _state.filter.resource.date.to,
                      },
                    },
                  });
                }
              }

              if (_state?.axiosInstance) {
                axiosManager.setInstance(_state.axiosInstance);
              }
            };
          },
        })
      )
    )
  : create<JooxterState>()(
      persist((set) => defaultState(set), {
        name: FILTERS_STORAGE_TOKEN,
        partialize: (state) => ({
          filter: {
            resource: state.filter.resource,
            colleague: Object.fromEntries(
              Object.entries(state.filter.colleague).filter(([key]) => !['date'].includes(key))
            ),
          },
          spaceSearchView: state.spaceSearchView,
          spaceSchedulerView: state.spaceSchedulerView,
          myBookingsView: state.myBookingsView,
          foldedFilters: state.foldedFilters,
          email: state.email,
          axiosInstance: state.axiosInstance,
        }),
        version: STORAGE_VERSION,
        onRehydrateStorage: () => {
          return (_state, error) => {
            if (error) {
              return;
            }

            const nowSnapshot = now();

            if (_state?.filter?.from) {
              const fromDateTime = fromISO(_state.filter.from);
              const toDateTime = fromISO(_state.filter.to);
              const isPast = fromDateTime < nowSnapshot;

              if (isPast) {
                _state.setFilter({
                  ..._state.filter,
                  from: toISO(nowSnapshot),
                  to: isDiffOneHour(fromDateTime, toDateTime)
                    ? toISO(getMinBetweenNextHourAndEndOfDay(fromDateTime))
                    : _state.filter.to,
                });
              }
            }

            if (_state?.filter?.resource.date.from) {
              const fromResourceFilterDateTime = fromISO(_state.filter.resource.date.from);
              const toResourceFilterDateTime = fromISO(_state.filter.resource.date.to);
              const isPastResourceFilter = fromResourceFilterDateTime < nowSnapshot;

              if (isPastResourceFilter) {
                _state.setFilter({
                  ..._state.filter,
                  resource: {
                    ..._state.filter.resource,
                    date: {
                      ..._state.filter.resource.date,
                      from: toISO(nowSnapshot),
                      to: isDiffOneHour(fromResourceFilterDateTime, toResourceFilterDateTime)
                        ? toISO(getMinBetweenNextHourAndEndOfDay(nowSnapshot))
                        : _state.filter.resource.date.to,
                    },
                  },
                });
              }

              if (_state?.axiosInstance) {
                axiosManager.setInstance(_state.axiosInstance);
              }
            }
          };
        },
      })
    );

export * from './types';
export * from './constants';
