import { Dialog, Transition } from '@headlessui/react';
import { SCREEN_SIZE } from '@jooxter/utils';
import clsx from 'clsx';
import { forwardRef, Fragment, Ref, useMemo } from 'react';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import JxtModalFooter from '../JxtModalFooter';
import { JxtModalHeader } from '../JxtModalHeader';
import { JxtModalConfig } from './config';
import { useDialogPanelClasses } from './style';
import { IJxtModal, IModalAnimation, JxtModalSizeEnum } from './types';

export const leaveDesktopAnimationDuration = 200;
export const leaveMobileAnimationDuration = 200;

const desktopAnimation = {
  enter: 'ease-out duration-200',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: `ease-in duration-${leaveDesktopAnimationDuration}`,
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

const mobileAnimation = {
  enter: 'transition ease-in-out duration-200 transform',
  enterFrom: 'translate-y-full',
  enterTo: 'translate-y-0',
  leave: `transition ease-in-out duration-${leaveMobileAnimationDuration} transform`,
  leaveFrom: 'translate-y-0',
  leaveTo: 'translate-y-full',
};

const JxtModal = (
  { header, children, footer, onHide, show = false, size = JxtModalSizeEnum.S }: IJxtModal,
  ref: Ref<HTMLDivElement> | ((node: HTMLDivElement | null) => void) | undefined
) => {
  const isSmallScreen = useIsSmallScreen(SCREEN_SIZE.SM);
  const animation = useMemo<IModalAnimation>(
    () => (isSmallScreen ? mobileAnimation : desktopAnimation),
    [isSmallScreen]
  );
  const { classes: dialogPanelClasses } = useDialogPanelClasses(JxtModalConfig.size[size]);

  return (
    <Transition.Root show={show} as={Fragment}>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <Dialog as="div" className="relative z-10" onClose={header.showCloseIcon ? onHide : () => {}}>
        <Transition.Child as={Fragment} {...animation}>
          <div className="fixed inset-0 bg-neutral-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-0 text-center sm:items-center">
            <Transition.Child as={Fragment} {...animation}>
              <Dialog.Panel className={clsx(dialogPanelClasses)}>
                <JxtModalHeader {...header} onHide={onHide} />
                <div
                  className="p-4 grow overflow-y-auto flex flex-col sm:max-h-[calc(100vh_-_(16_*_0.25rem)_-_144px)]"
                  ref={ref}
                >
                  {children}
                </div>
                {footer && <JxtModalFooter>{footer}</JxtModalFooter>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default forwardRef(JxtModal);
