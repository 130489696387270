import { useTranslation } from 'react-i18next';
import JxtOnboardingLayout from '../../../layouts/OnboardingLayout';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton } from '@jooxter/ui';
import { useNavigate } from 'react-router';
import { JxtProfilePictureUploader, useFetchUser } from '@jooxter/core';
import { useOnboardingNavigation } from '../hooks';
import { OnboardingPagesEnum } from '../types';
import { createGTMGAEvent } from '@jooxter/utils';

export const OnboardingProfilePicture = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useFetchUser();
  const { getPrevLink, getNextLink } = useOnboardingNavigation(OnboardingPagesEnum.PROFILE_PICTURE);

  if (!user) {
    return null;
  }

  return (
    <JxtOnboardingLayout
      title={t<string>('onboarding.profile-picture.title')}
      instructions={t<string>('onboarding.profile-picture.explanation')}
    >
      <div className="flex flex-col gap-6">
        <JxtProfilePictureUploader
          user={user}
          onSave={() => createGTMGAEvent('Onboarding', 'Profile Picture', 'Save photo')}
        />
        <div className="flex justify-between">
          <JxtButton
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => navigate(getPrevLink())}
          >
            {t('previous')}
          </JxtButton>
          <JxtButton size={ButtonSizeEnum.Large} onClick={() => navigate(getNextLink())}>
            {t('next')}
          </JxtButton>
        </div>
      </div>
    </JxtOnboardingLayout>
  );
};
