import { ReactNode } from 'react';
import { IJxtModalHeader } from '../JxtModalHeader/types';

export interface IJxtModal {
  header: Omit<IJxtModalHeader, 'onHide'>;
  children: ReactNode;
  footer?: string | ReactNode;
  show: boolean;
  onHide: () => void;
  size?: JxtModalSizeEnum;
}

export interface IModalAnimation {
  enter: string;
  enterFrom: string;
  enterTo: string;
  leave: string;
  leaveFrom: string;
  leaveTo: string;
}

export enum JxtModalSizeEnum {
  S = 'Small',
  M = 'Medium',
  L = 'Large',
}

export interface IJxtModalConfig {
  size: {
    [JxtModalSizeEnum.S]: string;
    [JxtModalSizeEnum.M]: string;
    [JxtModalSizeEnum.L]: string;
  };
}
