import { FloorCompressed, ILocationOptions } from '@jooxter/api';
import { JxtInfoWindow, JxtLoader } from '@jooxter/ui';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { SwitchViewEnum } from '..';
import { FiltersToLocationMapAdapter } from '../../adapters/locations/filters-to-location-map.adapter';
import { useTimezoneNameFormatter } from '../../hooks';
import { useFetchUser, useFetchUserPreferences } from '../../queries';
import { useFetchLocations, useSearchLocationMap } from '../../queries/queryFns/locations';
import { useStore } from '../../store';
import { createMarker, setupBounds } from './helpers';
import { createGTMGAEvent } from '@jooxter/utils';
import { useShallow } from 'zustand/shallow';
import './style.scss';

export const JxtGoogleMap = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  });
  const { user } = useFetchUser();
  const { preferences } = useFetchUserPreferences(user?.id);
  const [map, setMap] = useState<google.maps.Map>();
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
  const [filter, setFilter, setSpaceSearchView] = useStore(useShallow((state) => [
    state.filter,
    state.setFilter,
    state.setSpaceSearchView,
  ]));
  const options: ILocationOptions = useMemo(() => FiltersToLocationMapAdapter.adapt(filter.resource), [filter]);
  const { locations: locationDtos } = useSearchLocationMap(options);
  const { locations } = useFetchLocations(locationDtos?.map((l) => l.id).filter((id): id is number => !!id) ?? []);
  const { format } = useTimezoneNameFormatter();

  const defaultCenter = useMemo(() => {
    if (!user?.location) {
      return;
    }

    const { latitude, longitude } = user.location;

    if (latitude && longitude) {
      return {
        lat: latitude,
        lng: longitude,
      };
    }
  }, [user]);
  const markers: google.maps.Marker[] = useMemo(
    () =>
      isLoaded
        ? (locationDtos
            ?.map((location) =>
              createMarker(
                location,
                format,
                locations?.find((l) => l.id === location.id)
              )
            )
            .filter((m): m is google.maps.Marker => Boolean(m)) ?? [])
        : [],
    [locationDtos, isLoaded, locations]
  );
  const [showInfoWindows, setShowInfoWindows] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (map && markers?.length) {
      setupBounds(map, markers);
    }
  }, [map, markers]);

  const onAccessBuilding = (id: number, goToPlan: boolean) => {
    const location = locations?.find((l) => l.id === id);

    if (!(filter.resource && location)) {
      return;
    }

    const firstFloor = location.floors
      ?.filter((floor) => floor.number >= 0)
      .sort((a: FloorCompressed, b: FloorCompressed) => a.number - b.number)
      .shift();

    setSpaceSearchView(goToPlan ? SwitchViewEnum.Floorplan : SwitchViewEnum.List);
    const floorId = id === preferences?.resource?.locationId ? preferences?.resource?.floorId : firstFloor?.id;

    setFilter({
      ...filter,
      resource: { ...filter.resource, location: { locationIds: [id], floorIds: floorId ? [floorId] : [] } },
    });
  };

  return !isLoaded ? (
    <div className="flex bg-white z-[999] items-center justify-center">
      <JxtLoader />
    </div>
  ) : (
    <GoogleMap
      extraMapTypes={[]}
      options={{
        gestureHandling: 'cooperative',
        minZoom: 2,
        // change the level of details in the map
        // https://developers.google.com/maps/documentation/javascript/reference/map#MapTypeId
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
        mapTypeControlOptions: {
          mapTypeIds: [window.google.maps.MapTypeId.ROADMAP],
        },
        disableDefaultUI: true, // a way to quickly hide all controls
        mapTypeControl: false,
        streetViewControl: true,
        fullscreenControl: true,
        scaleControl: true,
        zoomControl: true,
      }}
      mapContainerClassName="map-container"
      center={defaultCenter}
      onLoad={onLoad}
      zoom={6}
      onClick={() => setShowInfoWindows({})}
    >
      {markers.map((m) => (
        <Marker
          key={m.get('locationId')}
          position={m.getPosition() ?? { lat: 0, lng: 0 }}
          options={{ icon: m.getIcon() }}
          onClick={() => {
            setShowInfoWindows({ ...showInfoWindows, [m.get('locationId')]: true });
            createGTMGAEvent('Spaces', 'Google Map Marker', 'Click on a google-map marker');
          }}
        >
          {Boolean(showInfoWindows[m.get('locationId')]) && (
            <InfoWindow
              onCloseClick={() => setShowInfoWindows({ ...showInfoWindows, [m.get('locationId')]: false })}
              position={m.getPosition() ?? { lat: 0, lng: 0 }}
            >
              <JxtInfoWindow
                id={m.get('locationId') as number}
                name={m.get('locationName') as string}
                address={m.get('locationAddress') as string}
                timezone={m.get('locationTimezone') as string}
                url={m.get('locationUrl') as string}
                onAccessBuilding={(id: number) => onAccessBuilding(id, m.get('locationHasFloors'))}
              />
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
};
