/** @type {import('tailwindcss').Config} */
import defaultTheme from 'tailwindcss/defaultTheme';
import type { Config } from 'tailwindcss';
import forms from '@tailwindcss/forms';
import headlessui from '@headlessui/tailwindcss';

export default {
  content: [
    'src/**/*.{js,ts,tsx,jsx}',
    '../../packages/ui/src/**/*.{js,ts,jsx,tsx,mdx}',
    '../../packages/core/src/**/*.{js,ts,jsx,tsx,mdx}',
    '../../packages/utils/src/**/*.{js,ts,jsx,tsx,mdx}',
    '../../packages/fullcalendar/src/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    boxShadow: {
      'elevation-3': '0 2px 16px rgba(0, 0, 0, 0.17)',
      'elevation-2': '0 4px 16px rgba(0, 0, 0, 0.05)',
      'elevation-1': '0 8px 24px rgba(0, 0, 0, 0.05)',
      none: 'none',
    },
    colors: {
      neutral: {
        10: '#F0F1F3',
        20: '#E0E3E8',
        30: '#D1D5DC',
        40: '#C1C7D1',
        50: '#B2B9C5',
        60: '#A3ABB9',
        70: '#939DAE',
        80: '#848FA2',
        90: '#748196',
        100: '#677489',
        110: '#566172',
        120: '#454D5B',
        130: '#343A44',
        140: '#22272E',
        150: '#111317',
      },
      green: {
        10: '#E9F9EE',
        20: '#D2F2DD',
        30: '#BCECCC',
        40: '#A6E5BB',
        50: '#90DFAA',
        60: '#79D999',
        70: '#63D288',
        80: '#4DCC77',
        90: '#36C566',
        100: '#20BF55',
        110: '#1DAC4D',
        120: '#1A9944',
        130: '#16863B',
        140: '#137333',
        150: '#10602B',
      },
      orange: {
        10: '#FFF8EB',
        20: '#FFF0D8',
        30: '#FFE9C4',
        40: '#FFE1B1',
        50: '#FFDA9D',
        60: '#FFD289',
        70: '#FFCB76',
        80: '#FFC362',
        90: '#FFBC4F',
        100: '#FFB43B',
        110: '#E6A235',
        120: '#CC902F',
        130: '#B37E29',
        140: '#996C23',
        150: '#805A1E',
      },
      red: {
        10: '#FFEBEB',
        20: '#FFD8D8',
        30: '#FFC4C4',
        40: '#FFB1B1',
        50: '#FF9D9D',
        60: '#FF8989',
        70: '#FF7676',
        80: '#FF6262',
        90: '#FF4F4F',
        100: '#FF3B3B',
        110: '#E63535',
        120: '#CC2F2F',
        130: '#B32929',
        140: '#992323',
        150: '#801E1E',
      },
      blue: {
        10: '#EBFBFF',
        20: '#D7F6FF',
        30: '#C4F2FE',
        40: '#B0EDFE',
        50: '#9CE9FE',
        60: '#88E5FE',
        70: '#74E0FE',
        80: '#61DCFD',
        90: '#4DD7FD',
        100: '#39D3FD',
        110: '#33BEE4',
        120: '#2EA9CA',
        130: '#2894B1',
        140: '#227F98',
        150: '#1D6A7F',
      },
      purple: {
        10: '#F2EBFF',
        20: '#E5D8FF',
        30: '#D9C4FF',
        40: '#CCB1FF',
        50: '#BF9DFF',
        60: '#B289FF',
        70: '#A576FF',
        80: '#9962FF',
        90: '#8C4FFF',
        100: '#7F3BFF',
        110: '#7235E6',
        120: '#662FCC',
        130: '#5929B3',
        140: '#4C2399',
        150: '#401E80',
      },
      pink: {
        10: '#FEEDFD',
        20: '#FDDCFB',
        30: '#FBCAF9',
        40: '#FAB9F7',
        50: '#F9A7F5',
        60: '#F895F3',
        70: '#F784F1',
        80: '#F572EF',
        90: '#F461ED',
        100: '#F34FEB',
        110: '#DB47D4',
        120: '#C23FBC',
        130: '#AA37A5',
        140: '#922F8D',
        150: '#7A2876',
      },
      white: '#FFFFFF',
      background: '#F6F7FB',
      transparent: 'transparent',
    },
    extend: {
      fontSize: {
        'headline-xxxl': [
          '2.5rem',
          {
            lineHeight: '3.5rem',
          },
        ],
        'headline-xxl': [
          '2rem',
          {
            lineHeight: '2.5rem',
          },
        ],
        'title-xl': [
          '1.5rem',
          {
            lineHeight: '2rem',
          },
        ],
        'title-l': [
          '1.25rem',
          {
            lineHeight: '1.5rem',
          },
        ],
        'title-m': [
          '1rem',
          {
            lineHeight: '1.5rem',
          },
        ],
        'title-s': [
          '0.875rem',
          {
            lineHeight: '1rem',
          },
        ],
        'body-m': [
          '1rem',
          {
            lineHeight: '1.5rem',
          },
        ],
        'body-s': [
          '0.875rem',
          {
            lineHeight: '1.25rem',
          },
        ],
        'body-xs': [
          '0.75rem',
          {
            lineHeight: '1rem',
          },
        ],
        'body-xxs': [
          '0.5rem',
          {
            lineHeight: '1rem',
          },
        ],
      },
      fontFamily: {
        sans: ['Noto Color Emoji Flags', 'Inter', ...defaultTheme.fontFamily.sans],
      },
      lineHeight: {
        11: '3.5rem',
      },
      colors: {
        primary: {
          10: '#EBF3FF',
          20: '#D8E7FF',
          30: '#C4DBFF',
          40: '#B1CFFF',
          50: '#9DC3FF',
          60: '#89B6FF',
          70: '#76AAFF',
          80: '#629EFF',
          90: '#4F92FF',
          100: '#3B86FF',
          110: '#3579E6',
          120: '#2F6BCC',
          130: '#295EB3',
          140: '#235099',
          150: '#1E4380',
        },
      },
      maxWidth: {
        'modal-sm': '540px',
        'modal-md': '800px',
        'modal-lg': '1140px',
      },
      borderSpacing: {
        initial: 'initial',
      },
      screens: {
        '3xl': '1792px',
      },
    },
  },
  plugins: [
    // https://stackoverflow.com/a/75541876
    // https://github.com/tailwindlabs/tailwindcss-forms#using-only-global-styles-or-only-classes
    forms({
      strategy: 'class',
    }),
    headlessui,
  ],
} satisfies Config;
