import { IRange } from '@jooxter/utils';

export enum IJxtRangeDateFilterViewType {
  Week = 'week',
  Day = 'day',
}

export interface IJxtRangeDateFilter {
  range: IRange;
  setRange: (range: IRange) => void;
  timezone?: string;
  viewType?: IJxtRangeDateFilterViewType;
  excludeWeekEnds?: boolean;
  className?: string;
  children?: React.ReactNode;
}
