import clsx from 'clsx';
import { IJxtSmartSlotSelector } from './types';

const JxtSmartSlotSelector = ({ children, selected, onClick }: IJxtSmartSlotSelector) => {
  return (
    <button
      type="button"
      className={clsx(
        'px-3 rounded-full border h-9 text-body-s',
        selected
          ? 'text-primary-100 bg-primary-10 border-primary-40'
          : 'bg-white hover:bg-neutral-10 text-neutral-120 border-neutral-40 hover:border-neutral-50'
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default JxtSmartSlotSelector;
