import { JxtContainer } from '@jooxter/ui';
import { IJxtOnboardingLayout } from './types';

const JxtOnboardingLayout = ({ title, surtitle, instructions, children }: IJxtOnboardingLayout) => {
  return (
    <div className="flex grow justify-center items-start px-5 py-10">
      <JxtContainer className="w-[400px] max-h-[660px]">
        <div className="flex flex-col grow gap-6 min-w-0">
          {surtitle && <h3 className="text-title-l text-neutral-140 font-semibold text-center">{surtitle}</h3>}
          {title && <h2 className="text-title-xl text-neutral-140 font-semibold text-center">{title}</h2>}
          {instructions && <p className="text-body-m text-neutral-100 text-center">{instructions}</p>}
          {children}
        </div>
      </JxtContainer>
    </div>
  );
};

export default JxtOnboardingLayout;
