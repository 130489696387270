import { RefObject, useCallback, useEffect, useRef } from 'react';

const useHookWithRefCallback = <T>(
  callback: (n: T) => void,
  cleanup?: (r?: RefObject<T | null>) => void
): [RefObject<T | null>, (n: T) => void] => {
  const ref = useRef<T | null>(null);
  const setRef = useCallback(
    (node: T) => {
      if (ref.current && cleanup) {
        // Make sure to cleanup any events/references added to the last instance
        cleanup(ref);
      }

      if (node) {
        callback(node);
      }

      // Save a reference to the node
      ref.current = node;
    },
    [callback, cleanup]
  );

  useEffect(() => {
    return () => cleanup && cleanup(ref);
  }, []);

  return [ref, setRef];
};

export default useHookWithRefCallback;
