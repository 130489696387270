import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  BOOKINGS_MINE_ROUTE,
  SEARCH_SPACE_ROUTE,
  USER_PLANNING_ROUTE,
  useCanAccessCollaborators,
  useFetchUser,
  useStore,
} from '@jooxter/core';
import {
  JxtRangeDateFilter,
  JxtContainer,
  ContainerPaddingEnum,
  ContainerSizeEnum,
  ButtonVariantEnum,
  JxtLoader,
} from '@jooxter/ui';
import { createGTMUpdateVirtualPathEvent, fromISO, IRange, now, toISO } from '@jooxter/utils';
import { HomeWorkplacesWeek } from './HomeWorkplacesWeek';
import { HomeStatisticsSoon } from './HomeStatisticsSoon';
import { HomeBookingsWeek } from './HomeBookingsWeek';
import { useNavigate } from 'react-router';
import { useShallow } from 'zustand/shallow';

export const Home = () => {
  const { t } = useTranslation();
  const { user } = useFetchUser();
  const { canAccessCollaborators } = useCanAccessCollaborators();
  const [filter, setFilter] = useStore(useShallow((state) => [state.filter, state.setFilter]));
  const [range, setRange] = useState<IRange>({
    from: filter?.from ? fromISO(filter.from, user?.timezone) : now().startOf('week'),
    to: filter?.to ? fromISO(filter.to, user?.timezone) : now().endOf('week').minus({ days: 2 }),
  });
  const navigate = useNavigate();

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('Home');
  }, []);

  useEffect(() => {
    setFilter({ ...filter, from: toISO(range.from), to: toISO(range.to) });
  }, [range]);

  useEffect(() => {
    if (canAccessCollaborators === undefined) {
      return;
    }

    if (user && !canAccessCollaborators) {
      navigate(SEARCH_SPACE_ROUTE);
    }
  }, [user, canAccessCollaborators]);

  if (!user) {
    return <JxtLoader />;
  }

  return (
    <div className="flex justify-center">
      <div className="mx-6 mt-4 mb-3 max-w-[1440px] w-full">
        <header className="flex max-sm:flex-col gap-2 flex-wrap max-sm:items-start justify-between items-center">
          <h1 className="text-headline-xxl font-bold">
            {t('hello')} {user.firstname}
          </h1>
          <JxtRangeDateFilter
            range={range}
            setRange={setRange}
            timezone={user.timezone}
            excludeWeekEnds={true}
            className="rounded-2xl"
          />
        </header>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pt-4 lg:grid-rows-[74vh]">
          <JxtContainer
            size={ContainerSizeEnum.Small}
            title={t<string>('home-workplaces')}
            button={{
              variant: ButtonVariantEnum.Link,
              onClick: () => navigate(USER_PLANNING_ROUTE),
              children: <p className="text-body-m font-semibold">{t('see-all')}</p>,
            }}
            padding={ContainerPaddingEnum.None}
          >
            <HomeWorkplacesWeek range={range} user={user} />
          </JxtContainer>
          <JxtContainer
            size={ContainerSizeEnum.Small}
            title={t<string>('home-bookings')}
            button={{
              variant: ButtonVariantEnum.Link,
              children: <p className="text-body-m font-semibold">{t('see-all')}</p>,
              onClick: () => navigate(BOOKINGS_MINE_ROUTE),
            }}
          >
            <HomeBookingsWeek range={range} user={user} />
          </JxtContainer>
          <JxtContainer
            size={ContainerSizeEnum.Small}
            title={t<string>('home.statistics')}
            padding={ContainerPaddingEnum.None}
          >
            <HomeStatisticsSoon />
          </JxtContainer>
        </div>
      </div>
    </div>
  );
};
