import { JxtFavoritesOnlyFilter, JxtLocationSelect, JxtTeamsCheckboxList, useFetchLocations } from '@jooxter/core';
import { IColleaguesFilters } from './types';
import { useTranslation } from 'react-i18next';
import { LocationCompressed } from '@jooxter/api';
import { JxtWorkplaceSelect } from '@jooxter/ui';
import { createGTMGAEvent, JxtWorkplaceTypeWithoutUnknownEnum } from '@jooxter/utils';

export const ColleaguesFilters = ({ filter, onChangeFilter, user }: IColleaguesFilters) => {
  const { t } = useTranslation();
  const { locations } = useFetchLocations(filter.colleague.locationIds);
  const defaultLocationValue = locations
    .map((l) => ({ value: l.id, label: l.name }))
    .filter((l): l is { value: number; label: string } => !!l.value);

  const handleWorkplaceSelect = (selectedWorkplace: JxtWorkplaceTypeWithoutUnknownEnum | null) => {
    createGTMGAEvent('Collaborators', 'Filter', 'Statuses');
    if (selectedWorkplace !== JxtWorkplaceTypeWithoutUnknownEnum.Office) {
      onChangeFilter({ locationIds: [], type: selectedWorkplace });
    } else {
      onChangeFilter({ type: selectedWorkplace });
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <div className="p-4 border-b border-neutral-20">
        <JxtFavoritesOnlyFilter
          label={t('colleagues-label-favorites-only')}
          defaultValue={filter.colleague.favoritesOnly}
          onChange={(checked: boolean) => {
            onChangeFilter({ favoritesOnly: checked });
            createGTMGAEvent('Collaborators', 'Filter', 'Favorite');
          }}
        />
      </div>
      <div className="flex flex-col gap-2 p-4 border-b border-neutral-20">
        <span className="text-body-s text-neutral-140 font-medium">{t('colleagues-label-workplaces')}</span>
        <JxtWorkplaceSelect
          value={filter.colleague.type}
          isClearable={true}
          placeholder={t<string>('colleagues-placeholder-workplaces')}
          onWorkplaceSelect={handleWorkplaceSelect}
        />
      </div>
      <div className="flex flex-col gap-2 p-4 border-b border-neutral-20">
        <span className="text-body-s text-neutral-140 font-medium">{t('colleagues-label-buildings')}</span>
        <JxtLocationSelect
          isMulti
          defaultValue={defaultLocationValue}
          selectedOptions={filter.colleague.locationIds}
          onLocationSelect={(selectedLocations: LocationCompressed[]) => {
            onChangeFilter({ locationIds: selectedLocations.map((loc) => loc.id) });
            createGTMGAEvent('Collaborators', 'Filter', 'Building');
          }}
          disabled={filter.colleague.type !== JxtWorkplaceTypeWithoutUnknownEnum.Office}
        />
      </div>
      <JxtTeamsCheckboxList
        scope="colleagues"
        selectedGroupIds={filter.colleague.groupIds}
        onChange={(selectedOptions: number[]) => {
          onChangeFilter({ groupIds: selectedOptions });
          createGTMGAEvent('Collaborators', 'Filter', 'Group');
        }}
      />
    </>
  );
};
