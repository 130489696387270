// eslint-disable-next-line import/named
import { AxiosInstance } from 'axios';
import {
  TOnRequestError,
  TOnRequestSuccess,
  TOnResponseError,
  TOnResponseSuccess,
  TUpdateAxiosInterceptorsConfig,
} from './types';
import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { now } from '@jooxter/utils';

export const createResponseInterceptor = (
  instance: AxiosInstance,
  onSuccess: TOnResponseSuccess,
  onError: TOnResponseError
): number => {
  return instance.interceptors.response.use(
    onSuccess || ((response) => response),
    onError ||
      ((error) => {
        console.warn('An error occurred while adding token', JSON.stringify(error));
        return Promise.reject(error);
      })
  );
};

export const createRequestInterceptor = (
  instance: AxiosInstance,
  onSuccess: TOnRequestSuccess,
  onError: TOnRequestError
): number => {
  return instance.interceptors.request.use(
    onSuccess || ((config) => config),
    onError ||
      ((error) => {
        console.warn('An error occurred while adding token', JSON.stringify(error));
        return Promise.reject(error);
      })
  );
};

export const updateAxiosInterceptors = (instance: AxiosInstance, config: TUpdateAxiosInterceptorsConfig) => {
  const responseInterceptorId = createResponseInterceptor(instance, config.onResponseSuccess, config.onResponseError);
  const requestInterceptorId = createRequestInterceptor(instance, config.onRequestSuccess, config.onRequestError);

  return () => {
    instance.interceptors.request.eject(requestInterceptorId);
    instance.interceptors.response.eject(responseInterceptorId);
  };
};

export const isJwtTokenExpired = (token: string): boolean => {
  const decodedToken = jwtDecode(token);
  if (!decodedToken.exp) {
    return true;
  }
  const expirationDate = DateTime.fromSeconds(decodedToken.exp);
  return expirationDate < now().plus({ minutes: 1 });
};
