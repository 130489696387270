import { useTranslation } from 'react-i18next';
import { IJxtResourceInfos } from './types';
import JxtResourceIcon from '../JxtResourceIcon';

const JxtResourceInfos = ({ name, type, typeName, capacity, locationName, floor, picture }: IJxtResourceInfos) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-3">
      {picture ? (
        <img src={picture} className="rounded-xl size-[72px] object-cover shrink-0" alt={`Picture of ${name}`} />
      ) : (
        <JxtResourceIcon type={type} />
      )}
      <div className="flex flex-col justify-center truncate">
        <p className="text-title-m text-neutral-140 font-medium truncate">{name}</p>
        {typeName && capacity && (
          <p className="text-body-s text-neutral-100">
            {typeName} ({capacity} {t('resource-person')})
          </p>
        )}
        {locationName && (
          <p className="text-body-s text-neutral-100">
            {locationName}
            {(!!floor || floor === 0) && ' - ' + t('resource-short-card-floor-number', { floorNumber: floor })}
          </p>
        )}
      </div>
    </div>
  );
};

export default JxtResourceInfos;
