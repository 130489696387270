import JxtAlert from '../JxtAlert';
import { JxtAlertTypeEnum } from '../JxtAlert/types';
import { DateTime } from 'luxon';
import { IJxtEventDate } from '../JxtEventDate/types';
import { getGridStyles } from './styles';

const JxtEventDateSameDay = ({ range, timezoneWarning }: IJxtEventDate) => {
  const classes = getGridStyles(!!timezoneWarning);

  return (
    <div className={classes}>
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-clock fa-fw fa-lg text-neutral-60" />
      </div>
      <div className="text-body-m text-neutral-140">
        <span className="mr-3">{range.from.toLocaleString(DateTime.DATE_FULL)}</span>
        <span>
          {range.from.toLocaleString(DateTime.TIME_SIMPLE)} - {range.to.toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
      </div>
      {!!timezoneWarning && (
        <div className="col-start-2 col-end-3">
          <JxtAlert text={timezoneWarning} type={JxtAlertTypeEnum.Warning} closeable={false} />
        </div>
      )}
    </div>
  );
};

export default JxtEventDateSameDay;
