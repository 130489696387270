import { useEffect, useState } from 'react';
import { IJxtSearchBar } from './types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const JxtSearchBar = ({ name, value = '', placeholder, disabled = false, onChange, onClick }: IJxtSearchBar) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    setShowClearButton(!disabled && inputValue.length > 0);
  }, [disabled, isFocused, inputValue]);

  useEffect(() => {
    if (onChange) {
      onChange(inputValue);
    }
  }, [inputValue]);

  const handleChange = (value: string) => {
    setInputValue(value);
  };

  const handleClear = () => {
    setShowClearButton(false);
    setInputValue('');
  };

  return (
    <div className="w-full">
      <div className="relative group">
        <label
          htmlFor={name}
          className={clsx(
            'pointer-events-none absolute inset-y-0 pl-2 flex items-center',
            disabled
              ? 'text-neutral-40'
              : isFocused || inputValue.length > 0
              ? 'text-neutral-100'
              : 'text-neutral-60 group-hover:text-neutral-100'
          )}
        >
          <i className="fas fa-search" />
        </label>
        <input
          id={name}
          name={name}
          type="search"
          value={inputValue}
          placeholder={placeholder ?? t<string>('search')}
          disabled={disabled}
          autoComplete="off"
          onClick={onClick}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={clsx(
            'w-full py-2 pl-8 pr-9 rounded-lg outline outline-1 outline-offset-0 text-body-s',
            disabled
              ? 'bg-neutral-10 outline-neutral-10 text-neutral-40 cursor-not-allowed'
              : inputValue.length > 0
              ? 'bg-white outline-primary-100 text-neutral-140'
              : 'bg-white outline-neutral-20 text-neutral-120 hover:outline-neutral-30 hover:text-neutral-100 focus:outline-primary-100 focus:text-neutral-140'
          )}
        />
        {showClearButton && (
          <button
            className="text-neutral-120 cursor-pointer absolute inset-y-0 right-0 flex pr-2 items-center"
            onClick={handleClear}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          >
            <i className="fas fa-fw fa-times" />
          </button>
        )}
      </div>
    </div>
  );
};

export default JxtSearchBar;
