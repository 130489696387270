import { useFetchUser } from '@jooxter/core';
import { JxtNavbar } from '@jooxter/ui';
import { Outlet } from 'react-router';
import useJooxterNavigation from '../../routes/hooks';

export const MenuLayout = () => {
  const { user } = useFetchUser();
  const [navigation] = useJooxterNavigation({ user });

  return (
    <>
      {user && (
        <JxtNavbar
          user={user}
          routes={navigation.routes}
          buttons={navigation.buttons ?? []}
          logoRoute={navigation.baseRoute}
        />
      )}
      <div className="bg-background grow">
        <Outlet />
      </div>
    </>
  );
};
