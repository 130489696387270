import { FormEvent, FormEventHandler } from 'react';
import { IJxtSwitch } from './types';

const JxtSwitch = ({ children, tabChanged }: IJxtSwitch) => {
  const onChange: FormEventHandler<HTMLFormElement> = (e: FormEvent<HTMLFormElement>) => {
    const value = (e.target as HTMLInputElement).value;

    if (value) {
      tabChanged(value);
    }
  };

  return (
    <form
      onChange={onChange}
      className="p-1 h-9 max-sm:w-full sm:min-w-60 bg-neutral-10 rounded-lg gap-1 justify-start items-start inline-flex"
    >
      {children}
    </form>
  );
};

export default JxtSwitch;
