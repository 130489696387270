import { UserLoginDetailApiVersionEnum } from '@jooxter/api';
import { createGTMGAEvent, generateToast } from '@jooxter/utils';
import { LOGIN_ROUTE } from '../routing/routes';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resetPassword } from 'aws-amplify/auth';
import { useStartResetPasswordMutation } from '../mutations/login';
import { useButtonStatus } from '../hooks';
import { useNavigate } from 'react-router';
import { useFetchLoginDetails } from '../queries';

export const useStartResetPassword = (
  gtm: [string, string, string],
  email?: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  const { t } = useTranslation();
  const { loginDetails } = useFetchLoginDetails(email);
  const { mutate: mutateResetPasswordV3 } = useStartResetPasswordMutation();
  const {
    children: btnPasswordChildren,
    setLoading: setIsButtonPasswordLoading,
    loading: isButtonPasswordLoading,
  } = useButtonStatus();
  const navigate = useNavigate();
  const [firstEmailSent, setFirstEmailSent] = useState<boolean>(false);

  const onEmailSentSuccess = (): void => {
    generateToast(t('submit-btn-done'), false);
    onSuccess && onSuccess();
  };

  const onEmailSentError = (error?: string): void => {
    generateToast(error ?? t('unknown-error'), true);
    onError && onError();
  };

  const onEmailSentSettled = () => {
    setIsButtonPasswordLoading(false);
    setFirstEmailSent(true);
  };

  const startResetPassword = useCallback(async () => {
    if (!(email && loginDetails)) {
      return navigate(LOGIN_ROUTE);
    }

    createGTMGAEvent(...gtm);
    setIsButtonPasswordLoading(true);

    if (loginDetails?.shouldBeMigratedToCognito && loginDetails?.apiVersion === UserLoginDetailApiVersionEnum.V4) {
      try {
        const output = await resetPassword({
          username: email,
        });
        const { nextStep } = output;
        // eslint-disable-next-line no-console
        console.log('nextStep', nextStep);

        if (nextStep.resetPasswordStep !== 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
          return onEmailSentError();
        }

        return onEmailSentSuccess();
      } catch (e: unknown) {
        if (
          typeof e === 'object' &&
          e !== null &&
          Object.getOwnPropertyNames(e).includes('name') &&
          (e as { name: string }).name === 'LimitExceededException'
        ) {
          onEmailSentError(t<string>('reset-password.limit-exceeded'));
        } else {
          onEmailSentError();
        }
      } finally {
        onEmailSentSettled();
      }
    } else {
      mutateResetPasswordV3(email, {
        onSuccess: onEmailSentSuccess,
        onError: (err) => {
          const error =
            Array.isArray(err.response?.data) && err.response?.data[0]?.description
              ? err.response?.data[0]?.description
              : t('unknown-error');
          onEmailSentError(error);
        },
        onSettled: onEmailSentSettled,
      });
    }
  }, [email, gtm, loginDetails, mutateResetPasswordV3, navigate]);

  return { startResetPassword, isButtonPasswordLoading, btnPasswordChildren, firstEmailSent };
};
