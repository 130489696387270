import { ChangeEvent, useState } from 'react';
import { getLabelClasses } from './styles';
import { IJxtRecurrenceSwitchDay } from './types';

const JxtRecurrenceSwitchDay = ({ labelForId, title, value, onChange }: IJxtRecurrenceSwitchDay) => {
  const [activated, setActivated] = useState<boolean>(value);
  const labelClasses = getLabelClasses(activated);

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setActivated(e.target.checked);
    onChange(e.target.checked);
  };

  return (
    <label className={labelClasses} htmlFor={labelForId} title={title}>
      <span>{title}</span>
      <input checked={activated} onChange={onCheckboxChange} className="hidden" type="checkbox" id={labelForId} />
    </label>
  );
};

export default JxtRecurrenceSwitchDay;
