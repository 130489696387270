import {
  BAD_REQUEST_ROUTE,
  LOGIN_ROUTE,
  useAuthContext,
  useFetchLoginDetails,
  useFetchUser,
  useStore,
} from '@jooxter/core';
import { useNavigate, useParams } from 'react-router';
import { JSX, useCallback, useEffect } from 'react';
import { RealTimeStatusMap } from '../pages/RealTimeStatusMap';
import { UserLoginDetailTypeEnum } from '@jooxter/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { ComingFromEnum } from './types';
import { useShallow } from 'zustand/shallow';

export const RtsmGuardV4 = (): JSX.Element | null => {
  const { encoded_params } = useParams();
  const { login, logout } = useAuthContext();
  const navigate = useNavigate();
  const [token, setEmail] = useStore(useShallow((state) => [state.token, state.setEmail]));
  const decoded_params = encoded_params ? atob(encoded_params) : '';
  const params = new URLSearchParams(decoded_params);
  const username = params.get('username') || undefined;
  const password = params.get('password') || undefined;
  const { loginDetails: urlDetails } = useFetchLoginDetails(username);
  const { user } = useFetchUser();
  const { loginDetails: sessionDetails } = useFetchLoginDetails(user?.email);
  const comingFrom = token ? ComingFromEnum.SESSION : encoded_params ? ComingFromEnum.URL : null;
  const details =
    comingFrom === ComingFromEnum.SESSION ? sessionDetails : comingFrom === ComingFromEnum.URL ? urlDetails : null;
  const shouldMigrate = details?.shouldBeMigratedToCognito;

  const redirectToLogin = useCallback(() => {
    logout();
    navigate(LOGIN_ROUTE);
  }, [logout, navigate]);

  const redirectToBadRequest = useCallback(() => {
    navigate(BAD_REQUEST_ROUTE);
  }, [navigate]);

  useEffect(() => {
    if (username && password && shouldMigrate) {
      fetchAuthSession().then((session) => {
        if (!session.tokens?.accessToken) {
          void login({ email: username, password: password });
        }
      });
    }
  }, [login, username, password, shouldMigrate]);

  useEffect(() => {
    if (!token && username) {
      setEmail(username);
    }
  }, [username, token, setEmail]);

  if (details && (!shouldMigrate || details?.type === UserLoginDetailTypeEnum.Saml2)) {
    redirectToBadRequest();

    return null;
  }

  if (!(encoded_params || token)) {
    redirectToLogin();

    return null;
  }

  return <RealTimeStatusMap />;
};
