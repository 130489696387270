import clsx from 'clsx';
// eslint-disable-next-line import/named
import { NavLink, NavLinkProps } from 'react-router';
import { IJxtNavItem } from './types';

const JxtNavItem = ({ name, href, icon, openInNewTab, onClick }: IJxtNavItem) => {
  const iconClasses = `fas fa-${icon} text-neutral-60 mr-1 max-lg:ml-[4.8px]`;
  const liClasses =
    'list-none flex mx-px lg:mx-1 lg:my-2 my-3 lg:my-0 focus:shadow-nav-item-left-primary-60 hover:shadow-nav-item-left-primary-60 lg:hover:shadow-nav-item-bottom-primary-60 lg:focus:shadow-nav-item-bottom-primary-60';
  const linkClasses: NavLinkProps['className'] = ({ isActive }) => {
    const staticClasses =
      'py-2 cursor-pointer lg:py-[23px] text-body-s font-semibold text-[#212529] font-["Open_Sans"] px-4 lg:px-[0.3rem]';
    return clsx(
      staticClasses,
      isActive ? 'shadow-nav-item-left-primary-100 lg:shadow-nav-item-bottom-primary-100' : ''
    );
  };
  const attributes = openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <li className={liClasses}>
      <NavLink className={linkClasses} to={href} {...attributes} title={name} onClick={onClick}>
        <i className={iconClasses} />
        {'\u00a0' + name}
      </NavLink>
    </li>
  );
};

export default JxtNavItem;
