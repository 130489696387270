import { axiosManager } from '../config';
import { MediaResponseDto } from '../model';

export const MediaService = {
  path: 'medias',

  postImage(image: File): Promise<MediaResponseDto> {
    const url = `${this.path}`;
    const formData = new FormData();
    formData.append('file', image);

    return axiosManager
      .getInstance()
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  },
};
