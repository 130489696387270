import JxtCloseButton from '../JxtCloseButton';
import { IJxtOffCanvasHeader, JxtOffCanvasHeaderHideIconEnum } from './types';
import { JxtIconButtonSizeEnum } from '../JxtIconButton/types';
import JxtBackButton from '../JxtBackButton';
import JxtIconButton from '../JxtIconButton';
import clsx from 'clsx';

export const JxtOffCanvasHeader = ({
  title,
  subtitle,
  hideIcon = JxtOffCanvasHeaderHideIconEnum.Close,
  onHide,
  iconList,
  children,
}: IJxtOffCanvasHeader) => (
  <header className="flex flex-col gap-4 border-b border-neutral-20 px-6 py-4">
    <div className={clsx('flex justify-between gap-2', title && subtitle ? '' : 'items-center')}>
      {hideIcon === JxtOffCanvasHeaderHideIconEnum.Close ? (
        <JxtCloseButton size={JxtIconButtonSizeEnum.R} onClick={onHide} />
      ) : (
        <JxtBackButton size={JxtIconButtonSizeEnum.R} onClick={onHide} />
      )}
      {(title || subtitle) && (
        <div className="flex flex-col truncate items-center">
          {title && typeof title === 'string' && <p className="text-title-m text-neutral-140 font-medium">{title}</p>}
          {title && typeof title !== 'string' && title}
          {subtitle && <p className="text-body-s text-neutral-80">{subtitle}</p>}
        </div>
      )}
      {iconList?.length ? (
        <div className="flex gap-2">
          {iconList.map((icon) => (
            <JxtIconButton
              key={icon.iconName}
              size={JxtIconButtonSizeEnum.R}
              onClick={icon.onClick}
              icon={icon.iconName}
            />
          ))}
        </div>
      ) : (
        <div className="size-9" />
      )}
    </div>
    {children}
  </header>
);
