import clsx from 'clsx';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import { IJxtZoomButton } from './types';

const JxtZoomButton = ({ disabled = false, className, onZoomIn, onZoomOut }: IJxtZoomButton) => {
  return (
    <span className={clsx(className, 'isolate inline-flex shadow-sm rounded-lg shadow-elevation-3')}>
      <JxtButton
        disabled={disabled}
        variant={ButtonVariantEnum.Light}
        className={clsx(
          'rounded-l-lg border border-r-0 border-neutral-10 rounded-r-none !text-body-m/[16px] !py-2.5 !px-[13px]'
        )}
        onClick={onZoomOut}
      >
        <span className="sr-only">Zoom out</span>
        <i className={clsx('fa-solid fa-minus', disabled && 'text-neutral-60')} aria-hidden="true" />
      </JxtButton>
      <JxtButton
        disabled={disabled}
        variant={ButtonVariantEnum.Light}
        className={clsx(
          '-ml-px rounded-r-lg border border-l-0 border-neutral-10 rounded-l-none !text-body-m/[16px] !py-2.5 !px-[13px]'
        )}
        onClick={onZoomIn}
      >
        <span className="sr-only">Zoom in</span>
        <i className={clsx('fa-solid fa-plus', disabled && 'text-neutral-60')} aria-hidden="true" />
      </JxtButton>
    </span>
  );
};

export default JxtZoomButton;
