import JxtIconButton from '../JxtIconButton';
import { JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from '../JxtIconButton/types';
import { IJxtCloseButton } from './types';

const JxtCloseButton = ({
  onClick,
  size = JxtIconButtonSizeEnum.R,
  type = JxtIconButtonTypeEnum.Filled,
}: IJxtCloseButton) => {
  return <JxtIconButton onClick={onClick} size={size} type={type} icon="times" />;
};

export default JxtCloseButton;
