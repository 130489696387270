import { DataPeriodEnum, IQuickTimeSlot } from '@jooxter/utils';
import { useCallback, useMemo } from 'react';
import { IUseResourceSlotGenerator } from './types';
import { useDefaultSlotGenerator } from './useDefaultSlotGenerator';
import { useFetchFreeSlots } from './useFetchFreeSlots';

export const useResourceSlotGenerator = ({
  start,
  end,
  options,
}: IUseResourceSlotGenerator): { slots: IQuickTimeSlot[] } => {
  const { slots: defaultSlots } = useDefaultSlotGenerator({ start, end, timezone: options.timezone });

  const dayDefaultSlot = useMemo(() => defaultSlots.find((s) => s.period === DataPeriodEnum.Day), [defaultSlots]);
  const amDefaultSlot = useMemo(() => defaultSlots.find((s) => s.period === DataPeriodEnum.Am), [defaultSlots]);
  const pmDefaultSlot = useMemo(() => defaultSlots.find((s) => s.period === DataPeriodEnum.Pm), [defaultSlots]);

  const { slots: resourceDaySlots } = useFetchFreeSlots(
    dayDefaultSlot,
    options.timezone,
    dayDefaultSlot?.start && dayDefaultSlot.end
      ? {
          id: options.resourceId,
          start: dayDefaultSlot.start,
          end: dayDefaultSlot.end,
          seatRequested: options.seatRequested,
          bookingMask: options.bookingMask,
        }
      : undefined
  );
  const { slots: resourceAmSlots } = useFetchFreeSlots(
    amDefaultSlot,
    options.timezone,
    amDefaultSlot?.start && amDefaultSlot.end
      ? {
          id: options.resourceId,
          start: amDefaultSlot.start,
          end: amDefaultSlot.end,
          seatRequested: options.seatRequested,
          bookingMask: options.bookingMask,
        }
      : undefined
  );
  const { slots: resourcePmSlots } = useFetchFreeSlots(
    pmDefaultSlot,
    options.timezone,
    pmDefaultSlot?.start && pmDefaultSlot.end
      ? {
          id: options.resourceId,
          start: pmDefaultSlot.start,
          end: pmDefaultSlot.end,
          seatRequested: options.seatRequested,
          bookingMask: options.bookingMask,
        }
      : undefined
  );

  const getResourceSlots = useCallback(() => {
    if (dayDefaultSlot) {
      if (resourceDaySlots.length === 1 && resourceDaySlots[0] === dayDefaultSlot) {
        return [dayDefaultSlot, amDefaultSlot, pmDefaultSlot].filter((s): s is IQuickTimeSlot => !!s);
      }

      return [...resourceAmSlots, ...resourcePmSlots];
    }

    if (pmDefaultSlot) {
      return resourcePmSlots;
    }

    return [];
  }, [resourceDaySlots, resourceAmSlots, resourcePmSlots]);

  const slots = useMemo(() => {
    if (!defaultSlots?.length) {
      return [];
    }

    return getResourceSlots();
  }, [resourceDaySlots, resourceAmSlots, resourcePmSlots]);

  return { slots };
};
