import { IGetFloorResourcesParameters } from '@jooxter/api';
import { FilterContextResource } from '../../store';
import { fromISO } from '@jooxter/utils';

export const SearchResourcesFiltersToGetFloorResourcesParamsAdapter = {
  adapt(filters?: FilterContextResource): IGetFloorResourcesParameters {
    if (!filters) {
      return {};
    }

    return {
      name: filters.resourceName,
      capacity: filters.capacity,
      labels: filters.labels,
      from: filters.now ? undefined : fromISO(filters.date?.from),
      to: filters.now ? undefined : fromISO(filters.date?.to),
      resourceTypeId: filters.resourceTypesIds,
    };
  },
};
