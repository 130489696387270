import clsx from 'clsx';
import { IJxtCollapsibleFilter } from './types';
import { useEffect, useState } from 'react';

const JxtCollapsibleFilter = ({
  label,
  defaultFolded = true,
  value,
  children,
  hasError = false,
  onFoldChange,
}: IJxtCollapsibleFilter) => {
  const [isFolded, setIsFolded] = useState<boolean>(defaultFolded);

  useEffect(() => {
    if (onFoldChange) {
      onFoldChange(isFolded);
    }
  }, [isFolded]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col cursor-pointer" onClick={() => setIsFolded(!isFolded)}>
        <div className="flex gap-2 items-center justify-between">
          <span className="text-body-s font-medium text-neutral-140">{label}</span>
          <i
            className={clsx(
              'fas text-neutral-120 px-2.5 py-1.5 justify-between',
              isFolded ? 'fa-chevron-right' : 'fa-chevron-down'
            )}
          />
        </div>
        {isFolded && (
          <span className={clsx('text-body-s font-medium', hasError ? 'text-red-80' : 'text-neutral-80')}>{value}</span>
        )}
      </div>
      {!isFolded && children}
    </div>
  );
};

export default JxtCollapsibleFilter;
