import { clsx } from 'clsx';
import { ButtonSizeEnum, ButtonVariantEnum } from './types';

export interface IGetJxtButtonClass {
  variant: ButtonVariantEnum;
  size: ButtonSizeEnum;
}

export interface IGetJxtButtonTextClass {
  size: ButtonSizeEnum;
}

export const getJxtButtonClass = ({ variant, size }: IGetJxtButtonClass) => {
  const variantClasses: Record<ButtonVariantEnum, string> = {
    primary:
      'bg-primary-100 text-white hover:bg-primary-110 active:bg-primary-120 focus-visible:bg-primary-120 disabled:bg-primary-40 ring-primary-30 focus-visible:ring-4 focus:outline-none',
    'outline-primary':
      'text-primary-100 outline outline-1 outline-offset-0 outline-primary-100 bg-white hover:text-primary-110 active:text-primary-120 focus-visible:text-primary-120 disabled:text-primary-40 ring-primary-30 focus-visible:ring-4 hover:outline-primary-110 focus:outline-primary-120 disabled:outline-primary-40',
    secondary:
      'bg-neutral-10 text-neutral-120 hover:bg-neutral-20 active:bg-neutral-30 focus-visible:bg-neutral-30 disabled:bg-neutral-10 disabled:text-neutral-40 ring-neutral-10 focus-visible:ring-4 focus:outline-none',
    link: 'bg-transparent text-primary-100 hover:underline disabled:text-primary-40 disabled:no-underline focus:outline-none',
    'link-dark':
      'bg-transparent text-neutral-120 hover:underline disabled:text-neutral-60 disabled:no-underline focus:outline-none',
    light:
      'bg-white text-neutral-120 hover:bg-neutral-10 active:bg-neutral-20 focus-visible:bg-neutral-20 disabled:bg-neutral-10 disabled:text-neutral-60 focus:outline-none',
    danger:
      'bg-red-100 text-white hover:bg-red-110 active:bg-red-120 focus-visible:bg-red-120 disabled:bg-red-40 ring-red-30 focus-visible:ring-4 focus:outline-none',
  };

  const isBtnLink = (variant: ButtonVariantEnum) =>
    variant === ButtonVariantEnum.Link || variant === ButtonVariantEnum.LinkDark;

  const sizeClasses = {
    small: clsx('rounded', !isBtnLink(variant) && 'py-1.5 px-2'),
    regular: clsx('rounded-lg', !isBtnLink(variant) && 'py-2 px-3'),
    large: clsx('rounded-lg', !isBtnLink(variant) && 'py-2 px-4'),
  };

  const classes = clsx('flex items-center justify-center', variantClasses[variant], sizeClasses[size]);

  return classes;
};

export const getJxtButtonTextClass = ({ size }: IGetJxtButtonTextClass) => {
  const sizeClasses = {
    small: clsx('text-body-xs font-medium'),
    regular: clsx('text-body-s font-medium'),
    large: clsx('text-body-m font-semibold'),
  };

  const classes = sizeClasses[size];

  return classes;
};
