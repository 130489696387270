import { BookingVisibilityEnum, ResourceTypeDtoMetaTypeEnum } from '@jooxter/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z, ZodRawShape } from 'zod';
import { useFetchUser } from '../../../queries';
import { IBookingFormDefaultValues, TResourceConfiguration } from '../types/booking-form.types';
import { useCreateOptionsDef } from '../hooks/useCreateOptionsDef';
import { createDateDef, dateTimeSchema } from '@jooxter/utils';

export const physicalAttendeesPattern = new RegExp(/^\d+$/);

// These definitions needs to be set for properties when we know
// we will need to get default value from schema later
const createRruleDef = (defaultRrule?: string | null) =>
  defaultRrule ? z.string().optional().nullable().default(defaultRrule) : z.string().optional().nullable();

export const useBookingSchema = (
  minPhysicalAttendees: number,
  maxPhysicalAttendees: number,
  isEditing: boolean,
  config?: TResourceConfiguration,
  defaultValues?: IBookingFormDefaultValues
) => {
  const { t } = useTranslation();
  const { user } = useFetchUser();
  const { createOptionsDef } = useCreateOptionsDef(config, defaultValues);

  const baseBookingSchema = useMemo(() => {
    let baseShape: {
      description: z.ZodNullable<z.ZodOptional<z.ZodString>>;
      resource: z.ZodObject<ZodRawShape>;
      start: z.ZodObject<{ date: typeof dateTimeSchema; time: z.ZodString }>;
      end: z.ZodObject<{ date: typeof dateTimeSchema; time: z.ZodString }>;
      options?: z.ZodObject<ZodRawShape>;
    } = {
      description: z.string().optional().nullable(),
      resource: z.object({
        id: z.number(),
      }),
      start: createDateDef(),
      end: createDateDef(),
    };

    const options = createOptionsDef();

    if (options) {
      baseShape = {
        ...baseShape,
        options,
      };
    }
    const simplestBookingSchema = z.object(baseShape);

    if (!user?.id || !defaultValues) {
      return simplestBookingSchema;
    }

    return simplestBookingSchema.extend({
      summary: z.string().min(1, t<string>('booking-form.error.summary.required')).default(defaultValues.summary),
      organizerId: z.number().default(user?.id),
      color: z.string().default(defaultValues.color),
      visibility: z.nativeEnum(BookingVisibilityEnum).default(defaultValues.visibility),
    });
  }, [user, defaultValues, config?.timezone, createOptionsDef, isEditing]);

  const deskParkingSchema: ZodRawShape = {
    rrule: createRruleDef(defaultValues?.rrule),
  };

  const zoneSchema: ZodRawShape = {
    physicalAttendees: z
      .number({
        required_error: t<string>('booking-form.error.physical-attendees.required'),
      })
      .int(t<string>('booking-form.error.physical-attendees.pattern'))
      .nonnegative(t<string>('booking-form.error.physical-attendees.negative'))
      .min(minPhysicalAttendees, t<string>('booking-form.error.physical-attendees.min'))
      .max(maxPhysicalAttendees, t<string>('booking-form.error.no-seat-remaining')),
    rrule: createRruleDef(defaultValues?.rrule),
    internalAttendees: z
      .array(z.number())
      .min(minPhysicalAttendees, t<string>('booking-form.error.internal-attendees.min')),
    externalAttendees: z.array(z.number()).min(0),
  };

  const vehicleSchema: ZodRawShape = {
    physicalAttendees: z
      .number()
      .int(t<string>('booking-form.error.physical-attendees.pattern'))
      .nonnegative(t<string>('booking-form.error.physical-attendees.negative'))
      .min(minPhysicalAttendees, t<string>('booking-form.error.physical-attendees.min'))
      .max(maxPhysicalAttendees, t<string>('booking-form.error.physical-attendees.max'))
      .optional()
      .nullable(),
    rrule: createRruleDef(defaultValues?.rrule),
    internalAttendees: z.array(z.number()).min(0),
    externalAttendees: z.array(z.number()).min(0),
  };

  const meetingBoothBubbleSchema: ZodRawShape = {
    physicalAttendees: z
      .number()
      .int(t<string>('booking-form.error.physical-attendees.pattern'))
      .nonnegative(t<string>('booking-form.error.physical-attendees.negative'))
      .min(minPhysicalAttendees, t<string>('booking-form.error.physical-attendees.min'))
      .max(maxPhysicalAttendees, t<string>('booking-form.error.physical-attendees.max'))
      .optional()
      .nullable(),
    rrule: createRruleDef(defaultValues?.rrule),
    internalAttendees: z.array(z.number()).min(0),
    externalAttendees: z.array(z.number()).min(0),
  };

  const visioOtherSchema: ZodRawShape = {
    rrule: createRruleDef(defaultValues?.rrule),
    internalAttendees: z.array(z.number()).min(0),
    externalAttendees: z.array(z.number()).min(0),
  };

  const schema = useMemo(() => {
    if (config?.isZone) {
      return baseBookingSchema.extend(zoneSchema);
    }

    let additionalConfig: ZodRawShape;

    switch (config?.metaType) {
      case ResourceTypeDtoMetaTypeEnum.Desk:
      case ResourceTypeDtoMetaTypeEnum.Parking:
        additionalConfig = deskParkingSchema;
        break;
      case ResourceTypeDtoMetaTypeEnum.Vehicle:
        additionalConfig = vehicleSchema;
        break;
      case ResourceTypeDtoMetaTypeEnum.Meeting:
      case ResourceTypeDtoMetaTypeEnum.Booth:
      case ResourceTypeDtoMetaTypeEnum.Bubble:
        additionalConfig = meetingBoothBubbleSchema;
        break;
      case ResourceTypeDtoMetaTypeEnum.Visio:
      case ResourceTypeDtoMetaTypeEnum.Other:
        additionalConfig = visioOtherSchema;
        break;
      default:
        additionalConfig = {};
        break;
    }

    if (config?.physicalAttendeesRequired) {
      additionalConfig = {
        ...additionalConfig,
        physicalAttendees: z
          .number({
            required_error: t<string>('booking-form.error.physical-attendees.required'),
          })
          .int(t<string>('booking-form.error.physical-attendees.pattern'))
          .nonnegative(t<string>('booking-form.error.physical-attendees.negative'))
          .min(minPhysicalAttendees, t<string>('booking-form.error.physical-attendees.min'))
          .max(maxPhysicalAttendees, t<string>('booking-form.error.physical-attendees.max'))
          .default(minPhysicalAttendees),
      };
    }

    if (config?.guestsRequired) {
      additionalConfig = {
        ...additionalConfig,
        internalAttendees: z
          .array(z.number())
          .min(minPhysicalAttendees, t<string>('booking-form.error.internal-attendees.min')),
      };
    }

    if (!config?.recurrence) {
      return baseBookingSchema.extend(additionalConfig).omit({ rrule: true });
    }

    return baseBookingSchema.extend(additionalConfig);
  }, [baseBookingSchema, config, minPhysicalAttendees, maxPhysicalAttendees]);

  return schema;
};
