export interface IJxtNavbarDropdownItem {
  icon?: string;
  text: string;
  onClick?: () => void;
  interactive: boolean;
  to?: string;
  size?: NavbarDropdownItemSizeEnum;
  /**
   * Add target="_blank" and ref="noopener" to the a tag
   */
  openInNewTab?: boolean;
}

export enum NavbarDropdownItemSizeEnum {
  M = 'M',
  L = 'L',
}
