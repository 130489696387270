import { BookingCompressed, IUserWorkplacesParameters, UserScheduler, UserService, UserWorkplaces } from '@jooxter/api';
import { flatMap, uniqBy } from 'lodash-es';
import { UserQueryKeys } from '../queryKeys';
import { UserStaleTimeEnum } from '../staleTimes';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

export const useFetchOldUserScheduler = (
  options: IUserWorkplacesParameters
): { schedulers: Array<UserScheduler & { bookings?: BookingCompressed[] }> | undefined } => {
  const fetchScheduler = () => UserService.getScheduler(options);

  const { data: schedulers } = useQuery({
    queryKey: [UserQueryKeys.GetOldScheduler, options],
    queryFn: () => fetchScheduler(),
    placeholderData: keepPreviousData,
    staleTime: UserStaleTimeEnum.GetOldScheduler,
  });

  return { schedulers };
};

export const useFetchWorkplaces = (
  options: IUserWorkplacesParameters,
  withSlice = false,
  personalWorkplaces = false
): {
  workplaces: UserWorkplaces[] | undefined;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          data: UserWorkplaces[];
          nextPage: string;
        },
        unknown
      >
    >
  >;
  hasNextPage?: boolean;
  lastPage: UserWorkplaces[];
  isFetching: boolean;
} => {
  const [workplaces, setWorkplaces] = useState<UserWorkplaces[]>([]);
  const [lastPage, setLastPage] = useState<UserWorkplaces[]>([]);
  const fetchWorkplaces = async ({ pageParam = '' }) => {
    const newOptions = { ...options };
    if (pageParam) {
      newOptions.page = pageParam;
    }

    return await UserService.getWorkplaces(newOptions);
  };

  const key = options.favoritesOnly
    ? UserQueryKeys.GetFavoritesWorkplaces
    : personalWorkplaces
      ? UserQueryKeys.GetMyWorkplaces
      : UserQueryKeys.GetWorkplaces;

  const { data, isLoading, isError, isFetching, isRefetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [key, options],
    queryFn: fetchWorkplaces,
    refetchInterval: 30000,
    getNextPageParam: (lastPage: { nextPage?: string; data: UserWorkplaces[] }) => {
      // must return undefined if it's the last page
      // lastPage.nextPage is ''
      return lastPage.nextPage?.length && lastPage.nextPage.length > 0 ? lastPage.nextPage : undefined;
    },
    initialPageParam: '',
    staleTime: UserStaleTimeEnum.GetWorkplaces,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (data?.pages.length) {
      const pages = [...data.pages];
      setLastPage(data.pages[pages.length - 1].data);
      const workplaces = uniqBy(flatMap(pages.map((page) => page.data)), 'id');
      if (withSlice) {
        setWorkplaces(workplaces.map((workplace) => ({ ...workplace, workplaces: workplace.workplaces?.slice(0, 5) })));
      } else {
        setWorkplaces(workplaces);
      }
    }
  }, [data?.pages, withSlice]);

  return {
    workplaces,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    lastPage,
    isFetching: isRefetching || isFetching,
  };
};

export const useInvalidateWorkplaceQueries = () => {
  const queryClient = useQueryClient();

  const invalidateWorkplaceQueries = useCallback(() => {
    queryClient.invalidateQueries({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      predicate: (query: any): boolean => {
        return (
          query.queryKey[0] === UserQueryKeys.GetWorkplaces ||
          query.queryKey[0] === UserQueryKeys.GetFavoritesWorkplaces ||
          query.queryKey[0] === UserQueryKeys.GetMyWorkplaces ||
          query.queryKey[0] === UserQueryKeys.GetOldScheduler
        );
      },
    });
  }, [queryClient]);

  return { invalidateWorkplaceQueries };
};
