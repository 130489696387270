import { DailyWorkplaceDto, UserWorkplaces } from '@jooxter/api';
import { fromISO, IJxtWorkplaceDay } from '@jooxter/utils';
import { useCallback, useMemo } from 'react';
import { useFetchLocations } from '../../queries';
import { useWorkplaceCompressedDtoToIJxtWorkplaceHalfDayAdapter } from './useWorkplaceCompressedDtoToIJxtWorkplaceHalfDayAdapter';

export const useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter = (usersWorkplaces?: UserWorkplaces[]) => {
  const workplacesLocationId = useMemo(() => {
    return (
      usersWorkplaces
        ?.flatMap(
          (userWorkplaces: UserWorkplaces) =>
            userWorkplaces?.workplaces
              ?.flatMap((workplace) => [workplace.morning?.locationId, workplace.afternoon?.locationId])
              .filter((locationId): locationId is number => !!locationId) ?? []
        )
        .filter((locationId): locationId is number => !!locationId) ?? []
    );
  }, [usersWorkplaces]);

  const { locations } = useFetchLocations(workplacesLocationId);
  const { adapt: adaptWorkplaceHalfDay } = useWorkplaceCompressedDtoToIJxtWorkplaceHalfDayAdapter(locations);

  const adapt = useCallback(
    (workplace: DailyWorkplaceDto): IJxtWorkplaceDay => {
      return {
        date: fromISO(workplace.date),
        morning: adaptWorkplaceHalfDay(workplace.morning),
        afternoon: adaptWorkplaceHalfDay(workplace.afternoon),
      };
    },
    [adaptWorkplaceHalfDay]
  );

  return { adapt };
};
