import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useBookingFormContext } from '../forms';
import { useLocation } from 'react-router';
import { TOffCanvasProps } from './useOffCanvasContext';
import { OffCanvasContext } from './OffCanvasContext';
import { offCanvasContainerEnum } from '@jooxter/ui';

const DEFAULT_OFFCANVAS_PROPS = { container: offCanvasContainerEnum.Main };

export const OffCanvasProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState<boolean>(false);
  const [offCanvasProps, setOffCanvasProps] = useState<TOffCanvasProps | null>(null);
  const bookingFormContext = useBookingFormContext();
  const location = useLocation();

  const close = () => {
    bookingFormContext?.closeBookingDetails();
    setShow(false);
    setOffCanvasProps(null);
  };

  const open = (props: TOffCanvasProps) => {
    setOffCanvasProps({ ...DEFAULT_OFFCANVAS_PROPS, ...props });
    setShow(true);
  };

  useEffect(() => {
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const offCanvasValue = useMemo(
    () => ({ offCanvasProps, setOffCanvasProps, show, open, close }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offCanvasProps, show]
  );

  return <OffCanvasContext.Provider value={offCanvasValue}>{children}</OffCanvasContext.Provider>;
};
