import { useEffect } from 'react';

export const useJimo = () => {
  // see https://docs.usejimo.com/guides/install-jimo/install-snippet/react/
  useEffect(() => {
    window.jimo = [];
    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://undercity.usejimo.com/jimo-invader.js';
    window.JIMO_PROJECT_ID = import.meta.env.VITE_JIMO_ID;
    window.JIMO_MANUAL_INIT = true;
    document.getElementsByTagName('head')[0].appendChild(s);
  }, []);
};
