import clsx from 'clsx';
import { getJxtIconButtonClass } from './styles';
import { IJxtIconButton, JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from './types';
import { MouseEvent } from 'react';

const JxtIconButton = ({
  icon,
  onClick,
  size = JxtIconButtonSizeEnum.R,
  type = JxtIconButtonTypeEnum.Filled,
  title,
  disabled = false,
}: IJxtIconButton) => {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const stopEvent = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (type === JxtIconButtonTypeEnum.Light) {
    return (
      <span
        aria-disabled={disabled}
        title={title}
        className={getJxtIconButtonClass(size, disabled)}
        onClick={disabled ? (event) => stopEvent(event) : (event) => handleClick(event)}
      >
        {!disabled && <i className="fas fa-fw fa-circle fa-stack-2x text-neutral-10 !hidden group-hover:!block" />}
        {!disabled && (
          <i className="fas fa-fw fa-circle fa-stack-2x text-neutral-20 !hidden group-focus:!block group-active:!block" />
        )}
        <i className={clsx('fas fa-fw fa-stack-1x', disabled ? 'text-neutral-40' : 'text-neutral-120', `fa-${icon}`)} />
      </span>
    );
  }

  return (
    <span
      aria-disabled={disabled}
      title={title}
      className={getJxtIconButtonClass(size, disabled)}
      onClick={disabled ? (event) => stopEvent(event) : (event) => handleClick(event)}
    >
      {disabled && <i className="fas fa-fw fa-circle fa-stack-2x text-neutral-10" />}
      {!disabled && (
        <i className="fas fa-circle fa-fw fa-stack-2x text-neutral-10 group-hover:!hidden group-focus:!hidden group-active:!hidden" />
      )}
      {!disabled && <i className="fas fa-fw fa-circle fa-stack-2x text-neutral-20 !hidden group-hover:!block" />}
      {!disabled && (
        <i className="fas fa-circle fa-fw fa-stack-2x text-neutral-30 !hidden group-focus:!block group-active:!block" />
      )}
      <i className={clsx('fas fa-fw fa-stack-1x', disabled ? 'text-neutral-40' : 'text-neutral-120', `fa-${icon}`)} />
    </span>
  );
};

export default JxtIconButton;
