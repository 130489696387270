import { ReactNode, useCallback, useState } from 'react';
import { BookingFormContext } from './useBookingFormContext';

export const BookingFormProvider = ({ children }: { children: ReactNode }) => {
  const [showBooking, setShowBooking] = useState<number | false>(false);

  const showBookingDetails = useCallback((id: number) => {
    setShowBooking(id);
  }, []);

  const closeBookingDetails = useCallback(() => {
    setShowBooking(false);
  }, []);

  return (
    <BookingFormContext.Provider value={{ showBooking, showBookingDetails, closeBookingDetails }}>
      {children}
    </BookingFormContext.Provider>
  );
};
