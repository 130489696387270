import { LoginService } from '@jooxter/api';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useStartResetPasswordMutation = () => {
  const resetPassword = (email: string) => LoginService.startResetPassword(email);

  const mutation = useMutation<void, AxiosError, string>({
    mutationFn: resetPassword,
  });

  return mutation;
};
