import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useFetchUser } from '../queries';

export const useTimezoneNameFormatter = () => {
  const { user } = useFetchUser();
  const locale = useMemo(() => {
    if (user) {
      return user.lang;
    }
  }, [user]);

  /** Returns undefined when could not retrieve the city for the given timezone */
  const getCity = (timezone: string): string | undefined => {
    const regexp = /^[a-zA-Z_-]+\/((?:[a-zA-Z_-]+\/)*[a-zA-Z_-]+)$/;
    const match = regexp.exec(timezone);

    if (match?.[1]) {
      return match[1].replace(/[_\\-]/g, ' ').replace(/\//g, ', ');
    }
    return undefined;
  };

  const format = useCallback(
    (timezone: string) => {
      if (!locale) {
        return timezone;
      }

      let humanizedtimezone: string | undefined;

      // If browser doesn't not support the locale, it will fallback to a default locale
      // could be 'en' locale or "GMT+xx:xx"/"UTC+xx:xx"

      // Intl.DateTimeFormat may throw when timezone id not supported
      try {
        const formatter = new Intl.DateTimeFormat(locale, {
          timeZone: timezone,
          timeZoneName: 'long',
        });

        humanizedtimezone = formatter.formatToParts(new Date()).find((part) => part.type === 'timeZoneName')?.value;
      } catch (e) {
        humanizedtimezone = DateTime.fromObject({}, { zone: timezone }).toFormat('ZZZZZ');
      }

      const offset = DateTime.fromObject({}, { zone: timezone }).toFormat('ZZ');
      const city = getCity(timezone);

      return city
        ? `(GMT${offset}) ${humanizedtimezone} - ${city}`
        : `(GMT${offset}) ${humanizedtimezone} - ${timezone}`;
    },
    [locale]
  );

  return { format };
};
