import JxtIconButton from '../JxtIconButton';
import { JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from '../JxtIconButton/types';
import { useTranslation } from 'react-i18next';
import { IJxtBookButton } from './types';

const JxtBookButton = ({
  onClick,
  size = JxtIconButtonSizeEnum.R,
  type = JxtIconButtonTypeEnum.Light,
  disabled,
}: IJxtBookButton) => {
  const { t } = useTranslation();
  return (
    <JxtIconButton
      title={t<string>('booking-button')}
      onClick={onClick}
      type={type}
      size={size}
      icon="calendar-plus"
      disabled={disabled}
    />
  );
};

export default JxtBookButton;
