import { useState } from 'react';
import { IJxtAlert, JxtAlertTypeInfo } from './types';
import clsx from 'clsx';

const JxtAlert = ({ text, type, closeable }: IJxtAlert) => {
  const [hidden, setHidden] = useState<boolean>(false);

  const handleAlertClose = () => {
    setHidden(true);
  };

  return hidden ? null : (
    <div className={clsx('p-2 gap-2 border rounded-lg flex items-center', JxtAlertTypeInfo[type].colors)}>
      <i className={clsx('fas fa-fw', JxtAlertTypeInfo[type].iconName)} />
      <p className="text-body-m grow break-words min-w-0">{text}</p>
      {closeable && (
        <div
          className={clsx(
            'h-6 w-6 cursor-pointer flex items-center justify-center',
            JxtAlertTypeInfo[type].closeIconStates
          )}
          onClick={handleAlertClose}
        >
          <i className="fas fa-fw fa-times" />
        </div>
      )}
    </div>
  );
};

export default JxtAlert;
