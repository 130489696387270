import { TResourceConfiguration } from '../types/booking-form.types';
import { Booking, ResourceStateDto } from '@jooxter/api';

export const useZoneSpaceAvailability = (
  resourceConfiguration?: TResourceConfiguration,
  count?: number,
  resourceState?: ResourceStateDto,
  bookingToEdit?: Booking | null
) => {
  let remainingSeats = 0;

  if (!resourceConfiguration?.isZone || resourceConfiguration.capacity < (resourceState?.occupantsCount ?? 0)) {
    remainingSeats = 0;
  } else {
    remainingSeats =
      resourceConfiguration.capacity -
      (count ?? 0) -
      (resourceState?.occupantsCount ?? 0) +
      (bookingToEdit?.physicalAttendees ?? 0);
  }

  return {
    remainingSeats: Math.max(0, remainingSeats),
  };
};
