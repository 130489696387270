import { useTranslation } from 'react-i18next';
import JxtBookButton from '../JxtBookButton';
import { JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from '../JxtIconButton/types';
import JxtLocateButton from '../JxtLocateButton';
import JxtResourceIcon from '../JxtResourceIcon';
import JxtTag from '../JxtTag';
import { JxtTagSizeEnum } from '../JxtTag/types';
import { IJxtResourceItem } from './types';
import JxtStar from '../JxtStar';
import { JxtStarSizeEnum, JxtStarTypeEnum } from '../JxtStar/types';
import { debounce } from 'lodash-es';
import { FAVORITE_DEBOUNCE_DELAY } from '@jooxter/utils';
import { useEffect, useState } from 'react';

const JxtResourceItem = ({
  name,
  id,
  email,
  resourceType,
  capacity,
  locationName,
  metaTypeIcon,
  floorNumber,
  bookable,
  status,
  disableLocate = false,
  picture,
  isFavorite = false,
  onClick,
  onBook,
  onLocate,
  isAddin = false,
  selectedLocations = [],
  onToggleFavorite,
}: IJxtResourceItem) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (email) {
      setIsSelected(selectedLocations.includes(email));
    }
  }, [selectedLocations]);

  return (
    <div
      className={`cursor-pointer w-full h-32 flex-1 bg-white gap-3 p-3 rounded-xl flex hover:bg-neutral-20 md:hover:bg-white ${isSelected && isAddin ? 'border-2 border-solid border-primary-100' : ''}`}
      onClick={() => {
        onClick(id);
        setIsSelected(!isSelected);
      }}
    >
      <figure className="shrink grow-0 basis-1/4 min-w-[calc(25%_-_12px)] flex">
        {!!picture && <img src={picture} className="grow rounded-lg object-cover" />}
        {!picture && <JxtResourceIcon type={metaTypeIcon} fixedWidth={false} />}
      </figure>
      <div className="flex flex-col gap-1 shrink grow min-w-[75%]">
        <h3 className="text-title-m font-medium text-neutral-140 gap-1 flex justify-between items-center">
          <span className="truncate">{name}</span>
          <div className="flex items-center gap-1">
            <JxtLocateButton
              disabled={disableLocate}
              onClick={() => onLocate(id)}
              size={JxtIconButtonSizeEnum.S}
              type={JxtIconButtonTypeEnum.Filled}
              disabledMessage={t('space-not-locatable-reason')}
              title={t('locate-space')}
            />
            {!isAddin && (
              <>
                <JxtBookButton
                  disabled={!bookable}
                  onClick={() => onBook(id)}
                  size={JxtIconButtonSizeEnum.S}
                  type={JxtIconButtonTypeEnum.Filled}
                />
                <JxtStar
                  checked={isFavorite}
                  type={JxtStarTypeEnum.Filled}
                  size={JxtStarSizeEnum.Small}
                  disabled={false}
                  onClick={debounce(
                    (e: boolean) => onToggleFavorite && onToggleFavorite(e, id),
                    FAVORITE_DEBOUNCE_DELAY
                  )}
                />
              </>
            )}
          </div>
        </h3>
        <section>
          <p
            className="flex text-body-s text-neutral-100"
            title={`${resourceType} - ${capacity} ${t('resource-person')}`}
          >
            <span className="truncate grow-0">{resourceType}</span>
            <span className="shrink-0">
              &nbsp;({capacity}&nbsp;{t('resource-person')})
            </span>
          </p>
          <p
            className="flex text-body-s text-neutral-100"
            title={`${locationName}${
              floorNumber
                ? ' - ' +
                  t('floor-by-number', {
                    floorNumber,
                  })
                : ''
            }`}
          >
            <span className="truncate grow-0">{locationName}</span>
            {!!floorNumber && (
              <span className="shrink-0">
                &nbsp;-&nbsp;
                {t('floor-by-number', {
                  floorNumber,
                })}
              </span>
            )}
          </p>
        </section>
        {status && <JxtTag {...status} size={JxtTagSizeEnum.SMALL} />}
      </div>
    </div>
  );
};

export default JxtResourceItem;
