import { JxtResourceStateEnum } from '@jooxter/utils';
import { JSX, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import JxtResourceItem from '../JxtResourceItem';
import { TResourceItem } from '../JxtResourceItem/types';
import { resourceStateSorter } from './helpers';
import { IJxtResourceListBloc } from './types';

const JxtResourceListBloc = ({
  locationId,
  locationName,
  floorName,
  floorId,
  resourceList,
  onSwitchToFloorPlan,
  onBook,
  onLocate,
  onClick,
  isAddin = false,
  selectedLocations = [],
  onToggleFavorite,
}: IJxtResourceListBloc): JSX.Element | null => {
  const { t } = useTranslation();
  const isResourceFree = (resource: TResourceItem) => {
    if (resource.status?.state === JxtResourceStateEnum.FREE) {
      return true;
    }

    // A zone will be BUSY if there is at least one booking, but it's available while the occupantsCount is less than the capacity
    if (resource.isZone) {
      if (resource.status?.state !== JxtResourceStateEnum.BUSY) {
        return false;
      }

      if (resource.status && typeof resource.status.occupantsCount === 'number') {
        return resource.status.occupantsCount < resource.capacity;
      }
    }

    return false;
  };
  const [showUnavailableResources, setShowUnavailableResources] = useState(true);
  const hasUnavailableResources = resourceList.some((resource) => !isResourceFree(resource));
  const hasOnlyUnavailableResources = resourceList.every((resource) => !isResourceFree(resource));
  const hasFloor = floorName !== undefined;

  const sortedFilteredResources = useMemo(
    () =>
      resourceList
        .filter((resource) => {
          if (!showUnavailableResources) {
            return resource.status?.state === JxtResourceStateEnum.FREE;
          }
          return true;
        })
        .sort(resourceStateSorter),
    [resourceList, showUnavailableResources]
  );

  if (!resourceList.length) {
    return null;
  }

  return (
    <section className="[&:not(last-child)]:mb-6">
      <header className="mb-3">
        <h2 className="text-title-l text-neutral-140 font-semibold flex items-center gap-4">
          <span>
            {locationName}
            {hasFloor && <>&nbsp;-&nbsp;{floorName} </>}
          </span>
          {hasFloor && !isAddin && (
            <JxtButton
              leftIcon="map-location-dot"
              variant={ButtonVariantEnum.Secondary}
              onClick={() =>
                floorId &&
                onSwitchToFloorPlan &&
                onSwitchToFloorPlan({ locationIds: [locationId], floorIds: [floorId] })
              }
            >
              {t('space-search.see-floorplan.button')}
            </JxtButton>
          )}
        </h2>
      </header>
      <div className="grid gap-3 mb-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4">
        {sortedFilteredResources.map((resource) => (
          <JxtResourceItem
            key={`${resource.id}-${resource.name}`}
            {...resource}
            onBook={onBook}
            onLocate={onLocate}
            onClick={onClick}
            isAddin={isAddin}
            selectedLocations={selectedLocations}
            onToggleFavorite={onToggleFavorite}
          />
        ))}
        {hasOnlyUnavailableResources && !showUnavailableResources && (
          <p className="text-neutral-80 text-body-s">{t('resource-tag-status.busy')}</p>
        )}
      </div>
      {hasUnavailableResources && (
        <JxtButton
          leftIcon={showUnavailableResources ? 'eye-slash' : 'eye'}
          variant={ButtonVariantEnum.Secondary}
          onClick={() => setShowUnavailableResources(!showUnavailableResources)}
        >
          {t(
            showUnavailableResources
              ? 'space-search.hide-unavailable-spaces.button'
              : 'space-search.show-unavailable-spaces.button'
          )}
        </JxtButton>
      )}
    </section>
  );
};

export default JxtResourceListBloc;
