import { createContext, useContext } from 'react';

export enum GTMCategoryContextEnum {
  SPACES = 'Spaces',
  RTSM = 'RTSM',
  ADDIN = 'Addin',
}

export const GTMCategoryContext = createContext<GTMCategoryContextEnum | null>(null);

export const useGTMCategoryContext = () => {
  return useContext(GTMCategoryContext);
};
