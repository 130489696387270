import { flip, offset, useFloating } from '@floating-ui/react';
import { IJxtPopover } from './types';
import { Popover } from '@headlessui/react';
import JxtContainer from '../JxtContainer';
import { ContainerSizeEnum, ContainerPaddingEnum } from '../JxtContainer/types';

const JxtPopover = ({ button, panel, config, classNames = '' }: IJxtPopover) => {
  const { refs, floatingStyles } = useFloating(
    config ?? {
      placement: 'bottom-start',
      strategy: 'fixed',
      middleware: [
        flip({
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
        }),
        offset(8),
      ],
    }
  );

  const Panel = panel;

  return (
    <Popover className={classNames}>
      <Popover.Button
        className="w-full relative ui-focus-visible:ring-2 rounded-lg focus:outline-none"
        ref={refs.setReference}
      >
        {button}
      </Popover.Button>
      <Popover.Panel ref={refs.setFloating} style={floatingStyles} className="z-10">
        {({ close }) => (
          <JxtContainer
            size={ContainerSizeEnum.Small}
            padding={ContainerPaddingEnum.Regular}
            className="min-w-[440px] shadow-elevation-3 flex flex-col"
          >
            <Panel onClose={() => close()} />
          </JxtContainer>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default JxtPopover;
