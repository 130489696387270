import { JxtSwitch, JxtSwitchOption, useIsSmallScreen } from '@jooxter/ui';
import { createGTMGAEvent, SCREEN_SIZE } from '@jooxter/utils';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../..';
import { SwitchViewEnum } from './types';
import { useShallow } from 'zustand/shallow';

const JxtViewSwitch = () => {
  const { t } = useTranslation();
  const [spaceSearchView, setSpaceSearchView] = useStore(
    useShallow((state) => [state.spaceSearchView, state.setSpaceSearchView])
  );
  const isSmallScreen = useIsSmallScreen(SCREEN_SIZE.SM);

  const tabs = useMemo(
    () =>
      Object.values(SwitchViewEnum).filter((view: SwitchViewEnum) => {
        if (isSmallScreen) {
          return view !== SwitchViewEnum.Scheduler;
        }

        return true;
      }),
    [isSmallScreen]
  );

  useEffect(() => {
    if (isSmallScreen && spaceSearchView === SwitchViewEnum.Scheduler) {
      setSpaceSearchView(SwitchViewEnum.List);
    }
  }, [isSmallScreen, spaceSearchView]);

  const createGTMEvent = (tab: SwitchViewEnum) => {
    createGTMGAEvent('Spaces', 'Switch view', tab.charAt(0).toUpperCase() + tab.toLowerCase().slice(1));
  };

  return (
    <div className="flex grow items-center justify-center">
      <span className="text-body-s font-medium text-neutral-140 hidden sm:inline-block mr-3">
        {t('space-search.switch-view.label')}
      </span>
      <JxtSwitch
        tabChanged={(tab) => {
          setSpaceSearchView(tab as SwitchViewEnum);
          createGTMEvent(tab as SwitchViewEnum);
        }}
      >
        {tabs.map((tab: SwitchViewEnum) => (
          <JxtSwitchOption key={`view-tab-${tab}`} checked={tab === spaceSearchView} value={tab} id={tab}>
            {t('space-search.switch-view', { view: tab })}
          </JxtSwitchOption>
        ))}
      </JxtSwitch>
    </div>
  );
};

export default JxtViewSwitch;
