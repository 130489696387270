import { IJxtFavoritesOnlyFilter } from './types';
import { JxtToggleSwitch } from '@jooxter/ui';

const JxtFavoritesOnlyFilter = ({ label, defaultValue, onChange }: IJxtFavoritesOnlyFilter) => {
  return (
    <div className="flex items-center justify-between gap-2">
      <span className="text-body-s text-neutral-140 font-medium">{label}</span>
      <JxtToggleSwitch defaultChecked={defaultValue} onChange={onChange} />
    </div>
  );
};

export default JxtFavoritesOnlyFilter;
