import { JxtWorkplaceTypeEnum } from '@jooxter/utils';
import { JxtWorkplaceTypeClasses } from './constants';
import { IJxtCalendarDayWorkplaceItem } from './types';
import clsx from 'clsx';

const JxtCalendarDayWorkplaceItem = ({ type, locationName, disabled, onClick }: IJxtCalendarDayWorkplaceItem) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={clsx(
        'flex flex-col p-2 items-center justify-center h-[72px] w-full rounded-lg overflow-hidden',
        disabled
          ? clsx(JxtWorkplaceTypeClasses[type].disabledColors, 'cursor-not-allowed')
          : clsx(JxtWorkplaceTypeClasses[type].colors, 'cursor-pointer')
      )}
    >
      <div className="size-5 flex items-center justify-center">
        <i className={clsx('fas', JxtWorkplaceTypeClasses[type].iconName)} />
      </div>
      {type === JxtWorkplaceTypeEnum.Office && locationName && (
        <p title={locationName} className="text-body-xs text-center truncate w-full">
          {locationName}
        </p>
      )}
    </div>
  );
};

export default JxtCalendarDayWorkplaceItem;
