import { JxtBookingStatusEnum, ResourceStateDtoStatusEnum } from '@jooxter/api';
import {
  FloorPlanPictoTypeEnum,
  JxtFloorPlanPictos,
  SearchResourcesFiltersToGetFloorResourcesParamsAdapter,
  useFetchFloorPlan,
  useFetchFloorPlanResources,
  useSearchBookings,
} from '@jooxter/core';
import { JxtNoFloorPlaceholder } from '@jooxter/ui';
import { fromISO } from '@jooxter/utils';
import { useMemo } from 'react';
import { ISpaceLocateResources } from './types';

const PADDING_X = 16;
const PADDING_Y = 24;

const SpaceLocateResources = ({ floorId, width, height, onClick, filter }: ISpaceLocateResources) => {
  const { floorPlan } = useFetchFloorPlan(floorId);
  const options = SearchResourcesFiltersToGetFloorResourcesParamsAdapter.adapt(filter);
  const refinedSize = useMemo(
    () => ({
      width: width - PADDING_X,
      height: height - PADDING_Y,
    }),
    [width, height]
  );
  const { resources } = useFetchFloorPlanResources(floorId, { ...options, visible: true });
  const busyResourcesIds = useMemo(() => {
    return resources?.filter((r) => r.state?.status === ResourceStateDtoStatusEnum.Busy).map((r) => r.id) ?? [];
  }, [resources]);
  const searchBookingsOptions = useMemo(() => {
    return {
      resourceId: busyResourcesIds,
      from: filter?.date?.from ? fromISO(filter?.date?.from) : undefined,
      to: filter?.date?.to ? fromISO(filter?.date?.to) : undefined,
      status: [JxtBookingStatusEnum.APPROVED, JxtBookingStatusEnum.REQUESTED],
    };
  }, [filter?.date?.from, filter?.date?.to, busyResourcesIds]);
  const { bookings } = useSearchBookings(searchBookingsOptions, busyResourcesIds.length > 0);

  if (!floorPlan?.image) {
    return <JxtNoFloorPlaceholder />;
  }

  return (
    !!floorPlan && (
      <div
        className="grow"
        style={{
          padding: `${PADDING_Y}px ${PADDING_X}px`,
        }}
      >
        <JxtFloorPlanPictos
          floorPlan={floorPlan}
          {...refinedSize}
          resources={resources ?? []}
          bookings={bookings ?? []}
          type={FloorPlanPictoTypeEnum.ResourceState}
          onClick={onClick}
        />
      </div>
    )
  );
};

export default SpaceLocateResources;
