export type GetLocationOptions = {
  name?: string;
  size?: number;
  type?: string;
};

export const defaultGetLocationOptions = {
  name: '',
  size: '25',
};

export const GetLocationOptionsToParamsAdapter = {
  adapt(options?: GetLocationOptions): URLSearchParams {
    const params = new URLSearchParams(defaultGetLocationOptions);

    if (!options) {
      return params;
    }

    if (options.name) {
      // using set here because this param already exists
      params.set('name', options.name);
    }

    if (options.size) {
      // using set here because this param already exists
      params.set('size', options.size.toString());
    }

    if (options.type) {
      // using set here because this param already exists
      params.set('type', options.type);
    }

    return params;
  },

  revert() {
    throw new Error('Method not implemented');
  },
};
