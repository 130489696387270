// eslint-disable-next-line import/named
import { DatePickerInput } from '@mantine/dates';
import clsx from 'clsx';
import { IDatePickerInput } from './types';

const JxtDatePickerInput = (props: IDatePickerInput) => {
  const baseInputClasses =
    'min-w-min text-center rounded-lg border text-body-s text-neutral-120 empty:text-neutral-80 bg-white disabled:border-neutral-10 disabled:text-neutral-60 focus:outline-none focus:ring-4 py-2';
  const errorClasses = props.invalid
    ? 'border-red-100 focus:ring-red-50 hover:border-red-30 focus:border-red-100'
    : 'hover:border-neutral-30 border-neutral-20 focus:ring-primary-30 focus:border-primary-100';

  const refinedProps = Object.fromEntries(
    Object.entries(props).filter(([key]) => !['className', 'invalid'].includes(key))
  );

  return (
    <div className={clsx('flex flex-col gap-2', props.className)}>
      <DatePickerInput
        {...refinedProps}
        popoverProps={{
          classNames: {
            dropdown: 'p-0 border-none bg-transparent',
          },
        }}
        classNames={{
          input: clsx(baseInputClasses, errorClasses),
          calendar: 'border border-neutral-10 rounded-lg shadow-elevation-2 bg-white p-4',
          calendarHeaderControl: 'hover:bg-neutral-10 text-neutral-140',
          calendarHeaderLevel: 'text-neutral-140 hover:bg:neutral-10',
          yearsListCell: 'rounded-lg hover:bg-neutral-10',
          monthsListCell: 'rounded-lg hover:bg-neutral-10 text-neutral-140',
          weekday: 'text-neutral-60',
          monthCell: 'rounded-lg text-neutral-140',
          day: 'hover:data-[outside=false]:bg-primary-20 data-[outside=true]:text-neutral-60 data-[selected=true]:bg-primary-100 data-[selected=true]:hover:bg-primary-110',
        }}
      />
    </div>
  );
};

export default JxtDatePickerInput;
