import { getThemeColorHex } from '@jooxter/tailwind-config';
import { IJxtDatePicker } from './types';
import { DatePicker } from '@mantine/dates';

const JxtDatePicker = ({ shadow = true, ...props }: IJxtDatePicker) => {
  const refinedProps = Object.fromEntries(Object.entries(props).filter(([key]) => !['shadow'].includes(key)));

  return (
    <DatePicker
      {...refinedProps}
      styles={() => ({
        calendar: shadow
          ? {
              borderRadius: '1rem',
              border: '1px solid',
              borderColor: getThemeColorHex('neutral10'),
              boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.17)',
              padding: '16px',
              backgroundColor: getThemeColorHex('white'),
            }
          : {},
        calendarHeaderControl: {
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        calendarHeader: {
          '& > button': {
            color: getThemeColorHex('neutral140'),
          },
        },
        calendarHeaderLevel: {
          color: getThemeColorHex('neutral140'),
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        yearsListCell: {
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
          '& > button:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        monthsListCell: {
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
          '& > button': {
            color: getThemeColorHex('neutral140'),
          },
          '& > button:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        weekday: {
          color: getThemeColorHex('neutral60'),
        },
        monthCell: {
          '&:hover': {
            borderRadius: '8px',
            backgroundColor: getThemeColorHex('primary20'),
          },
          '& > button': {
            color: getThemeColorHex('neutral140'),
          },
          '& > button[data-outside]': {
            color: getThemeColorHex('neutral60'),
          },
        },
        day: {
          '&[data-first-in-range]': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
          '&[data-last-in-range]': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          },
          '&[data-in-range]': {
            backgroundColor: getThemeColorHex('primary10'),
          },
          '&[data-selected]': {
            backgroundColor: getThemeColorHex('primary100'),
          },
          '&[data-selected]:hover': {
            backgroundColor: getThemeColorHex('primary110'),
          },
        },
      })}
    />
  );
};

export default JxtDatePicker;
