import {
  SearchWorkplacesFiltersToParamsAdapter,
  useFetchUser,
  useFetchUserIcsCalendar,
  useFetchWorkplaces,
  useUserWorkplacesToCalendarInputAdapter,
  useStore,
  useInvalidateWorkplaceQueries,
  useCanBeLocated,
} from '@jooxter/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColleaguesCalendar } from './Calendar';
import { ColleaguesFilters } from './Filters';
import { ButtonVariantEnum, JxtButton, JxtSearchBar, useIsSmallScreen } from '@jooxter/ui';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from '@mantine/hooks';
import {
  createGTMGAEvent,
  createGTMUpdateVirtualPathEvent,
  fromISO,
  IRange,
  now,
  SCREEN_SIZE,
  toISO,
} from '@jooxter/utils';
import { useShallow } from 'zustand/shallow';

export const Colleagues = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter, resetColleagueFilter] = useStore(
    useShallow((state) => [state.filter, state.setFilter, state.resetColleagueFilter])
  );
  const { user } = useFetchUser();
  const isMobile = useIsSmallScreen(SCREEN_SIZE.LG);
  const defaultFrom = isMobile ? now().startOf('day') : now().startOf('week');
  const defaultTo = isMobile ? now().endOf('day') : now().endOf('week').minus({ days: 2 });
  const [range, setRange] = useState<IRange>({
    from: filter?.colleague.date?.from ? fromISO(filter.colleague.date.from, user?.timezone) : defaultFrom,
    to: filter?.colleague.date?.to ? fromISO(filter.colleague.date.to, user?.timezone) : defaultTo,
  });
  const { calendar: icsCalendar } = useFetchUserIcsCalendar();
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();
  const [debounceOptions] = useDebouncedValue(filter.colleague, 300);
  const debounceOptionsAdapted = useMemo(
    () => SearchWorkplacesFiltersToParamsAdapter.adapt({ from: defaultFrom, to: defaultTo }, debounceOptions),
    [debounceOptions]
  );
  const { workplaces: fetchedPersonalWorkplaces } = useFetchWorkplaces(
    {
      from: debounceOptionsAdapted.from,
      to: debounceOptionsAdapted.to,
      favoritesOnly: false,
      globalSearch: user?.email,
    },
    false,
    true
  );
  const {
    workplaces: colleaguesWorkplaces,
    fetchNextPage,
    hasNextPage,
  } = useFetchWorkplaces({
    ...debounceOptionsAdapted,
    size: 25,
  });
  const ids = [
    ...(user?.id ? [user.id] : []),
    ...(colleaguesWorkplaces?.map((c) => c.id).filter((id) => id !== user?.id) ?? []),
  ];
  const bookingSearchRange: IRange = useMemo(() => {
    return {
      from:
        debounceOptionsAdapted.from.hasSame(now(), 'week') &&
        !debounceOptionsAdapted.from.hasSame(debounceOptionsAdapted.to, 'day')
          ? now().startOf('day')
          : debounceOptionsAdapted.from,
      to: debounceOptionsAdapted.to,
    };
  }, [debounceOptionsAdapted.from, debounceOptionsAdapted.to]);
  const canBeLocatedMap = useCanBeLocated(ids, bookingSearchRange);
  const navbarOffset = useMemo(() => {
    if (containerRef.current) {
      return containerRef.current.getBoundingClientRect().top;
    }
    return 0;
  }, [containerRef.current]);
  const personalWorkplacesAdapted = useUserWorkplacesToCalendarInputAdapter(
    fetchedPersonalWorkplaces ?? [],
    canBeLocatedMap
  );
  const personalWorkplaces = useMemo(() => {
    if (!personalWorkplacesAdapted.length) {
      return;
    }

    return personalWorkplacesAdapted[0];
  }, [personalWorkplacesAdapted]);

  const colleaguesWorkplacesAdapted = useUserWorkplacesToCalendarInputAdapter(
    colleaguesWorkplaces?.filter((c) => c.id !== user?.id) ?? [],
    canBeLocatedMap
  );

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('Collaborators');
    invalidateWorkplaceQueries();
    // run on mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChangeFilter({
      date: {
        from: toISO(range.from),
        to: toISO(range.to),
      },
    });
  }, [range.from, range.to]);

  const onChangeFilter = useCallback(
    (changeFilter: any) => {
      setFilter({
        ...filter,
        colleague: {
          ...filter.colleague,
          ...changeFilter,
        },
      });
    },
    [filter]
  );

  const onClickResetFilterButton = () => {
    createGTMGAEvent('Collaborators', 'Filter', 'Reset filters');
    resetColleagueFilter();
  };

  if (!user?.timezone) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className="flex max-lg:flex-col bg-white"
      style={{
        height: `calc(100vh - ${navbarOffset}px)`,
      }}
    >
      <div className="w-[280px] max-lg:hidden flex flex-col shrink-0 border-r border-neutral-20">
        <div className="overflow-y-auto flex flex-col grow">
          <div className="p-4 border-b border-neutral-20">
            <JxtSearchBar
              name="searchbar-colleagues"
              placeholder={t<string>('colleagues-placeholder-search')}
              value={filter.colleague.globalSearch}
              onChange={(search: string) => onChangeFilter({ globalSearch: search })}
              onClick={() => createGTMGAEvent('Collaborators', 'Filter', 'Colleague name')}
            />
          </div>
          <ColleaguesFilters filter={filter} onChangeFilter={onChangeFilter} user={user} />
        </div>
        <div className="flex flex-col p-4 border-t border-neutral-20">
          <JxtButton variant={ButtonVariantEnum.Secondary} onClick={onClickResetFilterButton}>
            {t('colleagues-reset-button')}
          </JxtButton>
        </div>
      </div>
      <div className="grow lg:bg-background h-full">
        <ColleaguesCalendar
          user={user}
          filter={filter}
          range={range}
          onChangeFilter={onChangeFilter}
          onRangeChange={setRange}
          timezone={user.timezone}
          personalWorkplaces={personalWorkplaces}
          colleaguesWorkplaces={colleaguesWorkplacesAdapted}
          icsCalendar={icsCalendar}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </div>
    </div>
  );
};
