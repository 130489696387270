import { BookingRequest, BookingRequestVisibilityEnum, Preferences, ResourceTypeDto, User } from '@jooxter/api';
import { fromISO, IQuickTimeSlot, JxtEventColorsEnum, JxtBookingVisibilityEnum, MID_DAY } from '@jooxter/utils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWorkHours } from '../../hooks/services/useWorkHours';
import { useFetchResourceTypes } from '../../queries';

export const useCreateAutomaticBooking = (
  user?: User | null,
  preferences?: Preferences | null
): {
  createAutomaticBooking: (start: string, end: string) => BookingRequest | null;
  resourceTypes: ResourceTypeDto[] | undefined;
} => {
  const { resourceTypes } = useFetchResourceTypes();
  const { t } = useTranslation();
  const { getDayHours, getMorningHours, getAfternoonHours } = useWorkHours(user?.id);

  const createAutomaticBooking = useCallback(
    (start: string, end: string): BookingRequest | null => {
      if (!(user && preferences?.resource)) {
        return null;
      }
      const resourceType = resourceTypes?.find((rtype) => rtype.id === preferences.resource?.resourceTypeId);
      let slot: IQuickTimeSlot | null;
      const workplaceStart = fromISO(start);
      const workplaceEnd = fromISO(end);
      const midDay = workplaceStart.set({
        hour: MID_DAY,
        minute: 0,
        second: 0,
      });

      if (workplaceEnd <= midDay) {
        slot = getMorningHours(workplaceStart);
      } else if (workplaceStart >= midDay) {
        slot = getAfternoonHours(workplaceEnd);
      } else {
        slot = getDayHours(workplaceStart);
      }

      if (!(slot && slot.start && slot.end)) {
        return null;
      }

      if (slot.start >= slot.end) {
        return null;
      }

      return {
        summary: t<string>('booking-form.default-summary', {
          organizerFirstName: user.firstname,
          organizerLastName: user.lastname,
        }),
        description: '',
        start: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dateTime: slot.start.toUTC().toISO()!,
        },
        end: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dateTime: slot.end.toUTC().toISO()!,
        },
        color: (preferences.defaultBookingColor?.desk as unknown as string) ?? JxtEventColorsEnum.Green,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizerId: user.id!,
        resourceId: preferences.resource.id,
        visibility:
          (preferences.defaultBookingVisibility?.desk as unknown as BookingRequestVisibilityEnum) ??
          JxtBookingVisibilityEnum.PUBLIC,
        options: [],
        physicalAttendees: 1,
        attendees: {
          external: [],
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          internal: resourceType?.allowOverlap ? [user.id!] : [],
        },
        participationStatusAttendees: [],
      };
    },
    [resourceTypes, getDayHours, getMorningHours, getAfternoonHours, preferences]
  );

  return { createAutomaticBooking, resourceTypes };
};
