/* eslint-disable react/prop-types */
import { ErrorMessage } from '@hookform/error-message';
import { reverseLangMapping } from '@jooxter/i18n';
import { getDateTimeFromDateTimeAndStandardTime, IDateTime, IDateTimeForm, now } from '@jooxter/utils';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import JxtDatePickerInput from '../JxtDatePickerInput';
import JxtTimeSelect from '../JxtTimeSelect';
import { getDateTimeFromJSDateAndStandardTime } from './helpers';
import { IJxtEventFormRange } from './types';
// eslint-disable-next-line import/named
import { DateValue } from '@mantine/dates';

const JxtEventFormRange = ({ sameDay: isSameDay, timezone }: IJxtEventFormRange) => {
  const { t, i18n } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext<IDateTimeForm>();

  const watchEnd = watch('end');
  const minDate = now().toJSDate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onStartDateChange = (e: DateValue, onChange: (...event: any[]) => void, value: IDateTime) => {
    if (!(e instanceof Date)) {
      return;
    }

    onDateChange(e, onChange, value);

    if (isSameDay) {
      setValue('end.date', getDateTimeFromJSDateAndStandardTime(e, watchEnd.time, timezone), { shouldValidate: true });
      trigger(['start.date', 'start.time', 'end.date', 'end.time']);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDateChange = (e: DateValue, onChange: (...event: any[]) => void, value: IDateTime) => {
    if (!(e instanceof Date)) {
      return;
    }

    onChange({ ...value, date: getDateTimeFromJSDateAndStandardTime(e, value.time, timezone) });
    trigger(['start.date', 'start.time', 'end.date', 'end.time']);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTimeChange = (e: string, onChange: (...event: any[]) => void, value?: IDateTime) => {
    if (!value) {
      return;
    }

    onChange({ time: e, date: getDateTimeFromDateTimeAndStandardTime(value.date, e) });
    trigger(['start.date', 'start.time', 'end.date', 'end.time']);
  };

  const gridClasses = isSameDay
    ? 'grid-cols-[min-content_auto] grid-rows-[repeat(2,_auto)]'
    : 'grid-cols-[min-content_min-content_auto] grid-rows-[repeat(3,_auto)]';

  return (
    <div className={clsx('grid items-center gap-x-3 -mb-2 gap-y-2', gridClasses)}>
      <i className="fas fa-fw fa-lg fa-clock text-neutral-60 col-span-1 col-start-1 row-start-1" />

      {!isSameDay && (
        <p className="text-body-s grow text-neutral-100 col-start-2 row-start-1">{t('datetime-picker.start')}</p>
      )}
      <div className="flex gap-2 items-center grow row-start-1">
        <Controller
          control={control}
          name="start"
          render={({ field: { onChange, value, onBlur } }) => (
            <>
              <JxtDatePickerInput
                className="grow"
                invalid={!!errors.start?.date}
                valueFormat="ll"
                value={value.date.toJSDate()}
                onChange={(e) => onStartDateChange(e, onChange, value)}
                locale={reverseLangMapping[i18n.language]}
                minDate={minDate}
                weekendDays={[]}
              />
              <div className="w-20">
                <JxtTimeSelect
                  invalid={!!errors.start?.time}
                  onBlur={onBlur}
                  selectedHour={value.time}
                  valueChange={(e) => onTimeChange(e, onChange, value)}
                />
              </div>
            </>
          )}
        />
        {isSameDay && (
          <Controller
            control={control}
            name="end"
            render={({ field: { onChange, value, onBlur } }) => (
              <div className="w-20">
                <JxtTimeSelect
                  onBlur={onBlur}
                  invalid={!!errors.end?.time}
                  selectedHour={value.time}
                  valueChange={(e) => onTimeChange(e, onChange, value)}
                />
              </div>
            )}
          />
        )}
      </div>
      {!isSameDay && (
        <>
          <p className="text-body-s grow text-neutral-100 col-start-2 row-start-2">{t('datetime-picker.end')}</p>
          <div
            className={clsx(
              'flex gap-2 items-center grow',
              isSameDay ? 'col-start-2 row-start-1' : 'col-start-3 row-start-2'
            )}
          >
            <Controller
              control={control}
              name="end"
              render={({ field: { onChange, value, onBlur } }) => (
                <>
                  <JxtDatePickerInput
                    className="grow"
                    invalid={!!errors.end?.date}
                    valueFormat="ll"
                    value={value.date.toJSDate()}
                    onChange={(e) => onDateChange(e, onChange, value)}
                    locale={reverseLangMapping[i18n.language]}
                    minDate={minDate}
                    weekendDays={[]}
                  />
                  <div className="w-20">
                    <JxtTimeSelect
                      onBlur={onBlur}
                      invalid={!!errors.end?.time}
                      selectedHour={value.time}
                      valueChange={(e) => onTimeChange(e, onChange, value)}
                    />
                  </div>
                </>
              )}
            />
          </div>
        </>
      )}
      <div className={clsx('col-start-2', isSameDay ? 'col-end-3' : 'col-end-4')}>
        <ErrorMessage
          name="start.date"
          errors={errors}
          render={({ message }) => <p className="text-red-100">{message && t<string>(message)}</p>}
        />
        <ErrorMessage
          name="start.time"
          errors={errors}
          render={({ message }) => <p className="text-red-100">{message && t<string>(message)}</p>}
        />
        {!isSameDay && (
          <>
            <ErrorMessage
              name="end.date"
              errors={errors}
              render={({ message }) => <p className="text-red-100">{message && t<string>(message)}</p>}
            />
            <ErrorMessage
              name="end.time"
              errors={errors}
              render={({ message }) => <p className="text-red-100">{message && t<string>(message)}</p>}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default JxtEventFormRange;
