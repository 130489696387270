import { IGetResourcesParameters } from '@jooxter/api';
import { FilterContextResource } from '../../store';
import { fromISO } from '@jooxter/utils';

export const SearchResourcesFiltersToGetResourcesParamsAdapter = {
  adapt(filters: FilterContextResource): IGetResourcesParameters {
    return {
      name: filters.resourceName,
      capacity: filters.capacity,
      resourceTypeId: filters.resourceTypesIds,
      labels: filters.labels,
      from: filters.now ? undefined : fromISO(filters.date?.from),
      to: filters.now ? undefined : fromISO(filters.date?.to),
      locationId: filters.location.locationIds,
      floorId: filters.location.floorIds,
      favoritesOnly: filters.favoritesOnly,
    };
  },
};
