import { User } from '@jooxter/api';

export interface IUseJooxterNavigation {
  user?: User;
}

export enum ComingFromEnum {
  URL = 'url',
  SESSION = 'session',
}
