export enum UserQueryKeys {
  GetMe = 'UserGetMe',
  GetTypicalWeek = 'UserGetTypicalWeek',
  GetOldScheduler = 'UserOldScheduler',
  GetWorkplaces = 'UserGetWorkplaces',
  GetMyWorkplaces = 'UserGetMyWorkplaces',
  GetFavoritesWorkplaces = 'UserGetFavoritesWorkplaces',
  GetMyPreferences = 'UserGetMyPreferences',
  GetMyNotifications = 'UserGetMyNotifications',
  GetUserById = 'UserGetUserById',
  GetUsersSearch = 'UserGetUsersSearch',
  PostUserIcsCalendar = 'UserPostUserIcsCalendar',
  GetUserLoginDetails = 'GetUserLoginDetails',
}
