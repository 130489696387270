import {
  BookingNotificationSettings,
  InvitationNotificationSettings,
  WorkplaceNotificationSettings,
  OwnerNotificationSettings,
  WorkflowNotificationSettings,
  NotificationChannel,
  NotificationSettings,
} from '@jooxter/api';
import { JxtAccordion, JxtNotificationUserListItem } from '@jooxter/ui';
import { useTranslation } from 'react-i18next';
import { useMutateUserNotifications } from '../../mutations';
import { IJxtNotificationUserList } from './types';
import { createGTMGAEvent, updateAllNotificationsByType } from '@jooxter/utils';

const JxtNotificationUserList = ({ userId, notifications }: IJxtNotificationUserList) => {
  const { t } = useTranslation();
  const { mutate: mutateNotifications } = useMutateUserNotifications();

  const getIcon = (key: string): string => {
    switch (key) {
      case 'booking':
        return 'calendar';
      case 'invitation':
        return 'envelope-open';
      case 'owner':
        return 'cube';
      case 'workflow':
        return 'info-circle';
      case 'workplace':
        return 'users';
      default:
        return '';
    }
  };

  const pushGTM = () => {
    createGTMGAEvent('Profile', 'Notifications preferences', 'All Mobile');
  };

  const emailGTM = () => {
    createGTMGAEvent('Profile', 'Notifications preferences', 'All Web');
  };

  const shouldSetAllToFalseIfNeeded = (
    keyToCompare: string,
    channel: keyof NotificationChannel,
    value: boolean
  ): boolean => {
    if (value) {
      return false;
    }

    let reducedValue = true;

    Object.entries(notifications)
      .filter(([key]) => key !== 'global')
      .map(
        ([, value]) =>
          value as
            | BookingNotificationSettings
            | InvitationNotificationSettings
            | WorkplaceNotificationSettings
            | OwnerNotificationSettings
            | WorkflowNotificationSettings
      )
      .forEach((category) => {
        Object.entries(category)
          .filter(([key]) => key !== keyToCompare)
          .map(([, value]) => value)
          .forEach((notification: NotificationChannel) => {
            if (channel in notification) {
              reducedValue = reducedValue && !(notification as { mail?: boolean; push?: boolean })[channel];
            }

            if (!reducedValue) {
              return;
            }
          });
      });

    return reducedValue;
  };

  const handleClickAllNotifications = (type: 'push' | 'mail') => {
    if (notifications['global']?.[type] !== undefined) {
      if (!notifications['global'][type]) {
        type === 'push' ? pushGTM() : emailGTM();
      }
      const clone = { ...notifications };
      mutateNotifications({
        id: userId,
        payload: updateAllNotificationsByType(clone, type, !notifications['global'][type]),
      });
    }
  };

  const handleClickSingleNotification = (
    type: 'push' | 'mail',
    subNotif: any,
    key: keyof NotificationSettings,
    subKey: string
  ) => {
    if (!subNotif[type]) {
      type === 'push' ? pushGTM() : emailGTM();
    }
    const payload = {
      [key]: {
        [subKey]: {
          [type]: !subNotif[type],
        },
      },
    } as NotificationSettings;

    if (!subNotif[type]) {
      payload['global'] = {
        [type]: !subNotif[type],
      } as NotificationChannel;
    }

    if (shouldSetAllToFalseIfNeeded(subKey, type, !subNotif[type])) {
      payload['global'] = {
        [type]: false,
      } as NotificationChannel;
    }

    mutateNotifications({
      id: userId,
      payload,
    });
  };

  const getNotificationAccordions = () => {
    const notificationKeys = Object.keys(notifications)
      .sort()
      .filter((key) => key !== 'global') as (keyof NotificationSettings)[];

    const result = [];
    if (notifications['global']) {
      result.push(
        <div key="global" className="py-4">
          <JxtNotificationUserListItem
            title={t('notification-settings.global')}
            isMobileActive={notifications['global']['push']}
            isEmailActive={notifications['global']['mail']}
            onClickMobile={() => handleClickAllNotifications('push')}
            onClickEmail={() => handleClickAllNotifications('mail')}
          />
        </div>
      );
    }
    result.push(
      notificationKeys.map((key) => {
        const notification = notifications[key];
        if (typeof notification === 'object') {
          return (
            <div key={key} className="border-t border-neutral-20 py-4">
              <JxtAccordion icon={getIcon(key)} title={t(`notification-settings.title.${key}`)}>
                <div className="flex flex-col gap-8">
                  {Object.keys(notification).map((subKey) => {
                    // eslint-disable-next-line
                    // @ts-ignore
                    const subNotif = notification[subKey];
                    if (typeof subNotif === 'object' && subNotif !== null) {
                      return (
                        <JxtNotificationUserListItem
                          key={`${key}-${subKey}`}
                          title={t(`notification-settings.subtitle.${key}.${subKey}`)}
                          description={t<string>(`notification-settings.description.${key}.${subKey}`)}
                          isMobileActive={subNotif['push']}
                          isEmailActive={subNotif['mail']}
                          onClickMobile={() => handleClickSingleNotification('push', subNotif, key, subKey)}
                          onClickEmail={() => handleClickSingleNotification('mail', subNotif, key, subKey)}
                        />
                      );
                    }
                  })}
                </div>
              </JxtAccordion>
            </div>
          );
        }
        return null;
      })
    );
    return result;
  };

  return <div className="w-full">{getNotificationAccordions()}</div>;
};

export default JxtNotificationUserList;
