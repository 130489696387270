export enum JxtAttendeeOptionTypeEnum {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  NEW_EXTERNAL = 'NEW_EXTERNAL',
}

export type JxtAttendeeOption = {
  type: JxtAttendeeOptionTypeEnum;
  id?: number;
  text: string;
};

export interface IJxtAttendeeCombobox {
  onAttendeeSelect?: (value: JxtAttendeeOption) => void;
  placeholder?: string;
  hasErrors?: boolean;
}
