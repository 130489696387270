import { useFetchUser, ONBOARDING_ROUTE_START } from '@jooxter/core';
import { Navigate, Outlet } from 'react-router';
import { JxtLoader } from '@jooxter/ui';
import { JSX } from 'react';

export const OnboardingDoneGuard = (): JSX.Element => {
  const { user } = useFetchUser();

  if (!user) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <JxtLoader />
      </div>
    );
  }

  return user.setupDone ? <Outlet /> : <Navigate to={ONBOARDING_ROUTE_START} replace />;
};
