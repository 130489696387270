import { clsx } from 'clsx';

export interface IUseJxtInputClass {
  type: 'text' | 'password' | 'email' | 'tel';
  leftIcon?: string;
  hasErrors: boolean;
  multiLines?: boolean;
  readonly?: boolean;
}

export const getJxtInputClass = ({
  type,
  leftIcon,
  hasErrors = false,
  multiLines = false,
  readonly = false,
}: IUseJxtInputClass) => {
  const baseClasses =
    'w-full rounded-lg border text-body-s text-neutral-120 disabled:border-neutral-10 disabled:text-neutral-60 disabled:cursor-not-allowed focus:outline-none focus:ring-4 py-2';
  const typeClasses = type === 'password' ? 'pr-10' : 'pr-2';
  const leftIconClasses = leftIcon ? 'pl-10' : 'pl-2';
  const errorClasses = hasErrors
    ? 'border-red-100 focus:ring-red-50 hover:border-red-30 focus:border-red-100'
    : 'hover:border-neutral-30 border-neutral-20 focus:ring-primary-30 focus:border-primary-100';
  const multiLinesClasses = multiLines ? 'h-16 block' : '';
  const readOnlyClasses = readonly ? 'bg-neutral-10' : '';

  const classes = clsx(baseClasses, typeClasses, leftIconClasses, errorClasses, multiLinesClasses, readOnlyClasses);

  return classes;
};
