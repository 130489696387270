import { BookingCompressed, BookingRequest, BookingRequestVisibilityEnum } from '@jooxter/api';
import { useCallback } from 'react';
import { useFetchBooking } from '../../queries';

export const useBookingIdToBookingRequestAdapter = (bookingId?: number) => {
  const { booking } = useFetchBooking(bookingId);

  const adapt = useCallback(
    (bookingCompressed: BookingCompressed): BookingRequest | undefined => {
      if (booking?.id !== bookingCompressed.id) {
        return;
      }

      const bookingRequest: BookingRequest = {
        summary: booking.summary,
        description: booking.description,
        start: booking.start,
        end: booking.end,
        color: booking.color,
        organizerId: booking.organizer.id,
        resourceId: booking.resource.id,
        visibility: booking.visibility as unknown as BookingRequestVisibilityEnum,
        physicalAttendees: booking.physicalAttendees,
        participationStatusAttendees: [],
        options: booking.options.map((option) => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          optionOverloadedId: option.optionId!,
          value: option.value?.content ?? '',
        })),
        attendees: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          internal: booking.attendees.internal.map((internal) => internal.id!),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          external: booking.attendees.external.map((external) => external.id!),
        },
      };

      return bookingRequest;
    },
    [booking]
  );

  return { adapt };
};
