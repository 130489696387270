import { IJxtUserShortDisplay } from './types';
import JxtAvatar from '../JxtAvatar';
import { JxtAvatarSizeEnum } from '../JxtAvatar/types';
import { useTranslation } from 'react-i18next';

const JxtUserShortDisplay = ({ firstname, lastname, picture }: IJxtUserShortDisplay) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-2 min-w-0">
      <JxtAvatar
        picture={picture}
        text={`${firstname.charAt(0)}${lastname.charAt(0)}`}
        alt={t('avatar-of', {
          name: `${firstname} ${lastname}`,
        })}
        size={JxtAvatarSizeEnum.S}
        className="shrink-0"
      />
      <div className="text-body-s text-neutral-120 overflow-hidden">
        <p className="truncate">
          <span className="max-lg:pr-1">{firstname}</span>
          <br className="max-lg:hidden" />
          <span>{lastname}</span>
        </p>
      </div>
    </div>
  );
};

export default JxtUserShortDisplay;
