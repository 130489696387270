import clsx from 'clsx';
import { getJxtWorkplacesIconClass } from './styles';
import { IJxtWorkplacesIcon } from './types';
import { JxtWorkplaceTypeEnum } from '@jooxter/utils';
import { JxtWorkplacesIconInfo } from './constants';

const JxtWorkplacesIcon = ({
  morningType = JxtWorkplaceTypeEnum.Unknown,
  afternoonType = JxtWorkplaceTypeEnum.Unknown,
  onClick,
}: IJxtWorkplacesIcon) => {
  const classes = getJxtWorkplacesIconClass({ morningType, afternoonType });

  const displayOneColorSquare = (roundedAngle: string, backgroundColor: string) => {
    return <div className={clsx('flex items-center justify-center', roundedAngle, backgroundColor)} />;
  };

  const displayTwoColorSquare = (roundedAngle: string) => {
    return (
      <div
        className={clsx(
          'bg-gradient-to-br via-50% to-0%',
          roundedAngle,
          JxtWorkplacesIconInfo[morningType].morningClasses,
          JxtWorkplacesIconInfo[afternoonType].afternoonClasses
        )}
      />
    );
  };

  const displayIcon = (workplaceType: JxtWorkplaceTypeEnum, classes?: string) => {
    return (
      <i
        className={clsx(
          'fas',
          JxtWorkplacesIconInfo[workplaceType].iconName,
          JxtWorkplacesIconInfo[workplaceType].iconColor,
          classes
        )}
      />
    );
  };
  return (
    <div className={classes} onClick={onClick}>
      {morningType === afternoonType ? (
        displayIcon(morningType)
      ) : (
        <>
          <div className="inline-grid grid-cols-2 size-9">
            {displayOneColorSquare('rounded-tl-lg', JxtWorkplacesIconInfo[morningType].iconBackgroundColor)}
            {displayTwoColorSquare('rounded-tr-lg')}
            {displayTwoColorSquare('rounded-bl-lg')}
            {displayOneColorSquare('rounded-br-lg', JxtWorkplacesIconInfo[afternoonType].iconBackgroundColor)}
          </div>
          {displayIcon(morningType, 'text-xs align-super absolute top-1 left-1')}
          {displayIcon(afternoonType, 'text-xs align-sub absolute bottom-1 right-1')}
        </>
      )}
    </div>
  );
};

export default JxtWorkplacesIcon;
