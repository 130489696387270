import { ExceptionalOpeningHours, Resource } from '@jooxter/api';
import { ExceptionalOpeningHoursTypeEnum, getIDateTimeFromDateTime, getIDateTimeFromISO } from '@jooxter/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchUser } from '../../../queries';
import {
  IExceptionalOpeningHoursFormDefaultValues,
  IExceptionalOpeningHoursFormPreset,
} from '../types/exceptional-opening-hours-form.types';

export const useExceptionalOpeningHoursFormDefaultValues = (
  preset?: IExceptionalOpeningHoursFormPreset,
  exceptionalOpeningHoursToEdit?: ExceptionalOpeningHours | null,
  resource?: Resource | null
) => {
  const { t } = useTranslation();
  const { user } = useFetchUser();

  const defaultValues: IExceptionalOpeningHoursFormDefaultValues | undefined = useMemo(() => {
    if (!user?.id) {
      return;
    }

    if (exceptionalOpeningHoursToEdit) {
      return {
        title:
          exceptionalOpeningHoursToEdit.title ??
          t('manage-space-form.default-title-for-slot', {
            creatorFirstName: user.firstname,
            creatorLastName: user.lastname,
          }),
        start: getIDateTimeFromISO(exceptionalOpeningHoursToEdit.timestampFrom, resource?.location.timezone),
        end: getIDateTimeFromISO(exceptionalOpeningHoursToEdit.timestampTo, resource?.location.timezone),
        type: exceptionalOpeningHoursToEdit.opened
          ? ExceptionalOpeningHoursTypeEnum.Opening
          : ExceptionalOpeningHoursTypeEnum.Closing,
      };
    }

    return {
      title: t('manage-space-form.default-title-for-slot', {
        creatorFirstName: user.firstname,
        creatorLastName: user.lastname,
      }),
      start: getIDateTimeFromDateTime(preset?.from, resource?.location.timezone),
      end: getIDateTimeFromDateTime(preset?.to, resource?.location.timezone),
      type: ExceptionalOpeningHoursTypeEnum.Closing,
    };
  }, [user, preset, exceptionalOpeningHoursToEdit, resource]);

  return defaultValues;
};
