export type TAdapterOptions = { title?: 'organizer' | 'resource' | 'summary'; editable?: boolean };

export interface Adapter<T, K> {
  adapt: (input: T, options?: TAdapterOptions) => K;
  revert: (output: K) => T;
}

export enum EventTypeEnum {
  BOOKING = 'BOOKING',
  EXCEPTIONAL_OPENING_HOURS = 'EXCEPTIONAL_OPENING_HOURS',
  FAKE = 'FAKE',
}
