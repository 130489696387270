import { IRange } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { createContext, useContext } from 'react';

export type TUserLocateModalInfos = { id: number; firstname: string; lastname: string; timezone?: string };

export interface IModalLocateUserContext {
  show: boolean;
  setShow: (show: boolean) => void;
  locateUserModalInfos: TUserLocateModalInfos;
  range?: IRange;
  setRange: (range: IRange) => void;
  setLocateUserModalInfos: (infos: TUserLocateModalInfos) => void;
  date?: DateTime;
  setDate: (date?: DateTime) => void;
}

export const ModalLocateUserContext = createContext<IModalLocateUserContext | null>(null);

export const useModalLocateUserContext = (): IModalLocateUserContext | null => {
  return useContext(ModalLocateUserContext);
};
