import { ResourceTypeDto, ResourceTypeDtoMetaTypeEnum } from '@jooxter/api';
import { TDeskOrParkingResourceTypeDto } from '@jooxter/utils';

export const getDeskOrParkingResourceTypes = (resourceTypes?: ResourceTypeDto[]): TDeskOrParkingResourceTypeDto[] =>
  resourceTypes
    ?.filter(
      (resourceType): resourceType is Omit<ResourceTypeDto, 'metaType'> & { metaType: ResourceTypeDtoMetaTypeEnum } =>
        !!resourceType.metaType
    )
    .filter((resourceType): resourceType is TDeskOrParkingResourceTypeDto =>
      [ResourceTypeDtoMetaTypeEnum.Desk, ResourceTypeDtoMetaTypeEnum.Parking].includes(resourceType.metaType)
    ) ?? [];

export const getResourceType = (id: number, resourceTypes?: ResourceTypeDto[]): ResourceTypeDto | undefined => {
  return resourceTypes?.find((rt) => rt.id === id);
};

export const getResourceTypesForMetaType = (
  metaType: ResourceTypeDtoMetaTypeEnum,
  resourceTypes?: ResourceTypeDto[]
): ResourceTypeDto[] => resourceTypes?.filter((rt) => rt.metaType === metaType) ?? [];
