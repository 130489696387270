import { useBookingForm, useFetchBooking, useRequiredParams } from '@jooxter/core';

const BookingForm = () => {
  const { bookingId } = useRequiredParams<{ bookingId: number }>();
  const { booking } = useFetchBooking(bookingId);
  const form = useBookingForm(booking);

  return <div className="w-[400px]">{form}</div>;
};

export default BookingForm;
