// eslint-disable-next-line import/named
import { OptionProps, SingleValue, components } from 'react-select';
import { useEffect, useState } from 'react';
import { ISearchUserGroupsParameters, UserGroupCompressed, UserGroupsService } from '@jooxter/api';
import { JxtAsyncSelect, SelectOption } from '@jooxter/ui';
import { IJxtTeamsSelect } from './types';
import { useFetchUserGroups } from '../../queries';
import { TextHighlight } from '@jooxter/utils';

const Option = (props: OptionProps<{ value: number; label: string }>) => {
  const userInput = props?.selectProps?.inputValue || '';
  const label = props?.data?.label || '';
  return (
    <components.Option {...props}>
      <div>
        {userInput?.length ? (
          label?.split(' ').length ? (
            <span>
              <TextHighlight text={label} searchTerm={userInput} />
            </span>
          ) : (
            label
          )
        ) : (
          label
        )}
      </div>
    </components.Option>
  );
};

export const JxtTeamsSelect = ({
  onTeamSelect,
  defaultTeamsIds,
  placeholder,
  label,
  helperText,
  noOptionsMessage,
  isMulti = false,
  isClearable = false,
  disabled = false,
}: IJxtTeamsSelect) => {
  const [userGroups, setUserGroups] = useState<UserGroupCompressed[]>([]);
  const { groups: defaultUserGroups } = useFetchUserGroups(defaultTeamsIds ?? []);
  const [value, setValue] = useState<{ value: number; label: string }[]>([]);

  useEffect(() => {
    if (defaultUserGroups.length > 0) {
      setValue(defaultUserGroups.map((g) => userGroupToOption(g)));
    }
  }, [defaultUserGroups]);

  const handleUserGroupSelection = (option: SingleValue<SelectOption<number>>) => {
    if (Array.isArray(option)) {
      const selectedUserGroup = option
        .map((g) => userGroups.find((group) => group.id === g.value))
        .filter((g): g is UserGroupCompressed => g !== undefined);
      setValue(selectedUserGroup.map((g) => userGroupToOption(g)));
      if (onTeamSelect) {
        onTeamSelect(selectedUserGroup);
      }
    }
  };

  const userGroupToOption = (g: UserGroupCompressed) => ({
    value: g.id as number,
    label: g.name,
  });

  async function loadOptions(search: string, loadedOptions: unknown, additional: { page?: string } | undefined) {
    const params: ISearchUserGroupsParameters = { name: search, page: additional?.page };

    const response = await UserGroupsService.search(params).then(async (res) => {
      setUserGroups([...userGroups, ...res.data]);

      return {
        data: res.data,
        nextPage: res.nextPage,
      };
    });

    return {
      options: response.data.map((g) => userGroupToOption(g)),
      hasMore: !!response.nextPage,
      additional: {
        page: response.nextPage,
      },
    };
  }

  return (
    <JxtAsyncSelect
      value={value}
      label={label}
      helperText={helperText}
      noOptionsMessage={noOptionsMessage}
      placeholder={placeholder}
      loadOptions={loadOptions}
      handleSelection={handleUserGroupSelection}
      components={{ Option }}
      isMulti={isMulti}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};
