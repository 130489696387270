import { IJxtWorkplaceCalendarUnit } from './types';
import clsx from 'clsx';
import { getJxtWorkplaceCalendarUnitClass } from './styles';
import { JxtWorkplaceTypeEnum } from '@jooxter/utils';
import { JxtWorkplaceTypeClasses } from './constants';

const JxtWorkplaceCalendarUnit = ({
  dataPeriod,
  type = JxtWorkplaceTypeEnum.Unknown,
  className = '',
  disabled = false,
  onClick,
}: IJxtWorkplaceCalendarUnit) => {
  const classes = getJxtWorkplaceCalendarUnitClass({ type, disabled });

  return (
    <button data-period={dataPeriod} className={clsx(classes, className)} onClick={onClick} disabled={disabled}>
      <i className={clsx('fas fa-fw', JxtWorkplaceTypeClasses[type].iconName)} />
    </button>
  );
};

export default JxtWorkplaceCalendarUnit;
