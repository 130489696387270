// eslint-disable-next-line import/named
import Select, { SelectInstance } from 'react-select';
import { BaseStyles } from './base-styles';
import { ThemeFactory } from './base-theme';
import { IJxtSelect, SelectOption } from './types';
import { useMemo, useRef } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useTranslation } from 'react-i18next';

//Needed to use Tailwind classes : https://react-select.com/styles#the-classnames-prop
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = useMemo(
    () =>
      createCache({
        key: 'with-tailwind',
        insertionPoint: document.querySelector('title')!,
      }),
    []
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

const JxtSelect = <T,>({
  defaultValue,
  value,
  options,
  placeholder,
  label,
  helperText,
  noOptionsMessage,
  handleSelection,
  styles,
  theme,
  onInputChange,
  isSearchable = true,
  disabled = false,
  formatOptionLabel,
  components,
  isClearable = false,
  classNames,
  className = '',
  noOptionsCustomMessage = '',
  isMulti = false,
  onBlur = () => {
    // noop
  },
}: IJxtSelect<T>) => {
  const { t } = useTranslation();
  const selectRef = useRef<SelectInstance<SelectOption<T>> | null>(null);

  return (
    <EmotionCacheProvider>
      <div className={className}>
        {label && <label className="block pb-1 text-neutral-120 text-title-m font-medium">{label}</label>}
        <Select
          ref={selectRef}
          isSearchable={isSearchable}
          defaultValue={defaultValue}
          value={value}
          noOptionsMessage={() => noOptionsMessage}
          loadingMessage={() => t('loading')}
          options={options}
          placeholder={placeholder}
          onChange={(e) => {
            selectRef.current?.blur();
            handleSelection(e);
          }}
          onInputChange={onInputChange}
          onBlur={onBlur}
          styles={{
            ...styles,
          }}
          classNames={{
            ...BaseStyles<T>(),
            ...classNames,
          }}
          isDisabled={disabled}
          theme={ThemeFactory(theme)}
          formatOptionLabel={formatOptionLabel}
          components={components}
          isClearable={isClearable}
          // eslint-disable-next-line
          // @ts-ignore
          isMulti={isMulti}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          noOptionsCustomMessage={noOptionsCustomMessage}
          menuPlacement="auto"
          minMenuHeight={400}
        />
        {helperText && <p className="text-neutral-80 text-body-xs pt-1">{helperText}</p>}
      </div>
    </EmotionCacheProvider>
  );
};

export default JxtSelect;
