/* eslint-disable react/prop-types */
import clsx from 'clsx';
import { getJxtInputNumberClass } from './styles';
import { IJxtInputNumber } from './types';
import { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Message } from 'react-hook-form';

const JxtInputNumber = ({
  name,
  value,
  placeholder,
  label,
  helperText,
  disabled,
  showErrorMessages = true,
  validationSchema,
  register,
  formState,
  getFieldState,
  onChange,
  errorStyle = false,
  className = '',
  min = 0,
  max,
  getValues,
  setValue,
}: IJxtInputNumber) => {
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [classes, setClasses] = useState<string>('');
  const fieldState = getFieldState(name, formState);

  useEffect(() => {
    setHasErrors(Object.keys(fieldState.error ?? {}).length > 0);
  }, [fieldState.error]);

  useEffect(() => {
    setClasses(getJxtInputNumberClass({ hasErrors: hasErrors || errorStyle }));
  }, [hasErrors, errorStyle]);

  const decrementValue = () => {
    if (!getValues(name) && Number(getValues(name)) !== 0) {
      setValue(name, min, { shouldValidate: true });
    } else if (min && Number(getValues(name)) <= min) {
      setValue(name, min, { shouldValidate: true });
    } else if (max && Number(getValues(name)) > max) {
      setValue(name, max, { shouldValidate: true });
    } else {
      setValue(name, Math.ceil(Number(getValues(name))) - 1, { shouldValidate: true });
    }
  };

  const incrementValue = () => {
    if (!getValues(name) && Number(getValues(name)) !== 0) {
      setValue(name, min, { shouldValidate: true });
    } else if (min && Number(getValues(name)) < min) {
      setValue(name, min, { shouldValidate: true });
    } else if (max && Number(getValues(name)) >= max) {
      setValue(name, max, { shouldValidate: true });
    } else {
      setValue(name, Math.floor(Number(getValues(name))) + 1, { shouldValidate: true });
    }
  };

  return (
    <div className={clsx(className, 'w-full')}>
      {label && <label className="block pb-1 text-neutral-120 text-title-m font-medium">{label}</label>}
      <div className="relative">
        <div
          className={clsx(
            disabled || (min && Number(getValues(name)) <= min)
              ? 'text-neutral-60 cursor-not-allowed'
              : 'text-neutral-120 cursor-pointer',
            'absolute inset-y-0 px-2.5 flex items-center'
          )}
          onClick={decrementValue}
        >
          <i className="fas fa-fw fa-minus" />
        </div>
        <input
          className={classes}
          type="number"
          defaultValue={value}
          placeholder={placeholder}
          disabled={disabled}
          aria-invalid={hasErrors}
          autoComplete="off"
          min={min}
          max={max}
          {...register(name, {
            ...validationSchema,
            onChange: onChange,
            setValueAs: (v) => (v === '' ? undefined : Number(v)),
          })}
        />
        <div
          className={clsx(
            disabled || (max && Number(getValues(name)) >= max)
              ? 'text-neutral-60 cursor-not-allowed'
              : 'text-neutral-120 cursor-pointer',
            'absolute inset-y-0 right-0 flex px-2.5 items-center'
          )}
          onClick={incrementValue}
        >
          <i className="fas fa-fw fa-plus" />
        </div>
      </div>
      {helperText && !hasErrors && (
        <p
          className={clsx(
            fieldState.isDirty && fieldState.isTouched && !fieldState.invalid ? 'text-green-80' : 'text-neutral-80',
            'text-body-xs',
            'pt-1'
          )}
        >
          {helperText}
          {fieldState.isDirty && fieldState.isTouched && !fieldState.invalid && (
            <i className="fas ml-1 fa-check text-green-80 fa-s" />
          )}
        </p>
      )}
      {showErrorMessages && fieldState?.error && (
        <ErrorMessage
          name={name}
          errors={{ [name]: fieldState.error }}
          render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1">{message}</p>}
        />
      )}
    </div>
  );
};

export default JxtInputNumber;
