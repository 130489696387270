import { invert } from 'lodash-es';
// eslint-disable-next-line import/named
import { InitOptions, createInstance } from 'i18next';
import { getJooxterLanguageSubdomain, isUserLang } from './url';
import { UserRequestExtendedLang } from '@jooxter/api';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import infos from '../../../apps/web/package.json';

dayjs.extend(LocalizedFormat);

export const langMapping: {
  [key: string]: string;
} = {
  en: 'en-US',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  es: 'es-ES',
  pt: 'pt-PT',
};

export const reverseLangMapping = invert(langMapping);

export const JxtI18nextOptionsFactory = {
  build(context: 'webapp' | 'mobile', env: 'staging' | 'prod' | 'development'): InitOptions {
    const randomNumber = Math.floor(Math.random() * 1000);
    const backendUrl =
      env === 'development'
        ? `https://s3.eu-west-1.amazonaws.com/translations.jooxter.com/staging/${context}/{{lng}}.vendor.json`
        : `https://translations.jooxter.com/${env}/${context}/{{lng}}.vendor.json?version=${infos.version}-${randomNumber}`;
    let currentLang = getJooxterLanguageSubdomain();

    if (!currentLang) {
      currentLang = isUserLang(window.navigator.language) ? window.navigator.language : UserRequestExtendedLang.En;
    }

    return {
      backend: {
        loadPath: backendUrl,
      },
      lng: langMapping[currentLang as UserRequestExtendedLang] ?? currentLang,
      fallbackLng: 'en-US',
      load: 'currentOnly',
    };
  },
};

export const JxtI18n = createInstance();

export const getLanguageList = (): Array<{ lang: UserRequestExtendedLang; label: string; icon: string }> => {
  return [
    {
      lang: UserRequestExtendedLang.En,
      label: 'lang.english',
      icon: '🇬🇧',
    },
    {
      lang: UserRequestExtendedLang.Fr,
      label: 'lang.french',
      icon: '🇫🇷',
    },
    {
      lang: UserRequestExtendedLang.De,
      label: 'lang.german',
      icon: '🇩🇪',
    },
    {
      lang: UserRequestExtendedLang.It,
      label: 'lang.italian',
      icon: '🇮🇹',
    },
    {
      lang: UserRequestExtendedLang.Es,
      label: 'lang.spanish',
      icon: '🇪🇸',
    },
    {
      lang: UserRequestExtendedLang.Pt,
      label: 'lang.portuguese',
      icon: '🇵🇹',
    },
  ];
};

export * from './url';
