import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateExceptionalOpeningHours,
  useDeleteExceptionalOpeningHours,
  useUpdateExceptionalOpeningHours,
} from '../../mutations';
import { ExceptionalOpeningHours, ISearchBookingsParameters, JxtBookingStatusEnum } from '@jooxter/api';
import { defaultOnError } from '../../mutations/default-error';
import { DataPeriodEnum, ExceptionalOpeningHoursTypeEnum, generateToast } from '@jooxter/utils';
import { useInvalidateExceptionalOpeningHoursQueries, useSearchBookings } from '../../queries';
import { useModalManageBookingsContext } from '../ModalManageBookingsContext';
import { IUseExceptionalOpeningHoursActions } from './types';
import { unionBy } from 'lodash-es';
import { JxtDeleteEventModal, JxtExceptionalOpeningHoursDetails } from '@jooxter/ui';

export const useExceptionalOpeningHoursActions = ({
  resourceId,
  range,
  additionalRange,
  offCanvasContext,
  exceptionalOpeningHours,
  exceptionalOpeningHoursDetails,
}: IUseExceptionalOpeningHoursActions) => {
  const { t } = useTranslation();
  const { mutate: mutationUpdate } = useUpdateExceptionalOpeningHours();
  const { mutate: mutationCreate } = useCreateExceptionalOpeningHours();
  const { mutate: mutationDelete } = useDeleteExceptionalOpeningHours();
  const { invalidateExceptionalOpeningHoursQueries } = useInvalidateExceptionalOpeningHoursQueries();
  const modalManageBookingsContext = useModalManageBookingsContext();
  const [showDeleteEventModal, setShowDeleteEventModal] = useState<boolean>(false);
  const searchBookingsBaseOptions: ISearchBookingsParameters = {
    resourceId: resourceId ? [resourceId] : undefined,
    status: [JxtBookingStatusEnum.APPROVED, JxtBookingStatusEnum.REQUESTED],
  };
  const { bookings, isLoading: isFetchingBookings } = useSearchBookings(
    { ...searchBookingsBaseOptions, from: range?.from, to: range?.to },
    !!resourceId
  );
  const { bookings: additionalBookings, isLoading: isFetchingAdditionalBookings } = useSearchBookings(
    {
      ...searchBookingsBaseOptions,
      from: additionalRange?.from,
      to: additionalRange?.to,
    },
    !!resourceId
  );
  const filteredBookings = useMemo(() => {
    if (!range && !additionalRange) {
      return [];
    }
    if (range && bookings && !additionalRange) {
      return bookings;
    }
    if (additionalRange && additionalBookings && !range) {
      return additionalBookings;
    }
    return unionBy(bookings, additionalBookings, 'id');
  }, [range, additionalRange, bookings, additionalBookings]);

  const cancel = useCallback((): void => {
    if (exceptionalOpeningHours && exceptionalOpeningHoursDetails && filteredBookings.length === 0) {
      setShowDeleteEventModal(true);
    } else if (exceptionalOpeningHours?.id) {
      deleteExceptionalOpeningHours(
        exceptionalOpeningHours.id,
        exceptionalOpeningHours.opened
          ? ExceptionalOpeningHoursTypeEnum.Opening
          : ExceptionalOpeningHoursTypeEnum.Closing
      );
    }
  }, [exceptionalOpeningHours, exceptionalOpeningHoursDetails, filteredBookings]);

  const deleteExceptionalOpeningHours = useCallback(
    (id: number, type: ExceptionalOpeningHoursTypeEnum) => {
      const onSuccess = () => {
        generateToast(
          type === ExceptionalOpeningHoursTypeEnum.Opening
            ? t<string>('exceptional-opening-cancelled')
            : t<string>('exceptional-closing-cancelled'),
          false
        );
      };

      const onError = () => {
        generateToast(
          type === ExceptionalOpeningHoursTypeEnum.Opening
            ? t<string>('exceptional-opening-cancellation-failed')
            : t<string>('exceptional-closing-cancellation-failed'),
          true
        );
      };

      const onSettled = () => {
        invalidateExceptionalOpeningHoursQueries([]);
      };

      if (
        !modalManageBookingsContext?.getters ||
        !modalManageBookingsContext?.setters ||
        isFetchingBookings ||
        isFetchingAdditionalBookings
      ) {
        return;
      }
      if (filteredBookings.length === 0) {
        return mutationDelete(id, { onSuccess, onError, onSettled });
      }
      modalManageBookingsContext?.setters?.setExistingBookings([]);
      modalManageBookingsContext?.setters?.setPeriodClicked(DataPeriodEnum.Day);
      modalManageBookingsContext?.setters?.setTitle(t('manage-closing.what-do-you-want-to-do'));
      modalManageBookingsContext?.setters?.setMutationToExecuteAfterConfirmation([
        ...(modalManageBookingsContext?.getters?.mutationToExecuteAfterConfirmation ?? []),
        {
          mutation: mutationDelete,
          args: id,
          onSuccess,
          onError,
          onSettled,
        },
      ]);
      modalManageBookingsContext?.setters?.showDeleteBookingsModal([
        ...(modalManageBookingsContext?.getters?.bookings ?? []),
        ...filteredBookings,
      ]);
    },
    [
      isFetchingBookings,
      isFetchingAdditionalBookings,
      mutationDelete,
      filteredBookings,
      invalidateExceptionalOpeningHoursQueries,
      modalManageBookingsContext?.getters.mutationToExecuteAfterConfirmation,
    ]
  );

  const update = useCallback(
    (
      { id, payload }: { id: number; payload: ExceptionalOpeningHours },
      onSuccess?: (exceptionalOpeningHours: ExceptionalOpeningHours[]) => void,
      onError?: (error: unknown) => void,
      onSettled?: () => void,
      onHideManageBookingModal?: () => void
    ) => {
      const error = (e: AxiosError) => {
        defaultOnError(e);
      };

      if (
        !modalManageBookingsContext?.getters ||
        !modalManageBookingsContext?.setters ||
        isFetchingBookings ||
        isFetchingAdditionalBookings
      ) {
        return;
      }
      if (filteredBookings.length === 0) {
        return mutationUpdate(
          { id, payload },
          {
            onSuccess: onSuccess,
            onError: onError || error,
            onSettled,
          }
        );
      }
      modalManageBookingsContext?.setters?.setExistingBookings([]);
      modalManageBookingsContext?.setters?.setPeriodClicked(DataPeriodEnum.Day);
      modalManageBookingsContext?.setters?.setTitle(t('manage-closing.what-do-you-want-to-do'));
      modalManageBookingsContext?.setters?.setOnHide(() => onHideManageBookingModal);
      modalManageBookingsContext?.setters?.setMutationToExecuteAfterConfirmation([
        ...(modalManageBookingsContext?.getters?.mutationToExecuteAfterConfirmation ?? []),
        {
          mutation: mutationUpdate,
          args: { id, payload },
          onSuccess: onSuccess,
          onError: onError || error,
          onSettled,
        },
      ]);
      modalManageBookingsContext?.setters?.showDeleteBookingsModal([
        ...(modalManageBookingsContext?.getters?.bookings ?? []),
        ...filteredBookings,
      ]);
    },
    [
      isFetchingBookings,
      isFetchingAdditionalBookings,
      mutationUpdate,
      filteredBookings,
      modalManageBookingsContext?.getters.mutationToExecuteAfterConfirmation,
    ]
  );

  const create = useCallback(
    (
      payload: ExceptionalOpeningHours,
      onSuccess?: (exceptionalOpeningHours: ExceptionalOpeningHours[]) => void,
      onError?: (error: AxiosError, exceptionalOpeningHours: ExceptionalOpeningHours) => void,
      onSettled?: () => void,
      onHideManageBookingModal?: () => void
    ) => {
      const error = (e: AxiosError) => {
        defaultOnError(e);
      };

      if (
        !modalManageBookingsContext?.getters ||
        !modalManageBookingsContext?.setters ||
        isFetchingBookings ||
        isFetchingAdditionalBookings
      ) {
        return;
      }
      if (filteredBookings.length === 0) {
        return mutationCreate(payload, {
          onSuccess: onSuccess,
          onError: onError || error,
          onSettled,
        });
      }
      modalManageBookingsContext?.setters?.setExistingBookings([]);
      modalManageBookingsContext?.setters?.setPeriodClicked(DataPeriodEnum.Day);
      modalManageBookingsContext?.setters?.setTitle(t('manage-closing.what-do-you-want-to-do'));
      modalManageBookingsContext?.setters?.setOnHide(() => onHideManageBookingModal);
      modalManageBookingsContext?.setters?.setMutationToExecuteAfterConfirmation([
        ...(modalManageBookingsContext?.getters?.mutationToExecuteAfterConfirmation ?? []),
        {
          mutation: mutationCreate,
          args: payload,
          onSuccess: onSuccess,
          onError: onError || error,
          onSettled,
        },
      ]);
      modalManageBookingsContext?.setters?.showDeleteBookingsModal([
        ...(modalManageBookingsContext?.getters?.bookings ?? []),
        ...filteredBookings,
      ]);
    },
    [
      isFetchingBookings,
      isFetchingAdditionalBookings,
      mutationCreate,
      filteredBookings,
      modalManageBookingsContext?.getters.mutationToExecuteAfterConfirmation,
    ]
  );

  const onConfirmDeletion = useCallback(() => {
    if (!exceptionalOpeningHours?.id) {
      setShowDeleteEventModal(false);
      return;
    }

    const { id } = exceptionalOpeningHours;
    const type = exceptionalOpeningHours?.opened
      ? ExceptionalOpeningHoursTypeEnum.Opening
      : ExceptionalOpeningHoursTypeEnum.Closing;

    const onSuccess = () => {
      invalidateExceptionalOpeningHoursQueries([]);
      generateToast(
        type === ExceptionalOpeningHoursTypeEnum.Opening
          ? t<string>('exceptional-opening-cancelled')
          : t<string>('exceptional-closing-cancelled'),
        false
      );
    };

    const onError = () => {
      generateToast(
        type === ExceptionalOpeningHoursTypeEnum.Opening
          ? t<string>('exceptional-opening-cancellation-failed')
          : t<string>('exceptional-closing-cancellation-failed'),
        true
      );
    };

    const onSettled = () => {
      invalidateExceptionalOpeningHoursQueries([id]);
      setShowDeleteEventModal(false);
      offCanvasContext?.close();
    };

    mutationDelete(id, {
      onSuccess,
      onError,
      onSettled,
    });
  }, [
    invalidateExceptionalOpeningHoursQueries,
    exceptionalOpeningHours?.id,
    exceptionalOpeningHours?.opened,
    mutationDelete,
  ]);

  const deleteEventModal = useMemo(
    () => (
      <JxtDeleteEventModal
        titleKey="do-you-want-to-delete-this-exceptional-hour"
        onSubmit={onConfirmDeletion}
        onCancel={() => {
          setShowDeleteEventModal(false);
          offCanvasContext?.close();
        }}
        show={showDeleteEventModal}
      >
        {exceptionalOpeningHoursDetails && (
          <JxtExceptionalOpeningHoursDetails showPlan={false} {...exceptionalOpeningHoursDetails} />
        )}
      </JxtDeleteEventModal>
    ),
    [exceptionalOpeningHours, showDeleteEventModal, onConfirmDeletion]
  );

  return {
    update,
    create,
    cancel,
    deleteEventModal,
  };
};
