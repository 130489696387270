import { apiErrorMessages } from './constants';
import { ErrorEnum } from './error.enum';

export const getApiErrorMessages = (code?: ErrorEnum): { title: string; message: string } => {
  if (!code || !apiErrorMessages[code]) {
    return { title: 'error.page-not-found', message: 'error.page-not-found-reason' };
  }

  return apiErrorMessages[code];
};
