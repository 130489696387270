import { JxtFloorPlan } from '@jooxter/ui';
import { useMemo } from 'react';
import {
  useBookingCompressedToIFloorPlanBookingAdapter,
  useResourceFloorDTOToIFloorPlanResourceAdapter,
} from '../../adapters';
import { useFloorPlan } from './hooks/useFloorPlan';
import { IJxtFloorPlanPictos } from './types';

const JxtFloorPlanPictos = ({ resources, width, height, floorPlan, type, bookings, onClick }: IJxtFloorPlanPictos) => {
  const { adapt: adaptBookings } = useBookingCompressedToIFloorPlanBookingAdapter(bookings);
  const { adapt: adaptResources } = useResourceFloorDTOToIFloorPlanResourceAdapter(resources, bookings);
  const adaptedResources = useMemo(() => adaptResources(), [adaptResources]);
  const adaptedBookings = useMemo(() => adaptBookings(), [adaptBookings]);
  const { pictos, backgroundImage } = useFloorPlan({
    resources: adaptedResources,
    width,
    floorPlan,
    type,
    bookings: adaptedBookings,
  });

  return (
    <JxtFloorPlan backgroundImage={backgroundImage} pictos={pictos} width={width} height={height} onClick={onClick} />
  );
};

export default JxtFloorPlanPictos;
