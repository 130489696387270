import { LocationMapDto, LocationMapDtoStatusEnum, Location } from '@jooxter/api';

export const getIcon = (status: LocationMapDtoStatusEnum): string => {
  if (status === LocationMapDtoStatusEnum.Free) {
    return 'images/map/pin_available.png';
  }

  if (status === LocationMapDtoStatusEnum.Busy) {
    return 'images/map/pin_busy.png';
  }

  if (status === LocationMapDtoStatusEnum.Closed) {
    return 'images/map/pin_closed.png';
  }

  if (status === LocationMapDtoStatusEnum.Unknown) {
    return 'images/map/pin_unknown.png';
  }

  return '';
};

export const createMarker = (
  location: LocationMapDto,
  timezoneFormatter?: (tz: string) => string,
  fullLocation?: Location
): google.maps.Marker | undefined => {
  if (!(location?.latitude && location?.longitude)) {
    return;
  }

  const marker = new window.google.maps.Marker({
    position: {
      lat: location.latitude,
      lng: location.longitude,
    },
    draggable: false,
    icon: location.status && getIcon(location.status),
  });
  const timezone = location.timezone
  const formattedTimezone = timezone ? timezoneFormatter ? timezoneFormatter(timezone) : timezone : undefined 

  marker.set('locationId', location.id);
  marker.set('locationName', location.name);
  marker.set('locationAddress', location.address);
  marker.set('locationTimezone', formattedTimezone);
  marker.set('locationUrl', fullLocation?.url);
  marker.set('locationHasFloors', fullLocation?.floors && fullLocation.floors.length > 0);

  return marker;
};

export const setupBounds = (map: google.maps.Map, markers: google.maps.Marker[]) => {
  if (markers.length === 1) {
    const bounds = new window.google.maps.LatLngBounds();
    const firstMarker = markers[0];
    const firstMarkerLat = firstMarker.getPosition()?.lat();
    const firstMarkerLng = firstMarker.getPosition()?.lng();

    if (typeof firstMarkerLat === 'number' && typeof firstMarkerLng === 'number') {
      const center = {
        lat: firstMarkerLat,
        lng: firstMarkerLng,
      };
      bounds.extend(center);
      const extendPos1 = {
        lat: center.lat - 0.001,
        lng: center.lng - 0.001,
      };
      bounds.extend(extendPos1);
      const extendPos2 = {
        lat: center.lat + 0.01,
        lng: center.lng + 0.01,
      };
      bounds.extend(extendPos2);
      map.fitBounds(bounds);
    }
  } else if (markers.length > 0) {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker: google.maps.Marker) => {
      const position = marker.getPosition();

      if (position) {
        bounds.extend(position);
      }
    });

    map.fitBounds(bounds);
  }
};
