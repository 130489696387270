// eslint-disable-next-line import/named
import { CalendarApi, DateSpanApi } from '@fullcalendar/core';
import DRange from 'drange';
import { floor, now, splitSpanBy } from '@jooxter/utils';
import { DateTime, Duration } from 'luxon';
import { useCallback } from 'react';

/** Past selection is forbidden (except in the current slot) */
export interface AllowSelectRules {
  /**
   * True: Disallow selection when at least one slot within the selection doesn't have at least one
   * inverse-background event.
   *
   * False: disable this rule.
   *
   * Defaults to False.
   */
  inverseBgEventMissing?: boolean;
  /**
   * True: Disallow selection if a least one slot within selection has a foreground event.
   *
   * False: disable this rule.
   *
   * Defaults to false.
   */
  fgEvent?: boolean;
  /** Called when span's start is between roundedFloor(now) and now. */
  startInNearPast?: (start: DateTime) => void;
}

export const useAllowSelect = (calendar?: CalendarApi) => {
  /**
   * Return true when span can be selected, false when cannot.
   */
  const allowSelect = useCallback(
    (span: DateSpanApi, allowRules: AllowSelectRules | (() => AllowSelectRules)): boolean => {
      const spanStart = DateTime.fromJSDate(span.start, { zone: calendar?.getOption('timeZone') });
      const finalRules = typeof allowRules === 'function' ? allowRules() : allowRules;

      const slotDuration = getSlotDuration();
      const min = floor(now(), slotDuration);

      if (spanStart < min.startOf('millisecond')) {
        return false;
      }

      if (min.startOf('millisecond') < spanStart && spanStart < now().startOf('millisecond')) {
        const cb = finalRules.startInNearPast;
        if (cb) {
          cb(spanStart);
        }
      }

      if (finalRules.fgEvent && hasBookingOverlap(span)) {
        return false;
      }

      if (finalRules.inverseBgEventMissing && spanInverseBgEventCoverage(span).some((cov) => cov === 0)) {
        return false;
      }

      return true;
    },
    [calendar]
  );

  const getSlotDuration = useCallback(
    () =>
      calendar?.getOption('slotDuration')
        ? Duration.fromISOTime(calendar?.getOption('slotDuration') as string)
        : Duration.fromObject(calendar?.view.getOption('slotDuration')),
    [calendar]
  );

  const hasBookingOverlap = useCallback(
    (span: DateSpanApi): boolean => {
      const spanStart = DateTime.fromJSDate(span.start, { zone: calendar?.getOption('timeZone') });
      const spanEnd = DateTime.fromJSDate(span.end, { zone: calendar?.getOption('timeZone') });
      const resourceId = span.resource?.id;
      for (const event of calendar?.getEvents() ?? []) {
        if (event.start && event.end) {
          const evStart = DateTime.fromJSDate(event.start, { zone: calendar?.getOption('timeZone') });
          const evEnd = DateTime.fromJSDate(event.end, { zone: calendar?.getOption('timeZone') });
          const resource = resourceId ? event.getResources()[0] : null;
          if (
            event.display !== 'inverse-background' &&
            spanStart < evEnd &&
            spanEnd > evStart &&
            (!resourceId || (resource !== null && resource.id === resourceId.toString()))
          ) {
            return true;
          }
        }
      }
      return false;
    },
    [calendar]
  );

  /** Count the presence of inverse-background events for each slot within the span */
  const spanInverseBgEventCoverage = useCallback(
    (span: DateSpanApi): number[] => {
      const resourceId = span.resource?.id;
      const slots = splitSpanBy(span, getSlotDuration());
      const bgEvents = calendar
        ?.getEvents()
        .filter(
          (ev) =>
            ev.display === 'inverse-background' &&
            (!resourceId || (ev.getResources()[0] !== null && ev.getResources()[0].id === resourceId.toString()))
        );

      const evRange = new DRange();
      bgEvents?.forEach((ev) => {
        if (ev.start && ev.end) {
          evRange.add(ev.start.getTime(), ev.end.getTime() - 1);
        }
      });

      return slots.map((slot) => {
        const bgEventsInsideSlot = evRange.clone().intersect(+slot.start, +slot.end - 1);
        return bgEventsInsideSlot.subranges().length;
      });
    },
    [calendar]
  );

  return { allowSelect };
};
