import { JxtLoginBase, LOGIN_ROUTE, useAuthContext } from '@jooxter/core';
import { ResetPasswordForm } from '../ResetPasswordForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoginService } from '@jooxter/api';
import { IUseResetPassword } from './types';
import { useTranslation } from 'react-i18next';
import { IResetPasswordFieldValues } from '../ResetPasswordForm/types';
import { confirmResetPassword } from 'aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router';

export const useResetPassword = ({ code, userId, token }: IUseResetPassword) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const { logout } = useAuthContext();
  const [isTokenValid, setIsTokenValid] = useState<boolean>();
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);
  const [isBadLink, setIsBadLink] = useState(false);
  const showTitleAndInstructions = (token && isTokenValid) || code;

  useEffect(() => {
    if (code) {
      return setIsReady(true);
    }

    if (userId && token) {
      LoginService.isTokenValid(parseInt(userId, 10), token)
        .then((result) => {
          setIsTokenValid(result);
        })
        .catch(() => {
          setIsBadLink(true);
        })
        .finally(() => setIsReady(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const invalidTokenAlert = useMemo(
    () => (
      <div className="alert alert-danger" role="alert">
        {isBadLink ? t('recover-password-invalid-token') : t('recover-password-invalid-link')}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBadLink]
  );

  const onResetPassword = useCallback(() => {
    logout();
    navigate(LOGIN_ROUTE);
  }, [logout, navigate]);

  const onSubmit = useCallback(
    (data: IResetPasswordFieldValues): Promise<Response> | Promise<Error> | Promise<void> => {
      const parsedUserId = userId && parseInt(userId, 10);
      const email = new URLSearchParams(search).get('email');

      if (parsedUserId && token) {
        return LoginService.resetPassword(parsedUserId, token, data.password).then<void>(onResetPassword);
      }

      if (!email) {
        return Promise.reject<Error>(new Error('No email found'));
      }

      if (code) {
        return confirmResetPassword({
          newPassword: data.password,
          username: email,
          confirmationCode: code,
        }).then<void>(onResetPassword);
      }

      return Promise.reject<Error>(new Error('Something went wrong'));
    },
    [userId, token, onResetPassword, code]
  );

  const innerPage = useMemo(() => {
    if (userId && token) {
      return isTokenValid ? <ResetPasswordForm callback={onSubmit} /> : invalidTokenAlert;
    }

    return <ResetPasswordForm callback={onSubmit} />;
  }, [userId, token, isTokenValid, onSubmit, invalidTokenAlert]);

  return {
    page: (
      <JxtLoginBase
        title={showTitleAndInstructions && t('recover-password-title')}
        instructions={showTitleAndInstructions && t('recover-password-instructions')}
      >
        {innerPage}
      </JxtLoginBase>
    ),
    isReady,
  };
};
