import { IJxtLocationFloorFilter } from './types';
import { useTranslation } from 'react-i18next';
import { JxtLocationSelect } from '../JxtLocationSelect';
import { JxtFloorSelect } from '../JxtFloorSelect';
import { FloorCompressed, GetFloorsSortParamsEnum, IGetFloorsParameters, LocationCompressed } from '@jooxter/api';
import { useEffect, useState } from 'react';
import { useFetchFloorsSearch, useFetchLocations } from '../../queries';
import { createGTMGAEvent } from '@jooxter/utils';
import { useGTMCategoryContext } from '../../hooks';

const baseFloorsParams: IGetFloorsParameters = {
  size: 100,
  sort: [GetFloorsSortParamsEnum.FloorNum],
};

const JxtLocationFloorFilter = ({ selectedOptions, onChange, isMulti = true }: IJxtLocationFloorFilter) => {
  const { t } = useTranslation();
  const gtmCategoryContext = useGTMCategoryContext();
  const [floorDisabled, setFloorDisabled] = useState<boolean>(false);
  const [floorsParams, setFloorsParams] = useState<IGetFloorsParameters | null>(null);
  const [autoFillFirstFloor, setAutoFillFirstFloor] = useState<boolean>(false);
  const { floors } = useFetchFloorsSearch(floorsParams ?? undefined, floorsParams !== null);
  const { locations } = useFetchLocations(selectedOptions.locationIds);
  const defaultLocationValue = locations
    .map((l) => ({ value: l.id, label: l.name }))
    .filter((l): l is { value: number; label: string } => !!l.value);

  const onLocationChange = (values: LocationCompressed[]) => {
    if (onChange) {
      if (values.length === 1 && values[0].id) {
        setFloorsParams({ ...baseFloorsParams, locationId: values[0].id });
        setAutoFillFirstFloor(true);
      } else {
        setFloorsParams(null);
      }
      const result = {
        locationIds: values.map((v) => v.id).filter((loc): loc is number => loc !== undefined),
        floorIds: values.length === 1 && values[0].floors?.length ? values[0].floors : [],
      };
      onChange(result);
    }
  };

  useEffect(() => {
    if (floors && autoFillFirstFloor) {
      if (selectedOptions.locationIds.length === 1) {
        const firstFloor = floors.data
          .filter((floor) => floor.number >= 0)
          .sort((a: FloorCompressed, b: FloorCompressed) => a.number - b.number)
          .slice(0, 1);
        onFloorChange(firstFloor);
        setAutoFillFirstFloor(false);
      }
    }
  }, [floors, autoFillFirstFloor, selectedOptions.locationIds.length]);

  useEffect(() => {
    setFloorDisabled(selectedOptions.locationIds.length === 0 || selectedOptions.locationIds.length > 1);
    if (selectedOptions.locationIds.length === 1 && selectedOptions.floorIds.length === 0) {
      setFloorsParams({ ...baseFloorsParams, locationId: selectedOptions.locationIds[0] });
    }
  }, [selectedOptions.floorIds.length, selectedOptions.locationIds]);

  const onFloorChange = (values: FloorCompressed[]) => {
    if (onChange) {
      const result = {
        ...selectedOptions,
        floorIds: values.map((v) => v.id).filter((floor): floor is number => floor !== undefined),
      };
      onChange(result);
    }
  };

  if (!gtmCategoryContext) {
    return null;
  }

  return (
    <div className="sm:px-6 py-4 border-b border-neutral-20">
      <span className="text-body-s text-neutral-140 font-medium">
        {t<string>('resourcesearch-label-location-label')}
      </span>
      <div className="mt-2">
        <JxtLocationSelect
          isMulti={isMulti}
          selectedOptions={selectedOptions.locationIds}
          onLocationSelect={(value) => {
            onLocationChange(value);
            createGTMGAEvent(gtmCategoryContext, 'Global filter', 'Building');
          }}
          defaultValue={defaultLocationValue}
        />
      </div>
      <div className="mt-2">
        <JxtFloorSelect
          locationId={selectedOptions.locationIds.length === 1 ? selectedOptions.locationIds[0] : null}
          isDisabled={floorDisabled}
          selectedOptions={selectedOptions.floorIds}
          onFloorSelect={(value) => {
            onFloorChange(value);
            createGTMGAEvent(gtmCategoryContext, 'Global filter', 'Floor');
          }}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};

export default JxtLocationFloorFilter;
