import { zodResolver } from '@hookform/resolvers/zod';
import { reverseLangMapping } from '@jooxter/i18n';
import {
  ButtonVariantEnum,
  JxtButton,
  JxtDatePicker,
  JxtSmartSlotContainerGapEnum,
  JxtSmartSlotSelectorContainer,
  JxtTimeSelect,
} from '@jooxter/ui';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { startBeforeEndSuperRefinerFactory } from './helpers';
import { ErrorMessage } from '@hookform/error-message';
import {
  IQuickTimeSlot,
  fromISO,
  fromStandardTime,
  getMinBetweenNextHourAndEndOfDay,
  getStandardTime,
  toISO,
  convertJSDateToTimezone,
  createGTMGAEvent,
} from '@jooxter/utils';
import { IJxtSpacesCalendar } from './types';
import { useDateTimeFormatter, useDefaultSlotGenerator } from '../../hooks';
import { DateTime } from 'luxon';
import { useDebouncedValue } from '@mantine/hooks';
import JxtCollapsibleFilter from '@jooxter/ui/src/components/JxtCollapsibleFilter';
import { useFoldedFilters } from '../../hooks/useFoldedFilters';

// Constants for field names to avoid magic strings and facilitate maintenance
const START = 'start';
const END = 'end';

const JxtSpacesCalendar = ({ user, value, onChange, onReset, scope }: IJxtSpacesCalendar) => {
  const { t, i18n } = useTranslation();

  // carefully tested debounce value to avoid the component to enter an infinite loop
  const [debouncedValue] = useDebouncedValue(value, 30);
  // State hooks for managing date range and picker values
  const [datePickerValue, setDatePickerValue] = useState<Date>(fromISO(debouncedValue.from).toJSDate());
  const [dateView, setDateView] = useState<Date>(fromISO(debouncedValue.from).toJSDate());
  const localDateView = useMemo(() => {
    return fromISO(dateView.toISOString()).setZone('system', { keepLocalTime: true }).toJSDate();
  }, [dateView]);
  const localDatePickerValue = useMemo(
    () => fromISO(debouncedValue.from).setZone('system', { keepLocalTime: true }).toJSDate(),
    [debouncedValue]
  );

  // Custom hook to generate default slots based on user timezone
  const { slots } = useDefaultSlotGenerator({
    start: toISO(fromISO(debouncedValue.from)),
    end: toISO(fromISO(debouncedValue.from)),
    timezone: user.timezone,
  });
  const { formatRange } = useDateTimeFormatter();

  // useForm hook for form management with Zod schema validation
  const {
    control: innerControl,
    formState,
    trigger,
    watch,
    setValue,
    resetField,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      start: getStandardTime(fromISO(debouncedValue.from)),
      end: getStandardTime(fromISO(debouncedValue.to)),
    },
    resolver: zodResolver(
      z
        .object({
          start: z.string(),
          end: z.string(),
        })
        .superRefine(startBeforeEndSuperRefinerFactory())
    ),
  });

  const touchedFields = formState.touchedFields;

  const { folded, onFoldChange } = useFoldedFilters(scope, 'date');
  useEffect(() => {
    reset(
      {
        start: getStandardTime(fromISO(debouncedValue.from)),
        end: getStandardTime(fromISO(debouncedValue.to)),
      },
      { keepTouched: true, keepDirty: true, keepErrors: true }
    );
  }, [debouncedValue.from, debouncedValue.to]);

  // Watchers for start and end time fields
  const watchStart = watch(START);
  const watchEnd = watch(END);
  const from = fromISO(watchStart).set({
    year: datePickerValue.getFullYear(),
    month: datePickerValue.getMonth() + 1,
    day: datePickerValue.getDate(),
  });
  const to = fromISO(watchEnd).set({
    year: datePickerValue.getFullYear(),
    month: datePickerValue.getMonth() + 1,
    day: datePickerValue.getDate(),
  });

  const convertDate = formatRange({ from, to });

  // Effect to reset form on initial load
  useEffect(() => {
    trigger().then((isValid) => {
      if (!isValid) {
        handleReset();
      }
    });
  }, []);

  useEffect(() => {
    trigger();
  }, [datePickerValue]);

  // Effect to update form values when external value changes
  useEffect(() => {
    if (!user.timezone) {
      return;
    }

    const newRange = { from: fromISO(debouncedValue.from), to: fromISO(debouncedValue.to) };

    if (getStandardTime(newRange.from) !== watchStart) {
      resetField(START, { defaultValue: getStandardTime(newRange.from) });
    }

    if (getStandardTime(newRange.to) !== watchEnd) {
      resetField(END, { defaultValue: getStandardTime(newRange.to) });
    }

    if (toISO(newRange.from) !== toISO(DateTime.fromJSDate(datePickerValue))) {
      setDateView(convertJSDateToTimezone(newRange.from.toJSDate(), user.timezone));
      setDatePickerValue(convertJSDateToTimezone(newRange.from.toJSDate(), user.timezone));
    }
  }, [debouncedValue.from, debouncedValue.to, user.timezone]);

  // Effects to ensure form fields are validated and range is updated based on user interactions
  useEffect(() => {
    if (!(START in touchedFields)) {
      return;
    }
    trigger(END).then((isValid) => {
      let end = watchEnd;
      if (formState.touchedFields.start && !formState.touchedFields.end) {
        end = getStandardTime(getMinBetweenNextHourAndEndOfDay(fromStandardTime(watchStart)));
        setValue(END, end, {
          shouldTouch: false,
        });
      }
      if (user?.timezone && isValid) {
        onChange(definesNewRange(convertJSDateToTimezone(datePickerValue, user.timezone), watchStart, end));
      }
    });
  }, [watchStart]);

  useEffect(() => {
    if (!(END in touchedFields)) {
      return;
    }
    trigger(START).then((isValid) => {
      if (formState.touchedFields.end && user?.timezone && isValid) {
        onChange(definesNewRange(convertJSDateToTimezone(datePickerValue, user.timezone), watchStart, watchEnd));
      }
    });
  }, [watchEnd]);

  // Callback for date picker changes
  const handleDateChange = (d: Date) => {
    if (!user.timezone) {
      return;
    }

    // DatePicker sends back a javascript Date, which is in the system timezone
    // so we first need to convert it to the user's timezone
    setDatePickerValue(convertJSDateToTimezone(d, user.timezone));

    if (formState.isValid) {
      onChange(definesNewRange(convertJSDateToTimezone(d, user.timezone), watchStart, watchEnd));
    } else {
      onChange(
        definesNewRange(
          convertJSDateToTimezone(d, user.timezone),
          getStandardTime(fromISO(debouncedValue.from)),
          getStandardTime(fromISO(debouncedValue.to))
        )
      );
    }
  };

  // Function to define a new date range based on start and end times
  const definesNewRange = (date: Date, start: string, end: string) => {
    const from = DateTime.fromJSDate(date);
    const dateTimeStart = fromStandardTime(start).set({
      day: from.day,
      month: from.month,
      year: from.year,
    });
    const dateTimeEnd = fromStandardTime(end).set({
      day: from.day,
      month: from.month,
      year: from.year,
    });

    return {
      from: toISO(dateTimeStart),
      to: toISO(dateTimeEnd),
    };
  };

  // Function to reset the form to the current date and time
  const handleReset = () => {
    reset(undefined, { keepErrors: false, keepTouched: false, keepDirty: false });
    onReset();
  };

  const onBackToNowClick = () => {
    handleReset();
    createGTMGAEvent('Spaces', 'Global filter', 'Back to now');
  };

  // Function to set start and end times from a selected slot
  const setStartEndFromSlot = (slot: IQuickTimeSlot) => {
    setValue(START, getStandardTime(slot.start), {
      shouldTouch: true,
    });
    setValue(END, getStandardTime(slot.end), {
      shouldTouch: true,
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <JxtCollapsibleFilter
        label={t<string>('date')}
        value={convertDate}
        defaultFolded={folded}
        hasError={!!formState.errors.start || !!formState.errors.end}
        onFoldChange={(e) => onFoldChange(e)}
      >
        <JxtDatePicker
          onChange={handleDateChange}
          onDateChange={setDateView}
          date={localDateView}
          value={localDatePickerValue}
          weekendDays={[]}
          withCellSpacing={false}
          locale={reverseLangMapping[i18n.language]}
          shadow={false}
          classNames={{
            month: 'w-full',
            calendarHeader: 'max-w-full',
            monthCell: 'text-center',
            decadeLevel: 'w-full',
            yearLevel: 'w-full',
            yearsList: 'w-full',
            monthLevel: 'w-full',
            monthsList: 'w-full',
            pickerControl: 'w-full',
            weekday: 'text-center',
            day: 'w-full',
          }}
        />
        <div>
          <div className="flex gap-[11px] items-center">
            <div className="flex-1">
              <Controller
                control={innerControl}
                name={START}
                render={({ field: { onChange, value, onBlur } }) => (
                  <JxtTimeSelect
                    invalid={!!formState.errors.start}
                    selectedHour={value}
                    valueChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
            <i className="fas fa-arrow-right text-neutral-120" />
            <div className="flex-1">
              <Controller
                control={innerControl}
                name={END}
                render={({ field: { onChange, value, onBlur } }) => (
                  <JxtTimeSelect
                    invalid={!!formState.errors.end}
                    selectedHour={value}
                    valueChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
          </div>
          <ErrorMessage
            name={START}
            errors={formState.errors}
            render={({ message }) => <p className="text-red-100 pt-1">{message && t<string>(message)}</p>}
          />
        </div>
        {slots.length > 0 && (
          <JxtSmartSlotSelectorContainer
            range={{ from: fromISO(debouncedValue.from), to: fromISO(debouncedValue.to) }}
            slots={slots}
            onSlotSelection={setStartEndFromSlot}
            gap={JxtSmartSlotContainerGapEnum.SMALL}
          />
        )}
        <div>
          <JxtButton variant={ButtonVariantEnum.LinkDark} onClick={onBackToNowClick}>
            {t('go-back-to-now')}
          </JxtButton>
        </div>
      </JxtCollapsibleFilter>
    </div>
  );
};

export default JxtSpacesCalendar;
