import { JxtNotificationUserList } from '@jooxter/core';
import { JxtContainer } from '@jooxter/ui';
import { useTranslation } from 'react-i18next';
import { INotificationSettingsContainer } from './types';

export const NotificationSettingsContainer = ({ userId, notifications }: INotificationSettingsContainer) => {
  const { t } = useTranslation();
  return (
    <JxtContainer id="notification-settings" title={t<string>('notification-settings-react')}>
      <JxtNotificationUserList userId={userId} notifications={notifications} />
    </JxtContainer>
  );
};
