import { JxtBookingParticipationStatusIconEnum } from '@jooxter/utils';

export enum JxtBookingAttendeeTypeEnum {
  Default = 'DEFAULT',
  External = 'EXTERNAL',
  CurrentUser = 'CURRENT_USER',
}

export interface IJxtBookingAttendee {
  name: string;
  picture?: string;
  checkout?: string;
  participationStatus?: JxtBookingParticipationStatusIconEnum;
  type?: JxtBookingAttendeeTypeEnum;
}
