import { useForm } from 'react-hook-form';
import JxtButton, { ButtonSizeEnum } from '../JxtButton';
import JxtInput from '../JxtInput';
import { IJxtClipboard } from './types';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';

export const JxtClipboard = ({ value }: IJxtClipboard) => {
  const { register, getFieldState, formState } = useForm();
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState<string>(t<string>('action-copy'));
  const [buttonIcon, setButtonIcon] = useState<string>('copy');
  const [hiddenButtonWidth1, setHiddenButtonWidth1] = useState<number>(0);
  const [hiddenButtonWidth2, setHiddenButtonWidth2] = useState<number>(0);
  const buttonWidth = useMemo(() => {
    const maxWidth = Math.max(hiddenButtonWidth1, hiddenButtonWidth2);
    return maxWidth;
  }, [hiddenButtonWidth1, hiddenButtonWidth2]);

  const buttonRef1 = useCallback((node: HTMLButtonElement | null) => {
    if (node) {
      setTimeout(() => {
        setHiddenButtonWidth1(node.getBoundingClientRect().width);
      }, 10);
    }
  }, []);

  const buttonRef2 = useCallback((node: HTMLButtonElement | null) => {
    if (node) {
      setTimeout(() => {
        setHiddenButtonWidth2(node.getBoundingClientRect().width);
      }, 10);
    }
  }, []);

  const handleButtonClick = () => {
    navigator.clipboard.writeText(value);
    setButtonText(t<string>('action-copied'));
    setButtonIcon('check');

    setTimeout(() => {
      setButtonText(t<string>('action-copy'));
      setButtonIcon('copy');
    }, 2000);
  };

  return (
    <>
      <div className="flex flex-row gap-x-2">
        <JxtInput
          name="clipboard"
          value={value}
          register={register}
          getFieldState={getFieldState}
          formState={formState}
          readonly={true}
        />

        <JxtButton
          leftIcon={buttonIcon}
          size={ButtonSizeEnum.Regular}
          onClick={handleButtonClick}
          style={{ minWidth: buttonWidth }}
        >
          <span className="whitespace-nowrap">{buttonText}</span>
        </JxtButton>
      </div>
      <div className="invisible absolute bottom-9999 right-9999">
        <JxtButton ref={buttonRef1} leftIcon="copy" size={ButtonSizeEnum.Regular}>
          <span className="whitespace-nowrap">{t('action-copy')}</span>
        </JxtButton>

        <JxtButton ref={buttonRef2} leftIcon="check" size={ButtonSizeEnum.Regular}>
          <span className="whitespace-nowrap">{t('action-copied')}</span>
        </JxtButton>
      </div>
    </>
  );
};
