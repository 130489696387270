const ADDIN_SUBDOMAIN = 'addin';

export const HardRedirectService = {
  redirect(url: string): void {
    window.location.assign(url);
  },

  getRedirectUrl(lang: string, pathname = window.location.pathname): string {
    const urlParts = window.location.origin.replace('https://', '').split('.');
    urlParts[0] = lang;

    return `https://${urlParts.join('.')}${pathname}`;
  },

  shouldRedirect(): boolean {
    return !import.meta.env.DEV && !window.location.hostname.includes(ADDIN_SUBDOMAIN);
  },
};
