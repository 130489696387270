import { Fragment, useEffect, useState } from 'react';
import { ExternalAttendee, ExternalAttendeesService, UserCompressed, UserService } from '@jooxter/api';
import { JxtInput } from '@jooxter/ui';
import { IJxtAttendeeCombobox, JxtAttendeeOption, JxtAttendeeOptionTypeEnum } from './types';
import { useFetchUser } from '../../queries';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';
import JxtDropdownItem from '@jooxter/ui/src/components/JxtDropdownItem';
import { Controller, useForm } from 'react-hook-form';
import { TextHighlight } from '@jooxter/utils';

export const JxtAttendeeCombobox = ({ onAttendeeSelect, placeholder, hasErrors = false }: IJxtAttendeeCombobox) => {
  const MIN_QUERY_LENGTH = 3;
  const name = 'attendees';
  const { t } = useTranslation();
  const [users, setUsers] = useState<Array<UserCompressed>>([]);
  const [externalAttendees, setExternalAttendees] = useState<Array<ExternalAttendee>>([]);
  const [query, setQuery] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [externalAttendeesLoaded, setExternalAttendeesLoaded] = useState(false);
  const [inputValid, setInputValid] = useState(true);
  const { user } = useFetchUser();

  const { register, formState, getFieldState, setValue, control, trigger, unregister } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    setIsLoaded(usersLoaded && externalAttendeesLoaded);
  }, [usersLoaded, externalAttendeesLoaded]);

  useEffect(() => {
    if (query.length < MIN_QUERY_LENGTH) {
      setUsersLoaded(false);
      setExternalAttendeesLoaded(false);
      setUsers([]);
      setExternalAttendees([]);
    } else {
      UserService.getUsersWithPagination({ globalSearch: query, size: 25 }).then(async (res) => {
        setUsers([...res.data]);
        setUsersLoaded(true);
      });
      ExternalAttendeesService.getExternalAttendeesWithPagination({ globalSearch: query, size: 25 }).then(
        async (res) => {
          setExternalAttendees([...res.data]);
          setExternalAttendeesLoaded(true);
        }
      );
    }
  }, [query]);

  useEffect(() => {
    if (
      query.length >= MIN_QUERY_LENGTH &&
      isLoaded &&
      users.length === 0 &&
      externalAttendees.length === 0 &&
      !/\S+@\S+\.\S+/.test(query)
    ) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [query, isLoaded, users, externalAttendees]);

  useEffect(() => {
    unregister(name);
    inputValid
      ? register(name)
      : register(name, { validate: { checkEmail: () => inputValid || t<string>('login-email-required') } });
    trigger(name);
  }, [inputValid]);

  const handleAttendeeSelection = (option: JxtAttendeeOption) => {
    if (getFieldState(name).invalid) {
      return;
    }
    if (onAttendeeSelect) {
      onAttendeeSelect(option);
    }
    setQuery('');
    setValue(name, '');
  };

  return (
    <Fragment>
      <div className="relative col-start-2 row-start-1">
        <Combobox onChange={handleAttendeeSelection}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Combobox.Input
                {...field}
                as={JxtInput}
                register={register}
                formState={formState}
                getFieldState={getFieldState}
                placeholder={placeholder}
                onChange={(event) => setQuery(event.target.value)}
                showErrorMessages={false}
                errorStyle={hasErrors}
              />
            )}
          />

          {query.length >= MIN_QUERY_LENGTH && isLoaded && (
            <Combobox.Options className="absolute z-10 max-h-[300px] w-full overflow-auto rounded-lg bg-white my-2 py-1 border border-neutral-30 shadow-elevation-2">
              {users.length === 0 && externalAttendees.length === 0 ? (
                <Combobox.Option
                  as={JxtDropdownItem}
                  value={{ type: JxtAttendeeOptionTypeEnum.NEW_EXTERNAL, text: query }}
                >
                  <p className="truncate">{query}</p>
                </Combobox.Option>
              ) : (
                users.map((u) => (
                  <Combobox.Option
                    as={JxtDropdownItem}
                    key={`user-${u.id}`}
                    value={{
                      type: JxtAttendeeOptionTypeEnum.INTERNAL,
                      id: u.id,
                      text: `${u.firstname} ${u.lastname}` + (u.id === user?.id ? ` (${t('you')})` : ''),
                    }}
                  >
                    <p className="truncate">
                      <TextHighlight
                        text={`${u.firstname} ${u.lastname} (${u.email})` + (u.id === user?.id ? ` (${t('you')})` : '')}
                        searchTerm={query}
                      />
                    </p>
                  </Combobox.Option>
                ))
              )}
              {externalAttendees.map((e) => (
                <Combobox.Option
                  as={JxtDropdownItem}
                  key={`external-${e.id}`}
                  value={{ type: JxtAttendeeOptionTypeEnum.EXTERNAL, id: e.id, text: e.email }}
                >
                  <p className="truncate">
                    <TextHighlight text={e.email} searchTerm={query} />
                  </p>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </Combobox>
      </div>
      {getFieldState(name).error?.message && typeof getFieldState(name).error?.message === 'string' ? (
        <p className="text-red-100 pt-1 col-start-2 row-start-2">{getFieldState(name).error?.message as string}</p>
      ) : (
        <p className="text-body-xs text-neutral-80 col-start-2 row-start-2 pt-1">
          {t('booking.attendees.search.helper')}
        </p>
      )}
    </Fragment>
  );
};
