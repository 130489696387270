import { JxtWorkplaceStatusButtonTypeEnum } from '@jooxter/utils';

export enum JxtWorkplaceStatusButtonStateEnum {
  Default = 'Default',
  Active = 'Active',
}

export interface IJxtWorkplaceStatusButton {
  type: JxtWorkplaceStatusButtonTypeEnum;
  state?: JxtWorkplaceStatusButtonStateEnum;
  onClick?: () => void;
  disabled?: boolean;
}
