import { useEffect, useState } from 'react';
import { useFloorMatrix } from './useFloorMatrix';
import { FloorPlanPictoTypeEnum, IUseFloorPlan } from './types';
import { usePictosGenerators } from './usePictosGenerator';
import { IPictoConfig } from '@jooxter/ui';

export const useFloorPlan = ({ resources, width, floorPlan, type, bookings }: IUseFloorPlan) => {
  const [pictos, setPictos] = useState<IPictoConfig[]>([]);
  const { matrix, locateResourceId } = useFloorMatrix(floorPlan?.matrix ?? '[]');
  const [backgroundImage, setBackgroundImage] = useState<HTMLImageElement>();
  const { resourcePictosGenerator, userPictosGenerator, bookingsPictosGenerator } = usePictosGenerators({
    width,
    resources,
    locateResourceId,
    floorPlan,
    bookings,
    backgroundImage,
    fixedPictoSize: type === FloorPlanPictoTypeEnum.Avatar,
  });

  useEffect(() => {
    if (floorPlan.image) {
      createImage(floorPlan.image).then((img) => setBackgroundImage(img));
    }
  }, [floorPlan.image]);

  const createImage = (imgUrl: string): Promise<HTMLImageElement> => {
    const img = document.createElement('img');
    img.src = imgUrl;

    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    if (!(matrix && floorPlan)) {
      // no pictos
      return;
    }

    if (type === FloorPlanPictoTypeEnum.ResourceState) {
      setPictos(resourcePictosGenerator.generate());
    }

    if (type === FloorPlanPictoTypeEnum.Avatar) {
      setPictos(userPictosGenerator.generate());
    }

    if (type === FloorPlanPictoTypeEnum.Pin) {
      setPictos(bookingsPictosGenerator.generate());
    }
  }, [matrix, resourcePictosGenerator, userPictosGenerator, bookingsPictosGenerator, width]);

  return { pictos, backgroundImage, bookingsPictosGenerator };
};
