import { JxtTagColorsEnum, JxtTagColorsInfo } from '@jooxter/utils/src/types';
import { JxtTagSizeEnum } from './types';
import { clsx } from 'clsx';

export interface IUseJxtTagClass {
  color: JxtTagColorsEnum;
  size: JxtTagSizeEnum;
  closeable?: boolean;
}

export const getJxtTagClass = ({ color, size, closeable }: IUseJxtTagClass) => {
  const baseClasses = 'max-w-max flex items-center justify-center';
  const closeableClasses = closeable ? 'gap-1' : '';
  const sizeClasses = {
    [JxtTagSizeEnum.SMALL]: 'px-2 py-1.5 rounded-[14px] text-body-xs font-medium',
    [JxtTagSizeEnum.LARGE]: 'px-3 py-2 rounded-[20px] text-body-s',
  };
  const colorClasses = clsx(JxtTagColorsInfo[color].backgroundColor, JxtTagColorsInfo[color].textColor);

  const classes = clsx(baseClasses, closeableClasses, sizeClasses[size], colorClasses);

  return classes;
};

export const getJxtTagCrossClass = ({ color, size }: IUseJxtTagClass) => {
  const baseClasses = 'flex items-center justify-center cursor-pointer';
  const sizeClasses = {
    [JxtTagSizeEnum.SMALL]: 'size-4',
    [JxtTagSizeEnum.LARGE]: 'size-5',
  };
  const colorClasses = clsx(JxtTagColorsInfo[color].crossColor, JxtTagColorsInfo[color].hoverCrossColor);

  const classes = clsx(baseClasses, sizeClasses[size], colorClasses);

  return classes;
};
