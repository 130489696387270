import { JxtResourceIconSizeEnum } from './types';
import clsx from 'clsx';

export interface IGetJxtResourceIconClass {
  size: JxtResourceIconSizeEnum;
  fixedWidth: boolean;
}

export const getJxtResourceIconClass = ({ size, fixedWidth }: IGetJxtResourceIconClass) => {
  const defaultClasses = 'flex items-center justify-center bg-neutral-10 shrink-0 text-neutral-120 rounded-lg';

  const sizeClasses = {
    S: fixedWidth ? 'size-10' : 'grow',
    M: fixedWidth ? 'size-[72px] fa-xl' : 'fa-xl grow',
    L: fixedWidth ? 'size-24 fa-2xl' : 'fa-2xl grow',
  };

  const classes = clsx(defaultClasses, sizeClasses[size]);

  return classes;
};
