import { forwardRef, Ref } from 'react';
import { IJxtRadioButton } from './types';
import clsx from 'clsx';

const JxtRadioButton = (
  { label, name, id, checked = false, disabled = false, onChange, value, className = '' }: IJxtRadioButton,
  ref: Ref<HTMLInputElement>
) => {
  return (
    <div className={clsx('inline-flex items-center', className)}>
      <label htmlFor={id} className={clsx('flex', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}>
        <div
          className={clsx(
            'relative h-4 w-4 shrink-0 appearance-none rounded-full border-2',
            disabled ? 'cursor-not-allowed border-neutral-60' : 'cursor-pointer border-neutral-140 hover:bg-neutral-20',
            label && 'my-1'
          )}
        >
          <input
            id={id}
            ref={ref}
            type="radio"
            name={name}
            onChange={onChange}
            value={value}
            defaultChecked={checked}
            disabled={disabled}
            className="sr-only peer"
          />
          <span className="pointer-events-none absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 opacity-0 peer-checked:opacity-100">
            <svg
              className={clsx('h-2 w-2 shrink-0', disabled ? 'fill-neutral-60' : 'fill-neutral-140')}
              viewBox="0 0 16 16"
            >
              <circle data-name="ellipse" cx="8" cy="8" r="8" />
            </svg>
          </span>
        </div>
        {label && (
          <span
            className={clsx(
              'text-body-m pl-2',
              disabled ? 'cursor-not-allowed text-neutral-60' : 'cursor-pointer text-neutral-140'
            )}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  );
};

export default forwardRef(JxtRadioButton);
