import { JxtBookingInfo, JxtBookingInfoContainer, JxtModal, JxtModalSizeEnum } from '@jooxter/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IJxtModalBookingsViolations } from './types';

const JxtModalBookingsViolations = ({ show, onHide, recurrence }: IJxtModalBookingsViolations) => {
  const [showModal, setShowModal] = useState<boolean>(show);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const closeModal = () => {
    setShowModal(false);
    onHide();
  };

  const displayViolations = () => {
    const violations = [];
    if (recurrence) {
      for (const [key, value] of Object.entries(recurrence.violations)) {
        violations.push(
          <JxtBookingInfoContainer key={`violation-${key}`}>
            <JxtBookingInfo title={{ title: recurrence.summary }} location={{ location: recurrence.resource.name }} />
            <div className="mt-4 text-neutral-100 text-body-s">{value}</div>
          </JxtBookingInfoContainer>
        );
      }
    }

    return violations;
  };

  return (
    <JxtModal
      header={{
        title: t('occurrences.violations.not-created.modal.title', {
          numberOfViolations: Object.keys(recurrence?.violations ?? {}).length,
        }),
      }}
      show={showModal}
      onHide={closeModal}
      size={JxtModalSizeEnum.S}
    >
      <div className="flex flex-col space-y-3">{displayViolations()}</div>
    </JxtModal>
  );
};

export default JxtModalBookingsViolations;
