import { JxtContainer } from '@jooxter/ui';
import { IProfilePictureContainer } from './types';
import { createGTMGAEvent } from '@jooxter/utils';
import { JxtProfilePictureUploader } from '@jooxter/core';

export const ProfilePictureContainer = ({ user }: IProfilePictureContainer) => {
  const onClickUpdatePictureButton = () => {
    createGTMGAEvent('Profile', 'Picture', 'Modify picture');
  };

  return (
    <JxtContainer>
      <div className="flex flex-col grow items-center gap-4">
        <JxtProfilePictureUploader user={user} onClick={onClickUpdatePictureButton} />
        <h1 className="text-title-xl text-neutral-140 font-semibold text-center">{`${user.firstname} ${user.lastname}`}</h1>
      </div>
    </JxtContainer>
  );
};
