import { useCallback, useMemo } from 'react';
import { FilterContextColleague, useStore } from '../../store';
import { useFetchLocations, useFetchUserGroups } from '../../queries';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../hooks/useDateTimeFormatter';
import { useShallow } from 'zustand/shallow';

type TChangeFilterParameter<T extends keyof FilterContextColleague> = Record<T, FilterContextColleague[T]>;

export const useColleagueFiltersToSelectedFiltersAdapter = () => {
  const [filter, setFilter] = useStore(useShallow((state) => [state.filter, state.setFilter]));
  const colleagueFilters = filter.colleague;
  const { t } = useTranslation();
  const { locations } = useFetchLocations(filter.colleague.locationIds);
  const { groups } = useFetchUserGroups(filter.colleague.groupIds);
  const { formatRange } = useDateTimeFormatter();

  const onChangeFilter = useCallback(
    <T extends keyof FilterContextColleague>(changeFilter: TChangeFilterParameter<T>) => {
      setFilter({
        ...filter,
        colleague: {
          ...filter.colleague,
          ...changeFilter,
        },
      });
    },
    [filter]
  );

  const adaptedFilters: { onRemove: () => void; value: string }[] = useMemo(() => {
    const result = [];

    if (colleagueFilters.favoritesOnly) {
      result.push({
        onRemove: () => onChangeFilter<'favoritesOnly'>({ favoritesOnly: false }),
        value: t<string>('selected-filters.favorites-only'),
      });
    }

    if (locations?.length) {
      locations.forEach((location) => {
        result.push({
          onRemove: () =>
            onChangeFilter<'locationIds'>({
              locationIds: locations
                .filter((l) => l.id !== location.id)
                .map((l) => l.id)
                .filter((l): l is number => !!l),
            }),
          value: location.name,
        });
      });
    }

    if (groups?.length) {
      groups.forEach((group) => {
        result.push({
          onRemove: () =>
            onChangeFilter<'groupIds'>({
              groupIds: groups
                .filter((g) => g.id !== group.id)
                .map((g) => g.id)
                .filter((g): g is number => !!g),
            }),
          value: group.name,
        });
      });
    }

    return result;
  }, [colleagueFilters, locations, groups, onChangeFilter, formatRange]);

  return adaptedFilters;
};
