import { JxtOffCanvasHeader } from '../JxtOffCanvasHeader';
import { Tab, Transition } from '@headlessui/react';
import { IJxtOffCanvas, offCanvasContainerEnum } from './types';
import { Fragment, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { JxtOffCanvasFooter } from '../JxtOffCanvasFooter';
import { leaveMobileAnimationDuration } from '../JxtModal';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import { SCREEN_SIZE } from '@jooxter/utils';
import clsx from 'clsx';
import { IModalAnimation } from '../JxtModal/types';
import { getOffCanvasPanelClasses } from './style';

const desktopAnimation = {
  enter: 'transform transition ease-in duration-200',
  enterFrom: 'translate-x-full',
  enterTo: 'translate-x-0',
  leave: 'transform transition ease-in duration-200',
  leaveFrom: 'translate-x-0',
  leaveTo: 'translate-x-full',
};

const mobileAnimation = {
  enter: 'transition ease-in-out duration-200 transform',
  enterFrom: 'translate-y-full',
  enterTo: 'translate-y-0',
  leave: `transition ease-in-out duration-${leaveMobileAnimationDuration} transform`,
  leaveFrom: 'translate-y-0',
  leaveTo: 'translate-y-full',
};

const MAIN_SELECTOR = '#root>:nth-child(2)';

const JxtOffCanvas = ({
  children,
  header,
  footer,
  show,
  onHide,
  container = offCanvasContainerEnum.Main,
  bodyClass = '',
  tabConfiguration,
}: IJxtOffCanvas) => {
  const isSmallScreen = useIsSmallScreen(SCREEN_SIZE.MD);
  const animation = useMemo<IModalAnimation>(
    () => (isSmallScreen ? mobileAnimation : desktopAnimation),
    [isSmallScreen]
  );
  const { classes } = getOffCanvasPanelClasses(isSmallScreen);
  const containerElement =
    container === offCanvasContainerEnum.Main ? document.querySelector(MAIN_SELECTOR) : document.body;

  useEffect(() => {
    containerElement &&
      containerElement instanceof HTMLElement &&
      containerElement.classList.add('relative', 'overflow-hidden');
  }, [containerElement]);

  useEffect(() => {
    if (isSmallScreen) {
      if (show) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    }
  }, [isSmallScreen, show]);

  const portalContent = useMemo(
    () =>
      isSmallScreen ? (
        <>
          <Transition.Child as={Fragment} {...animation}>
            <div className="fixed inset-0 bg-neutral-100 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-0 text-center md:items-center">
              <Transition.Child as={Fragment} {...animation}>
                {tabConfiguration ? (
                  <Tab.Group
                    defaultIndex={tabConfiguration?.defaultIndex}
                    selectedIndex={tabConfiguration?.selectedIndex}
                    onChange={tabConfiguration?.onChange}
                  >
                    <div className={classes}>
                      <JxtOffCanvasHeader {...header} onHide={onHide} />
                      <div className={clsx('px-6 py-4 flex flex-col grow', bodyClass)}>{children}</div>
                      {!!footer?.children && <JxtOffCanvasFooter {...footer} />}
                    </div>
                  </Tab.Group>
                ) : (
                  <div className={classes}>
                    <JxtOffCanvasHeader {...header} onHide={onHide} />
                    <div className={clsx('px-6 py-4 flex flex-col grow', bodyClass)}>{children}</div>
                    {!!footer?.children && <JxtOffCanvasFooter {...footer} />}
                  </div>
                )}
              </Transition.Child>
            </div>
          </div>
        </>
      ) : (
        <Transition.Child className={classes} {...animation}>
          {tabConfiguration ? (
            <Tab.Group
              defaultIndex={tabConfiguration?.defaultIndex}
              selectedIndex={tabConfiguration?.selectedIndex}
              onChange={tabConfiguration?.onChange}
            >
              <JxtOffCanvasHeader onHide={onHide} {...header} />
              <div className={clsx('px-6 py-4 flex flex-col grow', bodyClass)}>{children}</div>
              {!!footer?.children && <JxtOffCanvasFooter {...footer} />}
            </Tab.Group>
          ) : (
            <>
              <JxtOffCanvasHeader onHide={onHide} {...header} />
              <div className={clsx('px-6 py-4 flex flex-col grow', bodyClass)}>{children}</div>
              {!!footer?.children && <JxtOffCanvasFooter {...footer} />}
            </>
          )}
        </Transition.Child>
      ),
    [isSmallScreen, classes, animation, header, onHide, bodyClass, children, footer, tabConfiguration]
  );

  const content = useMemo(
    () => containerElement && createPortal(portalContent, containerElement),
    [portalContent, containerElement]
  );

  return <Transition show={show}>{content}</Transition>;
};

export default JxtOffCanvas;
