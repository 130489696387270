import { useTranslation } from 'react-i18next';
import { JxtButton } from '@jooxter/ui';

export const HomeStatisticsSoon = () => {
  const { t } = useTranslation();

  return (
    <div className="grow flex justify-center overflow-auto break-words">
      <div className="px-6 flex-col items-center gap-8 inline-flex justify-center">
        <i className="fas fa-chart-simple fa-2x text-neutral-60" />
        <div className="flex-col items-center gap-4 inline-flex">
          <p className="text-neutral-140 text-title-m font-medium">{t('home.statistics.title.coming-soon')}</p>
          <p className="text-neutral-80 text-body-s text-center">{t('home.statistics.content.coming-soon')}</p>
        </div>
        <JxtButton className="text-body-m font-semibold">{t('home.statistics.button')}</JxtButton>
      </div>
    </div>
  );
};
