import { ErrorMessage } from '@hookform/error-message';
import { OptionDefinitionCompressedTypeEnum } from '@jooxter/api';
import { BOOKING_OPTION_PREFIX } from '@jooxter/utils';
import { useCallback, useMemo } from 'react';
import { Controller, FieldValues, Message, UseFormRegister } from 'react-hook-form';
import JxtCheckbox from '../JxtCheckbox';
import JxtInput from '../JxtInput';
import { JxtSelect } from '../Selectors';
import { IJxtBookingOptionsListItem } from './types';
import clsx from 'clsx';

const JxtBookingOptionsListItem = ({
  option,
  register,
  getFieldState,
  formState,
  control,
}: IJxtBookingOptionsListItem) => {
  const selectOptions = useMemo(
    () => option.definition?.possibleValues?.map((v) => ({ label: v, value: v })) ?? [],
    [option]
  );
  const findSelectOption = useCallback(
    (value: string) => selectOptions.find((o) => o.value === value),
    [selectOptions]
  );

  const getOptionalField = () => {
    switch (option.definition?.type) {
      case OptionDefinitionCompressedTypeEnum.Text:
        return (
          <JxtInput
            name={`options.${BOOKING_OPTION_PREFIX}-${option.id}.value`}
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
            className="flex-1 grow"
            disabled={!option.editable}
            showErrorMessages={false}
          />
        );
      case OptionDefinitionCompressedTypeEnum.Textarea:
        return (
          <JxtInput
            name={`options.${BOOKING_OPTION_PREFIX}-${option.id}.value`}
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
            className="flex-1 grow"
            multiLines
            disabled={!option.editable}
            showErrorMessages={false}
          />
        );
      case OptionDefinitionCompressedTypeEnum.List:
        return (
          <div className="flex-1 grow">
            <Controller
              name={`options.${BOOKING_OPTION_PREFIX}-${option.id}.value`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <JxtSelect
                  options={selectOptions}
                  value={findSelectOption(value)}
                  handleSelection={(e) => e && onChange(e.value)}
                  disabled={!option.editable}
                />
              )}
            />
          </div>
        );

      case OptionDefinitionCompressedTypeEnum.Checkbox:
      default:
        return undefined;
    }
  };

  if (!option?.id) {
    return null;
  }

  return (
    <div
      className={clsx(
        'w-full grid items-center min-h-[38px]',
        option.definition?.type === OptionDefinitionCompressedTypeEnum.Checkbox ? 'grid-cols-1' : 'grid-cols-2'
      )}
    >
      <Controller
        name={`options.${BOOKING_OPTION_PREFIX}-${option.id}.activated`}
        control={control}
        render={({ field: { value, onChange, name }, fieldState: { error } }) => (
          <>
            <JxtCheckbox
              className="flex-1 grow"
              name={name}
              value={value}
              onChange={onChange}
              label={option.definition?.name + (option.mandatory ? '*' : '')}
              disabled={!option.editable}
            />
            {error && (
              <div className="col-start-1 row-start-2 col-span-2">
                <ErrorMessage
                  name={name}
                  errors={{ [name]: error }}
                  render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1">{message}</p>}
                />
              </div>
            )}
          </>
        )}
      />
      {getOptionalField()}
    </div>
  );
};

export default JxtBookingOptionsListItem;
