import { Matrix } from '@jooxter/ui/src/components/JxtFloorPlan/types';
import { IVector2D } from './hooks/types';

export const parseRawMatrix = (rawMatrix?: string): Matrix | undefined => {
  let result: Matrix | undefined;

  if (typeof rawMatrix === 'string') {
    try {
      result = JSON.parse(rawMatrix);
    } catch (error) {
      console.warn('Error while parsing raw matrix:', error);
    }
  }

  return result;
};

export const locateResourceId = (resId: number, matrix?: Matrix): IVector2D | undefined => {
  //  Return the last matching occurrence
  let pos: IVector2D | undefined;
  let isDevice = false;

  if (!matrix?.length) {
    return void 0;
  }

  matrix.forEach((row, rowIndex) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    row.forEach((col: any, colIndex: any) => {
      if (typeof col === 'object' && 'device' in col && col.device.idResource === resId) {
        isDevice = true;
        pos = { x: colIndex, y: rowIndex };
      }

      if (typeof col === 'object' && 'resource' in col && !isDevice && parseInt(col.resource.id, 10) === resId) {
        pos = { x: colIndex, y: rowIndex };
      }
    });
  });

  return pos;
};
