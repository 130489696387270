import { DateTime } from 'luxon';
import { IJxtBookingDay } from './types';

const JxtBookingDay = ({ day }: IJxtBookingDay) => {
  return (
    <div className="flex items-center gap-2">
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-calendar fa-fw fa-lg text-neutral-60" />
      </div>
      <span className="text-body-m text-neutral-140">{day.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}</span>
    </div>
  );
};

export default JxtBookingDay;
