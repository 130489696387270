import clsx from 'clsx';
import { IJxtCheckbox } from './types';

const JxtCheckbox = ({ name, label, value = false, disabled = false, onChange, className }: IJxtCheckbox) => {
  return (
    <div className={className}>
      <div className="relative flex items-start">
        <div className="flex min-h-6 items-center group max-w-full">
          <label htmlFor={name} aria-label={name} className="relative flex cursor-pointer max-w-full">
            <div className={clsx('relative flex items-center')}>
              <input
                id={name}
                name={name}
                type="checkbox"
                disabled={disabled}
                onChange={onChange}
                checked={value}
                className={clsx(
                  'peer relative size-4 appearance-none rounded border-2',
                  disabled
                    ? 'cursor-not-allowed border-neutral-40 checked:bg-neutral-40'
                    : 'cursor-pointer border-neutral-80 group-hover:border-primary-100 group-hover:bg-primary-10 checked:border-primary-100 checked:bg-primary-100 group-hover:checked:bg-primary-100'
                )}
              />
              <span className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>
            {label && (
              <span
                className={clsx(
                  'text-body-s pl-2 select-none truncate',
                  disabled ? 'cursor-not-allowed text-neutral-40' : 'cursor-pointer text-neutral-120',
                  value ? 'font-semibold' : 'font-normal'
                )}
              >
                {label}
              </span>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default JxtCheckbox;
