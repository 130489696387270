import { User, UserRequestExtended, UserRequestExtendedTypeEnum } from '@jooxter/api';

export const userToUserRequestExtendedAdapter = (user: User): { userExtended: UserRequestExtended | undefined } => {
  if (!user.location?.id || !user.corporation?.id) {
    return { userExtended: undefined };
  }
  const userExtended: UserRequestExtended = {
    email: user.email,
    firstname: user.firstname,
    lastname: user.lastname,
    lang: user.lang,
    type: user.type as unknown as UserRequestExtendedTypeEnum,
    validation: user.validation,
    externalId: user.externalId,
    authenticationProtocolId: user.authenticationProtocolId,
    telephone: user.telephone,
    profile: user.profile,
    department: user.department,
    locationId: user.location?.id,
    corporationId: user.corporation?.id,
    timezone: user.timezone,
    groupIds: user.groups?.filter((group) => group.id !== undefined).map((group) => group.id as number),
  };

  return { userExtended };
};
