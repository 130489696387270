import { useAuthContext } from '@jooxter/core';
import { IJxtNavbar, useIsSmallScreen } from '@jooxter/ui';
import { useState, useEffect, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseJooxterNavigation } from './types';
import { useBaseNavigation } from './BaseNavigation';
import { SCREEN_SIZE } from '@jooxter/utils';
import { showAdmin, adminItem, showManageMySpaces, manageSpacesItem, helpCenterItem, showLogout } from './helpers';

const useJooxterNavigation = ({ user }: IUseJooxterNavigation) => {
  const { t, i18n } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const isTablet = useIsSmallScreen(SCREEN_SIZE.MD);
  const { logout } = useAuthContext();

  const logoutItem = useMemo(
    () => ({
      name: t('navbar.logout'),
      icon: 'sign-out',
      callback: logout,
    }),
    [logout, t]
  );
  const [baseNavigation] = useBaseNavigation();

  const [navigation, setNavigation] = useState<{
    routes: IJxtNavbar['routes'];
    buttons?: ReactNode[];
    baseRoute: string;
  }>(baseNavigation);

  useEffect(() => {
    if (user) {
      const newSecondaryNav = [];

      if (showAdmin(user)) {
        newSecondaryNav.push(adminItem(t));
      }

      if (!isTablet && showManageMySpaces(user)) {
        newSecondaryNav.push(manageSpacesItem(t));
      }

      if (isSmallScreen) {
        newSecondaryNav.push(helpCenterItem(t));
      }

      if (showLogout) {
        newSecondaryNav.push(logoutItem);
      }

      setNavigation({
        ...baseNavigation,
        routes: {
          main: [...baseNavigation.routes.main],
          secondary: [...baseNavigation.routes.secondary, ...newSecondaryNav],
        },
      });
    }
  }, [user, isSmallScreen, i18n.language, isTablet, logoutItem, baseNavigation, t]);

  return [navigation];
};

export default useJooxterNavigation;
