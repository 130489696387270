import { WorkDay, WorkHoursWeekDto } from '@jooxter/api';
import {
  DataPeriodEnum,
  getDateTimeFromDateTimeAndStandardTime,
  getStandardTime,
  getWeekdayName,
  IQuickTimeSlot,
  now,
} from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchUserById, useFetchWeek } from '../../queries';

export const useWorkHours = (userId?: number) => {
  const { user } = useFetchUserById(userId);
  const { week } = useFetchWeek(userId);
  const { t, i18n } = useTranslation();

  const isWorkPeriodFinished = useCallback((period: DataPeriodEnum, day: WorkDay, date: DateTime): boolean => {
    if (date > now().endOf('day')) {
      return false;
    }

    let time: string;

    switch (period) {
      case DataPeriodEnum.Am:
        time = day.morning.end;
        break;
      case DataPeriodEnum.Pm:
      case DataPeriodEnum.Day:
        time = day.afternoon.end;
        break;

      default:
        console.warn(`Unexpected DataPeriodEnum: ${period}`);
        time = day.afternoon.end;
        break;
    }

    const momentToCompare = DateTime.fromFormat(time, 'hh:mm', { zone: user?.timezone });

    return (
      now() >
      momentToCompare.set({
        day: now().day,
        month: now().month,
        year: now().year,
      })
    );
  }, []);

  const getStart = useCallback((date: DateTime, time: string): string => {
    return date.startOf('day').equals(now(date.zoneName).startOf('day'))
      ? getStandardTime(
          DateTime.max(now(date.zoneName), DateTime.fromFormat(time, 'hh:mm', { zone: date.zoneName ?? undefined }))
        )
      : time;
  }, []);

  const getEnd = useCallback((start: string, end: string, timezone?: string | null) => {
    if (
      DateTime.fromFormat(start, 'hh:mm', { zone: timezone ?? user?.timezone }) <
      DateTime.fromFormat(end, 'hh:mm', { zone: timezone ?? user?.timezone })
    ) {
      return end;
    }

    return getStandardTime(DateTime.fromFormat(start, 'hh:mm', { zone: timezone ?? user?.timezone }).endOf('day'));
  }, []);

  const getMorningHours = useCallback(
    (date: DateTime): IQuickTimeSlot | null => {
      if (!week) {
        return null;
      }

      const weekdayName = getWeekdayName(date).toLowerCase();
      const day: WorkDay | undefined = week[weekdayName as keyof WorkHoursWeekDto];

      if (!day) {
        return null;
      }

      const text = t('quick-time-label-select-am-morning-pm-afternoon-day-all-day', {
        quickTimeLabel: DataPeriodEnum.Am,
      });

      if (isWorkPeriodFinished(DataPeriodEnum.Am, day, date)) {
        return null;
      }

      const startTime = getStart(date, day.morning.start);
      const endTime = day.morning.end;

      return {
        start: getDateTimeFromDateTimeAndStandardTime(date, startTime),
        end: getDateTimeFromDateTimeAndStandardTime(date, endTime),
        generatedByDefault: !!day.morning.generatedByDefault,
        period: DataPeriodEnum.Am,
        text,
      };
    },
    [week, i18n.language]
  );

  const getAfternoonHours = useCallback(
    (date: DateTime): IQuickTimeSlot | null => {
      if (!week) {
        return null;
      }

      const weekdayName = getWeekdayName(date).toLowerCase();
      const day: WorkDay | undefined = week[weekdayName as keyof WorkHoursWeekDto];

      if (!day) {
        return null;
      }

      const text = t('quick-time-label-select-am-morning-pm-afternoon-day-all-day', {
        quickTimeLabel: DataPeriodEnum.Pm,
      });

      if (isWorkPeriodFinished(DataPeriodEnum.Pm, day, date)) {
        return null;
      }

      const startTime = getStart(date, day.afternoon.start);
      const endTime = day.afternoon.end;

      return {
        start: getDateTimeFromDateTimeAndStandardTime(date, startTime),
        end: getDateTimeFromDateTimeAndStandardTime(date, endTime),
        generatedByDefault: !!day.afternoon.generatedByDefault,
        period: DataPeriodEnum.Pm,
        text,
      };
    },
    [week, i18n.language]
  );

  const getDayHours = useCallback(
    (date: DateTime): IQuickTimeSlot | null => {
      if (!week) {
        return null;
      }

      const weekdayName = getWeekdayName(date).toLowerCase();
      const day: WorkDay | undefined = week[weekdayName as keyof WorkHoursWeekDto];

      if (!day) {
        return null;
      }

      const text = t('quick-time-label-select-am-morning-pm-afternoon-day-all-day', {
        quickTimeLabel: DataPeriodEnum.Day,
      });

      if (isWorkPeriodFinished(DataPeriodEnum.Day, day, date)) {
        return null;
      }

      const startTime = getStart(date, day.morning.start);
      const endTime = getEnd(startTime, day.afternoon.end, date.zoneName);

      return {
        start: getDateTimeFromDateTimeAndStandardTime(date, startTime),
        end: getDateTimeFromDateTimeAndStandardTime(date, endTime),
        generatedByDefault: !!(day.afternoon.generatedByDefault || day.morning.generatedByDefault),
        period: DataPeriodEnum.Day,
        text,
      };
    },
    [week, i18n.language]
  );

  const get24Hours = useCallback(
    (date: DateTime): IQuickTimeSlot | null => {
      if (!week) {
        return null;
      }

      const weekdayName = getWeekdayName(date, i18n.language);
      const day: WorkDay | undefined = week[weekdayName as keyof WorkHoursWeekDto];

      if (!day) {
        return null;
      }

      if (isWorkPeriodFinished(DataPeriodEnum.Day, day, date)) {
        return null;
      }

      const text = t<string>('quick-time-label-select-am-morning-pm-afternoon-day-all-day', {
        quickTimeLabel: DataPeriodEnum.H24,
      });

      const startTime = getStart(date, day.morning.start);
      const endTime = startTime;

      return {
        start: getDateTimeFromDateTimeAndStandardTime(date, startTime),
        end: getDateTimeFromDateTimeAndStandardTime(date.plus({ hour: 24 }), endTime),
        generatedByDefault: !!(day.afternoon.generatedByDefault || day.morning.generatedByDefault),
        period: DataPeriodEnum.H24,
        text,
      };
    },
    [week, i18n.language]
  );

  const get48Hours = useCallback(
    (date: DateTime): IQuickTimeSlot | null => {
      if (!week) {
        return null;
      }

      const weekdayName = getWeekdayName(date, i18n.language);
      const day: WorkDay | undefined = week[weekdayName as keyof WorkHoursWeekDto];

      if (!day) {
        return null;
      }

      const text = t<string>('quick-time-label-select-am-morning-pm-afternoon-day-all-day', {
        quickTimeLabel: DataPeriodEnum.H48,
      });

      const startTime = getStart(date, day.morning.start);
      const endTime = startTime;

      return {
        start: getDateTimeFromDateTimeAndStandardTime(date, startTime),
        end: getDateTimeFromDateTimeAndStandardTime(date.plus({ hour: 48 }), endTime),
        generatedByDefault: !!(day.afternoon.generatedByDefault || day.morning.generatedByDefault),
        period: DataPeriodEnum.H48,
        text,
      };
    },
    [week, i18n.language]
  );

  return { getDayHours, getMorningHours, getAfternoonHours, get24Hours, get48Hours, getStart };
};
