import JxtBookingOptionsListItem from '../JxtBookingOptionsListItem';
import { IJxtBookingOptionsList } from './types';

const JxtBookingOptionsList = ({ options, register, getFieldState, formState, control }: IJxtBookingOptionsList) => {
  return (
    <div className="w-full flex flex-col gap-3">
      {options.map((option, index) => (
        <JxtBookingOptionsListItem
          key={`${option.id}-${index}`}
          option={option}
          register={register}
          getFieldState={getFieldState}
          formState={formState}
          control={control}
        />
      ))}
    </div>
  );
};

export default JxtBookingOptionsList;
