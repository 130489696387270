import {
  IGetOpeningHoursParameters,
  IGetResourcesParameters,
  IGetResourceStatusParameters,
  Option,
  Resource,
  ResourceCompressed,
  ResourceGroupCompressed,
  ResourceOpenInterval,
  ResourceService,
  ResourceStateDto,
} from '@jooxter/api';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  keepPreviousData,
  useInfiniteQuery,
  useQueries,
  useQuery,
} from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { ResourceQueryKeys } from '../queryKeys';
import { ResourceStaleTimeEnum } from '../staleTimes';
import { flatMap, uniqBy } from 'lodash-es';
import { ResourceOpenIntervalWithResource } from '../../adapters';

export const useFetchClosingHours = (options: IGetOpeningHoursParameters) => {
  const fetchClosingHours = useCallback(() => {
    if (!Array.isArray(options.resourceId)) {
      return ResourceService.getOpeningHours(options);
    }

    return Promise.resolve(null);
  }, [options]);

  const { data: openingHours } = useQuery({
    queryKey: [ResourceQueryKeys.GetOpeningHOurs, options],
    queryFn: () => fetchClosingHours(),
    staleTime: ResourceStaleTimeEnum.GetOpeningHOurs,
    enabled: !!options.resourceId,
  });

  return { openingHours };
};

export const useFetchAllResourcesClosingHours = (
  options: Omit<IGetOpeningHoursParameters, 'resourceId'> & { resourceId: number[] }
) => {
  const fetchClosingHours = (id: number): Promise<ResourceOpenIntervalWithResource[] | { id: number }[]> => {
    const option = { ...options, resourceId: id };
    return ResourceService.getOpeningHours(option).then((result) => {
      if (result.length === 0) {
        return [{ id }];
      }

      return result.map((r) => ({ id, ...r }));
    });
  };

  const { data: openingHours, isPending } = useQueries({
    queries: Array.isArray(options.resourceId)
      ? options.resourceId.map((id: number) => ({
          queryKey: [ResourceQueryKeys.GetOpeningHOurs, id, options],
          queryFn: () => fetchClosingHours(id),
          placeholderData: keepPreviousData,
          staleTime: ResourceStaleTimeEnum.GetOpeningHOurs,
        }))
      : [],
    combine: (results) => {
      return {
        data: results
          .map((result) => result.data)
          .filter((r): r is ResourceOpenIntervalWithResource[] => !!r)
          .flat(),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { openingHours, isPending };
};

export const useFetchResourcesList = (
  options?: IGetResourcesParameters
): {
  resources?: ResourceCompressed[];
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          data: ResourceCompressed[];
          nextPage: string;
        },
        unknown
      >
    >
  >;
  hasNextPage?: boolean;
  isFetching?: boolean;
  isRefetching?: boolean;
} => {
  const [resources, setResources] = useState<ResourceCompressed[]>([]);
  const fetchResources = useCallback(
    async ({ pageParam = '' }) => {
      const newOptions = { ...options };
      if (pageParam) {
        newOptions.page = pageParam;
      }

      return ResourceService.getResourcesWithPagination(newOptions);
    },
    [options]
  );

  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetching, isRefetching } = useInfiniteQuery({
    queryKey: [ResourceQueryKeys.GetResourcesList, options],
    queryFn: fetchResources,
    refetchInterval: 30000,
    getNextPageParam: (lastPage: { nextPage?: string; data: ResourceCompressed[] }) => {
      // must return undefined if it's the last page
      // lastPage.nextPage is ''
      return lastPage.nextPage?.length && lastPage.nextPage.length > 0 ? lastPage.nextPage : undefined;
    },
    initialPageParam: '',
  });

  useEffect(() => {
    if (data?.pages) {
      const pages = [...data.pages];
      const resources = uniqBy(flatMap(pages.map((page) => page.data)), 'id');
      setResources(resources);
    }
  }, [data]);

  return { resources, isLoading, isError, fetchNextPage, hasNextPage, isFetching, isRefetching };
};

export const useFetchResource = (id?: number): { resource: Resource | null | undefined; isLoading: boolean } => {
  const fetchResource = useCallback(() => {
    if (id) {
      return ResourceService.getById(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: resource, isLoading } = useQuery({
    queryKey: [ResourceQueryKeys.GetResource, id],
    queryFn: () => fetchResource(),
    placeholderData: keepPreviousData,
    staleTime: ResourceStaleTimeEnum.GetResource,
  });

  return { resource, isLoading };
};

export const useFetchResources = (ids?: number[]) => {
  const fetchResource = (id: number) => (id ? ResourceService.getById(id) : Promise.resolve(null));

  const { data: resources, isPending } = useQueries({
    queries:
      ids?.map((id: number) => ({
        queryKey: [ResourceQueryKeys.GetResource, id],
        queryFn: () => fetchResource(id),
        placeholderData: keepPreviousData,
        staleTime: ResourceStaleTimeEnum.GetResource,
      })) ?? [],
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is Resource => !!r),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { resources, isPending };
};

export const useFetchResourceOptions = (id?: number): { options: Option[] | null | undefined; isLoading: boolean } => {
  const fetchResource = useCallback(() => {
    if (id) {
      return ResourceService.getResourceOptions(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: options, isLoading } = useQuery({
    queryKey: [ResourceQueryKeys.GetResourceOptions, id],
    queryFn: () => fetchResource(),
    placeholderData: keepPreviousData,
    staleTime: ResourceStaleTimeEnum.GetResourceOptions,
  });

  return { options, isLoading };
};

export const useFetchResourcesOptions = (ids?: number[]) => {
  const fetchResource = useCallback(
    async (id: number) => {
      const result = await Promise.all(await ResourceService.getResourceOptions(id));
      return result;
    },
    [ids]
  );

  const { data: options, isLoading } = useQueries({
    queries:
      ids?.map((id: number) => ({
        queryKey: [ResourceQueryKeys.GetResourceOptions, id],
        queryFn: () => fetchResource(id),
        placeholderData: keepPreviousData,
        staleTime: ResourceStaleTimeEnum.GetResourceOptions,
      })) ?? [],
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is Option[] => !!r),
        isLoading: results.some((result) => result.isLoading),
      };
    },
  });

  return { options, isLoading };
};

export const useFetchResourceGroups = (
  id?: number
): { groups: ResourceGroupCompressed[] | null | undefined; isLoading: boolean } => {
  const fetchResource = useCallback(() => {
    if (id) {
      return ResourceService.getResourceGroups(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: groups, isLoading } = useQuery({
    queryKey: [ResourceQueryKeys.GetResourceGroups, id],
    queryFn: () => fetchResource(),
    placeholderData: keepPreviousData,
    staleTime: ResourceStaleTimeEnum.GetResourceGroups,
  });

  return { groups, isLoading };
};

export const useFetchResourceStatus = (
  options: IGetResourceStatusParameters
): { status: ResourceStateDto | undefined; isLoading: boolean } => {
  const fetchResource = useCallback(() => {
    return ResourceService.getResourceStatus(options);
  }, [options]);

  const { data: status, isLoading } = useQuery({
    queryKey: [ResourceQueryKeys.GetResourceStatus, options],
    queryFn: () => fetchResource(),
    placeholderData: keepPreviousData,
    staleTime: ResourceStaleTimeEnum.GetResourceStatus,
  });

  return { status, isLoading };
};
