import { ResourceTypeDtoMetaTypeEnum } from '@jooxter/api';
import {
  ONBOARDING_ROUTE_COLLEAGUES,
  ONBOARDING_ROUTE_DESK,
  ONBOARDING_ROUTE_FINALIZE,
  ONBOARDING_ROUTE_NOTIFICATIONS,
  ONBOARDING_ROUTE_PROFILE_PICTURE,
  ONBOARDING_ROUTE_START,
  ONBOARDING_ROUTE_WORK_HOURS,
  useFetchResourcesList,
  useFetchResourceTypes,
  useFetchUser,
  useFetchUserPreferences,
  useFetchUsersSearch,
} from '@jooxter/core';
import { OnboardingPagesEnum } from './types';

export const useOnboardingNavigation = (page: OnboardingPagesEnum) => {
  const { user } = useFetchUser();
  const { preferences } = useFetchUserPreferences(user?.id);
  const { resourceTypes } = useFetchResourceTypes({ metaTypes: [ResourceTypeDtoMetaTypeEnum.Desk] });
  const { resources } = useFetchResourcesList({
    locationId: preferences?.location?.id ? [preferences.location.id] : undefined,
    resourceTypeId: resourceTypes?.map((resourceType) => resourceType.id),
    visible: true,
    bookable: true,
    size: 1,
  });
  const { users } = useFetchUsersSearch({ size: 2 });
  const filteredUsers = users?.filter((u) => u.id !== user?.id);

  const shouldShowDesk = resources && resources?.length > 0;
  const shouldShowColleagues =
    user?.capabilities?.features?.collaborators &&
    user.groups &&
    user.groups.length > 0 &&
    filteredUsers &&
    filteredUsers.length > 0;

  const OnboardingNavigationInfo = {
    [OnboardingPagesEnum.START]: {
      prev: '',
      next: ONBOARDING_ROUTE_WORK_HOURS,
    },
    [OnboardingPagesEnum.WORK_HOURS]: {
      prev: ONBOARDING_ROUTE_START,
      next: shouldShowDesk
        ? ONBOARDING_ROUTE_DESK
        : shouldShowColleagues
          ? ONBOARDING_ROUTE_COLLEAGUES
          : ONBOARDING_ROUTE_PROFILE_PICTURE,
    },
    [OnboardingPagesEnum.DESK]: {
      prev: ONBOARDING_ROUTE_WORK_HOURS,
      next: shouldShowColleagues ? ONBOARDING_ROUTE_COLLEAGUES : ONBOARDING_ROUTE_PROFILE_PICTURE,
    },
    [OnboardingPagesEnum.COLLEAGUES]: {
      prev: shouldShowDesk ? ONBOARDING_ROUTE_DESK : ONBOARDING_ROUTE_WORK_HOURS,
      next: ONBOARDING_ROUTE_PROFILE_PICTURE,
    },
    [OnboardingPagesEnum.PROFILE_PICTURE]: {
      prev: shouldShowColleagues
        ? ONBOARDING_ROUTE_COLLEAGUES
        : shouldShowDesk
          ? ONBOARDING_ROUTE_DESK
          : ONBOARDING_ROUTE_WORK_HOURS,
      next: ONBOARDING_ROUTE_NOTIFICATIONS,
    },
    [OnboardingPagesEnum.NOTIFICATIONS]: {
      prev: ONBOARDING_ROUTE_PROFILE_PICTURE,
      next: ONBOARDING_ROUTE_FINALIZE,
    },
    [OnboardingPagesEnum.FINALIZE]: {
      prev: ONBOARDING_ROUTE_NOTIFICATIONS,
      next: '',
    },
  };

  const getNextLink = (): string => OnboardingNavigationInfo[page].next;

  const getPrevLink = (): string => OnboardingNavigationInfo[page].prev;

  return { getNextLink, getPrevLink };
};
