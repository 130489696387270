import { JxtEventColorSizeEnum, IJxtEventColor } from './types';
import { getJxtEventColorClass } from './styles';

const JxtEventColor = ({ color, size = JxtEventColorSizeEnum.REGULAR }: IJxtEventColor) => {
  const classes = getJxtEventColorClass({ color, size });

  return <div className={classes} />;
};

export default JxtEventColor;
