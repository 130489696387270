import {
  BookingOptionRequest,
  BookingRequest,
  BookingRequestVisibilityEnum,
  BookingVisibilityEnum,
} from '@jooxter/api';
import { BOOKING_OPTION_PREFIX, getDateTimeFromDateTimeAndStandardTime, IBookingForm, toISO } from '@jooxter/utils';

export const useIBookingFormToBookingRequestAdapter = () => {
  const getBookingOptionsRequest = (bookingForm: IBookingForm): BookingOptionRequest[] => {
    const options: BookingOptionRequest[] = [];

    if (!(bookingForm.options && Object.keys(bookingForm.options)?.length)) {
      return options;
    }

    Object.entries(bookingForm.options)
      .filter(([, value]) => value.activated === true)
      .forEach(([key, value]) => {
        const optionId = parseInt(key.replace(`${BOOKING_OPTION_PREFIX}-`, ''), 10);

        options.push({
          value: value.value,
          optionOverloadedId: optionId,
        });
      });

    return options;
  };

  const bookingFormToBookingRequestAdapter = (booking: IBookingForm): BookingRequest => ({
    summary: booking.summary,
    description: booking.description === null ? '' : booking.description,
    color: booking.color,
    visibility:
      booking.visibility === BookingVisibilityEnum.Public
        ? BookingRequestVisibilityEnum.Public
        : BookingRequestVisibilityEnum.Private,
    rrule: booking.rrule,
    start: { dateTime: toISO(getDateTimeFromDateTimeAndStandardTime(booking.start.date, booking.start.time)) },
    end: { dateTime: toISO(getDateTimeFromDateTimeAndStandardTime(booking.end.date, booking.end.time)) },
    resourceId: booking.resource.id,
    organizerId: booking.organizerId,
    physicalAttendees: booking.physicalAttendees,
    attendees: { internal: booking.internalAttendees ?? [], external: booking.externalAttendees ?? [] },
    participationStatusAttendees: [],
    options: getBookingOptionsRequest(booking),
  });

  return [bookingFormToBookingRequestAdapter];
};
