import { IJxtSelectedFilterList } from './types';
import './style.scss';
import clsx from 'clsx';

const JxtSelectedFilterList = ({ className = '', children }: IJxtSelectedFilterList) => {
  const classes = clsx(className, 'flex gap-2 py-1 shrink-0 overflow-x-auto cursor-grab');

  // note: each child should have a 'shrink-0' className to prevent inner wrapping
  // note: the py-2 class is there to make sure the div is tall enough for accessibility concerns,
  // see https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width#accessibility_concerns
  return (
    <div data-swipeable="false" id="selected-filter-list" className={classes}>
      {children}
    </div>
  );
};

export default JxtSelectedFilterList;
