import {
  ButtonVariantEnum,
  JxtButton,
  JxtModal,
  JxtMultipleFloorsPlaceholder,
  JxtMultipleBuildingsPlaceholder,
  JxtNoFloorPlaceholder,
  JxtNoSpaceAvailable,
  JxtSearchBar,
  JxtLoader,
} from '@jooxter/ui';
import { Filters } from './Filters';
import { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  JxtGoogleMap,
  JxtResourceList,
  JxtViewSwitch,
  SearchResourcesFiltersToGetResourcesParamsAdapter,
  SwitchViewEnum,
  useBookingFormContext,
  useBookingOffCanvasConfiguration,
  useFetchUser,
  useFetchUserPreferences,
  useOffCanvas,
  useStore,
  useFetchLocations,
  JxtLocateResource,
  useFetchResourcesList,
  SpaceFetchResourcesProvider,
  FilterContextLocation,
  useBookingCallbacks,
  SpaceLocateResources,
  GTMCategoryContext,
  GTMCategoryContextEnum,
  useMutatePutFavoriteResource,
  useMutateDeleteFavoriteResource,
  ResourceQueryKeys,
  queryClient,
} from '@jooxter/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  IRange,
  createGTMGAEvent,
  createGTMUpdateVirtualPathEvent,
  fromISO,
  generateToast,
  getMinBetweenNextHourAndEndOfDay,
  now,
  toISO,
} from '@jooxter/utils';
import { Scheduler } from './Scheduler';
import { uniq } from 'lodash-es';
import { JxtModalSizeEnum } from '@jooxter/ui/src/components/JxtModal/types';
import {
  FloorCompressed,
  FloorService,
  GetFloorsSortParamsEnum,
  GetResourcesSortParamsEnum,
  Preferences,
  User,
} from '@jooxter/api';
import { useDebouncedValue } from '@mantine/hooks';
import { useShallow } from 'zustand/shallow';

export const Spaces = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [filter, setFilter, spaceSearchView, setSpaceSearchView, resetResourceFilter] = useStore(
    useShallow((state) => [
      state.filter,
      state.setFilter,
      state.spaceSearchView,
      state.setSpaceSearchView,
      state.resetResourceFilter,
    ])
  );
  const [debounceOptions] = useDebouncedValue(filter.resource, 300);
  const debounceOptionsAdapter = useMemo(
    () => SearchResourcesFiltersToGetResourcesParamsAdapter.adapt(debounceOptions),
    [debounceOptions]
  );
  const { resources, isLoading, hasNextPage, fetchNextPage, isFetching, isRefetching } = useFetchResourcesList({
    ...debounceOptionsAdapter,
    visible: true,
    size: 25,
    sort: [
      GetResourcesSortParamsEnum.LocationName,
      GetResourcesSortParamsEnum.FloorNum,
      GetResourcesSortParamsEnum.ResourceName,
    ],
  });
  const locationIds = uniq(resources?.map((r) => r.locationId) ?? []);
  const { locations } = useFetchLocations(locationIds);
  const putFavorite = useMutatePutFavoriteResource();
  const deleteFavorite = useMutateDeleteFavoriteResource();
  const { offCanvasConfiguration, goToResourceCalendarView, goToCreateBooking, goToResourceInformations } =
    useBookingOffCanvasConfiguration();
  const offCanvasContext = useOffCanvas();
  const bookingFormContext = useBookingFormContext();
  const { onShowBookingDetailsClick } = useBookingCallbacks();
  const { user } = useFetchUser();
  const { preferences } = useFetchUserPreferences(user?.id);
  const filterSidebarRef = useRef<HTMLDivElement>(null);
  const resultViewRef = useRef<HTMLDivElement>(null);
  const navbarOffset = useMemo(() => {
    if (filterSidebarRef.current) {
      return filterSidebarRef.current.getBoundingClientRect().top;
    }

    return 0;
  }, [filterSidebarRef.current]);

  const resultViewOffset = useMemo(() => {
    if (resultViewRef.current) {
      return resultViewRef.current.getBoundingClientRect().top;
    }

    return 0;
  }, [resultViewRef.current]);
  const resultViewSize = useMemo(() => {
    if (resultViewRef.current) {
      return {
        width: resultViewRef.current.getBoundingClientRect().width,
        height: resultViewRef.current.getBoundingClientRect().height,
      };
    }
  }, [resultViewRef.current]);
  const hasMultipleFloors: boolean = useMemo(() => {
    if (!filter.resource?.location?.locationIds || filter.resource.location.locationIds.length === 0) {
      return false;
    }
    const location = locations?.find((l) => l.id === filter.resource.location.locationIds[0]);
    return (
      !!filter.resource?.location?.floorIds &&
      (filter.resource.location.floorIds.length > 1 ||
        (filter.resource.location.floorIds.length === 0 &&
          location?.floors !== undefined &&
          location.floors.length > 1))
    );
  }, [filter.resource.location.floorIds, filter.resource.location.locationIds, locations]);
  const hasMultipleBuildings: boolean = useMemo(() => {
    return !!filter.resource?.location?.locationIds && filter.resource.location.locationIds.length > 1;
  }, [filter.resource?.location?.locationIds]);
  const hasNoFloor: boolean = useMemo(() => {
    return !filter.resource?.location?.floorIds || filter.resource.location.floorIds.length === 0;
  }, [filter.resource?.location?.floorIds]);
  const showFloorPlan = useMemo(() => {
    return !(hasNoFloor || hasMultipleFloors);
  }, [hasNoFloor, hasMultipleFloors]);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);
  // https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const locateResourceModalRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setTimeout(() => {
        setModalWidth(node.getBoundingClientRect().width);
        setModalHeight(node.getBoundingClientRect().height - 2 * 16);
      }, 10);
    }
  }, []);
  const [locatedResourceId, setLocatedResourceId] = useState<number | undefined>();
  const locatedResource = useMemo(
    () => resources?.find((r) => r.id === locatedResourceId),
    [locatedResourceId, resources]
  );

  const showLocateResourceModal = useMemo(() => !!locatedResource, [locatedResource]);

  const fetchFloors = async (preferences: Preferences, user: User) => {
    if (preferences.location?.id && user.id) {
      await FloorService.getFloorsWithPagination({
        locationId: preferences.location.id,
        size: 100,
        sort: [GetFloorsSortParamsEnum.FloorNum],
      }).then(async (res) => {
        const firstFloor = res.data
          .filter((floor) => floor.number >= 0)
          .sort((a: FloorCompressed, b: FloorCompressed) => a.number - b.number)
          .slice(0, 1);
        setFilter({
          ...filter,
          resource: {
            ...filter.resource,
            organizerId: user.id,
            location: {
              floorIds: firstFloor && firstFloor.length > 0 ? [firstFloor[0].id] : [],
              locationIds: preferences.location?.id ? [preferences.location.id] : [],
            },
          },
        });
      });
    }
  };

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('Spaces');
  }, []);

  useEffect(() => {
    if (!filter.resource?.organizerId && user?.id && preferences?.location?.id) {
      if (preferences?.resource?.floorId) {
        setFilter({
          ...filter,
          resource: {
            ...filter.resource,
            organizerId: user.id,
            location: {
              floorIds: [preferences.resource.floorId],
              locationIds: [preferences.location.id],
            },
          },
        });
      } else {
        fetchFloors(preferences, user);
      }
    }
  }, [user, preferences]);

  useEffect(() => {
    if (!filter.resource?.date?.from) {
      return;
    }

    const interval = setInterval(() => {
      const from = fromISO(filter.resource.date.from);
      if (from.startOf('minute') < now().startOf('minute')) {
        setFilter({
          ...filter,
          resource: {
            ...filter.resource,
            date: {
              ...filter.resource.date,
              from: toISO(now()),
            },
          },
        });
      }
    }, 15000);

    return clearInterval(interval);
  }, [filter.resource?.date?.from]);

  useEffect(() => {
    if (bookingFormContext?.showBooking && !isNaN(bookingFormContext?.showBooking)) {
      onShowBookingDetailsClick(bookingFormContext?.showBooking);
    }
  }, [bookingFormContext?.showBooking]);

  const onChangeFilter = useCallback(
    (changeFilter: any) => {
      setFilter({
        ...filter,
        resource: {
          ...filter.resource,
          ...changeFilter,
        },
      });
    },
    [filter]
  );

  const onDateChange = useCallback(
    (date: { from: string; to: string }) => {
      onChangeFilter({ date });
    },
    [onChangeFilter]
  );

  const onLocate = (id: number) => {
    createGTMGAEvent('Spaces', 'Resources', 'Localized');
    setLocatedResourceId(id);
  };

  const onBook = (id: number, range?: IRange, rrule?: string, shouldAutoSelectFirstSlot?: boolean) => {
    createGTMGAEvent('Spaces', 'Resources', 'Creation Booking');
    goToCreateBooking(id, user?.timezone, range, rrule, filter.resource.now && shouldAutoSelectFirstSlot);
    offCanvasContext?.open(offCanvasConfiguration);
  };

  const onResourceClicked = (id: number, infos = false) => {
    if (infos) {
      createGTMGAEvent('Spaces', 'Resources', 'Show Resource');
      goToResourceInformations(id, user?.timezone);
      return offCanvasContext?.open(offCanvasConfiguration);
    }

    const nowMinute = now().startOf('minute');
    const isNowFrom = fromISO(filter.resource.date.from).startOf('minute').equals(nowMinute);
    const isNowTo = fromISO(filter.resource.date.to)
      .startOf('minute')
      .equals(getMinBetweenNextHourAndEndOfDay(nowMinute));

    if (isNowFrom && isNowTo) {
      goToResourceCalendarView(id, user?.timezone);
      offCanvasContext?.open(offCanvasConfiguration);
    } else {
      onBook(id);
    }
  };

  const onPictoClick = (id: number) => {
    createGTMGAEvent('Spaces', 'Map Filter', 'Click on a resource');
    onResourceClicked(id);
  };

  const handleResourceClickToInformation = (id: number) => onResourceClicked(id, true);

  const onSwitchToFloorPlan = (location: FilterContextLocation) => {
    onChangeFilter({ location });
    setSpaceSearchView(SwitchViewEnum.Floorplan);
  };

  const invalidateResources = (id: number) => {
    queryClient.invalidateQueries({ queryKey: [ResourceQueryKeys.GetResource, id] });
    queryClient.invalidateQueries({ queryKey: [ResourceQueryKeys.GetResourcesList] });
  };

  const onFavoriteSuccess = (id: number) => {
    invalidateResources(id);
  };

  const onFavoriteError = () => {
    generateToast(t('unknown-error'), true);
  };

  const handleToggleFavorite = (favorite: boolean, id: number) => {
    if (putFavorite.isPending || deleteFavorite.isPending) {
      return;
    }

    if (favorite) {
      createGTMGAEvent('Spaces', 'Add Favorite', 'Add Favorite');
      putFavorite.mutate(id, {
        onSuccess: () => onFavoriteSuccess(id),
        onError: onFavoriteError,
      });
    } else {
      createGTMGAEvent('Spaces', 'Remove Favorite', 'Remove Favorite');
      deleteFavorite.mutate(id, {
        onSuccess: () => onFavoriteSuccess(id),
        onError: onFavoriteError,
      });
    }
  };

  if (!user?.id) {
    return null;
  }

  const onClickResetFilterButton = () => {
    resetResourceFilter(user.id as number);
    createGTMGAEvent('Spaces', 'Global filter', 'Reset filters');
  };

  return (
    <GTMCategoryContext.Provider value={GTMCategoryContextEnum.SPACES}>
      <div
        ref={filterSidebarRef}
        className="flex max-sm:flex-col bg-white"
        style={{
          height: `calc(100vh - ${navbarOffset}px)`,
        }}
      >
        <div className="w-[320px] max-sm:w-full sm:flex sm:flex-col shrink-0 sm:border-r sm:border-neutral-20">
          <div className="overflow-auto max-sm:flex max-sm:items-center max-sm:gap-2 max-sm:px-4 max-sm:py-3 grow">
            <div className="w-full sm:py-4 sm:px-6 sm:border-b sm:border-neutral-20">
              <JxtSearchBar
                name="searchbar-spaces"
                value={filter.resource?.resourceName}
                onChange={(search: string) => onChangeFilter({ resourceName: search })}
                onClick={() => createGTMGAEvent('Spaces', 'Global filter', 'Resource name')}
              />
            </div>
            <div className="max-sm:hidden">
              <Filters filter={filter} onChangeFilter={onChangeFilter} user={user} />
            </div>
            <JxtButton
              variant={ButtonVariantEnum.Secondary}
              leftIcon="sliders"
              className="sm:hidden"
              onClick={() => setShow(true)}
            >
              {t('search-resource-filter-form-open-filters')}
            </JxtButton>
          </div>
          <div className="flex flex-col px-6 py-3 border-t border-neutral-20 max-sm:hidden">
            <JxtButton variant={ButtonVariantEnum.Secondary} onClick={onClickResetFilterButton}>
              {t('resourcesearch-reset-button')}
            </JxtButton>
          </div>
        </div>
        <div className="flex flex-col grow">
          <div className="flex sm:justify-center sm:py-4 max-sm:pb-3 max-sm:px-4 sm:border-b sm:border-neutral-20">
            <JxtViewSwitch />
          </div>
          <div
            className="flex justify-center"
            style={{
              height: `calc(100vh - ${resultViewOffset}px)`,
            }}
            ref={resultViewRef}
          >
            {resources?.length === 0 &&
            !(isLoading || (isFetching && !isRefetching)) &&
            spaceSearchView !== SwitchViewEnum.Floorplan ? (
              <div className="bg-background px-6 py-4 h-full grow">
                <JxtNoSpaceAvailable onChangeView={() => setSpaceSearchView(SwitchViewEnum.Scheduler)} />
              </div>
            ) : (
              <SpaceFetchResourcesProvider
                value={{ hasNextPage: hasNextPage ?? false, fetchNextPage, isFetching: isFetching ?? false }}
              >
                {spaceSearchView === SwitchViewEnum.Floorplan && (
                  <>
                    {hasMultipleBuildings && <JxtMultipleBuildingsPlaceholder />}
                    {hasMultipleFloors && !hasMultipleBuildings && <JxtMultipleFloorsPlaceholder />}
                    {hasNoFloor && !hasMultipleBuildings && !hasMultipleFloors && <JxtNoFloorPlaceholder />}
                    {showFloorPlan && resultViewSize && (
                      <SpaceLocateResources
                        {...resultViewSize}
                        filter={filter.resource}
                        floorId={filter.resource.location?.floorIds[0]}
                        onClick={onPictoClick}
                      />
                    )}
                  </>
                )}
                {spaceSearchView === SwitchViewEnum.List && (
                  <div
                    className="relative bg-background px-6 py-4 h-full grow overflow-auto"
                    id="scrollable-resource-list"
                  >
                    {!!resources?.length && resources?.length > 0 && (
                      <InfiniteScroll
                        hasMore={hasNextPage ?? false}
                        loader={<h3>{t('loading')}</h3>}
                        next={() => fetchNextPage()}
                        dataLength={resources.length}
                        scrollableTarget="scrollable-resource-list"
                      >
                        <JxtResourceList
                          resources={resources}
                          onSwitchToFloorPlan={onSwitchToFloorPlan}
                          onBook={onBook}
                          onClick={onResourceClicked}
                          onLocate={onLocate}
                          onToggleFavorite={handleToggleFavorite}
                        />
                      </InfiniteScroll>
                    )}
                    {(isFetching || isLoading) && !isRefetching && (
                      <div className="absolute inset-0 flex bg-white z-[999] items-center justify-center">
                        <JxtLoader />
                      </div>
                    )}
                  </div>
                )}
                {spaceSearchView === SwitchViewEnum.Scheduler && (
                  <div className="relative bg-white h-full grow flex overflow-auto">
                    <Scheduler
                      initialDate={{
                        from: fromISO(filter.resource.date.from),
                        to: fromISO(filter.resource.date.to),
                      }}
                      user={user}
                      resources={resources}
                      onDateChange={onDateChange}
                      onResourceClicked={handleResourceClickToInformation}
                      onBook={onBook}
                      onLocateResource={onLocate}
                      onToggleFavorite={handleToggleFavorite}
                    />
                    {(isFetching || isLoading) && !isRefetching && (
                      <div className="absolute inset-0 flex bg-white z-[999] items-center justify-center">
                        <JxtLoader />
                      </div>
                    )}
                  </div>
                )}
                {spaceSearchView === SwitchViewEnum.Map && <JxtGoogleMap />}
              </SpaceFetchResourcesProvider>
            )}
          </div>
        </div>
      </div>
      <JxtModal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        header={{ title: t('panel-header.filters'), icon: 'sliders' }}
        footer={
          <JxtButton variant={ButtonVariantEnum.Secondary} className="w-full" onClick={onClickResetFilterButton}>
            {t('resourcesearch-reset-button')}
          </JxtButton>
        }
      >
        <Filters filter={filter} onChangeFilter={onChangeFilter} user={user} />
      </JxtModal>
      <JxtModal
        header={{
          title: `${locatedResource?.name}`,
          icon: 'map-marker-alt',
        }}
        ref={locateResourceModalRef}
        show={!!showLocateResourceModal}
        onHide={() => setLocatedResourceId(undefined)}
        size={JxtModalSizeEnum.L}
      >
        {modalWidth && modalHeight && locatedResource ? (
          <div className="grow">
            <JxtLocateResource resourceId={locatedResource.id} width={modalWidth} height={modalHeight} />
          </div>
        ) : (
          <JxtLoader />
        )}
      </JxtModal>
    </GTMCategoryContext.Provider>
  );
};
