import clsx from 'clsx';
import {
  IJxtAvatar,
  JxtAvatarDiameterEnum,
  JxtAvatarSizeEnum,
  JxtAvatarStarSizeEnum,
  JxtAvatarTextSizeEnum,
  JxtFavoriteDiameterEnum,
} from './types';
import { getThemeColorHex } from '@jooxter/tailwind-config';

const JxtAvatar = ({
  alt,
  x,
  y,
  size = JxtAvatarSizeEnum.M,
  picture,
  text,
  favorite = false,
  className = '',
  style,
}: IJxtAvatar) => {
  const baseClasses = 'inline-block';
  const diameter = JxtAvatarDiameterEnum[size];
  const radius = diameter / 2;
  const favoriteDiameter = JxtFavoriteDiameterEnum[size];
  const favoriteRadius = favoriteDiameter / 2;
  const id = `userAvatar${size}${Math.random()}`;

  return (
    <svg
      width={diameter}
      height={diameter}
      className={clsx(baseClasses, className)}
      viewBox={`0 0 ${diameter} ${diameter}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      style={style}
    >
      <title>{alt}</title>
      <circle cx={radius} cy={radius} r={radius} fill={getThemeColorHex('white')} />
      {picture ? (
        <>
          <clipPath id={id}>
            <circle cx={(x ?? 0) + radius} cy={(y ?? 0) + radius} r={radius - 1} />
          </clipPath>
          <image width={diameter} height={diameter} href={picture} x={x} y={y} clipPath={`url(#${id})`} />
        </>
      ) : (
        <>
          <circle cx={radius} cy={radius} r={radius - 1} fill={getThemeColorHex('neutral100')} />
          <text
            fill={getThemeColorHex('white')}
            dominantBaseline="middle"
            textAnchor="middle"
            x="50%"
            y="54%"
            className={clsx(JxtAvatarTextSizeEnum[size], 'select-none')}
          >
            {text?.toUpperCase()}
          </text>
        </>
      )}
      {favorite && (
        <>
          <circle
            cx={diameter - favoriteRadius}
            cy={diameter - favoriteRadius}
            r={favoriteRadius}
            fill={getThemeColorHex('white')}
          />
          <circle
            cx={diameter - favoriteRadius}
            cy={diameter - favoriteRadius}
            r={favoriteRadius - 1}
            fill={getThemeColorHex('orange100')}
          />
          <text
            fill={getThemeColorHex('white')}
            dominantBaseline="middle"
            textAnchor="middle"
            x={diameter - favoriteRadius}
            y={diameter - favoriteRadius}
            className={clsx('font-[FontAwesome] select-none', JxtAvatarStarSizeEnum[size])}
          >
            &#xf005;
          </text>
        </>
      )}
    </svg>
  );
};

export default JxtAvatar;
