import { useTranslation } from 'react-i18next';
import JxtAvatar from '../JxtAvatar';
import { JxtAvatarSizeEnum } from '../JxtAvatar/types';
import { IJxtBookingAttendee, JxtBookingAttendeeTypeEnum } from './types';
import JxtBookingParticipationStatusIcon from '../JxtBookingParticipationStatusIcon';

const JxtBookingAttendee = ({
  name,
  picture,
  checkout,
  participationStatus,
  type = JxtBookingAttendeeTypeEnum.Default,
}: IJxtBookingAttendee) => {
  const { t } = useTranslation();

  return (
    <div className="flex py-2 gap-2 items-center">
      <JxtAvatar picture={picture} alt={t('avatar-of', { name })} size={JxtAvatarSizeEnum.XS} text={name[0]} />
      <p className="text-body-m text-neutral-140">
        {name}
        {type === JxtBookingAttendeeTypeEnum.External
          ? ' (' + t('ext') + ')'
          : type === JxtBookingAttendeeTypeEnum.CurrentUser
            ? ' (' + t('you') + ')'
            : ''}
      </p>
      <JxtBookingParticipationStatusIcon icon={participationStatus} checkout={checkout} />
    </div>
  );
};

export default JxtBookingAttendee;
