import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { JxtBookingParticipationStatusIconEnum, JxtBookingParticipationStatusIconInfo } from '@jooxter/utils';
import { IJxtBookingParticipationStatusIcon } from './types';

const JxtBookingParticipationStatusIcon = ({
  icon = JxtBookingParticipationStatusIconEnum.Unknown,
  checkout,
}: IJxtBookingParticipationStatusIcon) => {
  const { t } = useTranslation();

  const displayIcon = () => {
    return (
      <div
        className={clsx(
          JxtBookingParticipationStatusIconInfo[icon].iconBackgroundColor,
          'flex items-center justify-center rounded-full size-4'
        )}
      >
        <i
          className={clsx(
            JxtBookingParticipationStatusIconInfo[icon].iconName,
            JxtBookingParticipationStatusIconInfo[icon].iconColor,
            'fas fa-fw fa-2xs'
          )}
        />
      </div>
    );
  };

  const displayIconWithText = (text: string) => {
    return (
      <div className="flex items-center gap-1">
        {displayIcon()}
        <p className="text-neutral-80 text-body-xs">{text}</p>
      </div>
    );
  };

  if (icon === JxtBookingParticipationStatusIconEnum.AcceptedRemotely) {
    return displayIconWithText(t('booking-attendees.participation.remotely'));
  } else if (icon === JxtBookingParticipationStatusIconEnum.Left && checkout) {
    return displayIconWithText(t('left-at') + ' ' + DateTime.fromISO(checkout).toLocaleString(DateTime.TIME_SIMPLE));
  } else {
    return displayIcon();
  }
};

export default JxtBookingParticipationStatusIcon;
