import { useLocation, useSearchParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { useStore, HOME_ROUTE, AuthV3Service } from '@jooxter/core';
import { useTranslation } from 'react-i18next';
import { AddInSSOError } from './AddInSSOError';
import { CookieService } from '@jooxter/storage';
import { useShallow } from 'zustand/shallow';

const CONTEXT_COOKIE_NAME = 'jxtfromctx';

export const Saml = () => {
  const { t } = useTranslation();
  const [addinSSOError, setAddinSSOError] = useState<boolean>(false);
  const [token, setToken] = useStore(useShallow((state) => [state.token, state.setToken]));
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [addinCookie] = useState(CookieService.getCookie(CONTEXT_COOKIE_NAME));

  useEffect(() => {
    if (!addinCookie && token) {
      window.location.replace(HOME_ROUTE);
    }
  }, [token, addinCookie]);

  useEffect(() => {
    if (!key) {
      return;
    }

    const saveToken = (t?: string): void => {
      if (t) {
        const authService = AuthV3Service.getInstance();

        if (authService && typeof authService.setToken === 'function') {
          authService.setToken(t);
        }

        setToken(t);
      }
    };

    // f: from, p: platform, l: language, e: environment
    let addinContext: { f?: string; p: { l: string; e: string } } | undefined = undefined;

    if (!addinCookie) {
      return saveToken(key);
    }

    if (addinCookie) {
      try {
        addinContext = JSON.parse(atob(addinCookie));
      } catch (e) {
        // noop
      }

      CookieService.deleteCookie(CONTEXT_COOKIE_NAME, {
        sameSite: 'none',
        path: '/',
        secure: true,
        domain: '.jooxter.com',
      });

      if (!addinContext) {
        window.location.replace(HOME_ROUTE);
        return;
      }

      if (addinContext.f === 'addin') {
        const script = document.createElement('script');
        script.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';

        script.addEventListener('load', () => {
          void Office.onReady().then(() => {
            if (!Office.context.isDialog) {
              // Is not inside an Office host, go back to J2
              window.location.replace(HOME_ROUTE);
              return;
            }

            let env = '';

            if (addinContext?.p.e === 'preview') {
              env = addinContext.p.e;
            }

            let addinUrl = `https://addin.jooxter.com/index.html#sso-callback${window.location.search}`;
            if (env) {
              addinUrl = `https://preview.addin.jooxter.com/index.html#sso-callback${window.location.search}`;
            }
            window.location.replace(addinUrl);
          });
        });

        script.addEventListener('error', (ev) => {
          setAddinSSOError(true);
          console.error(ev);
        });

        document.head.appendChild(script);
      } else {
        saveToken(key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, addinCookie]);

  if (addinSSOError) {
    return <AddInSSOError />;
  }

  return <span>{t('you-are-being-authenticated-please-wait-few-seconds')}</span>;
};
