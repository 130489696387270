import { clsx } from 'clsx';
import { ContainerSizeEnum } from './types';

export interface IUseJxtContainerClass {
  size: ContainerSizeEnum;
}

export const getJxtContainerClass = ({ size }: IUseJxtContainerClass) => {
  const defaultClasses = 'flex flex-col rounded-xl bg-white';

  const sizeClasses = {
    small: 'py-4',
    medium: 'py-6',
  };

  const classes = clsx(defaultClasses, sizeClasses[size]);

  return classes;
};
