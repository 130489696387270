import { useTranslation } from 'react-i18next';
import JxtOnboardingLayout from '../../../layouts/OnboardingLayout';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton, JxtToast, JxtToastVariantEnum } from '@jooxter/ui';
import { useNavigate } from 'react-router';
import {
  HOME_ROUTE,
  PROFILE_ROUTE,
  SEARCH_SPACE_ROUTE,
  useFetchUser,
  useMutateSetupUser,
  UserQueryKeys,
  useStore,
} from '@jooxter/core';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { createGTMGAEvent } from '@jooxter/utils';
import { useShallow } from 'zustand/shallow';

export const OnboardingFinalize = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: mutateSetupUser } = useMutateSetupUser();
  const { user } = useFetchUser();
  const hasCollaborators = user?.capabilities?.features?.collaborators;
  const queryClient = useQueryClient();
  const [token] = useStore(useShallow((state) => [state.token]));
  const [setupDone, setSetupDone] = useState(false);

  useEffect(() => {
    if (user?.id && !setupDone) {
      validateSetup();
    }
  }, [user?.id]);

  const validateSetup = () => {
    if (user?.id) {
      mutateSetupUser(user.id)
        .then(() => setSetupDone(true))
        .catch(() => {
          toast.custom(
            (newToast) => (
              <JxtToast title={t('unknown-error')} variant={JxtToastVariantEnum.Failure} closeable={false}>
                <JxtButton
                  className="!p-0"
                  size={ButtonSizeEnum.Large}
                  variant={ButtonVariantEnum.Link}
                  onClick={() => handleClickToastButton(newToast.id)}
                >
                  {t('try-again')}
                </JxtButton>
              </JxtToast>
            ),
            {
              position: 'bottom-left',
              duration: Infinity,
            }
          );
        });
    }
  };

  const handleClickToastButton = (toastId: string) => {
    toast.remove(toastId);
    validateSetup();
  };

  const handleClickHomeButton = () => {
    createGTMGAEvent('Onboarding', 'Finalize', 'Go to Jooxter');
    onNavigate(hasCollaborators ? HOME_ROUTE : SEARCH_SPACE_ROUTE);
  };

  const handleClickProfileButton = () => {
    createGTMGAEvent('Onboarding', 'Finalize', 'Go to profile');
    onNavigate(PROFILE_ROUTE);
  };

  const onNavigate = (route: string) => {
    queryClient.setQueryData([UserQueryKeys.GetMe, token], { ...user, setupDone: true });
    navigate(route);
  };

  return (
    <JxtOnboardingLayout
      title={t<string>('onboarding.your-account-is-setup.title')}
      surtitle={t<string>('onboarding.congratulations')}
      instructions={t<string>('onboarding.your-account-is-setup.summary')}
    >
      <div className="flex flex-col gap-6">
        <JxtButton size={ButtonSizeEnum.Large} onClick={handleClickHomeButton} disabled={!setupDone}>
          {t('onboarding.lets-go')}
        </JxtButton>
        <p className="text-body-m text-neutral-100 text-center">
          {t('onboarding.your-account-is-setup.summary2-react')}
        </p>
        <JxtButton
          size={ButtonSizeEnum.Large}
          variant={ButtonVariantEnum.Link}
          onClick={handleClickProfileButton}
          disabled={!setupDone}
        >
          {t('onboarding.your-account-is-setup.acces-profile-button')}
        </JxtButton>
      </div>
    </JxtOnboardingLayout>
  );
};
