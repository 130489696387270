import { BookingCompressed } from '@jooxter/api';
import { DataPeriodEnum } from '@jooxter/utils';
import { createContext, useContext } from 'react';
import { TransformedBookings } from '../components/JxtBookingModalContent/types';

export interface IModalManageBookingsContext {
  setters: {
    showDeleteBookingsModal: (bookings: BookingCompressed[]) => void;
    onChange: (t: TransformedBookings) => void;
    setExistingBookings: (bookings: BookingCompressed[]) => void;
    setPeriodClicked: (period: DataPeriodEnum) => void;
    setMutationToExecuteAfterConfirmation: any;
    setTitle: (title: string) => void;
    setOnHide: (onHide: (() => void) | undefined) => void;
  };
  getters: {
    bookings: BookingCompressed[];
    period: DataPeriodEnum;
    transformedBookings: TransformedBookings;
    show: boolean;
    onConfirm?: () => void;
    onHide?: () => void;
    mutationToExecuteAfterConfirmation: any;
    title: string;
  };
}

export const ModalManageBookingsContext = createContext<IModalManageBookingsContext | null>(null);

export const useModalManageBookingsContext = (): IModalManageBookingsContext | null => {
  return useContext(ModalManageBookingsContext);
};
