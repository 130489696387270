import { IManageMyResourcesCalendar } from './types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import luxonPlugin from '@fullcalendar/luxon3';
// eslint-disable-next-line import/named
import { DateSpanApi, DatesSetArg, EventClickArg, MoreLinkContentArg } from '@fullcalendar/core';
import { renderEventClassNames, useFullCalendar, useFullCalendarLocale } from '@jooxter/fullcalendar';
import { useTranslation } from 'react-i18next';
import { BookingDisplayModeEnum, JxtButton, JxtMonthPicker } from '@jooxter/ui';
import { IRange, fromISO, getButtonText, now, prependCalendarIconToDatePickerBtn } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useClickOutside, useDebouncedValue, useViewportSize } from '@mantine/hooks';
import { Popover } from '@headlessui/react';
import './styles.scss';

export const ManageMyResourcesCalendar = ({
  timezone,
  user,
  events = [],
  onRangeChange,
  onSelect,
  onEventSelect,
}: IManageMyResourcesCalendar) => {
  const { i18n, t } = useTranslation();
  const { locale } = useFullCalendarLocale(i18n.language);
  const [range, setRange] = useState<IRange>();
  const [debouncedRange] = useDebouncedValue(range, 300);
  const { width } = useViewportSize();
  const calendarRef = useRef<FullCalendar>(null);
  const datePickerRef = useClickOutside(() => setShowDatePicker(false));
  const [datePickerValue, setDatePickerValue] = useState<Date>(new Date());
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [internalRange, setInternalRange] = useState<IRange>({
    from: now().startOf('month'),
    to: now().plus({ month: 1 }).startOf('month'),
  });
  const datePickerButton = document.querySelector('.fc-datePickerButton-button');
  const openSliderButton = document.querySelector<HTMLButtonElement>('.fc-openSliderButton-button');
  const [navbarOffset, setNavbarOffset] = useState(0);
  const containerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setNavbarOffset(node.getBoundingClientRect().top);
    }
  }, []);
  const { renderEventContent } = useFullCalendar({
    showHours: true,
    showTitle: true,
    bookingMode: BookingDisplayModeEnum.Compact,
    user: user,
    view: 'dayGridMonth',
  });

  const datePickerOverlayPosition: { top: number; left?: number; right?: number } | undefined = useMemo(() => {
    return datePickerButton
      ? {
          top: datePickerButton.getBoundingClientRect().bottom + 24 - navbarOffset,
          left: datePickerButton.getBoundingClientRect().left - 24 - datePickerButton.getBoundingClientRect().width / 2,
        }
      : undefined;
  }, [
    width,
    navbarOffset,
    datePickerButton?.getBoundingClientRect().bottom,
    datePickerButton?.getBoundingClientRect().left,
    datePickerButton?.getBoundingClientRect().width,
  ]);

  const toggleDatePicker = useCallback(() => {
    setShowDatePicker(!showDatePicker);
  }, [showDatePicker]);
  const [datePickerButtonText, setDatePickerButtonText] = useState<string>();

  const headerToolbar = {
    left: 'prev,next today',
    center: 'datePickerButton',
    right: 'openSliderButton',
  };

  const viewOptions = {
    dayGridMonth: {
      dayMaxEvents: 2,
      nowIndicator: true,
      dayHeaderFormat: {
        weekday: 'long' as const,
      },
    },
  };

  const plugins = [
    dayGridPlugin,
    interactionPlugin,
    listPlugin,
    luxonPlugin,
    resourceTimeGridPlugin,
    resourceTimelinePlugin,
    scrollGridPlugin,
  ];

  useEffect(() => {
    if (internalRange) {
      setDatePickerButtonText(getButtonText(internalRange, 'dayGridMonth'));
      setDatePickerValue(internalRange.from.toJSDate());
    }
  }, [internalRange?.from.toISO(), internalRange?.to.toISO()]);

  useEffect(() => {
    if (debouncedRange) {
      onRangeChange({
        from: debouncedRange.from,
        to: debouncedRange.to,
      });
    }
  }, [debouncedRange]);

  useEffect(() => {
    if (datePickerButtonText) {
      prependCalendarIconToDatePickerBtn();
    }
  }, [datePickerButtonText]);

  const selectAllowCallback = (selectInfo: DateSpanApi): boolean => {
    const dayStart = now(timezone).startOf('day');
    const rangeInPast =
      DateTime.fromJSDate(selectInfo.end, { zone: timezone }) < dayStart ||
      DateTime.fromJSDate(selectInfo.start, { zone: timezone }) < dayStart;

    if (rangeInPast) {
      return false;
    }

    return true;
  };

  const handleDateChange = (e: Date) => {
    setDatePickerValue(e);
    if (calendarRef.current?.getApi().getOption('timeZone')) {
      // rebuilding date from JS Date because of a weird fullcalendar behavior
      const dt = DateTime.fromObject(
        {
          year: e.getFullYear(),
          month: e.getMonth() + 1,
          day: e.getDate(),
        },
        { zone: 'utc' }
      ).setZone(calendarRef.current?.getApi().getOption('timeZone'));
      calendarRef.current?.getApi().gotoDate(dt.toJSDate());
      toggleDatePicker();
    }
  };

  const datesSet = useCallback(
    (args: DatesSetArg) => {
      setRange({ from: fromISO(args.startStr, user.timezone), to: fromISO(args.endStr, user.timezone) });
      if (calendarRef.current) {
        setInternalRange({
          from: DateTime.fromJSDate(calendarRef.current?.getApi().view.currentStart),
          to: DateTime.fromJSDate(calendarRef.current?.getApi().view.currentEnd),
        });
      }
    },
    [user.timezone]
  );

  const onEventClick = (event: EventClickArg) => {
    onEventSelect(parseInt(event.event.id, 10), event.event.extendedProps.resourceId);
  };

  const moreLinkWrapper = (args: MoreLinkContentArg) => {
    const text = t('calendar.more', { num: args.num });
    return <span title={text}>{text}</span>;
  };

  const onOpenSliderButtonClick = (e: MouseEvent) => {
    const view = calendarRef.current?.getApi().view;

    if (view) {
      let startDateTime = DateTime.fromJSDate(view.currentStart, { zone: timezone });

      if (startDateTime < now(timezone)) {
        startDateTime = now(timezone);
      }

      const startJS = startDateTime.toJSDate();
      const endJS = startDateTime.plus({ day: 1 }).startOf('day').toJSDate();

      onSelect(
        {
          jsEvent: e,
          allDay: false,
          view,
          start: startJS,
          startStr: startJS.toISOString(),
          end: endJS,
          endStr: endJS.toISOString(),
        },
        ['ManageSpaces', 'Create', 'Open Slider Exception From Button']
      );
    }
  };

  const onOpenSliderButtonClicked = (): void => {
    openSliderButton?.click();
  };

  return (
    <div id="fc-manageResources" className="h-[90%] relative" ref={containerRef}>
      <JxtButton
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        onClick={onOpenSliderButtonClicked}
      >
        {t('open-close-my-space')}
      </JxtButton>
      <Popover>
        {showDatePicker && datePickerOverlayPosition && (
          <Popover.Panel
            ref={datePickerRef}
            static
            className="absolute z-10"
            style={{
              top: datePickerOverlayPosition.top,
              left: datePickerOverlayPosition.left,
              right: datePickerOverlayPosition.right,
            }}
          >
            <JxtMonthPicker initialDate={datePickerValue} onDateChange={(date: Date) => handleDateChange(date)} />
          </Popover.Panel>
        )}
      </Popover>
      <FullCalendar
        initialView="dayGridMonth"
        ref={calendarRef}
        plugins={plugins}
        events={events}
        timeZone={timezone}
        stickyHeaderDates={true}
        displayEventTime={false}
        height="100%"
        datesSet={datesSet}
        eventClassNames={renderEventClassNames}
        eventContent={renderEventContent}
        views={viewOptions}
        headerToolbar={headerToolbar}
        locale={locale}
        nowIndicator={true}
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        moreLinkClassNames="text-body-s text-neutral-100 font-medium"
        slotLabelClassNames="text-body-s text-neutral-60"
        moreLinkContent={moreLinkWrapper}
        editable={false}
        select={onSelect}
        selectAllow={selectAllowCallback}
        selectable={true}
        eventClick={onEventClick}
        firstDay={1}
        customButtons={{
          datePickerButton: {
            text: datePickerButtonText,
            click: toggleDatePicker,
          },
          openSliderButton: {
            text: t<string>('open-close-my-space'),
            click: onOpenSliderButtonClick,
          },
        }}
      />
    </div>
  );
};
