import { ResourceCompressed } from '@jooxter/api';
import {
  locateResourceId,
  parseRawMatrix,
  useFetchFloorPlans,
  useFetchLocations,
  useFetchResourcesOptions,
} from '@jooxter/core';
import { uniq } from 'lodash-es';
import { useMemo } from 'react';

export const useResourceInputAdapter = (resources: ResourceCompressed[]) => {
  const floorIds = uniq(resources?.map((r) => r.floorId).filter((f): f is number => !!f));
  const { floorPlans } = useFetchFloorPlans(floorIds);
  const resourcesLocationId = useMemo(() => {
    return resources?.map((resource) => resource.locationId);
  }, [resources]);

  const { locations } = useFetchLocations(resourcesLocationId);
  const { options } = useFetchResourcesOptions(resources?.map((r) => r.id));
  const floors = locations
    .map((l) => l.floors)
    .flat()
    .filter((f) => !!f);

  const resourceSourceInput = useMemo(() => {
    return resources?.map((r, i) => {
      const floorPlan = floorPlans.find((f) => f.id === r.floorId);
      const matrix = parseRawMatrix(floorPlan?.matrix);
      const canBeLocated = !!locateResourceId(r.id, matrix);
      const locationName = locations.find((location) => location.id === r.locationId)?.name;
      const floor = floors.find((floor) => floor && floor.id === r.floorId);
      const floorNumber = floor?.number;
      const resourceOptions = options[i];

      return {
        id: r.id.toString(),
        title: r.name,
        extendedProps: {
          locationName,
          floorNumber,
          name: r.name,
          disableLocate: !canBeLocated,
          bookable: r.bookable,
          resourceTypeId: r.resourceTypeId,
          timezone: r.timezone,
          capacity: r.capacity,
          order: i,
          options: resourceOptions,
          favorite: r.favorite,
        },
      };
    });
  }, [resources, floorPlans, locations, floors, options]);

  return resourceSourceInput;
};
