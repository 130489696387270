import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  IWorkplaceCalendarContext,
  JxtButton,
  JxtToast,
  JxtToastVariantEnum,
  WorkplaceCalendarContext,
} from '@jooxter/ui';
import {
  addOneWeekIf,
  createGTMUpdateVirtualPathEvent,
  DataPeriodEnum,
  dateIsTodayAndWeAreAfternoon,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  disableIJxtWorkplaceHalfDay,
  getStandardTime,
  goToNextMondayIf,
  IJxtWorkplaceDay,
  IRange,
  ISOToLocalDate,
  ISOToLocalTime,
  JxtWorkplaceStatusButtonTypeEnum,
  JxtWorkplaceTypeWithoutUnknownEnum,
  MID_DAY,
  min,
  sameDay,
} from '@jooxter/utils';
import { useEffect, useMemo, useState } from 'react';
import { useWorkplaceToEventInputAdapter } from '../../adapters/workplaces/useWorkplaceToEventInputAdapter';
import {
  useFetchExistingBookings,
  useFetchUser,
  useFetchUserPreferences,
  useFetchWorkplaces,
  useInvalidateBookingQueries,
  useInvalidateWorkplaceQueries,
} from '../../queries';
import JxtWorkplaceCalendar from '../JxtWorkplaceCalendar';
import { IJxtWorkplaceCalendarContainer } from './types';
import { EventInput } from '@fullcalendar/core';
import {
  Booking,
  BookingCompressed,
  LocationCompressed,
  WorkplaceDto,
  WorkplaceRequestDto,
  WorkplaceRequestDtoTypeEnum,
} from '@jooxter/api';
import { DateTime, WeekdayNumbers } from 'luxon';
import { DayToWorkplaceAdapter } from './day-to-workplace.adapter';
import { useCreateAutomaticBooking } from './hooks';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { getDeskOrParkingResourceTypes } from '../../utils/resource-types';
import JxtWorkplaceStrategyLimitContainer from '../JxtWorkplaceStrategyLimitContainer';
import JxtWorkplaceSelector from '../JxtWorkplaceSelector';
import { useCreateBooking } from '../../mutations';
import { useCreateWorkplace, useCreateWorkplaces } from '../../mutations/workplace';
import { useModalManageBookingsContext } from '../../hooks';
import { getExistingBookingsOverlappingRange, shouldPostAutomaticBooking } from '../../utils/bookings';

const JxtWorkplaceCalendarContainer = ({ initialRange, onToastActionClicked }: IJxtWorkplaceCalendarContainer) => {
  const [range, setRange] = useState<IRange>(initialRange);
  const [statusType, setStatusType] = useState(JxtWorkplaceStatusButtonTypeEnum.Office);
  const isStatusTypeOffice = useMemo(() => statusType === JxtWorkplaceStatusButtonTypeEnum.Office, [statusType]);
  const { user } = useFetchUser();
  const modalManageBookingsContext = useModalManageBookingsContext();
  const { t } = useTranslation();
  const { preferences } = useFetchUserPreferences(user?.id);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [location, setLocation] = useState<
    | {
        id?: number;
        name?: string;
      }
    | undefined
  >({ id: user?.location?.id, name: user?.location?.name });
  const { workplaces: fetchedWorkplaces } = useFetchWorkplaces(
    {
      ...range,
      globalSearch: user?.email,
      favoritesOnly: false,
    },
    false,
    true
  );
  const [events, setEvents] = useState<EventInput[]>([]);
  const { createAutomaticBooking, resourceTypes } = useCreateAutomaticBooking(user, preferences);
  const deskOrParkingResourceTypes = useMemo(() => getDeskOrParkingResourceTypes(resourceTypes), [resourceTypes]);
  // Get all bookings of the month
  const { bookings } = useFetchExistingBookings(deskOrParkingResourceTypes, range, user);
  const { invalidateBookingQueries } = useInvalidateBookingQueries();
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();
  const { adapt: adaptWorkplace } = useWorkplaceToEventInputAdapter(fetchedWorkplaces);

  const handleRangeChange = (newRange: IRange) => {
    if (newRange.from.equals(range.from) && newRange.to.equals(range.to)) {
      // noop to avoid infinite rerenders
      return;
    }

    setRange(newRange);
  };

  const handleLocationSelect = (location: LocationCompressed | LocationCompressed[]) => {
    if (!Array.isArray(location)) {
      if (location.id && location.name) {
        setLocation({
          id: location.id,
          name: location.name,
        });
      }
    }
  };

  const postBookingMutation = useCreateBooking(
    (booking: Booking) => {
      invalidateBookingQueries([booking.id]);
      invalidateWorkplaceQueries();
      const start = DateTime.fromISO(booking.start.dateTime, { zone: booking.resource.timezone });
      const end = DateTime.fromISO(booking.end.dateTime, { zone: booking.resource.timezone });
      const startTime = getStandardTime(start);
      const endTime = getStandardTime(end);

      toast.custom(
        (newToast) => (
          <JxtToast
            onHide={() => toast.remove(newToast.id)}
            title={t<string>('booking-created')}
            subtitle={t<string>('booking.favorite-desk.success', {
              date: start.toLocaleString(DateTime.DATE_FULL),
              startTime,
              endTime,
            })}
            variant={JxtToastVariantEnum.Success}
          >
            <JxtButton
              className="!p-0"
              size={ButtonSizeEnum.Large}
              variant={ButtonVariantEnum.Link}
              onClick={() => {
                onToastActionClicked(booking.id);
                toast.remove(newToast.id);
              }}
            >
              {t('open-booking')}
            </JxtButton>
          </JxtToast>
        ),
        {
          position: 'bottom-left',
        }
      );
    },
    (_, booking) => {
      const startDate = ISOToLocalDate(booking.start.dateTime, preferences?.resource?.timezone);
      const startTime = ISOToLocalTime(booking.start.dateTime, preferences?.resource?.timezone);
      const endTime = ISOToLocalTime(booking.end.dateTime, preferences?.resource?.timezone);

      toast.custom(
        (newToast) => (
          <JxtToast
            onHide={() => toast.remove(newToast.id)}
            title={t<string>('booking.favorite-desk.error', {
              date: startDate,
              startTime,
              endTime,
            })}
            variant={JxtToastVariantEnum.Failure}
          />
        ),
        {
          position: 'bottom-left',
        }
      );
    }
  );

  const onCreateWorkplaceSuccess = (workplace: WorkplaceDto) => {
    const automaticBooking = createAutomaticBooking(workplace.start, workplace.end);

    if (automaticBooking && shouldPostAutomaticBooking(workplace, automaticBooking, bookings, preferences)) {
      postBookingMutation.mutate(automaticBooking);
    }
  };

  const onCreateWorkplacesSuccess = (workplaces: WorkplaceDto[]) => {
    workplaces.forEach((workplace) => {
      const automaticBooking = createAutomaticBooking(workplace.start, workplace.end);

      if (automaticBooking && shouldPostAutomaticBooking(workplace, automaticBooking, bookings, preferences)) {
        postBookingMutation.mutate(automaticBooking);
      }
    });
  };

  const onCreateWorkplaceError = (error: AxiosError) => {
    const response = error.response?.data as { error: string; description: string }[];
    if (response.length > 0) {
      toast.custom(
        (newToast) => (
          <JxtToast
            onHide={() => toast.remove(newToast.id)}
            title={response[0].error}
            subtitle={response[0].description}
            variant={JxtToastVariantEnum.Failure}
          />
        ),
        {
          position: 'bottom-left',
        }
      );
    }
  };
  const postWorkplace = useCreateWorkplace(onCreateWorkplaceSuccess, onCreateWorkplaceError);
  const postWorkplaces = useCreateWorkplaces(onCreateWorkplacesSuccess, onCreateWorkplaceError);

  const createUniqueWorkplace = (date: string, period: DataPeriodEnum) => {
    if (!(modalManageBookingsContext?.getters && modalManageBookingsContext?.setters && user?.id)) {
      return;
    }
    modalManageBookingsContext?.setters?.setExistingBookings([]);
    modalManageBookingsContext?.setters?.setPeriodClicked(period);
    modalManageBookingsContext?.setters?.setTitle(t('manage-closing.what-to-do-when-out-of-office'));
    const start = DateTime.fromFormat(date, 'yyyy-MM-dd')
      .set({ hour: period === DataPeriodEnum.Am ? DEFAULT_START_TIME : MID_DAY })
      .toUTC()
      .toISO();
    const end = DateTime.fromFormat(date, 'yyyy-MM-dd')
      .set({ hour: period === DataPeriodEnum.Am ? MID_DAY : DEFAULT_END_TIME })
      .toUTC()
      .toISO();

    if (start && end) {
      const from = DateTime.fromISO(start);
      const to = DateTime.fromISO(end);
      const tmpExistingBookings = getExistingBookingsOverlappingRange({ from, to }, bookings);

      if (!isStatusTypeOffice && tmpExistingBookings.length) {
        modalManageBookingsContext?.setters?.setMutationToExecuteAfterConfirmation([
          ...(modalManageBookingsContext?.getters?.mutationToExecuteAfterConfirmation ?? []),
          {
            mutation: postWorkplace.mutateAsync,
            args: {
              userId: user.id,
              workplace: {
                start,
                end,
                type: statusType as unknown as WorkplaceRequestDtoTypeEnum,
                locationId: location?.id,
              },
            },
          },
        ]);
        modalManageBookingsContext?.setters?.showDeleteBookingsModal([
          ...(modalManageBookingsContext?.getters?.bookings ?? []),
          ...tmpExistingBookings,
        ]);
      } else {
        postWorkplace
          .mutateAsync({
            userId: user.id,
            workplace: {
              start,
              end,
              type: statusType as unknown as WorkplaceRequestDtoTypeEnum,
              locationId: location?.id,
            },
          })
          .then(invalidateWorkplaceQueries);
      }
    }
  };

  const createMultipleWorkplaces = (days: DateTime[], period: DataPeriodEnum | null) => {
    if (!(modalManageBookingsContext?.getters && modalManageBookingsContext?.setters && user?.id)) {
      return;
    }
    modalManageBookingsContext?.setters?.setExistingBookings([]);
    modalManageBookingsContext?.setters?.setPeriodClicked(period ?? DataPeriodEnum.Day);
    modalManageBookingsContext?.setters?.setTitle(t('manage-closing.what-to-do-when-out-of-office'));
    const workplacesToPost = days.flatMap((day) =>
      DayToWorkplaceAdapter.adapt(day, statusType as unknown as WorkplaceRequestDtoTypeEnum, period, location?.id)
    );
    const tmpExistingBookings: BookingCompressed[] = workplacesToPost.flatMap((w) =>
      getExistingBookingsOverlappingRange({ from: DateTime.fromISO(w.start), to: DateTime.fromISO(w.end) }, bookings)
    );
    const tmpMutations: {
      mutation: ReturnType<typeof useCreateWorkplaces>['mutateAsync'];
      args: { userId: number; workplaces: WorkplaceRequestDto[] };
    } = {
      mutation: postWorkplaces.mutateAsync,
      args: { userId: user.id, workplaces: [] },
    };
    const directMutations: { userId: number; workplaces: WorkplaceRequestDto[] } = { userId: user.id, workplaces: [] };

    for (const { start, end } of workplacesToPost) {
      if (tmpExistingBookings.length && !isStatusTypeOffice) {
        tmpMutations.args.workplaces.push({
          start,
          end,
          type: statusType as unknown as WorkplaceRequestDtoTypeEnum,
          locationId: location?.id,
        });
      } else {
        directMutations.workplaces.push({
          start,
          end,
          type: statusType as unknown as WorkplaceRequestDtoTypeEnum,
          locationId: location?.id,
        });
      }
    }

    if (tmpMutations.args.workplaces.length) {
      modalManageBookingsContext?.setters?.setMutationToExecuteAfterConfirmation([
        ...(modalManageBookingsContext?.getters?.mutationToExecuteAfterConfirmation ?? []),
        tmpMutations,
      ]);
      modalManageBookingsContext?.setters?.setExistingBookings([
        ...(modalManageBookingsContext?.getters?.bookings ?? []),
        ...tmpExistingBookings,
      ]);
      modalManageBookingsContext?.setters?.showDeleteBookingsModal([
        ...(modalManageBookingsContext?.getters?.bookings ?? []),
        ...tmpExistingBookings,
      ]);
    } else if (directMutations.workplaces.length) {
      postWorkplaces.mutateAsync(directMutations).then(invalidateWorkplaceQueries);
    }
  };

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('/WorkplaceDeclarationPanel');
  }, []);

  useEffect(() => {
    if (fetchedWorkplaces?.length) {
      setEvents(fetchedWorkplaces[0].workplaces.map((w) => adaptWorkplace(w)));
    }
  }, [fetchedWorkplaces, adaptWorkplace]);

  useEffect(() => {
    modalManageBookingsContext?.setters.setExistingBookings([]);
    modalManageBookingsContext?.setters.setMutationToExecuteAfterConfirmation([]);
  }, [range]);

  const callbacks: IWorkplaceCalendarContext['callbacks'] = {
    onJxtWorkplaceCalendarUnitClick: (date: string, period: DataPeriodEnum) => {
      modalManageBookingsContext?.setters.setExistingBookings([]);
      createUniqueWorkplace(date, period);
    },
    onJxtWorkplaceCalendarDayHeaderClick: (date: string) => {
      createMultipleWorkplaces([DateTime.fromFormat(date, 'yyyy-MM-dd')], null);
    },
    onJxtShareWorkplaceRangeDayClick: (dayOfWeek: WeekdayNumbers, date: DateTime) => {
      let day = date.set({ weekday: dayOfWeek });

      // if day of month > 7, it means that Monday is in the previous month
      // in this case we go straight to next Monday
      day = addOneWeekIf(day, day.day > 7);

      const clickedMonth = day.month;
      const days: DateTime[] = [];

      while (clickedMonth === day.month) {
        const workplaceDay = events.find((e) => sameDay(e.extendedProps?.date, day))?.extendedProps as IJxtWorkplaceDay;

        if (
          !disableIJxtWorkplaceHalfDay(workplaceDay.morning) ||
          !disableIJxtWorkplaceHalfDay(workplaceDay.afternoon)
        ) {
          days.push(day);
        }

        day = day.plus({ days: 7 });
      }

      createMultipleWorkplaces(days, null);
    },
    onJxtShareWorkplaceRangePeriodClick: (weekNumber: number, period: DataPeriodEnum, date: DateTime) => {
      if (![DataPeriodEnum.Am, DataPeriodEnum.Pm].includes(period)) {
        return;
      }

      // if the month starts on a week-end, go to next Monday
      date = goToNextMondayIf(date, [6, 7].includes(date.weekday));

      let currentDay = weekNumber > 0 ? date.plus({ days: 7 * weekNumber }).startOf('week') : date;
      const endOfWeek = min(currentDay.endOf('week'), currentDay.endOf('month'));
      const days: DateTime[] = [];

      while (currentDay < endOfWeek) {
        if ([6, 7].includes(currentDay.weekday)) {
          currentDay = currentDay.plus({ days: 1 });
          continue;
        }

        if (period === DataPeriodEnum.Am && dateIsTodayAndWeAreAfternoon(currentDay)) {
          currentDay = currentDay.plus({ days: 1 });
          continue;
        }

        const workplaceDay = events.find((e) => sameDay(e.extendedProps?.date, currentDay))
          ?.extendedProps as IJxtWorkplaceDay;

        if (
          !disableIJxtWorkplaceHalfDay(period === DataPeriodEnum.Am ? workplaceDay.morning : workplaceDay.afternoon)
        ) {
          days.push(currentDay);
        }

        currentDay = currentDay.plus({ days: 1 });
      }

      createMultipleWorkplaces(days, period);
    },
  };

  return (
    <>
      <div className="flex flex-col px-1 overflow-y-auto">
        <JxtWorkplaceSelector
          statusType={statusType}
          onChangeStatusType={setStatusType}
          defaultLocation={location}
          onChangeLocation={handleLocationSelect}
        />
        <WorkplaceCalendarContext.Provider value={{ callbacks }}>
          <JxtWorkplaceCalendar
            timezone={user?.timezone}
            range={range}
            rangeChange={handleRangeChange}
            events={events}
          />
        </WorkplaceCalendarContext.Provider>
      </div>
      {user?.id && (
        <JxtWorkplaceStrategyLimitContainer
          workplaceType={statusType as unknown as JxtWorkplaceTypeWithoutUnknownEnum}
          locationId={location?.id}
          classNames="pt-4"
          remainingBookings={null}
          organizerId={user?.id}
          isBooking={false}
        />
      )}
    </>
  );
};

export default JxtWorkplaceCalendarContainer;
