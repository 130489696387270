import {
  Floor,
  FloorCompressed,
  FloorService,
  IGetFloorResourcesParameters,
  IGetFloorsParameters,
  ResourceFloorDto,
} from '@jooxter/api';
import { keepPreviousData, useQueries, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { FloorQueryKeys } from '../queryKeys';
import { FloorStaleTimeEnum } from '../staleTimes';

export const useFetchFloorPlan = (
  id?: number
): {
  floorPlan: Floor | null | undefined;
} => {
  const fetchFloorPlan = useCallback(() => {
    if (id) {
      return FloorService.getFloorPlan(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: floorPlan } = useQuery({
    queryKey: [FloorQueryKeys.GetFloorPlan, id],
    queryFn: () => fetchFloorPlan(),
    placeholderData: keepPreviousData,
    staleTime: FloorStaleTimeEnum.GetFloorPlan,
  });

  return { floorPlan };
};

export const useFetchFloorPlanResources = (
  id: number,
  options?: IGetFloorResourcesParameters
): {
  resources: ResourceFloorDto[] | null | undefined;
} => {
  const fetchFloorPlanResources = () => FloorService.getFloorPlanResources(id, options);

  const { data: resources } = useQuery({
    queryKey: [FloorQueryKeys.GetFloorPlanResources, id, options],
    queryFn: fetchFloorPlanResources,
    placeholderData: keepPreviousData,
    staleTime: FloorStaleTimeEnum.GetFloorPlanResources,
    refetchInterval: 30000,
  });

  return { resources };
};

export const useFetchFloorPlans = (ids: number[]) => {
  const fetchFloorPlan = (id: number) => FloorService.getFloorPlan(id);

  const { data: floorPlans, isPending } = useQueries({
    queries: ids.map((id: number) => ({
      queryKey: [FloorQueryKeys.GetFloorPlan, id],
      queryFn: () => fetchFloorPlan(id),
      placeholderData: keepPreviousData,
      staleTime: FloorStaleTimeEnum.GetFloorPlan,
      enabled: !!id,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is Floor => !!r),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { floorPlans, isPending };
};

export const useFetchFloor = (
  id?: number,
  options?: { retry?: boolean | number }
): { floor?: FloorCompressed | null; isError: boolean } => {
  const fetchFloor = () => (id ? FloorService.getFloor(id) : Promise.resolve(null));

  const { data: floor, isError } = useQuery({
    refetchInterval: 30000,
    queryKey: [FloorQueryKeys.GetFloor, id, options?.retry],
    queryFn: () => fetchFloor(),
    placeholderData: keepPreviousData,
    staleTime: FloorStaleTimeEnum.GetFloor,
    retry: options?.retry,
  });

  return { floor, isError };
};

export const useFetchFloors = (ids?: number[]): { floors: FloorCompressed[]; isPending: boolean } => {
  const fetchFloor = (id: number) => FloorService.getFloor(id);

  const { data: floors, isPending } = useQueries({
    queries:
      ids?.map((id: number) => ({
        queryKey: [FloorQueryKeys.GetFloor, id],
        queryFn: () => fetchFloor(id),
        placeholderData: keepPreviousData,
        staleTime: FloorStaleTimeEnum.GetFloor,
      })) ?? [],
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is FloorCompressed => !!r),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { floors, isPending };
};

export const useFetchFloorsSearch = (options?: IGetFloorsParameters, enabled = true) => {
  const fetchFloors = () => FloorService.getFloorsWithPagination(options);

  const { data: floors } = useQuery({
    queryKey: [FloorQueryKeys.GetFloorsSearch, options],
    queryFn: () => fetchFloors(),
    staleTime: FloorStaleTimeEnum.GetFloorsSearch,
    enabled,
  });

  return { floors };
};
