import {
  Location,
  ResourceCompressed,
  ResourceStateDtoStatusEnum,
  ResourceTypeDto,
  ResourceTypeDtoMetaTypeEnum,
} from '@jooxter/api';
import { TResourceItem } from '@jooxter/ui/src/components/JxtResourceItem/types';
import { JxtResourceStatusInfos, JxtResourcesTypeEnum, JxtTagColorsEnum } from '@jooxter/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { locateResourceId, parseRawMatrix } from '../../components/JxtFloorPlanPictos/helpers';
import { useFetchFloorPlans, useFetchFloors, useFetchLocations, useFetchResourceTypes } from '../../queries';

export const useResourceCompressedToTResourceItemAdapter = (resources: ResourceCompressed[]): TResourceItem[] => {
  const { resourceTypes } = useFetchResourceTypes();
  const locationIds = useMemo(() => resources.map((r) => r.locationId), [resources]);
  const floorIds = useMemo(() => resources.map((r) => r.floorId).filter((i): i is number => !!i), [resources]);
  const { locations } = useFetchLocations(locationIds);
  const { floors } = useFetchFloors(floorIds);
  const { floorPlans } = useFetchFloorPlans(floorIds);
  const { t } = useTranslation();

  const shouldReturnNullisResource = (location?: Location, resourceType?: ResourceTypeDto): boolean => {
    if (!location?.name) {
      return true;
    }

    if (!resourceType?.name) {
      return true;
    }

    if (!resourceType?.metaType) {
      return true;
    }

    return false;
  };

  const getText = (resource: ResourceCompressed, isZone: boolean): string => {
    if (
      isZone &&
      resource.state?.status &&
      [ResourceStateDtoStatusEnum.Free, ResourceStateDtoStatusEnum.Busy].includes(resource.state?.status)
    ) {
      return t<string>('zone-availability', {
        remainingSeats: getRemainingSeats(resource.state?.occupantsCount ?? 0, resource.capacity),
      });
    }

    return t<string>('resource-list-item-status', { resourceStatus: resource.state?.status });
  };

  const getRemainingSeats = (occupantsCount: number, capacity: number): number => {
    if (capacity < occupantsCount) {
      console.error("Logic exception: there can't be more remaining seats than the resource's capacity");

      return 0;
    }

    const remainingSeats = capacity - occupantsCount;

    if (remainingSeats < 0) {
      return 0;
    }

    return capacity - occupantsCount;
  };

  const getFillPercentage = (occupantsCount: number, capacity: number): number => {
    const remainingSeats = getRemainingSeats(occupantsCount, capacity);

    if (remainingSeats < 0) {
      return 1;
    }

    return 1 - remainingSeats / capacity;
  };

  const getColorForZone = (occupantsCount = 0, capacity: number): JxtTagColorsEnum => {
    const fillPercentage = getFillPercentage(occupantsCount, capacity);

    if (fillPercentage === 0 || fillPercentage < 0.5) {
      return JxtTagColorsEnum.GREEN;
    } else if (fillPercentage < 1) {
      return JxtTagColorsEnum.ORANGE;
    } else {
      return JxtTagColorsEnum.RED;
    }
  };

  const getStatus = (resource: ResourceCompressed, isZone: boolean): TResourceItem['status'] | null => {
    if (!resource.state?.status) {
      return null;
    }

    const status = {
      ...JxtResourceStatusInfos[resource.state.status],
      occupantsCount: resource.state.occupantsCount,
      text: getText(resource, isZone),
    };

    if (isZone) {
      if ([ResourceStateDtoStatusEnum.Free, ResourceStateDtoStatusEnum.Busy].includes(resource.state.status)) {
        return {
          ...status,
          color: getColorForZone(resource.state.occupantsCount, resource.capacity),
        };
      }
    }

    return status;
  };

  const resourceItems = useMemo(() => {
    return resources
      .map((resource) => {
        const fullLocation = locations?.find((location) => location?.id === resource.locationId) ?? undefined;
        const fullFloor = floors?.find((floor) => floor.id === resource.floorId) ?? undefined;
        const resourceType = resourceTypes?.find((rt) => rt.id === resource.resourceTypeId);
        const floorPlan = floorPlans?.find((fp) => fp.id === resource.floorId) ?? undefined;
        const matrix = parseRawMatrix(floorPlan?.matrix);
        const canBeLocated = !!locateResourceId(resource.id, matrix);

        if (
          !resource.state?.status ||
          !fullLocation ||
          !resourceType ||
          shouldReturnNullisResource(fullLocation, resourceType)
        ) {
          return null;
        }

        const resourceItem: TResourceItem = {
          name: resource.name,
          id: resource.id,
          email: resource.email,
          resourceType: resourceType.name,
          capacity: resource.capacity,
          isFavorite: resource.favorite,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          locationId: fullLocation.id!,
          locationName: fullLocation.name,
          floorId: fullFloor?.id,
          floorName: fullFloor?.name,
          floorNumber: fullFloor?.number,
          bookable: resource.bookable,
          status: getStatus(resource, !!resourceType.allowOverlap),
          metaTypeIcon: JxtResourcesTypeEnum[resourceType.metaType as ResourceTypeDtoMetaTypeEnum],
          picture: resource.pictures?.[0],
          isZone: resourceType.allowOverlap,
          disableLocate: !canBeLocated,
        };
        return resourceItem;
      })
      .filter((r): r is TResourceItem => !!r);
  }, [resources, resourceTypes, locations, floors, floorPlans]);

  return resourceItems;
};
