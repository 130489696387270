import { User } from '@jooxter/api';

export const setupWootric = (user: User): void => {
  if (!user.registrationDate || !user.location?.id) {
    return;
  }

  const timeStampRegistration = Math.round(new Date(user.registrationDate).getTime() / 1000);

  const wootricSettings: IWootricSettings = {
    account_token: import.meta.env.VITE_WOOTRIC_ID,
    email: user.email,
    created_at: timeStampRegistration,
    properties: {
      location_id: user.location.id,
      user_type: user.type,
      corporation_name: user.corporation?.name,
    },
  };

  window.wootricSettings = wootricSettings;

  if (window.WootricSurvey?.run) {
    window.WootricSurvey.run();
  }
};
