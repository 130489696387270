import clsx from 'clsx';
import JxtCalendarDayWorkplace from '../JxtCalendarDayWorkplace';
import { IJxtColleagueCalendarWorkplace } from './types';

const JxtColleagueCalendarWorkplace = ({ workplaceDay, onClick, title, isToday }: IJxtColleagueCalendarWorkplace) => {
  return (
    <div
      className={clsx(
        'flex flex-col gap-2 p-3 bg-white rounded-2xl',
        isToday ? 'shadow-[0_0_0_2px] shadow-primary-100' : ''
      )}
    >
      {title && <span className="text-neutral-140 text-title-m font-medium text-center">{title}</span>}
      <JxtCalendarDayWorkplace workplaceDay={workplaceDay} onClick={onClick} />
    </div>
  );
};

export default JxtColleagueCalendarWorkplace;
