import { User, UserTypeEnum } from '@jooxter/api';
import {
  BOOKINGS_MINE_ROUTE,
  HOME_ROUTE,
  MANAGE_SPACES_ROUTE,
  SEARCH_SPACE_ROUTE,
  USER_PLANNING_ROUTE,
} from '@jooxter/core';
import { IJxtRoute } from '@jooxter/ui';
import { isTeams } from '@jooxter/utils';
// eslint-disable-next-line import/named
import { TFunction } from 'i18next';

export const showLogout = !isTeams();
export const showAdmin = (user: User) => [UserTypeEnum.Admin, UserTypeEnum.Manager].includes(user.type) && !isTeams();
export const showManageMySpaces = (user: User) => user.capabilities?.features?.resourceManager;
export const helpCenterItem = (t: TFunction<'translation', undefined, 'translation'>): IJxtRoute => ({
  name: t('navbar.help'),
  to: t<string>('help-center-url'),
  icon: 'question-circle',
  openInNewTab: true,
});

export const manageSpacesItem = (t: TFunction<'translation', undefined, 'translation'>): IJxtRoute => ({
  name: t('navbar.manage-my-spaces'),
  to: MANAGE_SPACES_ROUTE,
  icon: 'cube',
});

export const adminItem = (t: TFunction<'translation', undefined, 'translation'>): IJxtRoute => ({
  name: t('navigation.administration-page-name'),
  to: import.meta.env.VITE_ADMIN_URL,
  icon: 'cog',
});

export const getMainNav = (hasCollaborators: boolean, t: TFunction<'translation', undefined, 'translation'>) =>
  hasCollaborators
    ? [
        {
          name: t('navbar.home'),
          to: HOME_ROUTE,
          icon: 'home',
        },
        {
          name: t('navbar.spaces'),
          to: SEARCH_SPACE_ROUTE,
          icon: 'building',
        },
        {
          name: t('navbar.my-bookings'),
          to: BOOKINGS_MINE_ROUTE,
          icon: 'clock',
        },
        {
          name: t('navbar.colleagues'),
          to: USER_PLANNING_ROUTE,
          icon: 'users',
        },
      ]
    : [
        {
          name: t('navbar.spaces'),
          to: SEARCH_SPACE_ROUTE,
          icon: 'building',
        },
        {
          name: t('navbar.my-bookings'),
          to: BOOKINGS_MINE_ROUTE,
          icon: 'clock',
        },
      ];
