import { CSSProperties } from 'react';

export enum JxtAvatarSizeEnum {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

export enum JxtAvatarDiameterEnum {
  XS = 28,
  S = 36,
  M = 40,
  L = 48,
  XL = 104,
}

export enum JxtFavoriteDiameterEnum {
  XS = 12,
  S = 16,
  M = 16,
  L = 20,
  XL = 36,
}

export enum JxtAvatarTextSizeEnum {
  XS = 'text-body-xs',
  S = 'text-body-xs font-medium',
  M = 'text-title-s font-medium',
  L = 'text-title-m font-medium',
  XL = 'text-headline-xxl font-bold',
}

export enum JxtAvatarStarSizeEnum {
  XS = 'text-[6px]',
  S = 'text-[8px]',
  M = 'text-[8px]',
  L = 'text-[10px]',
  XL = 'text-[18px]',
}

export interface IJxtAvatar {
  alt: string;
  x?: number;
  y?: number;
  size?: JxtAvatarSizeEnum;
  picture?: string;
  text?: string;
  favorite?: boolean;
  className?: string;
  style?: CSSProperties;
}
