import { useTranslation } from 'react-i18next';
import JxtOnboardingLayout from '../../../layouts/OnboardingLayout';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton, JxtNotificationUserListItem } from '@jooxter/ui';
import { useNavigate } from 'react-router';
import { useFetchUser, useFetchUserNotifications, useMutateUserNotifications } from '@jooxter/core';
import { createGTMGAEvent, updateAllNotificationsByType } from '@jooxter/utils';
import { useOnboardingNavigation } from '../hooks';
import { OnboardingPagesEnum } from '../types';
import { useEffect, useState } from 'react';

export const OnboardingNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useFetchUser();
  const { getPrevLink, getNextLink } = useOnboardingNavigation(OnboardingPagesEnum.NOTIFICATIONS);
  const { notifications } = useFetchUserNotifications(user?.id);
  const { mutate: mutateNotifications } = useMutateUserNotifications();
  const [shouldTriggerMailGTM, setShouldTriggerMailGTM] = useState(true);
  const [shouldTriggerPushGTM, setShouldTriggerPushGTM] = useState(true);

  useEffect(() => {
    if (notifications?.['global']) {
      setShouldTriggerMailGTM(notifications['global']['mail'] ?? true);
      setShouldTriggerPushGTM(notifications['global']['push'] ?? true);
    }
  }, [notifications]);

  if (!notifications?.['global']) {
    return null;
  }

  const handleClickNotification = (type: 'push' | 'mail') => {
    if (notifications['global']?.[type] !== undefined && user?.id) {
      type === 'push'
        ? setShouldTriggerPushGTM(!notifications['global']['push'])
        : setShouldTriggerMailGTM(!notifications['global']['mail']);
      const clone = { ...notifications };
      mutateNotifications({
        id: user.id,
        payload: updateAllNotificationsByType(clone, type, !notifications['global'][type]),
      });
    }
  };

  const handleClickRouteButton = (route: string) => {
    if (shouldTriggerMailGTM) {
      createGTMGAEvent('Onboarding', 'Notifications', 'Select web');
    }
    if (shouldTriggerPushGTM) {
      createGTMGAEvent('Onboarding', 'Notifications', 'Select mobile');
    }
    navigate(route);
  };

  return (
    <JxtOnboardingLayout
      title={t<string>('onboarding.notifications.title')}
      instructions={t<string>('onboarding.notifications.explanation')}
    >
      <div className="flex flex-col gap-6">
        <JxtNotificationUserListItem
          title={t('notification-settings.global')}
          isMobileActive={notifications['global']['push']}
          isEmailActive={notifications['global']['mail']}
          onClickMobile={() => handleClickNotification('push')}
          onClickEmail={() => handleClickNotification('mail')}
        />
        <div className="flex justify-between">
          <JxtButton
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => handleClickRouteButton(getPrevLink())}
          >
            {t('previous')}
          </JxtButton>
          <JxtButton size={ButtonSizeEnum.Large} onClick={() => handleClickRouteButton(getNextLink())}>
            {t('next')}
          </JxtButton>
        </div>
      </div>
    </JxtOnboardingLayout>
  );
};
