import { BookingCompressed } from '@jooxter/api';
import { DataPeriodEnum, IRange } from '@jooxter/utils';

export interface IJxtBookingModalContent {
  bookings: BookingCompressed[];
  period: DataPeriodEnum;
  onChange: (transformedBookings: TransformedBookings) => void;
  readonly: boolean;
  title: string;
}

export type TransformedBookings = {
  delete: number[];
  refuse: number[];
  update: { id: number; newRange: IRange }[];
};

export enum BookingCompressedActionEnum {
  DELETE = 'Delete',
  DELETE_ALL = 'Delete all',
  KEEP = 'Keep',
  KEEP_ALL = 'Keep all',
  SWITCH_FROM_TO = 'SwitchFromTo',
  PERSONALIZED = 'Personalized',
}

type TUnitaryBookingCompressedActionEnum =
  | BookingCompressedActionEnum.DELETE
  | BookingCompressedActionEnum.KEEP
  | BookingCompressedActionEnum.SWITCH_FROM_TO;

export const isUnitaryBookingCompressedActionEnum = (
  str: BookingCompressedActionEnum
): str is TUnitaryBookingCompressedActionEnum => {
  return (
    str === BookingCompressedActionEnum.DELETE ||
    str === BookingCompressedActionEnum.KEEP ||
    str === BookingCompressedActionEnum.SWITCH_FROM_TO
  );
};
