import { IJxtNotificationUserListItem } from './types';
import JxtNotificationDevice from '../JxtNotificationDevice';
import clsx from 'clsx';

const JxtNotificationUserListItem = ({
  title,
  description,
  isEmailActive,
  isMobileActive,
  onClickEmail,
  onClickMobile,
}: IJxtNotificationUserListItem) => {
  return (
    <div className="w-full flex gap-2">
      <div className="flex flex-col flex-1 justify-center">
        <span className="text-neutral-140 text-body-m font-normal">{title}</span>
        {description && <span className="text-neutral-100 text-body-s font-normal">{description}</span>}
      </div>
      {isMobileActive !== undefined && (
        <JxtNotificationDevice
          icon="mobile"
          isActive={isMobileActive}
          onClick={onClickMobile}
          classNames={clsx(isEmailActive === undefined && 'mr-12')}
        />
      )}
      {isEmailActive !== undefined && (
        <JxtNotificationDevice icon="envelope" isActive={isEmailActive} onClick={onClickEmail} />
      )}
    </div>
  );
};

export default JxtNotificationUserListItem;
