// eslint-disable-next-line import/named
import { Theme, ThemeConfig } from 'react-select';

export const ThemeFactory =
  (theme?: ThemeConfig) =>
  (baseTheme: Theme): Theme => ({
    ...baseTheme,
    borderRadius: 8,
    ...theme,
  });
