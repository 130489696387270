import { EventInput } from '@fullcalendar/core';
import {
  BookingCompressedListItemAdapter,
  JxtMyBookingsCalendar,
  useFetchUser,
  useSearchBookings,
  useStore,
} from '@jooxter/core';
import { createGTMUpdateVirtualPathEvent, IRange } from '@jooxter/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/shallow';

export const MyBookings = () => {
  const { user } = useFetchUser();
  const [filter] = useStore(useShallow((state) => [state.filter]));
  const containerRef = useRef<HTMLDivElement>(null);
  const initialDate = filter.resource.date.from;
  const [range, setRange] = useState<IRange>();
  const [events, setEvents] = useState<EventInput[]>([]);
  const {
    bookings,
    isLoading: isFetchingBookings,
    hasNextPage: hasNextBookingsPage,
    fetchNextPage: fetchNextBookingsPage,
  } = useSearchBookings({
    ...range,
    participantId: user?.id,
  });
  const navbarOffset = useMemo(() => {
    if (containerRef.current) {
      return containerRef.current.getBoundingClientRect().top;
    }
    return 0;
  }, [containerRef.current]);

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('My bookings');
  }, []);

  useEffect(() => {
    if (hasNextBookingsPage) {
      fetchNextBookingsPage();
    }
  }, [bookings, isFetchingBookings, hasNextBookingsPage, fetchNextBookingsPage]);

  useEffect(() => {
    if (bookings && !isFetchingBookings) {
      setEvents(bookings.map((b) => BookingCompressedListItemAdapter.adapt(b, { title: 'resource' })));
    }
  }, [bookings, isFetchingBookings]);

  const onFullCalendarRangeChange = (range: IRange) => {
    setRange(range);
  };

  return (
    <div
      className="bg-white px-6 pt-4"
      style={{
        height: `calc(100vh - ${navbarOffset}px)`,
      }}
      ref={containerRef}
    >
      <JxtMyBookingsCalendar
        timezone={user?.timezone}
        events={events}
        initialDate={initialDate}
        onFullCalendarRangeChange={onFullCalendarRangeChange}
      />
    </div>
  );
};
