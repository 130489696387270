import { IJxtSelectedFilter } from './types';

const JxtSelectedFilter = ({ text, onClose }: IJxtSelectedFilter) => {
  return (
    <div className="select-none last:mr-4 w-max shrink-0 flex items-center justify-center gap-1 p-2 rounded-md text-body-s bg-primary-20 text-primary-150">
      {text}

      <div
        className="flex items-center justify-center cursor-pointer text-primary-100 hover:text-primary-110 size-5"
        onClick={onClose}
      >
        <i className="fas fa-times" />
      </div>
    </div>
  );
};

export default JxtSelectedFilter;
