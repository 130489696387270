import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import {
  IJxtWorkplacesListItem,
  IJxtWorkplacesWeek,
  JxtButton,
  JxtWorkplacesList,
  JxtWorkplacesListItem,
  useModalWorkplaceContext,
} from '@jooxter/ui';
import { BookingCompressed } from '@jooxter/api';
import { IHomeWorkplacesWeek } from './types';
import { OffCanvasModeEnum, now } from '@jooxter/utils';
import { HomeWorkplacesFavoritesEmpty } from '../HomeWorkplacesFavoritesEmpty';
import {
  useFetchOldUserScheduler,
  useFetchWorkplaces,
  useOffCanvas,
  useBookingOffCanvasConfiguration,
  useModalLocateUserContext,
  useUsersWorkplacesToIJxtWorkplacesListItemsAdapter,
  ChooseWorkplaceOverlayProvider,
  JxtModalWorkplace,
  JxtWorkplaceCalendarContainer,
} from '@jooxter/core';
import { DateTime } from 'luxon';

export const HomeWorkplacesWeek = ({ range, user }: IHomeWorkplacesWeek) => {
  const { t } = useTranslation();
  const modalLocateUserContext = useModalLocateUserContext();
  const modalWorkplaceContext = useModalWorkplaceContext();
  const [favoritesWorkplaces, setFavoritesWorkplaces] = useState<IJxtWorkplacesListItem[]>();
  const [personalWorkplaces, setPersonalWorkplaces] = useState<IJxtWorkplacesListItem[]>();
  const [favoritesWorkplacesWithBookings, setFavoritesWorkplacesWithBookings] = useState<IJxtWorkplacesListItem[]>([]);
  const [personalWorkplacesWithBookings, setPersonalWorkplacesWithBookings] = useState<IJxtWorkplacesListItem>();
  const { schedulers: favoriteOldSchedulers } = useFetchOldUserScheduler({ ...range, favoritesOnly: true });
  const { schedulers: personalOldSchedulers } = useFetchOldUserScheduler({
    ...range,
    favoritesOnly: false,
    globalSearch: user.email,
  });
  const { workplaces: fetchedPersonalWorkplaces } = useFetchWorkplaces(
    {
      ...range,
      globalSearch: user.email,
    },
    true,
    true
  );
  const { workplaces: fetchedFavoritesWorkplaces } = useFetchWorkplaces(
    {
      ...range,
      favoritesOnly: true,
    },
    true
  );
  const { adapt: adaptPersonalWorkplaces } = useUsersWorkplacesToIJxtWorkplacesListItemsAdapter(
    fetchedPersonalWorkplaces?.length ? [fetchedPersonalWorkplaces[0]] : []
  );
  const { adapt: adaptFavoritesWorkplaces } =
    useUsersWorkplacesToIJxtWorkplacesListItemsAdapter(fetchedFavoritesWorkplaces);
  const offCanvasContext = useOffCanvas();
  const bookings: Map<number, BookingCompressed[]> = useMemo(() => {
    const map = new Map<number, BookingCompressed[]>();

    favoriteOldSchedulers?.forEach((scheduler) => {
      if (scheduler.bookings?.length) {
        map.set(
          scheduler.id,
          scheduler.bookings
            .filter(
              (b) =>
                now().startOf('day') < DateTime.fromISO(b.end.dateTime) && DateTime.fromISO(b.end.dateTime).weekday < 6
            )
            .sort((a, b) => {
              if (a.start.dateTime < b.start.dateTime) {
                return -1;
              }

              return 1;
            })
        );
      }
    });

    if (personalOldSchedulers?.[0]?.bookings) {
      map.set(
        personalOldSchedulers[0].id,
        personalOldSchedulers[0].bookings.filter((b) => DateTime.fromISO(b.end.dateTime).weekday < 6)
      );
    }

    return map;
  }, [favoriteOldSchedulers, personalOldSchedulers]);

  const { offCanvasConfiguration, setBookingId, setMode } = useBookingOffCanvasConfiguration();

  const viewBooking = (id: number) => {
    setBookingId(id);
    setMode(OffCanvasModeEnum.View);
    openBookingCanvas();
  };

  const openBookingCanvas = () => {
    offCanvasContext?.open(offCanvasConfiguration);
  };

  useEffect(() => {
    if (fetchedPersonalWorkplaces?.length && fetchedPersonalWorkplaces[0]) {
      setPersonalWorkplaces([adaptPersonalWorkplaces(fetchedPersonalWorkplaces[0])]);
    }
  }, [adaptPersonalWorkplaces, fetchedPersonalWorkplaces]);

  useEffect(() => {
    if (fetchedFavoritesWorkplaces?.length) {
      setFavoritesWorkplaces(
        fetchedFavoritesWorkplaces?.map((favoriteWorkplaces) => adaptFavoritesWorkplaces(favoriteWorkplaces))
      );
    }
  }, [adaptFavoritesWorkplaces, fetchedFavoritesWorkplaces]);

  useEffect(() => {
    if (favoritesWorkplaces) {
      const workplacesWithBookings: IJxtWorkplacesListItem[] = [];

      favoritesWorkplaces.forEach((w) => {
        workplacesWithBookings.push({ ...w, bookings: bookings.get(w.user.id) });
      });

      setFavoritesWorkplacesWithBookings(workplacesWithBookings);
    }
  }, [bookings, favoritesWorkplaces]);

  useEffect(() => {
    if (personalWorkplaces) {
      setPersonalWorkplacesWithBookings({
        ...personalWorkplaces[0],
        bookings: bookings.get(personalWorkplaces[0].user.id),
      });
    }
  }, [bookings, personalWorkplaces]);

  const openLocateUserModal = (user: IJxtWorkplacesWeek['user'] | undefined) => {
    if (user && modalLocateUserContext) {
      // use context here
      modalLocateUserContext.setLocateUserModalInfos({ ...user });
      modalLocateUserContext.setRange(range);
      modalLocateUserContext.setShow(true);
    }
  };

  if (!offCanvasContext) {
    return null;
  }

  return (
    <ChooseWorkplaceOverlayProvider onToastActionClicked={viewBooking}>
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-col gap-y-2 pb-4 px-4">
          {user.id && personalWorkplacesWithBookings && (
            <JxtWorkplacesListItem
              workplaces={personalWorkplacesWithBookings.workplaces}
              user={{
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                picture: user.picture,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                timezone: user.timezone!,
              }}
              personal={personalWorkplacesWithBookings.personal}
              bookings={personalWorkplacesWithBookings.bookings}
              onClick={() => openLocateUserModal(personalWorkplacesWithBookings.user)}
            />
          )}
          <JxtButton
            onClick={() => {
              offCanvasContext.open({
                header: {
                  title: t<string>('provide-a-status'),
                },
                onHide: offCanvasContext.close,
                bodyClass: 'overflow-y-auto',
                children: (
                  <JxtWorkplaceCalendarContainer
                    initialRange={
                      now().hasSame(range.to, 'week')
                        ? { from: now().startOf('month'), to: now().endOf('month') }
                        : { from: range.from.startOf('month'), to: range.from.endOf('month') }
                    }
                    onToastActionClicked={viewBooking}
                  />
                ),
              });
            }}
          >
            {t('modify-workplaces')}
          </JxtButton>
        </div>
        <hr className="border-neutral-10" />
        {!favoritesWorkplaces || favoritesWorkplaces.length === 0 ? (
          <div className="pt-4 px-6 grow flex items-center justify-center">
            <HomeWorkplacesFavoritesEmpty />
          </div>
        ) : (
          <div className="pt-4 px-4 lg:flex lg:grow">
            <div className="lg:relative lg:grow">
              <div className="lg:absolute lg:inset-0">
                <div className="h-full overflow-auto">
                  <JxtWorkplacesList
                    workplacesList={favoritesWorkplacesWithBookings}
                    onLocateUser={openLocateUserModal}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!!modalWorkplaceContext && <JxtModalWorkplace {...modalWorkplaceContext} />}
    </ChooseWorkplaceOverlayProvider>
  );
};
