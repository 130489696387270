import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import luxonPlugin from '@fullcalendar/luxon3';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DateTime, Settings } from 'luxon';
import './style.scss';
import { EventInput } from '@fullcalendar/core';
import { JxtResourcesTypeEnum, now, SCREEN_SIZE } from '@jooxter/utils';
import { useIsSmallScreen } from '@jooxter/ui';
import { IJxtMyBookingsCalendarList } from './types';
import clsx from 'clsx';
import { IFullCalendarConfig, useFullCalendar, useFullCalendarLocale } from '@jooxter/fullcalendar';
import { ResourceTypeDto, ResourceTypeDtoMetaTypeEnum } from '@jooxter/api';
import { getResourceTypesForMetaType } from '../../utils';
import { isoToDateTimeAdapter } from '../../adapters';
import { useStore } from '../../store';
import { useNavigate } from 'react-router';
import { useFetchResourceTypes, useFetchUser } from '../../queries';
import { useBookingCallbacks, useWorkHours } from '../../hooks';
import { SEARCH_SPACE_ROUTE } from '../../routing/routes';
import { EventTypeEnum } from '../../adapters/types';
import { useShallow } from 'zustand/shallow';

const JxtMyBookingsCalendarList = ({
  events,
  range,
  onClick,
  selectedEventId,
  readonly = false,
  className = '',
  timezone = 'system',
}: IJxtMyBookingsCalendarList) => {
  const { t, i18n } = useTranslation();
  const { user } = useFetchUser();
  const calendarRef = useRef<FullCalendar>(null);
  const { locale } = useFullCalendarLocale(i18n.language);
  const [eventsToSend, setEventsToSend] = useState<EventInput[]>([]);
  const isMobile = useIsSmallScreen(SCREEN_SIZE.LG);
  const navigate = useNavigate();
  const { resourceTypes } = useFetchResourceTypes();
  const { getDayHours } = useWorkHours(user?.id);
  const [filter, setFilter] = useStore(useShallow((state) => [state.filter, state.setFilter]));
  const { onClickCheckIn, onClickCheckOut, onClickUpdate, onClickDelete, deleteBookingModal } = useBookingCallbacks();
  const viewOptions = {
    listWeek: {
      stickyHeaderDates: false,
      listDaySideFormat: false as const,
      listDayFormat: t('calendar.date.shortWeekday-dayOfMonth-monthName.react'),
      nowIndicator: true,
      weekends: false,
    },
  };

  const goToResources = (metaType?: JxtResourcesTypeEnum, date?: DateTime): void => {
    let resourceTypesToFilter: ResourceTypeDto[] = [];
    const dateToFilter = { from: filter.from, to: filter.to };

    if (metaType) {
      const resourceTypesForMetaType = getResourceTypesForMetaType(
        metaType as unknown as ResourceTypeDtoMetaTypeEnum,
        resourceTypes
      );

      resourceTypesToFilter = resourceTypesForMetaType;
    }

    if (date) {
      const dayHours = getDayHours(date);

      if (dayHours) {
        dateToFilter.from = isoToDateTimeAdapter.revert(dayHours?.start);
        dateToFilter.to = isoToDateTimeAdapter.revert(dayHours?.end);
      }
    }

    setFilter({
      ...filter,
      resource: {
        ...filter.resource,
        date: dateToFilter,
        resourceTypesIds: resourceTypesToFilter.map((rt) => rt.id),
      },
    });

    navigate(SEARCH_SPACE_ROUTE);
  };

  const fullCalendarConfig: IFullCalendarConfig = {
    user: user,
    showHours: true,
    showTitle: true,
    showActions: true,
    view: calendarRef.current?.getApi().view.type,
    readonly: readonly,
    selectedEventId: selectedEventId,
    callbacks: { onClick, goToResources, onClickCheckIn, onClickCheckOut, onClickUpdate, onClickDelete },
  };
  const { renderEventContent } = useFullCalendar(fullCalendarConfig);

  useEffect(() => {
    if (calendarRef.current && locale) {
      calendarRef.current.getApi().setOption('locale', locale);
    }

    if (calendarRef.current && timezone) {
      calendarRef.current.getApi().setOption('timeZone', timezone);
    }
  }, [locale, timezone, calendarRef.current]);

  const bookingAtDate = (booking: EventInput, date: DateTime) => {
    if (booking.start && booking.end) {
      const start = new Date(booking.start.toString());
      const end = new Date(booking.end.toString());
      const bookingFromDate = DateTime.fromJSDate(start).startOf('day');
      const bookingToDate = DateTime.fromJSDate(end).endOf('day');
      const dateToCompare = date.startOf('day');

      return bookingFromDate <= dateToCompare && bookingToDate >= dateToCompare;
    }
    return false;
  };

  const manageFakeEvents = () => {
    let currentDate = range.from;
    const fakeEventsToAdd = [];
    const dayWithoutBooking = [];
    while (currentDate <= range.to) {
      const dayHasBooking = events.some((event) => bookingAtDate(event, currentDate));
      if (!dayHasBooking) {
        dayWithoutBooking.push(currentDate);
      }
      currentDate = currentDate.plus({ days: 1 });
    }

    dayWithoutBooking.forEach((date: DateTime) => {
      if (date.startOf('day') < now().startOf('day')) {
        const dateIso = date.toISO();
        if (dateIso) {
          fakeEventsToAdd.push({
            title: 'No Event',
            start: dateIso,
            end: dateIso,
            extendedProps: {
              type: EventTypeEnum.FAKE,
            },
          });
        }
      }
    });

    currentDate = now() < range.from ? range.from : now();
    while (currentDate <= range.to) {
      const dateIsoStart = currentDate.setZone(Settings.defaultZone).set({ hour: 23, minute: 59, second: 58 }).toISO();
      const dateIsoEnd = currentDate.setZone(Settings.defaultZone).set({ hour: 23, minute: 59, second: 59 }).toISO();
      if (dateIsoStart !== null && dateIsoEnd !== null) {
        fakeEventsToAdd.push({
          title: 'Add Event',
          start: dateIsoStart,
          end: dateIsoEnd,
          extendedProps: {
            type: EventTypeEnum.FAKE,
          },
        });
      }
      currentDate = currentDate.plus({ days: 1 });
    }

    return fakeEventsToAdd;
  };

  useEffect(() => {
    if (readonly) {
      setEventsToSend([...events]);
    } else {
      setEventsToSend([...events, ...manageFakeEvents()]);
    }

    if (calendarRef && initialDate !== range.from) {
      calendarRef.current?.getApi().gotoDate(range.from.toJSDate());
    }
  }, [events, readonly]);

  const initialDate = useMemo(() => {
    if (range) {
      const start = range.from;
      return DateTime.fromObject(
        {
          year: start.year,
          month: start.month,
          day: start.day,
        },
        { zone: 'utc' }
      );
    }
  }, [range?.from.toISO()]);

  return (
    <>
      <div className={clsx('w-full h-full', className)}>
        <FullCalendar
          ref={calendarRef}
          plugins={[listPlugin, luxonPlugin]}
          initialView="listWeek"
          initialDate={initialDate?.toJSDate() ?? now().toJSDate()}
          timeZone={timezone}
          height={isMobile ? 'auto' : '100%'}
          locale={locale}
          views={viewOptions}
          displayEventTime={false}
          headerToolbar={false}
          firstDay={1}
          events={eventsToSend}
          eventContent={renderEventContent}
        />
      </div>
      {deleteBookingModal}
    </>
  );
};

export default JxtMyBookingsCalendarList;
