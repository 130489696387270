import clsx from 'clsx';
import { getJxtResourceIconClass } from './styles';
import { IJxtResourceIcon, JxtResourceIconSizeEnum } from './types';
import { JxtResourceIconInfo } from '@jooxter/utils';

const JxtResourceIcon = ({ type, size = JxtResourceIconSizeEnum.M, fixedWidth = true }: IJxtResourceIcon) => {
  const classes = getJxtResourceIconClass({ size, fixedWidth });

  return (
    <div className={classes}>
      <i className={clsx('fas fa-fw', JxtResourceIconInfo[type])} />
    </div>
  );
};

export default JxtResourceIcon;
