import { MonthlyMode } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { SingleValue } from 'react-select';
import { JxtSelect, SelectOption } from '../Selectors';
import { IJxtRecurrenceMonthlyModeSelect, TMonthlyModeOption } from './types';

const JxtRecurrenceMonthlyModeSelect = ({ value, onChange, payload }: IJxtRecurrenceMonthlyModeSelect) => {
  const { t } = useTranslation();

  const options: TMonthlyModeOption[] = [
    {
      value: MonthlyMode.MonthDay,
      label: t<string>('recurrence.monthly.every-same-date', {
        dayIndex: payload.dayIndex,
        interval: payload.interval,
      }),
    },
    {
      value: MonthlyMode.WeekDay,
      label: t<string>('recurrence.monthly.every-same-weekday', {
        monthDayIndex: payload.monthDayIndex,
        dayName: payload.dayName,
        interval: payload.interval,
      }),
    },
  ];

  const onMontlhlyModeChange = (selection: SingleValue<SelectOption<MonthlyMode>>) =>
    selection && onChange(selection.value);

  return (
    <JxtSelect
      value={options.find((o) => o.value === value)}
      defaultValue={options.find((o) => o.value === MonthlyMode.MonthDay)}
      isSearchable={false}
      options={options}
      handleSelection={onMontlhlyModeChange}
    />
  );
};

export default JxtRecurrenceMonthlyModeSelect;
