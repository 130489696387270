import { IQuickTimeSlot, toISO } from '@jooxter/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JxtSmartSlotSelector from '../JxtSmartSlotSelector';
import { IJxtSmartSlotContainer, JxtSmartSlotContainerGapEnum } from './types';
import { getJxtSmartSlotSelectorContainerClass } from './styles';
import { isSlotSelected } from './helpers';

const JxtSmartSlotSelectorContainer = ({
  range,
  slots,
  children,
  gap = JxtSmartSlotContainerGapEnum.REGULAR,
  onSlotSelection,
  autoSelectFirstSlot = false,
}: IJxtSmartSlotContainer) => {
  const { t } = useTranslation();
  const classes = getJxtSmartSlotSelectorContainerClass({ gap });
  const [selectedSlot, setSelectedSlot] = useState<IQuickTimeSlot | null>();
  const [selectFirstSlot, setSelectFirstSlot] = useState<boolean>(autoSelectFirstSlot);

  useEffect(() => {
    if (slots.length) {
      const correspondingSlot = slots.find(
        (s) =>
          s.start?.startOf('minute').equals(range.from.startOf('minute')) &&
          s.end?.startOf('minute').equals(range.to.startOf('minute'))
      );
      if (correspondingSlot) {
        setSelectedSlot(correspondingSlot);
      } else {
        setSelectedSlot(null);
      }
    }
  }, [toISO(range.from), toISO(range.to), JSON.stringify(slots)]);

  useEffect(() => {
    if (slots.length && selectFirstSlot) {
      const firstSlot = slots[0];
      setSelectedSlot(firstSlot);
      onSlotSelection(firstSlot);
    }
  }, [slots, selectFirstSlot]);

  const selectSlot = (s: IQuickTimeSlot): void => {
    setSelectedSlot(s);
    onSlotSelection(s);
    setSelectFirstSlot(false);
  };

  if (!slots?.length) {
    return <span className="text-neutral-60">{t('this-space-has-no-time-slot-available-this-day')}</span>;
  }

  return (
    <>
      {children}
      <div className={classes}>
        {slots.map((s) => (
          <JxtSmartSlotSelector
            key={s.text}
            selected={!!(s && selectedSlot) && isSlotSelected(s, selectedSlot)}
            onClick={() => {
              selectSlot(s);
            }}
          >
            {s.text}
          </JxtSmartSlotSelector>
        ))}
      </div>
    </>
  );
};

export default JxtSmartSlotSelectorContainer;
