import { JxtWorkplaceTypeEnum } from '@jooxter/utils';
import { JxtWorkplaceTypeClasses } from './constants';
import clsx from 'clsx';
export interface IGetJxtWorkplaceCalendarUnitClass {
  type: JxtWorkplaceTypeEnum;
  disabled: boolean;
}

export const getJxtWorkplaceCalendarUnitClass = ({ type, disabled }: IGetJxtWorkplaceCalendarUnitClass) => {
  const defaultClasses = 'h-8 w-full py-2 flex items-center justify-center';

  const disabledClasses = disabled
    ? clsx(JxtWorkplaceTypeClasses[type].disabledColors, 'cursor-not-allowed')
    : JxtWorkplaceTypeClasses[type].colors;

  const classes = clsx(defaultClasses, disabledClasses);

  return classes;
};
