import { WorkplaceRequestDto, WorkplaceRequestDtoTypeEnum } from '@jooxter/api';
import {
  DataPeriodEnum,
  dateIsTodayAndWeAreAfternoon,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  MID_DAY,
} from '@jooxter/utils';
import { DateTime } from 'luxon';

export type TStartEndItem = {
  start: Omit<DateTime, 'toISO'> & {
    toISO: () => string;
    toUTC: () => Omit<DateTime, 'toISO'> & { toISO: () => string };
  };
  end: Omit<DateTime, 'toISO'> & {
    toISO: () => string;
    toUTC: () => Omit<DateTime, 'toISO'> & { toISO: () => string };
  };
};

export const DayToWorkplaceAdapter = {
  adapt(
    day: DateTime,
    type: WorkplaceRequestDtoTypeEnum,
    period: DataPeriodEnum | null,
    locationId?: number
  ): WorkplaceRequestDto[] {
    const startEndArr: {
      start: DateTime;
      end: DateTime;
    }[] = this.getStartEnd(day, period);

    return startEndArr
      .filter((item): item is TStartEndItem => !!(item.start.toISO() && item.end.toISO()))
      .map<WorkplaceRequestDto>(({ start, end }) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        start: start.toUTC().toISO()!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        end: end.toUTC().toISO()!,
        type,
        locationId,
      }));
  },

  getStartEnd(
    day: DateTime,
    period: DataPeriodEnum | null
  ): {
    start: DateTime;
    end: DateTime;
  }[] {
    if (period === DataPeriodEnum.Am) {
      return [
        {
          start: day.set({ hour: DEFAULT_START_TIME }),
          end: day.set({ hour: MID_DAY }),
        },
      ];
    }

    if (period === DataPeriodEnum.Pm) {
      return [
        {
          start: day.set({ hour: MID_DAY }),
          end: day.set({ hour: DEFAULT_END_TIME }),
        },
      ];
    }

    return [
      {
        start: day.set({ hour: DEFAULT_START_TIME }),
        end: day.set({ hour: DEFAULT_END_TIME }),
      },
    ];
  },
};
