import { FloorPlanPictoTypeEnum, JxtFloorPlanPictos, useFetchFloorPlan, useFetchResource } from '@jooxter/core';
import { JxtNoFloorPlan } from '@jooxter/ui';
import { IJxtLocateResource } from './types';

const JxtLocateResource = ({ resourceId, width, height }: IJxtLocateResource) => {
  const { resource } = useFetchResource(resourceId);
  const { floorPlan } = useFetchFloorPlan(resource?.floor?.id);

  if (!resource) {
    return null;
  }

  return floorPlan?.image ? (
    <JxtFloorPlanPictos
      floorPlan={floorPlan}
      width={width}
      height={height}
      resources={[{ ...resource, resourceTypeId: resource.resourceType.id }]}
      type={FloorPlanPictoTypeEnum.Pin}
    />
  ) : (
    <JxtNoFloorPlan />
  );
};

export default JxtLocateResource;
