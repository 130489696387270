import { Disclosure } from '@headlessui/react';
import JxtAvatar from '../JxtAvatar';
import JxtDropdown from '../JxtDropdown';
import JxtNavbarDropdownItem from '../JxtNavbarDropdownItem';
import { NavbarDropdownItemSizeEnum } from '../JxtNavbarDropdownItem/types';
import JxtMenuToggle from '../JxtMenuToggle';
import JxtNavItem from '../JxtNavItem';
import { IJxtNavbar } from './types';
import JooxterLogoNavbar from '../../assets/images/JooxterLogoNavbar2.png';
import { JxtAvatarSizeEnum } from '../JxtAvatar/types';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router';
import { useRef } from 'react';

const JxtNavbar = ({ user, routes, buttons, logoRoute }: IJxtNavbar) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const imgRef = useRef<HTMLImageElement>(null);
  let cursor = 0;
  const KONAMI_CODE = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ];

  document.addEventListener('keydown', (e) => {
    cursor = e.key === KONAMI_CODE[cursor] ? cursor + 1 : 0;
    if (cursor === KONAMI_CODE.length) {
      imgRef.current?.classList.add('animate-spin');
    }
  });

  return (
    <Disclosure as="nav" className="bg-white border-b border-[#E8E9EA]">
      {({ open, close }) => (
        <>
          <div className="mx-auto px-4">
            <div className="relative flex h-[70px] items-center justify-between">
              <div className="flex flex-1 basis-full items-center lg:items-stretch lg:justify-between">
                <div className="flex flex-shrink-0 items-center pt-[3px]">
                  <NavLink to={logoRoute} onClick={() => close()}>
                    <img ref={imgRef} src={JooxterLogoNavbar} alt="Jooxter logo" width="40" height="40" />
                  </NavLink>
                </div>
                <div className="hidden lg:ml-[0.5rem] lg:flex lg:flex-1 lg:basis-auto justify-between">
                  <div className="flex px-2">
                    {routes.main.map((item) => (
                      <JxtNavItem
                        key={`main-navbar-item-${item.name}`}
                        name={item.name}
                        href={item.to ?? '#'}
                        icon={item.icon}
                      />
                    ))}
                  </div>
                </div>
                {buttons.length > 0 && (
                  <ul className="hidden lg:flex gap-2 pr-6">
                    {buttons.map((b, i) => (
                      <li className="flex items-center" key={i}>
                        {b}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-lg lg:p-2 pr-[13px] py-2 pl-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <JxtMenuToggle expanded={open} />
                </Disclosure.Button>
              </div>
              <div className="hidden lg:flex mr-[18.5px]">
                <JxtDropdown
                  dropdownButton={
                    <JxtAvatar
                      className="pl-4 lg:pl-0"
                      picture={user.picture}
                      alt={t('avatar-of', {
                        name: `${user.firstname} ${user.lastname}`,
                      })}
                      size={JxtAvatarSizeEnum.M}
                      text={`${user.firstname[0]}${user.lastname[0]}`}
                    />
                  }
                >
                  <JxtNavbarDropdownItem
                    text={`${user.firstname} ${user.lastname.toUpperCase()}`}
                    size={NavbarDropdownItemSizeEnum.L}
                    interactive={false}
                  />
                  {routes.secondary.map((item) => (
                    <JxtNavbarDropdownItem
                      key={`secondary-navbar-item-${item.name}`}
                      text={item.name}
                      size={NavbarDropdownItemSizeEnum.M}
                      icon={item.icon}
                      openInNewTab={item.openInNewTab}
                      onClick={item.callback}
                      interactive={true}
                      to={item.to}
                    />
                  ))}
                </JxtDropdown>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden border-b max-lg:mt-[-6px] lg:py-2 border-neutral-10 absolute w-full bg-white z-10">
            {routes.main.map((item) => (
              <JxtNavItem
                key={`main-navbar-item-${item.name}`}
                name={item.name}
                href={item.to ?? '#'}
                icon={item.icon}
                onClick={() => close()}
              />
            ))}
            <div className="ml-4 mt-4 mb-[31px]">
              <button
                onClick={() => {
                  // @TODO: generate this in the upstream hook and use it as we do for other links
                  navigate('/together');
                  close();
                }}
                key={t('together.beta')}
                className="bg-primary-100 text-white px-[17px] py-3 rounded-lg
              font-['Open_Sans'] text-[14px] font-semibold hover:bg-[#78acff] transition duration-300"
              >
                {t('together.beta')}
              </button>
            </div>

            <div className="lg-ml-0 mb-[21px]">
              <JxtDropdown
                dropdownButton={
                  <JxtAvatar
                    className="ml-[17px]"
                    picture={user.picture}
                    alt={t('avatar-of', {
                      name: `${user.firstname} ${user.lastname}`,
                    })}
                    size={JxtAvatarSizeEnum.M}
                    text={`${user.firstname[0]}${user.lastname[0]}`}
                  />
                }
              >
                <JxtNavbarDropdownItem
                  text={`${user.firstname} ${user.lastname.toUpperCase()}`}
                  size={NavbarDropdownItemSizeEnum.L}
                  interactive={false}
                />
                {routes.secondary.map((item) => (
                  <JxtNavbarDropdownItem
                    key={`secondary-navbar-item-${item.name}`}
                    text={item.name}
                    size={NavbarDropdownItemSizeEnum.M}
                    icon={item.icon}
                    onClick={() => {
                      if (item.callback) item.callback();
                      close();
                    }}
                    interactive={true}
                    to={item.to}
                  />
                ))}
              </JxtDropdown>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default JxtNavbar;
export * from './types';
