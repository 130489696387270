import { User } from '@jooxter/api';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { pick } from 'lodash-es';
import { useEffect } from 'react';
import { IUserInformationContainer, UserInformations } from './types';
import { useMutatePatchUser, useStartResetPassword } from '@jooxter/core';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton, JxtContainer, JxtInput } from '@jooxter/ui';
import { useDebouncedState } from '@mantine/hooks';

const gtm: [string, string, string] = ['Profile', 'Password', 'Reset Password'];

export const UserInformationContainer = ({ user }: IUserInformationContainer) => {
  const disabledFields = ['email', 'department'];
  const { t } = useTranslation();
  const { mutate: mutateUser } = useMutatePatchUser();
  const { startResetPassword, isButtonPasswordLoading, btnPasswordChildren } = useStartResetPassword(gtm, user.email);
  const [value, setValue] = useDebouncedState({}, 200);
  const { register, handleSubmit, reset, formState, getFieldState } = useForm({
    defaultValues: pick<User, keyof UserInformations>(
      user,
      'firstname',
      'lastname',
      'department',
      'profile',
      'telephone',
      'email'
    ),
    shouldFocusError: false,
    mode: 'onChange',
  });

  useEffect(() => {
    if (formState.isDirty && user.id) {
      const dirtyFieldsKeys = Object.keys(formState.dirtyFields).filter((key) => !disabledFields.includes(key));
      if (dirtyFieldsKeys.length > 0) {
        const dirtyData: Record<string, string> = pick(value, ...dirtyFieldsKeys);
        const trimmedData: Record<string, string> = {};
        Object.keys(dirtyData).forEach((key) => {
          const trimmedValue = dirtyData[key].trim();
          if (key in user && trimmedValue !== user[key as keyof typeof user]) {
            trimmedData[key] = trimmedValue;
          }
        });
        if (Object.keys(trimmedData).length > 0) {
          mutateUser({ id: user.id, payload: trimmedData });
        }
      }
      reset((formValues) => ({ ...formValues }));
    }
  }, [value]);

  const onChange = (data: FieldValues) => {
    setValue(data);
  };

  return (
    <JxtContainer id="personal-information" title={t<string>('my-information')}>
      <div className="flex flex-col grow gap-6">
        <form className="grid grid-cols-2 grow gap-6 max-md:flex max-md:flex-col" onChange={handleSubmit(onChange)}>
          <JxtInput
            name="firstname"
            label={t<string>('user-update-profile-label-firstname')}
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
            validationSchema={{
              required: {
                value: true,
                message: t('user-form.error.first-name-required'),
              },
            }}
          />
          <JxtInput
            name="lastname"
            label={t<string>('user-update-profile-label-lastname')}
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
            validationSchema={{
              required: {
                value: true,
                message: t('user-form.error.last-name-required'),
              },
            }}
          />
          <JxtInput
            name="email"
            label={t<string>('user-update-profile-label-email')}
            type="email"
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
            disabled
          />
          <JxtInput
            name="department"
            label={t<string>('user-update-profile-label-department')}
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
            disabled
          />
          <JxtInput
            name="profile"
            label={t<string>('user-update-profile-label-profile')}
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
          />
          <JxtInput
            name="telephone"
            label={t<string>('user-update-profile-label-phone-number')}
            type="tel"
            register={register as unknown as UseFormRegister<FieldValues>}
            getFieldState={getFieldState}
            formState={formState}
            validationSchema={{
              pattern: {
                value: /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/i,
                message: t('user-update-profile-phone-number-error'),
              },
            }}
          />
        </form>
        {user?.capabilities?.features?.changePassword && (
          <div>
            <label className="block pb-1 text-neutral-120 text-title-m font-medium">{t('password')}</label>
            <JxtButton
              variant={ButtonVariantEnum.Link}
              onClick={startResetPassword}
              disabled={isButtonPasswordLoading}
              size={ButtonSizeEnum.Large}
            >
              {isButtonPasswordLoading ? btnPasswordChildren : t('user-button-reset-password')}
            </JxtButton>
          </div>
        )}
      </div>
    </JxtContainer>
  );
};
