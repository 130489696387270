import { ISearchResourceTypesParameters, ResourceTypesService } from '@jooxter/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ResourceTypeQueryKeys } from '../queryKeys';
import { ResourceTypeStaleTimeEnum } from '../staleTimes';

export const useFetchResourceTypes = (options?: ISearchResourceTypesParameters) => {
  const fetchAllResourceTypes = () => ResourceTypesService.search(options);

  const { data: resourceTypes } = useQuery({
    queryKey: [ResourceTypeQueryKeys.GetAll, options],
    queryFn: fetchAllResourceTypes,
    placeholderData: keepPreviousData,
    staleTime: ResourceTypeStaleTimeEnum.GetAll,
  });

  return { resourceTypes };
};
