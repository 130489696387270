// eslint-disable-next-line import/named
import { ControlProps, DropdownIndicatorProps, GroupBase, IndicatorsContainerProps, OptionProps } from 'react-select';
import clsx from 'clsx';
import { SelectOption } from './types';

export const BaseStyles = <T>() => ({
  control: (state: ControlProps<SelectOption<T>, false, GroupBase<SelectOption<T>>>) =>
    clsx(
      'rounded-lg border text-body-s text-neutral-120',
      state.isFocused
        ? 'border-primary-100 outline-none ring-4 primary-100 ring-primary-30'
        : 'border-neutral-20 hover:border-neutral-30',
      state.isDisabled ? 'pointer-events-auto cursor-not-allowed' : 'cursor-pointer'
    ),
  indicatorsContainer: (state: IndicatorsContainerProps<SelectOption<T>, false, GroupBase<SelectOption<T>>>) =>
    clsx('pl-0', state.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'),
  dropdownIndicator: (state: DropdownIndicatorProps<SelectOption<T>, false, GroupBase<SelectOption<T>>>) =>
    state.isFocused ? 'rotate-180 pr-0.5' : 'pl-0.5',
  option: (state: OptionProps<SelectOption<T>, false, GroupBase<SelectOption<T>>>) =>
    clsx(
      'cursor-pointer text-body-m text-neutral-140 active:bg-neutral-10',
      state.isFocused || state.isSelected ? 'bg-neutral-10' : ''
    ),
  multiValue: () => 'bg-primary-10',
  multiValueLabel: () => 'text-primary-100',
  multiValueRemove: () => 'text-primary-100',
  indicatorSeparator: () => 'hidden',
  valueContainer: () => 'pr-0',
  placeholder: () => 'text-body-s text-neutral-80',
});
