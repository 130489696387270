import { useCallback } from 'react';
import { z, ZodRawShape } from 'zod';
import { IBookingFormDefaultValues, TResourceConfiguration } from '../types/booking-form.types';
import { BookingOption, Option } from '@jooxter/api';
import { BOOKING_OPTION_PREFIX } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';

export const useCreateOptionsDef = (config?: TResourceConfiguration, defaultValues?: IBookingFormDefaultValues) => {
  const { t } = useTranslation();

  const findBookingOption = (id?: number) => id && defaultValues?.options?.[id];

  const createOptionValueDef = (option: Option, defaultValue?: BookingOption) => {
    if (defaultValue?.value?.content) {
      if (option.mandatory) {
        return z
          .string({ required_error: t<string>('booking-form.error.option.value.required') })
          .min(1, t<string>('booking-form.error.option.value.required'))
          .default(defaultValue.value.content);
      } else {
        return z.string().optional().default(defaultValue.value?.content);
      }
    } else if (option.defaultValue) {
      if (option.mandatory) {
        return z
          .string({ required_error: t<string>('booking-form.error.option.value.required') })
          .min(1, t<string>('booking-form.error.option.value.required'))
          .default(option.defaultValue);
      } else {
        return z.string().optional().default(option.defaultValue);
      }
    } else {
      if (option.mandatory) {
        return z
          .string({ required_error: t<string>('booking-form.error.option.value.required') })
          .min(1, t<string>('booking-form.error.option.value.required'));
      } else {
        return z.string().optional();
      }
    }
  };

  const createOptionActivatedDef = (option: Option, defaultValue?: BookingOption) => {
    if (defaultValue) {
      if (option.mandatory) {
        return z
          .literal(true, { errorMap: () => ({ message: t<string>('booking-form.error.option.activated.required') }) })
          .default(true);
      } else {
        return z.boolean().default(true).optional();
      }
    } else {
      if (option.mandatory) {
        return z.literal(true, {
          errorMap: () => ({ message: t<string>('booking-form.error.option.activated.required') }),
        });
      } else {
        return z.boolean().default(!!option.selectedByDefault);
      }
    }
  };

  const createOptionsDef = useCallback((): null | z.ZodObject<ZodRawShape> => {
    if (!config) {
      return null;
    }

    const resourceOptions = config.options;

    if (!resourceOptions?.length) {
      return null;
    }

    const optionsSchema = resourceOptions
      .map((option: Option) => [option, findBookingOption(option.id)])
      .filter((item): item is [Option, BookingOption | undefined] => !!item[0])
      .map(([resourceOption, bookingOption]) => {
        if (resourceOption.mandatory) {
          return [
            `${BOOKING_OPTION_PREFIX}-${resourceOption.id}`,
            z.object({
              activated: createOptionActivatedDef(resourceOption, bookingOption),
              value: createOptionValueDef(resourceOption, bookingOption),
            }),
          ];
        }

        return [
          `${BOOKING_OPTION_PREFIX}-${resourceOption.id}`,
          z
            .object({
              activated: createOptionActivatedDef(resourceOption, bookingOption),
              value: createOptionValueDef(resourceOption, bookingOption),
            })
            .optional(),
        ];
      });

    return z.object(Object.fromEntries(optionsSchema));
  }, [config, defaultValues]);

  return { createOptionsDef };
};
