import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { IJxtRangeDateFilter, IJxtRangeDateFilterViewType } from './types';
import { now } from '@jooxter/utils';
import JxtRangeDatePicker from '../JxtRangeDatePicker';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import JxtButtonGroups from '../JxtButtonGroups';
import clsx from 'clsx';

const JxtRangeDateFilter = ({
  range,
  setRange,
  timezone = 'system',
  viewType = IJxtRangeDateFilterViewType.Week,
  excludeWeekEnds = false,
  className,
  children,
}: IJxtRangeDateFilter) => {
  const { t } = useTranslation();
  const [disableToday, setDisableToday] = useState<boolean>(false);

  useEffect(() => {
    const today = now();
    setDisableToday(today.hasSame(range.from, viewType));
  }, [range, viewType]);

  const goToday = () => {
    const nowDate = now();
    if (excludeWeekEnds && nowDate.weekday > 5) {
      navigateTo(now().plus({ week: 1 }).startOf('week'));
    } else {
      navigateTo(now());
    }
  };

  const goBack = () => {
    const minusDate = range.from.minus({ [viewType]: 1 });
    if (excludeWeekEnds && minusDate.weekday > 5) {
      navigateTo(minusDate.set({ weekday: 5 }));
    } else {
      navigateTo(minusDate);
    }
  };

  const goForward = () => {
    const plusDate = range.from.plus({ [viewType]: 1 });
    if (excludeWeekEnds && plusDate.weekday > 5) {
      navigateTo(plusDate.plus({ week: 1 }).startOf('week'));
    } else {
      navigateTo(plusDate);
    }
  };

  const navigateTo = (date: DateTime) => {
    const from = date.startOf(viewType);
    let to = date.endOf(viewType);
    if (viewType === IJxtRangeDateFilterViewType.Week && excludeWeekEnds) {
      to = to.minus({ days: 2 });
    }

    setRange({ from, to });
  };

  return (
    <div className={clsx('inline-flex max-sm:flex-wrap gap-2 bg-white flex-1 md:flex-none p-4', className)}>
      <div className="flex grow shrink-0 items-center justify-start gap-2">
        <JxtButtonGroups onClickPrev={goBack} onClickNext={goForward} />
        <JxtButton variant={ButtonVariantEnum.Secondary} onClick={goToday} disabled={disableToday} ariaLabel="Today">
          {t('today')}
        </JxtButton>
      </div>
      <div className={clsx('flex grow shrink-0 items-center', children ? 'sm:justify-center' : 'sm:justify-end')}>
        <JxtRangeDatePicker
          range={range}
          onRangeChange={setRange}
          timezone={timezone}
          viewType={viewType}
          excludeWeekEnds={excludeWeekEnds}
        />
      </div>
      {children && <div className="flex grow shrink-0 items-center sm:justify-end">{children}</div>}
    </div>
  );
};

export default JxtRangeDateFilter;
