import { ContainerPaddingEnum, JxtButton, JxtContainer } from '@jooxter/ui';
import { fromISO, JxtMeetUpBookingStatusEnum } from '@jooxter/utils';
import { IMeetUpSlot } from './types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { MeetUpBookingStatusTag } from '../MeetUpBookingStatusTag';
import { useStartCreateAutomaticBookings } from './hooks';

export const MeetUpSlot = ({ slot, onRetry, slotIndex }: IMeetUpSlot) => {
  const { t } = useTranslation();
  const [bookingStatus, setBookingStatus] = useState<Map<number, JxtMeetUpBookingStatusEnum>>(
    new Map<number, JxtMeetUpBookingStatusEnum>()
  );
  const [disableButton, setDisableButton] = useState(false);
  const { startCreateAutomaticBookings } = useStartCreateAutomaticBookings(
    slot,
    slotIndex,
    bookingStatus,
    setBookingStatus,
    setDisableButton,
    onRetry
  );

  const start = slot.interval?.start ? fromISO(slot.interval.start) : undefined;
  const end = slot.interval?.end ? fromISO(slot.interval.end) : undefined;
  const showButton = slot.usersToBook ? slot.usersToBook.length > 0 : false;
  const locationName = slot.bestPlace?.location?.name;
  const floorName = slot.bestPlace?.floor?.name;

  useEffect(() => {
    const newBookingMeetUpStatus = new Map<number, JxtMeetUpBookingStatusEnum>();
    slot.usersToBook?.map((user) => {
      newBookingMeetUpStatus.set(user.id, JxtMeetUpBookingStatusEnum.SHOULD_BE_BOOKED);
    });
    slot.alreadyBooked?.map((booking) => {
      newBookingMeetUpStatus.set(booking.organizer.id, JxtMeetUpBookingStatusEnum.ALREADY_BOOKED);
    });
    setBookingStatus(newBookingMeetUpStatus);
    setDisableButton(false);
  }, [slot]);

  if (!start || !end) {
    return null;
  }

  return (
    <JxtContainer padding={ContainerPaddingEnum.None}>
      <div className="flex flex-col grow">
        <div className="flex max-2xl:flex-col gap-4 px-6 pb-6 border-b border-neutral-20 2xl:justify-between">
          <div className="flex flex-col gap-4 text-neutral-100 text-title-l font-bold capitalize">
            <h2>{start.toLocaleString(DateTime.DATE_HUGE)}</h2>
            <h3>
              {`${start.toLocaleString(DateTime.TIME_24_SIMPLE)} - ${end.toLocaleString(DateTime.TIME_24_SIMPLE)}`}
            </h3>
            <div className="flex gap-2 items-center">
              <i className="fas fa-map-marker-alt text-neutral-60 shrink-0" />
              <h4 className="text-neutral-100 text-body-m">{`${locationName}${floorName ? ` - ${floorName}` : ''}`}</h4>
            </div>
          </div>
          <div>
            <JxtButton
              className={showButton ? '' : 'invisible'}
              onClick={startCreateAutomaticBookings}
              disabled={disableButton}
            >
              {t('together.choose-this-option')}
            </JxtButton>
          </div>
        </div>
        <div className="flex px-6 pt-6">
          <table>
            <thead>
              <tr className="text-neutral-100 text-title-s font-bold text-left">
                <th className="p-2">{t('together.colleague')}</th>
                <th className="p-2">{t('together.space-attributed')}</th>
                <th className="p-2">{t('together.status')}</th>
              </tr>
            </thead>
            <tbody>
              {slot.usersToBook?.map((user, i) => {
                const colleague = `${user.firstname} ${user.lastname}`;
                const space = slot.desksToBook?.[i]?.name;
                return (
                  <tr key={user.id}>
                    <td className="text-neutral-140 text-body-m p-2 [overflow-wrap:anywhere]" title={colleague}>
                      {colleague}
                    </td>
                    <td className="text-neutral-150 text-body-m font-medium p-2 [overflow-wrap:anywhere]" title={space}>
                      {space}
                    </td>
                    <td className="p-2">
                      <MeetUpBookingStatusTag status={bookingStatus.get(user.id)} />
                    </td>
                  </tr>
                );
              })}
              {slot.alreadyBooked?.map((booking) => {
                const colleague = `${booking.organizer.firstname} ${booking.organizer.lastname}`;
                const space = booking.resource.name;
                return (
                  <tr key={booking.organizer.id}>
                    <td className="text-neutral-140 text-body-m p-2" title={colleague}>
                      {colleague}
                    </td>
                    <td className="text-neutral-150 text-body-m font-medium p-2 break-words" title={space}>
                      {space}
                    </td>
                    <td className="p-2">
                      <MeetUpBookingStatusTag status={bookingStatus.get(booking.organizer.id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </JxtContainer>
  );
};
