import { JxtWorkplaceTypeWithoutUnknownEnum } from '@jooxter/utils';
import { IJxtWorkplaceStrategyLimit } from './types';
import { useTranslation } from 'react-i18next';
import JxtAccordion from '../JxtAccordion';
import { useMemo } from 'react';
import clsx from 'clsx';

const JxtWorkplaceStrategyLimit = ({
  workplaceType,
  rules,
  hasRemainingBookings,
  isBooking,
}: IJxtWorkplaceStrategyLimit) => {
  const { t } = useTranslation();
  const translationKeyMapForDescription: Record<JxtWorkplaceTypeWithoutUnknownEnum, string> = isBooking
    ? {
        [JxtWorkplaceTypeWithoutUnknownEnum.Office]: 'workplace.strategy.limit.office.booking.description',
        [JxtWorkplaceTypeWithoutUnknownEnum.Mission]: '',
        [JxtWorkplaceTypeWithoutUnknownEnum.WorkFromHome]: '',
        [JxtWorkplaceTypeWithoutUnknownEnum.Off]: '',
      }
    : {
        [JxtWorkplaceTypeWithoutUnknownEnum.Office]: 'workplace.strategy.limit.office.description',
        [JxtWorkplaceTypeWithoutUnknownEnum.Mission]: 'workplace.strategy.limit.mission.description',
        [JxtWorkplaceTypeWithoutUnknownEnum.WorkFromHome]: 'workplace.strategy.limit.home.description',
        [JxtWorkplaceTypeWithoutUnknownEnum.Off]: '',
      };

  const getIconClasses = (workplaceType: JxtWorkplaceTypeWithoutUnknownEnum) => {
    switch (workplaceType) {
      case JxtWorkplaceTypeWithoutUnknownEnum.Office:
        return 'building';
      case JxtWorkplaceTypeWithoutUnknownEnum.WorkFromHome:
        return 'home';
      case JxtWorkplaceTypeWithoutUnknownEnum.Mission:
        return 'car';
    }
  };

  const getTitle = useMemo(() => {
    switch (workplaceType) {
      case JxtWorkplaceTypeWithoutUnknownEnum.Office:
        return t('workplace.strategy.limit.office.title');
      case JxtWorkplaceTypeWithoutUnknownEnum.WorkFromHome:
        return t('workplace.strategy.limit.home.title');
      case JxtWorkplaceTypeWithoutUnknownEnum.Mission:
        return t('workplace.strategy.limit.mission.title');
    }
  }, [workplaceType]);

  const getDescription = useMemo(() => {
    if (hasRemainingBookings !== null && !hasRemainingBookings) {
      return t('workplace.strategy.limit.office.error');
    }

    return t<string>(translationKeyMapForDescription[workplaceType]);
  }, [workplaceType, hasRemainingBookings, translationKeyMapForDescription]);

  return (
    <JxtAccordion
      title={getTitle ?? ''}
      icon={getIconClasses(workplaceType)}
      iconClassNames={clsx(
        hasRemainingBookings !== null ? (hasRemainingBookings ? 'text-green-100' : 'text-red-100') : 'text-neutral-100'
      )}
      classNames={clsx(
        'rounded-xl p-3 border',
        hasRemainingBookings !== null
          ? hasRemainingBookings
            ? 'border-neutral-10'
            : 'border-red-100'
          : 'border-neutral-10'
      )}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <p className="text-body-s text-neutral-100 font-normal">{getDescription}</p>
        </div>
        <div className=" flex gap-1 flex-wrap mt-2">
          {rules.map((rule) => (
            <span
              key={rule}
              className="flex text-body-xs h-7 text-neutral-120 rounded-[14px] bg-neutral-10 py-1.5 px-2"
            >
              {rule}
            </span>
          ))}
        </div>
      </div>
    </JxtAccordion>
  );
};

export default JxtWorkplaceStrategyLimit;
