import { IJxtChooseWorkplaceOverlayContent } from '@jooxter/ui';
import { createContext, useContext } from 'react';

export interface IModalWorkplaceContext {
  show: boolean;
  setShow: (show: boolean) => void;
  onHide: () => void;
  chooseWorkplaceModalProps: IJxtChooseWorkplaceOverlayContent | null;
  setChooseWorkplaceModalProps: (infos: IJxtChooseWorkplaceOverlayContent | null) => void;
}

export const ModalWorkplaceContext = createContext<IModalWorkplaceContext | null>(null);

export const useModalWorkplaceContext = (): IModalWorkplaceContext | null => {
  return useContext(ModalWorkplaceContext);
};
