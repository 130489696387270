import { ReactNode, useState } from 'react';
import { IJxtChooseWorkplaceOverlayContent, ModalWorkplaceContext } from '@jooxter/ui';

export const ModalWorkplaceProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState<boolean>(false);
  const [chooseWorkplaceModalProps, setChooseWorkplaceModalProps] = useState<IJxtChooseWorkplaceOverlayContent | null>(
    null
  );
  const onHide = () => setShow(false);

  return (
    <ModalWorkplaceContext.Provider
      value={{ show, setShow, onHide, chooseWorkplaceModalProps, setChooseWorkplaceModalProps }}
    >
      {children}
    </ModalWorkplaceContext.Provider>
  );
};
