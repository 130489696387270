import { SCREEN_SIZE } from '@jooxter/utils';
import useViewport from './useViewport';

const useIsSmallScreen = (smallScreenDef = SCREEN_SIZE.LG): boolean => {
  const { width } = useViewport();

  return width <= smallScreenDef;
};

export default useIsSmallScreen;
