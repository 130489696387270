import { JxtCustomCalendarViewEnum } from '../types';

export const baseCustomCalendarConfig = (
  view: JxtCustomCalendarViewEnum
): {
  type: string;
  classNames: string[];
  duration?: {
    months?: number;
    weeks?: number;
    days?: number;
  };
  dateAlignment?: string;
  firstDay?: number;
  weekNumberCalculation?: 'ISO' | 'local';
  fixedWeekCount?: boolean;
  weekends?: boolean;
} => {
  const baseSettings = {
    type: 'dayGrid',
    classNames: ['click-n-create-view'],
  };

  if (view === JxtCustomCalendarViewEnum.Month) {
    return {
      ...baseSettings,
      duration: {
        months: 1,
      },
      fixedWeekCount: true,
      weekends: true,
    };
  }

  if (view === JxtCustomCalendarViewEnum.Week) {
    return {
      ...baseSettings,
      duration: {
        weeks: 1,
      },
      firstDay: 1,
      weekends: true,
    };
  }

  return {
    ...baseSettings,
    duration: {
      days: 1,
    },
    weekends: false,
  };
};
