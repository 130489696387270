import {
  Booking,
  BookingVisibilityEnum,
  BookingVisibilitySettingDeskEnum,
  isJxtEventColorsEnum,
  ResourceTypeDtoMetaTypeEnum,
} from '@jooxter/api';
import {
  BOOKING_OPTION_PREFIX,
  getIDateTimeFromDateTime,
  getIDateTimeFromISO,
  JxtEventColorsEnum,
} from '@jooxter/utils';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchUser, useFetchUserPreferences } from '../../..';
import { IBookingFormDefaultValues, IBookingFormPreset, TResourceConfiguration } from '../types/booking-form.types';
import { getDefaultOptions } from './helpers';

export const useBookingFormDefaultValues = (
  preset?: IBookingFormPreset,
  resourceConfig?: TResourceConfiguration,
  bookingToEdit?: Booking | null
) => {
  const { t } = useTranslation();
  const { user } = useFetchUser();
  const { preferences } = useFetchUserPreferences(user?.id);

  const defaultValues: IBookingFormDefaultValues | undefined = useMemo(() => {
    if (!user?.id) {
      return;
    }

    if (bookingToEdit) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const start = getIDateTimeFromISO(bookingToEdit.start.dateTime, bookingToEdit.resource.timezone!);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const end = getIDateTimeFromISO(bookingToEdit.end.dateTime, bookingToEdit.resource.timezone!);

      return {
        summary: bookingToEdit.summary,
        organizerId: bookingToEdit.organizer.id,
        resource: bookingToEdit.resource,
        color: isJxtEventColorsEnum(bookingToEdit.color) ? bookingToEdit.color : JxtEventColorsEnum.Primary,
        visibility: bookingToEdit.visibility,
        start,
        end,
        physicalAttendees: bookingToEdit.physicalAttendees === 0 ? null : bookingToEdit.physicalAttendees,
        internalAttendees: bookingToEdit.attendees.internal
          .filter((attendee) => attendee.id !== undefined)
          .map((attendee) => attendee.id as number),
        externalAttendees: bookingToEdit.attendees.external
          .filter((attendee) => attendee.id !== undefined)
          .map((attendee) => attendee.id as number),
        rrule: bookingToEdit.rrule,
        description: bookingToEdit.description,
        options: getDefaultOptions(resourceConfig?.options, bookingToEdit.options),
      };
    }

    const result: IBookingFormDefaultValues = {
      ...omit(preset, ['from', 'to']),
      start: getIDateTimeFromDateTime(preset?.from, resourceConfig?.timezone),
      end: getIDateTimeFromDateTime(preset?.to, resourceConfig?.timezone),
      color:
        resourceConfig?.metaType === ResourceTypeDtoMetaTypeEnum.Desk
          ? JxtEventColorsEnum.Green
          : JxtEventColorsEnum.Primary,
      visibility: BookingVisibilitySettingDeskEnum.Public as unknown as BookingVisibilityEnum,
      summary:
        resourceConfig?.metaType === ResourceTypeDtoMetaTypeEnum.Vehicle
          ? ''
          : t<string>('booking-form.default-summary', {
              organizerFirstName: user?.firstname,
              organizerLastName: user?.lastname,
            }),
      organizerId: preset?.organizerId ?? user.id,
      physicalAttendees: preset?.physicalAttendees,
      internalAttendees: resourceConfig?.isZone ? [preset?.organizerId ?? user.id] : [],
      externalAttendees: [],
      options:
        resourceConfig?.options && resourceConfig.options.length > 0
          ? Object.fromEntries(
              resourceConfig?.options?.map((o) => [
                `${BOOKING_OPTION_PREFIX}-${o.id}`,
                {
                  activated: !!o.selectedByDefault,
                  value: o.defaultValue,
                },
              ])
            )
          : undefined,
    };

    if (!(preferences && resourceConfig)) {
      return result;
    }

    if (preferences.defaultBookingColor?.desk && resourceConfig.metaType === ResourceTypeDtoMetaTypeEnum.Desk) {
      result.color = preferences.defaultBookingColor?.desk as JxtEventColorsEnum;
    }

    if (preferences.defaultBookingColor?.other && resourceConfig.metaType !== ResourceTypeDtoMetaTypeEnum.Desk) {
      result.color = preferences.defaultBookingColor?.other as JxtEventColorsEnum;
    }

    if (preferences.defaultBookingVisibility?.desk && resourceConfig.metaType === ResourceTypeDtoMetaTypeEnum.Desk) {
      result.visibility = preferences.defaultBookingVisibility?.desk as unknown as BookingVisibilityEnum;
    }

    if (preferences.defaultBookingVisibility?.other && resourceConfig.metaType !== ResourceTypeDtoMetaTypeEnum.Desk) {
      result.visibility = preferences.defaultBookingVisibility?.other as unknown as BookingVisibilityEnum;
    }

    if (resourceConfig.isZone && !result.physicalAttendees) {
      result.physicalAttendees = 1;
    }

    return result;
  }, [user, preset, resourceConfig, preferences, bookingToEdit]);

  return defaultValues;
};
