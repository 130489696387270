import { useTranslation } from 'react-i18next';
import { IJxtWorkplaceStatusButton, JxtWorkplaceStatusButtonStateEnum } from './types';
import clsx from 'clsx';
import { getJxtWorkplaceStatusButtonClass } from './styles';
import { JxtWorkplaceStatusButtonInfo } from './constants';

const JxtWorkplaceStatusButton = ({
  type,
  state = JxtWorkplaceStatusButtonStateEnum.Default,
  onClick,
  disabled,
}: IJxtWorkplaceStatusButton) => {
  const { t } = useTranslation();
  const classes = getJxtWorkplaceStatusButtonClass({ type, state, disabled });

  return (
    <button
      className={classes}
      title={t<string>(JxtWorkplaceStatusButtonInfo[type].translationKey)}
      onClick={onClick}
      disabled={disabled || state !== JxtWorkplaceStatusButtonStateEnum.Default}
    >
      <i className={clsx('fas fa-fw', JxtWorkplaceStatusButtonInfo[type].iconName)} />
      <span className="text-body-s font-medium">{t(JxtWorkplaceStatusButtonInfo[type].translationKey)}</span>
    </button>
  );
};

export default JxtWorkplaceStatusButton;
