import { JxtBookingInfo, JxtBookingInfoContainer, JxtRadioButton } from '@jooxter/ui';
import {
  BookingCompressedActionEnum,
  IJxtBookingModalContent,
  isUnitaryBookingCompressedActionEnum,
  TransformedBookings,
} from './types';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  DataPeriodEnum,
  IRange,
  dateToMidDay,
  isAfternoon,
  isMidDay,
  isMorning,
  sortBookingsByDate,
} from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import { submittedMessageMapping } from './constants';
import { shouldBookingBeRefused } from './helpers';

const JxtBookingModalContent = ({ bookings, period, onChange, readonly, title }: IJxtBookingModalContent) => {
  const [isAllDelete, setIsAllDelete] = useState<boolean>(true);
  const { t } = useTranslation();
  const [isAllKeep, setIsAllKeep] = useState<boolean>(false);
  const [isPersonalizedDisabled, setIsPersonalizedDisabled] = useState<boolean>(true);
  const { register, setValue, getValues, control } = useForm<{
    [key: string]: BookingCompressedActionEnum;
  }>({
    mode: 'onChange',
    defaultValues: {
      all: BookingCompressedActionEnum.DELETE_ALL,
    },
  });

  const bookingIdList = useMemo(() => bookings.map((b) => b.id.toString()), [bookings]);
  const watchAll = useWatch({ control, name: 'all' });
  const watchBookings = useWatch({ control, name: bookingIdList });
  const [personalizedBookingDate, setPersonalizedBookingDate] = useState<Map<number, IRange>>(
    new Map<number, IRange>()
  );

  const getSubmittedMessage = (id: number, value: BookingCompressedActionEnum): string => {
    if (isUnitaryBookingCompressedActionEnum(value)) {
      return t(
        submittedMessageMapping[value],
        value === BookingCompressedActionEnum.SWITCH_FROM_TO
          ? {
              from: personalizedBookingDate.get(id)?.from.toLocaleString(DateTime.TIME_SIMPLE),
              to: personalizedBookingDate.get(id)?.to.toLocaleString(DateTime.TIME_SIMPLE),
            }
          : {}
      );
    }

    return '';
  };

  useEffect(() => {
    if (period !== DataPeriodEnum.Day) {
      const map = new Map<number, IRange>();
      bookings.forEach((b) => {
        if (
          period === DataPeriodEnum.Am &&
          isAfternoon(DateTime.fromISO(b.end.dateTime)) &&
          !isMidDay(DateTime.fromISO(b.end.dateTime))
        ) {
          map.set(b.id, {
            from: dateToMidDay(DateTime.fromISO(b.start.dateTime)),
            to: DateTime.fromISO(b.end.dateTime),
          });
        } else if (period === DataPeriodEnum.Pm && isMorning(DateTime.fromISO(b.start.dateTime))) {
          map.set(b.id, {
            from: DateTime.fromISO(b.start.dateTime),
            to: dateToMidDay(DateTime.fromISO(b.end.dateTime)),
          });
        }
      });
      setPersonalizedBookingDate(map);
    }
  }, [bookings, period]);

  useEffect(() => {
    if (getValues('all') === BookingCompressedActionEnum.DELETE_ALL) {
      bookingIdList.forEach((key) => setValue(key, BookingCompressedActionEnum.DELETE));
      setIsAllDelete(true);
      setIsAllKeep(false);
    } else if (getValues('all') === BookingCompressedActionEnum.KEEP_ALL) {
      bookingIdList.forEach((key) => setValue(key, BookingCompressedActionEnum.KEEP));
      setIsAllDelete(false);
      setIsAllKeep(true);
    }
  }, [watchAll, bookings]);

  useEffect(() => {
    setIsAllDelete(bookingIdList.every((key) => getValues(key) === BookingCompressedActionEnum.DELETE));
    setIsAllKeep(bookingIdList.every((key) => getValues(key) === BookingCompressedActionEnum.KEEP));
  }, [watchBookings, bookings]);

  useEffect(() => {
    const transformedBookings: TransformedBookings = { delete: [], update: [], refuse: [] };
    bookings.forEach((b) => {
      if (getValues(b.id.toString()) === BookingCompressedActionEnum.DELETE) {
        shouldBookingBeRefused(b) ? transformedBookings.refuse.push(b.id) : transformedBookings.delete.push(b.id);
      } else if (getValues(b.id.toString()) === BookingCompressedActionEnum.SWITCH_FROM_TO) {
        const bookingDate = personalizedBookingDate.get(b.id);

        if (bookingDate) {
          shouldBookingBeRefused(b)
            ? transformedBookings.refuse.push(b.id)
            : transformedBookings.update.push({
                id: b.id,
                newRange: bookingDate,
              });
        }
      }
    });

    onChange(transformedBookings);
  }, [bookings, watchBookings, watchAll]);

  useEffect(() => {
    if (isAllDelete || isAllKeep) {
      setValue('all', isAllDelete ? BookingCompressedActionEnum.DELETE_ALL : BookingCompressedActionEnum.KEEP_ALL);
      setIsPersonalizedDisabled(true);
    } else {
      setValue('all', BookingCompressedActionEnum.PERSONALIZED);
      setIsPersonalizedDisabled(false);
    }
  }, [isAllDelete, isAllKeep]);

  return (
    <form className="flex flex-col gap-2.5">
      <div className="flex flex-col gap-3 pb-3">
        <p className="text-title-m text-neutral-140 font-medium">
          {readonly ? t('manage-closing.modifications-have-been-saved') : title}
        </p>
        {!readonly && (
          <fieldset className="flex max-md:flex-col gap-4">
            <JxtRadioButton
              id="deleteAll"
              label={t<string>('manage-closing.delete-all')}
              value={BookingCompressedActionEnum.DELETE_ALL}
              checked
              {...register('all')}
            />
            <JxtRadioButton
              id="keepAll"
              label={t<string>('manage-closing.keep-all')}
              value={BookingCompressedActionEnum.KEEP_ALL}
              {...register('all')}
            />
            <JxtRadioButton
              id="personalized"
              label={t<string>('manage-closing.personalized')}
              value={BookingCompressedActionEnum.PERSONALIZED}
              disabled={isPersonalizedDisabled}
              {...register('all')}
            />
          </fieldset>
        )}
      </div>
      <span className="text-title-m text-neutral-140 font-medium">
        {t('manage-closing.details-of-bookings')} ({bookings.length})
      </span>
      <div className="flex flex-col gap-2.5 overflow-auto">
        {sortBookingsByDate(bookings).map((b) => (
          <JxtBookingInfoContainer key={b.id}>
            <div className="flex flex-col gap-4">
              <JxtBookingInfo
                title={{ title: b.summary }}
                location={{ location: b.resource.name }}
                day={{ day: DateTime.fromISO(b.start.dateTime) }}
                hours={{
                  range: { from: DateTime.fromISO(b.start.dateTime), to: DateTime.fromISO(b.end.dateTime) },
                }}
              />
              {!readonly && (
                <div className="flex flex-col gap-3">
                  <span className="text-title-m text-neutral-140 font-medium">
                    {t('manage-closing.what-do-you-want-to-do-2')}
                  </span>
                  <fieldset className="flex max-md:flex-col gap-4">
                    <JxtRadioButton
                      id={'deleteByBooking' + b.id}
                      label={t<string>('delete')}
                      value={BookingCompressedActionEnum.DELETE}
                      checked
                      {...register(b.id.toString())}
                    />
                    <JxtRadioButton
                      id={'keepByBooking' + b.id}
                      label={t<string>('manage-closing.keep')}
                      value={BookingCompressedActionEnum.KEEP}
                      {...register(b.id.toString())}
                    />
                    {personalizedBookingDate.has(b.id) && (
                      <JxtRadioButton
                        id={'updateByBooking' + b.id}
                        label={t<string>('manage-closing.switch-from-to', {
                          from: personalizedBookingDate.get(b.id)?.from.toLocaleString(DateTime.TIME_SIMPLE),
                          to: personalizedBookingDate.get(b.id)?.to.toLocaleString(DateTime.TIME_SIMPLE),
                        })}
                        value={BookingCompressedActionEnum.SWITCH_FROM_TO}
                        {...register(b.id.toString())}
                      />
                    )}
                  </fieldset>
                </div>
              )}
              {readonly && (
                <p className="font-semibold text-green-100">{getSubmittedMessage(b.id, getValues(b.id.toString()))}</p>
              )}
            </div>
          </JxtBookingInfoContainer>
        ))}
      </div>
    </form>
  );
};

export default JxtBookingModalContent;
