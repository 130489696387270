export type SearchLocationOptions = {
  q?: string;
  size?: number;
};

export const defaultSearchLocationOptions = {
  q: '',
  size: '25',
};

export const SearchLocationOptionsToParamsAdapter = {
  adapt(options?: SearchLocationOptions): URLSearchParams {
    const params = new URLSearchParams(defaultSearchLocationOptions);

    if (!options) {
      return params;
    }

    if (options.q) {
      // using set here because this param already exists
      params.set('q', options.q);
    }

    if (options.size) {
      // using set here because this param already exists
      params.set('size', options.size.toString());
    }

    return params;
  },

  revert() {
    throw new Error('Method not implemented');
  },
};
