import { Booking, BookingCompressed } from '@jooxter/api';
import { useMemo } from 'react';
import JxtLocateBooking from '../../components/JxtLocateBooking';

export const useGenerateBookingPlan = (booking?: Booking | null) => {
  const plan = useMemo(
    () => <JxtLocateBooking booking={booking as unknown as BookingCompressed} width={300} height={300} />,
    [booking]
  );

  return { plan };
};
