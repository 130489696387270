import { zodResolver } from '@hookform/resolvers/zod';
import { JxtInputNumber } from '@jooxter/ui';
import { useEffect } from 'react';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { IJxtCapacityFilter } from './types';

const JxtCapacityFilter = ({ defaultValue, onChange }: IJxtCapacityFilter) => {
  const MIN_CAPACITY = 1;
  const MAX_CAPACITY = 99999;
  const { t } = useTranslation();
  const { register, getValues, setValue, getFieldState, formState, watch, trigger } = useForm({
    mode: 'all',
    defaultValues: {
      capacity: defaultValue,
    },
    resolver: zodResolver(
      z.object({
        capacity: z
          .number()
          .int(t<string>('capacity.error.integer'))
          .nonnegative(t<string>('capacity.error.positive'))
          .min(MIN_CAPACITY, t<string>('capacity.error.min'))
          .max(MAX_CAPACITY, t<string>('capacity.error.tooLong'))
          .optional(),
      })
    ),
  });

  const capacity = watch('capacity');

  useEffect(() => {
    trigger('capacity').then((isValid) => {
      if (onChange && isValid) {
        onChange(capacity);
      }
    });
  }, [capacity]);

  useEffect(() => {
    setValue('capacity', defaultValue);
  }, [defaultValue]);

  return (
    <>
      <span className="text-body-s text-neutral-140 font-medium">{t('capacity')}</span>
      <JxtInputNumber
        name="capacity"
        min={MIN_CAPACITY}
        max={MAX_CAPACITY}
        register={register as unknown as UseFormRegister<FieldValues>}
        formState={formState}
        getFieldState={getFieldState}
        getValues={getValues}
        setValue={setValue}
      />
    </>
  );
};

export default JxtCapacityFilter;
