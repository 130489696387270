import { IJxtBookingParticipate } from './types';
import { useTranslation } from 'react-i18next';
import { JxtBookingParticipationStatusEnum } from '@jooxter/utils';
import { ButtonSizeEnum, ButtonVariantEnum } from '../JxtButton';
import { useForm } from 'react-hook-form';
import { getJxtButtonClass } from '../JxtButton/styles';
import clsx from 'clsx';

const JxtBookingParticipate = ({ status, onChange }: IJxtBookingParticipate) => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch } = useForm<{ status: JxtBookingParticipationStatusEnum }>({
    mode: 'onChange',
    defaultValues: {
      status: status,
    },
  });
  const statusWatch = watch('status');

  const onFormValueChange = (e: { status: JxtBookingParticipationStatusEnum }) => onChange(e.status);

  const getButtonVariant = (
    value: JxtBookingParticipationStatusEnum,
    labelValue: JxtBookingParticipationStatusEnum
  ) => {
    return labelValue === value ? ButtonVariantEnum.Primary : ButtonVariantEnum.Secondary;
  };

  return (
    <div className="flex gap-2 items-center">
      <div className="text-neutral-100 font-semibold">{t('booking-attendees.participate?')}</div>
      <form onChange={handleSubmit(onFormValueChange)} className="flex gap-1">
        <label
          className={clsx(
            getJxtButtonClass({
              variant: getButtonVariant(statusWatch, JxtBookingParticipationStatusEnum.Accepted),
              size: ButtonSizeEnum.Regular,
            }),
            'cursor-pointer'
          )}
        >
          <input
            type="radio"
            className="hidden"
            {...register('status')}
            value={JxtBookingParticipationStatusEnum.Accepted}
          />
          {t('booking-attendees.participation.accept')}
        </label>
        <label
          className={clsx(
            getJxtButtonClass({
              variant: getButtonVariant(statusWatch, JxtBookingParticipationStatusEnum.AcceptedRemotely),
              size: ButtonSizeEnum.Regular,
            }),
            'cursor-pointer'
          )}
        >
          <input
            type="radio"
            className="hidden"
            {...register('status')}
            value={JxtBookingParticipationStatusEnum.AcceptedRemotely}
          />
          {t('booking-attendees.participation.accept.remotely')}
        </label>
        <label
          className={clsx(
            getJxtButtonClass({
              variant: getButtonVariant(statusWatch, JxtBookingParticipationStatusEnum.Declined),
              size: ButtonSizeEnum.Regular,
            }),
            'cursor-pointer'
          )}
        >
          <input
            type="radio"
            className="hidden"
            {...register('status')}
            value={JxtBookingParticipationStatusEnum.Declined}
          />
          {t('booking-attendees.participation.decline')}
        </label>
      </form>
    </div>
  );
};

export default JxtBookingParticipate;
