import clsx from 'clsx';
import { JxtEventColorSizeEnum } from './types';
import { JxtEventColorInfos, JxtEventColorsEnum } from '@jooxter/utils';

export interface IGetJxtEventColorClass {
  color: JxtEventColorsEnum;
  size: JxtEventColorSizeEnum;
}

export const getJxtEventColorClass = ({ color, size }: IGetJxtEventColorClass) => {
  const defaultClasses = 'rounded shrink-0';

  const colorClasses = JxtEventColorInfos[color].squareColor;

  const sizeClasses = {
    SMALL: 'size-4',
    REGULAR: 'size-5',
  };

  const classes = clsx(defaultClasses, colorClasses, sizeClasses[size]);

  return classes;
};
