import JxtWorkplacesListItem from '../JxtWorkplacesListItem';
import { IJxtWorkplacesList } from './types';

const JxtWorkplacesList = ({ workplacesList, onLocateUser }: IJxtWorkplacesList) => {
  return (
    <div className="flex flex-col gap-4">
      {workplacesList.map((workplacesListItem, i) => (
        <JxtWorkplacesListItem key={i} {...workplacesListItem} onClick={() => onLocateUser(workplacesListItem.user)} />
      ))}
    </div>
  );
};

export default JxtWorkplacesList;
