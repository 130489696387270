import { openInNewTab } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import JxtButton, { ButtonSizeEnum, ButtonVariantEnum } from '../JxtButton';
import { IJxtInfoWindow } from './types';

const JxtInfoWindow = ({ id, name, address, timezone, url, onAccessBuilding }: IJxtInfoWindow) => {
  const { t } = useTranslation();

  return (
    // p-1 because the google InfoWindow already has a 12px padding
    <div className="grid grid-cols-[auto_minmax(0,_1fr)] auto-rows-auto bg-white p-1 gap-x-4 gap-y-2 items-center justify-items-start">
      <i className="fas fa-building fa-fw fa-lg text-neutral-60 mb-2" />
      <h2 className="text-title-l text-neutral-140 font-semibold mb-2">{name}</h2>
      <i className="fas fa-location-dot fa-fw fa-lg text-neutral-60" />
      <h3 className="text-title-m text-neutral-140 font-medium mb-0">{t('location-address-heading')}</h3>
      <p className="text-body-s col-start-2 text-neutral-100 mb-2">{address}</p>
      <i className="fas fa-clock fa-fw fa-lg text-neutral-60" />
      <h3 className="text-title-m text-neutral-140 font-medium mb-0">{t('timezone')}</h3>
      <p className="text-body-s col-start-2 text-neutral-100 mb-2">{timezone}</p>
      {!!url && (
        <>
          <i className="fas fa-globe fa-fw fa-lg text-neutral-60" />
          <h3 className="text-title-m text-neutral-140 font-medium mb-0">{t('external-link')}</h3>
          <JxtButton className="mb-2 col-start-2" onClick={() => openInNewTab(url)} variant={ButtonVariantEnum.Link}>
            {url}
          </JxtButton>
        </>
      )}
      <JxtButton size={ButtonSizeEnum.Large} className="col-span-2 w-full" onClick={() => onAccessBuilding(id)}>
        {t('access-to-building')}
      </JxtButton>
    </div>
  );
};

export default JxtInfoWindow;
