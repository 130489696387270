import { clsx } from 'clsx';

export interface IGetJxtWorkplacesListItemClass {
  personal: boolean;
}

export const getJxtWorkplacesListItemClass = ({ personal }: IGetJxtWorkplacesListItemClass) => {
  const defaultClasses =
    'flex flex-row max-xl:items-stretch max-xl:flex-col max-xl:gap-px max-lg:flex-row max-lg:gap-0 max-[480px]:items-stretch max-[480px]:flex-col max-[480px]:gap-px';

  const personalClasses = personal ? 'items-end max-lg:items-end' : 'items-center max-lg:items-center';

  const classes = clsx(defaultClasses, personalClasses);

  return classes;
};
