import {
  NOT_FOUND_ROUTE,
  useBookingToIJxtBookingDetailsAdapter,
  useFetchBooking,
  useJxtRouter,
  useRequiredParams,
} from '@jooxter/core';
import { JxtBookingDetails, JxtContainer } from '@jooxter/ui';
import { useAutomaticBookingAction } from './hooks';
import { useCallback, useEffect, useState } from 'react';

export const Booking = () => {
  const navigate = useJxtRouter();
  const [navbarOffset, setNavbarOffset] = useState(0);
  const containerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setNavbarOffset(node.getBoundingClientRect().top);
    }
  }, []);

  const { bookingId } = useRequiredParams<{ bookingId: string }>();
  const { booking, isError } = useFetchBooking(Number(bookingId), { retry: false });
  const { bookingDetails } = useBookingToIJxtBookingDetailsAdapter(booking);
  useAutomaticBookingAction(booking);

  useEffect(() => {
    if (isError) {
      navigate(NOT_FOUND_ROUTE);
    }
  }, [isError]);

  if (!bookingDetails) {
    return null;
  }

  return (
    <div
      className="flex justify-center p-4"
      style={{
        maxHeight: `calc(100vh - ${navbarOffset}px)`,
      }}
      ref={containerRef}
    >
      <JxtContainer>
        <JxtBookingDetails {...bookingDetails} />
      </JxtContainer>
    </div>
  );
};
