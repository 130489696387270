import { JxtLogisticTimeRuleEnum, JxtResourcesTypeEnum, JxtTagColorsEnum, now } from '@jooxter/utils';
import JxtAlert from '../JxtAlert';
import { JxtAlertTypeEnum } from '../JxtAlert/types';
import JxtResourceIcon from '../JxtResourceIcon';
import { JxtResourceIconSizeEnum } from '../JxtResourceIcon/types';
import JxtTag from '../JxtTag';
import { JxtTagSizeEnum } from '../JxtTag/types';
import { IJxtResourceDetails } from './types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Autolinker } from 'autolinker';
import JxtIconButton from '../JxtIconButton';
import { Duration, DateTime } from 'luxon';
import { JxtIconButtonSizeEnum } from '../JxtIconButton/types';
import { capitalize } from 'lodash-es';

const JxtResourceDetails = ({ resource, groups, options }: IJxtResourceDetails) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    if (resource?.description) {
      const linker = new Autolinker({
        sanitizeHtml: true,
        className:
          'bg-transparent text-primary-100 hover:underline disabled:text-neutral-60 disabled:no-underline focus:outline-none rounded-lg text-body-m',
      });

      setDescription(linker.link(resource.description));
    }
  }, [resource]);

  return (
    <>
      {!resource.bookable && (
        <JxtAlert text={t('resource-page-headband-unbookable')} type={JxtAlertTypeEnum.Info} closeable={false} />
      )}
      <div className="flex justify-center">
        {resource.pictures?.length ? (
          <img
            src={resource.pictures[0]}
            className="rounded-xl h-24 w-24 object-cover"
            alt={`Picture of ${resource.name}`}
          />
        ) : (
          <JxtResourceIcon
            type={
              resource.resourceType.metaType
                ? JxtResourcesTypeEnum[resource.resourceType.metaType]
                : JxtResourcesTypeEnum.OTHER
            }
            size={JxtResourceIconSizeEnum.L}
          />
        )}
      </div>
      <div className="flex gap-3">
        <div className="h-6 w-5 flex items-center justify-center">
          <i className="fas fa-fw fa-map-marker-alt fa-lg text-neutral-60" />
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-title-m text-neutral-140 font-medium capitalize">{t('localization')}</h3>
          <span className="text-body-s text-neutral-100">
            {resource.location.name}
            {resource.floor && ' - ' + t('resource-short-card-floor-number', { floorNumber: resource.floor.number })}
          </span>
        </div>
      </div>
      {description && (
        <div className="flex gap-3">
          <div className="h-6 w-5 flex items-center justify-center">
            <i className="fas fa-align-left fa-fw fa-lg text-neutral-60" />
          </div>
          <div className="flex flex-col gap-2 min-w-0">
            <h3 className="text-title-m text-neutral-140 font-medium capitalize">{t('description')}</h3>
            <div
              className="text-body-m text-neutral-140 break-words"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      )}
      {resource.labels && resource.labels.length > 0 && (
        <div className="flex gap-3">
          <div className="h-6 w-5 flex items-center justify-center">
            <i className="fas fa-fw fa-tag fa-lg text-neutral-60" />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-title-m text-neutral-140 font-medium">{t('resource-page-label')}</span>
            <div className="flex flex-wrap gap-2">
              {resource.labels.map((label) => (
                <JxtTag key={label} text={label} color={JxtTagColorsEnum.NEUTRAL} size={JxtTagSizeEnum.SMALL} />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="flex gap-3">
        <div className="h-6 w-5 flex items-center justify-center">
          <i className="fas fa-fw fa-cog fa-lg text-neutral-60" />
        </div>
        <div className="flex flex-col gap-4 break-words min-w-0">
          {(resource.requestOwnerValidation ||
            resource.bookingPolicy?.latestPolicy ||
            resource.bookingPolicy?.earliestPolicy ||
            resource.resourceType.minDuration ||
            resource.resourceType.maxDuration ||
            resource.guestsRequired ||
            resource.resourceType.recurrency) && (
            <div className="flex flex-col gap-2">
              <h3 className="text-title-m text-neutral-140 font-medium">{t('resource-page-booking')}</h3>
              {resource.requestOwnerValidation && (
                <span className="text-body-s text-neutral-100">
                  {t('resource-display-policies-request-owner-validation')}
                </span>
              )}
              {resource.bookingPolicy?.latestPolicy && (
                <span className="text-body-s text-neutral-100">
                  {t('resource-display-policies-latest-booking-policy', {
                    bookingLatestPolicy: now()
                      .plus(Duration.fromISO(resource.bookingPolicy.latestPolicy).toObject())
                      .startOf('day')
                      .toLocaleString(DateTime.DATE_FULL),
                  })}
                </span>
              )}
              {resource.bookingPolicy?.earliestPolicy && (
                <span className="text-body-s text-neutral-100">
                  {t('resource-display-policies-earliest-booking-policy', {
                    earliestBookingPolicy: now()
                      .plus(Duration.fromISO(resource.bookingPolicy.earliestPolicy).toObject())
                      .toLocaleString(DateTime.DATETIME_MED),
                  })}
                </span>
              )}
              {resource.resourceType.minDuration && resource.resourceType.maxDuration && (
                <span className="text-body-s text-neutral-100">
                  {t<string>('resource-display-policies-duration-between', {
                    bookingMinBoundary: Duration.fromObject({
                      minute: resource.resourceType.minDuration,
                    })
                      .rescale()
                      .toHuman(),
                    bookingMaxBoundary: Duration.fromObject({
                      minute: resource.resourceType.maxDuration,
                    })
                      .rescale()
                      .toHuman(),
                  })}
                </span>
              )}
              {resource.resourceType.minDuration && !resource.resourceType.maxDuration && (
                <span className="text-body-s text-neutral-100">
                  {t('resource-display-policies-duration-above', {
                    bookingMinBoundary: Duration.fromObject({
                      minute: resource.resourceType.minDuration,
                    })
                      .rescale()
                      .toHuman(),
                  })}
                </span>
              )}
              {resource.resourceType.maxDuration && !resource.resourceType.minDuration && (
                <span className="text-body-s text-neutral-100">
                  {t('resource-display-policies-duration-under', {
                    bookingMaxBoundary: Duration.fromObject({
                      minute: resource.resourceType.maxDuration,
                    })
                      .rescale()
                      .toHuman(),
                  })}
                </span>
              )}
              {resource.guestsRequired && (
                <span className="text-body-s text-neutral-100">{t('resource-display-policies-guests-required')}</span>
              )}
              {resource.resourceType.recurrency && (
                <div>
                  <span className="text-body-s text-neutral-100">{t('resource-display-policies-recurrency')}</span>
                  &nbsp;
                  <JxtIconButton
                    title={t<string>('resource-display-policies-recurrency-more')}
                    icon="question"
                    size={JxtIconButtonSizeEnum.S}
                  />
                </div>
              )}
              {resource.sendMailToGuests && (
                <span className="text-body-s text-neutral-100">
                  {t('resource-display-policies-send-mail-to-guests')}
                </span>
              )}
            </div>
          )}
          {resource.checkin && (
            <div className="flex flex-col gap-2">
              <h3 className="text-title-m text-neutral-140 font-medium">{t('resource-page-checkin-checkout')}</h3>
              <span className="text-body-s text-neutral-100">{t('resource-display-checkin-mandatory')}</span>
              <span className="text-body-s text-neutral-100">
                {t('resource-display-checkin-delay-before-autocancel-before-start', {
                  delayBeforeAutocancel: resource.delayBeforeAutocancel,
                })}
              </span>
              <div>
                <span className="text-body-s text-neutral-100">
                  {t('resource-display-checkin-delay-before-autocancel-after-start', {
                    delayBeforeAutocancel: resource.delayBeforeAutocancel,
                  })}
                </span>
                &nbsp;
                <JxtIconButton
                  title={t<string>('resource-display-checkin-delay-before-autocancel-after-start-more')}
                  icon="question"
                  size={JxtIconButtonSizeEnum.S}
                />
              </div>
            </div>
          )}
          {options.length > 0 && (
            <div className="flex flex-col gap-2">
              <h3 className="text-title-m text-neutral-140 font-medium">{t('resource-page-options')}</h3>
              {options.map((option) => (
                <span key={option.id} className="text-body-s text-neutral-100">
                  {option.definition?.name}
                </span>
              ))}
            </div>
          )}
          {resource.capabilities?.update && (
            <div className="flex flex-col gap-2">
              <h3 className="text-title-m text-neutral-140 font-medium">{t('resource-page-management')}</h3>
              {groups.length > 0 && (
                <div className="">
                  <span className="text-body-s text-neutral-100 font-medium capitalize">
                    {t('resource-display-administration-group', {
                      numberOfGroups: groups.length,
                    })}
                    &nbsp;:&nbsp;
                  </span>
                  <span className="text-body-s text-neutral-100">{groups.map((group) => [group.name]).join(', ')}</span>
                </div>
              )}
              {!!resource.owner && (
                <div className="inline-flex">
                  <span className="text-body-s text-neutral-100 font-medium capitalize">
                    {t('resource-display-administration-owner', {
                      numberOfGroups: groups.length,
                    })}
                    &nbsp;:&nbsp;
                  </span>
                  <span className="text-body-s text-neutral-100">
                    {resource.owner.firstname + ' ' + resource.owner.lastname}
                  </span>
                </div>
              )}
              {!!resource.informationList?.length && (
                <div>
                  <span className="text-body-s text-neutral-100 font-medium capitalize">
                    {t('resource-display-administration-information-list', {
                      numberOfWatchers: groups.length,
                    })}
                    &nbsp;:&nbsp;
                  </span>
                  <span className="text-body-s text-neutral-100">
                    {resource.informationList.slice(0, 3).map((watcher) => [
                      <a
                        key={watcher}
                        href={`mailto:${watcher}`}
                        className="after:content-[',_'] last:after:content-none bg-transparent text-primary-100 hover:underline disabled:text-neutral-60 disabled:no-underline focus:outline-none rounded-lg text-body-s"
                      >
                        {watcher}
                      </a>,
                    ])}
                  </span>
                </div>
              )}

              <div>
                <span className="text-body-s text-neutral-100 font-medium">
                  {capitalize(
                    t<string>('resource-display-administration-logistic-time', {
                      numberOfGroups: groups.length,
                    })
                  )}
                  &nbsp;:&nbsp;
                </span>
                <span className="text-body-s text-neutral-100">
                  {resource.logisticTimeRule === JxtLogisticTimeRuleEnum.Max
                    ? t('resource-display-administration-logistic-time-max')
                    : t('resource-display-administration-logistic-time-sum')}
                </span>
                &nbsp;
                <JxtIconButton
                  title={
                    resource.logisticTimeRule === JxtLogisticTimeRuleEnum.Max
                      ? t<string>('resource-display-administration-logistc-time-max-more')
                      : t<string>('resource-display-administration-logistc-time-sum-more')
                  }
                  icon="question"
                  size={JxtIconButtonSizeEnum.S}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JxtResourceDetails;
