import { JxtTagColorsEnum } from '@jooxter/utils';

export enum JxtTagSizeEnum {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export interface IJxtTag {
  text: string;
  color: JxtTagColorsEnum;
  size: JxtTagSizeEnum;
  closeable?: boolean;
  onClose?: () => void;
}
