import { axiosManager } from '../config';
import { ResourceTypeDto, ResourceTypeDtoMetaTypeEnum } from '../model';

export interface ISearchResourceTypesParameters {
  name?: string;
  metaTypes?: ResourceTypeDtoMetaTypeEnum[];
}

export const ResourceTypesService = {
  path: 'resource_types',

  search(options?: ISearchResourceTypesParameters): Promise<ResourceTypeDto[]> {
    const url = `${this.path}`;
    const params = this.createParams(options);

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  getById(id: number): Promise<ResourceTypeDto> {
    const url = `${this.path}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data)
      .then((resourceTypes) => resourceTypes.find((rt: ResourceTypeDto) => rt.id === id));
  },

  createParams(options?: ISearchResourceTypesParameters): URLSearchParams {
    const params = new URLSearchParams();

    if (options?.name) {
      params.append('name', options.name.toString());
    }

    if (options?.metaTypes && options?.metaTypes?.length > 0) {
      params.append('metaTypes', options.metaTypes.join(','));
    }

    return params;
  },
};
