import { IJxtWorkplaceDay, IJxtWorkplaceHalfDay, JxtWorkplaceTypeEnum } from './types';

export const disableIJxtWorkplaceHalfDay = (workplaceHalfDay: IJxtWorkplaceHalfDay) => {
  return workplaceHalfDay.type === JxtWorkplaceTypeEnum.Unknown
    ? !workplaceHalfDay.capabilities.create
    : !workplaceHalfDay.capabilities.update;
};

export const disableIJxtWorkplaceDayReset = (workplaceDay: IJxtWorkplaceDay) => {
  return !workplaceDay.morning.capabilities.cancel && !workplaceDay.afternoon.capabilities.cancel;
};
