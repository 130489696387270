import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { IExceptionalOpeningHoursFormDefaultValues } from '../types/exceptional-opening-hours-form.types';
import { createDateDef, ExceptionalOpeningHoursTypeEnum } from '@jooxter/utils';

export const useExceptionalOpeningHoursSchema = (defaultValues?: IExceptionalOpeningHoursFormDefaultValues) => {
  const { t } = useTranslation();

  if (defaultValues) {
    return z.object({
      title: z.string().min(1, t<string>('manage-space-form.error.title.required')).default(defaultValues.title),
      start: createDateDef().default(defaultValues.start),
      end: createDateDef().default(defaultValues.end),
      type: z.nativeEnum(ExceptionalOpeningHoursTypeEnum).default(defaultValues.type),
    });
  }

  return z.object({
    title: z.string().min(1, t<string>('manage-space-form.error.title.required')),
    start: createDateDef(),
    end: createDateDef(),
    type: z.nativeEnum(ExceptionalOpeningHoursTypeEnum),
  });
};
