import { Info } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Weekday } from 'rrule';
// eslint-disable-next-line import/named
import { Language } from 'rrule/dist/esm/nlp/i18n';

export const useGenerateLocaleRRule = (): {
  data: Language & { strings: { [key: string]: string } };
  getText: (id: string | number | Weekday) => string;
} => {
  const { t, i18n } = useTranslation();

  const dayNames = Info.weekdays('long', { locale: i18n.language });

  const data = {
    // luxon starts weeks with Monday, rrule needs an array starting with Sunday
    dayNames: dayNames.slice(-1).concat(dayNames.slice(0, -1)),
    monthNames: Info.months('long', { locale: i18n.language }),
    // tokens are only needed for `Rrule.fromText`
    tokens: {},
    strings: {
      every: t<string>('rrule.every'),
      day: t<string>('rrule.day'),
      weekday: t<string>('rrule.weekday'),
      days: t<string>('rrule.days'),
      week: t<string>('rrule.week'),
      weeks: t<string>('rrule.weeks'),
      hour: t<string>('rrule.hour'),
      hours: t<string>('rrule.hours'),
      minute: t<string>('rrule.minute'),
      minutes: t<string>('rrule.minutes'),
      month: t<string>('rrule.month'),
      months: t<string>('rrule.months'),
      year: t<string>('rrule.year'),
      years: t<string>('rrule.years'),
      on: t<string>('rrule.on'),
      at: t<string>('rrule.at'),
      the: t<string>('rrule.the'),
      first: t<string>('rrule.first'),
      second: t<string>('rrule.second'),
      third: t<string>('rrule.third'),
      nth: t<string>('rrule.nth'),
      last: t<string>('rrule.last'),
      for: t<string>('rrule.for'),
      time: t<string>('rrule.time'),
      times: t<string>('rrule.times'),
      until: t<string>('rrule.until'),
      'on the': t<string>('rrule.on-the'),
      st: t<string>('rrule.st') !== '-' ? t<string>('rrule.st') : '',
      nd: t<string>('rrule.nd') !== '-' ? t<string>('rrule.nd') : '',
      rd: t<string>('rrule.rd') !== '-' ? t<string>('rrule.rd') : '',
      th: t<string>('rrule.th') !== '-' ? t<string>('rrule.th') : '',
    },
  };

  const getText = (id: string | number | Weekday) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.strings[id] ?? id;
  };

  return { data, getText };
};
