import { ExceptionalOpeningHours } from '@jooxter/api';
import {
  ExceptionalOpeningHoursTypeEnum,
  getDateTimeFromDateTimeAndStandardTime,
  IExceptionalOpeningHoursForm,
  toISO,
} from '@jooxter/utils';

export const useIExceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter = () => {
  const exceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter = (
    exceptionalOpeningHoursForm: IExceptionalOpeningHoursForm,
    resourceId: number
  ): ExceptionalOpeningHours => {
    return {
      title: exceptionalOpeningHoursForm.title,
      timestampFrom: toISO(
        getDateTimeFromDateTimeAndStandardTime(
          exceptionalOpeningHoursForm.start.date,
          exceptionalOpeningHoursForm.start.time
        )
      ),
      timestampTo: toISO(
        getDateTimeFromDateTimeAndStandardTime(
          exceptionalOpeningHoursForm.end.date,
          exceptionalOpeningHoursForm.end.time
        )
      ),
      resourceId,
      opened: exceptionalOpeningHoursForm.type === ExceptionalOpeningHoursTypeEnum.Opening,
    };
  };

  return [exceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter];
};
