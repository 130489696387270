import { IJxtResourceTabHeader } from './types';
import { useTranslation } from 'react-i18next';

const JxtResourceTabHeader = ({ name, type, capacity }: IJxtResourceTabHeader) => {
  const { t } = useTranslation();
  return (
    <header className="max-w-full text-center">
      <h3 className="text-neutral-140 font-medium truncate">{name}</h3>
      <h4 className="text-center text-body-s text-neutral-80 font-normal">
        {type}&nbsp;({capacity} {t('resource-person')})
      </h4>
    </header>
  );
};

export default JxtResourceTabHeader;
