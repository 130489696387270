import { IJxtWorkplacesWeek } from './types';
import JxtWorkplacesDay from '../JxtWorkplacesDay';

const JxtWorkplacesWeek = ({ workplaces, user }: IJxtWorkplacesWeek) => {
  return (
    <div className="gap-1 flex">
      {workplaces.map((workplaceDay, i) => (
        <JxtWorkplacesDay workplaceDay={workplaceDay} user={user} key={`wp-${i}`} />
      ))}
    </div>
  );
};

export default JxtWorkplacesWeek;
