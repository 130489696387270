import { IGetSlotsParameters, SlotsService, WorkTogetherDto } from '@jooxter/api';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { defaultOnError } from './default-error';

export const useGetSlots = (
  onSuccess?: (data: WorkTogetherDto[]) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) => {
  const mutationGetSlots = useMutation({
    mutationFn: (options: IGetSlotsParameters) => {
      return SlotsService.getSlots(options);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationGetSlots;
};
