import { DateTime } from 'luxon';
import { useState } from 'react';
import { DatePicker } from '@mantine/dates';
import { useTranslation } from 'react-i18next';
import { IJxtWeekDatePicker } from './types';
import { reverseLangMapping } from '@jooxter/i18n';
import { getThemeColorHex } from '@jooxter/tailwind-config';

const JxtWeekDatePicker = ({ initialDate, onDateChange, excludeWeekEnds = false }: IJxtWeekDatePicker) => {
  const { i18n } = useTranslation();
  const [weekDateHovered, setWeekDateHovered] = useState<Date | null>(null);

  const isInWeekRange = (date: Date, value?: Date | null) => {
    if (!value) {
      return false;
    }

    const lxDate = DateTime.fromJSDate(date);
    const startOfWeek = DateTime.fromJSDate(value).startOf('week');
    const endOfWeek = DateTime.fromJSDate(value).endOf('week');
    return lxDate <= endOfWeek && lxDate >= startOfWeek;
  };

  return (
    <DatePicker
      defaultDate={initialDate}
      withCellSpacing={false}
      onChange={onDateChange}
      excludeDate={excludeWeekEnds ? (date) => date.getDay() === 0 || date.getDay() === 6 : undefined}
      weekendDays={[]}
      locale={reverseLangMapping[i18n.language]}
      data-testid="date-picker"
      getDayProps={(date) => {
        const isHovered = isInWeekRange(date, weekDateHovered);
        const isSelected = isInWeekRange(date, initialDate);
        const isInRange = isHovered || isSelected;
        return {
          onMouseEnter: () => setWeekDateHovered(date),
          onMouseLeave: () => setWeekDateHovered(null),
          inRange: isInRange,
          firstInRange: isInRange && date.getDay() === 1,
          lastInRange:
            isInRange && ((excludeWeekEnds && date.getDay() === 5) || (!excludeWeekEnds && date.getDay() === 0)),
          selected: isSelected,
          onClick: () => onDateChange(date),
        };
      }}
      styles={() => ({
        calendar: {
          borderRadius: '1rem',
          border: '1px solid',
          borderColor: getThemeColorHex('neutral10'),
          boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.17)',
          padding: '16px',
          backgroundColor: getThemeColorHex('white'),
        },
        calendarHeaderControl: {
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        calendarHeader: {
          '& > button': {
            color: getThemeColorHex('neutral140'),
          },
        },
        calendarHeaderLevel: {
          color: getThemeColorHex('neutral140'),
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        yearsListCell: {
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
          '& > button:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        monthsListCell: {
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
          '& > button': {
            color: getThemeColorHex('neutral140'),
          },
          '& > button:hover': {
            backgroundColor: getThemeColorHex('neutral10'),
          },
        },
        weekday: {
          color: getThemeColorHex('neutral60'),
        },
        monthCell: {
          '&:hover': {
            borderRadius: '8px',
            backgroundColor: getThemeColorHex('primary20'),
          },
          '& > button': {
            color: getThemeColorHex('neutral140'),
          },
          '& > button[data-outside]': {
            color: getThemeColorHex('neutral60'),
          },
        },
        day: {
          '&[data-first-in-range]': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
          '&[data-last-in-range]': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          },
          '&[data-in-range]': {
            backgroundColor: getThemeColorHex('primary10'),
          },
          '&[data-selected]': {
            backgroundColor: getThemeColorHex('primary100'),
          },
          '&[data-selected]:hover': {
            backgroundColor: getThemeColorHex('primary110'),
          },
        },
      })}
    />
  );
};

export default JxtWeekDatePicker;
