import {
  BookingVisibilitySettingDeskEnum,
  BookingVisibilitySettingOtherEnum,
  LocationCompressed,
  PreferencesRequest,
  PreferencesRequestLangEnum,
  ResourceCompressed,
  ResourceTypeDtoMetaTypeEnum,
  UserRequestExtendedLang,
} from '@jooxter/api';
import {
  JxtBuildingSelect,
  JxtColorSelect,
  JxtLanguageSelect,
  JxtResourceSelect,
  JxtTimezoneSelect,
  JxtVisibilitySelect,
  preferencesToPreferencesRequestAdapter,
  useFetchResourceTypes,
  useMutatePatchPreferences,
  useMutatePutPreferences,
} from '@jooxter/core';
import { reverseLangMapping } from '@jooxter/i18n';
import { JxtContainer } from '@jooxter/ui';
import { createGTMGAEvent, JxtEventColorInfos, JxtEventColorsEnum, JxtBookingVisibilityEnum } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import { IBookingSettingsContainer } from './types';

export const BookingSettingsContainer = ({ userId, preferences }: IBookingSettingsContainer) => {
  const { t, i18n } = useTranslation();
  const { mutate: mutatePatchPreferences } = useMutatePatchPreferences(userId);
  const { mutate: mutatePutPreferences } = useMutatePutPreferences(userId);
  const { resourceTypes } = useFetchResourceTypes({ metaTypes: [ResourceTypeDtoMetaTypeEnum.Desk] });
  const defaultColorDeskValue = preferences.defaultBookingColor?.desk
    ? {
        value: JxtEventColorsEnum.parse(preferences.defaultBookingColor.desk),
        label: t(JxtEventColorInfos[JxtEventColorsEnum.parse(preferences.defaultBookingColor.desk)].translationKey),
      }
    : {
        value: JxtEventColorsEnum.Green,
        label: t(JxtEventColorInfos[JxtEventColorsEnum.Green].translationKey),
      };
  const defaultColorOtherValue = preferences.defaultBookingColor?.other
    ? {
        value: JxtEventColorsEnum.parse(preferences.defaultBookingColor.other),
        label: t(JxtEventColorInfos[JxtEventColorsEnum.parse(preferences.defaultBookingColor.other)].translationKey),
      }
    : {
        value: JxtEventColorsEnum.Primary,
        label: t(JxtEventColorInfos[JxtEventColorsEnum.Primary].translationKey),
      };
  const defaultVisibilityDeskValue = preferences.defaultBookingVisibility?.desk
    ? {
        value: preferences.defaultBookingVisibility.desk as unknown as JxtBookingVisibilityEnum,
        label: t('visibility-key-select-public-public-private-private', {
          visibility: preferences.defaultBookingVisibility.desk,
        }),
      }
    : {
        value: BookingVisibilitySettingDeskEnum.Public as unknown as JxtBookingVisibilityEnum,
        label: t('visibility-key-select-public-public-private-private', {
          visibility: BookingVisibilitySettingDeskEnum.Public,
        }),
      };
  const defaultVisibilityOtherValue = preferences.defaultBookingVisibility?.other
    ? {
        value: preferences.defaultBookingVisibility.other as unknown as JxtBookingVisibilityEnum,
        label: t('visibility-key-select-public-public-private-private', {
          visibility: preferences.defaultBookingVisibility.other,
        }),
      }
    : {
        value: BookingVisibilitySettingOtherEnum.Public as unknown as JxtBookingVisibilityEnum,
        label: t('visibility-key-select-public-public-private-private', {
          visibility: BookingVisibilitySettingOtherEnum.Public,
        }),
      };

  if (!preferences.location?.id || !preferences.timezone) {
    return null;
  }

  const onChange = (value: Partial<PreferencesRequest>) => {
    mutatePatchPreferences(value);
  };

  const onLanguageChange = (language: Partial<PreferencesRequest>) => {
    if (language.lang === reverseLangMapping[i18n.language]) {
      return;
    }
    mutatePatchPreferences(language);
  };

  const onLocationChange = (location: LocationCompressed | LocationCompressed[]) => {
    if (Array.isArray(location) || location.id === preferences.location?.id) {
      return;
    }
    const { preferencesRequest } = preferencesToPreferencesRequestAdapter(preferences);

    if (preferencesRequest) {
      preferencesRequest.location = location.id;
      preferencesRequest.resource = undefined;
      mutatePutPreferences(preferencesRequest);
    }
  };

  const onResourceChange = (resource: ResourceCompressed | null) => {
    if (resource?.id === preferences.resource?.id) {
      return;
    }
    createGTMGAEvent('Profile', 'Usage preferences', 'Preferred Desk');
    const { preferencesRequest } = preferencesToPreferencesRequestAdapter(preferences);
    if (preferencesRequest) {
      preferencesRequest.resource = resource?.id;
      mutatePutPreferences(preferencesRequest);
    }
  };

  return (
    <JxtContainer id="preferences-settings" title={t<string>('settings.preferences.title')}>
      <div className="flex flex-col grow gap-6">
        <div className="grid grid-cols-2 grow gap-6 grid-rows-[min-content] max-md:flex max-md:flex-col">
          <div className="flex flex-col gap-6">
            <JxtBuildingSelect
              label={t<string>('user-update-profile-label-building')}
              selectedOption={preferences.location.id}
              defaultValue={{ label: preferences.location.name, value: preferences.location.id }}
              onBuildingSelect={(value) => onLocationChange(value)}
            />
            <JxtResourceSelect
              label={t<string>('user-update-profile-label-resource')}
              helperText={t<string>('onboarding.favorite.desk.explanation')}
              placeholder={t<string>('choose-a-space')}
              noOptionsMessage={t<string>('user-update-profile-building.no-options')}
              defaultValue={
                preferences.resource?.id && preferences.resource?.name
                  ? { value: preferences.resource.id, label: preferences.resource.name }
                  : undefined
              }
              searchOptions={{
                locationId: [preferences.location.id],
                resourceTypeId: resourceTypes?.map((resourceType) => resourceType.id),
                bookable: true,
              }}
              onResourceSelect={(value) => onResourceChange(value)}
              key={preferences.location.id}
              isClearable
            />
            <JxtTimezoneSelect
              label={t<string>('user-update-profile-label-timezone')}
              defaultValue={{ value: preferences.timezone, label: preferences.timezone }}
              onTimezoneSelect={(value) => {
                if (value !== preferences.timezone) {
                  onChange({ timezone: value });
                }
              }}
            />
            <JxtLanguageSelect
              label={t<string>('user-update-profile-label-language')}
              defaultValue={reverseLangMapping[i18n.language] as UserRequestExtendedLang}
              onLanguageChange={(value) => onLanguageChange({ lang: value as unknown as PreferencesRequestLangEnum })}
              key={i18n.language}
            />
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-title-m text-neutral-120 font-medium">{t('user-update-profile-label-visibility')}</p>
            <div className="flex flex-col gap-2">
              <div className="flex gap-1 items-center justify-between">
                <p className="text-body-m text-neutral-140">{t('when-booking-desk')}</p>
                <div className="w-[140px] shrink-0">
                  <JxtVisibilitySelect
                    defaultValue={defaultVisibilityDeskValue}
                    onVisibilitySelect={(value) => {
                      if (
                        value !== (preferences.defaultBookingVisibility?.desk as unknown as JxtBookingVisibilityEnum)
                      ) {
                        onChange({
                          defaultBookingVisibility: { desk: value as unknown as BookingVisibilitySettingDeskEnum },
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <p className="text-neutral-100 text-body-s">
                {preferences.defaultBookingVisibility?.desk === BookingVisibilitySettingDeskEnum.Public
                  ? t('visibility-public-explanation-react')
                  : t('visibility-private-explanation-react')}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-1 items-center justify-between">
                <p className="text-body-m text-neutral-140">{t('when-booking-other')}</p>
                <div className="w-[140px] shrink-0">
                  <JxtVisibilitySelect
                    defaultValue={defaultVisibilityOtherValue}
                    onVisibilitySelect={(value) => {
                      if (
                        value !== (preferences.defaultBookingVisibility?.other as unknown as JxtBookingVisibilityEnum)
                      ) {
                        onChange({
                          defaultBookingVisibility: { other: value as unknown as BookingVisibilitySettingOtherEnum },
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <p className="text-neutral-100 text-body-s">
                {preferences.defaultBookingVisibility?.other === BookingVisibilitySettingOtherEnum.Public
                  ? t('visibility-public-explanation-react')
                  : t('visibility-private-explanation-react')}
              </p>
            </div>
            <p className="text-title-m text-neutral-120 font-medium">{t('user-update-profile-label-color')}</p>
            <div className="flex gap-1 items-center justify-between">
              <p className="text-body-m text-neutral-140">{t('when-booking-desk')}</p>
              <div className="w-[140px] shrink-0">
                <JxtColorSelect
                  defaultValue={defaultColorDeskValue}
                  onColorSelect={(value) => {
                    if (value !== preferences.defaultBookingColor?.desk) {
                      onChange({ defaultBookingColor: { desk: value } });
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex gap-1 items-center justify-between">
              <p className="text-body-m text-neutral-140">{t('when-booking-other')}</p>
              <div className="w-[140px] shrink-0">
                <JxtColorSelect
                  defaultValue={defaultColorOtherValue}
                  onColorSelect={(value) => {
                    if (value !== preferences.defaultBookingColor?.other) {
                      onChange({ defaultBookingColor: { other: value } });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </JxtContainer>
  );
};
