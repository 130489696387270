import { IRange, fromISO, now } from '@jooxter/utils';
import { useSearchOneBookingForUsers } from '../../queries/index';
import { useMemo } from 'react';

export const useCanBeLocated = (userIds: number[], range: IRange) => {
  const shouldFetchBookings = range.to > now();
  const { bookings } = useSearchOneBookingForUsers(userIds, range, shouldFetchBookings);
  const bookingMap = useMemo(() => {
    const map = new Map<number, boolean>();

    bookings.forEach((arrElement) => {
      const [entries] = Object.entries(arrElement);
      map.set(
        parseInt(entries[0], 10),
        (entries[1]?.filter((b) => now().startOf('day') < fromISO(b.end.dateTime)) ?? []).length > 0
      );
    });

    return map;
  }, [bookings]);

  return bookingMap;
};
