import capitalize from 'lodash-es/capitalize';
import { useCallback } from 'react';
import { RRule } from 'rrule';
import { useGenerateLocaleRRule } from './useGenerateLocaleRrule';

export const useRruleI18nService = () => {
  const { data, getText } = useGenerateLocaleRRule();

  /**
   * Generate an informative message
   * when the recurrence is validated
   */
  const generateInfoMessage = useCallback(
    (rrule: RRule): string => {
      const message = rrule.toText(getText, data);

      return capitalize(message);
    },
    [data]
  );

  return { generateInfoMessage };
};
