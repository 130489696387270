import { IJxtBookingRecurrence } from './types';

const JxtBookingRecurrence = ({ recurrence }: IJxtBookingRecurrence) => {
  return (
    <div className="flex items-center gap-3 text-body-m text-neutral-140">
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-arrows-rotate fa-fw fa-lg text-neutral-60" />
      </div>
      {recurrence}
    </div>
  );
};

export default JxtBookingRecurrence;
