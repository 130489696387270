import { IJxtCalendarDayWorkplace } from './types';
import JxtCalendarDayWorkplaceItem from '../JxtCalendarDayWorkplaceItem';
import { IJxtWorkplaceHalfDay, JxtWorkplaceTypeEnum } from '@jooxter/utils';
import JxtCalendarDayWorkplaceAdd from '../JxtCalendarDayWorkplaceAdd';

const JxtCalendarDayWorkplace = ({ workplaceDay, onClick }: IJxtCalendarDayWorkplace) => {
  const isSameWorkplace =
    workplaceDay.morning.type === workplaceDay.afternoon.type &&
    workplaceDay.morning.locationId === workplaceDay.afternoon.locationId;

  const renderWorkplace = (workplaceHalfDay: IJxtWorkplaceHalfDay) => {
    if (workplaceHalfDay.type === JxtWorkplaceTypeEnum.Unknown && workplaceHalfDay.capabilities.create) {
      return <JxtCalendarDayWorkplaceAdd onClick={() => onClick && onClick(workplaceHalfDay)} />;
    }
    return (
      <JxtCalendarDayWorkplaceItem
        type={workplaceHalfDay.type}
        locationName={workplaceHalfDay.locationName}
        onClick={() => onClick && onClick(workplaceHalfDay)}
      />
    );
  };

  return isSameWorkplace ? (
    renderWorkplace(workplaceDay.afternoon)
  ) : (
    <div className="flex gap-1">
      {renderWorkplace(workplaceDay.morning)}
      {renderWorkplace(workplaceDay.afternoon)}
    </div>
  );
};

export default JxtCalendarDayWorkplace;
