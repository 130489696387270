import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import Fetch from 'i18next-fetch-backend';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { JxtI18n, JxtI18nextOptionsFactory } from '@jooxter/i18n';
import infos from '../package.json';
import TagManager from 'react-gtm-module';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ViewportProvider } from '@jooxter/ui';
import ICU from 'i18next-icu';
import {
  AuthProvider,
  BookingFormProvider,
  ExceptionalOpeningHoursFormProvider,
  ModalLocateUserProvider,
  ModalManageBookingsProvider,
  ModalViolationsProvider,
  ModalWorkplaceProvider,
  OffCanvasProvider,
  queryClient,
} from '@jooxter/core';
import { JxtRouterProvider } from './routes/provider';
import { StrictMode } from 'react';

let i18nEnv: 'development' | 'prod' | 'staging' = import.meta.env.DEV ? 'development' : 'prod';

if (['staging', 'preproduction'].includes(import.meta.env.MODE)) {
  i18nEnv = 'staging';
}

JxtI18n.use(Fetch).use(ICU).use(initReactI18next).init(JxtI18nextOptionsFactory.build('webapp', i18nEnv));

if (import.meta.env.VITE_ENV_NAME === 'app' || import.meta.env.VITE_ENV_NAME === 'preprod') {
  Sentry.init({
    dsn: 'https://9a25f42af93743a6a730be6b06e0254f@o814636.ingest.sentry.io/4505092085055488',
    release: `webapp-${infos.version}`,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.6, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: ['An error occurred Error status 403 at https://app.jooxter.com/v3/login'],
  });
}

TagManager.initialize({
  gtmId: import.meta.env.VITE_GTM_ID,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <I18nextProvider i18n={JxtI18n}>
          <ViewportProvider>
            <Sentry.ErrorBoundary>
              <ModalViolationsProvider>
                <ModalLocateUserProvider>
                  <BookingFormProvider>
                    <ExceptionalOpeningHoursFormProvider>
                      <OffCanvasProvider>
                        <JxtRouterProvider>
                          <ModalManageBookingsProvider>
                            <ModalWorkplaceProvider>
                              <AuthProvider>
                                <App />
                              </AuthProvider>
                              <ReactQueryDevtools initialIsOpen={false} />
                            </ModalWorkplaceProvider>
                          </ModalManageBookingsProvider>
                        </JxtRouterProvider>
                      </OffCanvasProvider>
                    </ExceptionalOpeningHoursFormProvider>
                  </BookingFormProvider>
                </ModalLocateUserProvider>
              </ModalViolationsProvider>
            </Sentry.ErrorBoundary>
          </ViewportProvider>
        </I18nextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);
