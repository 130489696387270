import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  keepPreviousData,
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { BookingQueryKeys } from '../queryKeys';
import { IRange, TDeskOrParkingResourceTypeDto } from '@jooxter/utils';
import {
  Booking,
  BookingCapabilities,
  BookingCompressed,
  BookingService,
  IRemainingBookingsCheckOptions,
  ISearchBookingsParameters,
  JxtBookingStatusEnum,
  User,
  WorkplaceRuleRemainingDays,
} from '@jooxter/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BookingStaleTimeEnum } from '../staleTimes';
import { flatMap, uniqBy } from 'lodash-es';

export const useSearchOneBookingForUsers = (userIds: number[], range: IRange, enabled: boolean) => {
  const options = useMemo(
    () => ({
      ...range,
      size: 1,
      status: [JxtBookingStatusEnum.APPROVED, JxtBookingStatusEnum.REQUESTED, JxtBookingStatusEnum.FINISHED],
    }),
    [range]
  );
  const { data: bookings, isPending } = useQueries({
    queries:
      userIds?.map((id: number) => ({
        queryKey: [BookingQueryKeys.GetOneBookingForUser, id, options],
        queryFn: () => BookingService.search({ participantId: id, ...options }),
        placeholderData: keepPreviousData,
        staleTime: BookingStaleTimeEnum.GetBooking,
        enabled,
      })) ?? [],
    combine: (results) => {
      return {
        data: results.map((result, i) => ({ [userIds[i]]: (result.data as { data: BookingCompressed[] })?.data })),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { bookings, isPending };
};

export const useSearchBookings = (
  options: ISearchBookingsParameters,
  enabled = true,
  requiredCapabilities: (keyof BookingCapabilities)[] = []
): {
  bookings: BookingCompressed[] | undefined;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          data: BookingCompressed[];
          nextPage: string;
        },
        unknown
      >,
      Error
    >
  >;
  hasNextPage?: boolean;
  invalidateSearchBookings: () => void;
} => {
  const queryClient = useQueryClient();

  const invalidateSearchBookings = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [BookingQueryKeys.SearchBookings, options, requiredCapabilities],
    });
  }, [options, queryClient, requiredCapabilities]);

  if (!options.status || options.status?.length === 0) {
    options = {
      ...options,
      status: [JxtBookingStatusEnum.APPROVED, JxtBookingStatusEnum.REQUESTED, JxtBookingStatusEnum.FINISHED],
    };
  }

  const [bookings, setBookings] = useState<BookingCompressed[]>([]);
  const fetchBookings = useCallback(
    async ({ pageParam = '' }) => {
      const newOptions = { ...options };
      if (pageParam) {
        newOptions.page = pageParam;
      }

      return BookingService.search(newOptions);
    },
    [options]
  );

  const shouldFetch = enabled && options.from !== undefined && options.to !== undefined && options.from < options.to;

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [BookingQueryKeys.SearchBookings, options, requiredCapabilities],
    queryFn: fetchBookings,
    getNextPageParam: (lastPage: { nextPage?: string; data: BookingCompressed[] }) => {
      // must return undefined if it's the last page
      // lastPage.nextPage is ''
      return lastPage.nextPage?.length && lastPage.nextPage.length > 0 ? lastPage.nextPage : undefined;
    },
    initialPageParam: '',
    refetchInterval: 30000,
    enabled: shouldFetch,
  });

  useEffect(() => {
    if (data?.pages) {
      const pages = [...data.pages];
      const flatBookings = uniqBy(flatMap(pages.map((page) => page.data)), 'id');

      if (requiredCapabilities.length === 0) {
        setBookings(flatBookings);
      } else {
        const filteredBookings = flatBookings.filter((booking) =>
          requiredCapabilities.some((capability) => booking.capabilities?.[capability])
        );
        setBookings(filteredBookings);
      }
    }
  }, [data]);

  return { bookings, isLoading, isError, fetchNextPage, hasNextPage, invalidateSearchBookings };
};

export const useFetchBooking = (
  id?: number,
  options?: { retry?: boolean }
): {
  booking: Booking | undefined | null;
  isLoading: boolean;
  isError: boolean;
} => {
  const fetchBooking = useCallback(() => {
    if (id) {
      return BookingService.getById(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const {
    data: booking,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [BookingQueryKeys.GetBooking, id, options?.retry],
    queryFn: () => fetchBooking(),
    placeholderData: keepPreviousData,
    staleTime: BookingStaleTimeEnum.GetBooking,
    retry: options?.retry,
  });

  return { booking, isLoading, isError };
};

export const useFetchBookings = (ids: number[]) => {
  const { data: bookings, isPending } = useQueries({
    queries: ids.map((id: number) => {
      return {
        queryKey: [BookingQueryKeys.GetBooking, id],
        queryFn: () => BookingService.getById(id),
        staleTime: BookingStaleTimeEnum.GetBooking,
      };
    }),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is Booking => !!r),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { bookings, isPending };
};

export const useFetchExistingBookings = (
  deskOrParkingResourceTypes: TDeskOrParkingResourceTypeDto[],
  range?: IRange,
  user?: User | null
) => {
  const { bookings, invalidateSearchBookings } = useSearchBookings(
    {
      ...range,
      participantId: user?.id,
      resourceTypeId: deskOrParkingResourceTypes.map((resourceType) => resourceType.id),
      status: [JxtBookingStatusEnum.APPROVED, JxtBookingStatusEnum.REQUESTED],
    },
    true,
    ['cancel', 'update']
  );

  return { bookings, invalidateSearchBookings };
};

export const useFetchRemainingBookingsCheck = (
  options: IRemainingBookingsCheckOptions,
  enabled = true
): { data: WorkplaceRuleRemainingDays[] | undefined; isError: boolean } => {
  const fetchRemaining = useCallback(() => BookingService.getCheckRemainingBookings(options), [options]);

  const { data, isError } = useQuery({
    queryKey: [BookingQueryKeys.GetRemainingBookingsCheck, options],
    queryFn: () => fetchRemaining(),
    staleTime: BookingStaleTimeEnum.GetRemainingBookingsCheck,
    gcTime: 0,
    enabled,
    placeholderData: keepPreviousData,
  });

  return { data, isError };
};

export const useInvalidateBookingQueries = () => {
  const queryClient = useQueryClient();

  const invalidateBookingQueries = useCallback(
    (ids: number[]) => {
      queryClient.invalidateQueries({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        predicate: (query: any): boolean => {
          return (
            query.queryKey[0] === BookingQueryKeys.GetUserBookings ||
            (query.queryKey[0] === BookingQueryKeys.GetBooking && ids.includes(query.queryKey[1])) ||
            query.queryKey[0] === BookingQueryKeys.SearchBookings
          );
        },
      });
    },
    [queryClient]
  );

  return { invalidateBookingQueries };
};
