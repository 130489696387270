import { useTranslation } from 'react-i18next';

const JxtBookingEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white rounded-lg p-2.5 pl-0 text-body-s font-medium text-neutral-60">
      {t('booking.empty')}
    </div>
  );
};

export default JxtBookingEmpty;
