import { IJxtEventSummary } from './types';
import JxtEventColor from '../JxtEventColor';

const JxtEventSummary = ({ title, color }: IJxtEventSummary) => {
  return (
    <div className="flex items-center gap-3 text-title-m text-neutral-140 font-medium">
      <JxtEventColor color={color} />
      {title}
    </div>
  );
};

export default JxtEventSummary;
