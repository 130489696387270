import TagManager from 'react-gtm-module';

export const createGTMGAEvent = (category: string, action: string, label: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'GAEvent',
      category,
      action,
      label,
      value: '',
    },
  });
};

export const createGTMUpdateVirtualPathEvent = (viewPageName: string) => {
  TagManager.dataLayer({
    dataLayer: { event: 'updatevirtualpath', viewPageName },
  });
};
