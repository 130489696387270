import { createContext } from 'react';
import { IWorkplaceCalendarContext } from './types';

export const WorkplaceCalendarContext = createContext<IWorkplaceCalendarContext>({
  callbacks: {
    onJxtWorkplaceCalendarUnitClick: () => {
      throw new Error('Callback not implemented');
    },
    onJxtShareWorkplaceRangeDayClick: () => {
      throw new Error('Callback not implemented');
    },
    onJxtWorkplaceCalendarDayHeaderClick: () => {
      throw new Error('Callback not implemented');
    },
    onJxtShareWorkplaceRangePeriodClick: () => {
      throw new Error('Callback not implemented');
    },
  },
});
