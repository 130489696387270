import { IUserWorkplacesParameters, WorkplaceDtoTypeEnum } from '@jooxter/api';
import { fromISO, IRange } from '@jooxter/utils';
import { FilterContextColleague } from '../../store';

export const SearchWorkplacesFiltersToParamsAdapter = {
  adapt(defaultRange: IRange, filters?: FilterContextColleague): IUserWorkplacesParameters {
    if (!filters?.date) {
      return defaultRange;
    }

    return {
      ...filters,
      type: filters.type === null ? undefined : (filters.type as unknown as WorkplaceDtoTypeEnum),
      from: fromISO(filters.date.from),
      to: fromISO(filters.date.to),
    };
  },

  revert() {
    throw new Error('Method not implemented');
  },
};
