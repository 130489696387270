import clsx from 'clsx';
import { getJxtWorkplaceCalendarDayHeaderClass } from './styles';
import { IJxtWorkplaceCalendarDayHeader, JxtWorkplaceCalendarDayHeaderStateEnum } from './types';

const JxtWorkplaceCalendarDayHeader = ({
  text,
  onClick,
  dataDate = '',
  className = '',
  state = JxtWorkplaceCalendarDayHeaderStateEnum.Default,
}: IJxtWorkplaceCalendarDayHeader) => {
  const classes = getJxtWorkplaceCalendarDayHeaderClass({ state });

  return (
    <button
      className={clsx(className, classes)}
      data-date={dataDate}
      onClick={onClick}
      disabled={state === JxtWorkplaceCalendarDayHeaderStateEnum.Disabled}
    >
      {text}
    </button>
  );
};

export default JxtWorkplaceCalendarDayHeader;
