import { NotificationChannel, NotificationSettings } from '@jooxter/api';

/* eslint-disable */
export const updateAllNotificationsByType = (
  notificationsList: NotificationSettings | NotificationChannel,
  type: 'push' | 'mail',
  value: boolean
) => {
  for (const key in notificationsList) {
    const typedKey = key as keyof NotificationSettings;
    // @ts-ignore
    if (typeof notificationsList[typedKey] === 'object' && !Array.isArray(notificationsList[typedKey])) {
      // @ts-ignore
      updateAllNotificationsByType(notificationsList[typedKey], type, value);
    } else if (typedKey === (type as keyof NotificationSettings)) {
      // @ts-ignore
      notificationsList[typedKey] = value;
    }
  }
  return notificationsList;
};
/* eslint-enable */
