import { JxtChooseWorkplaceOverlayContent, JxtModal } from '@jooxter/ui';
import { IJxtModalWorkplace } from './types';

const JxtModalWorkplace = ({ show, onHide, chooseWorkplaceModalProps }: IJxtModalWorkplace) => {
  if (!chooseWorkplaceModalProps) {
    return null;
  }

  return (
    <JxtModal
      show={!!show}
      onHide={onHide}
      header={{
        title: `${chooseWorkplaceModalProps.userInfos.firstname} ${chooseWorkplaceModalProps.userInfos.lastname}`,
        icon: 'sliders',
      }}
    >
      <JxtChooseWorkplaceOverlayContent {...chooseWorkplaceModalProps} />
    </JxtModal>
  );
};

export default JxtModalWorkplace;
