import { ResourceService } from '@jooxter/api';
import { useMutation } from '@tanstack/react-query';

export const useMutatePutFavoriteResource = () => {
  const mutation = useMutation({
    mutationFn: (id: number) => ResourceService.putFavorite(id),
  });
  return mutation;
};

export const useMutateDeleteFavoriteResource = () => {
  const mutation = useMutation({
    mutationFn: (id: number) => ResourceService.deleteFavorite(id),
  });
  return mutation;
};
