import { axiosManager } from '../config';
import { WorkplaceRuleDto } from '../model';

export const WorkplaceRulesService = {
  path: 'users',

  getWorkplaceRules(userId: number): Promise<WorkplaceRuleDto[]> {
    const url = `${this.path}/${userId}/workplace_rules`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },
};
