import { RecurrenceDto } from '@jooxter/api';
import { createContext, useContext } from 'react';

export interface IModalViolationsContext {
  show: boolean;
  setShow: (show: boolean) => void;
  showViolations: (recurrence: RecurrenceDto) => void;
  recurrence: RecurrenceDto | null;
}

export const ModalViolationsContext = createContext<IModalViolationsContext | null>(null);

export const useModalViolationsContext = (): IModalViolationsContext | null => {
  return useContext(ModalViolationsContext);
};
