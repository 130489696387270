import {
  AxiosInstanceEnum,
  ISearchUsersParameters,
  LoginService,
  User,
  UserCompressed,
  UserLoginDetail,
  UserLoginDetailApiVersionEnum,
  UserService,
  WorkHoursWeekDto,
} from '@jooxter/api';
import { useStore } from '../../store';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { UserQueryKeys } from '../queryKeys';
import { UserStaleTimeEnum } from '../staleTimes';
import { useCallback, useEffect, useState } from 'react';
import { flatMap, uniqBy } from 'lodash-es';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AxiosError } from 'axios';
import { ErrorEnum } from '@jooxter/ui';
import { useAuthContext } from '../../auth';
import { useShallow } from 'zustand/shallow';

export const useFetchUser = () => {
  const [token, setEmail] = useStore(useShallow((state) => [state.token, state.setEmail]));
  const fetchUser = () => UserService.getMe();
  const { logout } = useAuthContext();

  const { data: user, error } = useQuery<User, AxiosError>({
    queryKey: [UserQueryKeys.GetMe, token],
    queryFn: () => fetchUser(),
    staleTime: UserStaleTimeEnum.GetMe,
    enabled: !!token,
  });

  useEffect(() => {
    if (error?.status === ErrorEnum.NOT_AUTHORIZED || error?.status === ErrorEnum.FORBIDDEN) {
      logout();
    }
  }, [error, logout]);

  useEffect(() => {
    fetchAuthSession();

    if (user?.email) {
      setEmail(user.email);
    }
  }, [user, setEmail]);

  return { user };
};

export const useFetchUserById = (id: number | undefined | null): { user: User | null | undefined } => {
  const fetchUser = useCallback(() => {
    if (id) {
      return UserService.getUser(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: user } = useQuery({
    queryKey: [UserQueryKeys.GetUserById, id],
    queryFn: () => fetchUser(),
    staleTime: UserStaleTimeEnum.GetUserById,
  });

  return { user };
};

export const useFetchUsersSearch = (
  options: ISearchUsersParameters
): {
  users: UserCompressed[] | undefined;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          data: UserCompressed[];
          nextPage: string;
        },
        unknown
      >
    >
  >;
  hasNextPage?: boolean;
  lastPage: UserCompressed[];
  isFetching: boolean;
} => {
  const [users, setUsers] = useState<UserCompressed[]>([]);
  const [lastPage, setLastPage] = useState<UserCompressed[]>([]);
  const fetchUsers = async ({ pageParam = '' }) => {
    const newOptions = { ...options };
    if (pageParam) {
      newOptions.page = pageParam;
    }

    return await UserService.getUsersWithPagination(newOptions);
  };

  const { data, isLoading, isError, isFetching, isRefetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [UserQueryKeys.GetUsersSearch, options],
    queryFn: fetchUsers,
    getNextPageParam: (lastPage: { nextPage?: string; data: UserCompressed[] }) => {
      // must return undefined if it's the last page
      // lastPage.nextPage is ''
      return lastPage.nextPage?.length && lastPage.nextPage.length > 0 ? lastPage.nextPage : undefined;
    },
    initialPageParam: '',
    staleTime: UserStaleTimeEnum.GetUsersSearch,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (data?.pages.length) {
      const pages = [...data.pages];
      setLastPage(data.pages[pages.length - 1].data);
      const users = uniqBy(flatMap(pages.map((page) => page.data)), 'id');
      setUsers(users);
    }
  }, [data?.pages]);

  return {
    users,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    lastPage,
    isFetching: isRefetching || isFetching,
  };
};

export const useFetchLoginDetails = (email?: string): { loginDetails?: UserLoginDetail | null; isPending: boolean } => {
  const [setAxiosInstance] = useStore(useShallow((state) => [state.setAxiosInstance]));

  const fetchLoginDetails = () => {
    if (email) {
      return LoginService.getUserLoginDetails(email);
    }

    return Promise.reject(null);
  };

  const { data: loginDetails, isPending } = useQuery({
    queryKey: [UserQueryKeys.GetUserLoginDetails, email],
    queryFn: () => fetchLoginDetails(),
    staleTime: UserStaleTimeEnum.GetUserLoginDetails,
    enabled: !!email,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (loginDetails?.apiVersion === UserLoginDetailApiVersionEnum.V4) {
      setAxiosInstance(AxiosInstanceEnum.axiosInstanceV4);
    }
  }, [loginDetails, setAxiosInstance]);

  return { loginDetails, isPending };
};

export const useFetchUserPreferences = (id?: number) => {
  const fetchUserPreferences = useCallback(() => {
    if (id) {
      return UserService.getPreferences(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: preferences } = useQuery({
    queryKey: [UserQueryKeys.GetMyPreferences, id],
    queryFn: () => fetchUserPreferences(),
    staleTime: UserStaleTimeEnum.GetMyPreferences,
  });

  return { preferences };
};

export const useFetchUserNotifications = (id?: number) => {
  const fetchUserNotifications = useCallback(() => {
    if (id) {
      return UserService.getNotifications(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: notifications } = useQuery({
    queryKey: [UserQueryKeys.GetMyNotifications, id],
    queryFn: () => fetchUserNotifications(),
    staleTime: UserStaleTimeEnum.GetMyNotifications,
  });

  return { notifications };
};

export const useFetchUserIcsCalendar = () => {
  const fetchUserIcsCalendar = () => UserService.postUserIcsCalendar();

  const { data: calendar } = useQuery({
    queryKey: [UserQueryKeys.PostUserIcsCalendar],
    queryFn: () => fetchUserIcsCalendar(),
    staleTime: UserStaleTimeEnum.PostUserIcsCalendar,
  });

  return { calendar };
};

export const useFetchWeek = (id?: number): { week: WorkHoursWeekDto | undefined | null } => {
  const fetchWeek = useCallback(() => {
    if (id) {
      return UserService.getTypicalWeek(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: week } = useQuery({
    queryKey: [UserQueryKeys.GetTypicalWeek, id],
    queryFn: () => fetchWeek(),
    staleTime: UserStaleTimeEnum.GetTypicalWeek,
  });

  return { week };
};

export const useInvalidatePersonalUser = () => {
  const queryClient = useQueryClient();
  const [token] = useStore(useShallow((state) => [state.token]));

  const invalidatePersonalUser = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [UserQueryKeys.GetMe, token],
    });
  }, [queryClient, token]);

  return { invalidatePersonalUser };
};
