import { ExceptionalOpeningHours } from '@jooxter/api';
import { createContext, useContext } from 'react';

export interface ExceptionalOpeningHoursFormContextType {
  showExceptionalOpeningHours: ExceptionalOpeningHours | null;
  showExceptionalOpeningHoursDetails: (exceptionalOpeningHours: ExceptionalOpeningHours) => void;
  closeExceptionalOpeningHoursDetails: () => void;
}

export const ExceptionalOpeningHoursFormContext = createContext<ExceptionalOpeningHoursFormContextType | null>(null);

export const useExceptionalOpeningHoursFormContext = (): ExceptionalOpeningHoursFormContextType | null => {
  const context = useContext(ExceptionalOpeningHoursFormContext);

  return context;
};
