import { JxtCollapsibleCheckboxList } from '@jooxter/ui';
import { IJxtSpaceTypesCheckboxList } from './types';
import { useFetchResourceTypes } from '../../queries';
import { useTranslation } from 'react-i18next';
import { TJxtCheckboxListItem } from '@jooxter/ui/src/components/JxtCheckboxList/types';
import { useFoldedFilters } from '../../hooks/useFoldedFilters';

export const JxtSpaceTypesCheckboxList = ({ selectedOptions, onChange, scope }: IJxtSpaceTypesCheckboxList) => {
  const { t } = useTranslation();
  const { resourceTypes } = useFetchResourceTypes();

  const { folded, onFoldChange } = useFoldedFilters(scope, 'spaceType');

  if (!resourceTypes || resourceTypes.length === 0) {
    return null;
  }

  const onChangeSpaceTypes = (spaceTypes: TJxtCheckboxListItem[]) => {
    if (onChange) {
      onChange(resourceTypes.filter((r) => spaceTypes.some((sT) => sT.value === r.id)).map((r) => r.id));
    }
  };

  return (
    <div className="sm:px-6 py-4 border-b border-neutral-20">
      <JxtCollapsibleCheckboxList
        defaultFolded={folded}
        onFoldChange={(e) => onFoldChange(e)}
        label={t<string>('resourcesearch-label-select-resource-types')}
        allOrNoneSelectedOptionsLabel={t<string>('collapsible.checkbox.list-all')}
        options={resourceTypes.map((rt) => ({ value: rt.id, label: rt.name }))}
        selectedOptions={resourceTypes
          .filter((r) => selectedOptions.includes(r.id))
          .map((r) => ({ value: r.id, label: r.name }))}
        onChange={onChangeSpaceTypes}
      />
    </div>
  );
};
