import JxtModal from '../JxtModal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import { JxtModalSizeEnum } from '../JxtModal/types';
import JxtRadioButton from '../JxtRadioButton';
import { IJxtUpdateBookingModal } from './types';
import { DateTime } from 'luxon';
import { RecurrentBookingClosingResultEnum } from '@jooxter/utils';

const JxtUpdateBookingModal = ({
  show,
  oldEvent,
  event,
  timezone,
  onHide,
  recurrence,
  oldResource,
  newResource,
}: IJxtUpdateBookingModal) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      recurrence: RecurrentBookingClosingResultEnum.ONLY_SELF,
    },
  });

  const getLocateDate = (d: DateTime) => d.setZone(timezone).toLocaleString(DateTime.DATE_MED);
  const getLocateTime = (d: DateTime) => d.setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE);

  const onSubmit = (e: { recurrence: RecurrentBookingClosingResultEnum }) => {
    onHide({ recurrence: e.recurrence === RecurrentBookingClosingResultEnum.FOLLOWING });
  };

  return (
    <JxtModal
      show={show}
      size={JxtModalSizeEnum.S}
      onHide={onHide}
      header={{
        icon: 'pen-to-square',
        title: t('you-are-about-to-modify-the-following-informations'),
      }}
      footer={
        <>
          <JxtButton onClick={() => onHide()} variant={ButtonVariantEnum.Secondary}>
            {t('cancel')}
          </JxtButton>
          <JxtButton onClick={handleSubmit(onSubmit)} variant={ButtonVariantEnum.Primary}>
            {t('confirm')}
          </JxtButton>
        </>
      }
    >
      {oldEvent && event && (
        <div className="flex flex-nowrap">
          <div className="grow shrink-0 basis-2/4 max-w-[50%]">
            <h5 className="text-title-l font-medium mb-3">{t('current')}</h5>
            <div className="flex items-center mb-1">
              <i className="fas fa-fw fa-calendar text-neutral-60 mr-2" />
              {oldEvent.start && getLocateDate(oldEvent.start)}
            </div>
            <div className="flex items-center mb-1">
              <i className="fas fa-fw fa-clock text-neutral-60 mr-2" />
              {oldEvent.start && getLocateTime(oldEvent.start)} - {oldEvent.end && getLocateTime(oldEvent.end)}
            </div>
            {!!oldResource && !!newResource && (
              <div className="flex items-center">
                <i className="fas fa-fw fa-map-marker-alt text-neutral-60 mr-2 shrink-0" />
                <p className="truncate">{oldResource.name}</p>
              </div>
            )}
          </div>
          <div className="grow shrink-0 basis-2/4 max-w-[50%]">
            <h5 className="text-title-l font-medium mb-3">{t('updated')}</h5>
            <div className="flex items-center mb-1">
              <i className="fas fa-fw fa-calendar text-neutral-60 mr-2" />
              {event.start && getLocateDate(event.start)}
            </div>
            <div className="flex items-center mb-1">
              <i className="fas fa-fw fa-clock text-neutral-60 mr-2" />
              {event.start && getLocateTime(event.start)} - {event.end && getLocateTime(event.end)}
            </div>
            {!!oldResource && !!newResource && (
              <div className="flex items-center">
                <i className="fas fa-fw fa-map-marker-alt text-neutral-60 mr-2 shrink-0" />
                <p className="truncate">{newResource.name}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {recurrence && <hr className="text-neutral-20 mt-4 mb-4" />}
      {recurrence && (
        <form className="flex flex-col gap-3">
          <h3 className="text-neutral-140 font-semibold">{t('what-event-do-you-want-to-modify')}</h3>
          <JxtRadioButton
            value={RecurrentBookingClosingResultEnum.ONLY_SELF}
            label={t<string>('this-booking-only')}
            {...register('recurrence')}
            checked={true}
          />
          <JxtRadioButton
            value={RecurrentBookingClosingResultEnum.FOLLOWING}
            label={t<string>('this-booking-and-the-following-occurrences')}
            {...register('recurrence')}
            checked={false}
          />
        </form>
      )}
    </JxtModal>
  );
};

export default JxtUpdateBookingModal;
