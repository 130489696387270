import { now } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { z } from 'zod';

export const startBeforeEndSuperRefinerFactory = () => (data: { start: string; end: string }, ctx: z.RefinementCtx) => {
  const start = getDateTimeFromJSDateAndStandardTime(data.start);
  const end = getDateTimeFromJSDateAndStandardTime(data.end);

  if (start > end || start.equals(end)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'space-search-form.error.to-before-from',
      path: ['start'],
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: ' ',
      path: ['end'],
    });
  }
};

export const getDateTimeFromJSDateAndStandardTime = (time: string): DateTime => {
  const dateTime = now();
  const newTime = DateTime.fromFormat(time, 'HH:mm');

  return dateTime.set({ hour: newTime.hour, minute: newTime.minute, second: 0, millisecond: 0 });
};
