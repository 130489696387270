import { ITimelineOptions, ResourceTimelineStatusDto, TimelineService } from '@jooxter/api';
import { keepPreviousData, useQueries, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ResourceQueryKeys } from '../queryKeys';
import { ResourceStaleTimeEnum } from '../staleTimes';
import { DateTime } from 'luxon';

export const useFetchTimeline = (options?: ITimelineOptions): { slots: ResourceTimelineStatusDto[] | undefined } => {
  const fetchTimeline = useCallback(
    () => (options ? TimelineService.getSlots(options) : Promise.resolve([])),
    [options]
  );

  const { data: slots } = useQuery({
    queryKey: [ResourceQueryKeys.GetTimeline, options],
    queryFn: () => fetchTimeline(),
    placeholderData: keepPreviousData,
    staleTime: ResourceStaleTimeEnum.GetTimeline,
    gcTime: 0,
  });

  return { slots };
};

export const useFetchTimelines = (
  options: Omit<ITimelineOptions, 'start' | 'end'> & {
    ranges: Array<{
      start: DateTime;
      end: DateTime;
    }>;
  }
) => {
  const fetchTimeline = (o: ITimelineOptions) => TimelineService.getSlots(o);

  const { data: timelines, isPending } = useQueries({
    queries: options.ranges.map(({ start, end }: { start: DateTime; end: DateTime }) => {
      return {
        queryKey: [ResourceQueryKeys.GetTimeline, options, start, end],
        queryFn: () => fetchTimeline({ ...options, start, end }),
        placeholderData: keepPreviousData,
        staleTime: ResourceStaleTimeEnum.GetTimeline,
      };
    }),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is ResourceTimelineStatusDto[] => !!r),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { timelines, isPending };
};
