import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { SingleValue } from 'react-select';
import { Frequency, RRule } from 'rrule';
import { JxtSelect, SelectOption } from '../Selectors';
import { IJxtRecurrenceFrequencySelect, TFrequency, TFrequencyOption } from './types';

const JxtRecurrenceFrequencySelect = ({ value, onChange, className = '' }: IJxtRecurrenceFrequencySelect) => {
  const { t } = useTranslation();
  const frequencies = Object.values(Frequency)
    .filter((freq): freq is Frequency => typeof freq === 'number')
    .filter((freq): freq is TFrequency => ![Frequency.SECONDLY, Frequency.MINUTELY, Frequency.HOURLY].includes(freq));

  const options: TFrequencyOption[] = frequencies.map((f) => ({
    label: t<string>('booking-form-recurrence-frequency-option', { frequency: RRule.FREQUENCIES[f] }),
    value: f,
  }));

  const onFrequencyChange = (selection: SingleValue<SelectOption<TFrequency>>) =>
    selection && onChange(selection.value);

  return (
    <JxtSelect
      className={className}
      value={options.find((o) => o.value === value)}
      isSearchable={false}
      options={options}
      handleSelection={onFrequencyChange}
    />
  );
};

export default JxtRecurrenceFrequencySelect;
