import { ButtonVariantEnum, JxtButton, JxtModal } from '@jooxter/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JxtBookingModalContent from '../JxtBookingModalContent';
import { IJxtModalManageBookings } from './types';

const JxtModalManageBookings = ({
  show,
  onConfirm,
  onHide,
  title,
  bookings,
  period,
  onChange,
}: IJxtModalManageBookings) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(show);
  const { t } = useTranslation();

  const header = isSubmitted
    ? {
        title: t('manage-closing.validation'),
        icon: 'check',
        iconColor: 'green-100',
      }
    : {
        title: t('manage-closing.action-required-from-you'),
        icon: 'cog',
      };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const closeModale = () => {
    setShowModal(false);
    setIsSubmitted(false);
    onHide && onHide();
  };

  if (!onChange) {
    return null;
  }

  return (
    <JxtModal
      header={header}
      footer={
        isSubmitted ? (
          <JxtButton onClick={closeModale}>{t('close')}</JxtButton>
        ) : (
          <>
            <JxtButton variant={ButtonVariantEnum.Secondary} onClick={closeModale}>
              {t('cancel')}
            </JxtButton>
            <JxtButton
              onClick={() => {
                setIsSubmitted(true);
                onConfirm && onConfirm();
              }}
            >
              {t('confirm')}
            </JxtButton>
          </>
        )
      }
      show={showModal}
      onHide={closeModale}
    >
      <JxtBookingModalContent
        readonly={isSubmitted}
        bookings={bookings}
        title={title}
        period={period}
        onChange={onChange}
      />
    </JxtModal>
  );
};

export default JxtModalManageBookings;
