import { AxiosError, AxiosHeaders } from 'axios';
import { useLayoutEffect, useCallback, useMemo, useEffect } from 'react';
import { AxiosInstanceEnum, axiosManager, UserLoginDetailTypeEnum } from '@jooxter/api';
import { useStore } from '../store';
import { isJwtTokenExpired, updateAxiosInterceptors } from './helpers';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useFetchLoginDetails } from '../queries';
import { useAuthContext } from './AuthContext';
import { ErrorEnum } from '@jooxter/ui';
import { useShallow } from 'zustand/shallow';

export const useInterceptors = (version: string, clientName: string) => {
  const [token, email, setToken, axiosInstance] = useStore(
    useShallow((state) => [state.token, state.email, state.setToken, state.axiosInstance])
  );
  const { loginDetails } = useFetchLoginDetails(email);
  const { loginSSO, logout } = useAuthContext();

  const onResponseError = useCallback(
    (error: AxiosError) => {
      if (error.response?.status === ErrorEnum.NOT_AUTHORIZED) {
        if (!email) {
          logout();
        }

        fetchAuthSession({ forceRefresh: true })
          .then((session) => {
            if (!session.tokens?.accessToken) {
              throw new Error('Tokens not found');
            }

            setToken(session.tokens?.accessToken.toString());
          })
          .catch((error) => {
            console.error(error);
            if (loginDetails) {
              if (loginDetails.type === UserLoginDetailTypeEnum.Jooxter) {
                logout();
              } else {
                loginSSO();
              }
            } else {
              logout();
            }
          });
      }

      return Promise.reject(error);
    },
    [setToken, loginDetails, logout, loginSSO, email]
  );

  const setTokenHeader = useCallback(
    async (headers: AxiosHeaders, token2: string) => {
      if (axiosInstance === AxiosInstanceEnum.axiosInstanceV3) {
        headers.delete('Authorization');
        return headers.set('X-API-KEY', token2);
      }

      if (axiosInstance === AxiosInstanceEnum.axiosInstanceV4) {
        headers.delete('X-API-KEY');
        try {
          // trying to decode an x-api-key token will throw an error
          if (isJwtTokenExpired(token2)) {
            try {
              const session = await fetchAuthSession({ forceRefresh: true });
              if (!session.tokens?.accessToken) {
                throw new Error('Tokens not found');
              }
              const newToken = session.tokens?.accessToken.toString();
              setToken(newToken);
              return headers.set('Authorization', `Bearer ${newToken}`);
            } catch {
              if (loginDetails) {
                if (loginDetails.type === UserLoginDetailTypeEnum.Jooxter) {
                  logout();
                } else {
                  loginSSO();
                }
              } else {
                logout();
              }
            }
          }
          return headers.set('Authorization', `Bearer ${token2}`);
        } catch (error) {
          console.error(error);
          logout();
        }
      }
    },
    [axiosInstance, setToken, loginDetails, logout, loginSSO]
  );

  const setAxiosHeaders = useCallback(
    async (config: { headers: AxiosHeaders }) => {
      const headers = new AxiosHeaders({ ...config.headers });

      if (token) {
        await setTokenHeader(headers, token);
      }

      headers.set('X-Client-Name', clientName);
      headers.set('X-Client-Version', version);

      config.headers = headers;
      return config;
    },
    [token, clientName, version, setTokenHeader]
  );

  const axiosInterceptorsConfig = useMemo(
    () => ({ onResponseError, onRequestSuccess: setAxiosHeaders }),
    [onResponseError, setAxiosHeaders]
  );

  useEffect(() => {
    axiosManager.setInstance(axiosInstance);

    if (!token) {
      return;
    }

    return updateAxiosInterceptors(axiosManager.getInstance(), axiosInterceptorsConfig);
  }, [token, axiosInterceptorsConfig, axiosInstance]);

  useLayoutEffect(() => {
    if (!token) {
      return;
    }

    return updateAxiosInterceptors(axiosManager.getInstance(), axiosInterceptorsConfig);
  }, [token, axiosInterceptorsConfig]);
};
