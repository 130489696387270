import { IRange } from '@jooxter/utils';
import { capitalize } from 'lodash-es';
import { DateTime } from 'luxon';

export const getButtonText = (range: IRange, viewType: string): string => {
  if (range && viewType) {
    const from = range.from;
    const to = range.to;

    const oneDay = from.toLocaleString(DateTime.DATE_MED);

    // Month View
    if (['dayGridMonth', 'listMonth', 'resourceTimelineMonth', 'paintMonthView'].includes(viewType)) {
      return `${capitalize(from.toFormat('LLL yyyy'))}`;
    }

    // remember that end is exclusive
    if (to.minus({ days: 1 }).hasSame(from, 'day')) {
      return oneDay;
    }

    // Week view
    if (['listWeek', 'dayGridWeek', 'resourceTimelineWeek', 'timeGridWeek', 'colleagueView'].includes(viewType)) {
      if (from.hasSame(to.minus({ days: 1 }), 'month')) {
        return `${from.day} - ${to.minus({ days: 1 }).day} ` + `${from.toFormat('MMM yyyy')}`;
      } else {
        return (
          `${from.day} ` + `${from.toFormat('LLL')} - ` + `${to.minus({ days: 1 }).day} ` + `${to.toFormat('LLL yyyy')}`
        );
      }
    }
  }

  return 'Invalid range or view type';
};

export const prependCalendarIconToDatePickerBtn = () => {
  const icon = document.createElement('i');
  icon.classList.add('fas', 'fa-calendar', 'mr-2', 'text-neutral-120');

  const button: HTMLButtonElement | null = document.querySelector('.fc-datePickerButton-button');
  const hasIcon = !!button?.querySelector('i.fas');

  if (!hasIcon) {
    button?.prepend(icon);
  } else {
    const hasCorrectIcon = !!button?.querySelector('i.fa-calendar');
    if (!hasCorrectIcon) {
      button?.querySelector('i.fas')?.remove();
      button?.prepend(icon);
    }
  }
};

export const prependCalendarIconToIcsBtn = () => {
  const icon = document.createElement('i');
  icon.classList.add('fas', 'fa-calendar-plus', 'text-neutral-120');

  const button: HTMLButtonElement | null = document.querySelector('.fc-faCalendarPlus-button');
  const hasIcon = !!button?.querySelector('i.fas');

  if (!hasIcon) {
    button?.prepend(icon);
  } else {
    const hasCorrectIcon = !!button?.querySelector('i.fa-calendar-plus');
    if (!hasCorrectIcon) {
      button?.querySelector('i.fas')?.remove();
      button?.prepend(icon);
    }
  }
};

export const prependPlusIconMyWorkplace = () => {
  const icon = document.createElement('i');
  icon.classList.add('fas', 'fa-plus', 'mr-2', 'text-white');

  const button: HTMLButtonElement | null = document.querySelector('.fc-myWorkplaceButton-button');
  const hasIcon = !!button?.querySelector('i.fas');

  if (!hasIcon) {
    button?.prepend(icon);
  } else {
    const hasCorrectIcon = !!button?.querySelector('i.fa-plus');
    if (!hasCorrectIcon) {
      button?.querySelector('i.fas')?.remove();
      button?.prepend(icon);
    }
  }
};
