import { JxtEventColor, JxtEventColorSizeEnum, JxtSelect } from '@jooxter/ui';
import { JxtEventColorInfos, JxtEventColorsEnum } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import { ColorOption, IJxtColorSelect } from './types';
//eslint-disable-next-line import/named
import { SingleValue } from 'react-select';

export const JxtColorSelect = ({ onColorSelect, defaultValue, value, label, helperText }: IJxtColorSelect) => {
  const { t } = useTranslation();
  const options: ColorOption[] = Object.values(JxtEventColorsEnum)
    .filter((color): color is JxtEventColorsEnum => color !== JxtEventColorsEnum.parse)
    .map((color) => ({
      value: color,
      label: t(JxtEventColorInfos[color].translationKey),
    }));

  const handleColorSelect = (e: SingleValue<ColorOption>) => {
    if (e?.value && onColorSelect) {
      onColorSelect(e.value);
    }
  };

  const formatOptionLabel = ({ value, label }: ColorOption) => (
    <div className="flex items-center gap-2">
      <JxtEventColor color={value} size={JxtEventColorSizeEnum.SMALL} />
      {label}
    </div>
  );

  return (
    <JxtSelect
      value={value}
      defaultValue={defaultValue ?? options[0]}
      label={label}
      helperText={helperText}
      isSearchable={false}
      options={options}
      handleSelection={handleColorSelect}
      formatOptionLabel={formatOptionLabel}
    />
  );
};
