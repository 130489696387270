import { JxtEventFormRange, JxtInput, JxtRadioButton } from '@jooxter/ui';
import { UseFormRegister, FieldValues, FormState, UseFormGetFieldState } from 'react-hook-form';
import { z, ZodTypeAny } from 'zod';
import { useTranslation } from 'react-i18next';
import { ExceptionalOpeningHoursTypeEnum } from '@jooxter/utils';
import { ExceptionalOpeningHours, Resource } from '@jooxter/api';
import { useMemo } from 'react';
import { Settings } from 'luxon';
import { useFetchUser } from '../../../queries';

export const useGenerateExceptionalOpeningHoursForm = (
  {
    register,
    formState,
    getFieldState,
  }: {
    register: UseFormRegister<FieldValues>;
    formState: FormState<FieldValues>;
    getFieldState: UseFormGetFieldState<FieldValues>;
  },
  {
    schema,
    exceptionalOpeningHoursToEdit,
    resource,
  }: {
    schema?: z.ZodObject<
      {
        [x: string]: ZodTypeAny;
      },
      'strip',
      ZodTypeAny,
      {
        [x: string]: unknown;
      },
      {
        [x: string]: unknown;
      }
    >;
    exceptionalOpeningHoursToEdit?: ExceptionalOpeningHours | null;
    resource?: Resource | null;
  }
) => {
  const { t } = useTranslation();
  const { user } = useFetchUser();

  const resourceTimezone = useMemo(() => {
    if (resource) {
      return resource.location.timezone;
    }
    return Settings.defaultZone.name;
  }, [resource]);

  if (!schema?.shape || !user?.id) {
    return null;
  }

  return (
    <>
      <div className="grid grid-cols-[min-content_auto] items-center gap-x-3">
        <i className="fas fa-fw fa-lg fa-quote-left text-neutral-60 col-start-1 row-start-1" />
        <div className="col-start-2 row-start-1">
          <JxtInput
            name="title"
            placeholder={t<string>('manage-space-form.summary-input-placeholder')}
            register={register}
            formState={formState}
            getFieldState={getFieldState}
            showErrorMessages={false}
          />
        </div>
        {getFieldState('title').error?.message && typeof getFieldState('title').error?.message === 'string' && (
          <p className="text-red-100 pt-1 col-start-2 row-start-2">{getFieldState('title').error?.message as string}</p>
        )}
      </div>
      <JxtEventFormRange sameDay={false} timezone={resourceTimezone} isEditing={!!exceptionalOpeningHoursToEdit} />
      <div className="grid items-center gap-x-3 -mb-2 gap-y-2 grid-cols-[min-content_auto] grid-rows-[repeat(2,_auto)]">
        <i className="fas fa-fw fa-lg fa-lock text-neutral-60 col-span-1 col-start-1 row-start-1" />
        <JxtRadioButton
          id={ExceptionalOpeningHoursTypeEnum.Opening}
          label={t<string>('manage-space-form.opening')}
          value={ExceptionalOpeningHoursTypeEnum.Opening}
          className="col-start-2 row-start-1"
          {...register('type')}
        />
        <JxtRadioButton
          id={ExceptionalOpeningHoursTypeEnum.Closing}
          label={t<string>('manage-space-form.closing')}
          value={ExceptionalOpeningHoursTypeEnum.Closing}
          className="col-start-2 row-start-2"
          {...register('type')}
        />
      </div>
    </>
  );
};
