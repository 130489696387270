import '../../../../fullcalendar/src/styles/now-indicator.scss';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import luxonPlugin from '@fullcalendar/luxon3';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { IJxtSchedulerCalendar } from './types';
import { CalendarApi, DateSpanApi, DatesSetArg, EventApi, FormatterInput } from '@fullcalendar/core';
import { ResourceInput } from '@fullcalendar/resource';
import { createGTMGAEvent, FAVORITE_DEBOUNCE_DELAY, fromISO, now } from '@jooxter/utils';
import './styles.scss';
import {
  AllowSelectRules,
  FullCalendarViewEnum,
  IFullCalendarConfig,
  renderEventClassNames,
  useAllowSelect,
  useFullCalendar,
} from '@jooxter/fullcalendar';
import { useSpaceFetchResourcesContext } from './hooks';
import { useBookingCallbacks, useFetchResourceTypes, useFetchUser, useStore } from '@jooxter/core';
import { debounce } from 'lodash-es';
import { DateTime } from 'luxon';
import { useShallow } from 'zustand/shallow';
import {
  JxtBookButton,
  JxtIconButtonSizeEnum,
  JxtLocateButton,
  JxtStar,
  JxtStarSizeEnum,
  JxtStarTypeEnum,
} from '@jooxter/ui';

const plugins = [
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  resourceTimeGridPlugin,
  resourceTimelinePlugin,
  scrollGridPlugin,
  luxonPlugin,
];

const viewOptions = {
  resourceTimelineDay: {
    eventMinWidth: 1,
    slotDuration: {
      minutes: 15,
    },
    slotLabelFormat: DateTime.TIME_SIMPLE,
  },
  resourceTimelineWeek: {
    slotDuration: {
      days: 1,
    },
    slotLabelFormat: 'ccc d',
    slotMinWidth: 160,
  },
  resourceTimelineMonth: {
    slotDuration: {
      days: 1,
    },
    slotMinWidth: 160,
    slotLabelFormat: 'ccc d',
  },
};

const JxtSchedulerCalendar = ({
  events,
  resources,
  timezone,
  locale,
  initialDate,
  onFullCalendarRangeChange,
  onResourceClicked,
  eventResizedOrDragged,
  onSelect,
  onBook,
  onLocateResource,
  onToggleFavorite,
}: IJxtSchedulerCalendar) => {
  const { t } = useTranslation();
  const { user } = useFetchUser();
  const calendarRef = useRef<FullCalendar>(null);
  const [calendarApi, setCalendarApi] = useState<CalendarApi | undefined>();
  const [spaceSchedulerView, setSpaceSchedulerView] = useStore(
    useShallow((state) => [state.spaceSchedulerView, state.setSpaceSchedulerView])
  );
  const { allowSelect } = useAllowSelect(calendarApi);
  const { resourceTypes } = useFetchResourceTypes();
  const { hasNextPage, isFetching, fetchNextPage } = useSpaceFetchResourcesContext();
  const { onShowBookingDetailsClick } = useBookingCallbacks();
  const fullCalendarConfig: IFullCalendarConfig = {
    user: user,
    showHours: calendarRef.current?.getApi().view.type !== 'resourceTimelineDay',
    showTitle: calendarRef.current?.getApi().view.type === 'resourceTimelineDay',
    showOptions: true,
    showPopover: true,
    view: calendarRef.current?.getApi().view.type,
    callbacks: {
      onClick: (id: number) => {
        onShowBookingDetailsClick(id);
        createGTMGAEvent('Spaces', 'Scheduler', 'Show Booking');
      },
    },
  };
  const { renderEventContent } = useFullCalendar(fullCalendarConfig);

  const headerToolbar = {
    left: 'prev,next today',
    center: 'title',
    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
  };

  const titleFormat: FormatterInput | undefined =
    calendarRef.current?.getApi().view.type === 'resourceTimelineDay'
      ? { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }
      : undefined;

  const resourceAreaColumns = useMemo(
    () => [
      {
        headerContent: t('calendar.header-resources'),
        headerClassNames: 'text-body-xs text-neutral-140 font-normal',
      },
    ],
    [t, onResourceClicked, onBook, onLocateResource]
  );

  const resourceLabelContent = useCallback(
    ({ resource }: { resource: ResourceInput }) => {
      const isLocateDisabled = !!resource.extendedProps?.disableLocate;
      const isBookingDisabled = !resource.extendedProps?.bookable;

      if (!resource.id) {
        return null;
      }

      const resourceId = parseInt(resource.id, 10);

      return (
        <div className="flex items-center justify-between">
          <div
            className="shrink truncate mr-1"
            title={resource.title}
            onClick={() => {
              if (!resource.id) {
                return;
              }
              onResourceClicked(resourceId);
            }}
          >
            {resource.title}
          </div>
          <div className="flex items-center gap-1">
            <span
              className="mx-1 text-neutral-120 flex items-center justify-center"
              title={t<string>('resource-capacity', { capacity: resource.extendedProps?.capacity })}
            >
              <i className="fas fa-user mr-1" /> {resource.extendedProps?.capacity}
            </span>
            <JxtLocateButton
              onClick={() => onLocateResource(resourceId)}
              size={JxtIconButtonSizeEnum.S}
              disabled={isLocateDisabled}
              disabledMessage={t('space-not-locatable-reason')}
              title={t('locate-space')}
            />
            <JxtBookButton
              onClick={() => onBook(resourceId)}
              size={JxtIconButtonSizeEnum.S}
              disabled={isBookingDisabled}
            />
            <JxtStar
              checked={resource.extendedProps?.favorite}
              type={JxtStarTypeEnum.Light}
              size={JxtStarSizeEnum.Small}
              disabled={false}
              onClick={debounce((e: boolean) => onToggleFavorite(e, resourceId), FAVORITE_DEBOUNCE_DELAY)}
            />
          </div>
        </div>
      );
    },
    [onResourceClicked, onBook, onLocateResource]
  );

  useEffect(() => {
    if (calendarRef.current && locale) {
      calendarRef.current.getApi().setOption('locale', locale);
    }

    if (calendarRef.current && timezone) {
      calendarRef.current.getApi().setOption('timeZone', timezone);
    }
  }, [locale, timezone]);

  useEffect(() => {
    if (calendarRef.current && initialDate) {
      calendarRef.current.getApi().gotoDate(initialDate);
    }
  }, [initialDate]);

  useEffect(() => {
    if (calendarRef.current?.getApi()) {
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [calendarRef]);

  const handleScroll = useCallback(
    (e: Event) => {
      const threshold = 100;
      const target = e.target as HTMLDivElement;

      if (!target) {
        return;
      }

      const thresholdReached = target.scrollHeight - target.clientHeight - target.scrollTop - threshold <= 0;

      if (thresholdReached && hasNextPage && fetchNextPage && !isFetching) {
        fetchNextPage();
      }
    },
    [hasNextPage, fetchNextPage, isFetching]
  );

  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 300), [handleScroll]);

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedHandleScroll.cancel();
    };
  }, []);

  useEffect(() => {
    // Attach a scroll event listener to the scrollable area
    const scrollContainer = document.querySelector('tbody .fc-scrollgrid-section-body .fc-scroller');

    scrollContainer?.addEventListener('scroll', debouncedHandleScroll);

    // Clean up the event listener when the components is unmounted
    return () => {
      scrollContainer?.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [resources, handleScroll]);

  const datesSet = useCallback(
    (args: DatesSetArg) => {
      if (onFullCalendarRangeChange && calendarRef.current) {
        onFullCalendarRangeChange({
          from: fromISO(args.startStr, timezone),
          to: fromISO(args.endStr, timezone),
        });
      }
    },
    [onFullCalendarRangeChange, timezone]
  );

  const selectAllowGuard = useCallback(
    (selectInfo: DateSpanApi): AllowSelectRules => {
      if (!selectInfo.resource?.id) {
        return {};
      }

      let isZone = false;

      if (resourceTypes) {
        const resourceType = resourceTypes.find((r) => r.id === selectInfo.resource?.extendedProps.resourceTypeId);
        if (resourceType?.allowOverlap) {
          isZone = true;
        }
      }

      if (calendarRef.current) {
        if (calendarRef.current.getApi().view.type === 'resourceTimelineDay') {
          return {
            fgEvent: !isZone,
            inverseBgEventMissing: true,
          };
        } else if (['resourceTimelineWeek', 'resourceTimelineMonth'].includes(calendarRef.current.getApi().view.type)) {
          return { inverseBgEventMissing: true };
        }
      }
      return {};
    },
    [resourceTypes]
  );

  /**
   * This guard prevents events from overlapping.
   * The events with inverse-background are the open hours, so they should be allow
   * to overlap with our booking events
   */
  const eventOverlapGuard = useCallback((stillEvent: EventApi | null, movingEvent: EventApi | null): boolean => {
    if (stillEvent?.display === 'auto' && stillEvent.extendedProps.isZone) {
      return true;
    }

    return stillEvent?.display === 'inverse-background' && movingEvent?.display === 'auto';
  }, []);

  /**
   * This guard prevents events from being resized in the past
   * The adapter sets at the event level whether it should be constraint or editable
   */
  const resizeGuard = useCallback((dropInfo: DateSpanApi, movingEvent: EventApi | null): boolean => {
    if (
      dropInfo.resource?.extendedProps?.options.length ||
      movingEvent?.extendedProps?.options.length ||
      (dropInfo.resource && !dropInfo.resource?.extendedProps?.bookable)
    ) {
      return false;
    }

    return DateTime.fromJSDate(dropInfo.start) > now() && DateTime.fromJSDate(dropInfo.end) > now();
  }, []);

  const handleViewClassNames = () => {
    if (calendarApi?.view.type && calendarApi.view.type !== spaceSchedulerView) {
      setSpaceSchedulerView(calendarApi?.view.type as FullCalendarViewEnum);
    }
    // the viewClassNames needs a className generator
    return '';
  };

  // switching between resourceTimeline views do not trigger viewDidMount
  // see https://github.com/fullcalendar/fullcalendar/issues/5543
  const selectAllowCallback = useCallback(
    (selectInfo: DateSpanApi) =>
      selectInfo.resource?.extendedProps?.bookable && allowSelect(selectInfo, selectAllowGuard(selectInfo)),
    [allowSelect, selectAllowGuard]
  );

  if (!timezone) {
    return null;
  }

  return (
    <div id="fc-scheduler" className="w-full h-full">
      <FullCalendar
        ref={calendarRef}
        plugins={plugins}
        datesSet={datesSet}
        initialView={spaceSchedulerView}
        viewClassNames={handleViewClassNames}
        height="100%"
        headerToolbar={headerToolbar}
        locale={locale}
        resourceAreaColumns={resourceAreaColumns}
        views={viewOptions}
        resourceLabelClassNames="text-body-s text-neutral-140"
        resourceLabelContent={resourceLabelContent}
        resourceOrder="order"
        slotLabelClassNames="text-body-xs text-neutral-140 font-normal capitalize"
        resourceAreaWidth="300px"
        timeZone={timezone}
        events={events}
        resources={resources}
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        eventClassNames={renderEventClassNames}
        eventContent={renderEventContent}
        select={onSelect}
        selectable={true}
        titleFormat={titleFormat}
        editable={true}
        firstDay={1}
        selectAllow={selectAllowCallback}
        eventResize={eventResizedOrDragged}
        eventDrop={eventResizedOrDragged}
        eventOverlap={eventOverlapGuard}
        eventAllow={resizeGuard}
        slotMinWidth={20}
        nowIndicator={true}
      />
    </div>
  );
};

export default JxtSchedulerCalendar;
