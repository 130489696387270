import { useState, useEffect, useRef, useCallback, JSX } from 'react';
import panzoom, { PanZoom } from 'panzoom';
import { IJxtFloorPlan, IPictoConfig, PictoTypeEnum } from './types';
import JxtZoomButton from '../JxtZoomButton';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import clsx from 'clsx';
import { JxtBookingPicto, JxtResourcePicto, JxtUserPicto } from './components';
import JxtLoader from '../JxtLoader';
import { computeRatio } from './helper';

const ZOOM_STEP = 0.25;

const JxtFloorPlan = ({ backgroundImage, width, height, pictos, onClick }: IJxtFloorPlan) => {
  const isSmallScreen = useIsSmallScreen();
  const svgRef = useRef<SVGSVGElement>(null);
  const [panzoomInstance, setPanzoomInstance] = useState<PanZoom>();
  const [isReady, setIsReady] = useState(false);
  const computedRatio = computeRatio(backgroundImage, svgRef.current);

  /**
   * We prevent using the smoothZoom on mobile devices because of performance issues
   */
  const zoomAccordingToScreenSize = useCallback(
    (scaleNumber: number) => {
      if (panzoomInstance && svgRef.current) {
        const clientX = svgRef.current.clientWidth / 2;
        const clientY = svgRef.current.clientHeight / 2;

        if (isSmallScreen) {
          panzoomInstance.zoomTo(clientX, clientY, scaleNumber);
        } else {
          panzoomInstance.smoothZoom(clientX, clientY, scaleNumber);
        }
      }
    },
    [panzoomInstance, isSmallScreen]
  );

  const onPictoClick = (id: number) => {
    onClick && onClick(id);
  };

  useEffect(() => {
    if (!backgroundImage) {
      setIsReady(false);
      return;
    }

    if (svgRef.current && backgroundImage && width && height) {
      try {
        setPanzoomInstance(
          panzoom(svgRef.current, {
            bounds: true,
            boundsPadding: 1.0,
            maxZoom: 5,
            minZoom: 1,
          })
        );
      } catch (error) {
        console.warn(error);
      }

      panzoomInstance?.setZoomSpeed(0.1);
      setIsReady(true);

      return panzoomInstance?.dispose();
    }
  }, [backgroundImage, width, height]);

  const createPicto = (picto: IPictoConfig, i: number): JSX.Element | null => {
    switch (picto.type) {
      case PictoTypeEnum.Booking:
        return (
          <JxtBookingPicto parent={svgRef} key={`${picto.x}${picto.y}${i}`} p={picto} onPictoClick={onPictoClick} />
        );
      case PictoTypeEnum.User:
        return <JxtUserPicto parent={svgRef} key={`${picto.x}${picto.y}${i}`} p={picto} onPictoClick={onPictoClick} />;
      case PictoTypeEnum.Resource:
        return (
          <JxtResourcePicto
            parent={svgRef}
            key={`${picto.x}${picto.y}${i}`}
            p={picto}
            computedRatio={computedRatio}
            onPictoClick={onPictoClick}
          />
        );

      default:
        // Invalid picto type, NOOP
        return null;
    }
  };

  return (
    <div className="relative h-full w-full">
      <div className={clsx('h-full w-full', !isReady ? 'opacity-25' : '')}>
        <div id="panzoom" className="h-full w-full overflow-hidden relative flex flex-col justify-center items-center">
          {backgroundImage && (
            <svg
              ref={svgRef}
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              preserveAspectRatio="xMidYMid meet"
              className="grow basis-60"
              width="100%"
              height="100%"
              viewBox={`0 0 ${backgroundImage.naturalWidth} ${backgroundImage.naturalHeight}`}
            >
              <image
                href={backgroundImage.src}
                width={backgroundImage.naturalWidth}
                height={backgroundImage.naturalHeight}
              />
              {pictos?.map((p, i) => createPicto(p, i))}
            </svg>
          )}
        </div>
        <div className="absolute top-4 right-4">
          <JxtZoomButton
            onZoomIn={() => zoomAccordingToScreenSize(1 + ZOOM_STEP)}
            onZoomOut={() => zoomAccordingToScreenSize(1 - ZOOM_STEP)}
          />
        </div>
      </div>
      {!isReady && (
        <div className="absolute inset-0 flex bg-white z-[999] items-center justify-center">
          <JxtLoader />
        </div>
      )}
    </div>
  );
};

export default JxtFloorPlan;
