import clsx from 'clsx';
import { JxtWorkplaceCalendarDayHeaderStateEnum } from './types';

export interface IGetJxtWorkplaceCalendarDayHeaderClass {
  state?: JxtWorkplaceCalendarDayHeaderStateEnum;
}

export const getJxtWorkplaceCalendarDayHeaderClass = ({
  state = JxtWorkplaceCalendarDayHeaderStateEnum.Default,
}: IGetJxtWorkplaceCalendarDayHeaderClass) => {
  const defaultClasses = 'h-8 w-full px-2 py-1 text-title-m font-medium text-right';

  const stateClasses = {
    Default: 'bg-white hover:bg-neutral-10 text-neutral-140',
    Active: 'bg-primary-100 hover:bg-primary-110 text-white',
    Disabled: 'bg-white text-neutral-60 cursor-not-allowed',
  };

  const classes = clsx(defaultClasses, stateClasses[state]);

  return classes;
};
