// eslint-disable-next-line import/named
import { EventContentArg } from '@fullcalendar/core';
import { BookingCompressed } from '@jooxter/api';
import { useBookingActions } from './useBookingActions';
import { useBookingOffCanvasConfiguration } from './useBookingOffCanvasConfiguration';
import { useCallback } from 'react';
import { JxtEventColorsEnum } from '@jooxter/utils';
import { useFetchResourceTypes } from '../../queries';
import { getResourceType } from '../../utils';
import { useOffCanvas } from '../useOffCanvasContext';
import { offCanvasContainerEnum } from '@jooxter/ui';

export const useBookingCallbacks = () => {
  const offCanvasContext = useOffCanvas();
  const { checkin, checkout, arrive, leave, cancel, deleteBookingModal } = useBookingActions(offCanvasContext);
  const { offCanvasConfiguration, setBookingId, goToEditView, setDirectEdit, goToBookingView, setContainer } =
    useBookingOffCanvasConfiguration();
  const { resourceTypes } = useFetchResourceTypes();

  const openBookingCanvas = () => {
    offCanvasContext?.open(offCanvasConfiguration);
  };

  const onClickCheckIn = (id: string, _: any, arriveCapability: boolean) => {
    if (arriveCapability) {
      arrive(id);
    } else {
      checkin(id);
    }
  };

  const onClickCheckOut = (id: string, _: any, leaveCapability: boolean) => {
    if (leaveCapability) {
      leave(id);
    } else {
      checkout(id);
    }
  };

  const onClickUpdate = (id: string): void => {
    setBookingId(parseInt(id, 10));
    setDirectEdit(true);
    goToEditView();
    openBookingCanvas();
  };

  const onClickDelete = useCallback(
    (event: EventContentArg) => {
      const booking: BookingCompressed = event.event.extendedProps.originalBooking;
      cancel({
        id: booking.id,
        rrule: booking.rrule,
        summary: {
          title: booking.summary,
          color: JxtEventColorsEnum.parse(booking.color),
        },
        start: {
          dateTime: booking.start.dateTime,
        },
        end: {
          dateTime: booking.end.dateTime,
        },
        resourceName: booking.resource.name,
        resourceType: getResourceType(booking.resource.resourceTypeId, resourceTypes),
      });
      offCanvasContext?.close();
      setBookingId(undefined);
    },
    [cancel, offCanvasContext, resourceTypes, setBookingId]
  );

  const onShowBookingDetailsClick = (id: number, container?: offCanvasContainerEnum): void => {
    if (container) {
      setContainer(container);
    }
    goToBookingView(id);
    openBookingCanvas();
  };

  return {
    onClickCheckIn,
    onClickCheckOut,
    onClickUpdate,
    onClickDelete,
    onShowBookingDetailsClick,
    deleteBookingModal,
  };
};
