import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import JxtButton, { ButtonSizeEnum, ButtonVariantEnum } from '../JxtButton';
import { IJxtBookingAddButton } from './types';

const JxtBookingAddButton = ({ onClickDesk, onClickRoom, onClickOther }: IJxtBookingAddButton) => {
  const [toggleButtons, setToggleButtons] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white rounded-lg">
      {!toggleButtons ? (
        <JxtButton
          variant={ButtonVariantEnum.Secondary}
          size={ButtonSizeEnum.Regular}
          onClick={() => setToggleButtons(true)}
          className="w-full"
          leftIcon="plus"
        >
          {t('booking.add')}
        </JxtButton>
      ) : (
        <div className="flex gap-2">
          <JxtButton
            variant={ButtonVariantEnum.Secondary}
            size={ButtonSizeEnum.Regular}
            className="flex-1 truncate"
            onClick={onClickDesk}
          >
            {t('booking.add.desk')}
          </JxtButton>
          <JxtButton
            variant={ButtonVariantEnum.Secondary}
            size={ButtonSizeEnum.Regular}
            className="flex-1 truncate"
            onClick={onClickRoom}
          >
            {t('booking.add.meeting-room')}
          </JxtButton>
          <JxtButton
            variant={ButtonVariantEnum.Secondary}
            size={ButtonSizeEnum.Regular}
            className="flex-1 truncate"
            onClick={onClickOther}
          >
            {t('booking.add.other')}
          </JxtButton>
          <JxtButton
            variant={ButtonVariantEnum.Secondary}
            size={ButtonSizeEnum.Regular}
            leftIcon="times"
            onClick={() => setToggleButtons(false)}
            className="w-10 flex items-center justify-center"
          />
        </div>
      )}
    </div>
  );
};

export default JxtBookingAddButton;
