import { DayOpeningHours } from '@jooxter/ui';
import { IRange } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useFetchClosingHours } from '../../queries';

export const useResourceWeekOpeningHours = (range: IRange, resourceId: number, timezone?: string) => {
  const { openingHours } = useFetchClosingHours({ from: range.from, to: range.to, byDay: true, resourceId });

  const get = useCallback(() => {
    if (!openingHours) {
      return null;
    }

    /** function's output */
    const days: DayOpeningHours[] = [];

    /**
     * Will contain the last processed day given in `OpeningHours`, thus will
     * only refer to a day that contains a opened timer range.
     */
    let lastDay: DayOpeningHours;

    // First (1) we fill `days` with the given opening range in `OpeningHours`.
    // Then (2) we iterate over the days of the `addClosing` range so as to add
    // the closed day to the result.

    // (1)
    openingHours.forEach((elt, ind) => {
      // If null/undefined, then the string will be interpreted as UTC.
      const start = DateTime.fromISO(elt.start, { zone: timezone });
      const end = DateTime.fromISO(elt.end, { zone: timezone });

      // If first item, we just push it without thinking.
      if (ind === 0) {
        const slot: DayOpeningHours = {
          slots: [
            {
              start,
              end,
            },
          ],
        };

        lastDay = slot;
        days.push(slot);
      } else if (start.startOf('day').equals(lastDay.slots[0].start.startOf('day'))) {
        // Then, we must verify that the current time range doesn't refer to a day
        // already present is the `day` object.
        // If so, we just push the current time range to the opening range for the
        // current day.
        lastDay.slots.push({
          start,
          end,
        });
      } else {
        // Else it's a day day that hasn't be stored in `day` as it won't create
        // conflicts with the already present days
        const slot = {
          slots: [
            {
              start,
              end,
            },
          ],
        };
        days.push(slot);

        lastDay = slot;
      }
    });

    let dayIndex = 0;
    let lastProcessedDay: DayOpeningHours;
    let processingDate = range.from.startOf('day');

    // starts from to `start` up to `end` day.
    while (processingDate < range.to.startOf('day')) {
      lastProcessedDay = days[dayIndex];

      if (!lastProcessedDay) {
        const tmp: DayOpeningHours = {
          day: processingDate,
          slots: [],
        };

        days.splice(dayIndex, 0, tmp);
        lastProcessedDay = tmp;
        dayIndex++;
        processingDate = processingDate.plus({ days: 1 });
      } else {
        while (
          processingDate < range.to.startOf('day') &&
          processingDate < (lastProcessedDay.day || lastProcessedDay.slots[0].start.startOf('day'))
        ) {
          const tmp: DayOpeningHours = {
            day: processingDate,
            slots: [],
          };
          days.splice(dayIndex, 0, tmp);

          if (tmp.day && tmp.day >= (lastProcessedDay.day || lastProcessedDay.slots[0].start)) {
            lastProcessedDay = tmp;
          }

          dayIndex++;
          processingDate = processingDate.plus({ days: 1 });
        }

        dayIndex++;
        processingDate = processingDate.plus({ days: 1 });
      }
    }

    days.forEach((val) => {
      if (val.slots.length !== 1) {
        return;
      }

      const start = val.slots[0].start;
      const end = val.slots[0].end;

      if (start.plus({ days: 1 }).equals(end)) {
        val.day = val.slots[0].start;
      }
    });

    return days;
  }, [openingHours]);

  return { get };
};
