import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { UserLoginDetail, UserLoginDetailTypeEnum } from '@jooxter/api';
import { useEffect, useState } from 'react';
import {
  JxtLoginBase,
  LOGIN_ROUTE,
  useAuthContext,
  useButtonStatus,
  useFetchLoginDetails,
  useStore,
} from '@jooxter/core';
import { ButtonVariantEnum, JxtInput, JxtButton, ButtonSizeEnum } from '@jooxter/ui';
import { createGTMGAEvent, createGTMUpdateVirtualPathEvent } from '@jooxter/utils';
import { useNavigate } from 'react-router';
import { useShallow } from 'zustand/shallow';

export const LoginEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();
  const [setEmailFromStore] = useStore(useShallow((state) => [state.setEmail]));
  const [disabled, setDisabled] = useState(true);
  const { children, setLoading, loading } = useButtonStatus();
  const { loginDetails } = useFetchLoginDetails(email);
  const { register, handleSubmit, getFieldState, formState, setError } = useForm({
    mode: 'onTouched',
  });
  const { loginSSO } = useAuthContext();

  useEffect(() => {
    setEmailFromStore(undefined);
    createGTMUpdateVirtualPathEvent('Login');
    // on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const continueLogin = (email: string, loginInfo: UserLoginDetail) => {
      createGTMGAEvent(
        'Login',
        'Connection',
        loginInfo.type === UserLoginDetailTypeEnum.Jooxter ? 'Jooxter authentication' : 'SSO authentication'
      );

      if (loginInfo.type === UserLoginDetailTypeEnum.Jooxter && !loginInfo.redirect) {
        navigate(LOGIN_ROUTE);
      }

      loginInfo.type === UserLoginDetailTypeEnum.Jooxter && loginInfo.redirect
        ? navigate(loginInfo.redirect, { state: { email } })
        : loginSSO().catch((err) => {
            console.error(err);
            setLoading(false);
            setError('email', { message: t<string>('unknown-error') });
          });
    };

    if (email && loginDetails) {
      continueLogin(email, loginDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  useEffect(() => {
    setDisabled(!!formState.errors.email || !formState.dirtyFields['email']);
  }, [!!formState.errors.email, formState.dirtyFields]);

  const onSubmit = (data: FieldValues) => {
    setLoading(true);
    setEmail(data.email);
    setEmailFromStore(data.email);
  };

  const form = (
    <form className="flex flex-col gap-6">
      <JxtInput
        name="email"
        getFieldState={getFieldState}
        placeholder={t<string>('name-company-com')}
        formState={formState}
        register={register}
        validationSchema={{
          required: {
            value: true,
            message: t('login-email-required'),
          },
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: t('login-email-required'),
          },
        }}
        type="email"
      />
      <JxtButton
        variant={loading ? ButtonVariantEnum.OutlinePrimary : ButtonVariantEnum.Primary}
        type="submit"
        size={ButtonSizeEnum.Large}
        onClick={handleSubmit(onSubmit)}
        disabled={loading ? true : disabled}
      >
        {loading ? children : t('login-btn-continue')}
      </JxtButton>
    </form>
  );

  return (
    <JxtLoginBase title={t('login-title')} instructions={t('login-instructions')}>
      {form}
    </JxtLoginBase>
  );
};
