export const HOME_ROUTE = '/home';
export const LOGIN_ROUTE = '/';
export const SEARCH_SPACE_ROUTE = '/resources/search';
// for retro compatibility
export const SCHEDULER_ROUTE = '/scheduler';
export const BOOKINGS_MINE_ROUTE = '/bookings/mine';
export const BOOKINGS_VIEW_ROUTE = '/bookings/:bookingId';
export const USER_PLANNING_ROUTE = '/users/planning';
export const ONBOARDING_ROUTE_START = '/onboarding/start';
export const ONBOARDING_ROUTE_WORK_HOURS = '/onboarding/work-hours';
export const ONBOARDING_ROUTE_DESK = '/onboarding/desk';
export const ONBOARDING_ROUTE_COLLEAGUES = '/onboarding/favorites';
export const ONBOARDING_ROUTE_PROFILE_PICTURE = '/onboarding/profile-picture';
export const ONBOARDING_ROUTE_NOTIFICATIONS = '/onboarding/notifications';
export const ONBOARDING_ROUTE_FINALIZE = '/onboarding/finalize';
export const MANAGE_SPACES_ROUTE = '/resources/manage';
export const TOGETHER_ROUTE = '/together';
export const PROFILE_ROUTE = '/users/me';
export const VERSION_ROUTE = '/version';
export const RTSM_ROUTE_USER_HASH = 'locations/:locationId/floors/:floorId/floor_plan/:userHash';
export const RTSM_ROUTE_USER_V4 = 'v4/locations/:locationId/floors/:floorId/floor_plan';
export const RTSM_ROUTE_USER_CODE_V4 = 'v4/locations/:locationId/floors/:floorId/floor_plan/:encoded_params';
export const RTSM_ROUTE = 'locations/:locationId/floors/:floorId/floor_plan';
export const NOT_FOUND_ROUTE = 'not-found';
export const NOT_AUTHORIZED_ROUTE = 'not-authorized';
export const BAD_REQUEST_ROUTE = 'bad-request';
export const ERROR_ROUTE = 'error';
export const RESET_PASSWORD_WITH_TOKEN_ROUTE = '/login/:userId/token/:token';
export const RESET_PASSWORD_WITH_CODE_ROUTE = '/login/:userId/code/:code';
export const FORGOT_PASSWORD_ROUTE = '/login/forgotpassword';
export const SAML_ROUTE = '/saml';
