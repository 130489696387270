import { JxtWorkplaceStatusButtonTypeEnum } from '@jooxter/utils';
import clsx from 'clsx';
import { JxtWorkplaceStatusButtonStateEnum } from './types';
import { JxtWorkplaceStatusButtonInfo } from './constants';

export interface IGetJxtWorkplaceStatusButtonClass {
  type: JxtWorkplaceStatusButtonTypeEnum;
  state?: JxtWorkplaceStatusButtonStateEnum;
  disabled?: boolean;
}

export const getJxtWorkplaceStatusButtonClass = ({
  type,
  state = JxtWorkplaceStatusButtonStateEnum.Default,
  disabled,
}: IGetJxtWorkplaceStatusButtonClass) => {
  const defaultClasses =
    'h-14 rounded-lg flex flex-1 flex-col gap-1 p-2 items-center justify-center whitespace-nowrap truncate';

  const stateClasses = {
    Default: disabled ? ' text-neutral-40' : 'text-neutral-120 hover:bg-neutral-10',
    Active: disabled ? JxtWorkplaceStatusButtonInfo[type].disabledColors : JxtWorkplaceStatusButtonInfo[type].colors,
  };

  const disabledClasses = disabled ? 'cursor-not-allowed' : '';

  const classes = clsx(defaultClasses, stateClasses[state], disabledClasses);

  return classes;
};
