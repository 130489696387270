import { useCallback, useEffect, useState } from 'react';
import { IJxtToggleSwitch } from './types';
import Switch from 'react-switch';
import { getThemeColorHex } from '@jooxter/tailwind-config';

const JxtToggleSwitch = ({ defaultChecked = false, disabled, onChange }: IJxtToggleSwitch) => {
  const [checked, setChecked] = useState(defaultChecked);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        if (disabled) {
          node.querySelector('.react-switch-bg')?.classList.add('!cursor-not-allowed');
          node.querySelector('.react-switch-handle')?.classList.add('!cursor-not-allowed');
        } else {
          node.querySelector('.react-switch-bg')?.classList.remove('!cursor-not-allowed');
          node.querySelector('.react-switch-handle')?.classList.remove('!cursor-not-allowed');
        }
      }
    },
    [disabled]
  );

  const handleChange = (isChecked: boolean) => {
    setChecked(isChecked);
    if (onChange) {
      onChange(isChecked);
    }
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="rounded-2xl h-7 w-12"
      ref={ref}
    >
      <Switch
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        handleDiameter={24}
        height={28}
        width={48}
        borderRadius={16}
        onColor={
          disabled
            ? getThemeColorHex('neutral40')
            : isHovered
              ? getThemeColorHex('primary110')
              : getThemeColorHex('primary100')
        }
        offColor={
          disabled
            ? getThemeColorHex('neutral40')
            : isHovered
              ? getThemeColorHex('neutral110')
              : getThemeColorHex('neutral100')
        }
        checkedIcon={false}
        uncheckedIcon={false}
      />
    </div>
  );
};

export default JxtToggleSwitch;
