import {
  Booking,
  BookingAttendeeParticipationStatusEnum,
  BookingCompressed,
  BookingRequest,
  WorkplaceRuleRemainingDays,
} from '../model';
import { DateTime } from 'luxon';
import { JxtBookingStatusEnum } from '../types';
import { onNextPage } from '../utils';
import { toISO } from '@jooxter/utils';
import { axiosManager } from '../config';

export interface ISearchBookingsParameters {
  from?: DateTime;
  to?: DateTime;
  participantId?: number;
  organizerId?: number;
  resourceTypeId?: number[];
  resourceId?: number[];
  status?: JxtBookingStatusEnum[];
  size?: number;
  page?: string;
}

export interface IRemainingBookingsCheckOptions {
  locationId: number;
  from: DateTime;
  to: DateTime;
  ownerId: number;
}

export const BookingService = {
  path: 'bookings',

  search(options: Partial<ISearchBookingsParameters>): Promise<{
    data: BookingCompressed[];
    nextPage: string;
  }> {
    const params = this.createSearchBookingsParams(options);
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(onNextPage);
    }

    const url = `${this.path}`;

    return axiosManager.getInstance().get(url, { params }).then(onNextPage);
  },

  getById(id: number): Promise<Booking> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  participate(id: number, statut: BookingAttendeeParticipationStatusEnum) {
    const url = `${this.path}/${id}/participation`;

    return axiosManager
      .getInstance()
      .put(url, { participationStatus: statut })
      .then((res) => res.data);
  },

  createSearchBookingsParams(options: Partial<ISearchBookingsParameters>): URLSearchParams {
    const params = new URLSearchParams();

    if (options.from && options.to) {
      params.append('from', options.from.toISO() ?? 'Invalid Date');
      params.append('to', options.to.toISO() ?? 'Invalid Date');
    }

    if (options.participantId) {
      params.append('participantId', options.participantId.toString());
    }

    if (options.organizerId) {
      params.append('organizerId', options.organizerId.toString());
    }

    if (options.resourceTypeId) {
      params.append('resourceTypeId', options.resourceTypeId.toString());
    }

    if (options.resourceId) {
      params.append('resourceId', options.resourceId.toString());
    }

    if (options.status && options.status?.length > 0) {
      params.append('status', options.status.join(','));
    }

    if (options.size) {
      params.append('size', options.size.toString());
    }

    if (options.page) {
      params.append('page', options.page);
    }

    return params;
  },

  createBookingsCheckParams(options: IRemainingBookingsCheckOptions): URLSearchParams {
    const params = new URLSearchParams();

    if (options.from && options.to) {
      params.append('from', toISO(options.from));
      params.append('to', toISO(options.to));
    }

    if (options.ownerId) {
      params.append('ownerId', options.ownerId.toString());
    }

    if (options.locationId) {
      params.append('locationId', options.locationId.toString());
    }

    return params;
  },

  getCheckRemainingBookings(options: IRemainingBookingsCheckOptions): Promise<WorkplaceRuleRemainingDays[]> {
    const url = `${this.path}/check`;

    return axiosManager
      .getInstance()
      .get(url, { params: this.createBookingsCheckParams(options) })
      .then((res) => res.data);
  },

  create(booking: BookingRequest): Promise<Booking> {
    const url = `${this.path}`;

    return axiosManager
      .getInstance()
      .post(url, booking)
      .then((res) => res.data);
  },

  update(id: number, booking: BookingRequest): Promise<Booking> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .put(url, booking)
      .then((res) => res.data);
  },

  updateWithRecurrence(id: number, booking: BookingRequest): Promise<Booking> {
    const url = `${this.path}/recurrences/${id}`;

    return axiosManager
      .getInstance()
      .put(url, booking)
      .then((res) => res.data);
  },

  delete(ids: number[]): Promise<never> {
    const url = `${this.path}/${ids.join(',')}`;

    return axiosManager.getInstance().delete(url);
  },

  deleteWithRecurrence(id: number): Promise<never> {
    const url = `${this.path}/recurrences/${id}`;

    return axiosManager.getInstance().delete(url);
  },

  checkin(id: number): Promise<Booking> {
    const url = `${this.path}/${id}/checkin`;

    return axiosManager
      .getInstance()
      .put(url)
      .then((res) => res.data);
  },

  checkout(id: number): Promise<Booking> {
    const url = `${this.path}/${id}/checkout`;

    return axiosManager
      .getInstance()
      .put(url)
      .then((res) => res.data);
  },

  approve(id: number): Promise<Booking> {
    const url = `${this.path}/${id}/approve`;

    return axiosManager
      .getInstance()
      .put(url, { approve: true })
      .then((res) => res.data);
  },

  refuse(id: number): Promise<Booking> {
    const url = `${this.path}/${id}/approve`;

    return axiosManager
      .getInstance()
      .put(url, { approve: false })
      .then((res) => res.data);
  },

  arrive(id: number): Promise<Booking> {
    const url = `${this.path}/${id}/arrive`;

    return axiosManager
      .getInstance()
      .put(url)
      .then((res) => res.data);
  },

  leave(id: number): Promise<Booking> {
    const url = `${this.path}/${id}/leave`;

    return axiosManager
      .getInstance()
      .put(url)
      .then((res) => res.data);
  },

  put(id: number, booking: BookingRequest): Promise<Booking> {
    const url = `${this.path}/${id}`;

    return axiosManager.getInstance().put(url, booking);
  },
};
