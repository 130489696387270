import { Outlet } from 'react-router';
import { IBackgroundLayout } from './types';
import clsx from 'clsx';

export const BackgroundLayout = ({ className }: IBackgroundLayout) => {
  return (
    <div className={clsx(className, 'bg-background grow flex')}>
      <Outlet />
    </div>
  );
};
