import { JxtResourceStateEnum } from '@jooxter/utils';
import { TResourceItem } from '../JxtResourceItem/types';

const stateOrder = [
  JxtResourceStateEnum.FREE,
  JxtResourceStateEnum.BUSY,
  JxtResourceStateEnum.CLOSED,
  JxtResourceStateEnum.UNKNOWN,
];

export const resourceStateSorter = (item1: TResourceItem, item2: TResourceItem): number => {
  if (!(item1.status?.state && item2.status?.state)) {
    return 0;
  }

  const index1 = stateOrder.indexOf(item1.status.state);
  const index2 = stateOrder.indexOf(item2.status.state);

  // If states are different, sort by state
  if (index1 !== index2) {
    return index1 - index2;
  }

  // If states are the same, sort by name
  return item1.name.localeCompare(item2.name);
};
