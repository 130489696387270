import { Booking, BookingAttendee, BookingAttendeeParticipationStatusEnum, ExternalAttendee } from '@jooxter/api';
import { IJxtBookingDetails } from '@jooxter/ui/src/components/JxtBookingDetails/types';
import {
  JxtEventColorsEnum,
  JxtBookingParticipationStatusEnum,
  JxtBookingParticipationStatusIconEnum,
  JxtResourcesTypeEnum,
  fromISO,
  now,
  useGenerateRruleLabel,
} from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useBookingActions, useTimezoneNameFormatter } from '../../hooks';
import { useBookingPresenter } from '../../presenters/booking';
import { useFetchResource, useFetchUser } from '../../queries';
import { useGenerateBookingAlert } from './useGenerateBookingAlert';
import { useGenerateBookingButtons } from './useGenerateBookingButtons';
import { useGenerateBookingPlan } from './useGenerateBookingPlan';
import { ButtonVariantEnum, JxtBookingParticipate, JxtButton } from '@jooxter/ui';
import { useTranslation } from 'react-i18next';

export const useBookingToIJxtBookingDetailsAdapter = (
  booking?: Booking | null
): { bookingDetails: IJxtBookingDetails | undefined } => {
  const { t } = useTranslation();
  const [bookingDetails, setBookingDetails] = useState<IJxtBookingDetails>();
  const { resource } = useFetchResource(booking?.resource.id);
  const { user } = useFetchUser();
  const { alerts } = useGenerateBookingAlert(booking);
  const { presenter } = useBookingPresenter(booking);
  const { approve, refuse, updateParticipationStatus } = useBookingActions();
  const { buttons } = useGenerateBookingButtons(booking);
  const { plan } = useGenerateBookingPlan(booking);
  const { format } = useTimezoneNameFormatter();
  const { rruleLabel } = useGenerateRruleLabel(
    booking?.start.dateTime
      ? fromISO(booking?.start.dateTime, resource?.location.timezone)
      : now(resource?.location.timezone),
    booking?.rrule
  );
  const bookingEnd = useMemo(() => {
    if (!(booking && resource)) {
      return null;
    }

    if (booking.checkout) {
      const startDate = DateTime.fromISO(booking.start.dateTime, { zone: resource.location.timezone });
      const checkOutDate = DateTime.fromISO(booking.checkout.at, { zone: resource.location.timezone });

      if (checkOutDate >= startDate) {
        return checkOutDate;
      }
    }

    return DateTime.fromISO(booking.end.dateTime, { zone: resource.location.timezone });
  }, [booking, resource]);

  const getParticipationStatus = (
    attendee: BookingAttendee | ExternalAttendee
  ): JxtBookingParticipationStatusIconEnum | undefined => {
    if (attendee.checkout) {
      return JxtBookingParticipationStatusIconEnum.Left;
    }

    if (
      !attendee.participationStatus ||
      attendee.participationStatus === BookingAttendeeParticipationStatusEnum.NeedsAction ||
      attendee.participationStatus === BookingAttendeeParticipationStatusEnum.Tentative
    ) {
      return JxtBookingParticipationStatusIconEnum.Unknown;
    }

    if (attendee.participationStatus === BookingAttendeeParticipationStatusEnum.Accepted) {
      return JxtBookingParticipationStatusIconEnum.Accepted;
    }

    if (attendee.participationStatus === BookingAttendeeParticipationStatusEnum.AcceptedRemotely) {
      return JxtBookingParticipationStatusIconEnum.AcceptedRemotely;
    }

    if (attendee.participationStatus === BookingAttendeeParticipationStatusEnum.Declined) {
      return JxtBookingParticipationStatusIconEnum.Declined;
    }

    return JxtBookingParticipationStatusIconEnum.Unknown;
  };

  useEffect(() => {
    if (booking && resource && bookingEnd) {
      setBookingDetails({
        ...booking,
        summary: {
          title: booking.summary,
          color: JxtEventColorsEnum.parse(booking.color),
        },
        date: {
          range: {
            from: DateTime.fromISO(booking?.start.dateTime, { zone: booking?.resource.timezone }),
            to: bookingEnd,
          },
          timezoneWarning:
            presenter.shouldShowTimezoneWarning() && booking.resource.timezone
              ? format(booking.resource.timezone)
              : undefined,
        },
        resource: {
          ...resource,
          locationName: resource.location.name,
          typeName: resource.resourceType.name,
          type: resource.resourceType.metaType
            ? JxtResourcesTypeEnum[resource.resourceType.metaType]
            : JxtResourcesTypeEnum.OTHER,
          floor: resource.floor?.number,
          picture: resource.pictures?.length ? resource.pictures[0] : undefined,
        },
        options: { options: booking.options },
        description: {
          text: booking.description,
        },
        attendees: {
          physicalAttendees: booking.physicalAttendees,
          internalAttendees: booking.attendees.internal.map((internal) => ({
            ...internal,
            participationStatus: getParticipationStatus(internal),
          })),
          externalAttendees: booking.attendees.external.map((external) => ({
            ...external,
            participationStatus: getParticipationStatus(external),
          })),
          userEmail: user?.email,
        },
        organizer: {
          name: presenter.ownerName,
        },
        recurrence: rruleLabel
          ? {
              recurrence: rruleLabel,
            }
          : undefined,
        alerts,
        buttons,
        plan,
        footer:
          presenter.showParticipate() || presenter.showApprove() ? (
            <div className="flex flex-col gap-4">
              {presenter.showParticipate() && (
                <JxtBookingParticipate
                  status={presenter.participationStatus as unknown as JxtBookingParticipationStatusEnum}
                  onChange={(status) => updateParticipationStatus(booking.id, status)}
                />
              )}
              {presenter.showApprove() && (
                <div className="flex gap-4">
                  <JxtButton onClick={() => approve(booking.id)} variant={ButtonVariantEnum.Primary} className="w-full">
                    {t('approve-button')}
                  </JxtButton>
                  <JxtButton onClick={() => refuse(booking.id)} variant={ButtonVariantEnum.Danger} className="w-full">
                    {t('refuse-button')}
                  </JxtButton>
                </div>
              )}
            </div>
          ) : undefined,
      });
    } else {
      setBookingDetails(undefined);
    }
  }, [booking, resource, bookingEnd]);

  return { bookingDetails };
};
