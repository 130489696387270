import { Crop } from 'react-image-crop';
import { canvasPreview } from './canvaspreview';

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(image: HTMLImageElement, pixelCrop: Crop): Promise<string | null> {
  const canvas = document.createElement('canvas');
  canvasPreview(image, canvas, pixelCrop);

  // As Base64 string
  return Promise.resolve(canvas.toDataURL('image/png'));
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
