import { TThemeColor } from './types';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const getThemeHex = (): TThemeColor => {
  return {
    primary10: fullConfig.theme.colors.primary[10],
    primary20: fullConfig.theme.colors.primary[20],
    primary100: fullConfig.theme.colors.primary[100],
    primary110: fullConfig.theme.colors.primary[110],
    neutral10: fullConfig.theme.colors.neutral[10],
    neutral20: fullConfig.theme.colors.neutral[20],
    neutral40: fullConfig.theme.colors.neutral[40],
    neutral60: fullConfig.theme.colors.neutral[60],
    neutral100: fullConfig.theme.colors.neutral[100],
    neutral110: fullConfig.theme.colors.neutral[110],
    neutral120: fullConfig.theme.colors.neutral[120],
    neutral140: fullConfig.theme.colors.neutral[140],
    red100: fullConfig.theme.colors.red[100],
    orange40: fullConfig.theme.colors.orange[40],
    orange100: fullConfig.theme.colors.orange[100],
    white: fullConfig.theme.colors.white,
  };
};

export const getThemeColorHex = (property: keyof TThemeColor) => {
  return getThemeHex()[property];
};
