import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { IJxtAccordion } from './types';

const JxtAccordion = ({
  icon,
  title,
  openByDefault = false,
  children,
  classNames,
  iconClassNames = 'text-neutral-60',
}: IJxtAccordion) => {
  return (
    <div className={clsx('w-full', classNames)}>
      <Disclosure as="div" defaultOpen={openByDefault}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center text-left">
              {icon && <i className={clsx('fas fa-fw mr-2', `fa-${icon}`, iconClassNames)} />}
              <span className="flex-1 text-neutral-120 text-title-m font-medium">{title}</span>
              <i
                className={clsx(
                  'fas fa-fw text-neutral-140',
                  `fa-chevron-down`,
                  `${open ? 'rotate-180 transform' : ''}`
                )}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6">{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default JxtAccordion;
