export enum JxtShareWorkplaceRangeSizeEnum {
  Regular = 'Regular',
  Small = 'Small',
}

export interface IJxtShareWorkplaceRange {
  text: string;
  size?: JxtShareWorkplaceRangeSizeEnum;
  className?: string;
  onClick?: () => void;
}
