import { IJxtLocateUserInfos } from './types';

const JxtLocateUserInfos = ({
  resourceName,
  bookingStartTime,
  bookingEndTime,
  locationName,
  floorName,
}: IJxtLocateUserInfos) => {
  return (
    <div className="flex flex-col justify-center gap-1 items-center text-neutral-100 text-body-s">
      <div className="text-center">
        {resourceName} ({bookingStartTime} - {bookingEndTime})
      </div>
      <div className="font-medium">
        {locationName}
        {!!floorName && <span> - {floorName}</span>}
      </div>
    </div>
  );
};

export default JxtLocateUserInfos;
