import { useStore, LOGIN_ROUTE, useAuthContext } from '@jooxter/core';
import { useNavigate, useParams } from 'react-router';
import { JSX, useCallback, useEffect } from 'react';
import { RealTimeStatusMap } from '../pages/RealTimeStatusMap';
import { useShallow } from 'zustand/shallow';
import { AxiosInstanceEnum } from '@jooxter/api';

export const RtsmGuard = (): JSX.Element | null => {
  const { userHash } = useParams();
  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const [token, setToken, setAxiosInstance, blockAxiosInstance] = useStore(
    useShallow((state) => [state.token, state.setToken, state.setAxiosInstance, state.blockAxiosInstance])
  );
  const redirectToLogin = useCallback(() => {
    logout();
    navigate(LOGIN_ROUTE);
  }, [logout, navigate]);

  useEffect(() => {
    const cleanup = () => {
      blockAxiosInstance(false);
    };

    window.addEventListener('beforeunload', cleanup);

    if (userHash && !token) {
      blockAxiosInstance(true);
      setAxiosInstance(AxiosInstanceEnum.axiosInstanceV3);
      setToken(atob(userHash));
    }

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!(userHash || token)) {
    redirectToLogin();

    return null;
  }

  return <RealTimeStatusMap />;
};
