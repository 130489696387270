import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { Frequency, RRule, rrulestr, Weekday } from 'rrule';
import { IGenerateRruleOptions } from './useGenerateRrule';
import { getRemainingDaysInWeek } from '../date';
import { BookingRecurrence, OccurrenceMode } from '../types';

export const useBookingRecurrenceToRruleOptionsAdapter = (date: DateTime) => {
  const weekdayIndex = date.weekday - 1;
  const daysInWeek = 7;
  const monthdayIndex = Math.ceil(date.day / daysInWeek);
  const nextDaysInWeek = getRemainingDaysInWeek(date);

  const adapt = useCallback(
    (bookingRecurrence: BookingRecurrence): IGenerateRruleOptions => {
      const result: Partial<IGenerateRruleOptions> = {
        recurrenceActive: false,
        weekdayIndex,
      };

      if (isNaN(weekdayIndex) || isNaN(monthdayIndex) || isNaN(nextDaysInWeek)) {
        return result as IGenerateRruleOptions;
      }

      switch (bookingRecurrence) {
        case BookingRecurrence.Once:
          // noop, recurrenceActive will be false
          break;
        case BookingRecurrence.Weekly:
          result.occurrenceMode = OccurrenceMode.Max;
          result.freq = Frequency.WEEKLY;
          result.interval = 1;
          result.weekDayControlMode = 'one';
          result.recurrenceActive = true;
          result.selectedDays = [new Weekday(weekdayIndex)];
          break;
        case BookingRecurrence.OnceInTwoWeeks:
          result.occurrenceMode = OccurrenceMode.Max;
          result.freq = Frequency.WEEKLY;
          result.interval = 2;
          result.weekDayControlMode = 'one';
          result.recurrenceActive = true;
          result.selectedDays = [new Weekday(weekdayIndex)];
          break;
        case BookingRecurrence.BusinessDays:
          if (nextDaysInWeek <= 0) {
            break;
          }
          result.count = nextDaysInWeek;
          result.occurrenceMode = OccurrenceMode.Custom;
          result.weekDayControlMode = 'business';
          result.freq = Frequency.WEEKLY;
          result.interval = 1;
          result.recurrenceActive = true;
          result.selectedDays = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR].slice(-nextDaysInWeek);
          break;
        case BookingRecurrence.Monthly:
          result.occurrenceMode = OccurrenceMode.Max;
          result.freq = Frequency.MONTHLY;
          result.interval = 1;
          result.bysetpos = monthdayIndex;
          result.recurrenceActive = true;
          result.selectedDays = [new Weekday(weekdayIndex)];
          break;
        default:
          result.recurrenceActive = true;
          break;
      }

      return result as IGenerateRruleOptions;
    },
    [weekdayIndex, monthdayIndex, nextDaysInWeek]
  );

  const revert = useCallback(
    (rruleString?: string): BookingRecurrence => {
      if (!rruleString || isNaN(weekdayIndex) || isNaN(monthdayIndex) || isNaN(nextDaysInWeek)) {
        return BookingRecurrence.Once;
      }

      const rrule = rrulestr(rruleString);
      const {
        options: { freq, byweekday, interval, bysetpos, count },
      } = rrule;

      if (freq === RRule.DAILY && count === 1) {
        return BookingRecurrence.Once;
      }

      if (freq === RRule.WEEKLY && nextDaysInWeek > 0 && count === nextDaysInWeek && byweekday?.length === count) {
        return BookingRecurrence.BusinessDays;
      }

      if (byweekday?.length === 1 && byweekday[0] === weekdayIndex && !count) {
        if (interval === 1 && freq === RRule.WEEKLY) {
          return BookingRecurrence.Weekly;
        }
        if (interval === 2 && freq === RRule.WEEKLY) {
          return BookingRecurrence.OnceInTwoWeeks;
        }
        if (interval === 1 && freq === RRule.MONTHLY && bysetpos[0] === monthdayIndex) {
          return BookingRecurrence.Monthly;
        }
      }

      return BookingRecurrence.Customize;
    },
    [weekdayIndex, monthdayIndex, nextDaysInWeek]
  );

  return { adapt, revert };
};
