import { IJxtWorkplaceStrategyLimitContainer } from './types';
import { useTranslation } from 'react-i18next';
import { JxtWorkplaceStrategyLimit } from '@jooxter/ui';
import { useFetchUserPresenceStrategy } from '../../queries';
import { useEffect, useState } from 'react';
import { JxtWorkplaceTypeWithoutUnknownEnum } from '@jooxter/utils';

const JxtWorkplaceStrategyLimitContainer = ({
  organizerId,
  workplaceType,
  locationId,
  classNames,
  remainingBookings,
  isBooking,
}: IJxtWorkplaceStrategyLimitContainer) => {
  const { t } = useTranslation();
  const { presenceStrategy } = useFetchUserPresenceStrategy(organizerId);
  const [hasRemainingBookings, setHasRemainingBookings] = useState<boolean | null>(null);
  const [rules, setRules] = useState<string[]>([]);

  useEffect(() => {
    if (remainingBookings) {
      const canBooking = remainingBookings.every(
        (item: { rulePeriod: string; remainingDays: number }) => item.remainingDays > 0
      );
      setHasRemainingBookings(canBooking);
    }
  }, [remainingBookings]);

  useEffect(() => {
    const r: string[] = [];
    if (presenceStrategy) {
      presenceStrategy.forEach((rule) => {
        if ((rule.workplaceType as unknown as JxtWorkplaceTypeWithoutUnknownEnum) !== workplaceType) {
          return;
        }

        if (
          workplaceType === JxtWorkplaceTypeWithoutUnknownEnum.Office &&
          rule.locationId !== locationId &&
          rule.locationId !== null
        ) {
          return;
        }

        const remainingRule = remainingBookings?.find((item) => item.workplaceRuleId === rule.id);

        if (remainingRule) {
          r.push(
            t('workplace.strategy.limit.rule.remaining', {
              remaining: remainingRule.remainingDays,
              max: rule.max,
              timeGranularity: rule.rulePeriod,
            })
          );
        } else {
          r.push(t('workplace.strategy.limit.rule', { max: rule.max, timeGranularity: rule.rulePeriod }));
        }
      });
    }

    if (JSON.stringify(r) !== JSON.stringify(rules)) {
      setRules(r);
    }
  }, [workplaceType, presenceStrategy, remainingBookings, locationId]);

  if (workplaceType === JxtWorkplaceTypeWithoutUnknownEnum.Off || !presenceStrategy || rules.length === 0) {
    return null;
  }

  return (
    <div className={classNames}>
      <JxtWorkplaceStrategyLimit
        workplaceType={workplaceType}
        rules={rules}
        hasRemainingBookings={hasRemainingBookings}
        isBooking={isBooking}
      />
    </div>
  );
};

export default JxtWorkplaceStrategyLimitContainer;
