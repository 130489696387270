// eslint-disable-next-line import/named
import { OptionProps, SingleValue, components } from 'react-select';
import { useEffect, useState } from 'react';
import { LocationCompressed, LocationDtoTypeEnum, LocationService } from '@jooxter/api';
import { JxtAsyncSelect, SelectOption } from '@jooxter/ui';
import { IJxtBuildingSelect } from './types';
import { GetLocationOptionsToParamsAdapter } from '../../adapters';
import { TextHighlight } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';

const Option = (props: OptionProps<{ value: number; label: string }>) => {
  const userInput = props?.selectProps?.inputValue || '';
  const label = props?.data?.label || '';
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-2">
        {userInput?.length ? (
          label?.split(' ').length ? (
            <span>
              <TextHighlight text={label} searchTerm={userInput} />
            </span>
          ) : (
            label
          )
        ) : (
          label
        )}
      </div>
    </components.Option>
  );
};

export const JxtBuildingSelect = ({
  onBuildingSelect,
  selectedOption,
  defaultValue,
  label,
  helperText,
  disabled,
}: IJxtBuildingSelect) => {
  const { t } = useTranslation();
  const [buildings, setBuildings] = useState<Array<LocationCompressed>>([]);
  const [initOption, setInitOption] = useState<{ value: number; label: string }>();

  const handleBuildingSelection = (option: SingleValue<SelectOption<number>>) => {
    if (option) {
      const buildingId = option.value;
      const selectedBuilding = buildings?.find((building) => building.id === buildingId);
      if (selectedBuilding && onBuildingSelect) {
        onBuildingSelect(selectedBuilding);
      }
    }
  };

  useEffect(() => {
    if (!defaultValue) {
      const defaultBuilding = buildings.find((building) => building.id === selectedOption);
      const option = defaultBuilding?.id ? { value: defaultBuilding.id, label: defaultBuilding.name } : undefined;
      setInitOption(option);
    }
  }, [buildings, selectedOption]);

  async function loadOptions(search: string, loadedOptions: unknown, additional: { page?: string } | undefined) {
    const params = GetLocationOptionsToParamsAdapter.adapt({ type: LocationDtoTypeEnum.Building, name: search });

    if (additional?.page) {
      params.append('page', additional.page);
    }

    const response = await LocationService.getLocationsWithPagination(params).then(async (res) => {
      setBuildings([...buildings, ...res.data]);

      return {
        data: res.data,
        nextPage: res.nextPage,
      };
    });

    return {
      options: response.data.map((b) => ({
        value: b.id!,
        label: b.name,
      })),
      hasMore: !!response.nextPage,
      additional: {
        page: response.nextPage,
      },
    };
  }

  return (
    <JxtAsyncSelect
      key={defaultValue?.value}
      placeholder={t<string>('select-building-placeholder')}
      defaultValue={defaultValue}
      value={initOption}
      label={label}
      helperText={helperText}
      loadOptions={loadOptions}
      handleSelection={handleBuildingSelection}
      components={{ Option }}
      isDisabled={disabled}
      noOptionsMessage={t<string>('no-matching-buildings')}
    />
  );
};
