import clsx from 'clsx';

export const getTabClasses = (disabled: boolean, active: boolean): string => {
  const baseClasses = 'p-2 rounded-xl grow shrink basis-full text-body-s hover:bg-neutral-10 outline-0';
  const variantClasses = disabled
    ? 'cursor-not-allowed text-neutral-40'
    : active
      ? 'cursor-pointer bg-primary-10 hover:bg-primary-10 text-primary-100 focus-visible:bg-primary-10 focus-visible:text-primary-100'
      : 'cursor-pointer text-neutral-100';

  return clsx(baseClasses, variantClasses);
};
