import { FloorCompressed } from '@jooxter/api';
import {
  GTMCategoryContext,
  GTMCategoryContextEnum,
  JxtLocationFloorFilter,
  NOT_FOUND_ROUTE,
  SpaceLocateResources,
  useBookingCallbacks,
  useBookingFormContext,
  useBookingOffCanvasConfiguration,
  useFetchFloor,
  useFetchLocation,
  useFetchUser,
  useJxtRouter,
  useOffCanvas,
  useRequiredParams,
} from '@jooxter/core';
import { JxtNoFloorPlan, offCanvasContainerEnum } from '@jooxter/ui';
import { createGTMGAEvent, createGTMUpdateVirtualPathEvent } from '@jooxter/utils';
import { JSX, useEffect, useMemo, useRef, useState } from 'react';

export const RealTimeStatusMap = (): JSX.Element => {
  // locationId is used only to check if the association location/floor is valid
  const { locationId: locationIdString, floorId: floorIdString } = useRequiredParams<Record<string, string>>();
  const locationId = parseInt(locationIdString, 10);
  const floorId = parseInt(floorIdString, 10);
  const { location: locationFromApi, isError: isErrorLocation } = useFetchLocation(locationId, { retry: 1 });
  const { isError: isErrorFloor } = useFetchFloor(floorId, { retry: 1 });
  const { user } = useFetchUser();
  const navigate = useJxtRouter();
  const [location, setLocation] = useState({
    locationIds: [locationId],
    floorIds: [floorId],
  });
  const hasNoFloor: boolean = !location?.floorIds || location.floorIds.length === 0;
  const { offCanvasConfiguration, setContainer, goToResourceCalendarView } = useBookingOffCanvasConfiguration();
  const offCanvasContext = useOffCanvas();
  const bookingFormContext = useBookingFormContext();
  const { onShowBookingDetailsClick } = useBookingCallbacks();
  const resultViewRef = useRef<HTMLDivElement>(null);
  const resultViewSize = useMemo(() => {
    if (resultViewRef.current) {
      return {
        width: resultViewRef.current.getBoundingClientRect().width,
        height: resultViewRef.current.getBoundingClientRect().height,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultViewRef.current]);

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('RTSM');
  }, []);

  useEffect(() => {
    if (locationFromApi?.id && floorId) {
      const hasFloor = locationFromApi.floors?.find((f: FloorCompressed) => f.id === floorId);

      if (!hasFloor) {
        navigate(NOT_FOUND_ROUTE);
      }
    }
  }, [locationFromApi, floorId, navigate]);

  useEffect(() => {
    if (isErrorLocation || isErrorFloor) {
      navigate(NOT_FOUND_ROUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorLocation, isErrorFloor]);

  useEffect(() => {
    if (bookingFormContext?.showBooking && !isNaN(bookingFormContext?.showBooking)) {
      onShowBookingDetailsClick(bookingFormContext?.showBooking, offCanvasContainerEnum.Body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingFormContext?.showBooking]);

  const onResourceClicked = (id: number) => {
    createGTMGAEvent('RTSM', 'Resources', 'Show Resource');
    goToResourceCalendarView(id, user?.timezone);
    setContainer(offCanvasContainerEnum.Body);
    return offCanvasContext?.open(offCanvasConfiguration);
  };

  return (
    <div className="flex h-screen w-screen" ref={resultViewRef}>
      <GTMCategoryContext.Provider value={GTMCategoryContextEnum.RTSM}>
        <JxtLocationFloorFilter selectedOptions={location} onChange={setLocation} isMulti={false} />
        {hasNoFloor && <JxtNoFloorPlan />}
        {!hasNoFloor && resultViewSize && (
          <SpaceLocateResources {...resultViewSize} floorId={location?.floorIds[0]} onClick={onResourceClicked} />
        )}
      </GTMCategoryContext.Provider>
    </div>
  );
};
