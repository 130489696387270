import JxtModal from '../JxtModal';
import { IJxtRecurrenceModal, IRecurrenceForm, TByWeekday } from './types';
import { useTranslation } from 'react-i18next';
import { JxtModalSizeEnum } from '../JxtModal/types';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import { Controller, FieldValues, Message, useForm, UseFormRegister } from 'react-hook-form';
import { Frequency } from 'rrule';
import {
  getWeekdayName,
  getWeekdayNameByIndex,
  MAXIMUM_OCCURRENCE,
  MINIMUM_OCCURRENCE,
  MonthlyMode,
  now,
  OccurrenceMode,
} from '@jooxter/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import JxtInputNumber from '../JxtInputNumber';
import JxtRecurrenceFrequencySelect from '../JxtRecurrenceFrequencySelect';
import { TFrequency } from '../JxtRecurrenceFrequencySelect/types';
import JxtRecurrenceMonthlyModeSelect from '../JxtRecurrenceMonthlyModeSelect';
import { ErrorMessage } from '@hookform/error-message';
import JxtRadioButton from '../JxtRadioButton';
import JxtDatePickerInput from '../JxtDatePickerInput';
import { reverseLangMapping } from '@jooxter/i18n';
import { useEffect, useState } from 'react';
import JxtRecurrenceSwitchDay from '../JxtRecurrenceSwitchDay';
import { useRecurrenceFormDefaultValue, useRecurrenceFormToRRuleAdapter } from './hooks';
import { isNil } from 'lodash-es';

const JxtRecurrenceModal = ({ date, show, onHide, value }: IJxtRecurrenceModal) => {
  const { t, i18n } = useTranslation();
  const { defaultValue: recurrenceFormDefaultValue } = useRecurrenceFormDefaultValue(date, value);
  const [numberOfOccurrences, setNumberOfOccurrences] = useState<number>(0);
  const [schema, setSchema] = useState<z.ZodRawShape>({
    freq: z
      .nativeEnum(Frequency, { required_error: t<string>('recurrence-form.error.frequency.required') })
      .default(recurrenceFormDefaultValue.freq),
    count: z
      .number({ required_error: t<string>('recurrence-form.error.count.required') })
      .min(
        MINIMUM_OCCURRENCE,
        t<string>('recurrence-form.error.count.out-range', { MINIMUM_OCCURRENCE, MAXIMUM_OCCURRENCE })
      )
      .max(
        MAXIMUM_OCCURRENCE,
        t<string>('recurrence-form.error.count.out-range', { MINIMUM_OCCURRENCE, MAXIMUM_OCCURRENCE })
      ),
    occurrenceMode: z.nativeEnum(OccurrenceMode).default(recurrenceFormDefaultValue.occurrenceMode),
    byWeekday: z.array(z.boolean()).default(recurrenceFormDefaultValue.byWeekday),
    bymonthday: z.number().int().optional().nullable(),
    dtstart: z.date(),
    monthlyMode: z.nativeEnum(MonthlyMode).default(recurrenceFormDefaultValue.monthlyMode ?? MonthlyMode.MonthDay),
    until: z
      .date()
      .min(
        now(date.zoneName ?? undefined)
          .startOf('day')
          .toJSDate(),
        t<string>('booking-form.error.date-not-in-past')
      )
      .optional(),
    interval: z
      .number({ required_error: t<string>('recurrence-form.error.frequency.required') })
      .int()
      .min(1),
  });
  const { register, handleSubmit, getValues, setValue, control, getFieldState, watch, formState, trigger, reset } =
    useForm<IRecurrenceForm>({
      mode: 'all',
      defaultValues: recurrenceFormDefaultValue,
      resolver: zodResolver(
        z.object(schema).superRefine((data, ctx: z.RefinementCtx) => {
          if (data.freq === Frequency.WEEKLY && !(data.byWeekday.filter((w: boolean) => w).length > 0)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t<string>('recurrence-form.error.by-weekday.required'),
              path: ['byWeekday'],
            });
          }
        })
      ),
    });
  const watchAll = watch();
  const { adapt } = useRecurrenceFormToRRuleAdapter(watchAll, date);
  const watchOccurrenceMode = watch('occurrenceMode');
  const watchByWeekday = watch('byWeekday');
  const watchMonthlyMode = watch('monthlyMode');
  const watchFreq = watch('freq');
  const watchInterval = watch('interval');
  const watchUntil = watch('until');
  const watchCount = watch('count');

  useEffect(() => {
    if (recurrenceFormDefaultValue) {
      reset(recurrenceFormDefaultValue, {
        keepDirtyValues: true,
      });
    }
  }, [recurrenceFormDefaultValue]);

  useEffect(() => {
    setSchema({
      ...schema,
      byWeekday:
        watchFreq === Frequency.WEEKLY
          ? z.array(z.boolean()).default(recurrenceFormDefaultValue.byWeekday)
          : z.optional(z.array(z.boolean().default(false))),
    });

    if (watchFreq === Frequency.WEEKLY) {
      trigger('byWeekday');
    }
  }, [watchFreq]);

  useEffect(() => {
    if (watchMonthlyMode === MonthlyMode.MonthDay) {
      setValue('bymonthday', date.day);
      setValue('byWeekday', [false, false, false, false, false, false, false]);
    } else if (watchMonthlyMode === MonthlyMode.WeekDay) {
      setValue('bymonthday', null);
      const newArray: TByWeekday = [...watchByWeekday];
      newArray.splice(date.weekday - 1, 1, true);
      setValue('byWeekday', newArray);
    }
  }, [watchMonthlyMode]);

  useEffect(() => {
    if (!watchInterval || (watchOccurrenceMode === OccurrenceMode.Custom && watchCount === undefined)) {
      return;
    }
    if (watchInterval > 0 && (isNil(watchCount) || watchCount >= 0 || watchOccurrenceMode !== OccurrenceMode.Custom)) {
      const occurrencesCreated = adapt()?.count();
      if (occurrencesCreated && occurrencesCreated > 0) {
        setNumberOfOccurrences(occurrencesCreated);
      }
    }
  }, [watchInterval, watchFreq, watchOccurrenceMode, watchByWeekday, watchMonthlyMode, watchUntil, watchCount]);

  useEffect(() => {
    setSchema({
      ...schema,
      count:
        watchOccurrenceMode === OccurrenceMode.Custom
          ? z
              .number({ required_error: t<string>('recurrence-form.error.count.required') })
              .min(
                MINIMUM_OCCURRENCE,
                t<string>('recurrence-form.error.count.out-range', { MINIMUM_OCCURRENCE, MAXIMUM_OCCURRENCE })
              )
              .max(
                MAXIMUM_OCCURRENCE,
                t<string>('recurrence-form.error.count.out-range', { MINIMUM_OCCURRENCE, MAXIMUM_OCCURRENCE })
              )
          : z.number().optional().nullable(),
      until:
        watchOccurrenceMode === OccurrenceMode.Until
          ? z
              .date()
              .min(
                now(date.zoneName ?? undefined)
                  .startOf('day')
                  .toJSDate(),
                t<string>('booking-form.error.date-not-in-past')
              )
              .optional()
          : z.date().optional().nullable(),
    });
  }, [watchOccurrenceMode]);

  useEffect(() => {
    trigger('count');
    trigger('until');
  }, [schema]);

  const onChangeDayValue = (v: boolean, i: number, callback: (r: boolean[]) => void) => {
    const newArray = [...watchByWeekday];
    newArray.splice(i, 1, v);
    callback(newArray);
  };

  const onSubmit = () => {
    onHide(adapt()?.toString().split('RRULE:')[1]);
  };

  return (
    <JxtModal
      show={show}
      size={JxtModalSizeEnum.S}
      onHide={onHide}
      header={{
        title: t<string>('custom-recurrence'),
      }}
      footer={
        <div className="flex justify-end items-center grow flex-wrap">
          <p className="grow">
            {t('booking.recurrence.occurrences.created', {
              occurrencesCreated: numberOfOccurrences,
            })}
          </p>
          <div className="flex flex-nowrap">
            <JxtButton className="mr-2" onClick={() => onHide()} variant={ButtonVariantEnum.Secondary}>
              {t('cancel')}
            </JxtButton>
            <JxtButton onClick={handleSubmit(onSubmit)} variant={ButtonVariantEnum.Primary}>
              {t('validate-button')}
            </JxtButton>
          </div>
        </div>
      }
    >
      <div className="flex gap-4 items-center mb-6 max-sm:flex-wrap">
        <h3 className="whitespace-nowrap text-title-m font-medium text-neutral-140">
          {t<string>('booking-form-recurrency-frequency-label')}
        </h3>
        <div className="flex gap-2">
          <div className="w-[120px] shrink-0">
            <JxtInputNumber
              showErrorMessages={false}
              register={register as unknown as UseFormRegister<FieldValues>}
              getFieldState={getFieldState}
              formState={formState}
              name="interval"
              setValue={setValue}
              getValues={getValues}
              min={1}
            />
          </div>
          <Controller
            control={control}
            name="freq"
            render={({ field: { value, onChange } }) => (
              <JxtRecurrenceFrequencySelect
                className="grow w-40"
                value={value as unknown as TFrequency}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
      <ErrorMessage
        name="FreqIntervalErrors"
        errors={{ freq: formState.errors.freq, interval: formState.errors.interval }}
        render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1">{message}</p>}
      />

      {watchFreq === Frequency.WEEKLY && (
        <div className="mb-6">
          <div className="flex gap-2">
            <Controller
              name="byWeekday"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  {watchByWeekday
                    .map((_, i) => getWeekdayNameByIndex(i, i18n.language))
                    .map((dayName, i) => (
                      <JxtRecurrenceSwitchDay
                        value={value[i]}
                        onChange={(v: boolean) => onChangeDayValue(v, i, onChange)}
                        key={`${i}-${dayName}`}
                        title={dayName}
                        labelForId={`${i}-${dayName}`}
                      />
                    ))}
                </>
              )}
            />
          </div>
          <ErrorMessage
            name="byWeekday"
            errors={{ byWeekday: formState.errors.byWeekday }}
            render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1">{message}</p>}
          />
        </div>
      )}

      {watchFreq === Frequency.MONTHLY && (
        <Controller
          control={control}
          name="monthlyMode"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="mb-6 w-[280px]">
              <JxtRecurrenceMonthlyModeSelect
                value={value}
                onChange={onChange}
                payload={{
                  interval: getValues('interval'),
                  dayIndex: date.day,
                  dayName: getWeekdayName(date, i18n.language),
                  monthDayIndex: Math.ceil(date.day / 7),
                }}
              />
              <ErrorMessage
                name="monthlyMode"
                errors={{ montlyMode: error }}
                render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1">{message}</p>}
              />
            </div>
          )}
        />
      )}

      <h3 className="whitespace-nowrap text-title-m font-medium text-neutral-140 mb-2">
        {t('booking-form-recurrency-ending-label')}
      </h3>
      <ul className="list-none">
        <li className="flex items-center gap-4 mb-2 h-10">
          <JxtRadioButton
            className="w-24"
            id="occurence-mode-until"
            label={t<string>('booking-form-recurrency-ending-until')}
            value={OccurrenceMode.Until}
            checked={watchOccurrenceMode === OccurrenceMode.Until}
            {...register('occurrenceMode')}
          />
          <Controller
            control={control}
            name="until"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <JxtDatePickerInput
                disabled={watchOccurrenceMode !== OccurrenceMode.Until}
                className="w-40"
                invalid={!!error}
                valueFormat="ll"
                value={value}
                onChange={onChange}
                locale={reverseLangMapping[i18n.language]}
                minDate={now().toJSDate()}
                weekendDays={[]}
              />
            )}
          />
        </li>
        {formState.errors.until && (
          <li className="-mt-2">
            <ErrorMessage
              name="until"
              errors={{ until: formState.errors.until }}
              render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1 mb-2">{message}</p>}
            />
          </li>
        )}
        <li className="flex items-center gap-4 mb-2 h-10">
          <JxtRadioButton
            className="w-24"
            id="occurence-mode-custom"
            label={t<string>('booking-form-recurrency-ending-after')}
            value={OccurrenceMode.Custom}
            checked={watchOccurrenceMode === OccurrenceMode.Custom}
            {...register('occurrenceMode')}
          />
          <div className="w-[120px] shrink-0">
            <JxtInputNumber
              showErrorMessages={false}
              disabled={watchOccurrenceMode !== OccurrenceMode.Custom}
              register={register as unknown as UseFormRegister<FieldValues>}
              getFieldState={getFieldState}
              formState={formState}
              name="count"
              setValue={setValue}
              getValues={getValues}
              min={MINIMUM_OCCURRENCE}
              max={MAXIMUM_OCCURRENCE}
            />
          </div>
          <label className="text-body-m text-neutral-140">{t<string>('occurrences')}</label>
        </li>
        {formState.errors.count && (
          <li className="-mt-2">
            <ErrorMessage
              name="count"
              errors={{ count: formState.errors.count }}
              render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1 mb-2">{message}</p>}
            />
          </li>
        )}
        <li className="flex items-center gap-4 h-10">
          <JxtRadioButton
            className="w-24"
            id="occurence-mode-max"
            label={t<string>('booking-form-recurrency-ending-never')}
            value={OccurrenceMode.Max}
            checked={watchOccurrenceMode === OccurrenceMode.Max}
            {...register('occurrenceMode')}
          />
        </li>
      </ul>
    </JxtModal>
  );
};

export default JxtRecurrenceModal;
