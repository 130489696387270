import { ISearchUserGroupsParameters, UserGroup, UserGroupCompressed, UserGroupsService } from '@jooxter/api';
import { keepPreviousData, useInfiniteQuery, useQueries } from '@tanstack/react-query';
import { UserGroupsQueryKeys } from '../queryKeys/userGroupsQueryKeys.enum';
import { UserGroupsStaleTimeEnum } from '../staleTimes/user-groups.enum';
import { uniqBy, flatMap } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

export const useSearchUserGroups = (options?: ISearchUserGroupsParameters, fetchAll = false) => {
  const [userGroups, setUserGroups] = useState<UserGroupCompressed[]>([]);

  const fetchUserGroups = useCallback(
    async ({ pageParam = '' }) => {
      const newOptions = { ...options };
      if (pageParam) {
        newOptions.page = pageParam;
      }

      return UserGroupsService.search(newOptions);
    },
    [options]
  );

  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [UserGroupsQueryKeys.Search, options],
    queryFn: fetchUserGroups,
    staleTime: UserGroupsStaleTimeEnum.Search,
    getNextPageParam: (lastPage: { nextPage?: string; data: UserGroupCompressed[] }) => {
      // must return undefined if it's the last page
      // lastPage.nextPage is ''
      return lastPage.nextPage?.length && lastPage.nextPage.length > 0 ? lastPage.nextPage : undefined;
    },
    initialPageParam: '',
  });

  useEffect(() => {
    if (data?.pages) {
      const pages = [...data.pages];
      const flatUserGroups = uniqBy(flatMap(pages.map((page) => page.data)), 'id');
      setUserGroups(flatUserGroups);
    }

    if (hasNextPage && fetchAll) {
      fetchNextPage();
    }
  }, [data]);

  return { userGroups, hasNextPage, fetchNextPage };
};

export const useFetchUserGroups = (ids: number[]): { groups: UserGroup[]; isPending: boolean } => {
  const fetchGroup = (id: number) => UserGroupsService.getUserGroup(id);

  const { data: groups, isPending } = useQueries({
    queries: ids.map((id: number) => {
      return {
        queryKey: [UserGroupsQueryKeys.GetById, id],
        queryFn: () => fetchGroup(id),
        placeholderData: keepPreviousData,
        staleTime: UserGroupsStaleTimeEnum.GetById,
        enabled: !!id,
      };
    }),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is UserGroup => !!r),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { groups, isPending };
};
