import { useTranslation } from 'react-i18next';
import { IJxtBookingOptions } from './types';
import Linkify from 'react-linkify';
import { componentDecorator } from '../../utils/componentDecorator';

const JxtBookingOptions = ({ options }: IJxtBookingOptions) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-3">
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-cogs fa-fw fa-lg text-neutral-60" />
      </div>
      <div className="flex flex-col min-w-0">
        <p className="text-title-m text-neutral-140 font-medium">{t('resource-page-options')}</p>
        <dl className="text-body-m text-neutral-140">
          {options.map((option) => {
            if (option.value?.type === 'CHECKBOX') {
              return (
                <div key={option.id} className="pt-2 flex">
                  <dt>{option.name}</dt>
                </div>
              );
            }

            return (
              <div key={option.id} className="pt-2 flex">
                <dt>
                  {option.name}
                  {option.value && ':\u00A0'}
                </dt>
                {option.value && (
                  <dd className="break-words min-w-0">
                    <Linkify componentDecorator={componentDecorator}>{option.value.content}</Linkify>
                  </dd>
                )}
              </div>
            );
          })}
        </dl>
      </div>
    </div>
  );
};

export default JxtBookingOptions;
