import { AsyncPaginate } from 'react-select-async-paginate';
import { IJxtAsyncSelect } from './types';
import { BaseStyles } from './base-styles';
import { ThemeFactory } from './base-theme';
import { useMemo } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useTranslation } from 'react-i18next';

//Needed to use Tailwind classes : https://react-select.com/styles#the-classnames-prop
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = useMemo(
    () =>
      createCache({
        key: 'with-tailwind',
        insertionPoint: document.querySelector('title')!,
      }),
    []
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

const JxtAsyncSelect = <T,>({
  keyString,
  value,
  handleSelection,
  loadOptions,
  styles,
  theme,
  defaultValue,
  placeholder,
  label,
  helperText,
  noOptionsMessage,
  formatOptionLabel,
  components,
  isClearable = false,
  isMulti = false,
  isDisabled = false,
  className = '',
  cacheUniqs = [],
  maxMenuHeight,
}: IJxtAsyncSelect<T>) => {
  const { t } = useTranslation();

  return (
    <EmotionCacheProvider>
      <div className={className}>
        {label && <label className="block pb-1 text-neutral-120 text-title-m font-medium">{label}</label>}
        <AsyncPaginate
          key={keyString}
          defaultOptions
          isDisabled={isDisabled}
          defaultValue={defaultValue}
          value={value}
          noOptionsMessage={() => noOptionsMessage}
          loadingMessage={() => t('loading')}
          placeholder={placeholder}
          loadOptions={loadOptions}
          additional={{ page: undefined }}
          onChange={handleSelection}
          styles={{
            ...styles,
          }}
          classNames={{
            ...BaseStyles<T>(),
          }}
          theme={ThemeFactory(theme)}
          formatOptionLabel={formatOptionLabel}
          components={components}
          isClearable={isClearable}
          // eslint-disable-next-line
          // @ts-ignore
          isMulti={isMulti}
          cacheUniqs={cacheUniqs}
          menuPlacement="auto"
          minMenuHeight={400}
          maxMenuHeight={maxMenuHeight}
        />
        {helperText && <p className="text-neutral-80 text-body-xs pt-1">{helperText}</p>}
      </div>
    </EmotionCacheProvider>
  );
};

export default JxtAsyncSelect;
