import { ReactNode, useEffect, useState } from 'react';
import { ModalLocateUserContext } from './ModalLocateUserContext';
import { IRange } from '@jooxter/utils';
import { DateTime } from 'luxon';

export const ModalLocateUserProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState<boolean>(false);
  const [locateUserModalInfos, setLocateUserModalInfos] = useState<any | null>(null);
  const [range, setRange] = useState<IRange>();
  const [date, setDate] = useState<DateTime | undefined>();

  useEffect(() => {
    if (!show) {
      setDate(undefined);
    }
  }, [show]);

  return (
    <ModalLocateUserContext.Provider
      value={{ date, setDate, show, setShow, locateUserModalInfos, range, setRange, setLocateUserModalInfos }}
    >
      {children}
    </ModalLocateUserContext.Provider>
  );
};
