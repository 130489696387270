import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { ReactComponent as Logo } from '../../assets/images/jooxter-logo-inline.svg';
import loginAsideUrl from '../../assets/images/jooxter-login-aside.svg';

const JxtLoginLayout = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row bg-white h-screen">
      <main className="order-1 lg:order-2 p-3 flex flex-col flex-1 lg:basis-0.5">
        <Outlet />
      </main>
      <aside className="flex flex-col items-center justify-center flex-1 order-2 lg:order-1 p-5 lg:basis-0.5 bg-primary-10">
        <div className="hidden lg:block text-center">
          <a className="mb-14 inline-block">
            <Logo />
          </a>
          <h2 className="text-headline-xxl font-bold my-0">{t('jooxter.baseline')}</h2>
        </div>
        <img
          className="lg:mt-14 max-w-[90%] lg:max-w-full mx-auto"
          src={loginAsideUrl}
          alt="Background picture for the login layout"
        />
      </aside>
    </div>
  );
};

export default JxtLoginLayout;
