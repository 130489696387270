import { useTranslation } from 'react-i18next';
import { ButtonSizeEnum, JxtButton } from '@jooxter/ui';
import { useNavigate } from 'react-router';
import { USER_PLANNING_ROUTE } from '@jooxter/core';

export const HomeWorkplacesFavoritesEmpty = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center text-center gap-8">
      <div className="w-10 h-8 flex items-center">
        <i className="fas fa-fw fa-2xl fa-users text-neutral-60" />
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-title-m text-neutral-140 font-medium">{t('no-favorites-paragraph')}</p>
        <p className="text-body-s text-neutral-80">{t('no-favorites-sub-paragraph')}</p>
      </div>
      <JxtButton
        size={ButtonSizeEnum.Large}
        className="text-body-m font-semibold"
        onClick={() => navigate(USER_PLANNING_ROUTE)}
      >
        {t('add-favorite')}
      </JxtButton>
    </div>
  );
};
