import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton } from '@jooxter/ui';
import { JxtLoginBase, LOGIN_ROUTE, useStartResetPassword } from '@jooxter/core';
import { createGTMUpdateVirtualPathEvent } from '@jooxter/utils';

const gtm: [string, string, string] = ['Login', 'First Login', 'First Login'];

export const LoginFirst = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [email] = useState(location.state?.email);
  const { startResetPassword, isButtonPasswordLoading, btnPasswordChildren, firstEmailSent } = useStartResetPassword(
    gtm,
    email
  );

  useEffect(() => {
    if (!firstEmailSent) {
      createGTMUpdateVirtualPathEvent('First Connection');
      startResetPassword();
    }
  }, [email, startResetPassword, firstEmailSent]);

  return (
    <JxtLoginBase title={t('first-login-title')} instructions={t('first-login-instructions')}>
      <div className="flex flex-col gap-6">
        <JxtButton size={ButtonSizeEnum.Large} variant={ButtonVariantEnum.Link} onClick={startResetPassword}>
          {isButtonPasswordLoading ? btnPasswordChildren : t('email-not-received')}
        </JxtButton>
        <JxtButton
          size={ButtonSizeEnum.Large}
          variant={ButtonVariantEnum.Primary}
          onClick={() => navigate(LOGIN_ROUTE)}
        >
          {t('first-login-btn-back')}
        </JxtButton>
      </div>
    </JxtLoginBase>
  );
};
