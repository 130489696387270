// eslint-disable-next-line import/named
import { EventContentArg } from '@fullcalendar/core';

export const renderEventClassNames = (args: EventContentArg) => {
  const classes = [];
  if (args.view.type === 'timeGridDay') {
    classes.push('px-2');
  } else if (['resourceTimelineDay', 'resourceTimelineWeek', 'resourceTimelineMonth'].includes(args.view.type)) {
    classes.push('p-0');
  }
  if (args.backgroundColor) {
    classes.push(args.backgroundColor);
  }
  if (args.borderColor) {
    classes.push(args.borderColor);
  }
  if (args.textColor) {
    classes.push(args.textColor);
  }
  return classes;
};
