import { IJxtSwitchOption } from './types';

const JxtSwitchOption = ({ children, value, id, checked = false, onChange }: IJxtSwitchOption) => {
  return (
    <div className="flex grow items-center justify-center">
      <input
        type="radio"
        name="switch-view"
        id={id}
        checked={checked}
        onChange={(tab: unknown) => {
          if (onChange) {
            onChange(tab);
          }
          // noop, change handled by parent form
        }}
        value={value ?? id}
        className="hidden peer"
      />
      <label
        htmlFor={id}
        className="px-2 grow text-center py-1 text-body-s text-neutral-120 bg-neutral-10 hover:bg-neutral-20 peer-checked:bg-white rounded cursor-pointer"
      >
        {children}
      </label>
    </div>
  );
};

export default JxtSwitchOption;
