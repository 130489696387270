import { ITimelineOptions } from '@jooxter/api';
import { IQuickTimeSlot } from '@jooxter/utils';
import { useMemo } from 'react';
import { useFetchTimeline } from '../../queries';
import { extractFreeSlots, shouldReturnDefaultSlot } from './helpers';

export const useFetchFreeSlots = (
  defaultSlot?: IQuickTimeSlot,
  timezone?: string,
  options?: ITimelineOptions
): { slots: IQuickTimeSlot[] } => {
  const { slots } = useFetchTimeline(options);

  const freeSlots = useMemo(() => {
    if (!(options && timezone)) {
      return [];
    }

    if (!slots?.length) {
      return [];
    }

    if (defaultSlot && shouldReturnDefaultSlot(slots, defaultSlot, timezone)) {
      return [defaultSlot];
    }

    return extractFreeSlots(slots, timezone);
  }, [slots, timezone, defaultSlot]);

  return { slots: freeSlots };
};
