import { useTranslation } from 'react-i18next';
import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';
import { LOGIN_PASSWORD_MIN_SIZE, LOGIN_PASSWORD_MAX_SIZE, useButtonStatus } from '@jooxter/core';
import { useEffect, useState } from 'react';
import { JxtButton, ButtonVariantEnum, JxtInput, ButtonSizeEnum } from '@jooxter/ui';
import { IResetPasswordFieldValues, IResetPasswordForm } from './types';

export const ResetPasswordForm = ({ callback }: IResetPasswordForm) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const { children, setLoading, loading } = useButtonStatus();
  const { register, handleSubmit, watch, setError, formState, getFieldState, trigger } =
    useForm<IResetPasswordFieldValues>({
      mode: 'onTouched',
    });
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    setDisabled(
      !!formState.errors.password ||
        !!formState.errors.confirmPassword ||
        !formState.dirtyFields['password'] ||
        !formState.dirtyFields['confirmPassword'] ||
        !confirmPassword ||
        confirmPassword.length < LOGIN_PASSWORD_MIN_SIZE
    );
  }, [!!formState.errors.password, !!formState.errors.confirmPassword, formState.dirtyFields, confirmPassword]);

  const onConfirmationPasswordChange = () => {
    trigger(['confirmPassword']);
  };

  const onSubmit = (data: IResetPasswordFieldValues) => {
    setLoading(true);
    callback(data)
      .catch(() => {
        setError('password', {});
        setError('confirmPassword', { message: t<string>('unknown-error') });
      })
      .finally(() => setLoading(false));
  };

  return (
    <form className="flex flex-col gap-6">
      <JxtInput
        name="password"
        placeholder={t<string>('new-password')}
        register={register as unknown as UseFormRegister<FieldValues>}
        validationSchema={{
          required: {
            value: true,
            message: t('recover-password-error-password-required'),
          },
          minLength: {
            value: LOGIN_PASSWORD_MIN_SIZE,
            message: t('recover-password-error-password-tooshort'),
          },
          maxLength: {
            value: LOGIN_PASSWORD_MAX_SIZE,
            message: t('recover-password-error-password-toolong'),
          },
          deps: ['confirmPassword'],
        }}
        formState={formState}
        getFieldState={getFieldState}
        type="password"
      />
      <JxtInput
        placeholder={t<string>('confirm-password')}
        name="confirmPassword"
        register={register as unknown as UseFormRegister<FieldValues>}
        validationSchema={{
          validate: (value) =>
            value === password || value.length <= 1 || t<string>('recover-password-error-confirmation-mismatched'),
        }}
        onChange={onConfirmationPasswordChange}
        formState={formState}
        getFieldState={getFieldState}
        type="password"
      />
      <JxtButton
        variant={loading ? ButtonVariantEnum.OutlinePrimary : ButtonVariantEnum.Primary}
        type="submit"
        size={ButtonSizeEnum.Large}
        onClick={handleSubmit(onSubmit)}
        disabled={loading ? true : disabled}
      >
        {loading ? children : t('change-password')}
      </JxtButton>
    </form>
  );
};
