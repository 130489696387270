/**
 * $grid-breakpoints: (
 *   xs: 0,
 *   sm: 576px,
 *   md: 768px,
 *   lg: 992px,
 *   xl: 1200px
 * ) !default;
 */
export enum SCREEN_SIZE {
  XXS = 0,
  XS = 375,
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export interface SizeUtil {
  device: SCREEN_SIZE;
  /** equals to */
  eq: (target: keyof typeof SCREEN_SIZE) => boolean;
  /** lower than or equals to */
  lte: (target: keyof typeof SCREEN_SIZE) => boolean;
  /** greater than to */
  gt: (target: keyof typeof SCREEN_SIZE) => boolean;
}
