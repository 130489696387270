// eslint-disable-next-line import/named
import { OptionProps, SingleValue, components } from 'react-select';
import { useState } from 'react';
import { User, UserCompressed, UserService } from '@jooxter/api';
import { JxtAsyncSelect, SelectOption } from '@jooxter/ui';
import { IJxtUserSelect } from './types';
import { useFetchUser } from '../../queries';
import { useTranslation } from 'react-i18next';
import { TextHighlight } from '@jooxter/utils';

const Option = (props: OptionProps<{ value: number; label: string }>) => {
  const userInput = props?.selectProps?.inputValue || '';
  const label = props?.data?.label || '';
  return (
    <components.Option {...props}>
      <div>
        {userInput?.length ? (
          label?.split(' ').length ? (
            <span>
              <TextHighlight text={label} searchTerm={userInput} />
            </span>
          ) : (
            label
          )
        ) : (
          label
        )}
      </div>
    </components.Option>
  );
};

export const JxtUserSelect = ({
  value,
  onUserSelect,
  placeholder,
  label,
  noOptionsMessage,
  helperText,
  excludedUsersId = [],
  disabled = false,
  maxMenuHeight,
}: IJxtUserSelect) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserCompressed[]>([]);
  const { user } = useFetchUser();

  const userToOption = (u: UserCompressed | User) => ({
    value: u.id as number,
    label: `${u.firstname} ${u.lastname}` + (user?.id && u.id === user.id ? ` (${t<string>('you')})` : ''),
  });

  const formattedValue = value === null ? null : value ? userToOption(value) : undefined;

  const handleUserSelection = (option: SingleValue<SelectOption<number>>) => {
    if (option) {
      const userId = option.value;
      const selectedUser = users?.find((u) => u.id === userId);
      if (selectedUser) {
        if (onUserSelect) {
          onUserSelect(selectedUser);
        }
      }
    }
  };

  async function loadOptions(search: string, loadedOptions: unknown, additional: { page?: string } | undefined) {
    const response = await UserService.getUsersWithPagination({
      globalSearch: search,
      size: 25,
      page: additional?.page,
    }).then(async (res) => {
      setUsers([...users, ...res.data]);

      return {
        data: res.data,
        nextPage: res.nextPage,
      };
    });

    return {
      options: response.data.filter((u) => !excludedUsersId.includes(u.id)).map((u) => userToOption(u)),
      hasMore: !!response.nextPage,
      additional: {
        page: response.nextPage,
      },
    };
  }

  return (
    <JxtAsyncSelect
      value={formattedValue}
      label={label}
      helperText={helperText}
      noOptionsMessage={noOptionsMessage ?? t<string>('no-matching-user')}
      loadOptions={loadOptions}
      handleSelection={handleUserSelection}
      placeholder={placeholder}
      components={{ Option }}
      isDisabled={disabled}
      maxMenuHeight={maxMenuHeight}
    />
  );
};
