export const componentDecorator = (decoratedHref: string, decoratedText: string, key: number) => (
  <a
    href={decoratedHref}
    key={key}
    target="_blank"
    rel="noreferrer"
    className="bg-transparent text-primary-100 hover:underline disabled:text-neutral-60 disabled:no-underline focus:outline-none rounded-lg text-body-m"
  >
    {decoratedText}
  </a>
);
