import { Slots } from '@jooxter/api';
import { DataPeriodEnum, getStandardTime, IQuickTimeSlot } from '@jooxter/utils';
import { DateTime, Duration } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useWorkHours } from '../services/useWorkHours';
import { useFetchUser } from '../../queries';

export const useDefaultDurationSlotGenerator = (date: DateTime, slots?: Slots): { durationSlots: IQuickTimeSlot[] } => {
  const { user } = useFetchUser();
  const { getStart } = useWorkHours(user?.id);

  const getDurationSlot = useCallback(
    (duration: Duration): IQuickTimeSlot => {
      const start = getStart(date, getStandardTime(date));
      const hourMin = start.split(':');
      const end = date
        .set({
          hour: +hourMin[0],
          minute: +hourMin[1],
        })
        .plus(duration);

      let formattedDuration = duration.shiftTo('hours', 'minutes');

      if (formattedDuration.minutes % 60 === 0) {
        formattedDuration = formattedDuration.shiftTo('hours');
      }

      if (formattedDuration.hours === 0) {
        formattedDuration = formattedDuration.shiftTo('minutes');
      }

      return {
        start: date,
        end,
        generatedByDefault: true,
        period: DataPeriodEnum.Day,
        text: formattedDuration.toHuman({ unitDisplay: 'short' }) ?? '',
      };
    },
    [date]
  );

  const durationSlots = useMemo(() => {
    return (
      slots?.durations
        ?.map((duration) => {
          if (!duration) {
            return null;
          }

          return getDurationSlot(Duration.fromObject({ minutes: duration }));
        })
        .filter((d): d is IQuickTimeSlot => !!d) ?? []
    );
  }, [date, slots]);

  return { durationSlots };
};
