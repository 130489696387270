import { ReactNode, createContext, useContext } from 'react';

export interface ISpaceFetchResourcesContext {
  hasNextPage: boolean;
  isFetching: boolean;
  fetchNextPage?: () => void;
}
export const SpaceFetchResourcesContext = createContext<ISpaceFetchResourcesContext | null>(null);

export const useSpaceFetchResourcesContext = (): ISpaceFetchResourcesContext => {
  const context = useContext(SpaceFetchResourcesContext);

  if (!context) {
    return {
      hasNextPage: false,
      isFetching: false,
      fetchNextPage: undefined,
    };
  }

  return context;
};

export const SpaceFetchResourcesProvider = ({
  value,
  children,
}: {
  value: ISpaceFetchResourcesContext;
  children: ReactNode;
}) => {
  return <SpaceFetchResourcesContext.Provider value={value}>{children}</SpaceFetchResourcesContext.Provider>;
};
