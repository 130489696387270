import { ResourceOpenInterval } from '@jooxter/api';
import { IRange } from '@jooxter/utils';
import { DateTime } from 'luxon';
// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';

export interface ResourceOpenIntervalWithResource extends ResourceOpenInterval {
  id: number;
}

export const OpeningHoursToEventInputAdapter = {
  adapt(
    openingHours: { id?: number; start: string; end: string }[] | ResourceOpenIntervalWithResource[],
    resourceTimezone: string
  ) {
    const tmpOpeningHours = [];

    if (openingHours.length === 0) {
      // create a fake open interval in the past (won't be visible) so as to show the space as
      // closed (remember that we use inverse background event for closed hours)
      tmpOpeningHours.push({
        resourceId: null,
        range: {
          from: DateTime.now().minus({ years: 1 }),
          to: DateTime.now().minus({ years: 1 }).plus({ minutes: 1 }),
        },
      });
    } else {
      tmpOpeningHours.push(
        ...openingHours.map((openInterval: ResourceOpenIntervalWithResource | ResourceOpenInterval) => ({
          resourceId: 'id' in openInterval ? openInterval.id : null,
          range: {
            from: openInterval.start
              ? DateTime.fromISO(openInterval.start, { zone: resourceTimezone })
              : DateTime.now().minus({ years: 1 }),
            to: openInterval.end
              ? DateTime.fromISO(openInterval.end, { zone: resourceTimezone })
              : DateTime.now().minus({ years: 1 }).plus({ minutes: 1 }),
          },
        }))
      );
    }

    return tmpOpeningHours.map((o) => this.adaptClosingHour(o.resourceId, o.range));
  },

  adaptClosingHour(resourceId: number | null, range: IRange): EventInput {
    return this.createBgEvent(resourceId, range.from, range.to, ['bg-neutral-40'], 'bgClosedId');
  },

  /**
   * Create a Background Event
   * Fullcalendar Event.end is exclusive. For example, an event with the end of 2018-09-03 will
   * appear to span through 2018-09-02 but end before the start of 2018-09-03.
   */
  createBgEvent(
    resourceId: number | undefined | null,
    start: DateTime,
    end: DateTime,
    cssClasses: string[],
    groupId: string
  ): EventInput {
    return {
      groupId,
      resourceId: resourceId?.toString(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      start: start.toISO()!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      end: end.toISO()!,
      display: 'inverse-background',
      classNames: cssClasses,
    };
  },
};
