import JooxterLogoNavbar from '../../assets/images/jooxter-logo.svg';
import JxtContainer from '../JxtContainer';
import { ContainerSizeEnum } from '../JxtContainer/types';
import { useErrorMessage } from './hooks';
import { IJxtError } from './types';

const JxtError = ({ code }: IJxtError) => {
  const { title, message } = useErrorMessage(code);

  return (
    <JxtContainer size={ContainerSizeEnum.Small}>
      <div className="flex flex-col p-4">
        <header className="flex flex-col justify-center">
          <img src={JooxterLogoNavbar} alt="Jooxter logo" className="h-32" />
          <h3 className="text-title-l font-medium text-neutral-140 mt-6 mb-3">{title}</h3>
        </header>
        <section>
          <p className="text-body-m font-light text-neutral-120"> {message}</p>
        </section>
      </div>
    </JxtContainer>
  );
};

export default JxtError;
