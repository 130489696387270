import { JxtTagColorsEnum } from '@jooxter/utils';
import { TResourceConfiguration } from '../types/booking-form.types';

export const useZoneSpaceAvailabilityColor = (
  remainingSeats: number,
  resourceConfiguration?: TResourceConfiguration
) => {
  if (!resourceConfiguration) {
    return { zoneSpaceAvailabilityColor: JxtTagColorsEnum.RED };
  }

  const fillPercentage = getFillPercentage(remainingSeats, resourceConfiguration);

  if (fillPercentage === 0 || fillPercentage < 0.5) {
    return { zoneSpaceAvailabilityColor: JxtTagColorsEnum.GREEN };
  } else if (fillPercentage < 1) {
    return { zoneSpaceAvailabilityColor: JxtTagColorsEnum.ORANGE };
  } else {
    return { zoneSpaceAvailabilityColor: JxtTagColorsEnum.RED };
  }
};

const getFillPercentage = (remainingSeats: number, resourceConfiguration: TResourceConfiguration): number => {
  return resourceConfiguration.capacity < remainingSeats
    ? 0
    : (resourceConfiguration.capacity - remainingSeats) / resourceConfiguration.capacity;
};
