import './App.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import { AppRoutes } from './routes/AppRoutes';
import { useEffect } from 'react';
import { useJimo } from './hooks/useJimo';
import { Toaster } from 'react-hot-toast';
import infos from '../package.json';
import {
  setupJimo,
  setupLuxon,
  redirectToSubdomain,
  useFetchUser,
  useModalViolationsContext,
  useOffCanvas,
  JxtModalLocateUser,
  useModalLocateUserContext,
  useInterceptors,
  useModalManageBookingsContext,
  JxtModalManageBookings,
  setupWootric,
} from '@jooxter/core';
import { JxtOffCanvas } from '@jooxter/ui';
import JxtModalBookingsViolations from '@jooxter/core/src/components/JxtModalBookingsViolations';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import './configure-amplify';
import { useWootric } from './hooks/useWootric';
import { getWindowName } from '@jooxter/utils';

const App = () => {
  useJimo();
  useWootric();
  useInterceptors(infos.version, getWindowName());
  const offCanvasContext = useOffCanvas();
  const modalViolationsContext = useModalViolationsContext();
  const modalLocateUserContext = useModalLocateUserContext();
  const modalManageBookingsContext = useModalManageBookingsContext();
  const { user } = useFetchUser();
  const { i18n } = useTranslation();
  const routerLocation = useLocation();

  useEffect(() => {
    if (user) {
      redirectToSubdomain(user, routerLocation.pathname, i18n.changeLanguage);
      if (user.lang) {
        document.documentElement.lang = user.lang;
      }
      setupLuxon(user);
      setupJimo(user);
      setupWootric(user);
    }
    // we don't want the effect to be run when routerLocation.pathName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.changeLanguage, user]);

  useEffect(() => {
    console.info(`You are serving the react app, version ${infos.version}`);
  }, []);

  if (!offCanvasContext) {
    return null;
  }

  return (
    <>
      <AppRoutes />
      <Toaster />

      <JxtOffCanvas
        container={offCanvasContext.offCanvasProps?.container}
        header={offCanvasContext.offCanvasProps?.header}
        footer={offCanvasContext.offCanvasProps?.footer}
        onHide={offCanvasContext.offCanvasProps?.onHide}
        show={offCanvasContext.show}
        bodyClass={offCanvasContext.offCanvasProps?.bodyClass}
        tabConfiguration={offCanvasContext.offCanvasProps?.tabConfiguration}
      >
        {offCanvasContext.offCanvasProps?.children}
      </JxtOffCanvas>
      <JxtModalBookingsViolations
        show={modalViolationsContext?.show ?? false}
        onHide={() => modalViolationsContext?.setShow(false)}
        recurrence={modalViolationsContext?.recurrence ?? null}
      />
      <JxtModalLocateUser
        show={modalLocateUserContext?.show ?? false}
        onHide={() => modalLocateUserContext?.setShow(false)}
        range={modalLocateUserContext?.range}
        date={modalLocateUserContext?.date}
        locateUserModalInfos={modalLocateUserContext?.locateUserModalInfos}
      />
      {!!modalManageBookingsContext && (
        <JxtModalManageBookings {...modalManageBookingsContext.getters} {...modalManageBookingsContext.setters} />
      )}
    </>
  );
};

export default App;
