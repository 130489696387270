import { useTranslation } from 'react-i18next';
import { IJxtBookingAttendeesList } from './types';
import JxtBookingAttendee from '../JxtBookingAttendee';
import { JxtBookingAttendeeTypeEnum } from '../JxtBookingAttendee/types';
import { useMemo } from 'react';

const JxtBookingAttendeesList = ({
  physicalAttendees,
  internalAttendees,
  externalAttendees,
  userEmail,
}: IJxtBookingAttendeesList) => {
  const { t } = useTranslation();
  const totalAttendees = useMemo(() => {
    const attendees = (internalAttendees?.length ?? 0) + (externalAttendees?.length ?? 0);

    if (physicalAttendees && physicalAttendees > attendees) {
      return physicalAttendees;
    }

    return attendees;
  }, [physicalAttendees, internalAttendees?.length, externalAttendees?.length]);

  const hasAttendees = useMemo(
    () => !!(internalAttendees?.length || externalAttendees?.length),
    [internalAttendees?.length, externalAttendees?.length]
  );

  return (
    <div className="flex gap-3">
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-users fa-fw fa-lg text-neutral-60" />
      </div>
      <div className="flex flex-col">
        {totalAttendees > 0 && (
          <p className="text-title-m text-neutral-140 font-medium">
            {totalAttendees + ' ' + t('participants', { physicalAttendees: totalAttendees })}
            {hasAttendees && ' ' + t('including')}
          </p>
        )}
        {internalAttendees?.map((attendee) =>
          attendee.email === userEmail ? (
            <JxtBookingAttendee
              key={attendee.id}
              name={attendee.name}
              picture={attendee.picture}
              checkout={attendee.checkout}
              participationStatus={attendee.participationStatus}
              type={JxtBookingAttendeeTypeEnum.CurrentUser}
            />
          ) : (
            <JxtBookingAttendee
              key={attendee.id}
              name={attendee.name}
              picture={attendee.picture}
              checkout={attendee.checkout}
              participationStatus={attendee.participationStatus}
            />
          )
        )}
        {externalAttendees?.map((attendee) => (
          <JxtBookingAttendee
            key={attendee.id}
            name={attendee.name}
            checkout={attendee.checkout}
            participationStatus={attendee.participationStatus}
            type={JxtBookingAttendeeTypeEnum.External}
          />
        ))}
      </div>
    </div>
  );
};

export default JxtBookingAttendeesList;
