export const rgbToHex = ([r, g, b]: [number, number, number, number?]): string => {
  const innerConverter = (n: number): string => {
    const hex = Number(n).toString(16);

    return hex.length < 2 ? '0' + hex : hex;
  };
  const red: string = innerConverter(r);
  const green: string = innerConverter(g);
  const blue: string = innerConverter(b);

  return red + green + blue;
};

/**
 * Convert a string into hex
 * @example "rgb(0, 0, 0)" or "rgba(0, 0, 0, 1)"
 */
export const convertRgbStringToHex = (rgb: string): string | undefined => {
  /**
   * rgb(0,0,0)
   * rgb(0, 0, 0)
   * rgba(255,255,255,1)
   * rgba(255, 255, 255, 1)
   */
  const pattern = new RegExp(/^rgba?\(((?:\s?\d{1,3},?){3,4})\)$/);
  const matches = pattern.exec(rgb);

  if (matches && matches[1]) {
    return rgbToHex(
      matches[1]
        .replace(' ', '')
        .split(',')
        .map((s) => parseInt(s, 10)) as [number, number, number, number?]
    );
  }
};

/**
 * @param hex should be #aaaaaa or aaaaaa, no shortcut
 */
export const hex2rgba = (hex: string, alpha = 1): string | undefined => {
  const matches = hex.match(/\w\w/g);

  if (matches?.length) {
    const [r, g, b] = matches.map((x) => parseInt(x, 16));

    if (alpha === 1) {
      return `rgb(${r}, ${g}, ${b})`;
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
};
