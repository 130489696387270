import {
  convertJSDateToTimezone,
  getDateTimeFromDateTimeAndStandardTime,
  isPastDateMinusFiveMinutes,
  now,
} from '@jooxter/utils';
import { DateTime } from 'luxon';
import { z } from 'zod';

export const dateNotInPastSuperRefinerFactory =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (messageKey: string, timezone?: string, isEditing?: boolean) => (data: any, ctx: z.RefinementCtx) => {
    const startDate = data.start.date;
    const endDate = data.end.date;
    const nowDay = now(timezone).startOf('day');

    if (startDate.startOf('day') < nowDay) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: messageKey,
        path: ['start.date'],
      });
    } else if (isPastDateMinusFiveMinutes(startDate) && !isEditing) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: messageKey,
        path: ['start.time'],
      });
    }

    if (endDate.startOf('day') < nowDay && startDate.startOf('day') >= nowDay) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: messageKey,
        path: ['end.date'],
      });
    } else if (isPastDateMinusFiveMinutes(endDate) && !isPastDateMinusFiveMinutes(startDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: messageKey,
        path: ['end.time'],
      });
    }
  };

export const startBeforeEndSuperRefinerFactory = (messageKey: string) => (data: any, ctx: z.RefinementCtx) => {
  if (data.start.date > data.end.date) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: messageKey,
      path: ['start', 'date'],
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: ' ',
      path: ['end', 'date'],
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: ' ',
      path: ['start', 'time'],
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: ' ',
      path: ['end', 'time'],
    });
  }
};

export const getDateTimeFromJSDateAndStandardTime = (date: Date, time: string, timezone: string): DateTime => {
  const dateTime = DateTime.fromJSDate(convertJSDateToTimezone(date, timezone), { zone: timezone });

  return getDateTimeFromDateTimeAndStandardTime(dateTime, time);
};
