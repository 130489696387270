import { DateSelectArg, EventApi } from '@fullcalendar/core';
import { IScheduler } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BookingCompressedListItemAdapter,
  useFetchAllResourcesClosingHours,
  useFetchFloors,
  useFetchResourceTypes,
  useFetchLocations,
  useSearchBookings,
  useStore,
  useUpdateBooking,
  OpeningHoursToEventInputAdapter,
  useBookingCompressedLogisticTimesAdapter,
  IEventChange,
  getResourceType,
  BookingQueryKeys,
  useBookingIdToBookingRequestAdapter,
  JxtSchedulerCalendar,
} from '@jooxter/core';
import {
  IRange,
  RruleConstructorObject,
  cutMidnight,
  fromISO,
  isDateInRange,
  sameDay,
  toISO,
  dateToISO,
  ISOToLocalDate,
  ISOToLocalTime,
  isToday,
} from '@jooxter/utils';
import { JxtToast, JxtToastVariantEnum, JxtUpdateBookingModal } from '@jooxter/ui';
import { useFullCalendarLocale } from '@jooxter/fullcalendar';
import { useTranslation } from 'react-i18next';
import { useResourceInputAdapter } from './hooks';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { Booking, ResourceTypeDtoMetaTypeEnum } from '@jooxter/api';
import { Frequency, RRule } from 'rrule';
import { useQueryClient } from '@tanstack/react-query';
import { useDebouncedValue } from '@mantine/hooks';
import { useShallow } from 'zustand/shallow';

export const Scheduler = ({
  initialDate,
  user,
  resources = [],
  onDateChange,
  onResourceClicked,
  onBook,
  onLocateResource,
  onToggleFavorite,
}: IScheduler) => {
  const [filter] = useStore(useShallow((state) => [state.filter]));
  const [date, setDate] = useState<IRange>(initialDate);
  const [debouncedDate] = useDebouncedValue(date, 200);

  const { t, i18n } = useTranslation();
  const { locale } = useFullCalendarLocale(i18n.language);
  const resourceSourceInput = useResourceInputAdapter(resources);
  const [showUpdateBookingModal, setShowUpdateBookingModal] = useState<boolean>(false);
  const [eventToUpdate, setEventToUpdate] = useState<IEventChange | null>(null);
  const { mutate: mutationUpdateRecurrences } = useUpdateBooking(true);
  const { mutate: mutationUpdate } = useUpdateBooking();
  const { resourceTypes } = useFetchResourceTypes();
  const { locations } = useFetchLocations(resources?.map((r) => r.locationId));
  const { floors } = useFetchFloors(resources?.map((r) => r.floorId).filter((f): f is number => typeof f === 'number'));
  const queryClient = useQueryClient();

  const searchBookingOptions = useMemo(() => {
    return {
      resourceId: resources.map((r) => r.id),
      from: date.from.startOf('day'),
      to: date.from.startOf('day').equals(date.to.startOf('day')) ? date.to.endOf('day') : date.to.startOf('day'),
    };
  }, [toISO(date.from), toISO(date.to), resources]);

  const [debouncedSearchBookingOptions] = useDebouncedValue(searchBookingOptions, 200);

  const {
    bookings,
    isLoading: isFetchingBookings,
    hasNextPage: hasNextBookingsPage,
    fetchNextPage: fetchNextBookingsPage,
    invalidateSearchBookings,
  } = useSearchBookings(debouncedSearchBookingOptions, !!(debouncedSearchBookingOptions.resourceId?.length > 0));

  const { adapt: adaptBookingCompressedToBookingRequest } = useBookingIdToBookingRequestAdapter(
    eventToUpdate?.event.id ? parseInt(eventToUpdate.event.id, 10) : undefined
  );
  const { openingHours } = useFetchAllResourcesClosingHours({
    from: debouncedDate.from.startOf('day'),
    to: debouncedDate.to.endOf('day'),
    byDay: true,
    resourceId: resources.map((r) => r.id),
  });
  const { adapt: adaptToLogisticTimes } = useBookingCompressedLogisticTimesAdapter();
  // https://github.com/TanStack/query/issues/3049
  const events = useMemo(() => {
    if (user.timezone) {
      return [
        ...(bookings
          ?.map((b) => BookingCompressedListItemAdapter.adapt(b, { title: 'summary', editable: true }))
          ?.map((b) => {
            if (b.extendedProps) {
              b.extendedProps.location = locations?.find(
                (l) => l.id === b.extendedProps?.originalBooking.resource.locationId
              );
              b.extendedProps.options = resourceSourceInput?.find((r) => r.id === b.resourceId)?.extendedProps.options;
              b.extendedProps.floor = floors?.find((l) => l.id === b.extendedProps?.originalBooking.resource.floorId);

              const resourceType = getResourceType(
                b.extendedProps.originalBooking.resource.resourceTypeId,
                resourceTypes
              );

              if (resourceType) {
                b.extendedProps.isZone = resourceType.allowOverlap;
              }
            }

            return b;
          }) ?? []),
        ...(bookings?.map((b) => adaptToLogisticTimes(b)) ?? []).flat(),
        ...OpeningHoursToEventInputAdapter.adapt(openingHours, user.timezone),
      ];
    }

    return [];
    // https://github.com/TanStack/query/issues/3049
  }, [
    user.timezone,
    bookings,
    openingHours,
    locations,
    resourceSourceInput,
    floors,
    resourceTypes,
    adaptToLogisticTimes,
  ]);

  useEffect(() => {
    if (hasNextBookingsPage) {
      fetchNextBookingsPage();
    }
  }, [bookings, isFetchingBookings, hasNextBookingsPage, fetchNextBookingsPage]);

  const onFullCalendarRangeChange = useCallback(
    (range: IRange) => {
      if (!isDateInRange(fromISO(filter.resource.date.from), range)) {
        const from = fromISO(filter.resource.date.from).set({
          year: range.from.year,
          month: range.from.month,
          day: range.from.day,
          second: 0,
          millisecond: 0,
        });
        const to = fromISO(filter.resource.date.to).set({
          year: range.from.year,
          month: range.from.month,
          day: range.from.day,
          second: 0,
          millisecond: 0,
        });
        const transformedRange = { from, to };
        onDateChange({ from: toISO(transformedRange.from), to: toISO(transformedRange.to) });
      }
      setDate(range);
    },
    [onDateChange, filter.resource.date.from, filter.resource.date.to]
  );

  const onSelect = (selectionInfo: DateSelectArg) => {
    if (resourceTypes && selectionInfo.resource?.id) {
      const resourceType = resourceTypes.find((r) => r.id === selectionInfo.resource?.extendedProps.resourceTypeId);
      const isVehicle = resourceType?.metaType === ResourceTypeDtoMetaTypeEnum.Vehicle;
      let from = fromISO(selectionInfo.startStr);
      let to = cutMidnight(fromISO(selectionInfo.endStr));
      if (['resourceTimelineWeek', 'resourceTimelineMonth'].includes(selectionInfo.view.type)) {
        const filterStart = fromISO(filter.resource.date.from);
        const filterTo = fromISO(filter.resource.date.to);
        from = from.set({ hour: filterStart.hour, minute: filterStart.minute });
        to = to.set({ hour: filterTo.hour, minute: filterTo.minute });
      }

      const shouldAutoSelectFirstSlot = selectionInfo.view.type !== 'resourceTimelineDay' && isToday(from);
      if (isVehicle) {
        onBook(Number(selectionInfo.resource.id), { from, to }, undefined, shouldAutoSelectFirstSlot);
      } else {
        let rrulestr;
        if (!sameDay(from, to)) {
          const rrule = {
            freq: Frequency.DAILY,
            dtstart: from.toJSDate(),
            interval: 1,
            tzid: selectionInfo.resource?.extendedProps.timezone,
            until: to.toJSDate(),
          } as RruleConstructorObject;
          rrulestr = new RRule(rrule).toString().split('RRULE:')[1];
          to = to.set({ year: from.year, month: from.month, day: from.day });
        }
        onBook(Number(selectionInfo.resource.id), { from, to }, rrulestr, shouldAutoSelectFirstSlot);
      }
    }
  };

  const onConfirmUpdate = (event?: EventApi, recurrence?: boolean, revert?: () => void) => {
    if (!event) {
      return;
    }

    let bookingRequest = adaptBookingCompressedToBookingRequest(event.extendedProps.originalBooking);

    if (!bookingRequest) {
      return;
    }

    const newStart = event?.start
      ? dateToISO(event.start, event.extendedProps.originalBooking.resource.timezone)
      : undefined;
    const newEnd = event?.end ? dateToISO(event.end, event.extendedProps.originalBooking.resource.timezone) : undefined;

    if (!(newStart && newEnd)) {
      return;
    }

    bookingRequest = {
      ...bookingRequest,
      start: {
        dateTime: newStart,
      },
      end: {
        dateTime: newEnd,
      },
    };

    const resourceId = event.getResources()[0]?.id;

    if (resourceId) {
      bookingRequest.resourceId = parseInt(resourceId, 10);
    }

    const onSuccess = (booking: Booking) => {
      toast.custom(
        (newToast) => (
          <JxtToast
            variant={JxtToastVariantEnum.Success}
            onHide={() => toast.remove(newToast.id)}
            title={recurrence ? t<string>('booking-and-following-occurrences-updated') : t<string>('update-saved')}
            subtitle={t<string>('booking-updated-message', {
              resourceName: booking.resource.name,
              bookingDate: ISOToLocalDate(booking.start.dateTime, booking.resource.timezone),
              startTime: ISOToLocalTime(booking.start.dateTime, booking.resource.timezone),
              endTime: ISOToLocalTime(booking.end.dateTime, booking.resource.timezone),
            })}
          />
        ),
        { position: 'bottom-left' }
      );
    };
    const onError = () => {
      revert && revert();
      toast.custom(
        (newToast) => (
          <JxtToast
            onHide={() => toast.remove(newToast.id)}
            variant={JxtToastVariantEnum.Failure}
            title={t<string>('booking-update-failed')}
          />
        ),
        { position: 'bottom-left' }
      );
    };

    const onSettled = (b?: Booking) => {
      invalidateSearchBookings();
      b?.id &&
        queryClient.invalidateQueries({
          queryKey: [BookingQueryKeys.GetBooking, b.id],
        });
    };

    if (recurrence) {
      mutationUpdateRecurrences(
        { id: parseInt(event.id), payload: bookingRequest },
        {
          onSuccess,
          onError,
          onSettled,
        }
      );
    } else {
      mutationUpdate(
        { id: parseInt(event.id), payload: bookingRequest },
        {
          onSuccess,
          onError,
          onSettled,
        }
      );
    }
  };
  const eventResizedOrDragged = useCallback((arg: IEventChange) => {
    setEventToUpdate(arg);
    setShowUpdateBookingModal(true);
  }, []);

  return (
    <div className="grow">
      <JxtSchedulerCalendar
        events={events}
        resources={resourceSourceInput}
        timezone={user.timezone}
        locale={locale}
        initialDate={toISO(initialDate.from)}
        onFullCalendarRangeChange={onFullCalendarRangeChange}
        onResourceClicked={onResourceClicked}
        onSelect={onSelect}
        onBook={onBook}
        onLocateResource={onLocateResource}
        eventResizedOrDragged={eventResizedOrDragged}
        onToggleFavorite={onToggleFavorite}
      />
      <JxtUpdateBookingModal
        oldEvent={{
          start: eventToUpdate?.oldEvent.start ? DateTime.fromJSDate(eventToUpdate?.oldEvent.start) : undefined,
          end: eventToUpdate?.oldEvent.end ? DateTime.fromJSDate(eventToUpdate?.oldEvent.end) : undefined,
        }}
        oldResource={
          eventToUpdate?.oldResource && {
            id: parseInt(eventToUpdate?.oldResource.id, 10),
            name: eventToUpdate?.oldResource.title,
          }
        }
        newResource={
          eventToUpdate?.newResource && {
            id: parseInt(eventToUpdate?.newResource.id, 10),
            name: eventToUpdate?.newResource.title,
          }
        }
        event={{
          start: eventToUpdate?.event.start ? DateTime.fromJSDate(eventToUpdate?.event.start) : undefined,
          end: eventToUpdate?.event.end ? DateTime.fromJSDate(eventToUpdate?.event.end) : undefined,
        }}
        timezone={eventToUpdate?.event.extendedProps.originalBooking.resource.timezone}
        recurrence={!!eventToUpdate?.event.extendedProps.originalBooking.rrule}
        onHide={(e) => {
          if (e) {
            onConfirmUpdate(eventToUpdate?.event, e?.recurrence, eventToUpdate?.revert);
          } else {
            eventToUpdate?.revert();
          }

          setShowUpdateBookingModal(false);
          setEventToUpdate(null);
        }}
        show={showUpdateBookingModal}
      />
    </div>
  );
};
