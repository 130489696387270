import { JxtCollapsibleCheckboxList } from '@jooxter/ui';
import { IJxtTeamsCheckboxList } from './types';
import { useTranslation } from 'react-i18next';
import { TJxtCheckboxListItem } from '@jooxter/ui/src/components/JxtCheckboxList/types';
import { useSearchUserGroups } from '../../queries/queryFns/user-groups';
import { useFoldedFilters } from '../../hooks/useFoldedFilters';

export const JxtTeamsCheckboxList = ({ selectedGroupIds, onChange, scope }: IJxtTeamsCheckboxList) => {
  const { t } = useTranslation();
  const { userGroups, hasNextPage, fetchNextPage } = useSearchUserGroups();

  const { folded, onFoldChange } = useFoldedFilters(scope, 'teams');

  if (!userGroups || userGroups.length === 0) {
    return null;
  }

  const onChangeGroup = (selectedGroups: TJxtCheckboxListItem[]) => {
    if (onChange && userGroups) {
      onChange(
        userGroups
          .filter((group) => selectedGroups.some((selectedGroup) => selectedGroup.value === group.id))
          .map((group) => group.id!)
      );
    }
  };

  return (
    <div className="p-4 border-b border-neutral-20">
      <JxtCollapsibleCheckboxList
        onFoldChange={(e) => onFoldChange(e)}
        defaultFolded={folded}
        label={t('colleagues-label-teams')}
        allOrNoneSelectedOptionsLabel={t<string>('collapsible.checkbox.list-all_f')}
        options={userGroups.map((group) => ({ value: group.id!, label: group.name }))}
        selectedOptions={userGroups
          .filter((group) => selectedGroupIds.includes(group.id!))
          .map((option) => ({ value: option.id!, label: option.name }))}
        onChange={onChangeGroup}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </div>
  );
};

export default JxtTeamsCheckboxList;
