import { clsx } from 'clsx';

export interface IUseJxtInputNumberClass {
  hasErrors: boolean;
}

export const getJxtInputNumberClass = ({ hasErrors = false }: IUseJxtInputNumberClass) => {
  const baseClasses =
    'w-full rounded-lg border text-body-s text-neutral-120 disabled:border-neutral-10 disabled:text-neutral-60 focus:outline-none focus:ring-4 py-2 px-10 text-center';
  const errorClasses = hasErrors
    ? 'border-red-100 focus:ring-red-50 hover:border-red-30 focus:border-red-100'
    : 'hover:border-neutral-30 border-neutral-20 focus:ring-primary-30 focus:border-primary-100';

  const classes = clsx(baseClasses, errorClasses);

  return classes;
};
