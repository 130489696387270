import { DailyWorkplaceDto, UserWorkplaces } from '@jooxter/api';
// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';
import { useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter } from './useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter';
import { useCallback } from 'react';

export const useWorkplaceToEventInputAdapter = (usersWorkplaces?: UserWorkplaces[]) => {
  const { adapt: adaptWorkplaces } = useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter(usersWorkplaces);

  const adapt = useCallback(
    (workplace: DailyWorkplaceDto): EventInput => {
      return {
        date: workplace.date,
        allDay: false,
        extendedProps: {
          ...adaptWorkplaces(workplace),
        },
      };
    },
    [adaptWorkplaces]
  );

  return { adapt };
};
