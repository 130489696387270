// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';
import {
  BookingCompressedListItemAdapter,
  useSearchBookings,
  JxtMyBookingsCalendarList,
  useBookingCallbacks,
  useBookingFormContext,
} from '@jooxter/core';
import { useEffect, useState } from 'react';
import { IHomeBookingsWeek } from './types';
import { JxtLoader } from '@jooxter/ui';

export const HomeBookingsWeek = ({ range, user }: IHomeBookingsWeek) => {
  const [bookings, setBookings] = useState<EventInput[]>([]);
  const { onShowBookingDetailsClick } = useBookingCallbacks();
  const { bookings: bookingsFromAPI, isLoading } = useSearchBookings({
    ...range,
    participantId: user.id,
  });
  const bookingFormContext = useBookingFormContext();

  useEffect(() => {
    if (bookingFormContext?.showBooking && !isNaN(bookingFormContext?.showBooking)) {
      onShowBookingDetailsClick(bookingFormContext?.showBooking);
    }
  }, [bookingFormContext?.showBooking]);

  useEffect(() => {
    if (bookingsFromAPI) {
      setBookings(bookingsFromAPI.map((b) => BookingCompressedListItemAdapter.adapt(b, { title: 'resource' })));
    }
  }, [bookingsFromAPI]);

  return (
    <div className="relative w-full">
      <JxtMyBookingsCalendarList
        events={bookings}
        range={range}
        onClick={onShowBookingDetailsClick}
        timezone={user.timezone}
      />
      {isLoading && (
        <div className="absolute inset-0 flex bg-white z-[999] items-center justify-center">
          <JxtLoader />
        </div>
      )}
    </div>
  );
};
