import { BookingDisplayModeEnum } from './types';

export const getClassNames = (mode: BookingDisplayModeEnum) => {
  if (mode === BookingDisplayModeEnum.Compact) {
    return 'h-full p-1 flex-col';
  }
  if (mode === BookingDisplayModeEnum.Regular) {
    return 'h-10 py-1.5 px-2 items-center';
  }
  if (mode === BookingDisplayModeEnum.NoPadding) {
    return 'h-full flex-col';
  }
  return '';
};
