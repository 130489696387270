import { BookingCompressed, ResourceFloorDto, ResourceTypeDto } from '@jooxter/api';
import { IFloorPlanResource } from '@jooxter/ui';
import { useCallback } from 'react';
import { useFetchResourceTypes } from '../../queries';

const DEFAULT_MAP_ICON_SIZE = 1;

export const useResourceFloorDTOToIFloorPlanResourceAdapter = (
  resources: ResourceFloorDto[] = [],
  bookings: BookingCompressed[] = []
) => {
  const { resourceTypes } = useFetchResourceTypes();

  const adapt = useCallback((): IFloorPlanResource[] => {
    if (!resourceTypes) {
      return [];
    }

    return resources.map((rc) => ({
      ...rc,
      resourceType: resourceTypes.find((rt: ResourceTypeDto) => rt.id === rc.resourceTypeId) ?? {
        mapIconSize: DEFAULT_MAP_ICON_SIZE,
        showMapIcon: false,
        allowOverlap: false,
      },
      bookings: bookings.filter((b) => b.resource.id === rc.id),
    }));
  }, [resourceTypes, resources, JSON.stringify(bookings)]);

  return { adapt };
};
