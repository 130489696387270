// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';
import { ExceptionalOpeningHours } from '@jooxter/api';
import { fromISO, JxtEventColorsEnum } from '@jooxter/utils';
import { Adapter, EventTypeEnum } from '../types';

export const ExceptionalOpeningHoursToEventInputAdapter: Adapter<ExceptionalOpeningHours, EventInput> = {
  adapt(exceptionalOpeningHours: ExceptionalOpeningHours): EventInput {
    return {
      id: exceptionalOpeningHours.id?.toString(),
      title: exceptionalOpeningHours.title,
      start: fromISO(exceptionalOpeningHours.timestampFrom).toJSDate(),
      end: fromISO(exceptionalOpeningHours.timestampTo).toJSDate(),
      extendedProps: {
        type: EventTypeEnum.EXCEPTIONAL_OPENING_HOURS,
        resourceId: exceptionalOpeningHours.resourceId,
        color: exceptionalOpeningHours.opened ? JxtEventColorsEnum.Green : JxtEventColorsEnum.Red,
      },
    };
  },

  revert() {
    throw new Error('Method not implemented');
  },
};
