import { JxtCollapsibleCheckboxList } from '@jooxter/ui';
import { IJxtLabelsCheckboxList } from './types';
import { useFetchLabels } from '../../queries';
import { useTranslation } from 'react-i18next';
import { TJxtCheckboxListItem } from '@jooxter/ui/src/components/JxtCheckboxList/types';
import { useFoldedFilters } from '../../hooks/useFoldedFilters';

export const JxtLabelsCheckboxList = ({ selectedOptions, onChange, scope }: IJxtLabelsCheckboxList) => {
  const { t } = useTranslation();
  const { labels } = useFetchLabels();

  const { folded, onFoldChange } = useFoldedFilters(scope, 'labels');

  if (!labels || labels.length === 0) {
    return null;
  }

  const onChangeLabel = (selectedLabels: TJxtCheckboxListItem[]) => {
    if (onChange) {
      onChange(labels.filter((lb) => selectedLabels.some((sLb) => sLb.label === lb)));
    }
  };

  return (
    <div className="sm:px-6 py-4 border-b border-neutral-20">
      <JxtCollapsibleCheckboxList
        onFoldChange={(e) => onFoldChange(e)}
        defaultFolded={folded}
        label={t<string>('resourcesearch-label-resource-label')}
        allOrNoneSelectedOptionsLabel={t<string>('collapsible.checkbox.list-all_f')}
        options={labels.map((lb) => ({ value: lb, label: lb }))}
        selectedOptions={selectedOptions.map((option) => ({ value: option, label: option }))}
        onChange={onChangeLabel}
      />
    </div>
  );
};
