import { JxtWorkplaceTypeEnum } from '@jooxter/utils';

export const JxtWorkplacesIconInfo = {
  [JxtWorkplaceTypeEnum.Office]: {
    iconName: 'fa-building',
    iconColor: 'text-green-150',
    iconBackgroundColor: 'bg-green-20',
    morningClasses: 'from-green-20 via-green-20',
    afternoonClasses: 'to-green-20',
    translationKey: 'workplace.office',
  },
  [JxtWorkplaceTypeEnum.WorkFromHome]: {
    iconName: 'fa-house',
    iconColor: 'text-primary-150',
    iconBackgroundColor: 'bg-primary-20',
    morningClasses: 'from-primary-20 via-primary-20',
    afternoonClasses: 'to-primary-20',
    translationKey: 'workplace.teleworking',
  },
  [JxtWorkplaceTypeEnum.Mission]: {
    iconName: 'fa-car',
    iconColor: 'text-orange-150',
    iconBackgroundColor: 'bg-orange-20',
    morningClasses: 'from-orange-20 via-orange-20',
    afternoonClasses: 'to-orange-20',
    translationKey: 'workplace.mission',
  },
  [JxtWorkplaceTypeEnum.Off]: {
    iconName: 'fa-times-circle',
    iconColor: 'text-red-150',
    iconBackgroundColor: 'bg-red-20',
    morningClasses: 'from-red-20 via-red-20',
    afternoonClasses: 'to-red-20',
    translationKey: 'workplace.off',
  },
  [JxtWorkplaceTypeEnum.Unknown]: {
    iconName: 'fa-question-circle',
    iconColor: 'text-neutral-60',
    iconBackgroundColor: 'bg-neutral-10',
    morningClasses: 'from-neutral-10 via-neutral-10',
    afternoonClasses: 'to-neutral-10',
    translationKey: 'no-info-filled',
  },
};
