// eslint-disable-next-line import/named
import { OptionProps, SingleValue, components } from 'react-select';
import { useEffect, useMemo, useState } from 'react';
import { ResourceCompressed, ResourceService } from '@jooxter/api';
import { JxtAsyncSelect, SelectOption } from '@jooxter/ui';
import { IJxtResourceSelect } from './types';
import { TextHighlight } from '@jooxter/utils';

const Option = (props: OptionProps<{ value: number; label: string }>) => {
  const userInput = props?.selectProps?.inputValue || '';
  const label = props?.data?.label || '';
  return (
    <components.Option {...props}>
      <div>
        {userInput?.length ? (
          label?.split(' ').length ? (
            <span>
              <TextHighlight text={label} searchTerm={userInput} />
            </span>
          ) : (
            label
          )
        ) : (
          label
        )}
      </div>
    </components.Option>
  );
};

export const JxtResourceSelect = ({
  onResourceSelect,
  searchOptions,
  defaultValue,
  value,
  label,
  helperText,
  placeholder,
  noOptionsMessage,
  isClearable = false,
  autoSelectFirstOption = false,
  className = '',
}: IJxtResourceSelect) => {
  const [resources, setResources] = useState<Array<ResourceCompressed>>([]);

  const formatOption = (resource: ResourceCompressed) => ({
    value: resource.id,
    label: resource.name,
  });

  const defaultOption = useMemo(
    () => (autoSelectFirstOption && resources.length > 0 ? formatOption(resources[0]) : defaultValue),
    [defaultValue, resources[0], autoSelectFirstOption]
  );

  useEffect(() => {
    if (autoSelectFirstOption && !value) {
      handleResourceSelection(defaultOption as SingleValue<SelectOption<number>>);
    }
  }, [defaultOption]);

  const handleResourceSelection = (option: SingleValue<SelectOption<number>> | null) => {
    if (option) {
      const resourceId = option.value;
      const selectedResource = resources?.find((resource) => resource.id === resourceId);

      if (selectedResource && onResourceSelect) {
        onResourceSelect(selectedResource);
      }
    } else if (option === null && onResourceSelect) {
      onResourceSelect(option);
    }
  };

  async function loadOptions(search: string, loadedOptions: unknown, additional: { page?: string } | undefined) {
    const response = await ResourceService.getResourcesWithPagination({
      ...searchOptions,
      name: search,
      page: additional?.page,
      size: 25,
      visible: true,
    }).then(async (res) => {
      setResources([...resources, ...res.data]);

      return {
        data: res.data,
        nextPage: res.nextPage,
      };
    });

    return {
      options: response.data.map((r) => formatOption(r)),
      hasMore: !!response.nextPage,
      additional: {
        page: response.nextPage,
      },
    };
  }

  return (
    <JxtAsyncSelect
      key={`${defaultOption?.value}-${JSON.stringify(searchOptions)}`}
      className={className}
      defaultValue={defaultOption}
      value={value}
      label={label}
      helperText={helperText}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      isClearable={isClearable}
      loadOptions={loadOptions}
      handleSelection={handleResourceSelection}
      components={{ Option }}
    />
  );
};
