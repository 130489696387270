import { IJxtBookingLocation } from './types';

const JxtBookingLocation = ({ location }: IJxtBookingLocation) => {
  return (
    <div className="flex items-center gap-2">
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-location-dot fa-fw fa-lg text-neutral-60" />
      </div>
      <span className="text-body-m text-neutral-140 truncate">{location}</span>
    </div>
  );
};

export default JxtBookingLocation;
