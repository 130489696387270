import { Booking, BookingAttendeeParticipationStatusEnum, BookingRequest, BookingService } from '@jooxter/api';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { defaultOnError } from './default-error';

export const useCheckinBooking = () => {
  const mutationCheckin = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return BookingService.checkin(id);
    },
  });

  return mutationCheckin;
};

export const useCheckoutBooking = () => {
  const mutationCheckout = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return BookingService.checkout(id);
    },
  });

  return mutationCheckout;
};

export const useArriveBooking = () => {
  const mutationArrive = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return BookingService.arrive(id);
    },
  });

  return mutationArrive;
};

export const useLeaveBooking = () => {
  const mutationLeave = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return BookingService.leave(id);
    },
  });

  return mutationLeave;
};

export const useApproveBooking = () => {
  const mutationApprove = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return BookingService.approve(id);
    },
  });

  return mutationApprove;
};

export const useRefuseBooking = (
  onSuccess?: (data: Booking) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) => {
  const mutationRefuse = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return BookingService.refuse(id);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationRefuse;
};

export const useDeleteBooking = (
  recurrence = false,
  onSuccess?: (data: Booking) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) => {
  const mutationDelete = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      if (recurrence) {
        return BookingService.deleteWithRecurrence(id);
      }

      return BookingService.delete([id]);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationDelete;
};

export const useDeleteBookings = (
  onSuccess?: (data: Booking, variables: number[]) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) => {
  const mutationDelete = useMutation({
    mutationFn: (bookings: number[]) => {
      return BookingService.delete(bookings);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationDelete;
};

export const useCreateBooking = (
  onSuccess?: (data: Booking) => void,
  onError?: (error: AxiosError, variable: BookingRequest) => void,
  onSettled?: () => void
) => {
  const mutationCreate = useMutation<Booking, AxiosError, BookingRequest>({
    mutationFn: (booking: BookingRequest) => {
      return BookingService.create(booking);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationCreate;
};

export const useUpdateBooking = (
  recurrence = false,
  onSuccess?: (data: Booking) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) => {
  const mutationUpdate = useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: BookingRequest }) => {
      if (recurrence) {
        return BookingService.updateWithRecurrence(id, payload);
      }

      return BookingService.update(id, payload);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationUpdate;
};

export const useUpdateParticipationBooking = () => {
  const mutationUpdateParticipation = useMutation({
    mutationFn: ({ id, status }: { id: number; status: BookingAttendeeParticipationStatusEnum }) =>
      BookingService.participate(id, status),
  });

  return mutationUpdateParticipation;
};
