import { useCallback, useEffect, useRef, useState } from 'react';
import { BASE_FONT_SIZE, STROKE_WIDTH } from '../constants';
import { IJxtPictoGroup } from '../types';

// padding of one side in px
const PADDING = 8;
const SPACING = 20;
const INTERLINE = 8;
// max chars we want to display for title and subtitle
const MAX_CHARACTERS = 40;

export const JxtResourcePicto = ({ p, onPictoClick, parent, computedRatio }: IJxtPictoGroup) => {
  const [showTooltip, setShowTooltip] = useState(p.showMapIcon);
  const groupRef = useRef<SVGGElement>(null);
  const [titleWidth, setTitleWidth] = useState<number>();
  const [subtitleWidth, setSubtitleWidth] = useState<number>();
  const [subtitleHeight, setsubtitleHeight] = useState<number>();
  const [baseHeight, setBaseHeight] = useState<number>();
  const baseWidth = titleWidth && (!subtitleWidth || titleWidth > subtitleWidth) ? titleWidth : subtitleWidth;
  const titleFontSize = computedRatio ? computedRatio * BASE_FONT_SIZE : BASE_FONT_SIZE;
  const truncatedTitle =
    p.title && p.title?.length > MAX_CHARACTERS ? p.title.substring(0, MAX_CHARACTERS).concat('...') : p.title;
  const truncatedSubtitle =
    p.subtitle && p.subtitle?.length > MAX_CHARACTERS
      ? p.subtitle.substring(0, MAX_CHARACTERS).concat('...')
      : p.subtitle;
  const toFront = () => {
    groupRef.current && parent.current?.appendChild(groupRef.current);
  };

  // https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node

  const titleRef = useCallback(
    (node: SVGTextElement | null) => {
      if (node) {
        setTitleWidth(node.getBBox().width);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [p]
  );

  const subtitleRef = useCallback(
    (node: SVGTextElement | null) => {
      if (node) {
        setSubtitleWidth(node.getBBox().width);
        setsubtitleHeight(node.getBBox().height);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [p]
  );

  const textRef = useCallback(
    (node: SVGGElement | null) => {
      if (node) {
        setBaseHeight(node.getBBox().height);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titleWidth, subtitleWidth, subtitleHeight]
  );

  if (!(p.x && p.y)) {
    return null;
  }

  return (
    <g
      ref={groupRef}
      onClick={() => onPictoClick(p.id)}
      onTouchEnd={() => onPictoClick(p.id)}
      onMouseEnter={() => {
        toFront();
        setShowTooltip(true);
      }}
      onMouseLeave={() => !p.showMapIcon && setShowTooltip(false)}
      className="cursor-pointer"
    >
      <image width={p.size} height={p.size} href={p.url} x={p.x} y={p.y} />
      {baseWidth && baseHeight && (
        <rect
          className={showTooltip ? 'visible' : 'invisible'}
          width={baseWidth + PADDING * 2}
          height={baseHeight + PADDING}
          fill="white"
          stroke="#e0e3e8"
          strokeWidth={computedRatio ? computedRatio * STROKE_WIDTH : STROKE_WIDTH}
          rx="8"
          ry="8"
          x={p.x - baseWidth / 2 - 8 + p.size / 2 - 1}
          y={
            p.y - baseHeight / 2 - PADDING / 2 - (p.subtitle && subtitleHeight ? SPACING + subtitleHeight / 2 : SPACING)
          }
        />
      )}
      <g ref={textRef}>
        <text
          ref={titleRef}
          className={showTooltip ? 'visible' : 'invisible'}
          textAnchor="middle"
          alignmentBaseline="central"
          x={p.x + p.size / 2}
          y={p.y - (p.subtitle && subtitleHeight ? SPACING + INTERLINE + subtitleHeight - PADDING : SPACING)}
          fontFamily='"Open Sans", sans-serif'
          fontSize={titleFontSize}
          fontWeight="800"
          fill="#22272e"
        >
          {truncatedTitle}
        </text>
        {p.subtitle && (
          <text
            ref={subtitleRef}
            className={showTooltip ? 'visible' : 'invisible'}
            textAnchor="middle"
            alignmentBaseline="central"
            x={p.x + p.size / 2}
            y={p.y - SPACING}
            fontFamily='"Open Sans", sans-serif'
            fontSize={titleFontSize}
            fontWeight="400"
            fill="#677489"
          >
            {truncatedSubtitle}
          </text>
        )}
      </g>
    </g>
  );
};
