import { useTranslation } from 'react-i18next';
import { IJxtProfilePictureUploader } from './types';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  JxtAvatar,
  JxtAvatarSizeEnum,
  JxtButton,
  JxtInputFile,
  JxtModal,
  JxtToast,
  JxtToastVariantEnum,
} from '@jooxter/ui';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useCreateImage, useMutatePatchUser, useMutatePutUser } from '../../mutations';
import toast from 'react-hot-toast';
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { FileValidator } from './FileValidator';
import { userToUserRequestExtendedAdapter } from '../../adapters';
import Resizer from 'react-image-file-resizer';
import { getCroppedImg } from '@jooxter/utils';

const JxtProfilePictureUploader = ({ user, onClick, onSave }: IJxtProfilePictureUploader) => {
  const { t } = useTranslation();
  const { mutate: mutatePatchUser } = useMutatePatchUser();
  const { mutate: mutatePutUser } = useMutatePutUser();
  const { mutateAsync: mutationCreateImage } = useCreateImage();
  const imgRef = useRef<HTMLImageElement>(null);
  const [show, setShow] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>();
  const [imgUrl, setImgUrl] = useState<string | undefined>(user.picture);

  useEffect(() => {
    setImgUrl(user.picture);
  }, [user.picture]);

  const onClickUpdatePictureButton = () => {
    setShow(true);
    if (onClick) {
      onClick();
    }
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files.length > 0 && e.target.files[0];
    if (file) {
      if (FileValidator.isValid(file)) {
        addFile(file);
      } else {
        toast.custom(
          (newToast) => (
            <JxtToast
              variant={JxtToastVariantEnum.Failure}
              onHide={() => toast.remove(newToast.id)}
              title={t('file-size-error')}
            />
          ),
          {
            position: 'bottom-left',
          }
        );
      }
    }
  };

  const addFile = (file: File) => {
    setCrop(undefined);
    setCompletedCrop(undefined);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImgUrl(reader.result?.toString() || ''));
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 80,
          height: 80,
        },
        1,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'PNG',
        85,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file'
      );
    });

  const onValidate = async () => {
    if (imgUrl && imgRef.current && completedCrop) {
      const base64 = await getCroppedImg(imgRef.current, completedCrop);

      if (base64) {
        // https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
        const decodedBase64 = window.atob(base64.split(',')[1]);
        let n = decodedBase64.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = decodedBase64.charCodeAt(n);
        }
        const file = new File([u8arr], 'file_name.png', {
          type: 'image/png',
        });

        resizeFile(file).then((resizedFile) => {
          uploadImage(resizedFile);
          setCrop(undefined);
          setCompletedCrop(undefined);
        });
      }

      if (onSave) {
        onSave();
      }
    }
    setShow(false);
  };

  const uploadImage = (image: File) => {
    mutationCreateImage(image).then((media) => {
      if (user.id) {
        mutatePatchUser({ id: user.id, payload: { pictureId: media.id } });
      }
    });
  };

  const onDelete = () => {
    setImgUrl(undefined);
    if (user.id) {
      const { userExtended } = userToUserRequestExtendedAdapter(user);
      if (userExtended) {
        mutatePutUser({ id: user.id, payload: userExtended });
      }
    }
  };

  const onCancel = () => {
    setImgUrl(undefined);
  };

  return (
    <div className="flex flex-col items-center gap-3">
      <JxtAvatar
        picture={user.picture}
        size={JxtAvatarSizeEnum.XL}
        text={`${user.firstname[0]}${user.lastname[0]}`}
        alt={t('avatar-of', {
          name: `${user.firstname} ${user.lastname}`,
        })}
      />
      <JxtButton variant={ButtonVariantEnum.Link} size={ButtonSizeEnum.Large} onClick={onClickUpdatePictureButton}>
        {user.picture ? t('user-profile-picture.update') : t('user-profile-picture.add')}
      </JxtButton>
      <JxtModal
        show={show}
        onHide={() => {
          setShow(false);
          setImgUrl(user.picture);
        }}
        header={{ title: t('user-profile-picture.update') }}
        footer={
          <div className="sm:flex grid grid-cols-2 grow gap-3">
            {imgUrl &&
              (user.picture ? (
                <JxtButton variant={ButtonVariantEnum.Secondary} className="sm:flex-1" onClick={onDelete}>
                  {t('delete')}
                </JxtButton>
              ) : (
                <JxtButton variant={ButtonVariantEnum.Secondary} className="sm:flex-1" onClick={onCancel}>
                  {t('cancel')}
                </JxtButton>
              ))}
            <JxtInputFile
              variant={ButtonVariantEnum.Secondary}
              handleFileChange={onSelectFile}
              accept="image/*"
              className={clsx('sm:flex-1', !imgUrl && 'col-span-2')}
            >
              {t('select-picture-label')}
            </JxtInputFile>
            <JxtButton onClick={onValidate} className="sm:flex-1 row-start-2 col-span-2" autoFocus>
              {t('user-profile-picture.save')}
            </JxtButton>
          </div>
        }
      >
        <div className="flex grow items-center justify-center">
          {imgUrl ? (
            <ReactCrop
              crop={crop}
              aspect={1}
              onChange={(crop) => setCrop(crop)}
              onComplete={(crop) => setCompletedCrop(crop)}
              circularCrop
              keepSelection
            >
              <img ref={imgRef} src={imgUrl} width="100%" height="100%" crossOrigin="anonymous" onLoad={onImageLoad} />
            </ReactCrop>
          ) : (
            <JxtAvatar
              picture={user.picture}
              size={JxtAvatarSizeEnum.XL}
              text={`${user.firstname[0]}${user.lastname[0]}`}
              alt={t('avatar-of', {
                name: `${user.firstname} ${user.lastname}`,
              })}
            />
          )}
        </div>
      </JxtModal>
    </div>
  );
};

export default JxtProfilePictureUploader;
