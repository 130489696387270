import { ReactNode } from 'react';
import { IJxtButton } from '../JxtButton/types';

export enum ContainerPaddingEnum {
  Regular = 'regular',
  None = 'none',
}

export enum ContainerSizeEnum {
  Small = 'small',
  Medium = 'medium',
}

export interface IJxtContainer {
  id?: string;

  /**
   *  Title of the container.
   */
  title?: string;

  /**
   * Subtitle of the container.
   */
  subtitle?: string;

  /**
   *  Button of the container.
   */
  button?: IJxtButton;

  /**
   * Padding of the container.
   * @default regular
   */
  padding?: ContainerPaddingEnum;

  /**
   * Size of the container.
   * @default medium
   */
  size?: ContainerSizeEnum;

  /**
   * Content in the body of the container, it can be some text or React Node.
   */
  children: ReactNode;

  className?: string;
}
