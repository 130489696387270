import { ExceptionalOpeningHours } from '@jooxter/api';
import { isSameTimezoneAsTheUser, JxtEventColorsEnum, JxtResourcesTypeEnum } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTimezoneNameFormatter } from '../../hooks';
import { useFetchResource } from '../../queries';
import { IJxtExceptionalOpeningHoursDetails } from '@jooxter/ui';
import { JxtLocateResource } from '../../components';

export const useExceptionalOpeningHoursToIJxtExceptionalOpeningHoursDetailsAdapter = (
  exceptionalOpeningHours?: ExceptionalOpeningHours | null
): { exceptionalOpeningHoursDetails: IJxtExceptionalOpeningHoursDetails | undefined } => {
  const { resource } = useFetchResource(exceptionalOpeningHours?.resourceId);
  const { format } = useTimezoneNameFormatter();

  const exceptionalOpeningHoursDetails: IJxtExceptionalOpeningHoursDetails | undefined = useMemo(() => {
    if (exceptionalOpeningHours && resource) {
      return {
        summary: {
          title: exceptionalOpeningHours.title ?? '',
          color: exceptionalOpeningHours.opened ? JxtEventColorsEnum.Green : JxtEventColorsEnum.Red,
        },
        date: {
          range: {
            from: DateTime.fromISO(exceptionalOpeningHours.timestampFrom, { zone: resource.location.timezone }),
            to: DateTime.fromISO(exceptionalOpeningHours.timestampTo, { zone: resource.location.timezone }),
          },
          timezoneWarning: !isSameTimezoneAsTheUser(resource.location.timezone)
            ? format(resource.location.timezone)
            : undefined,
        },
        resource: {
          ...resource,
          locationName: resource.location.name,
          typeName: resource.resourceType.name,
          type: resource.resourceType.metaType
            ? JxtResourcesTypeEnum[resource.resourceType.metaType]
            : JxtResourcesTypeEnum.OTHER,
          floor: resource.floor?.number,
          picture: resource.pictures?.length ? resource.pictures[0] : undefined,
        },
        plan: <JxtLocateResource resourceId={resource.id} width={300} height={300} />,
      };
    }
  }, [exceptionalOpeningHours, resource]);

  return { exceptionalOpeningHoursDetails };
};
