import { DateSelectArg } from '@fullcalendar/core';
import {
  JxtAlert,
  JxtAlertTypeEnum,
  JxtLoader,
  JxtOffCanvasHeaderHideIconEnum,
  JxtResourceTabHeader,
  JxtTab,
  offCanvasContainerEnum,
} from '@jooxter/ui';
import {
  OffCanvasModeEnum,
  BookingOffCanvasTabEnum,
  createGTMGAEvent,
  fromISO,
  IQuickTimeSlot,
  IRange,
  isSameTimezoneAsTheUser,
  now,
  toISO,
} from '@jooxter/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchBooking, useFetchResource, useFetchResourceTypes } from '../../queries';
import { useOffCanvas } from '../useOffCanvasContext';
import { Tab } from '@headlessui/react';
import { DateTime } from 'luxon';
import {
  JxtBookingDetailsContainer,
  JxtBookingFormContainer,
  JxtResourceCalendar,
  ResourceInformation,
} from '../../components';
import { useBookingToIJxtBookingDetailsAdapter } from '../../adapters';
import { useBookingPresenter } from '../../presenters';
import { useFullCalendarLocale } from '@jooxter/fullcalendar';
import { useBookingActions } from './useBookingActions';
import { getResourceType, useStore, useTimezoneNameFormatter } from '../..';
import { IBookingFormPreset } from '../../forms/Booking/types/booking-form.types';
import { AutoSelectFirstSlotContext } from './AutoSelectFirstSlotContext';
import { useShallow } from 'zustand/shallow';

// We have two entry points for this hook : the booking (ie: UPDATE or VIEW) or the resource (ie CREATE)
export const useBookingOffCanvasConfiguration = (onBookingDelete?: (id: number) => void) => {
  const [mode, setMode] = useState<OffCanvasModeEnum>();
  const [container, setContainer] = useState<offCanvasContainerEnum>(offCanvasContainerEnum.Main);
  const [autoSelectFirstSlot, setAutoSelectFirstSlot] = useState<boolean>(false);
  const [directEdit, setDirectEdit] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [bookingId, setBookingId] = useState<number | undefined>();
  const [resourceId, setResourceId] = useState<number | undefined>();
  const { resource } = useFetchResource(resourceId);
  const [slotToBookInstantly, setSlotToBookInstantly] = useState<IQuickTimeSlot | null>(null);
  const { booking: bookingInOffCanvas } = useFetchBooking(bookingId);
  const { bookingDetails } = useBookingToIJxtBookingDetailsAdapter(bookingInOffCanvas);
  const { presenter } = useBookingPresenter(bookingInOffCanvas);
  const [selectedIndex, setSelectedIndex] = useState<BookingOffCanvasTabEnum>(BookingOffCanvasTabEnum.Planning);
  const offCanvasContext = useOffCanvas();
  const { locale } = useFullCalendarLocale(i18n.language);
  const [preset, setPreset] = useState<IBookingFormPreset>();
  const { cancel, deleteBookingModal } = useBookingActions(offCanvasContext, onBookingDelete);
  const [filters] = useStore(useShallow((state) => [state.filter.resource]));
  const { resourceTypes } = useFetchResourceTypes();
  const { format: formatTimezoneName } = useTimezoneNameFormatter();
  const resourceCalendarProps = useMemo(() => {
    if (mode === OffCanvasModeEnum.Create) {
      if (resource) {
        return {
          resourceId: resource.id,
          resourceName: resource.name,
          resourceTypeName: resource.resourceType.name,
          resourceTypeId: resource.resourceType.id,
          capacity: resource.capacity,
          initialDate: fromISO(
            toISO(preset?.from?.setZone(resource.location.timezone, { keepLocalTime: true }) ?? now()),
            resource.location.timezone
          ),
          timezone: resource.location.timezone,
          isBookable: resource.bookable,
          shouldShowTimezoneWarning: !isSameTimezoneAsTheUser(resource.location.timezone),
        };
      }
    } else if (mode === OffCanvasModeEnum.Update) {
      if (presenter.start && presenter.resource) {
        return {
          resourceId: presenter.resource.id,
          resourceName: presenter.resource.name,
          resourceTypeId: presenter.resource.resourceTypeId,
          resourceTypeName: getResourceType(presenter.resource.resourceTypeId, resourceTypes)?.name ?? '',
          capacity: presenter.resource.capacity,
          initialDate: fromISO(presenter.start.dateTime, presenter.resource.timezone),
          timezone: presenter.resource.timezone,
          isBookable: presenter.resource.bookable,
          shouldShowTimezoneWarning: presenter.resource.timezone
            ? !isSameTimezoneAsTheUser(presenter.resource.timezone)
            : false,
        };
      }
    }
  }, [mode, resource?.id, presenter.start?.dateTime, presenter.resource?.id, preset]);

  const goToEditView = () => {
    setMode(OffCanvasModeEnum.Update);
    setSelectedIndex(BookingOffCanvasTabEnum.Form);
  };

  useEffect(() => {
    if (!resourceCalendarProps?.isBookable) {
      setSelectedIndex(BookingOffCanvasTabEnum.Information);
    }
  }, [resourceCalendarProps]);

  useEffect(() => {
    return () => resetAndOffCanvas();
    // we want this to run on unmount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToBookingView = (id: number) => {
    setBookingId(id);
    setDirectEdit(false);
    setMode(OffCanvasModeEnum.View);
  };

  const goToResourceCalendarView = useCallback(
    (id?: number, timezone?: string) => {
      if (id && timezone) {
        setBookingId(undefined);
        setMode(OffCanvasModeEnum.Create);
        setDirectEdit(true);
        setResourceId(id);
        setPreset({
          ...preset,
          from: fromISO(filters.date.from, timezone),
          to: fromISO(filters.date.to, timezone),
          physicalAttendees: filters.capacity,
          organizerId: filters.organizerId,
          rrule: null,
        });
      }
      setSelectedIndex(BookingOffCanvasTabEnum.Planning);
    },
    [preset, filters]
  );

  const resetAndOffCanvas = () => {
    setBookingId(undefined);
    setResourceId(undefined);
    offCanvasContext?.close();
  };

  const goToResourceInformations = useCallback(
    (id?: number, timezone?: string) => {
      if (id && timezone) {
        setBookingId(undefined);
        setMode(OffCanvasModeEnum.Create);
        setDirectEdit(true);
        setResourceId(id);
        setPreset({
          ...preset,
          from: fromISO(filters.date.from, timezone),
          to: fromISO(filters.date.to, timezone),
          physicalAttendees: filters.capacity,
          organizerId: filters.organizerId,
          rrule: null,
        });
      }
      setSelectedIndex(BookingOffCanvasTabEnum.Information);
    },
    [preset, filters]
  );

  const goToCreateBooking = useCallback(
    (resourceId: number, timezone?: string, range?: IRange, rrule?: string, shouldAutoSelectFirstSlot = true) => {
      setBookingId(undefined);
      setMode(OffCanvasModeEnum.Create);
      setResourceId(resourceId);
      setDirectEdit(true);
      setAutoSelectFirstSlot(shouldAutoSelectFirstSlot);
      setPreset({
        ...preset,
        from: range ? range.from : fromISO(filters.date.from, timezone),
        to: range ? range.to : fromISO(filters.date.to, timezone),
        physicalAttendees: filters.capacity,
        organizerId: filters.organizerId,
        rrule: rrule,
      });
      setSelectedIndex(BookingOffCanvasTabEnum.Form);
    },
    [preset, filters]
  );

  const deleteBooking = () => {
    if (bookingId && bookingDetails) {
      cancel &&
        cancel({
          ...bookingDetails,
          id: bookingId,
          start: {
            dateTime: toISO(bookingDetails.date.range.from),
          },
          end: {
            dateTime: toISO(bookingDetails.date.range.to),
          },
          resourceName: bookingDetails.resource.name,
        });
    }
  };

  const getIconList = (): { iconName: string; title: string; onClick: () => void }[] => {
    const icons: { iconName: string; title: string; onClick: () => void }[] = [];

    if (OffCanvasModeEnum.View !== mode) {
      return icons;
    }

    if (!bookingInOffCanvas) {
      return icons;
    }

    if (bookingInOffCanvas.capabilities?.update) {
      icons.push({
        iconName: 'pen',
        title: t<string>('update'),
        onClick: () => goToEditView(),
      });
    }

    if (bookingInOffCanvas.capabilities?.cancel) {
      icons.push({
        iconName: 'trash',
        title: t<string>('delete'),
        onClick: () => deleteBooking(),
      });
    }

    return icons;
  };

  const onEventClick = (id: number) => {
    setBookingId(id);
    setMode(OffCanvasModeEnum.View);
  };

  const onSelection = (selectionInfo: DateSelectArg) => {
    setAutoSelectFirstSlot(false);
    // define a preset
    const from = DateTime.fromISO(selectionInfo.startStr, { zone: selectionInfo.view.getOption('timeZone') });
    const to = DateTime.fromISO(selectionInfo.endStr, { zone: selectionInfo.view.getOption('timeZone') });
    setPreset({ from, to });
    setResourceId(resourceCalendarProps?.resourceId);
    setMode(OffCanvasModeEnum.Create);
    setSelectedIndex(BookingOffCanvasTabEnum.Form);
  };

  const onBookSlot = (slot: IQuickTimeSlot) => {
    setResourceId(resourceCalendarProps?.resourceId);
    setMode(OffCanvasModeEnum.Create);
    setPreset({ ...preset, from: slot.start, to: slot.end, rrule: null });
    setSlotToBookInstantly(slot);
    setSelectedIndex(BookingOffCanvasTabEnum.Form);
  };

  const onClickBookingTab = () => {
    if (selectedIndex === BookingOffCanvasTabEnum.Information) {
      createGTMGAEvent('ResourceViewPanel', 'Booking', 'Open Creation Booking');
    }
  };

  const offCanvasConfiguration = useMemo(
    () => ({
      container,
      header: {
        hideIcon:
          mode === OffCanvasModeEnum.View || directEdit
            ? JxtOffCanvasHeaderHideIconEnum.Close
            : JxtOffCanvasHeaderHideIconEnum.Back,
        iconList: getIconList(),
        title: mode !== OffCanvasModeEnum.View && !!resourceCalendarProps && (
          <JxtResourceTabHeader
            name={resourceCalendarProps.resourceName}
            type={resourceCalendarProps.resourceTypeName}
            capacity={resourceCalendarProps.capacity}
          />
        ),
        children: mode !== OffCanvasModeEnum.View && (
          <>
            {resourceCalendarProps?.shouldShowTimezoneWarning && resourceCalendarProps?.timezone && (
              <JxtAlert
                text={formatTimezoneName(resourceCalendarProps.timezone)}
                type={JxtAlertTypeEnum.Warning}
                closeable={false}
              />
            )}
            <Tab.List className="flex justify-around gap-2">
              <JxtTab
                text={t('navbar.scheduler')}
                icon="calendar"
                disabled={!resourceCalendarProps?.isBookable}
                active={selectedIndex === BookingOffCanvasTabEnum.Planning}
                onClick={() => setMode(bookingId ? OffCanvasModeEnum.Update : OffCanvasModeEnum.Create)}
              />
              <JxtTab
                text={t('information')}
                icon="info-circle"
                active={selectedIndex === BookingOffCanvasTabEnum.Information}
                onClick={() => setMode(bookingId ? OffCanvasModeEnum.Update : OffCanvasModeEnum.Create)}
              />
              <JxtTab
                text={t('booking-button')}
                icon="calendar-plus"
                disabled={!resourceCalendarProps?.isBookable}
                active={selectedIndex === BookingOffCanvasTabEnum.Form}
                onClick={onClickBookingTab}
              />
            </Tab.List>
          </>
        ),
      },
      onHide: () => {
        if (mode !== OffCanvasModeEnum.View) {
          if (selectedIndex === BookingOffCanvasTabEnum.Information) {
            createGTMGAEvent('ResourceViewPanel', 'Close', 'Close ResourceViewPanel');
          } else if (selectedIndex === BookingOffCanvasTabEnum.Form) {
            createGTMGAEvent(
              'Booking',
              mode === OffCanvasModeEnum.Update ? 'Update Booking' : 'Create Booking',
              'Cancel'
            );
          }
          if (!directEdit) {
            setMode(OffCanvasModeEnum.View);
            setSelectedIndex(BookingOffCanvasTabEnum.Planning);
          } else {
            resetAndOffCanvas();
          }
        } else {
          resetAndOffCanvas();
        }
      },
      bodyClass: 'overflow-y-auto',
      children: (
        <>
          {mode === OffCanvasModeEnum.View && !!bookingDetails && bookingId && (
            <JxtBookingDetailsContainer bookingDetails={bookingDetails} id={bookingId} />
          )}
          {mode !== OffCanvasModeEnum.View && (
            <Tab.Panels className="h-full">
              <Tab.Panel className="h-full">
                {resourceCalendarProps?.isBookable && (
                  <JxtResourceCalendar
                    {...resourceCalendarProps}
                    locale={locale}
                    onSelect={onSelection}
                    onEventClick={onEventClick}
                    bookSlot={onBookSlot}
                  />
                )}
              </Tab.Panel>
              <Tab.Panel className="h-full">
                {bookingInOffCanvas?.resource.id && (
                  <ResourceInformation resourceId={bookingInOffCanvas?.resource.id} />
                )}
                {resource?.id && <ResourceInformation resourceId={resource?.id} />}
              </Tab.Panel>
              <Tab.Panel className="h-full" unmount={true}>
                {bookingInOffCanvas && mode === OffCanvasModeEnum.Update && (
                  <JxtBookingFormContainer bookingToEdit={bookingInOffCanvas} />
                )}
                {resource?.id && resource.bookable && mode === OffCanvasModeEnum.Create && (
                  <AutoSelectFirstSlotContext.Provider value={autoSelectFirstSlot}>
                    <JxtBookingFormContainer
                      bookingFormPreset={
                        preset
                          ? {
                              from: preset.from,
                              to: preset.to,
                              physicalAttendees: preset.physicalAttendees,
                              organizerId: preset?.organizerId,
                              rrule: preset.rrule,
                              resource: {
                                ...resource,
                                resourceTypeId: resource.resourceType.id,
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                locationId: resource.location.id!,
                                timezone: resource.location.timezone,
                              },
                            }
                          : {
                              resource: {
                                ...resource,
                                resourceTypeId: resource.resourceType.id,
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                locationId: resource.location.id!,
                                timezone: resource.location.timezone,
                              },
                            }
                      }
                      slotToBookInstantly={slotToBookInstantly}
                    />
                  </AutoSelectFirstSlotContext.Provider>
                )}
              </Tab.Panel>
            </Tab.Panels>
          )}
          {!(bookingDetails || resourceCalendarProps) && (
            <div className="absolute top-0 right-0 left-0 bottom-0 backdrop-blur h-full flex flex-1 items-center justify-center">
              <JxtLoader />
            </div>
          )}
          {deleteBookingModal}
        </>
      ),
      tabConfiguration: {
        selectedIndex,
        defaultIndex: selectedIndex,
        onChange: setSelectedIndex,
      },
    }),
    [
      resourceCalendarProps,
      mode,
      directEdit,
      selectedIndex,
      bookingDetails,
      resource,
      deleteBookingModal,
      autoSelectFirstSlot,
    ]
  );

  useEffect(() => {
    if (bookingId || resourceId) {
      offCanvasContext?.setOffCanvasProps(offCanvasConfiguration);
    }
  }, [offCanvasConfiguration]);

  return {
    offCanvasConfiguration,
    bookingInOffCanvas,
    bookingDetails,
    setBookingId,
    goToEditView,
    setMode,
    setContainer,
    setDirectEdit,
    goToResourceCalendarView,
    goToCreateBooking,
    goToResourceInformations,
    goToBookingView,
  };
};
