import { JxtResourcesTypeEnum } from '@jooxter/utils';

export enum JxtResourceIconSizeEnum {
  S = 'S',
  M = 'M',
  L = 'L',
}

export interface IJxtResourceIcon {
  type: JxtResourcesTypeEnum;
  size?: JxtResourceIconSizeEnum;
  fixedWidth?: boolean;
}
