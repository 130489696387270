import {
  JxtCapacityFilter,
  JxtLabelsCheckboxList,
  JxtSpaceTypesCheckboxList,
  JxtSpacesCalendar,
  JxtUserSelect,
  JxtLocationFloorFilter,
  useStore,
  FilterContextLocation,
  useFetchUserById,
  JxtFavoritesOnlyFilter,
} from '@jooxter/core';
import { IFilters } from './types';
import { useTranslation } from 'react-i18next';
import { createGTMGAEvent } from '@jooxter/utils';
import { useShallow } from 'zustand/shallow';

export const Filters = ({ filter, onChangeFilter, user }: IFilters) => {
  const { t } = useTranslation();
  const [resetResourceFilterDate] = useStore(useShallow((state) => [state.resetResourceFilterDate]));
  const { user: organizer } = useFetchUserById(filter.resource.organizerId);

  if (!user || filter.resource.organizerId === null) {
    return null;
  }

  return (
    <>
      <div className="sm:px-6 py-4 border-b border-neutral-20">
        <JxtSpacesCalendar
          scope="spaces"
          user={user}
          value={{ ...filter.resource.date, now: filter.resource.now }}
          onChange={(selectedDate: { from: string; to: string }) => {
            onChangeFilter({ date: selectedDate, now: false });
            createGTMGAEvent('Spaces', 'Global filter', 'Date');
          }}
          onReset={resetResourceFilterDate}
        />
      </div>
      <JxtLocationFloorFilter
        selectedOptions={filter.resource.location}
        onChange={(selectedLocations: FilterContextLocation) => onChangeFilter({ location: selectedLocations })}
      />
      <div className="sm:px-6 py-4 border-b border-neutral-20">
        <JxtFavoritesOnlyFilter
          label={t('resourcesearch-label-favorites-only')}
          defaultValue={filter.resource.favoritesOnly}
          onChange={(checked: boolean) => {
            onChangeFilter({ favoritesOnly: checked });
            createGTMGAEvent('Spaces', 'Global filter', 'Favorite');
          }}
        />
      </div>
      <JxtSpaceTypesCheckboxList
        scope="spaces"
        selectedOptions={filter.resource.resourceTypesIds}
        onChange={(selectedOptions: number[]) => {
          onChangeFilter({ resourceTypesIds: selectedOptions });
          createGTMGAEvent('Spaces', 'Global filter', 'Space Types');
        }}
      />
      <div className="sm:px-6 py-4 border-b border-neutral-20 gap-2 flex flex-col">
        <JxtCapacityFilter
          defaultValue={filter.resource.capacity}
          onChange={(capacity: number | undefined) => {
            onChangeFilter({ capacity });
            createGTMGAEvent('Spaces', 'Global filter', 'Capacity');
          }}
        />
      </div>
      <JxtLabelsCheckboxList
        scope="spaces"
        selectedOptions={filter.resource.labels}
        onChange={(selectedOptions: string[]) => {
          onChangeFilter({ labels: selectedOptions });
          createGTMGAEvent('Spaces', 'Global filter', 'Label');
        }}
      />
      <div className="sm:px-6 py-4 gap-2 flex flex-col border-b border-neutral-20">
        <span className="text-body-s text-neutral-140 font-medium">{t('resourcesearch-label-organizer')}</span>
        <JxtUserSelect
          value={organizer}
          onUserSelect={(selectedUser) => {
            onChangeFilter({ organizerId: selectedUser.id });
            createGTMGAEvent('Spaces', 'Global filter', 'Book for');
          }}
        />
      </div>
    </>
  );
};
