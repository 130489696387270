import { UserWorkplaces } from '@jooxter/api';
import { IJxtWorkplacesListItem } from '@jooxter/ui';
import { useCallback } from 'react';
import { useFetchUser } from '../../queries';
import { useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter } from './useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter';

export const useUsersWorkplacesToIJxtWorkplacesListItemsAdapter = (
  usersWorkplaces?: UserWorkplaces[]
): { adapt: (userWorkplaces: UserWorkplaces) => IJxtWorkplacesListItem } => {
  const { user } = useFetchUser();
  const { adapt: adaptWorkplaces } = useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter(usersWorkplaces);

  const adapt = useCallback(
    (userWorkplaces: UserWorkplaces): IJxtWorkplacesListItem => {
      return {
        workplaces: userWorkplaces.workplaces.map((workplace) => adaptWorkplaces(workplace)),
        user: {
          id: userWorkplaces.id,
          firstname: userWorkplaces.firstname,
          lastname: userWorkplaces.lastname,
          picture: userWorkplaces.picture,

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore The timezone exists here
          timezone: usersWorkplaces?.find((s) => s.id === userWorkplaces.id)?.timezone,
        },
        personal: user?.id === userWorkplaces.id,
      };
    },
    [JSON.stringify(user), JSON.stringify(usersWorkplaces), adaptWorkplaces]
  );

  return { adapt };
};
