import { IJxtBookingInfo } from './types';
import JxtBookingTitle from '../JxtBookingTitle';
import JxtBookingLocation from '../JxtBookingLocation';
import JxtBookingDay from '../JxtBookingDay';
import JxtBookingHours from '../JxtBookingHours';

const JxtBookingInfo = ({ title, location, day, hours }: IJxtBookingInfo) => {
  return (
    <div className="grid grid-cols-2 gap-y-3 gap-x-4">
      <JxtBookingTitle {...title} />
      <JxtBookingLocation {...location} />
      {day && <JxtBookingDay {...day} />}
      {hours && <JxtBookingHours {...hours} />}
    </div>
  );
};

export default JxtBookingInfo;
