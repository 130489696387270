import { CookieService } from '@jooxter/storage';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';

Amplify.configure({
  Geo: {
    LocationService: {
      region: 'eu-west-1',
    },
  },
  Auth: {
    Cognito: {
      userPoolClientId: import.meta.env.VITE_COGNITO_USERPOOL_CLIENT_ID,
      userPoolId: import.meta.env.VITE_COGNITO_USERPOOL_ID,
      loginWith: {
        username: false,
        email: true,
        oauth: {
          domain: import.meta.env.VITE_COGNITO_DOMAIN,
          scopes: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code',
        },
      },
      signUpVerificationMethod: 'code',
    },
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({ ...CookieService.setOptions, expires: CookieService.setOptions.expires?.valueOf() })
);
