import { axiosInstanceV3 } from '../config';
import { AuthenticationSuccess, LoginForm, ResetPasswordToken, UserLoginDetail } from '../model';

export const LoginService = {
  path: 'login',

  /**
   * Return user (login) details
   * @returns null if user is not found.
   */
  getUserLoginDetails(email: string): Promise<UserLoginDetail> {
    const encodedEmail = encodeURIComponent(email);
    const url = `${this.path}/email/${encodedEmail}/check`;

    return axiosInstanceV3.get(url).then((res) => res.data);
  },

  /**
   * Log in the user. After that authentication key will be available to all the application.
   */
  logIn(payload: LoginForm): Promise<string> {
    return this.authenticate(payload).then((response) => {
      if (response.key) {
        return response.key;
      }

      throw new Error('Key not found in login response', {
        cause: { status: 401 },
      });
    });
  },

  /**
   * Try to authenticate a user. Only perfom back end validation,
   * to logIn use the corresponding method.
   */
  authenticate(payload: LoginForm): Promise<AuthenticationSuccess> {
    const url = `${this.path}`;

    return axiosInstanceV3.post(url, payload).then((res) => res.data);
  },

  /**
   * Start the "reset password" procedure.
   * Will generate a new Token to reset the user's password.
   * The user will receive an email with a link to reset it's password.
   * The API returns empty body
   */
  startResetPassword(email: string): Promise<void> {
    const url = `${this.path}/token`;

    return axiosInstanceV3.post(url, { email });
  },

  /**
   * Validate with back office that the reset password token is legit and still usable.
   *
   * @param userId token are linked to a user
   * @param token token to validate
   */
  isTokenValid(userId: number, token: string): Promise<boolean> {
    const url = `${this.path}/${userId}/token/${token}`;

    return axiosInstanceV3
      .get(url)
      .then((res) => res.data)
      .then((res: ResetPasswordToken) => !!res.tokenValid);
  },

  /**
   * Reset the user's password with the specified new value.
   */
  resetPassword(userId: number, token: string, newPassword: string): Promise<Response> {
    const url = `${this.path}/${userId}/token/${token}`;

    return axiosInstanceV3.post(url, { newPassword });
  },
};
