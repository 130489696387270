// eslint-disable-next-line import/named
import { DateTime, DurationObjectUnits } from 'luxon';
import {
  BookingVisibilityEnum,
  ResourceCompressed,
  ResourceStateDtoStatusEnum,
  ResourceTypeDto,
  ResourceTypeDtoMetaTypeEnum,
  WorkplaceCompressedDtoTypeEnum,
} from '@jooxter/api';
import { FieldValues } from 'react-hook-form';
import { Frequency, Weekday } from 'rrule';

export interface IRange {
  from: DateTime;
  to: DateTime;
}

export interface ILuxonGeneratorOptions {
  start?: DateTime;
  end?: DateTime;
  stepAmount?: number;
  stepUnit?: keyof DurationObjectUnits;
}

export enum JxtEventColorsEnum {
  Primary = '#3B86FF',
  Red = '#FF3B3B',
  Blue = '#39D3FD',
  Green = '#20BF55',
  Purple = '#7F3BFF',
  Neutral = '#677489',
  Orange = '#FFB43B',
  Pink = '#F34FEB',
}

//eslint-disable-next-line
export namespace JxtEventColorsEnum {
  export const parse = (color: string) => {
    switch (color) {
      case '#3B86FF': {
        return JxtEventColorsEnum.Primary;
      }
      case '#FF3B3B': {
        return JxtEventColorsEnum.Red;
      }
      case '#39D3FD': {
        return JxtEventColorsEnum.Blue;
      }
      case '#20BF55': {
        return JxtEventColorsEnum.Green;
      }
      case '#7F3BFF': {
        return JxtEventColorsEnum.Purple;
      }
      case '#677489': {
        return JxtEventColorsEnum.Neutral;
      }
      case '#FFB43B': {
        return JxtEventColorsEnum.Orange;
      }
      case '#F34FEB': {
        return JxtEventColorsEnum.Pink;
      }
      default: {
        return JxtEventColorsEnum.Primary;
      }
    }
  };
}

export const JxtEventColorInfos = {
  [JxtEventColorsEnum.Primary]: {
    textColor: 'text-primary-150',
    bgColor: 'bg-primary-20',
    hoverBgColor: 'hover:bg-primary-10',
    borderColor: 'border-primary-20',
    squareColor: 'bg-primary-100',
    translationKey: 'Primary',
  },
  [JxtEventColorsEnum.Red]: {
    textColor: 'text-red-150',
    bgColor: 'bg-red-20',
    hoverBgColor: 'hover:bg-red-10',
    borderColor: 'border-red-20',
    squareColor: 'bg-red-100',
    translationKey: 'Red',
  },
  [JxtEventColorsEnum.Blue]: {
    textColor: 'text-blue-150',
    bgColor: 'bg-blue-20',
    hoverBgColor: 'hover:bg-blue-10',
    borderColor: 'border-blue-20',
    squareColor: 'bg-blue-100',
    translationKey: 'Blue',
  },
  [JxtEventColorsEnum.Green]: {
    textColor: 'text-green-150',
    bgColor: 'bg-green-20',
    hoverBgColor: 'hover:bg-green-10',
    borderColor: 'border-green-20',
    squareColor: 'bg-green-100',
    translationKey: 'Green',
  },
  [JxtEventColorsEnum.Purple]: {
    textColor: 'text-purple-150',
    bgColor: 'bg-purple-20',
    hoverBgColor: 'hover:bg-purple-10',
    borderColor: 'border-purple-20',
    squareColor: 'bg-purple-100',
    translationKey: 'Purple',
  },
  [JxtEventColorsEnum.Neutral]: {
    textColor: 'text-neutral-150',
    bgColor: 'bg-neutral-20',
    hoverBgColor: 'hover:bg-neutral-10',
    borderColor: 'border-neutral-20',
    squareColor: 'bg-neutral-100',
    translationKey: 'Neutral',
  },
  [JxtEventColorsEnum.Orange]: {
    textColor: 'text-orange-150',
    bgColor: 'bg-orange-20',
    hoverBgColor: 'hover:bg-orange-10',
    borderColor: 'border-orange-20',
    squareColor: 'bg-orange-100',
    translationKey: 'Orange',
  },
  [JxtEventColorsEnum.Pink]: {
    textColor: 'text-pink-150',
    bgColor: 'bg-pink-20',
    hoverBgColor: 'hover:bg-pink-10',
    borderColor: 'border-pink-20',
    squareColor: 'bg-pink-100',
    translationKey: 'Pink',
  },
};

export enum JxtBookingParticipationStatusIconEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Unknown = 'UNKNOWN',
  Left = 'LEFT',
  AcceptedRemotely = 'ACCEPTED_REMOTELY',
}

export enum JxtBookingParticipationStatusEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  AcceptedRemotely = 'ACCEPTED_REMOTELY',
}

export const JxtBookingParticipationStatusIconInfo = {
  [JxtBookingParticipationStatusIconEnum.Accepted]: {
    iconName: 'fa-check',
    iconColor: 'text-green-150',
    iconBackgroundColor: 'bg-green-20',
  },
  [JxtBookingParticipationStatusIconEnum.Declined]: {
    iconName: 'fa-times',
    iconColor: 'text-red-150',
    iconBackgroundColor: 'bg-red-20',
  },
  [JxtBookingParticipationStatusIconEnum.Unknown]: {
    iconName: 'fa-question',
    iconColor: 'text-neutral-140',
    iconBackgroundColor: 'bg-neutral-10',
  },
  [JxtBookingParticipationStatusIconEnum.Left]: {
    iconName: 'fa-arrow-right-from-bracket',
    iconColor: 'text-neutral-140',
    iconBackgroundColor: 'bg-neutral-10',
  },
  [JxtBookingParticipationStatusIconEnum.AcceptedRemotely]: {
    iconName: 'fa-check',
    iconColor: 'text-green-150',
    iconBackgroundColor: 'bg-green-20',
  },
};

export enum JxtResourcesTypeEnum {
  BOOTH = 'BOOTH',
  BUBBLE = 'BUBBLE',
  VEHICLE = 'VEHICLE',
  DESK = 'DESK',
  MEETING = 'MEETING',
  OTHER = 'OTHER',
  PARKING = 'PARKING',
  VISIO = 'VISIO',
}

export enum JxtLogisticTimeRuleEnum {
  Sum = 'SUM',
  Max = 'MAX',
}

export const JxtResourcesTypeMapping = {
  [ResourceTypeDtoMetaTypeEnum.Booth]: JxtResourcesTypeEnum.BOOTH,
  [ResourceTypeDtoMetaTypeEnum.Bubble]: JxtResourcesTypeEnum.BUBBLE,
  [ResourceTypeDtoMetaTypeEnum.Vehicle]: JxtResourcesTypeEnum.VEHICLE,
  [ResourceTypeDtoMetaTypeEnum.Desk]: JxtResourcesTypeEnum.DESK,
  [ResourceTypeDtoMetaTypeEnum.Meeting]: JxtResourcesTypeEnum.MEETING,
  [ResourceTypeDtoMetaTypeEnum.Other]: JxtResourcesTypeEnum.OTHER,
  [ResourceTypeDtoMetaTypeEnum.Parking]: JxtResourcesTypeEnum.PARKING,
  [ResourceTypeDtoMetaTypeEnum.Visio]: JxtResourcesTypeEnum.VISIO,
};

export const JxtResourceIconInfo = {
  [JxtResourcesTypeEnum.BOOTH]: 'fa-phone',
  [JxtResourcesTypeEnum.BUBBLE]: 'fa-user-group',
  [JxtResourcesTypeEnum.VEHICLE]: 'fa-car',
  [JxtResourcesTypeEnum.DESK]: 'fa-desktop',
  [JxtResourcesTypeEnum.MEETING]: 'fa-users',
  [JxtResourcesTypeEnum.OTHER]: 'fa-building',
  [JxtResourcesTypeEnum.PARKING]: 'fa-parking',
  [JxtResourcesTypeEnum.VISIO]: 'fa-video',
};

export enum JxtWorkplaceStatusButtonTypeEnum {
  Office = 'OFFICE',
  WorkFromHome = 'WORK_FROM_HOME',
  Mission = 'MISSION',
  Off = 'OFF',
  Unknown = 'UNKNOWN',
}

export enum JxtWorkplaceTypeEnum {
  WorkFromHome = 'WORK_FROM_HOME',
  Office = 'OFFICE',
  Mission = 'MISSION',
  Off = 'OFF',
  Unknown = 'UNKNOWN',
}

export enum JxtWorkplaceTypeWithoutUnknownEnum {
  Office = 'OFFICE',
  WorkFromHome = 'WORK_FROM_HOME',
  Mission = 'MISSION',
  Off = 'OFF',
}

export const JxtWorkplaceTypeMapping = {
  [WorkplaceCompressedDtoTypeEnum.WorkFromHome]: JxtWorkplaceTypeEnum.WorkFromHome,
  [WorkplaceCompressedDtoTypeEnum.Mission]: JxtWorkplaceTypeEnum.Mission,
  [WorkplaceCompressedDtoTypeEnum.Office]: JxtWorkplaceTypeEnum.Office,
  [WorkplaceCompressedDtoTypeEnum.Off]: JxtWorkplaceTypeEnum.Off,
  [WorkplaceCompressedDtoTypeEnum.Unknown]: JxtWorkplaceTypeEnum.Unknown,
};

export enum DataPeriodEnum {
  Am = 'AM',
  Pm = 'PM',
  Day = 'DAY',
  H24 = 'H24',
  H48 = 'H48',
}

export interface IQuickTimeSlot {
  period?: DataPeriodEnum;
  text: string;
  generatedByDefault: boolean;
  start: DateTime;
  end: DateTime;
}

export enum JxtTagColorsEnum {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  RED = 'RED',
  ORANGE = 'ORANGE',
  NEUTRAL = 'NEUTRAL',
}

export const JxtTagColorsInfo = {
  [JxtTagColorsEnum.BLUE]: {
    backgroundColor: 'bg-primary-20',
    textColor: 'text-primary-150',
    crossColor: 'text-primary-100',
    hoverCrossColor: 'hover:text-primary-110',
  },
  [JxtTagColorsEnum.GREEN]: {
    backgroundColor: 'bg-green-20',
    textColor: 'text-green-150',
    crossColor: 'text-green-100',
    hoverCrossColor: 'hover:text-green-110',
  },
  [JxtTagColorsEnum.RED]: {
    backgroundColor: 'bg-red-20',
    textColor: 'text-red-150',
    crossColor: 'text-red-100',
    hoverCrossColor: 'hover:text-red-110',
  },
  [JxtTagColorsEnum.ORANGE]: {
    backgroundColor: 'bg-orange-20',
    textColor: 'text-orange-150',
    crossColor: 'text-orange-100',
    hoverCrossColor: 'hover:text-orange-110',
  },
  [JxtTagColorsEnum.NEUTRAL]: {
    backgroundColor: 'bg-neutral-10',
    textColor: 'text-neutral-120',
    crossColor: 'text-neutral-100',
    hoverCrossColor: 'hover:text-neutral-110',
  },
};

export enum OffCanvasModeEnum {
  View = 'VIEW',
  Update = 'UPDATE',
  Create = 'CREATE',
}

export enum BookingOffCanvasTabEnum {
  Planning = 0,
  Information = 1,
  Form = 2,
}

export enum ColleaguesOffCanvasTabEnum {
  ALL = 0,
  OFFICE = 1,
  WORK_FROM_HOME = 2,
  MISSION = 3,
  OFF = 4,
  UNKNOWN = 5,
}

export enum JxtBookingVisibilityEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export interface IDateTime {
  date: DateTime;
  time: string;
}

export interface IDateTimeForm extends FieldValues {
  start: IDateTime;
  end: IDateTime;
}

export interface IBookingForm extends FieldValues {
  summary: string;
  description?: string | null;
  organizer: {
    id: number;
    firstname: string;
    lastname: string;
  };
  resource: ResourceCompressed;
  start: IDateTime;
  end: IDateTime;
  color: string;
  visibility: BookingVisibilityEnum;
  options?: {
    [key: string]: {
      activated: boolean;
      value: string;
    };
  };
}

export interface IExceptionalOpeningHoursForm extends FieldValues {
  title: string;
  start: IDateTime;
  end: IDateTime;
  type: ExceptionalOpeningHoursTypeEnum;
}

export enum BookingRecurrence {
  Once = 'booking.recurrence.once',
  BusinessDays = 'booking.recurrence.businessdays',
  Weekly = 'booking.recurrence.weekly',
  OnceInTwoWeeks = 'booking.recurrence.onceintwoweeks',
  Monthly = 'booking.recurrence.monthly',
  Customize = 'booking.recurrence.customize',
}

export type TRecurrenceOption = {
  value: BookingRecurrence;
  label: string;
};

export enum OccurrenceMode {
  Max = 'MAX',
  Custom = 'CUSTOM',
  Until = 'UNTIL',
}

export enum MonthlyMode {
  MonthDay = 'MONTHDAY',
  WeekDay = 'WEEKDAY',
}

export const MINIMUM_OCCURRENCE = 1;
export const MAXIMUM_OCCURRENCE = 720;
export const DEFAULT_OCCURRENCE = 10;

export interface RruleConstructorObject {
  bymonthday?: number;
  freq: Frequency;
  count?: number | null;
  byweekday?: Weekday[];
  dtstart?: Date;
  until?: Date | null;
  interval?: number;
  bysetpos?: number;
}

export enum RecurrentBookingClosingResultEnum {
  ONLY_SELF = 'Only self',
  FOLLOWING = 'Self and following occurrences',
}

export enum JxtResourceStateEnum {
  BUSY = ResourceStateDtoStatusEnum.Busy,
  FREE = ResourceStateDtoStatusEnum.Free,
  CLOSED = ResourceStateDtoStatusEnum.Closed,
  UNKNOWN = ResourceStateDtoStatusEnum.Unknown,
}

export const JxtResourceStatusInfos = {
  [ResourceStateDtoStatusEnum.Busy]: {
    color: JxtTagColorsEnum.RED,
    state: JxtResourceStateEnum.BUSY,
  },
  [ResourceStateDtoStatusEnum.Free]: {
    color: JxtTagColorsEnum.GREEN,
    state: JxtResourceStateEnum.FREE,
  },
  [ResourceStateDtoStatusEnum.Closed]: {
    color: JxtTagColorsEnum.NEUTRAL,
    state: JxtResourceStateEnum.CLOSED,
  },
  [ResourceStateDtoStatusEnum.Unknown]: {
    color: JxtTagColorsEnum.NEUTRAL,
    state: JxtResourceStateEnum.UNKNOWN,
  },
};

export enum ExceptionalOpeningHoursTypeEnum {
  Opening = 'Opening',
  Closing = 'Closing',
}

export enum JxtMeetUpBookingStatusEnum {
  ALREADY_BOOKED = 'ALREADY_BOOKED',
  SHOULD_BE_BOOKED = 'SHOULD_BE_BOOKED',
  BOOKED = 'BOOKED',
  ERROR = 'ERROR',
}

export interface IJxtWorkplaceDay {
  morning: IJxtWorkplaceHalfDay;
  afternoon: IJxtWorkplaceHalfDay;
  date: DateTime;
}

export interface IJxtWorkplaceHalfDay {
  id?: number;
  type: JxtWorkplaceTypeEnum;
  locationId?: number;
  locationName?: string;
  capabilities: {
    update?: boolean;
    cancel?: boolean;
    create?: boolean;
  };
}

export type TDeskOrParkingResourceTypeDto = Omit<ResourceTypeDto, 'metaType'> & {
  metaType: ResourceTypeDtoMetaTypeEnum.Desk | ResourceTypeDtoMetaTypeEnum.Parking;
};
