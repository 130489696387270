import clsx from 'clsx';
import { getJxtContainerClass } from './styles';
import { ContainerPaddingEnum, ContainerSizeEnum, IJxtContainer } from './types';
import JxtButton from '../JxtButton';

const JxtContainer = ({
  id,
  title,
  subtitle,
  button,
  padding = ContainerPaddingEnum.Regular,
  size = ContainerSizeEnum.Medium,
  children,
  className,
}: IJxtContainer) => {
  const classes = getJxtContainerClass({ size });

  return (
    <div id={id} className={clsx(classes, className)}>
      {(title || button || subtitle) && (
        <header className={size === ContainerSizeEnum.Medium ? 'px-6 pb-6' : 'px-4 pb-4'}>
          <div className="flex justify-between items-center">
            {title && (
              <p
                className={clsx(
                  size === ContainerSizeEnum.Medium ? 'text-title-l font-semibold' : 'text-title-m font-medium',
                  'text-neutral-140 '
                )}
              >
                {title}
              </p>
            )}
            {button && (
              <div className="ml-auto">
                <JxtButton {...button}>{button?.children}</JxtButton>
              </div>
            )}
          </div>
          {subtitle && (
            <p className={clsx('text-body-s text-neutral-120', title || button ? 'pt-2' : '')}>{subtitle}</p>
          )}
        </header>
      )}
      <div
        className={clsx(
          'flex grow min-h-0',
          padding === ContainerPaddingEnum.Regular ? (size === ContainerSizeEnum.Medium ? 'px-6' : 'px-4') : ''
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default JxtContainer;
