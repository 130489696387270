import clsx from 'clsx';
import { JxtShareWorkplaceRangeSizeEnum } from './types';

export interface IGetJxtShareWorkplaceRangeClass {
  size: JxtShareWorkplaceRangeSizeEnum;
}

export const getJxtShareWorkplaceRangeClass = ({ size }: IGetJxtShareWorkplaceRangeClass) => {
  const defaultClasses = 'h-7 text-body-s text-neutral-140 font-medium bg-neutral-10 hover:bg-neutral-20 rounded-lg';

  const sizeClasses = {
    Regular: 'w-full',
    Small: 'w-10',
  };

  const classes = clsx(defaultClasses, sizeClasses[size]);

  return classes;
};
