import { User } from '@jooxter/api';

export const setupJimo = (user: User): void => {
  const { email, firstname, lastname, id, location, corporation, timezone, lang } = user;
  window.jimo.push(['set', 'user:email', [email]]);
  window.jimo.push(['set', 'user:name', [`${firstname} ${lastname}`]]);

  window.jimo.push(['set', 'core:language', [lang]]);

  if (id) {
    window.jimo.push(['set', 'user:id', [id?.toString()]]);
    window['jimo'].push(['do', 'identify', [id?.toString()]]);
  }

  // each attribute must be a string, number or boolean
  window.jimo.push([
    'set',
    'user:attributes',
    [
      {
        location: location?.name,
        corporation: corporation?.name,
        timezone,
        lang,
      },
    ],
  ]);
  // if possible call this only once
  // if window.jimo is not null/undefined, Array.isArray(window.jimo) is true => jimo not initialized yet
  if (Array.isArray(window.jimo) && window.jimoInit) {
    window.jimoInit();
    // call kill on logout
  }
};
