import deLocale from '@fullcalendar/core/locales/de';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import itLocale from '@fullcalendar/core/locales/it';
import ptLocale from '@fullcalendar/core/locales/pt';
// eslint-disable-next-line import/named
import { LocaleInput } from '@fullcalendar/core';
import { useMemo, useState } from 'react';

export const useFullCalendarLocale = (locale: string) => {
  const [locales] = useState([frLocale, deLocale, itLocale, esLocale, ptLocale]);

  const localeInput: LocaleInput | undefined = useMemo(() => {
    if (locale.startsWith('fr')) {
      return frLocale;
    } else if (locale.startsWith('en')) {
      return undefined;
    } else if (locale.startsWith('de')) {
      return deLocale;
    } else if (locale.startsWith('it')) {
      return itLocale;
    } else if (locale.startsWith('es')) {
      return esLocale;
    } else if (locale.startsWith('pt')) {
      return ptLocale;
    } else {
      undefined;
    }
  }, [locale]);

  return { locales, locale: localeInput };
};
