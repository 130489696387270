import { JxtWorkplaceTypeEnum } from '@jooxter/utils';
import { clsx } from 'clsx';
import { JxtWorkplacesIconInfo } from './constants';

export interface IGetJxtWorkplacesIconClass {
  morningType: JxtWorkplaceTypeEnum;
  afternoonType: JxtWorkplaceTypeEnum;
}

export const getJxtWorkplacesIconClass = ({ morningType, afternoonType }: IGetJxtWorkplacesIconClass) => {
  const defaultClasses = 'size-9 shrink-0';

  const singleIconClasses =
    morningType === afternoonType
      ? clsx(JxtWorkplacesIconInfo[morningType].iconBackgroundColor, 'rounded-lg flex items-center justify-center')
      : 'relative';

  const classes = clsx(defaultClasses, singleIconClasses);

  return classes;
};
