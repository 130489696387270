// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';
import { fromISO } from '@jooxter/utils';
import { DateTime } from 'luxon';

const INVALID_DATE_TOKEN = 'Invalid Date';
export const getSelectedEventId = (events: EventInput[], date?: DateTime): number | undefined => {
  if (date) {
    const event = events.find((e) => {
      const start =
        typeof e.start === 'string'
          ? fromISO(e.start)
          : e.start instanceof Date
            ? DateTime.fromJSDate(e.start)
            : INVALID_DATE_TOKEN;

      if (start === INVALID_DATE_TOKEN) {
        return false;
      }

      return start.startOf('day').equals(date.startOf('day'));
    });

    if (!event?.id) {
      return;
    }

    return parseInt(event.id, 10);
  }

  const event = events.sort((a, b) => {
    if (a.start && b.start) {
      const AstartDate =
        typeof a.start === 'string'
          ? fromISO(a.start)
          : a.start instanceof Date
            ? DateTime.fromJSDate(a.start)
            : 'Invalid Date';
      const BstartDate =
        typeof b.start === 'string'
          ? fromISO(b.start)
          : b.start instanceof Date
            ? DateTime.fromJSDate(b.start)
            : INVALID_DATE_TOKEN;

      return AstartDate < BstartDate ? -1 : 1;
    }

    return 0;
  })[0];

  if (event?.id) {
    return parseInt(event.id, 10);
  }
};
