import { ILocationOptions, Location, LocationMapDto, LocationService } from '@jooxter/api';
import { keepPreviousData, useQueries, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { LocationQueryKeys } from '../queryKeys';
import { LocationStaleTimeEnum } from '../staleTimes';

export const useFetchLocation = (
  id?: number,
  options?: { retry?: boolean | number }
): { location?: Location | null; isError: boolean; isFetching: boolean } => {
  const fetchLocation = () => (id ? LocationService.getLocation(id) : Promise.resolve(null));

  const {
    data: location,
    isError,
    isFetching,
  } = useQuery({
    refetchInterval: 30000,
    queryKey: [LocationQueryKeys.GetLocation, id, options?.retry],
    queryFn: () => fetchLocation(),
    placeholderData: keepPreviousData,
    staleTime: LocationStaleTimeEnum.GetLocation,
    retry: options?.retry,
  });

  return { location, isError, isFetching };
};

export const useFetchLocations = (ids: number[]): { locations: Location[]; isPending: boolean } => {
  const fetchLocation = (id: number) => LocationService.getLocation(id);

  const { data: locations, isPending } = useQueries({
    queries: ids.map((id: number) => {
      return {
        queryKey: [LocationQueryKeys.GetLocation, id],
        queryFn: () => fetchLocation(id),
        placeholderData: keepPreviousData,
        staleTime: LocationStaleTimeEnum.GetLocation,
        enabled: !!id,
      };
    }),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((r): r is Location => !!r),
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  return { locations, isPending };
};

export const useSearchLocationMap = (options: ILocationOptions): { locations: LocationMapDto[] | undefined } => {
  const searchLocations = useCallback(() => LocationService.searchLocationMap(options), [options]);

  const { data: locations } = useQuery({
    queryKey: [LocationQueryKeys.SearchLocationMap, options],
    queryFn: searchLocations,
    placeholderData: keepPreviousData,
    staleTime: LocationStaleTimeEnum.SearchLocationMap,
  });

  return { locations };
};
