import { useEffect, useState } from "react";
import { useFetchUser } from "..";

export const useCanAccessCollaborators = () => {
  const { user } = useFetchUser();
  const [canAccessCollaborators, setCanAccessCollaborators] = useState<boolean | undefined>();

  useEffect(() => {
    if (!user) {
      return;
    }

    setCanAccessCollaborators(user.capabilities?.features?.collaborators);
  }, [user?.id]);

  return { canAccessCollaborators };
}
