import { JxtSelect } from '@jooxter/ui';
import { IJxtTimezoneSelect, TimezoneOption } from './types';
//eslint-disable-next-line import/named
import { SingleValue } from 'react-select';
import timezones from '../../assets/data/timezones.json';
import { useTranslation } from 'react-i18next';

export const JxtTimezoneSelect = ({ onTimezoneSelect, defaultValue, value, label, helperText }: IJxtTimezoneSelect) => {
  const options: TimezoneOption[] = timezones.map((timezone) => ({
    value: timezone,
    label: timezone,
  }));
  const { t } = useTranslation();

  const handleTimezoneSelect = (timezone: SingleValue<TimezoneOption>) => {
    if (timezone?.value && onTimezoneSelect) {
      onTimezoneSelect(timezone.value);
    }
  };

  return (
    <JxtSelect
      value={value}
      defaultValue={defaultValue ?? options[0]}
      label={label}
      helperText={helperText}
      isSearchable={true}
      options={options}
      handleSelection={handleTimezoneSelect}
      noOptionsMessage={t<string>('user-update-profile-label-timezone.no-options')}
    />
  );
};
