import { BookingCompressed, ResourceStateDto } from '@jooxter/api';
import { RefObject } from 'react';

export interface IJxtFloorPlan {
  backgroundImage?: HTMLImageElement;
  width?: number;
  height?: number;
  pictos?: IPictoConfig[];
  onClick?: (id: number) => void;
}

export interface IPictoConfig {
  id: number;
  size: number;
  x: number;
  y: number;
  userInitials?: string;
  firstName?: string;
  lastName?: string;
  url?: string;
  type: PictoTypeEnum;
  title?: string;
  subtitle?: string;
  showMapIcon: boolean;
}

export interface IJxtPictoGroup {
  p: IPictoConfig;
  onPictoClick: (id: number) => void;
  parent: RefObject<SVGSVGElement | null>;
  computedRatio?: number;
}

export enum PictoTypeEnum {
  User = 'User',
  Booking = 'Booking',
  Resource = 'Resource',
}

export type Dimensions = {
  width: number;
  height: number;
};

export type ScalingObject = {
  scaleX: number;
  scaleY: number;
};

export interface ResourceNode {
  id: string;
  name: string;
}

// in TS v3.5,  ResourceNodeCasted extends Omit<ResourceNode, "id"
export interface ResourceNodeCasted extends Omit<ResourceNode, 'id'> {
  id: number;
}

export interface DeviceNode extends ResourceNode {
  capacity: number;
  ibeaconIdentifier: string;
  idResource: number;
  technicalName: string;
  token: string;
}

/**
 * received from the API
 */
export type Matrix = Array<
  Array<
    | 0
    | (
        | { walkable: false } // not used anymore
        | { stair: { min: number; max: number } } // not used anymore
        | { device: DeviceNode }
        | { resource: ResourceNode }
      )
  >
>;

export interface Position {
  col: number;
  row: number;
}

export interface IFloorPlanResource {
  id: number;
  bookings?: BookingCompressed[];
  name: string;
  bookable?: boolean;
  capacity: number;
  resourceType: {
    mapIconSize: number;
    showMapIcon: boolean;
    allowOverlap: boolean;
  };
  state?: {
    pictogram?: string;
    occupantsCount?: number;
  };
}

export interface IFloorPlanBooking {
  id: number;
  resource: {
    id: number;
    resourceType: {
      mapIconSize: number;
    };
    state?: ResourceStateDto;
  };
  organizer: {
    firstname: string;
    lastname: string;
    picture?: string;
  };
}

export type THandle = {
  id: number;
  locate: () => void;
  fade: () => void;
};
