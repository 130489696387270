import { useCallback } from 'react';
import { useCreateWorkplace, useCreateWorkplaces, useDeleteWorkplace } from '../../mutations/workplace';
import toast from 'react-hot-toast';
import { JxtToast, JxtToastVariantEnum } from '@jooxter/ui';
import { AxiosError } from 'axios';

export const useWorkplaceActions = () => {
  const { mutate: mutationDelete } = useDeleteWorkplace();
  const onCreateWorkplaceError = (error: AxiosError) => {
    const response = error.response?.data as { error: string; description: string }[];
    if (response.length > 0) {
      toast.custom(
        (newToast) => (
          <JxtToast
            onHide={() => toast.remove(newToast.id)}
            title={response[0].error}
            subtitle={response[0].description}
            variant={JxtToastVariantEnum.Failure}
          />
        ),
        {
          position: 'bottom-left',
        }
      );
    }
  };

  const deleteWorkplace = useCallback(
    ({ userId, id }: { userId: number; id: number }) => {
      mutationDelete({ userId, id });
    },
    [mutationDelete]
  );

  const deleteWorkplaces = useCallback(
    ({ userId, ids }: { userId: number; ids: number[] }) => {
      ids.forEach(id => {
        mutationDelete({ userId, id });
      });
    },
    [mutationDelete]
  );

  const postWorkplace = useCreateWorkplace(undefined, onCreateWorkplaceError);
  const postWorkplaces = useCreateWorkplaces(undefined, onCreateWorkplaceError);

  return { delete: deleteWorkplace, create: postWorkplace, createMany: postWorkplaces, deleteMany: deleteWorkplaces };
};
