import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { getTabClasses } from './styles';
import { IJxtTab } from './types';

const JxtTab = ({ text, icon = '', disabled = false, active = false, onClick }: IJxtTab) => {
  const classes = getTabClasses(disabled, active);
  return (
    <Tab
      className={classes}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (disabled) {
          e.preventDefault();
          e.stopPropagation();
        } else if (onClick) {
          onClick();
        }
      }}
    >
      {!!icon && (
        <>
          <i className={clsx('fas fa-fw fa-lg mb-2', `fa-${icon}`)} />
          <br />
        </>
      )}
      {text}
    </Tab>
  );
};

export default JxtTab;
