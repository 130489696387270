import { IAuthService } from './types';
import { fetchAuthSession } from 'aws-amplify/auth';

export class AuthV4Service implements IAuthService {
  static #instance: IAuthService;

  private constructor() {
    // private constructor to allow singleton pattern
  }

  public static getInstance(): IAuthService {
    if (!AuthV4Service.#instance) {
      AuthV4Service.#instance = new AuthV4Service();
    }

    return AuthV4Service.#instance;
  }

  public async getToken(): Promise<string | undefined> {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken?.toString();

    return accessToken;
  }
}
