import { DataPeriodEnum } from '@jooxter/utils';
import clsx from 'clsx';
import { useContext } from 'react';
import { WorkplaceCalendarContext } from '../JxtPaintCalendar/context';
import JxtWorkplaceCalendarDayHeader from '../JxtWorkplaceCalendarDayHeader';
import JxtWorkplaceCalendarUnit from '../JxtWorkplaceCalendarUnit';
import { IJxtShareWorkplaceDay } from './types';

const JxtShareWorkplaceDay = ({ header, morning, afternoon, dataDate = '', isCurrent }: IJxtShareWorkplaceDay) => {
  const baseClasses = 'flex flex-col gap-px bg-neutral-20 border border-neutral-20 rounded-lg overflow-hidden';
  const { callbacks } = useContext(WorkplaceCalendarContext);

  if (!dataDate) {
    console.warn('No data date for the day, not displaying cell');
    return null;
  }

  return (
    <div data-date={dataDate} className={clsx(baseClasses, isCurrent ? 'current' : '')}>
      <JxtWorkplaceCalendarDayHeader
        onClick={() => callbacks.onJxtWorkplaceCalendarDayHeaderClick(dataDate)}
        {...header}
      />
      <JxtWorkplaceCalendarUnit
        onClick={() => callbacks.onJxtWorkplaceCalendarUnitClick(dataDate, DataPeriodEnum.Am)}
        {...morning}
      />
      <JxtWorkplaceCalendarUnit
        onClick={() => callbacks.onJxtWorkplaceCalendarUnitClick(dataDate, DataPeriodEnum.Pm)}
        {...afternoon}
      />
    </div>
  );
};

export default JxtShareWorkplaceDay;
