import { JxtWorkplaceTypeEnum } from "@jooxter/utils";

export const JxtWorkplaceTypeClasses = {
  [JxtWorkplaceTypeEnum.Office]: {
    iconName: 'fa-building',
    colors: 'text-green-150 bg-green-20 hover:bg-green-30',
    disabledColors: 'text-green-40 bg-green-10',
  },
  [JxtWorkplaceTypeEnum.WorkFromHome]: {
    iconName: 'fa-house',
    colors: 'text-primary-150 bg-primary-20 hover:bg-primary-30',
    disabledColors: 'text-primary-40 bg-primary-10',
  },
  [JxtWorkplaceTypeEnum.Mission]: {
    iconName: 'fa-car',
    colors: 'text-orange-150 bg-orange-20 hover:bg-orange-30',
    disabledColors: 'text-orange-40 bg-orange-10',
  },
  [JxtWorkplaceTypeEnum.Off]: {
    iconName: 'fa-times-circle',
    colors: 'text-red-150 bg-red-20 hover:bg-red-30',
    disabledColors: 'text-red-40 bg-red-10',
  },
  [JxtWorkplaceTypeEnum.Unknown]: {
    iconName: 'fa-circle-question',
    colors: 'text-neutral-60 bg-neutral-10 hover:bg-neutral-20',
    disabledColors: 'text-neutral-20 bg-white',
  },
};
