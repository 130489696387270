import JxtIconButton from '../JxtIconButton';
import { JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from '../JxtIconButton/types';
import { IJxtLocateButton } from './types';

const JxtLocateButton = ({
  onClick,
  size = JxtIconButtonSizeEnum.R,
  disabled,
  type = JxtIconButtonTypeEnum.Light,
  disabledMessage,
  title,
}: IJxtLocateButton) => {
  return (
    <JxtIconButton
      disabled={disabled}
      title={disabled ? disabledMessage : title}
      onClick={onClick}
      type={type}
      size={size}
      icon="location-dot"
    />
  );
};

export default JxtLocateButton;
