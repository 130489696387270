import { Routes, Route, Navigate } from 'react-router';
import {
  BookingForm,
  useFetchUser,
  BAD_REQUEST_ROUTE,
  BOOKINGS_MINE_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  NOT_AUTHORIZED_ROUTE,
  NOT_FOUND_ROUTE,
  PROFILE_ROUTE,
  RTSM_ROUTE,
  RTSM_ROUTE_USER_HASH,
  SCHEDULER_ROUTE,
  SEARCH_SPACE_ROUTE,
  USER_PLANNING_ROUTE,
  VERSION_ROUTE,
  ONBOARDING_ROUTE_START,
  ONBOARDING_ROUTE_WORK_HOURS,
  ONBOARDING_ROUTE_DESK,
  ONBOARDING_ROUTE_COLLEAGUES,
  BOOKINGS_VIEW_ROUTE,
  ONBOARDING_ROUTE_PROFILE_PICTURE,
  ONBOARDING_ROUTE_NOTIFICATIONS,
  ONBOARDING_ROUTE_FINALIZE,
  MANAGE_SPACES_ROUTE,
  TOGETHER_ROUTE,
  RESET_PASSWORD_WITH_TOKEN_ROUTE,
  RESET_PASSWORD_WITH_CODE_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  useStore,
  AuthV4Service,
  AuthV3Service,
  RTSM_ROUTE_USER_V4,
  RTSM_ROUTE_USER_CODE_V4,
  GuardedRoute,
  SAML_ROUTE,
} from '@jooxter/core';
import { UserContainer } from '../pages/UserProfile';
import { Home } from '../pages/Home';
import { ForgotPassword } from '../pages/Login/ForgotPassword';
import { LoginPassword } from '../pages/Login/LoginPassword';
import { LoginEmail } from '../pages/Login';
import { LoginFirst } from '../pages/Login/LoginFirst';
import { ResetPassword } from '../pages/Login/ResetPassword';
import { Saml } from '../pages/Login/Saml';
import { BackgroundLayout, MenuLayout } from '../layouts';
import { ErrorEnum, JxtError, JxtLoader, JxtLoginLayout } from '@jooxter/ui';
import { JSX, useEffect, useLayoutEffect, useState } from 'react';
import { Booking } from '../pages/Booking';
import { Resource } from '../pages/Resource';
import { OnboardingDoneGuard } from './OnboardingDoneGuard';
import { Spaces } from '../pages/Spaces';
import { useQueryClient } from '@tanstack/react-query';
import { MyBookings } from '../pages/MyBookings';
import { Colleagues } from '../pages/Colleagues';
import { Version } from '../pages/Version';
import { RtsmGuard } from './RtsmGuard';
import { OnboardingStart } from '../pages/Onboarding/Start';
import { OnboardingNotDoneGuard } from './OnboardingNotDoneGuard';
import { OnboardingWorkHours } from '../pages/Onboarding/WorkHours';
import { OnboardingDesk } from '../pages/Onboarding/Desk';
import { OnboardingColleagues } from '../pages/Onboarding/Colleagues';
import { OnboardingProfilePicture } from '../pages/Onboarding/ProfilePicture';
import { OnboardingNotifications } from '../pages/Onboarding/Notifications';
import { OnboardingFinalize } from '../pages/Onboarding/Finalize';
import { ManageMyResources } from '../pages/ManageMyResources';
import { MeetUp } from '../pages/MeetUp';
import { getThemeColorHex } from '@jooxter/tailwind-config';
import { RtsmGuardV4 } from './RtsmGuardV4';
import { useShallow } from 'zustand/shallow';

export const AppRoutes = (): JSX.Element => {
  const { user } = useFetchUser();
  const [token, setToken] = useStore(useShallow((state) => [state.token, state.setToken]));
  const queryClient = useQueryClient();
  const features = user?.capabilities?.features;
  const hasCollaborators = features?.collaborators;
  const manageSpaces = features?.resourceManager;
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      queryClient.removeQueries();
    }
  }, [token, queryClient]);

  useLayoutEffect(() => {
    Promise.all([AuthV4Service.getInstance().getToken(), AuthV3Service.getInstance().getToken()]).then(([v4t, v3t]) => {
      if (v4t) {
        setToken(v4t);
      }
      if (v3t) {
        setToken(v3t);
      }
      setReady(true);
    });

    // run only on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <JxtLoader size={65} color={getThemeColorHex('red100')} />
      </div>
    );
  }

  if (import.meta.env.DEV) {
    return (
      <Routes>
        {/* Unguarded Routes */}
        <Route path={RESET_PASSWORD_WITH_TOKEN_ROUTE} element={<JxtLoginLayout />}>
          <Route path="" element={<ResetPassword />} />
        </Route>
        <Route path={RESET_PASSWORD_WITH_CODE_ROUTE} element={<JxtLoginLayout />}>
          <Route path="" element={<ResetPassword />} />
        </Route>
        <Route element={<BackgroundLayout className="justify-center items-center" />}>
          <Route path={VERSION_ROUTE} element={<Version />} />
          <Route path={RTSM_ROUTE_USER_HASH} element={<RtsmGuard />} />
          <Route path={RTSM_ROUTE_USER_CODE_V4} element={<RtsmGuardV4 />} />
          <Route path={NOT_FOUND_ROUTE} element={<JxtError code={ErrorEnum.NOT_FOUND} />} />
          <Route path={BAD_REQUEST_ROUTE} element={<JxtError code={ErrorEnum.BAD_REQUEST} />} />
          <Route path={NOT_AUTHORIZED_ROUTE} element={<JxtError code={ErrorEnum.NOT_AUTHORIZED} />} />
        </Route>

        {/* Non-Authenticated Routes: accessible only if user in not authenticated */}
        <Route element={<GuardedRoute isRouteAccessible={!token} redirectRoute={HOME_ROUTE} />}>
          <Route path="/" element={<JxtLoginLayout />}>
            <Route path="" element={<LoginEmail />} />
            <Route path="login" element={<LoginPassword />} />
            <Route path="login/first" element={<LoginFirst />} />
            <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
            <Route path={SAML_ROUTE} element={<Saml />} />
          </Route>
        </Route>
        {/* Authenticated Routes */}
        <Route element={<GuardedRoute isRouteAccessible={!!token} redirectRoute={LOGIN_ROUTE} />}>
          <Route element={<OnboardingDoneGuard />}>
            <Route element={<BackgroundLayout className="justify-center items-center" />}>
              <Route path={RTSM_ROUTE} element={<RtsmGuard />} />
              <Route path={RTSM_ROUTE_USER_V4} element={<RtsmGuardV4 />} />
            </Route>
            <Route element={<MenuLayout />}>
              <Route element={<GuardedRoute isRouteAccessible={hasCollaborators} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={HOME_ROUTE} element={<Home />} />
              </Route>
              <Route path={SEARCH_SPACE_ROUTE} element={<Spaces />} />
              <Route element={<GuardedRoute isRouteAccessible={false} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={SCHEDULER_ROUTE} element={<Spaces />} />
              </Route>
              <Route path={BOOKINGS_MINE_ROUTE} element={<MyBookings />} />
              <Route element={<GuardedRoute isRouteAccessible={hasCollaborators} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={USER_PLANNING_ROUTE} element={<Colleagues />} />
              </Route>
              <Route element={<GuardedRoute isRouteAccessible={manageSpaces} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={MANAGE_SPACES_ROUTE} element={<ManageMyResources />} />
              </Route>
              <Route path={PROFILE_ROUTE} element={<UserContainer />} />
              <Route path="/bookings/create" element={<BookingForm />} />
              <Route path={BOOKINGS_VIEW_ROUTE} element={<Booking />} />
              <Route path="/bookings/:bookingId/edit" element={<BookingForm />} />
              <Route path="/resources/:resourceId" element={<Resource />} />
              <Route path={TOGETHER_ROUTE} element={<MeetUp />} />
            </Route>
          </Route>
          <Route element={<OnboardingNotDoneGuard />}>
            <Route element={<BackgroundLayout />}>
              <Route path={ONBOARDING_ROUTE_START} element={<OnboardingStart />} />
              <Route path={ONBOARDING_ROUTE_WORK_HOURS} element={<OnboardingWorkHours />} />
              <Route path={ONBOARDING_ROUTE_DESK} element={<OnboardingDesk />} />
              <Route path={ONBOARDING_ROUTE_COLLEAGUES} element={<OnboardingColleagues />} />
              <Route path={ONBOARDING_ROUTE_PROFILE_PICTURE} element={<OnboardingProfilePicture />} />
              <Route path={ONBOARDING_ROUTE_NOTIFICATIONS} element={<OnboardingNotifications />} />
              <Route path={ONBOARDING_ROUTE_FINALIZE} element={<OnboardingFinalize />} />
            </Route>
          </Route>
        </Route>
        {/* Not found Route */}
        <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
      </Routes>
    );
  }

  if (import.meta.env.VITE_ENV_NAME === 'staging') {
    return (
      <Routes>
        {/* Unguarded Routes */}
        <Route path={RESET_PASSWORD_WITH_TOKEN_ROUTE} element={<JxtLoginLayout />}>
          <Route path="" element={<ResetPassword />} />
        </Route>
        <Route path={RESET_PASSWORD_WITH_CODE_ROUTE} element={<JxtLoginLayout />}>
          <Route path="" element={<ResetPassword />} />
        </Route>
        <Route element={<BackgroundLayout className="justify-center items-center" />}>
          <Route path={VERSION_ROUTE} element={<Version />} />
          <Route path={RTSM_ROUTE_USER_HASH} element={<RtsmGuard />} />
          <Route path={RTSM_ROUTE_USER_CODE_V4} element={<RtsmGuardV4 />} />
          <Route path={NOT_FOUND_ROUTE} element={<JxtError code={ErrorEnum.NOT_FOUND} />} />
          <Route path={BAD_REQUEST_ROUTE} element={<JxtError code={ErrorEnum.BAD_REQUEST} />} />
          <Route path={NOT_AUTHORIZED_ROUTE} element={<JxtError code={ErrorEnum.NOT_AUTHORIZED} />} />
        </Route>
        {/* Non-Authenticated Routes: accessible only if user in not authenticated */}
        <Route element={<GuardedRoute isRouteAccessible={!token} redirectRoute={HOME_ROUTE} />}>
          <Route path="/" element={<JxtLoginLayout />}>
            <Route path="" element={<LoginEmail />} />
            <Route path="login" element={<LoginPassword />} />
            <Route path="login/first" element={<LoginFirst />} />
            <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
            <Route path={SAML_ROUTE} element={<Saml />} />
          </Route>
        </Route>
        {/* Authenticated Routes */}
        <Route element={<GuardedRoute isRouteAccessible={!!token} redirectRoute={LOGIN_ROUTE} />}>
          <Route element={<OnboardingDoneGuard />}>
            <Route element={<BackgroundLayout className="justify-center items-center" />}>
              <Route path={RTSM_ROUTE} element={<RtsmGuard />} />
              <Route path={RTSM_ROUTE_USER_V4} element={<RtsmGuardV4 />} />
            </Route>
            <Route element={<MenuLayout />}>
              <Route element={<GuardedRoute isRouteAccessible={hasCollaborators} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={HOME_ROUTE} element={<Home />} />
              </Route>
              <Route path={SEARCH_SPACE_ROUTE} element={<Spaces />} />
              <Route element={<GuardedRoute isRouteAccessible={false} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={SCHEDULER_ROUTE} element={<Spaces />} />
              </Route>
              <Route path={BOOKINGS_MINE_ROUTE} element={<MyBookings />} />
              <Route element={<GuardedRoute isRouteAccessible={hasCollaborators} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={USER_PLANNING_ROUTE} element={<Colleagues />} />
              </Route>
              <Route element={<GuardedRoute isRouteAccessible={manageSpaces} redirectRoute={SEARCH_SPACE_ROUTE} />}>
                <Route path={MANAGE_SPACES_ROUTE} element={<ManageMyResources />} />
              </Route>
              <Route path={PROFILE_ROUTE} element={<UserContainer />} />
              <Route path="/bookings/create" element={<BookingForm />} />
              <Route path={BOOKINGS_VIEW_ROUTE} element={<Booking />} />
              <Route path={TOGETHER_ROUTE} element={<MeetUp />} />
            </Route>
          </Route>
          <Route element={<OnboardingNotDoneGuard />}>
            <Route element={<BackgroundLayout />}>
              <Route path={ONBOARDING_ROUTE_START} element={<OnboardingStart />} />
              <Route path={ONBOARDING_ROUTE_WORK_HOURS} element={<OnboardingWorkHours />} />
              <Route path={ONBOARDING_ROUTE_DESK} element={<OnboardingDesk />} />
              <Route path={ONBOARDING_ROUTE_COLLEAGUES} element={<OnboardingColleagues />} />
              <Route path={ONBOARDING_ROUTE_PROFILE_PICTURE} element={<OnboardingProfilePicture />} />
              <Route path={ONBOARDING_ROUTE_NOTIFICATIONS} element={<OnboardingNotifications />} />
              <Route path={ONBOARDING_ROUTE_FINALIZE} element={<OnboardingFinalize />} />
            </Route>
          </Route>
        </Route>
        {/* Not found Route */}
        <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* Unguarded Routes */}
      <Route path={RESET_PASSWORD_WITH_TOKEN_ROUTE} element={<JxtLoginLayout />}>
        <Route path="" element={<ResetPassword />} />
      </Route>
      <Route path={RESET_PASSWORD_WITH_CODE_ROUTE} element={<JxtLoginLayout />}>
        <Route path="" element={<ResetPassword />} />
      </Route>
      <Route element={<BackgroundLayout className="justify-center items-center" />}>
        <Route path={VERSION_ROUTE} element={<Version />} />
        <Route path={RTSM_ROUTE_USER_HASH} element={<RtsmGuard />} />
        <Route path={RTSM_ROUTE_USER_CODE_V4} element={<RtsmGuardV4 />} />
        <Route path={NOT_FOUND_ROUTE} element={<JxtError code={ErrorEnum.NOT_FOUND} />} />
        <Route path={BAD_REQUEST_ROUTE} element={<JxtError code={ErrorEnum.BAD_REQUEST} />} />
        <Route path={NOT_AUTHORIZED_ROUTE} element={<JxtError code={ErrorEnum.NOT_AUTHORIZED} />} />
      </Route>
      {/* Non-Authenticated Routes: accessible only if user in not authenticated */}
      <Route element={<GuardedRoute isRouteAccessible={!token} redirectRoute={HOME_ROUTE} />}>
        <Route path="/" element={<JxtLoginLayout />}>
          <Route path="" element={<LoginEmail />} />
          <Route path="login" element={<LoginPassword />} />
          <Route path="login/first" element={<LoginFirst />} />
          <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
          <Route path={SAML_ROUTE} element={<Saml />} />
        </Route>
      </Route>
      {/* Authenticated Routes */}
      <Route element={<GuardedRoute isRouteAccessible={!!token} redirectRoute={LOGIN_ROUTE} />}>
        <Route element={<OnboardingDoneGuard />}>
          <Route element={<BackgroundLayout className="justify-center items-center" />}>
            <Route path={RTSM_ROUTE} element={<RtsmGuard />} />
            <Route path={RTSM_ROUTE_USER_V4} element={<RtsmGuardV4 />} />
          </Route>
          <Route element={<MenuLayout />}>
            <Route element={<GuardedRoute isRouteAccessible={hasCollaborators} redirectRoute={SEARCH_SPACE_ROUTE} />}>
              <Route path={HOME_ROUTE} element={<Home />} />
            </Route>
            <Route path={SEARCH_SPACE_ROUTE} element={<Spaces />} />
            <Route element={<GuardedRoute isRouteAccessible={false} redirectRoute={SEARCH_SPACE_ROUTE} />}>
              <Route path={SCHEDULER_ROUTE} element={<Spaces />} />
            </Route>
            <Route path={BOOKINGS_MINE_ROUTE} element={<MyBookings />} />
            <Route element={<GuardedRoute isRouteAccessible={hasCollaborators} redirectRoute={SEARCH_SPACE_ROUTE} />}>
              <Route path={USER_PLANNING_ROUTE} element={<Colleagues />} />
            </Route>
            <Route element={<GuardedRoute isRouteAccessible={manageSpaces} redirectRoute={SEARCH_SPACE_ROUTE} />}>
              <Route path={MANAGE_SPACES_ROUTE} element={<ManageMyResources />} />
            </Route>
            <Route path={PROFILE_ROUTE} element={<UserContainer />} />
            <Route path={BOOKINGS_VIEW_ROUTE} element={<Booking />} />
            <Route path={TOGETHER_ROUTE} element={<MeetUp />} />
          </Route>
        </Route>
        <Route element={<OnboardingNotDoneGuard />}>
          <Route element={<BackgroundLayout />}>
            <Route path={ONBOARDING_ROUTE_START} element={<OnboardingStart />} />
            <Route path={ONBOARDING_ROUTE_WORK_HOURS} element={<OnboardingWorkHours />} />
            <Route path={ONBOARDING_ROUTE_DESK} element={<OnboardingDesk />} />
            <Route path={ONBOARDING_ROUTE_COLLEAGUES} element={<OnboardingColleagues />} />
            <Route path={ONBOARDING_ROUTE_PROFILE_PICTURE} element={<OnboardingProfilePicture />} />
            <Route path={ONBOARDING_ROUTE_NOTIFICATIONS} element={<OnboardingNotifications />} />
            <Route path={ONBOARDING_ROUTE_FINALIZE} element={<OnboardingFinalize />} />
          </Route>
        </Route>
      </Route>
      {/* Not found Route */}
      <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
    </Routes>
  );
};
