import JxtIconButton from '../JxtIconButton';
import { JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from '../JxtIconButton/types';
import { IJxtBackButton } from './types';

const JxtBackButton = ({
  onClick,
  size = JxtIconButtonSizeEnum.R,
  type = JxtIconButtonTypeEnum.Filled,
}: IJxtBackButton) => {
  return <JxtIconButton onClick={onClick} size={size} type={type} icon="chevron-left" />;
};

export default JxtBackButton;
