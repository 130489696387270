import { JxtDisplayOpeningHours } from '@jooxter/ui';
import { useResourceWeekOpeningHours } from '../../hooks';
import { IDisplayResourceOpeningHours } from './types';

const DisplayResourceOpeningHours = ({ range, resourceId, timezone }: IDisplayResourceOpeningHours) => {
  const { get } = useResourceWeekOpeningHours(range, resourceId, timezone);
  const openingHours = get();

  return <JxtDisplayOpeningHours openingHours={openingHours} />;
};

export default DisplayResourceOpeningHours;
