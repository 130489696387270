import { createContext, useContext } from 'react';
import { TNavigateFunction } from './types';

const defaultNavigate: TNavigateFunction = () => {
  throw new Error('JXT router not provided');
};

export const JxtRouterContext = createContext<TNavigateFunction>(defaultNavigate);

export const useJxtRouter = () => {
  return useContext(JxtRouterContext);
};
