import clsx from 'clsx';
import JxtEventDate from '../JxtEventDate';
import JxtEventSummary from '../JxtEventSummary';
import JxtResourceInfos from '../JxtResourceInfos';
import { IJxtExceptionalOpeningHoursDetails } from './types';

const JxtExceptionalOpeningHoursDetails = ({
  summary,
  date,
  resource,
  plan,
  className = '',
  showPlan = true,
}: IJxtExceptionalOpeningHoursDetails) => {
  return (
    <div className="flex flex-col">
      <div className={clsx('flex flex-col gap-4 px-1', className)}>
        <JxtEventSummary {...summary} />
        <JxtEventDate {...date} />
        <JxtResourceInfos {...resource} />
        {showPlan && plan && <div className="rounded-lg border border-neutral-20">{plan}</div>}
      </div>
    </div>
  );
};

export default JxtExceptionalOpeningHoursDetails;
