import clsx from 'clsx';
import { getJxtButtonClass, getJxtButtonTextClass } from './styles';
import { ButtonSizeEnum, ButtonVariantEnum, IJxtButton } from './types';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

const JxtButton = (
  {
    variant = ButtonVariantEnum.Primary,
    size = ButtonSizeEnum.Regular,
    disabled = false,
    type = 'button',
    leftIcon = '',
    children,
    onClick,
    className = '',
    style,
    autoFocus = false,
    ariaLabel,
  }: IJxtButton,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const classes = getJxtButtonClass({ variant, size });

  useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement);

  useEffect(() => {
    if (autoFocus && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  return (
    <button
      autoFocus={autoFocus}
      type={type}
      className={clsx(classes, className, disabled && 'cursor-not-allowed')}
      disabled={disabled}
      onClick={onClick}
      ref={buttonRef}
      style={style}
      aria-label={ariaLabel}
    >
      {leftIcon && (
        <i
          className={clsx(
            `fas fa-${leftIcon}`,
            size === ButtonSizeEnum.Small ? 'fa-xs' : '',
            children ? (size === ButtonSizeEnum.Small ? 'pr-1' : 'pr-2') : ''
          )}
        />
      )}
      <div className={getJxtButtonTextClass({ size })}>{children}</div>
    </button>
  );
};

export default forwardRef(JxtButton);

export * from './types';
