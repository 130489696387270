import { IJxtWorkplacesListItem } from './types';
import JxtUserShortDisplay from '../JxtUserShortDisplay';
import JxtWorkplacesWeek from '../JxtWorkplacesWeek';
import { getJxtWorkplacesListItemClass } from './styles';
import { Info } from 'luxon';
import JxtLocateButton from '../JxtLocateButton';
import { fromISO, now } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';

const JxtWorkplacesListItem = ({ workplaces, user, personal = false, onClick, bookings }: IJxtWorkplacesListItem) => {
  const classes = getJxtWorkplacesListItemClass({ personal });
  const days = Info.weekdays().map((c) => c.charAt(0).toUpperCase());
  const { t } = useTranslation();

  return (
    <div className={classes}>
      <div className="h-10 flex grow justify-between items-center min-w-0">
        <JxtUserShortDisplay firstname={user.firstname} lastname={user.lastname} picture={user.picture} />
        {onClick && (
          <div className="ml-1 mr-1 max-xl:mr-0 max-lg:mr-1 max-[480px]:mr-0">
            <JxtLocateButton
              onClick={onClick}
              disabled={
                !bookings || bookings.filter((b) => now().startOf('day') < fromISO(b.end.dateTime)).length === 0
              }
              disabledMessage={t('user-not-locatable-reason')}
              title={t('locate-user')}
            />
          </div>
        )}
      </div>
      {personal ? (
        <div className="flex flex-col gap-px">
          <div className="flex gap-1 text-body-xs font-medium text-neutral-80">
            {days.slice(0, 5).map((day, i) => (
              <p key={i} className="w-9 text-center">
                {day}
              </p>
            ))}
          </div>
          <div className="h-10 flex items-center">
            <JxtWorkplacesWeek workplaces={workplaces} user={user} />
          </div>
        </div>
      ) : (
        <JxtWorkplacesWeek workplaces={workplaces} user={user} />
      )}
    </div>
  );
};

export default JxtWorkplacesListItem;
