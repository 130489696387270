import { JxtWorkplaceStatusButton, JxtWorkplaceStatusButtonStateEnum } from '@jooxter/ui';
import { JxtWorkplaceStatusButtonTypeEnum } from '@jooxter/utils';
import { JxtBuildingSelect } from '../JxtBuildingSelect';
import { IJxtWorkplaceSelector } from './types';

const JxtWorkplaceSelector = ({
  statusType,
  onChangeStatusType,
  defaultLocation,
  onChangeLocation,
  showUnknown = false,
  disabled,
}: IJxtWorkplaceSelector) => {
  const showSelectLocation = statusType === JxtWorkplaceStatusButtonTypeEnum.Office;
  const types = showUnknown
    ? Object.values(JxtWorkplaceStatusButtonTypeEnum)
    : Object.values(JxtWorkplaceStatusButtonTypeEnum).filter((t) => t !== JxtWorkplaceStatusButtonTypeEnum.Unknown);

  return (
    <>
      <div className="flex gap-2 my-2">
        {types.map((type) => (
          <JxtWorkplaceStatusButton
            key={`workplace-status-button-${type}`}
            type={type}
            state={
              type === statusType ? JxtWorkplaceStatusButtonStateEnum.Active : JxtWorkplaceStatusButtonStateEnum.Default
            }
            onClick={() => onChangeStatusType(type)}
            disabled={disabled}
          />
        ))}
      </div>
      {showSelectLocation && (
        <JxtBuildingSelect
          defaultValue={
            defaultLocation?.id && defaultLocation?.name
              ? { label: defaultLocation.name, value: defaultLocation.id }
              : undefined
          }
          selectedOption={defaultLocation?.id}
          onBuildingSelect={onChangeLocation}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default JxtWorkplaceSelector;
