import clsx from 'clsx';
import { useMemo } from 'react';

export const useDialogPanelClasses = (size: string) => {
  const baseClasses =
    'relative bg-white rounded-b-none sm:rounded-b-2xl rounded-2xl overscroll-contain flex flex-col z-10 top-unset bottom-0 transform text-left shadow-xl transition-all max-sm:max-h-[88vh] max-sm:min-h-[50vh] w-full sm:min-h-0 sm:my-8 sm:max-h-[calc(100vh_-_(16_*_0.25rem))]';
  const classes = useMemo(() => clsx(baseClasses, size), [size]);

  return { classes };
};
