import { ReactNode, useCallback, useState } from 'react';
import { ExceptionalOpeningHoursFormContext } from './useExceptionalOpeningHoursFormContext';
import { ExceptionalOpeningHours } from '@jooxter/api';

export const ExceptionalOpeningHoursFormProvider = ({ children }: { children: ReactNode }) => {
  const [showExceptionalOpeningHours, setShowExceptionalOpeningHours] = useState<ExceptionalOpeningHours | null>(null);

  const showExceptionalOpeningHoursDetails = useCallback((exceptionalOpeningHours: ExceptionalOpeningHours) => {
    setShowExceptionalOpeningHours(exceptionalOpeningHours);
  }, []);

  const closeExceptionalOpeningHoursDetails = useCallback(() => {
    setShowExceptionalOpeningHours(null);
  }, []);

  return (
    <ExceptionalOpeningHoursFormContext.Provider
      value={{ showExceptionalOpeningHours, showExceptionalOpeningHoursDetails, closeExceptionalOpeningHoursDetails }}
    >
      {children}
    </ExceptionalOpeningHoursFormContext.Provider>
  );
};
