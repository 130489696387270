import { JxtModal, JxtModalSizeEnum } from '@jooxter/ui';
import { useCallback, useState } from 'react';
import { LocateUser } from '../LocateUser';
import { IJxtModalLocateUser } from './types';

const JxtModalLocateUser = ({ show, onHide, range, locateUserModalInfos, date }: IJxtModalLocateUser) => {
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);
  // https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const modalRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setTimeout(() => {
        setModalWidth(node.getBoundingClientRect().width);
        setModalHeight(node.getBoundingClientRect().height - 2 * 16);
      }, 10);
    }
  }, []);

  if (!(locateUserModalInfos && range)) {
    return null;
  }

  return (
    <JxtModal
      header={{
        title: `${locateUserModalInfos?.firstname} ${locateUserModalInfos?.lastname}`,
        icon: 'map-marker-alt',
      }}
      ref={modalRef}
      show={!!show}
      onHide={onHide}
      size={JxtModalSizeEnum.L}
    >
      {modalWidth && modalHeight ? (
        <LocateUser
          userId={locateUserModalInfos?.id}
          range={range}
          date={date}
          width={modalWidth}
          height={modalHeight}
          timezone={locateUserModalInfos?.timezone}
        />
      ) : (
        <div>Calculating</div>
      )}
    </JxtModal>
  );
};

export default JxtModalLocateUser;
