import { DateTime } from 'luxon';
import { z } from 'zod';

export const dateTimeSchema = z.custom<DateTime>((val: unknown) => {
  return (val as DateTime).isValid;
});

export const createDateDef = () =>
  z.object({
    date: dateTimeSchema,
    time: z.string().regex(/\d\d:\d\d/),
  });
