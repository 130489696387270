import noFloorPlan from '../../assets/images/floor.svg';
import { useTranslation } from 'react-i18next';

const JxtNoFloorPlan = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-6 items-center p-4 m-auto">
      <img width="50%" src={noFloorPlan} alt={t<string>('there-is-no-plan-to-display')} />
      <p className="text-neutral-100">{t<string>('there-is-no-plan-to-display')}</p>
    </div>
  );
};

export default JxtNoFloorPlan;
