import { User } from '@jooxter/api';
import { Settings } from 'luxon';

export const setupLuxon = (user: User): void => {
  if (user.lang) {
    Settings.defaultLocale = user.lang;
  }

  if (user.timezone) {
    Settings.defaultZone = user.timezone;
  }
};
