import { useTranslation } from 'react-i18next';
import { IJxtCalendarDayWorkplaceAdd } from './types';
import clsx from 'clsx';

const JxtCalendarDayWorkplaceAdd = ({ disabled, onClick }: IJxtCalendarDayWorkplaceAdd) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      title={t<string>('colleagues-add-workplace')}
      className={clsx(
        'flex flex-col gap-1 p-2 items-center justify-center h-[72px] w-full rounded-lg overflow-hidden text-neutral-120',
        disabled
          ? 'cursor-not-allowed bg-neutral-30'
          : 'cursor-pointer bg-neutral-10 hover:bg-neutral-20 border border-dashed border-neutral-40'
      )}
    >
      <div className="size-5 flex items-center justify-center">
        <i className="fas fa-plus" />
      </div>
      <p className="text-body-xs text-center truncate w-full">{t('colleagues-add-workplace')}</p>
    </div>
  );
};

export default JxtCalendarDayWorkplaceAdd;
