// eslint-disable-next-line import/named
import { EventContentArg } from '@fullcalendar/core';
import { BookingAttendeeParticipationStatusEnum, BookingCompressed } from '@jooxter/api';
import {
  BookingDisplayModeEnum,
  JxtCalendarEvent,
  JxtBookingAddButton,
  JxtBookingEmpty,
  JxtSchedulerCalendarTooltip,
} from '@jooxter/ui';
import { JxtResourcesTypeEnum, PRIVATE_TOKEN, shouldShowSensibleInformation } from '@jooxter/utils';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { IFullCalendarConfig } from '../types';
import { EventTypeEnum } from '@jooxter/core';

export const useFullCalendar = ({
  user,
  showHours = false,
  showTitle = false,
  showInfoIcons = false,
  showOptions = false,
  showActions = false,
  showPopover = false,
  bookingMode = BookingDisplayModeEnum.Regular,
  view,
  readonly = false,
  selectedEventId,
  callbacks = {},
}: IFullCalendarConfig) => {
  const { onClick, goToResources, onClickCheckIn, onClickCheckOut, onClickUpdate, onClickDelete } = callbacks;
  const { t } = useTranslation();

  const getEventTitle = (title: string, isPrivate: boolean, booking?: BookingCompressed): string => {
    if (shouldShowSensibleInformation(user, booking)) {
      return title;
    } else if (isPrivate) {
      return t(PRIVATE_TOKEN);
    }
    return title;
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    switch (eventInfo.event.extendedProps.type) {
      case EventTypeEnum.BOOKING:
        return renderBookingEventContent(eventInfo);
      case EventTypeEnum.EXCEPTIONAL_OPENING_HOURS:
        return renderExceptionalOpeningHoursEventContent(eventInfo);
      case EventTypeEnum.FAKE:
        return renderFakeEventContent(eventInfo);
      default:
        break;
    }

    return <div className={clsx(view && view === 'resourceTimelineDay' ? 'h-10' : 'h-2', 'px-2 py-1')} />;
  };

  const renderBookingEventContent = (eventInfo: EventContentArg) => {
    if (eventInfo.event.start && eventInfo.event.end) {
      const formattedStart = DateTime.fromJSDate(eventInfo.event.start).toLocaleString(DateTime.TIME_SIMPLE);
      const formattedEnd = DateTime.fromJSDate(eventInfo.event.end).toLocaleString(DateTime.TIME_SIMPLE);

      const onClickEvent = () => {
        if (eventInfo.event.title && eventInfo.event.id !== '' && onClick) {
          onClick(parseInt(eventInfo.event.id, 10));
        }
      };

      const { isPrivate, participationStatus } = eventInfo.event.extendedProps;
      const eventTitle = getEventTitle(eventInfo.event.title, isPrivate, eventInfo.event.extendedProps.originalBooking);

      let optionIconsList: string[] = [];

      if (eventInfo.event.extendedProps?.icons?.length > 0) {
        optionIconsList = (eventInfo.event.extendedProps.icons as string[])
          .map((url) => DOMPurify.sanitize(url.toString(), { USE_PROFILES: { html: true } }))
          .filter((u): u is string => !!u);
      }

      const {
        checkin,
        checkout,
        arrive,
        leave,
        cancel,
        update: updateBooking,
      } = eventInfo.event.extendedProps.capabilities;

      const content = (
        <JxtCalendarEvent
          id={eventInfo.event.id}
          color={eventInfo.event.extendedProps.color}
          mode={bookingMode}
          notSelected={readonly && selectedEventId !== parseInt(eventInfo.event.id, 10)}
          hours={showHours ? `${formattedStart} - ${formattedEnd}` : undefined}
          text={showTitle ? eventTitle : ''}
          nbAttendees={showInfoIcons ? eventInfo.event.extendedProps.nbAttendees : undefined}
          isValidated={showInfoIcons ? eventInfo.event.extendedProps.isValidated !== null : undefined}
          isDeclined={participationStatus === BookingAttendeeParticipationStatusEnum.Declined}
          optionIconsList={showOptions ? optionIconsList : undefined}
          isRecurrence={showInfoIcons ? !!eventInfo.event.extendedProps.rrule : undefined}
          onClickEvent={onClickEvent}
          onClickCheckIn={
            readonly || (!checkin && !arrive) || !showActions || !onClickCheckIn
              ? undefined
              : () => {
                  onClickCheckIn(eventInfo.event.id, checkin, arrive);
                }
          }
          onClickCheckOut={
            readonly || (!checkout && !leave) || !showActions || !onClickCheckOut
              ? undefined
              : () => {
                  onClickCheckOut(eventInfo.event.id, checkout, leave);
                }
          }
          onClickEdit={
            readonly || !updateBooking || !showActions || !onClickUpdate
              ? undefined
              : () => onClickUpdate(eventInfo.event.id)
          }
          onClickDelete={
            readonly || !cancel || !showActions || !onClickDelete ? undefined : () => onClickDelete(eventInfo)
          }
        />
      );

      // Temporary code ? Do not show Overlay if event is private and user cannot see it
      if (
        !showPopover ||
        (isPrivate && !shouldShowSensibleInformation(user, eventInfo.event.extendedProps.originalBooking))
      ) {
        return content;
      }

      return <JxtSchedulerCalendarTooltip eventInfo={eventInfo}>{content}</JxtSchedulerCalendarTooltip>;
    }
  };

  const renderExceptionalOpeningHoursEventContent = (eventInfo: EventContentArg) => {
    if (eventInfo.event.start && eventInfo.event.end) {
      const formattedStart = DateTime.fromJSDate(eventInfo.event.start).toLocaleString(DateTime.TIME_SIMPLE);
      const formattedEnd = DateTime.fromJSDate(eventInfo.event.end).toLocaleString(DateTime.TIME_SIMPLE);

      const onClickEvent = () => {
        if (eventInfo.event.title && eventInfo.event.id !== '' && onClick) {
          onClick(parseInt(eventInfo.event.id, 10));
        }
      };

      return (
        <JxtCalendarEvent
          id={eventInfo.event.id}
          color={eventInfo.event.extendedProps.color}
          mode={bookingMode}
          hours={showHours ? `${formattedStart} - ${formattedEnd}` : undefined}
          text={eventInfo.event.title}
          onClickEvent={onClickEvent}
        />
      );
    }
  };

  const renderFakeEventContent = (eventInfo: EventContentArg) => {
    if (readonly || !goToResources) {
      return null;
    }

    if (eventInfo.event.title == 'No Event') {
      return <JxtBookingEmpty />;
    } else if (eventInfo.event.title == 'Add Event' && eventInfo.event.start) {
      const fakeEventDate = DateTime.fromJSDate(eventInfo.event.start);

      return (
        <JxtBookingAddButton
          onClickDesk={() => goToResources(JxtResourcesTypeEnum.DESK, fakeEventDate)}
          onClickRoom={() => goToResources(JxtResourcesTypeEnum.MEETING, fakeEventDate)}
          onClickOther={() => goToResources(undefined, fakeEventDate)}
        />
      );
    }
  };

  return { renderEventContent };
};
