import { DateTime } from 'luxon';
import { IJxtBookingHours } from './types';

const JxtBookingHours = ({ range }: IJxtBookingHours) => {
  return (
    <div className="flex items-center gap-2">
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-clock fa-fw fa-lg text-neutral-60" />
      </div>
      <span className="text-body-m text-neutral-140">
        {range.from.toLocaleString(DateTime.TIME_SIMPLE) + ' - ' + range.to.toLocaleString(DateTime.TIME_SIMPLE)}
      </span>
    </div>
  );
};

export default JxtBookingHours;
