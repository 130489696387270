import { startCase } from 'lodash-es';
import { useChooseWorkplaceOverlayContext } from './ChooseWorkplaceOverlayContext';
import { DateTime } from 'luxon';
import JxtToggleSwitch from '../JxtToggleSwitch';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import { useTranslation } from 'react-i18next';
import { IJxtChooseWorkplaceOverlayContent } from './types';
import { useEffect } from 'react';

const JxtChooseWorkplaceOverlayContent = ({
  workplaceDay,
  onClose,
  disabledMorning,
  disabledAfternoon,
  disabledReset,
  userInfos,
}: IJxtChooseWorkplaceOverlayContent) => {
  const context = useChooseWorkplaceOverlayContext();
  const { t } = useTranslation();
  const showResetButton = !disabledReset;
  const showValidateButtons = !disabledMorning || !disabledAfternoon;
  const showFooter = showResetButton || showValidateButtons;
  const disableToggleSwitch = disabledMorning || disabledAfternoon;

  useEffect(() => {
    context?.methods?.initProvider(onClose, workplaceDay, disabledMorning, disabledAfternoon);

    return () => {
      context?.methods?.cleanupProvider();
    };
  }, [
    workplaceDay,
    context?.methods.cleanupProvider,
    context?.methods.initProvider,
    disabledMorning,
    disabledAfternoon,
    onClose,
  ]);

  useEffect(() => {
    setUserInfos(userInfos);
  }, [userInfos]);

  if (!context) {
    return null;
  }

  const {
    methods: { onSubmit, onReset },
    getters: { halfDay },
    setters: { setHalfDay, setUserInfos },
    components: { morningWorkplaceSelector, afternoonWorkplaceSelector },
  } = context;

  return (
    <div className="flex flex-col grow gap-3">
      <div className="flex flex-wrap gap-2 justify-between items-center">
        <span className="text-body-s text-neutral-140">
          {startCase(workplaceDay.date.toLocaleString(DateTime.DATE_HUGE))}
        </span>
        <div className="flex items-center gap-2">
          <span className="text-body-s text-neutral-140 font-medium">{t('half-day')}</span>
          <JxtToggleSwitch defaultChecked={halfDay} onChange={setHalfDay} disabled={disableToggleSwitch} />
        </div>
      </div>
      <div className="flex flex-col">
        {halfDay && <span className="text-body-s text-neutral-100 font-medium">{t('morning')}</span>}
        {morningWorkplaceSelector}
      </div>
      {halfDay && (
        <div className="flex flex-col">
          {halfDay && <span className="text-body-s text-neutral-100 font-medium">{t('afternoon')}</span>}
          {afternoonWorkplaceSelector}
        </div>
      )}
      {showFooter && (
        <footer className="flex gap-2">
          {showResetButton && (
            <JxtButton
              variant={ButtonVariantEnum.Link}
              onClick={() => onReset(workplaceDay.morning, workplaceDay.afternoon)}
            >
              {t('choose-workplace-reset')}
            </JxtButton>
          )}
          {showValidateButtons && (
            <div className="flex gap-2 ml-auto">
              <JxtButton variant={ButtonVariantEnum.Secondary} onClick={onClose}>
                {t('cancel')}
              </JxtButton>
              <JxtButton onClick={onSubmit}>{t('validate')}</JxtButton>
            </div>
          )}
        </footer>
      )}
    </div>
  );
};

export default JxtChooseWorkplaceOverlayContent;
