export enum JxtWorkplaceCalendarDayHeaderStateEnum {
  Default = 'Default',
  Active = 'Active',
  Disabled = 'Disabled',
}

export interface IJxtWorkplaceCalendarDayHeader {
  text: string;
  dataDate?: string | null;
  onClick?: () => void;
  className?: string;
  state?: JxtWorkplaceCalendarDayHeaderStateEnum;
}
