import { IJxtColleagueCalendarUser } from './types';
import JxtLocateButton from '../JxtLocateButton';
import { useTranslation } from 'react-i18next';
import JxtStar from '../JxtStar';
import { JxtStarSizeEnum, JxtStarTypeEnum } from '../JxtStar/types';
import clsx from 'clsx';
import JxtAvatar from '../JxtAvatar';
import { JxtAvatarSizeEnum } from '../JxtAvatar/types';
import { debounce } from 'lodash-es';
import { FAVORITE_DEBOUNCE_DELAY } from '@jooxter/utils';

const JxtColleagueCalendarUser = ({
  firstname,
  lastname,
  picture,
  disableLocate,
  showFavorite = true,
  disableFavorite = false,
  isFavorite = false,
  title,
  onLocate,
  onToggleFavorite,
}: IJxtColleagueCalendarUser) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 p-3 bg-white rounded-2xl">
      {title && <span className="text-neutral-140 text-title-m font-medium text-center">{title}</span>}
      <div
        className={clsx('flex justify-between items-center gap-2 px-2 py-4', title ? 'border-t border-neutral-20' : '')}
      >
        <div className="flex items-center gap-2 min-w-0">
          <JxtAvatar
            picture={picture}
            text={`${firstname.charAt(0)}${lastname.charAt(0)}`}
            alt={t('avatar-of', {
              name: `${firstname} ${lastname}`,
            })}
            size={JxtAvatarSizeEnum.S}
            className="shrink-0"
          />
          <div className="text-body-s text-neutral-140 font-medium overflow-hidden">
            <p className="flex flex-col" title={`${firstname} ${lastname}`}>
              <span className="truncate">{firstname}</span>
              <span className="truncate">{lastname}</span>
            </p>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <JxtLocateButton
            onClick={onLocate}
            disabled={disableLocate}
            disabledMessage={t('user-not-locatable-reason')}
            title={t('locate-user')}
          />
          <div className={clsx(showFavorite ? 'visible' : 'invisible')}>
            <JxtStar
              checked={isFavorite}
              type={JxtStarTypeEnum.Light}
              size={JxtStarSizeEnum.Medium}
              disabled={disableFavorite}
              onClick={onToggleFavorite && debounce(onToggleFavorite, FAVORITE_DEBOUNCE_DELAY)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JxtColleagueCalendarUser;
