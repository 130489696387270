import { JxtSelect } from '@jooxter/ui';
import { JxtBookingVisibilityEnum } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import { IJxtVisibilitySelect, VisibilityOption } from './types';
//eslint-disable-next-line import/named
import { SingleValue } from 'react-select';

export const JxtVisibilitySelect = ({
  onVisibilitySelect,
  defaultValue,
  value,
  label,
  helperText,
}: IJxtVisibilitySelect) => {
  const { t } = useTranslation();
  const options: VisibilityOption[] = Object.values(JxtBookingVisibilityEnum).map((visibility) => ({
    value: visibility,
    label: t('visibility-key-select-public-public-private-private', { visibility }),
  }));

  const handleVisibilitySelect = (e: SingleValue<VisibilityOption>) => {
    if (e?.value && onVisibilitySelect) {
      onVisibilitySelect(e.value);
    }
  };

  return (
    <JxtSelect
      value={value}
      defaultValue={defaultValue ?? options[0]}
      label={label}
      helperText={helperText}
      isSearchable={false}
      options={options}
      handleSelection={handleVisibilitySelect}
    />
  );
};
