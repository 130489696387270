import { useTranslation } from 'react-i18next';
import { BookingRecurrence, TRecurrenceOption } from '../types';
import { getMonthDayIndex, getWeekdayName } from '../date';
import { DateTime } from 'luxon';

export const useGenerateRruleOptions = (date: DateTime, customizeLabel?: string) => {
  const { t, i18n } = useTranslation();
  const monthDayIndex = getMonthDayIndex(date);
  const dayName = getWeekdayName(date, i18n.language);

  const options: TRecurrenceOption[] = [
    {
      value: BookingRecurrence.Once,
      label: t<string>('booking.recurrence.once'),
    },
    {
      value: BookingRecurrence.BusinessDays,
      label: t<string>('booking.recurrence.every-business-day-of-the-week'),
    },
    {
      value: BookingRecurrence.Weekly,
      label: t<string>('booking.recurrence.weekly', {
        dayName: dayName,
      }),
    },
    {
      value: BookingRecurrence.OnceInTwoWeeks,
      label: t<string>('booking.recurrence.one-week-on-two', {
        dayName: dayName,
      }),
    },
    {
      value: BookingRecurrence.Monthly,
      label: t<string>('booking.recurrence.monthly', {
        monthDayIndex: monthDayIndex,
        dayName: dayName,
      }),
    },
    {
      value: BookingRecurrence.Customize,
      label: customizeLabel ?? t<string>('booking.recurrence.customize'),
    },
  ];

  return { options };
};
