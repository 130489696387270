import { ReactNode } from 'react';

export interface IJxtToast {
  onHide?: () => void;
  title: string;
  subtitle?: string;
  variant?: JxtToastVariantEnum;
  children?: ReactNode;
  closeable?: boolean;
}

export enum JxtToastVariantEnum {
  Success = 'success',
  Failure = 'failure',
}
