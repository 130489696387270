import { getThemeColorHex } from '@jooxter/tailwind-config';
import { IJxtLoader } from './types';
import { Infinity } from 'ldrs/react';
import 'ldrs/react/Infinity.css';

const JxtLoader = ({
  size = 65,
  color = getThemeColorHex('red100'),
  speed = 1.3,
  stroke = 8,
  strokeLength = 0.4,
  bgOpacity = 0.32,
}: IJxtLoader) => {
  return (
    <Infinity
      size={size}
      color={color}
      speed={speed}
      stroke={stroke}
      strokeLength={strokeLength}
      bgOpacity={bgOpacity}
    />
  );
};

export default JxtLoader;
