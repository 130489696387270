import { PreferencesRequest, UserRequestExtended, UserService, WorkHoursWeekDto } from '@jooxter/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserQueryKeys } from '../queries';
import { useStore } from '../store';
import { useShallow } from 'zustand/shallow';

export const useMutatePatchUser = () => {
  const queryClient = useQueryClient();
  const [token] = useStore(useShallow((state) => [state.token]));

  const mutation = useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: Partial<UserRequestExtended> }) => {
      return UserService.patch(id, payload);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UserQueryKeys.GetMe, token],
      }),
  });

  return mutation;
};

export const useMutatePutUser = () => {
  const queryClient = useQueryClient();
  const [token] = useStore(useShallow((state) => [state.token]));

  const mutation = useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: UserRequestExtended }) => {
      return UserService.putUser(id, payload);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UserQueryKeys.GetMe, token],
      }),
  });

  return mutation;
};

export const useMutatePatchPreferences = (userId?: number) => {
  const queryClient = useQueryClient();
  const [token] = useStore(useShallow((state) => [state.token]));

  const mutation = useMutation({
    mutationFn: (payload: Partial<PreferencesRequest>) => {
      if (!userId) {
        return Promise.reject();
      }
      return UserService.patchPreferences(userId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [UserQueryKeys.GetMyPreferences, userId],
      });
      queryClient.invalidateQueries({
        queryKey: [UserQueryKeys.GetMe, token],
      });
    },
  });

  return mutation;
};

export const useMutatePutPreferences = (userId?: number) => {
  const queryClient = useQueryClient();
  const [token] = useStore(useShallow((state) => [state.token]));

  const mutation = useMutation({
    mutationFn: (payload: PreferencesRequest) => {
      if (!userId) {
        return Promise.reject();
      }
      return UserService.putPreferences(userId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [UserQueryKeys.GetMyPreferences, userId],
      });
      queryClient.invalidateQueries({
        queryKey: [UserQueryKeys.GetMe, token],
      });
    },
  });

  return mutation;
};

export const useMutateUserNotifications = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: any }) => UserService.patchNotifications(id, payload),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [UserQueryKeys.GetMyNotifications] });
    },
  });

  return mutation;
};

export const useMutateUserTypicalWeek = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: WorkHoursWeekDto }) =>
      UserService.postTypicalWeek(id, payload),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [UserQueryKeys.GetTypicalWeek] });
    },
  });

  return mutation;
};

export const useMutatePutFavoriteUser = () => {
  const mutation = useMutation({
    mutationFn: (id: number) => UserService.putFavorite(id),
  });
  return mutation;
};

export const useMutateDeleteFavoriteUser = () => {
  const mutation = useMutation({
    mutationFn: (id: number) => UserService.deleteFavorite(id),
  });
  return mutation;
};

export const useMutateSetupUser = () => {
  const mutation = useMutation({
    mutationFn: (id: number) => UserService.setup(id),
  });
  return mutation;
};

export const useHeartBeat = () => {
  const mutation = useMutation({
    mutationFn: () => UserService.heartbeat(),
  });

  return mutation;
};
