import { MAXIMUM_OCCURRENCE, OccurrenceMode, RruleConstructorObject } from '../types';
import { DateTime } from 'luxon';
import { Frequency, RRule, Weekday } from 'rrule';

export interface IGenerateRruleOptions {
  freq: Frequency;
  interval?: number;
  occurrenceMode: OccurrenceMode;
  until: DateTime;
  selectedDays: Weekday[];
  bysetpos?: number;
  count: number;
  recurrenceActive: boolean;
  weekDayControlMode: 'one' | 'business';
  weekdayIndex: number;
}

export const useGenerateRrule = (
  date: DateTime,
  timezone: string
): { generate: (options: IGenerateRruleOptions) => RRule | null } => {
  const generate = (options: IGenerateRruleOptions): RRule | null => {
    if (!options.recurrenceActive) {
      return null;
    }

    const rrule = {
      freq: options.freq,
      dtstart: date.toJSDate(),
      interval: options.interval,
      tzid: timezone,
    } as RruleConstructorObject;

    switch (options.occurrenceMode) {
      case OccurrenceMode.Max:
        rrule.until = date.plus({ days: MAXIMUM_OCCURRENCE }).toJSDate();
        break;

      case OccurrenceMode.Custom:
        rrule.count = options.count;
        break;

      case OccurrenceMode.Until:
        rrule.until = options.until.endOf('day').toJSDate();
        break;

      default:
        throw new Error('Invalid occurrence mode');
    }

    if (options.selectedDays) {
      rrule.byweekday = options.selectedDays;
    }

    if (options.bysetpos !== undefined && options.bysetpos !== null && !isNaN(options.bysetpos)) {
      rrule.bysetpos = options.bysetpos;
    } else if (rrule.freq === RRule.MONTHLY) {
      rrule.bymonthday = date.day;
    }

    return new RRule(rrule);
  };

  return { generate };
};
