import { IJxtNavbar } from '@jooxter/ui';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useFetchUser, HOME_ROUTE, PROFILE_ROUTE, SEARCH_SPACE_ROUTE, TOGETHER_ROUTE } from '@jooxter/core';
import { getMainNav } from './helpers';

export const useBaseNavigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useFetchUser();
  const hasCollaborators = !!user?.capabilities?.features?.collaborators;
  const baseRoute = hasCollaborators ? HOME_ROUTE : SEARCH_SPACE_ROUTE;
  const mainNav = useMemo(() => getMainNav(hasCollaborators, t), [hasCollaborators, t]);

  const baseNavigation: { routes: IJxtNavbar['routes']; buttons?: ReactNode[]; baseRoute: string } = useMemo(
    () => ({
      routes: {
        main: mainNav,
        secondary: [
          {
            name: t('navbar.profile'),
            icon: 'user',
            to: PROFILE_ROUTE,
          },
        ],
      },
      baseRoute: baseRoute,
      buttons: [
        <button
          onClick={() => navigate(TOGETHER_ROUTE)}
          key={t('together.beta')}
          className="bg-primary-100 text-white px-[17px] mr-[-1px] py-3 rounded-lg font-['Open_Sans'] text-[14px] font-semibold hover:bg-[#78acff] transition duration-300"
        >
          {t('together.beta')}
        </button>,
        <p key="jimo-badge" className="jimo-widget px-1 mx-1">
          <i className="fas fa-gift opacity-60 fa-sm text-[#677489] " />
        </p>,
        <a
          key={t('navbar.help')}
          target="_blank"
          rel="noopener noreferrer"
          title={t<string>('navbar.help')}
          href={t<string>('help-center-url')}
          className="h-10 w-10 flex items-center justify-center"
        >
          <i className="fas fa-question-circle opacity-60 fa-sm text-[#677489]" />
        </a>,
      ],
    }),
    [mainNav, baseRoute, t, navigate]
  );

  const devBaseNavigation: { routes: IJxtNavbar['routes']; buttons?: ReactNode[]; baseRoute: string } = useMemo(
    () => ({
      routes: {
        main: mainNav,
        secondary: [
          {
            name: t('navbar.profile'),
            icon: 'user',
            to: PROFILE_ROUTE,
          },
        ],
      },
      baseRoute: baseRoute,
      buttons: [
        <button
          onClick={() => navigate(TOGETHER_ROUTE)}
          key={t('together.beta')}
          className="bg-primary-100 text-white px-[17px] mr-[-1px] py-3 rounded-lg font-['Open_Sans'] text-[14px] font-semibold hover:bg-[#78acff] transition duration-300"
        >
          {t('together.beta')}
        </button>,
        <p key="jimo-badge" className="jimo-widget px-1 mx-1">
          <i className="fas fa-gift opacity-60 fa-sm text-[#677489] " />
        </p>,
        <a
          key={t('navbar.help')}
          target="_blank"
          rel="noopener noreferrer"
          title={t<string>('navbar.help')}
          href={t<string>('help-center-url')}
          className="h-10 w-10 flex items-center justify-center"
        >
          <i className="fas fa-question-circle opacity-60 fa-sm text-[#677489]" />
        </a>,
      ],
    }),
    [mainNav, baseRoute, t, navigate]
  );

  return import.meta.env.DEV ? [devBaseNavigation] : [baseNavigation];
};
