import { flip, offset, useFloating } from '@floating-ui/react';
import {
  ButtonVariantEnum,
  JxtButton,
  JxtCalendarOverlayContent,
  JxtColleagueCalendarUser,
  JxtColleagueWorkplace,
  JxtColleagueCalendarWorkplace,
  JxtIconButton,
  JxtLoader,
  JxtModal,
  JxtPopover,
  JxtRangeDateFilter,
  JxtSearchBar,
  JxtSelectedFilter,
  JxtSelectedFilterList,
  JxtChooseWorkplaceOverlayContent,
  useIsSmallScreen,
  IJxtChooseWorkplaceOverlayContent,
  useModalWorkplaceContext,
} from '@jooxter/ui';
import { IColleaguesCalendar } from './types';
import { useTranslation } from 'react-i18next';
import {
  createGTMGAEvent,
  fromISO,
  IRange,
  IJxtWorkplaceHalfDay,
  JxtWorkplaceTypeEnum,
  now,
  sameDay,
  SCREEN_SIZE,
  disableIJxtWorkplaceHalfDay,
  disableIJxtWorkplaceDayReset,
  generateToast,
} from '@jooxter/utils';
import { IJxtRangeDateFilterViewType } from '@jooxter/ui/src/components/JxtRangeDateFilter/types';
import { Popover } from '@headlessui/react';
import {
  ChooseWorkplaceOverlayProvider,
  JxtWorkplaceCalendarContainer,
  JxtModalWorkplace,
  TUserLocateModalInfos,
  useBookingCallbacks,
  useBookingFormContext,
  useColleagueFiltersToSelectedFiltersAdapter,
  useModalLocateUserContext,
  useMutateDeleteFavoriteUser,
  useMutatePutFavoriteUser,
  useOffCanvas,
  UserQueryKeys,
  useStore,
} from '@jooxter/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { isSwipeable } from './helpers';
import { ColleaguesFilters } from '../Filters';
import useHookWithRefCallback from '../../../hooks/useRefWithCallback';
import { Interval } from 'luxon';
import { useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash-es';
import { useShallow } from 'zustand/shallow';

const dayFormat = 'yyyy-MM-dd';

export const ColleaguesCalendar = ({
  user,
  filter,
  range,
  onChangeFilter,
  onRangeChange,
  timezone,
  icsCalendar,
  personalWorkplaces,
  colleaguesWorkplaces,
  fetchNextPage,
  hasNextPage,
}: IColleaguesCalendar) => {
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen(SCREEN_SIZE.LG);
  const queryClient = useQueryClient();
  const days = useMemo(
    () =>
      Interval.fromDateTimes(range.from, range.to)
        .splitBy({ day: 1 })
        .map((i: Interval) => i.start?.toFormat(dayFormat))
        .filter((d): d is string => !!d),
    [range]
  );
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [
      flip({
        fallbackPlacements: ['bottom-end', 'top-start', 'right'],
      }),
      offset(8),
    ],
  });
  const { onShowBookingDetailsClick } = useBookingCallbacks();
  const offCanvasContext = useOffCanvas();
  const bookingFormContext = useBookingFormContext();

  /* START HANDLE FILTERS */
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [resetColleagueFilter] = useStore(useShallow((state) => [state.resetColleagueFilter]));
  const filtersAdapted = useColleagueFiltersToSelectedFiltersAdapter();
  /* END HANDLE FILTERS */

  /* START HANDLE SYNC SCROLL */
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  // if a scroll has been triggered programmatically, we don't want it to trigger another scroll event
  const [programmaticScrolling, setProgrammaticScrolling] = useState<boolean>(false);
  // keep track of which element is scrolling
  const [scroller, setScroller] = useState<'personal' | 'colleagues'>();
  // use AbortController to remove listeners on subsequent renders
  const [personalAbortController, setPersonalAbortController] = useState<AbortController>(new AbortController());
  const [colleaguesAbortController, setColleaguesAbortController] = useState<AbortController>(new AbortController());
  const addScrollToPersonal = useCallback(
    (node: HTMLDivElement) => {
      const aC = new AbortController();
      setPersonalAbortController(aC);
      node?.addEventListener(
        'scroll',
        (e: Event) => {
          if (programmaticScrolling && scroller !== 'personal') {
            return;
          }
          setProgrammaticScrolling(false);

          if (!e?.target) {
            return;
          }
          const target = e.target as HTMLDivElement;

          if (scrollLeft !== target.scrollLeft || (scrollLeft === 0 && target.scrollLeft === 0)) {
            // horizontal scrolling
            setScroller('personal');
            setScrollLeft(target.scrollLeft);
          }
        },
        { signal: aC.signal }
      );
    },
    [programmaticScrolling, scroller, scrollLeft]
  );

  const addScrollToColleagues = useCallback(
    (node: HTMLDivElement) => {
      const aC = new AbortController();
      setColleaguesAbortController(aC);
      node?.addEventListener(
        'scroll',
        (e: Event) => {
          if (programmaticScrolling && scroller !== 'colleagues') {
            return;
          }
          setProgrammaticScrolling(false);

          if (!e?.target) {
            return;
          }
          const target = e.target as HTMLDivElement;

          if (scrollLeft !== target.scrollLeft || (scrollLeft === 0 && target.scrollLeft === 0)) {
            // horizontal scrolling
            setScroller('colleagues');
            setScrollLeft(target.scrollLeft);
          }
        },
        { signal: aC.signal }
      );
    },
    [programmaticScrolling, scroller, scrollLeft]
  );

  const [personalRef, setPersonalRef] = useHookWithRefCallback<HTMLDivElement>(addScrollToPersonal);
  const [colleaguesRef, setColleaguesRef] = useHookWithRefCallback<HTMLDivElement>(addScrollToColleagues);

  useEffect(() => {
    if (isMobile) {
      if (range.from.hasSame(now(), 'week')) {
        onRangeChange({
          from: now().startOf('day'),
          to: now().endOf('day'),
        });
      } else {
        onRangeChange({
          from: range.from.startOf('week'),
          to: range.from.startOf('week').endOf('day'),
        });
      }
    } else {
      onRangeChange({
        from: range.from.startOf('week'),
        to: range.from.endOf('week').minus({ day: 2 }),
      });
    }
  }, [isMobile]);

  useEffect(() => {
    return () => personalAbortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addScrollToPersonal]);

  useEffect(() => {
    return () => colleaguesAbortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addScrollToColleagues]);

  useEffect(() => {
    if (scroller === 'colleagues') {
      setProgrammaticScrolling(true);
      personalRef?.current?.scrollTo({ left: scrollLeft, behavior: 'instant' });
    }

    if (scroller === 'personal') {
      setProgrammaticScrolling(true);
      colleaguesRef.current?.scrollTo({ left: scrollLeft, behavior: 'instant' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollLeft, scroller]);
  /* END HANDLE SYNC SCROLL */

  // open offcanvas when clicking the link in a toast, see https://jooxter.atlassian.net/browse/ND-4421
  useEffect(() => {
    if (bookingFormContext?.showBooking && !isNaN(bookingFormContext?.showBooking)) {
      onShowBookingDetailsClick(bookingFormContext?.showBooking);
    }
  }, [bookingFormContext?.showBooking]);

  // enable left/right swipe on mobile to change the date
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      isSwipeable(eventData.event.target as HTMLDivElement) &&
        onRangeChange({
          from: range.from.plus({ day: 1 }),
          to: range.to.plus({ day: 1 }),
        });
    },
    onSwipedRight: (eventData) => {
      isSwipeable(eventData.event.target as HTMLDivElement) &&
        onRangeChange({
          from: range.from.minus({ day: 1 }),
          to: range.to.minus({ day: 1 }),
        });
    },
  });

  // Mutations
  const putFavorite = useMutatePutFavoriteUser();
  const deleteFavorite = useMutateDeleteFavoriteUser();
  const modalLocateUserContext = useModalLocateUserContext();
  const modalWorkplaceContext = useModalWorkplaceContext();

  // Component methods
  const onClickResetFilterButton = () => {
    resetColleagueFilter();
  };

  const openOffCanvas = () => {
    offCanvasContext?.open({
      header: {
        title: t<string>('provide-a-status'),
      },
      onHide: offCanvasContext.close,
      bodyClass: 'overflow-y-auto',
      children: (
        <JxtWorkplaceCalendarContainer
          initialRange={
            now().hasSame(range.to, 'week')
              ? { from: now().startOf('month'), to: now().endOf('month') }
              : { from: range.from.startOf('month'), to: range.from.endOf('month') }
          }
          onToastActionClicked={onShowBookingDetailsClick}
        />
      ),
    });
  };

  const handleRangeChange = (range: IRange) => {
    onRangeChange({
      from: range.from,
      to: range.to,
    });
  };

  const invalidateWorkplaces = () => {
    queryClient.invalidateQueries({ queryKey: [UserQueryKeys.GetFavoritesWorkplaces] });
    queryClient.invalidateQueries({ queryKey: [UserQueryKeys.GetWorkplaces] });
  };

  const onFavoriteSuccess = () => {
    invalidateWorkplaces();
  };

  const onFavoriteError = () => {
    generateToast(t('unknown-error'), true);
  };

  const handleToggleFavorite = (favorite: boolean, id: number) => {
    if (putFavorite.isPending || deleteFavorite.isPending) {
      return;
    }

    if (favorite) {
      createGTMGAEvent('Collaborators', 'Add Favorite', 'Add Favorite');
      putFavorite.mutate(id, {
        onSuccess: onFavoriteSuccess,
        onError: onFavoriteError,
      });
    } else {
      createGTMGAEvent('Collaborators', 'Remove Favorite', 'Remove Favorite');
      deleteFavorite.mutate(id, {
        onSuccess: onFavoriteSuccess,
        onError: onFavoriteError,
      });
    }
  };

  const openLocateUserModal = (user: TUserLocateModalInfos) => {
    if (user && modalLocateUserContext) {
      // use context here
      modalLocateUserContext.setLocateUserModalInfos({ ...user });
      modalLocateUserContext.setRange(range);
      modalLocateUserContext.setShow(true);
    }
  };

  const openChooseWorkplaceModal = (props: IJxtChooseWorkplaceOverlayContent) => {
    if (modalWorkplaceContext) {
      modalWorkplaceContext.setChooseWorkplaceModalProps(props);
      modalWorkplaceContext.setShow(true);
    }
  };

  const handleWorkplaceClick = (workplace: IJxtWorkplaceHalfDay) => {
    if (workplace.type !== JxtWorkplaceTypeEnum.Unknown) {
      createGTMGAEvent('Collaborators', 'Update', 'Update Workplace');
    } else {
      createGTMGAEvent('Collaborators', 'Create', 'Create Workplace');
    }
  };

  if (!(timezone && personalWorkplaces)) {
    return null;
  }

  return (
    <ChooseWorkplaceOverlayProvider onToastActionClicked={onShowBookingDetailsClick}>
      <div className="h-full flex flex-col">
        <div className="flex flex-col bg-white border-b border-neutral-20">
          <JxtRangeDateFilter
            range={range}
            setRange={handleRangeChange}
            timezone={timezone}
            excludeWeekEnds={true}
            viewType={isMobile ? IJxtRangeDateFilterViewType.Day : IJxtRangeDateFilterViewType.Week}
          >
            {!isMobile ? (
              <>
                <JxtButton leftIcon="plus" onClick={openOffCanvas} className="shrink truncate mr-2">
                  <div className="text-body-s font-medium">{t('my-monthly-workplaces')}</div>
                </JxtButton>
                <Popover>
                  <Popover.Button
                    className="ui-focus-visible:ring-2 rounded-full focus:outline-none"
                    ref={refs.setReference}
                  >
                    <JxtIconButton icon="calendar-plus" />
                  </Popover.Button>
                  <Popover.Panel ref={refs.setFloating} style={floatingStyles} className="w-[480px] z-10">
                    {!!icsCalendar && <JxtCalendarOverlayContent value={icsCalendar} />}
                  </Popover.Panel>
                </Popover>
              </>
            ) : null}
          </JxtRangeDateFilter>
        </div>

        {isMobile ? (
          <div {...handlers} className="flex flex-col grow min-h-0 gap-2">
            {(() => {
              const workplaceDay = personalWorkplaces.workplaces.get(range.from.toFormat(dayFormat));
              return (
                workplaceDay && (
                  <JxtColleagueWorkplace
                    user={personalWorkplaces}
                    canBeLocated={personalWorkplaces.canBeLocated}
                    onLocate={() => openLocateUserModal(personalWorkplaces)}
                    onToggleFavorite={(favorite: boolean) => handleToggleFavorite(favorite, personalWorkplaces.id)}
                    disableFavorite={true}
                    workplaceDay={workplaceDay}
                    onClick={() =>
                      openChooseWorkplaceModal({
                        workplaceDay,
                        onClose: () => modalWorkplaceContext?.onHide(),
                        disabledMorning: disableIJxtWorkplaceHalfDay(workplaceDay.morning),
                        disabledAfternoon: disableIJxtWorkplaceHalfDay(workplaceDay.afternoon),
                        disabledReset: disableIJxtWorkplaceDayReset(workplaceDay),
                        userInfos: {
                          id: personalWorkplaces.id,
                          firstname: personalWorkplaces.firstname,
                          lastname: personalWorkplaces.lastname,
                        },
                      })
                    }
                  />
                )
              );
            })()}
            <div className="w-full shrink-0 flex items-center gap-2 px-4">
              <div className="w-full lg:py-4 lg:px-6 lg:border-b lg:border-neutral-20">
                <JxtSearchBar
                  name="searchbar-colleagues-mobile"
                  placeholder={t<string>('colleagues-placeholder-search')}
                  value={filter.colleague.globalSearch}
                  onChange={(search: string) => onChangeFilter({ globalSearch: search })}
                />
              </div>
              <JxtButton
                variant={ButtonVariantEnum.Secondary}
                leftIcon="sliders"
                className="lg:hidden"
                onClick={() => setShowFilters(true)}
              >
                {t('search-resource-filter-form-open-filters')}
              </JxtButton>
            </div>
            {filtersAdapted?.length > 0 && (
              <JxtSelectedFilterList className="pl-4">
                {filtersAdapted.map((f) => (
                  <JxtSelectedFilter text={f.value} onClose={f.onRemove} key={f.value} />
                ))}
              </JxtSelectedFilterList>
            )}

            <div id="scrollable-colleagues-list" className="grow shrink overflow-auto">
              <InfiniteScroll
                dataLength={colleaguesWorkplaces.length}
                next={() => fetchNextPage()}
                hasMore={hasNextPage ?? false}
                loader={
                  <div className="flex flex-1 items-center justify-center">
                    <JxtLoader />
                  </div>
                }
                scrollableTarget="scrollable-colleagues-list"
              >
                {colleaguesWorkplaces.map((colleague) => {
                  const workplaceDay = colleague.workplaces.get(range.from.toFormat(dayFormat));
                  return (
                    workplaceDay && (
                      <JxtColleagueWorkplace
                        key={`mobile-${colleague.id}`}
                        user={colleague}
                        canBeLocated={colleague.canBeLocated}
                        onLocate={() => openLocateUserModal(colleague)}
                        onToggleFavorite={(favorite: boolean) => handleToggleFavorite(favorite, colleague.id)}
                        workplaceDay={workplaceDay}
                        disableFavorite={false}
                        onClick={() =>
                          openChooseWorkplaceModal({
                            workplaceDay,
                            onClose: () => modalWorkplaceContext?.onHide(),
                            disabledMorning: disableIJxtWorkplaceHalfDay(workplaceDay.morning),
                            disabledAfternoon: disableIJxtWorkplaceHalfDay(workplaceDay.afternoon),
                            disabledReset: disableIJxtWorkplaceDayReset(workplaceDay),
                            userInfos: {
                              id: colleague.id,
                              firstname: colleague.firstname,
                              lastname: colleague.lastname,
                            },
                          })
                        }
                      />
                    )
                  );
                })}
              </InfiniteScroll>
            </div>
            <div className="flex flex-col px-4 pt-4 pb-6 border-t border-neutral-10 lg:hidden">
              <JxtButton leftIcon="plus" onClick={openOffCanvas}>
                <div className="text-body-s font-medium">{t('my-monthly-workplaces')}</div>
              </JxtButton>
            </div>
            <JxtModal
              show={showFilters}
              onHide={() => setShowFilters(false)}
              header={{ title: t('panel-header.filters'), icon: 'sliders' }}
              footer={
                <JxtButton variant={ButtonVariantEnum.Secondary} className="w-full" onClick={onClickResetFilterButton}>
                  {t('resourcesearch-reset-button')}
                </JxtButton>
              }
            >
              <ColleaguesFilters filter={filter} onChangeFilter={onChangeFilter} user={user} />
            </JxtModal>
          </div>
        ) : (
          <div className="flex flex-col gap-2 px-2 pb-2 w-[calc(100vw-280px)] grow-0 shrink min-h-0">
            <div
              ref={setPersonalRef}
              className="overflow-x-auto grid grid-cols-[250px_repeat(5,minmax(150px,1fr))] gap-x-4 p-2 shrink-0"
            >
              <JxtColleagueCalendarUser
                firstname={personalWorkplaces.firstname}
                lastname={personalWorkplaces.lastname}
                picture={personalWorkplaces.picture}
                disableLocate={!personalWorkplaces.canBeLocated}
                showFavorite={false}
                title={t<string>('colleagues-list-title')}
                onLocate={() => openLocateUserModal(personalWorkplaces)}
              />
              {days.map((day, i) => {
                const workplaceDay = personalWorkplaces.workplaces.get(day);
                if (!workplaceDay) {
                  return (
                    <JxtColleagueCalendarWorkplace
                      key={`personal-${day}-${i}`}
                      workplaceDay={{
                        morning: {
                          type: JxtWorkplaceTypeEnum.Unknown,
                          capabilities: { update: false, cancel: false, create: false },
                        },
                        afternoon: {
                          type: JxtWorkplaceTypeEnum.Unknown,
                          capabilities: { update: false, cancel: false, create: false },
                        },
                        date: fromISO(day),
                      }}
                      title={capitalize(fromISO(day).toFormat('cccc d'))}
                      isToday={sameDay(fromISO(day), now())}
                    />
                  );
                }
                return (
                  <JxtPopover
                    key={`personal-${day}-${i}`}
                    classNames="relative"
                    button={() => (
                      <JxtColleagueCalendarWorkplace
                        workplaceDay={workplaceDay}
                        title={capitalize(fromISO(day).toFormat('cccc d'))}
                        isToday={sameDay(fromISO(day), now())}
                        onClick={handleWorkplaceClick}
                      />
                    )}
                    panel={({ onClose }) => (
                      <JxtChooseWorkplaceOverlayContent
                        workplaceDay={workplaceDay}
                        onClose={onClose}
                        disabledMorning={disableIJxtWorkplaceHalfDay(workplaceDay.morning)}
                        disabledAfternoon={disableIJxtWorkplaceHalfDay(workplaceDay.afternoon)}
                        disabledReset={disableIJxtWorkplaceDayReset(workplaceDay)}
                        userInfos={{
                          id: personalWorkplaces.id,
                          firstname: personalWorkplaces.firstname,
                          lastname: personalWorkplaces.lastname,
                        }}
                      />
                    )}
                  />
                );
              })}
            </div>
            <div id="scrollable-colleagues-list" className="overflow-auto">
              <InfiniteScroll
                dataLength={colleaguesWorkplaces.length}
                next={() => fetchNextPage()}
                hasMore={hasNextPage ?? false}
                loader={
                  <div className="flex flex-1 items-center justify-center">
                    <JxtLoader />
                  </div>
                }
                scrollableTarget="scrollable-colleagues-list"
              >
                <div
                  ref={setColleaguesRef}
                  className="overflow-x-auto grid grid-cols-[250px_repeat(5,minmax(150px,1fr))] gap-x-4 gap-y-2 px-2"
                >
                  {colleaguesWorkplaces.map((colleague) => (
                    <Fragment key={`fragment-${colleague.id}`}>
                      <JxtColleagueCalendarUser
                        key={`desktop-${colleague.id}`}
                        firstname={colleague.firstname}
                        lastname={colleague.lastname}
                        picture={colleague.picture}
                        isFavorite={colleague.favorite}
                        disableLocate={!colleague.canBeLocated}
                        onLocate={() => openLocateUserModal(colleague)}
                        onToggleFavorite={(favorite: boolean) => handleToggleFavorite(favorite, colleague.id)}
                        disableFavorite={false}
                      />
                      {days.map((day, i) => {
                        const workplaceDay = colleague.workplaces.get(day);
                        if (!workplaceDay) {
                          return (
                            <JxtColleagueCalendarWorkplace
                              key={`colleagues-${colleague.id}-${day}-${i}`}
                              workplaceDay={{
                                morning: {
                                  type: JxtWorkplaceTypeEnum.Unknown,
                                  capabilities: { update: false, cancel: false, create: false },
                                },
                                afternoon: {
                                  type: JxtWorkplaceTypeEnum.Unknown,
                                  capabilities: { update: false, cancel: false, create: false },
                                },
                                date: fromISO(day),
                              }}
                            />
                          );
                        }
                        return (
                          <JxtPopover
                            key={`colleagues-${colleague.id}-${day}-${i}`}
                            classNames="relative"
                            button={() => (
                              <JxtColleagueCalendarWorkplace
                                workplaceDay={workplaceDay}
                                onClick={handleWorkplaceClick}
                              />
                            )}
                            panel={({ onClose }) => (
                              <JxtChooseWorkplaceOverlayContent
                                workplaceDay={workplaceDay}
                                onClose={onClose}
                                disabledMorning={disableIJxtWorkplaceHalfDay(workplaceDay.morning)}
                                disabledAfternoon={disableIJxtWorkplaceHalfDay(workplaceDay.afternoon)}
                                disabledReset={disableIJxtWorkplaceDayReset(workplaceDay)}
                                userInfos={{
                                  id: colleague.id,
                                  firstname: colleague.firstname,
                                  lastname: colleague.lastname,
                                }}
                              />
                            )}
                          />
                        );
                      })}
                    </Fragment>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
      {!!modalWorkplaceContext && <JxtModalWorkplace {...modalWorkplaceContext} />}
    </ChooseWorkplaceOverlayProvider>
  );
};
