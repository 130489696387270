import { useCallback, useEffect, useState } from 'react';

export function useBrowserLanguage() {
  const [lang, setLang] = useState<string>(window.navigator.language);
  const handleLanguageChange = useCallback(() => setLang((current) => current), []);

  useEffect(() => {
    if (window.navigator.language) {
      setLang(window.navigator.language);
      window.addEventListener('languagechange', handleLanguageChange);
      return () => window.removeEventListener('languagechange', handleLanguageChange);
    }

    return undefined;
  }, []);

  return lang;
}
