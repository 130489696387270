import { createContext, useContext } from 'react';

export interface BookingFormContextType {
  showBooking: number | false;
  showBookingDetails: (id: number) => void;
  closeBookingDetails: () => void;
}

export const BookingFormContext = createContext<BookingFormContextType | null>(null);

export const useBookingFormContext = (): BookingFormContextType | null => {
  const context = useContext(BookingFormContext);

  return context;
};
