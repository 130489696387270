import { JxtEventColorInfos } from '@jooxter/utils';
import clsx from 'clsx';
import JxtIconButton from '../JxtIconButton';
import { JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from '../JxtIconButton/types';
import { BookingDisplayModeEnum, IJxtCalendarEvent } from './types';
import { getClassNames } from './styles';

const JxtCalendarEvent = ({
  id,
  mode = BookingDisplayModeEnum.Regular,
  color,
  hours,
  text,
  notSelected,
  isValidated = true,
  nbAttendees = 0,
  isRecurrence = false,
  isDeclined = false,
  optionIconsList = [],
  onClickEvent,
  onClickEdit,
  onClickCheckIn,
  onClickCheckOut,
  onClickDelete,
}: IJxtCalendarEvent) => {
  const bgColor = notSelected
    ? clsx('bg-transparent', JxtEventColorInfos[color].hoverBgColor)
    : JxtEventColorInfos[color].bgColor;
  const borderColor = notSelected ? 'border-transparent' : JxtEventColorInfos[color].borderColor;
  const textColor = JxtEventColorInfos[color].textColor;
  return (
    <div
      className={clsx(
        'w-full rounded-lg gap-2 hover:cursor-pointer border flex overflow-hidden',
        bgColor,
        borderColor,
        getClassNames(mode)
      )}
      onClick={onClickEvent ? () => onClickEvent(id) : undefined}
    >
      <div
        className={clsx(
          'flex text-body-s font-normal truncate',
          textColor,
          isDeclined ? 'line-through' : '',
          [BookingDisplayModeEnum.Compact, BookingDisplayModeEnum.NoPadding].includes(mode)
            ? 'flex-col shrink-0'
            : 'gap-2'
        )}
      >
        {hours && <span>{hours}</span>}
        <span className="truncate">{text}</span>
      </div>
      {(isRecurrence || !isValidated || nbAttendees > 0) && (
        <div className={clsx('flex items-center gap-2', textColor)}>
          {isRecurrence && <i className="fas fa-arrows-rotate fa-xs" />}
          {!isValidated && <i className="fas fa-hourglass-half fa-xs" />}
          {nbAttendees > 0 && (
            <div className="flex items-center gap-1">
              <i className="fas fa-user fa-xs" />
              <span className="text-body-xs font-normal">{nbAttendees}</span>
            </div>
          )}
        </div>
      )}
      {optionIconsList.length > 0 && (
        <div className="items-center flex gap-1 shrink-0">
          {optionIconsList.map((icon, i) => (
            <img key={`${icon}-${i}`} src={icon} height="16" width="16" />
          ))}
        </div>
      )}
      {(onClickCheckIn || onClickCheckOut || onClickEdit || onClickDelete) && (
        <div
          className={clsx('flex flex-grow items-center', mode === BookingDisplayModeEnum.Compact ? '' : 'justify-end')}
        >
          {onClickCheckIn && (
            <JxtIconButton
              icon="check"
              type={JxtIconButtonTypeEnum.Light}
              size={JxtIconButtonSizeEnum.S}
              onClick={onClickCheckIn}
            />
          )}
          {onClickCheckOut && (
            <JxtIconButton
              icon="arrow-right-from-bracket"
              type={JxtIconButtonTypeEnum.Light}
              size={JxtIconButtonSizeEnum.S}
              onClick={onClickCheckOut}
            />
          )}
          {onClickEdit && (
            <JxtIconButton
              icon="pen"
              type={JxtIconButtonTypeEnum.Light}
              size={JxtIconButtonSizeEnum.S}
              onClick={onClickEdit}
            />
          )}
          {onClickDelete && (
            <JxtIconButton
              icon="trash"
              type={JxtIconButtonTypeEnum.Light}
              size={JxtIconButtonSizeEnum.S}
              onClick={onClickDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default JxtCalendarEvent;
