import { Menu } from '@headlessui/react';
import { clsx } from 'clsx';
import { NavLink, NavLinkProps } from 'react-router';
import { NavbarDropdownItemSizeEnum, IJxtNavbarDropdownItem } from './types';

const JxtNavbarDropdownItem = ({
  icon,
  text,
  to,
  interactive = true,
  size = NavbarDropdownItemSizeEnum.M,
  onClick,
  openInNewTab,
}: IJxtNavbarDropdownItem) => {
  let menuItemBaseClass = 'list-none text-neutral-140 bg-white';
  const attributes = openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  const linkClasses: NavLinkProps['className'] = ({ isActive }) => {
    const staticClasses = 'w-full inline-block text-body-s text-[#707070] font-["Open_Sans"]';
    return clsx(
      staticClasses,
      isActive ? 'shadow-nav-item-left-primary-100' : '',
      size === NavbarDropdownItemSizeEnum.M ? 'py-1 px-5 my-0.5 leading-[26px]' : 'py-1.5 px-5 leading-[39px]'
    );
  };

  if (interactive) {
    menuItemBaseClass +=
      ' cursor-pointer active:shadow-nav-item-left-primary-100 focus:shadow-nav-item-left-primary-60 hover:shadow-nav-item-left-primary-60';
  }

  const content = (
    <>
      {icon && <i className={`fas fa-${icon} text-neutral-60 mr-1 ml-[1px]`} />}
      {icon && '\u00a0'}
      {text}
    </>
  );

  const linkElement = to ? (
    <NavLink to={to} className={linkClasses} {...attributes} title={text} onClick={onClick}>
      {content}
    </NavLink>
  ) : (
    <p
      className={clsx(
        size === NavbarDropdownItemSizeEnum.M ? 'py-1 px-5 my-0.5 leading-[26px]' : 'py-1.5 px-5 leading-[39px]',
        'text-body-s text-[#707070] font-["Open_Sans"]'
      )}
    >
      {content}
    </p>
  );

  return (
    <Menu.Item as="li" onClick={onClick} className={menuItemBaseClass}>
      {linkElement}
    </Menu.Item>
  );
};

export default JxtNavbarDropdownItem;
