import { useEffect } from 'react';

export const useWootric = () => {
  useEffect(() => {
    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://cdn.wootric.com/wootric-sdk.js';
    document.getElementsByTagName('head')[0].appendChild(s);
  }, []);
};
