import { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createGTMGAEvent, isAddin } from '@jooxter/utils';
import { AuthContext } from './AuthContext';
import {
  LoginForm,
  LoginService,
  UserLoginDetailApiVersionEnum,
  AxiosInstanceEnum,
  UserLoginDetailTypeEnum,
  axiosManager,
  axiosInstanceV4,
} from '@jooxter/api';
import { signIn, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useStore } from '../store';
import { AuthV3Service } from './authV3.service';
import { AuthV4Service } from './authV4.service';
import { IAuthService } from './types';
import { useFetchLoginDetails } from '../queries';
import { CookieService } from '@jooxter/storage';
import { useHeartBeat } from '../mutations/user';
import { REDIRECT_AFTER_LOGIN } from '../routing/constants';
import { useShallow } from 'zustand/shallow';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [email, token, setToken, deleteToken, setEmail, setAxiosInstance] = useStore(
    useShallow((state) => [
      state.email,
      state.token,
      state.setToken,
      state.deleteToken,
      state.setEmail,
      state.setAxiosInstance,
    ])
  );
  const [heartBeatDone, setHeartBeatDone] = useState<boolean>(false);
  const { mutateAsync } = useHeartBeat();
  const { loginDetails: userLoginDetail } = useFetchLoginDetails(email);
  const shouldBeMigratedToCognito = userLoginDetail?.shouldBeMigratedToCognito;
  const authService: IAuthService = useMemo(
    () => (shouldBeMigratedToCognito ? AuthV4Service.getInstance() : AuthV3Service.getInstance()),
    [shouldBeMigratedToCognito]
  );

  useLayoutEffect(() => {
    const emailFromCookie = CookieService.getCookie('email');
    if (emailFromCookie && !email) {
      setEmail(emailFromCookie);
    }

    AuthV4Service.getInstance()
      .getToken()
      .then((t) => {
        if (t) {
          setAxiosInstance(AxiosInstanceEnum.axiosInstanceV4);
          setToken(t);
        }
      });

    // we want this to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && !heartBeatDone) {
      mutateAsync().then(() => setHeartBeatDone(true));
    }
  }, [token, heartBeatDone, mutateAsync]);

  const onLoginSuccess = useCallback(
    (token: string) => {
      authService.setToken && authService.setToken(token);
      createGTMGAEvent('Login', 'Check Password', 'Valid Password');
      setToken(token);
    },
    [authService, setToken]
  );

  const loginSSO = useCallback((): Promise<void | Error> => {
    if (!userLoginDetail) {
      return Promise.reject(new Error('No user login detail found'));
    }

    if (userLoginDetail.shouldBeMigratedToCognito && userLoginDetail.type === UserLoginDetailTypeEnum.Saml2) {
      if (userLoginDetail.cognitoFederatedIdentityProvider) {
        return signInWithRedirect({
          provider: { custom: userLoginDetail.cognitoFederatedIdentityProvider },
        });
      } else {
        // if cognitoFederatedIdentityProvider not found, attempt to login with SSO V3
        console.warn('No cognito federated identity provider found, attempting to login with SSO V3');
      }
    }

    if (!userLoginDetail.redirect) {
      return Promise.reject(new Error('No redirection url found'));
    }

    window.location.assign(userLoginDetail.redirect);
    return Promise.resolve();
  }, [userLoginDetail]);

  const login = useCallback(
    ({ email, password }: LoginForm) => {
      if (!email) {
        return Promise.reject(new Error('Email is required'));
      }

      if (authService instanceof AuthV3Service) {
        return LoginService.logIn({ email, password }).then(onLoginSuccess);
      }

      return signIn({
        username: email,
        password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
          preferredChallenge: 'PASSWORD_SRP', // or 'PASSWORD'
        },
      })
        .then(() => authService.getToken())
        .then((accessToken) => {
          if (!accessToken) {
            throw new Error('No access token found');
          }

          if (userLoginDetail?.apiVersion === UserLoginDetailApiVersionEnum.V3) {
            const decoded = jwtDecode<JwtPayload & { username?: string }>(accessToken);
            const username = decoded?.username;

            if (!username) {
              throw new Error('No username found in payload');
            }

            setToken(accessToken);
            setAxiosInstance(AxiosInstanceEnum.axiosInstanceV4);
          } else {
            onLoginSuccess(accessToken);
          }
        });
    },
    [authService, onLoginSuccess, userLoginDetail?.apiVersion, setToken, setAxiosInstance]
  );

  const logout = useCallback(async () => {
    if (!userLoginDetail) {
      // the email has been manually deleted from localstorage, this should not happend
      // try both logout methods
      await signOut({
        global: false,
        oauth: {
          redirectUrl: window.location.origin,
        },
      });
      authService?.deleteToken && authService.deleteToken();
    }

    if (userLoginDetail?.apiVersion === UserLoginDetailApiVersionEnum.V4) {
      await signOut(
        userLoginDetail?.type === UserLoginDetailTypeEnum.Saml2
          ? {
              global: false,
              oauth: {
                redirectUrl: window.location.origin,
              },
            }
          : undefined
      );
    } else {
      const authInstance = AuthV3Service.getInstance();
      if (authInstance.deleteToken) {
        authInstance.deleteToken(isAddin() ? { sameSite: 'none' } : undefined);
      }
    }

    CookieService.deleteCookie(REDIRECT_AFTER_LOGIN);
    deleteToken();
  }, [authService, deleteToken, userLoginDetail]);

  useEffect(() => {
    if (authService instanceof AuthV4Service) {
      setAxiosInstance(AxiosInstanceEnum.axiosInstanceV4);
    } else if (axiosManager.getInstance() === axiosInstanceV4) {
      setAxiosInstance(AxiosInstanceEnum.axiosInstanceV3);
    }

    authService
      .getToken()
      .then((token) => {
        if (token) {
          setToken(token);
        }
      })
      .catch(() => {
        deleteToken();
      });
  }, [authService, deleteToken, setAxiosInstance, setToken]);

  return <AuthContext.Provider value={{ login, logout, loginSSO }}>{children}</AuthContext.Provider>;
};
