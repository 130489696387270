import { UserRequestExtendedLang } from '@jooxter/api';
import { getJooxterLanguageSubdomain, langMapping } from '@jooxter/i18n';
import { HardRedirectService } from '@jooxter/utils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

export const useLanguageSubdomainRedirect = (lang: UserRequestExtendedLang) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const redirect = useCallback(() => {
    if (HardRedirectService.shouldRedirect() && lang !== getJooxterLanguageSubdomain()) {
      HardRedirectService.redirect(HardRedirectService.getRedirectUrl(lang, location.pathname));
    } else {
      i18n.changeLanguage(langMapping[lang]);
    }
  }, [lang]);

  return { redirect };
};
