export enum JxtStarSizeEnum {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum JxtStarTypeEnum {
  Filled = 'Filled',
  Light = 'Light',
}

export interface IJxtStar {
  checked: boolean;
  disabled: boolean;
  type: JxtStarTypeEnum;
  size: JxtStarSizeEnum;
  onClick?: (checked: boolean) => void;
}
