import { axiosManager } from '../config';
import { WorkplaceDto, WorkplaceRequestDto } from '../model';

export const WorkplaceService = {
  path: 'users',

  postWorkplace(userId: number, payload: WorkplaceRequestDto): Promise<WorkplaceDto> {
    const url = `${this.path}/${userId}/workplaces`;

    return axiosManager
      .getInstance()
      .post(url, payload)
      .then((res) => res.data);
  },

  postWorkplaces(userId: number, payload: WorkplaceRequestDto[]): Promise<WorkplaceDto[]> {
    const url = `${this.path}/${userId}/workplaces/bulk`;

    return axiosManager
      .getInstance()
      .post(url, payload)
      .then((res) => res.data);
  },
};
