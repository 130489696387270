// eslint-disable-next-line import/named
import { FieldValues, FormProvider, useForm, UseFormRegister, useWatch } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useGenerateBookingForm } from './useGenerateBookingForm';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  JxtAlertTypeEnum,
  JxtButton,
  JxtExternalLinkModal,
  JxtToast,
  JxtToastVariantEnum,
  JxtUpdateBookingModal,
  dateNotInPastSuperRefinerFactory,
  startBeforeEndSuperRefinerFactory,
} from '@jooxter/ui';
import {
  BookingQueryKeys,
  FloorQueryKeys,
  ResourceQueryKeys,
  useFetchRemainingBookingsCheck,
  useFetchResource,
  useFetchResourceOptions,
  useFetchResourceStatus,
  useFetchResourceTypes,
  useFetchUser,
  useFetchUserById,
  useInvalidateBookingQueries,
  useInvalidateWorkplaceQueries,
} from '../../../queries';
import { IBookingFormPreset, TResourceConfiguration } from '../types/booking-form.types';
import { useBookingFormDefaultValues } from './useBookingFormDefaultValues';
import { useBookingSchema } from '../schemas/useBookingSchema';
import {
  Booking,
  BookingExternalAction,
  BookingExternalActionTypeEnum,
  BookingRequest,
  isRecurrenceDto,
  RecurrenceDto,
  ResourceStateDto,
  ResourceTypeDtoMetaTypeEnum,
  WorkplaceRuleDtoWorkplaceTypeEnum,
} from '@jooxter/api';
import { JxtAttendeeOption, JxtAttendeeOptionTypeEnum, JxtWorkplaceStrategyLimitContainer } from '../../../components';
import { useTranslation } from 'react-i18next';
import {
  createGTMGAEvent,
  createGTMUpdateVirtualPathEvent,
  getMinBetweenNextHourAndEndOfDay,
  getStandardTime,
  fromISO,
  IBookingForm,
  IQuickTimeSlot,
  ISOToLocalDate,
  ISOToLocalTime,
  JxtWorkplaceTypeWithoutUnknownEnum,
} from '@jooxter/utils';
import { useMinMaxAttendees } from './useMinMaxAttendees';
import JxtAlert from '@jooxter/ui/src/components/JxtAlert';
import { AxiosError } from 'axios';
import { useUpdateBooking } from '../../../mutations';
import { DateTime } from 'luxon';
import { useIBookingFormToBookingRequestAdapter } from '../../../adapters/bookings/useIBookingFormToBookingRequestAdapter';
import toast from 'react-hot-toast';
import { useBookingFormContext } from './useBookingFormContext';
import { useBookingActions, useButtonStatus, useModalViolationsContext, useOffCanvas } from '../../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

export const useBookingForm = (
  bookingToEdit?: Booking | null,
  bookingFormPreset?: IBookingFormPreset,
  slotToBookInstantly?: IQuickTimeSlot | null
) => {
  const { t } = useTranslation();
  const [resourceConfiguration, setResourceConfiguration] = useState<TResourceConfiguration>();
  const { children, setLoading, loading } = useButtonStatus();
  const { resourceTypes } = useFetchResourceTypes();
  const bookingFormDefaultValues = useBookingFormDefaultValues(bookingFormPreset, resourceConfiguration, bookingToEdit);
  const [resourceState, setResourceState] = useState<ResourceStateDto>();
  const { minPhysicalAttendees, maxPhysicalAttendees } = useMinMaxAttendees(
    resourceConfiguration,
    resourceState,
    bookingToEdit
  );
  const schema = useBookingSchema(
    minPhysicalAttendees,
    maxPhysicalAttendees,
    !!bookingToEdit,
    resourceConfiguration,
    bookingFormDefaultValues
  );
  const [attendees, setAttendees] = useState<JxtAttendeeOption[]>([]);
  const [alert, setAlert] = useState<React.ReactElement>();
  const [showUpdateBookingModal, setShowUpdateBookingModal] = useState<boolean>(false);
  const [newBooking, setNewBooking] = useState<BookingRequest>();
  const [showExternalLinksModal, setShowExternalLinksModal] = useState<boolean>(false);
  const [hasRemainingBookings, setHasRemainingBookings] = useState<boolean | undefined>();
  const [externalLinksToVisit, setExternalLinksToVisit] = useState<BookingExternalAction[]>();
  const { user } = useFetchUser();
  const { user: organizerInPreset } = useFetchUserById(bookingFormPreset?.organizerId);
  const { create, update } = useBookingActions();
  const { mutate: mutationUpdateRecurrences } = useUpdateBooking(true);
  const [bookingFormToBookingRequestAdapter] = useIBookingFormToBookingRequestAdapter();
  const bookingFormContext = useBookingFormContext();
  const offCanvasContext = useOffCanvas();
  const modalViolationsContext = useModalViolationsContext();
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();

  const schemaRefined = useMemo(() => {
    if (resourceConfiguration?.isZone) {
      return schema
        .superRefine((data, ctx: z.RefinementCtx) => {
          if (
            data.physicalAttendees > minPhysicalAttendees &&
            data.physicalAttendees < data.internalAttendees.length + data.externalAttendees.length
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t<string>('capacity-attendees.mismatch.error-react', {
                requestedSeats: data.physicalAttendees,
                attendees: data.internalAttendees.length + data.externalAttendees.length - data.physicalAttendees,
              }),
              path: ['internalAttendees'],
            });
          }
        })
        .superRefine(
          dateNotInPastSuperRefinerFactory(
            'booking-form.error.date-not-in-past',
            resourceConfiguration?.timezone,
            !!bookingToEdit
          )
        )
        .superRefine(startBeforeEndSuperRefinerFactory('booking-form.error.compare-date'));
    }

    return schema
      .superRefine(
        dateNotInPastSuperRefinerFactory(
          'booking-form.error.date-not-in-past',
          resourceConfiguration?.timezone,
          !!bookingToEdit
        )
      )
      .superRefine(startBeforeEndSuperRefinerFactory('booking-form.error.compare-date'));
  }, [schema]);

  const form = useForm<IBookingForm>({
    resolver: zodResolver(schemaRefined),
    defaultValues: bookingFormDefaultValues,
    mode: 'all',
  });

  const { register, reset, handleSubmit, control, formState, getFieldState, getValues, setValue, trigger } = form;

  const { touchedFields } = formState;
  const watchSummary = useWatch({ name: 'summary', control });
  const watchStart = useWatch({ name: 'start', control });
  const watchEnd = useWatch({ name: 'end', control });
  const watchResource = useWatch({ name: 'resource', control });
  const watchPhysicalAttendees = useWatch({ name: 'physicalAttendees', control });
  const watchInternalAttendees = useWatch({ name: 'internalAttendees', control });
  const watchExternalAttendees = useWatch({ name: 'externalAttendees', control });
  const watchOrganizerId = useWatch({ name: 'organizerId', control });
  const { status } = useFetchResourceStatus({
    resourceId: watchResource.id,
    start: watchStart.date,
    end: watchEnd.date,
  });
  const autoIncrement: boolean = useMemo(() => {
    if (touchedFields?.end) {
      return false;
    }

    return watchEnd.date.diff(watchStart.date, 'minutes').minutes === 60;
  }, [touchedFields?.end]);

  useEffect(() => {
    if (watchStart.date.isValid && watchEnd.date.isValid && autoIncrement) {
      const newEnd = getMinBetweenNextHourAndEndOfDay(watchStart.date);
      setValue(
        'end',
        {
          date: newEnd,
          time: getStandardTime(newEnd),
        },
        {
          shouldTouch: false,
        }
      );
    }
  }, [watchStart]);

  const shouldCheckRemainingBookings = useMemo(() => {
    if (
      !resourceConfiguration?.metaType ||
      ![ResourceTypeDtoMetaTypeEnum.Desk, ResourceTypeDtoMetaTypeEnum.Parking].includes(resourceConfiguration.metaType)
    ) {
      return false;
    }

    if (!bookingToEdit) {
      return true;
    }

    const startDay = watchStart.date.startOf('day');
    const endDay = watchEnd.date.startOf('day');
    const oldStartDay = fromISO(bookingToEdit.start.dateTime, bookingToEdit.resource.timezone).startOf('day');
    const oldEndDay = fromISO(bookingToEdit.end.dateTime, bookingToEdit.resource.timezone).startOf('day');
    const isSameDay = startDay.equals(oldStartDay) && endDay.equals(oldEndDay);

    return !isSameDay;
  }, [bookingToEdit, watchStart, watchEnd, resourceConfiguration]);
  const { data: remainingBookingsCheck, isError: isFetchRemainingBookingsError } = useFetchRemainingBookingsCheck(
    {
      locationId: watchResource.locationId,
      from: getValues('start.date'),
      to: getValues('end.date'),
      ownerId: watchOrganizerId,
    },
    shouldCheckRemainingBookings
  );
  const shouldDisableSubmitButton =
    !formState.isValid || (hasRemainingBookings === false && shouldCheckRemainingBookings);

  useEffect(() => {
    if (resourceConfiguration?.metaType === ResourceTypeDtoMetaTypeEnum.Vehicle) {
      trigger('summary');
    }
  }, [resourceConfiguration?.metaType, watchSummary, schemaRefined, schema]);

  useEffect(() => {
    // https://react-hook-form.com/docs/useform#defaultValues
    // defaultValues are cached. To reset them, use the reset API.
    reset(bookingFormDefaultValues);
  }, [bookingFormDefaultValues]);

  useEffect(() => {
    if (isFetchRemainingBookingsError) {
      // if for some reason the request fail, we still want to enable the submit button
      return setHasRemainingBookings(true);
    }

    const canBooking = remainingBookingsCheck?.every(
      (item: { rulePeriod: string; remainingDays: number }) => item.remainingDays > 0
    );
    setHasRemainingBookings(canBooking);
  }, [remainingBookingsCheck, isFetchRemainingBookingsError]);

  const generatedForm = useGenerateBookingForm(
    {
      register: register as unknown as UseFormRegister<FieldValues>,
      formState,
      getFieldState,
      getValues,
      setValue,
      control,
    },
    {
      minPhysicalAttendees,
      maxPhysicalAttendees,
      schema,
      resourceConfiguration,
      bookingToEdit,
    },
    attendees,
    setAttendees,
    resourceState
  );

  const { resource } = useFetchResource(watchResource?.id);
  const { options } = useFetchResourceOptions(watchResource?.id);
  const queryClient = useQueryClient();
  const { invalidateBookingQueries } = useInvalidateBookingQueries();

  useEffect(() => {
    trigger('internalAttendees');
  }, [watchPhysicalAttendees, watchInternalAttendees, watchExternalAttendees]);

  useEffect(() => {
    trigger('physicalAttendees');
  }, [watchPhysicalAttendees, minPhysicalAttendees, maxPhysicalAttendees]);

  useEffect(() => {
    if (!bookingToEdit) {
      return;
    }

    const resourceType = resourceTypes?.find((r) => r.id === bookingToEdit.resource.resourceTypeId);

    if (resourceType) {
      setResourceConfiguration({
        timezone: bookingToEdit.resource.timezone,
        guestsRequired: bookingToEdit.resource.guestsRequired,
        physicalAttendeesRequired: bookingToEdit.resource.physicalAttendeesRequired,
        isZone: resourceType.allowOverlap,
        metaType: resourceType.metaType,
        recurrence: bookingToEdit.resource.capabilities?.recurrence,
        capacity: bookingToEdit.resource.capacity,
        slots: resource?.slots,
        options: options,
      });
      setAttendees(
        bookingToEdit.attendees.internal
          .map((a) => ({
            type: JxtAttendeeOptionTypeEnum.INTERNAL,
            id: a.id,
            text: a.name + (a.id === user?.id ? ` (${t('you')})` : ''),
          }))
          .concat(
            bookingToEdit.attendees.external.map((a) => ({
              type: JxtAttendeeOptionTypeEnum.EXTERNAL,
              id: a.id,
              text: a.email,
            }))
          )
      );
    }
  }, [bookingToEdit, resourceTypes, resource, options]);

  useEffect(() => {
    if (!watchResource?.id || !resourceTypes?.length || bookingToEdit) {
      return;
    }

    const resourceType = resourceTypes?.find((r) => r.id === watchResource.resourceTypeId);

    if (resourceType) {
      setResourceConfiguration({
        timezone: watchResource.timezone,
        guestsRequired: watchResource.guestsRequired,
        physicalAttendeesRequired: watchResource.physicalAttendeesRequired,
        isZone: resourceType.allowOverlap,
        metaType: resourceType.metaType,
        recurrence: watchResource.capabilities?.recurrence,
        capacity: watchResource.capacity,
        slots: resource?.slots,
        options: options,
      });
    }
  }, [watchResource?.id, resourceTypes, resource, options, bookingToEdit]);

  useEffect(() => {
    setResourceState(status);
  }, [status]);

  useEffect(() => {
    if (!bookingToEdit && resourceConfiguration?.isZone && user) {
      setAttendees([
        {
          type: JxtAttendeeOptionTypeEnum.INTERNAL,
          id: organizerInPreset?.id ?? user.id,
          text: `${organizerInPreset?.firstname ?? user.firstname} ${organizerInPreset?.lastname ?? user.lastname} ${
            !organizerInPreset || organizerInPreset.id === user.id ? `(${t('you')})` : ''
          }`,
        },
      ]);
    }
  }, [bookingToEdit, resourceConfiguration]);

  useEffect(() => {
    if (slotToBookInstantly && formState.isValid) {
      onSubmit(getValues());
    }
  }, [slotToBookInstantly]);

  const onSuccessUpdate = (booking: Booking, recurrence: boolean) => {
    invalidateBookingQueries([booking.id]);
    queryClient.invalidateQueries({
      queryKey: [FloorQueryKeys.GetFloorPlanResources],
    });
    queryClient.invalidateQueries({
      queryKey: [ResourceQueryKeys.GetResourceStatus],
    });
    offCanvasContext?.close();

    const bookingDate = ISOToLocalDate(booking.start.dateTime, booking.resource.timezone);
    const startTime = ISOToLocalTime(booking.start.dateTime, booking.resource.timezone);
    const endDate = ISOToLocalDate(booking.end.dateTime, booking.resource.timezone);
    const endTime = ISOToLocalTime(booking.end.dateTime, booking.resource.timezone);
    const isSingleDayBooking = bookingDate === endDate;

    toast.custom(
      (newToast) => (
        <JxtToast
          variant={JxtToastVariantEnum.Success}
          onHide={() => toast.remove(newToast.id)}
          title={recurrence ? t<string>('booking-and-following-occurrences-updated') : t<string>('update-saved')}
          subtitle={
            isSingleDayBooking
              ? t<string>('booking-updated-message', {
                  resourceName: booking.resource.name,
                  bookingDate: bookingDate,
                  startTime: startTime,
                  endTime: endTime,
                })
              : t<string>('booking-updated-message-with-end-date', {
                  resourceName: booking.resource.name,
                  bookingDate: bookingDate,
                  startTime: startTime,
                  endDate: endDate,
                  endTime: endTime,
                })
          }
        />
      ),
      { position: 'bottom-left' }
    );
  };

  const onSuccessCreate = (booking: Booking | RecurrenceDto) => {
    queryClient.setQueryData([BookingQueryKeys.GetBooking, booking.id], booking);
    invalidateBookingQueries([booking.id]);
    invalidateWorkplaceQueries();
    queryClient.invalidateQueries({
      queryKey: [FloorQueryKeys.GetFloorPlanResources],
    });
    queryClient.invalidateQueries({
      queryKey: [ResourceQueryKeys.GetResourceStatus],
    });

    const bookingOptionsLink = booking.options
      .filter((opt) => opt.link?.href && opt.link.type === BookingExternalActionTypeEnum.Link)
      .map((o) => o.link);

    if (bookingOptionsLink.length > 0) {
      setExternalLinksToVisit(bookingOptionsLink as BookingExternalAction[]);
      setShowExternalLinksModal(true);
    } else {
      offCanvasContext?.close();
    }

    const bookingDate = ISOToLocalDate(booking.start.dateTime, booking.resource.timezone);
    const startTime = ISOToLocalTime(booking.start.dateTime, booking.resource.timezone);
    const endDate = ISOToLocalDate(booking.end.dateTime, booking.resource.timezone);
    const endTime = ISOToLocalTime(booking.end.dateTime, booking.resource.timezone);
    const isSingleDayBooking = bookingDate === endDate;

    toast.custom(
      (newToast) => (
        <JxtToast
          onHide={() => toast.remove(newToast.id)}
          title={t<string>('booking-created')}
          subtitle={
            isSingleDayBooking
              ? t<string>('booking-created-message', {
                  resourceName: booking.resource.name,
                  bookingDate: bookingDate,
                  startTime: startTime,
                  endTime: endTime,
                })
              : t<string>('booking-created-message-with-end-date', {
                  resourceName: booking.resource.name,
                  bookingDate: bookingDate,
                  startTime: startTime,
                  endDate: endDate,
                  endTime: endTime,
                })
          }
          variant={JxtToastVariantEnum.Success}
        >
          <JxtButton
            className="!p-0"
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => {
              bookingFormContext?.showBookingDetails(booking.id);
            }}
          >
            {t('open-booking')}
          </JxtButton>
        </JxtToast>
      ),
      {
        position: 'bottom-left',
      }
    );

    if (isRecurrenceDto(booking) && booking.violations && Object.keys(booking.violations).length) {
      toast.custom(
        (newToast) => (
          <JxtToast
            variant={JxtToastVariantEnum.Failure}
            onHide={() => toast.remove(newToast.id)}
            title={t<string>('occurrences.violations.not-created', {
              numberOfViolations: Object.keys(booking.violations).length,
            })}
          >
            <JxtButton
              className="!p-0"
              size={ButtonSizeEnum.Large}
              variant={ButtonVariantEnum.Link}
              onClick={() => modalViolationsContext?.showViolations(booking as RecurrenceDto)}
            >
              {t('see-details')}
            </JxtButton>
          </JxtToast>
        ),
        { position: 'bottom-left' }
      );
    }
  };

  const onError = (error: unknown) => {
    const axiosError = error as AxiosError;
    const response = axiosError.response?.data as { error: string; description: string }[];
    if (response.length > 0) {
      setAlert(<JxtAlert type={JxtAlertTypeEnum.Error} text={response[0].error} closeable />);
    }
  };

  const onSettled = () => {
    setLoading(false);
  };

  const onSubmit = (data: IBookingForm) => {
    setLoading(true);

    if (!bookingToEdit) {
      createGTMGAEvent('Booking', 'Create Booking', 'Validation');
    } else {
      createGTMGAEvent('Booking', 'Modify', 'Modify booking');
    }
    setAlert(undefined);
    const result = schema.safeParse(data);
    if (!result.success) {
      return;
    }
    const bookingRequest = bookingFormToBookingRequestAdapter(data);
    setNewBooking(bookingRequest);

    if (!bookingToEdit) {
      create(bookingRequest, onSuccessCreate, onError, onSettled);
    } else if (bookingToEdit.rrule) {
      setShowUpdateBookingModal(true);
      createGTMUpdateVirtualPathEvent('/BookingUpdateConfirmation');
    } else {
      update(
        { id: bookingToEdit.id, payload: bookingRequest },
        (booking: Booking) => onSuccessUpdate(booking, false),
        onError,
        onSettled
      );
    }
  };

  const onConfirmUpdate = (recurrence?: boolean) => {
    if (!bookingToEdit || !newBooking) {
      return;
    }

    if (recurrence) {
      mutationUpdateRecurrences(
        { id: bookingToEdit.id, payload: newBooking },
        {
          onSuccess: (booking: Booking) => onSuccessUpdate(booking, true),
          onError,
          onSettled,
        }
      );
    } else {
      update(
        { id: bookingToEdit.id, payload: newBooking },
        (booking: Booking) => onSuccessUpdate(booking, false),
        onError,
        onSettled
      );
    }
  };

  const getStrategyLimitContainer = useMemo(() => {
    if (!remainingBookingsCheck) {
      return;
    }

    if (
      !resourceConfiguration?.metaType ||
      ![ResourceTypeDtoMetaTypeEnum.Desk, ResourceTypeDtoMetaTypeEnum.Parking].includes(resourceConfiguration.metaType)
    ) {
      return;
    }
    return (
      <JxtWorkplaceStrategyLimitContainer
        workplaceType={WorkplaceRuleDtoWorkplaceTypeEnum.Office as unknown as JxtWorkplaceTypeWithoutUnknownEnum}
        locationId={watchResource.locationId}
        organizerId={watchOrganizerId}
        remainingBookings={remainingBookingsCheck}
        isBooking={true}
      />
    );
  }, [remainingBookingsCheck, resourceConfiguration]);

  if (!bookingFormDefaultValues) {
    return [];
  }

  return (
    <FormProvider<IBookingForm> {...form}>
      <form
        key="form"
        className="w-full h-full flex flex-col justify-between overflow-hidden -mt-1 -ml-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-3 pb-6 px-1 pt-1 overflow-auto grow">
          {alert}
          {getStrategyLimitContainer}
          {generatedForm}
        </div>
        <footer className="pt-3 pb-3 max-sm:pb-4 pl-1 border-t border-neutral-20">
          <JxtButton
            variant={loading ? ButtonVariantEnum.OutlinePrimary : ButtonVariantEnum.Primary}
            className="w-full"
            type="submit"
            disabled={loading ? true : shouldDisableSubmitButton}
          >
            {loading ? children : bookingToEdit ? t('booking-form.submit-edit') : t('booking-form.submit-create')}
          </JxtButton>
        </footer>
      </form>
      <JxtUpdateBookingModal
        oldEvent={{
          start: bookingToEdit?.start.dateTime ? DateTime.fromISO(bookingToEdit.start.dateTime) : undefined,
          end: bookingToEdit?.end.dateTime ? DateTime.fromISO(bookingToEdit.end.dateTime) : undefined,
        }}
        event={{
          start: getValues('start.date'),
          end: getValues('end.date'),
        }}
        timezone={bookingToEdit?.resource.timezone}
        recurrence={true}
        onHide={(e) => {
          if (e) {
            onConfirmUpdate(e?.recurrence);
          }
          setShowUpdateBookingModal(false);
          setLoading(false);
        }}
        show={showUpdateBookingModal}
      />
      <JxtExternalLinkModal
        externalLinks={externalLinksToVisit?.length ? externalLinksToVisit.map(({ type, ...rest }) => rest) : []}
        show={showExternalLinksModal}
        onHide={() => {
          setShowExternalLinksModal(false);
          offCanvasContext?.close();
        }}
      />
    </FormProvider>
  );
};
