import { useTranslation } from 'react-i18next';
import JxtAvatar from '../../JxtAvatar';
import { JxtAvatarSizeEnum } from '../../JxtAvatar/types';
import { STROKE_WIDTH } from '../constants';
import { IJxtPictoGroup } from '../types';
import { JSX } from 'react';

export const JxtUserPicto = ({ p }: IJxtPictoGroup): JSX.Element | null => {
  const { t } = useTranslation();
  const id = `${p.url}${p.userInitials}`;

  const alt =
    p.firstName && p.lastName
      ? t('avatar-of', {
          name: `${p.firstName} ${p.lastName}`,
        })
      : t('user-profile-picture');

  if (p.url) {
    return (
      <>
        <clipPath id={id}>
          <circle cx={p.x + p.size / 2} cy={p.y + p.size / 2} r={p.size / 2} />
        </clipPath>
        <g clipPath={`url(#${id})`}>
          <title>{alt}</title>
          <image width={p.size} height={p.size} href={p.url} x={p.x} y={p.y} />
          <circle
            fill="none"
            stroke="#000000"
            strokeWidth={STROKE_WIDTH}
            r={p.size / 2 + 1}
            cx={p.x + p.size / 2}
            cy={p.y + p.size / 2}
          />
        </g>
      </>
    );
  }

  return <JxtAvatar picture={p.url} x={p.x} y={p.y} size={JxtAvatarSizeEnum.XL} text={p.userInitials} alt={alt} />;
};
