import { useTranslation } from 'react-i18next';
import JxtCheckboxList from '../JxtCheckboxList';
import JxtCollapsibleFilter from '../JxtCollapsibleFilter';
import { IJxtCollapsibleCheckboxList } from './types';
import { convertValues } from './helpers';

const JxtCollapsibleCheckboxList = ({
  label,
  allOrNoneSelectedOptionsLabel,
  defaultFolded = true,
  options,
  selectedOptions,
  onChange,
  onFoldChange,
  hasNextPage,
  fetchNextPage,
}: IJxtCollapsibleCheckboxList) => {
  const { t } = useTranslation();
  const values =
    selectedOptions.length === 0 || selectedOptions.length === options.length
      ? (allOrNoneSelectedOptionsLabel ?? t<string>('collapsible.checkbox.list-all'))
      : selectedOptions.map((option) => option.label);

  const value = convertValues(values);

  return (
    <JxtCollapsibleFilter label={label} value={value} defaultFolded={defaultFolded} onFoldChange={onFoldChange}>
      <JxtCheckboxList
        options={options}
        selectedOptions={selectedOptions}
        onChange={onChange}
        hasNextPage={hasNextPage}
        onClickShowMore={fetchNextPage}
      />
    </JxtCollapsibleFilter>
  );
};

export default JxtCollapsibleCheckboxList;
