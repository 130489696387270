export const smartTimeFormatter = {
  finalFormat: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  forbiddenChars: /[^\d\sHh:.]+/,

  /**
   * @returns either the formatted input or null when `input` cannot be formatted.
   */
  format(input: string): string | null {
    const trimInput = input.trim();

    if (trimInput.length === 0) {
      return null;
    }

    if (this.finalFormat.test(trimInput)) {
      return trimInput;
    }

    if (this.forbiddenChars.test(input)) {
      return null;
    }

    const inputWithoutSpacers = trimInput.replace(/\s/g, '');

    const withDelimiters = /((\d)(\d)|(\d))?([hH:.]+)(\d)?(\d)?/.exec(inputWithoutSpacers);

    if (withDelimiters !== null) {
      return this.prepareFromDelimiterPattern(withDelimiters);
    }

    const onlyNumbers = /(\d)?(\d)?(\d)?(\d)?/.exec(inputWithoutSpacers);

    if (onlyNumbers !== null) {
      return this.finalizeFormat([onlyNumbers[1], onlyNumbers[2], onlyNumbers[3], onlyNumbers[4]]);
    }

    return null;
  },

  /**
   * Designed to work with the following regex: ((\d)(\d)|(\d))?([hH:.]+)(\d)?(\d)?
   */
  prepareFromDelimiterPattern(matches: RegExpMatchArray): string | null {
    // nothing before delimiters
    if (matches[1] === undefined) {
      return this.finalizeFormat([undefined, undefined, matches[6], matches[7]], true);
    }

    // at least 2 digits at beginning
    if (matches[3] !== undefined) {
      return this.finalizeFormat([matches[2], matches[3], matches[6], matches[7]], true);
    }

    // only one digit before delimiters
    if (matches[4] !== undefined) {
      return this.finalizeFormat([undefined, matches[4], matches[6], matches[7]], true);
    }

    return this.finalizeFormat([matches[1], matches[2], matches[6], matches[7]], true);
  },

  /**
   * Takes 4 time digits and produce a time string;
   * A undefined digit will become 0.
   * @param matchedDigits index 0 is digit 1, and index 3 is digit 4.
   */
  finalizeFormat(matchedDigits: Array<string | undefined>, withDelimiter?: boolean): string | null {
    let hours: number;
    let minutes: number;

    if (matchedDigits.length !== 4) {
      throw new Error('SmartTimeFormatter.finalizeFormat: expected to 4 digits');
    }

    let parsedDigits = matchedDigits.map((x) => (typeof x === 'string' ? Number.parseInt(x, 10) : undefined));
    const validDigits = parsedDigits.filter((x) => x !== null && x !== undefined && !isNaN(x));
    const nbOfDigits = validDigits.length;

    const template = parsedDigits.map((x) => (x === undefined ? '.' : 'x')).join('');

    if (nbOfDigits === 0) {
      parsedDigits = [0, 0, 0, 0];
    } else if (nbOfDigits === 1) {
      parsedDigits = [0, validDigits.shift() as number, 0, 0];
    } else if (template === '..xx') {
      parsedDigits = [0, 0, validDigits.shift() as number, validDigits.shift() as number];
    } else if (template === 'xx..') {
      parsedDigits = [validDigits.shift() as number, validDigits.shift() as number, 0, 0];
    } else if (template === '.xx.') {
      parsedDigits = [0, validDigits.shift() as number, validDigits.shift() as number, 0];
    } else if (template === 'xxx.') {
      if (withDelimiter) {
        parsedDigits = [validDigits.shift() as number, validDigits.shift() as number, validDigits.shift() as number, 0];
      } else {
        parsedDigits = [0, validDigits.shift() as number, validDigits.shift() as number, validDigits.shift() as number];
      }
    } else if (template === '.xxx') {
      parsedDigits = [0, validDigits.shift() as number, validDigits.shift() as number, validDigits.shift() as number];
    } else if (nbOfDigits === 4) {
      // noop, parsedDigits already good
    } else {
      return null;
    }

    hours = (parsedDigits[0] as number) * 10 + (parsedDigits[1] as number);
    minutes = (parsedDigits[2] as number) * 10 + (parsedDigits[3] as number);

    if (hours === 24 && minutes === 0) {
      hours = 23;
      minutes = 59;
    } else {
      hours = hours >= 24 ? 23 : hours;
      minutes = minutes >= 60 ? 59 : minutes;
    }

    const hoursStr = hours <= 9 ? '0' + hours.toString(10) : hours.toString(10);
    const minutesStr = minutes <= 9 ? '0' + minutes.toString(10) : minutes.toString(10);

    return `${hoursStr}:${minutesStr}`;
  },
};
