import { useTranslation } from 'react-i18next';

const JxtMultipleFloorsPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <div className="flex p-4 text-center flex-col items-center m-auto">
      <i className="fas fa-map-location-dot fa-2x text-neutral-60 mb-8" aria-hidden="true" />
      <p className="text-neutral-140 font-medium mb-4">{t<string>('floor-plan.impossible-display-multiple-floors')}</p>
      <p className="text-body-s text-neutral-80">{t<string>('floor-plan.switch-view.multiple-floors')}</p>
    </div>
  );
};

export default JxtMultipleFloorsPlaceholder;
