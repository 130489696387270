import { JxtContainer, JxtTypicalWeekDay } from '@jooxter/ui';
import { useTranslation } from 'react-i18next';
import { useMutateUserTypicalWeek } from '@jooxter/core';
import { WorkDay, WorkHoursWeekDto } from '@jooxter/api';
import { IJxtTypicalWeek } from './types';
import { createGTMGAEvent } from '@jooxter/utils';

export const TypicalWeekContainer = ({ userId, week }: IJxtTypicalWeek) => {
  const { t } = useTranslation();
  const { mutate: mutateWeek } = useMutateUserTypicalWeek();

  const updateTypicalWeek = (day: WorkDay, dayName: string, applyAll = false) => {
    if (week) {
      let clone;
      if (applyAll) {
        clone = Object.fromEntries(Object.entries(week).map(([key]) => [key, day]));
      } else {
        clone = { ...week };
        clone[dayName as keyof WorkHoursWeekDto] = day;
      }
      mutateWeek({
        id: userId,
        payload: clone,
      });
    }
  };

  const onClickApplyAll = () => {
    createGTMGAEvent('Profile', 'Booking preferences', 'Apply to all');
  };

  return (
    <JxtContainer
      id="typical-week"
      title={t<string>('typical-week-settings-title')}
      subtitle={t<string>('typical-week-settings-subtitle')}
    >
      <div className="flex flex-col gap-6">
        {Object.entries(week).map(([dayName, day]) => {
          return (
            <JxtTypicalWeekDay
              key={`${userId}-${JSON.stringify(dayName)}`}
              dayName={dayName}
              dayRange={day}
              onChange={updateTypicalWeek}
              displayApplyAll={dayName === 'monday'}
              onClickApplyAll={dayName === 'monday' ? onClickApplyAll : undefined}
            />
          );
        })}
      </div>
    </JxtContainer>
  );
};
