import { JxtEventColorsEnum } from '@jooxter/utils';

export enum BookingTypeEnum {
  Desk,
  Room,
}

export enum BookingDisplayModeEnum {
  Compact,
  Regular,
  NoPadding,
}

export interface IJxtCalendarEvent {
  id: string;
  mode?: BookingDisplayModeEnum;
  color: JxtEventColorsEnum;
  hours?: string;
  text: string;
  isValidated?: boolean;
  notSelected?: boolean;
  nbAttendees?: number;
  isRecurrence?: boolean;
  isDeclined?: boolean;
  optionIconsList?: string[];
  onClickEvent?: (id: string) => void;
  onClickCheckIn?: () => void;
  onClickCheckOut?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}
