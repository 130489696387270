import { axiosManager } from '../config';
import { UserGroup, UserGroupCompressed } from '../model';
import { onNextPage } from '../utils';

export interface ISearchUserGroupsParameters {
  name?: string;
  page?: string;
  size?: number;
}

export const UserGroupsService = {
  path: 'user_groups',

  getUserGroup(id: number): Promise<UserGroup> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  search(options?: ISearchUserGroupsParameters): Promise<{ data: UserGroupCompressed[]; nextPage: string }> {
    const params = this.createParams(options);

    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(onNextPage);
    }

    const url = `${this.path}`;

    return axiosManager.getInstance().get(url, { params }).then(onNextPage);
  },

  createParams(options?: ISearchUserGroupsParameters): URLSearchParams {
    const params = new URLSearchParams();

    if (!options) {
      return params;
    }

    if (options.name) {
      params.set('name', options.name);
    }
    if (options.page) {
      params.set('page', options.page);
    }
    if (options.size) {
      params.set('size', options.size.toString());
    }
    return params;
  },
};
