import { IJxtOffCanvas } from '@jooxter/ui/src/components/JxtOffCanvas/types';
import { useContext } from 'react';
import { OffCanvasContext } from './OffCanvasContext';

export type TOffCanvasProps = Omit<IJxtOffCanvas, 'show'>;

export interface IOffCanvas {
  show: boolean;
  offCanvasProps: TOffCanvasProps | null;
  setOffCanvasProps: (props: TOffCanvasProps | null) => void;
  open: (props: TOffCanvasProps) => void;
  close: () => void;
}

export const useOffCanvas = (): IOffCanvas | null => {
  return useContext(OffCanvasContext);
};
