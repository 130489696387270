import { BookingOption, Option } from '@jooxter/api';
import { BOOKING_OPTION_PREFIX } from '@jooxter/utils';

export const getDefaultOptions = (resourceOptions?: Option[] | null, bookingOptions?: BookingOption[] | null) => {
  if (!resourceOptions?.length) {
    return;
  }

  return Object.fromEntries(
    resourceOptions.map((option) => {
      const bookingOption = bookingOptions?.find((o) => o.optionId === option.id);

      if (bookingOption) {
        return [
          `${BOOKING_OPTION_PREFIX}-${bookingOption.optionId}`,
          {
            activated: true,
            value: bookingOption.value?.content,
          },
        ];
      }

      return [
        `${BOOKING_OPTION_PREFIX}-${option.id}`,
        {
          activated: false,
          value: option.defaultValue,
        },
      ];
    })
  );
};
