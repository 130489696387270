import { toISO } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { ResourceTimelineStatusDto } from '../model';
import { axiosManager } from '../config';

export interface ITimelineOptions {
  id: number;
  start: DateTime;
  end: DateTime;
  bookingMask?: number;
  seatRequested?: number;
}

export const TimelineService = {
  path: 'resources',
  appendix: 'timeline',

  getSlots(options: ITimelineOptions): Promise<ResourceTimelineStatusDto[]> {
    const url = `${this.path}/${options.id}/${this.appendix}`;
    const params = this.createParams(options);

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  createParams(options: ITimelineOptions): URLSearchParams {
    const params = new URLSearchParams();
    params.append('start', toISO(options.start));
    params.append('end', toISO(options.end));

    if (options.bookingMask) {
      params.append('ignoreBookingIds', options.bookingMask.toString());
    }

    if (options?.seatRequested) {
      params.append('seatRequested', options.seatRequested.toString());
    }
    return params;
  },
};
