import { IQuickTimeSlot, IRange } from '@jooxter/utils';
import { ReactNode } from 'react';

export enum JxtSmartSlotContainerGapEnum {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
}

export interface IJxtSmartSlotContainer {
  range: IRange;
  slots: IQuickTimeSlot[];
  children?: ReactNode;
  gap?: JxtSmartSlotContainerGapEnum;
  onSlotSelection: (slot: IQuickTimeSlot) => void;
  autoSelectFirstSlot?: boolean;
}
