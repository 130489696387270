import { BookingCompressed, BookingRequest, BookingRequestVisibilityEnum } from '@jooxter/api';
import { useCallback, useMemo } from 'react';
import { useFetchBookings } from '../../queries';

/**
 * WARNING: This hook fetches individual bookings based on their IDs.
 * Fetching bookings individually can significantly slow down the application
 * if used extensively or with a large number of bookings. Consider optimizing
 * the data retrieval strategy or batch fetching if performance issues arise.
 *
 * Current usage:
 * - JxtResourceCalendar: fetch bookings for one resource and one day, safe
 * - JxtWorkplaceCalendarContainer: fetch bookings either on a day, the mornings of the week, the afternoon of a week
 * or one weekday of the months (ie each Monday), for the connected user and for desk/parkings. Overall the number of
 * bookings to fetch seems reasonable
 *
 */
export const useBookingCompressedToBookingRequestAdapter = (bookings?: BookingCompressed[]) => {
  const bookingIds = useMemo(() => bookings?.map((b) => b.id) ?? [], [bookings]);
  const { bookings: bookingsFromApi } = useFetchBookings(bookingIds);

  const adapt = useCallback(
    (booking: BookingCompressed): BookingRequest | undefined => {
      const bookingFromApi = bookingsFromApi.find((b) => b.id === booking.id);

      if (!bookingFromApi) {
        return;
      }

      const bookingRequest: BookingRequest = {
        summary: bookingFromApi.summary,
        description: bookingFromApi.description,
        start: bookingFromApi.start,
        end: bookingFromApi.end,
        color: bookingFromApi.color,
        organizerId: bookingFromApi.organizer.id,
        resourceId: bookingFromApi.resource.id,
        visibility: bookingFromApi.visibility as unknown as BookingRequestVisibilityEnum,
        participationStatusAttendees: [],
        physicalAttendees: bookingFromApi.physicalAttendees,
        options: bookingFromApi.options.map((option) => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          optionOverloadedId: option.optionId!,
          value: option.value?.content ?? '',
        })),
        attendees: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          internal: bookingFromApi.attendees.internal.map((internal) => internal.id!),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          external: bookingFromApi.attendees.external.map((external) => external.id!),
        },
      };

      return bookingRequest;
    },
    [bookingsFromApi]
  );

  return { adapt };
};
