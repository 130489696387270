import { useFetchUser, HOME_ROUTE, SEARCH_SPACE_ROUTE } from '@jooxter/core';
import { Navigate, Outlet } from 'react-router';
import { JxtLoader } from '@jooxter/ui';
import { JSX } from 'react';

export const OnboardingNotDoneGuard = (): JSX.Element => {
  const { user } = useFetchUser();
  const hasCollaborators = user?.capabilities?.features?.collaborators;

  if (!user) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <JxtLoader />
      </div>
    );
  }

  return !user.setupDone ? <Outlet /> : <Navigate to={hasCollaborators ? HOME_ROUTE : SEARCH_SPACE_ROUTE} replace />;
};
