import clsx from 'clsx';
import JxtButton from '../JxtButton';
import { ButtonVariantEnum } from '../JxtButton/types';
import { IJxtButtonGroups } from './types';

const JxtButtonGroups = ({ disabled = false, onClickNext, onClickPrev, className = '' }: IJxtButtonGroups) => {
  return (
    <span className={clsx(className, 'isolate inline-flex rounded-md shadow-sm')}>
      <JxtButton
        disabled={disabled}
        variant={ButtonVariantEnum.Secondary}
        className={clsx('rounded-l-lg rounded-r-none !text-body-m/[16px]')}
        onClick={onClickPrev}
        ariaLabel="Prev"
      >
        <span className="sr-only">Précédent</span>
        <i className={clsx('fa-solid fa-chevron-left', disabled && 'text-neutral-60')} aria-hidden="true" />
      </JxtButton>
      <JxtButton
        disabled={disabled}
        variant={ButtonVariantEnum.Secondary}
        className={clsx('-ml-px rounded-r-lg rounded-l-none !text-body-m/[16px]')}
        onClick={onClickNext}
        ariaLabel="Next"
      >
        <span className="sr-only">Suivant</span>
        <i className={clsx('fa-solid fa-chevron-right', disabled && 'text-neutral-60')} aria-hidden="true" />
      </JxtButton>
    </span>
  );
};

export default JxtButtonGroups;
