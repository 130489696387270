// eslint-disable-next-line import/named
import { AxiosHeaders, AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import {
  BookingCompressed,
  Preferences,
  NotificationSettings,
  User,
  UserCompressed,
  UserRequestExtended,
  UserScheduler,
  UserWorkplaces,
  WorkHoursWeekDto,
  WorkplaceDtoTypeEnum,
  PreferencesRequest,
  UserTypeEnum,
} from '../model';
import { parseLinkHeader, toISO } from '@jooxter/utils';
import { axiosManager, getBaseUrl } from '../config';

export interface ISearchUsersParameters {
  email?: string;
  firstName?: string;
  lastName?: string;
  type?: UserTypeEnum;
  validation?: boolean;
  locationId?: number[];
  corporationId?: number[];
  externalUID?: string;
  globalSearch?: string;
  groupIds?: number[];
  favoritesOnly?: boolean;
  page?: string;
  size?: number;
}

export interface IUserWorkplacesParameters {
  globalSearch?: string;
  groupIds?: number[];
  favoritesOnly?: boolean;
  from: DateTime;
  to: DateTime;
  type?: WorkplaceDtoTypeEnum;
  locationIds?: number[];
  page?: string;
  size?: number;
}

export const UserService = {
  path: 'users',

  getMe(): Promise<User> {
    const url = `${this.path}/me`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getUser(userId: number): Promise<User> {
    const url = `${this.path}/${userId}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getPreferences(id: number): Promise<Preferences> {
    const url = `${this.path}/${id}/settings/preferences`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getNotifications(id: number): Promise<NotificationSettings> {
    const url = `${this.path}/${id}/settings/notifications`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  patchNotifications(userId: number, payload: Partial<NotificationSettings>): Promise<NotificationSettings> {
    const url = `${this.path}/${userId}/settings/notifications`;

    return axiosManager
      .getInstance()
      .patch(url, payload)
      .then((res) => res.data);
  },

  patch(userId: number, payload: Partial<UserRequestExtended>): Promise<User> {
    const url = `${this.path}/${userId}`;

    return axiosManager
      .getInstance()
      .patch(url, payload)
      .then((res) => res.data);
  },

  patchPreferences(userId: number, payload: Partial<PreferencesRequest>): Promise<Preferences> {
    const url = `${this.path}/${userId}/settings/preferences`;

    return axiosManager
      .getInstance()
      .patch(url, payload)
      .then((res) => res.data);
  },

  putUser(userId: number, payload: UserRequestExtended): Promise<User> {
    const url = `${this.path}/${userId}`;

    return axiosManager
      .getInstance()
      .put(url, payload)
      .then((res) => res.data);
  },

  putPreferences(userId: number, payload: PreferencesRequest): Promise<Preferences> {
    const url = `${this.path}/${userId}/settings/preferences`;

    return axiosManager
      .getInstance()
      .put(url, payload)
      .then((res) => res.data);
  },

  getTypicalWeek(userId: number): Promise<WorkHoursWeekDto> {
    const url = `${this.path}/${userId}/work_hours`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  postTypicalWeek(userId: number, payload: WorkHoursWeekDto): Promise<WorkHoursWeekDto> {
    const url = `${this.path}/${userId}/work_hours`;

    return axiosManager
      .getInstance()
      .post(url, payload)
      .then((res) => res.data);
  },

  getWorkplaces(options: IUserWorkplacesParameters): Promise<{
    data: UserWorkplaces[];
    nextPage: string;
  }> {
    const params = this.createUserWorkplacesParams(options);
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(this.onNextPage);
    }

    const url = `${this.path}/workplaces`;

    return axiosManager.getInstance().get(url, { params }).then(this.onNextPage);
  },

  getScheduler(
    options: IUserWorkplacesParameters
  ): Promise<Array<UserScheduler & { bookings: BookingCompressed[] }[]>> {
    const url = `${this.path}/scheduler`;
    const params = this.createUserWorkplacesParams(options);

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  deleteWorkplace(id: number, userId: number): Promise<void> {
    const url = `${this.path}/${userId}/workplaces/${id}`;

    return axiosManager.getInstance().delete(url);
  },

  getUsersWithPagination(options: ISearchUsersParameters): Promise<{
    data: UserCompressed[];
    nextPage: string;
  }> {
    const params = this.createSearchUsersParams(options);
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(this.onNextPage);
    }

    const url = `${this.path}`;

    return axiosManager.getInstance().get(url, { params }).then(this.onNextPage);
  },

  postUserIcsCalendar(): Promise<string> {
    const url = `${this.path}/calendars/ics`;
    return axiosManager
      .getInstance()
      .post(url)
      .then((res) => `${getBaseUrl()}/spring/calendars/ics/${res.data}`);
  },

  async onNextPage(res: AxiosResponse) {
    if (res.headers?.get && res.headers instanceof AxiosHeaders) {
      const linkHeader = res.headers.get('Link')?.toString() ?? null;
      const parsed = parseLinkHeader(linkHeader);
      const nextPage = parsed?.next.url ?? '';

      return {
        data: await res.data,
        nextPage,
      };
    }

    return {
      data: await res.data,
      nextPage: '',
    };
  },

  putFavorite(userId: number): Promise<void> {
    const url = `${this.path}/favorites/${userId}`;

    return axiosManager.getInstance().put(url);
  },

  deleteFavorite(userId: number): Promise<void> {
    const url = `${this.path}/favorites/${userId}`;

    return axiosManager.getInstance().delete(url);
  },

  setup(userId: number): Promise<void> {
    const url = `${this.path}/${userId}/setup`;

    return axiosManager.getInstance().post(url);
  },

  heartbeat(): Promise<void> {
    const url = `${this.path}/heartbeat`;

    return axiosManager.getInstance().post(url);
  },

  createUserWorkplacesParams(options: IUserWorkplacesParameters): URLSearchParams {
    const params = new URLSearchParams();

    params.set('from', toISO(options.from));
    params.set('to', toISO(options.to));

    if (options.globalSearch) {
      params.append('globalSearch', options.globalSearch);
    }

    if (options.groupIds && options.groupIds.length > 0) {
      params.append('groupsIds', options.groupIds.join(','));
    }

    if (typeof options.favoritesOnly === 'boolean') {
      params.set('favoritesOnly', options.favoritesOnly.toString());
    }

    if (options.type) {
      params.set('type', options.type);
    }

    if (options.locationIds && options.locationIds.length > 0) {
      params.set('locationIds', options.locationIds.toString());
    }

    if (options.page) {
      params.append('page', options.page);
    }

    if (options.size) {
      params.append('size', options.size.toString());
    }

    return params;
  },

  createSearchUsersParams(options: ISearchUsersParameters): URLSearchParams {
    const params = new URLSearchParams();

    if (options.email) {
      params.append('email', options.email);
    }

    if (options.firstName) {
      params.append('firstName', options.firstName);
    }

    if (options.lastName) {
      params.append('lastName', options.lastName);
    }

    if (options.type) {
      params.append('type', options.type);
    }

    if (options.validation !== undefined) {
      params.append('validation', options.validation.toString());
    } else {
      params.append('validation', 'true');
    }

    if (options.locationId && options.locationId.length > 0) {
      params.append('locationId', options.locationId.join(','));
    }

    if (options.corporationId && options.corporationId.length > 0) {
      params.append('corporationId', options.corporationId.join(','));
    }

    if (options.externalUID) {
      params.append('externalUID', options.externalUID);
    }

    if (options.globalSearch) {
      params.append('globalSearch', options.globalSearch);
    }

    if (options.groupIds && options.groupIds.length > 0) {
      params.append('groupIds', options.groupIds.join(','));
    }

    if (typeof options.favoritesOnly === 'boolean') {
      params.set('favoritesOnly', options.favoritesOnly.toString());
    }

    if (options.page) {
      params.append('page', options.page);
    }

    if (options.size) {
      params.append('size', options.size.toString());
    }

    return params;
  },
};
