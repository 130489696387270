import { IJxtTag } from './types';
import { useState } from 'react';
import { getJxtTagClass, getJxtTagCrossClass } from './styles';

const JxtTag = ({ text, color, size, closeable = false, onClose }: IJxtTag) => {
  const classes = getJxtTagClass({ color, size, closeable });
  const [hidden, setHidden] = useState<boolean>(false);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setHidden(true);
  };

  return hidden ? null : (
    <div className={classes}>
      {text}
      {closeable && (
        <div className={getJxtTagCrossClass({ color, size })} onClick={handleClose}>
          <i className="fas fa-times" />
        </div>
      )}
    </div>
  );
};

export default JxtTag;
