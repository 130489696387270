import { FieldValues, FormProvider, useForm, UseFormRegister } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  JxtAlert,
  JxtAlertTypeEnum,
  JxtButton,
  JxtResourceInfos,
  dateNotInPastSuperRefinerFactory,
  startBeforeEndSuperRefinerFactory,
} from '@jooxter/ui';
import { useFetchResource, useInvalidateExceptionalOpeningHoursQueries } from '../../../queries';
import { IExceptionalOpeningHoursFormPreset } from '../types/exceptional-opening-hours-form.types';
import { useExceptionalOpeningHoursFormDefaultValues } from './useExceptionalOpeningHoursFormDefaultValues';
import { useExceptionalOpeningHoursSchema } from '../schemas/useExceptionalOpeningHoursSchema';
import { ExceptionalOpeningHours } from '@jooxter/api';
import { useTranslation } from 'react-i18next';
import {
  createGTMGAEvent,
  ExceptionalOpeningHoursTypeEnum,
  fromISO,
  generateToast,
  getDateTimeFromDateTimeAndStandardTime,
  IExceptionalOpeningHoursForm,
  IRange,
  ISOToLocalDate,
  ISOToLocalTime,
  JxtResourcesTypeEnum,
  sameDay,
} from '@jooxter/utils';
import { useButtonStatus, useOffCanvas } from '../../../hooks';
import { useGenerateExceptionalOpeningHoursForm } from './useGenerateExceptionalOpeningHoursForm';
import { useExceptionalOpeningHoursActions } from '../../../hooks/resources/useExceptionalOpeningHoursActions';
import { useIExceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter } from '../../../adapters/exceptional-opening-hours/useIExceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter';
import { useExceptionalOpeningHoursFormContext } from './useExceptionalOpeningHoursFormContext';
import { AxiosError } from 'axios';
import {
  getExcludedIntervals,
  isEditingClosing,
  isEditingClosingToOpening,
  isEditingOpening,
  isEditingOpeningToClosing,
} from './helpers';

export const useExceptionalOpeningHoursForm = (
  exceptionalOpeningHoursToEdit?: ExceptionalOpeningHours | null,
  exceptionalOpeningHoursFormPreset?: IExceptionalOpeningHoursFormPreset
) => {
  const { t } = useTranslation();
  const { children, setLoading, loading } = useButtonStatus();
  const { resource: exceptionalOpeningHoursToEditResource } = useFetchResource(
    exceptionalOpeningHoursToEdit?.resourceId
  );
  const resource = exceptionalOpeningHoursFormPreset
    ? exceptionalOpeningHoursFormPreset.resource
    : exceptionalOpeningHoursToEditResource;
  const exceptionalOpeningHoursFormDefaultValues = useExceptionalOpeningHoursFormDefaultValues(
    exceptionalOpeningHoursFormPreset,
    exceptionalOpeningHoursToEdit,
    resource
  );
  const schema = useExceptionalOpeningHoursSchema(exceptionalOpeningHoursFormDefaultValues);
  const [alert, setAlert] = useState<React.ReactElement>();
  const [bookingRange, setBookingRange] = useState<IRange>();
  const [additionalBookingRange, setAdditionalBookingRange] = useState<IRange>();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [exceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter] =
    useIExceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter();
  const exceptionalOpeningHoursFormContext = useExceptionalOpeningHoursFormContext();
  const offCanvasContext = useOffCanvas();
  const { invalidateExceptionalOpeningHoursQueries } = useInvalidateExceptionalOpeningHoursQueries();

  const schemaRefined = useMemo(() => {
    return schema
      .superRefine(
        dateNotInPastSuperRefinerFactory(
          'manage-space-form.error.date-not-in-past',
          resource?.location.timezone,
          !!exceptionalOpeningHoursToEdit
        )
      )
      .superRefine(startBeforeEndSuperRefinerFactory('manage-space-form.error.compare-date'));
  }, [schema]);

  const form = useForm<IExceptionalOpeningHoursForm>({
    resolver: zodResolver(schemaRefined),
    defaultValues: exceptionalOpeningHoursFormDefaultValues,
    mode: 'all',
  });

  const { register, reset, handleSubmit, formState, getFieldState, watch } = form;

  const shouldDisableSubmitButton = !formState.isValid;
  const watchStart = watch('start');
  const watchEnd = watch('end');
  const watchType = watch('type');

  const { create, update } = useExceptionalOpeningHoursActions({
    resourceId: resource?.id,
    range: bookingRange,
    additionalRange: additionalBookingRange,
  });

  const handleCreate = () => {
    if (watchType === ExceptionalOpeningHoursTypeEnum.Closing) {
      setBookingRange({
        from: getDateTimeFromDateTimeAndStandardTime(watchStart.date, watchStart.time),
        to: getDateTimeFromDateTimeAndStandardTime(watchEnd.date, watchEnd.time),
      });
      setAdditionalBookingRange(undefined);
    } else {
      setBookingRange(undefined);
      setAdditionalBookingRange(undefined);
    }
  };

  const handleEdit = (exceptionalOpeningHoursToEdit: ExceptionalOpeningHours) => {
    if (isEditingOpeningToClosing(exceptionalOpeningHoursToEdit.opened, watchType)) {
      setBookingRange({
        from: getDateTimeFromDateTimeAndStandardTime(watchStart.date, watchStart.time),
        to: getDateTimeFromDateTimeAndStandardTime(watchEnd.date, watchEnd.time),
      });
      setAdditionalBookingRange({
        from: fromISO(exceptionalOpeningHoursToEdit.timestampFrom),
        to: fromISO(exceptionalOpeningHoursToEdit.timestampTo),
      });
    } else if (isEditingClosing(exceptionalOpeningHoursToEdit.opened, watchType)) {
      setBookingRange({
        from: getDateTimeFromDateTimeAndStandardTime(watchStart.date, watchStart.time),
        to: getDateTimeFromDateTimeAndStandardTime(watchEnd.date, watchEnd.time),
      });
      setAdditionalBookingRange(undefined);
    } else if (isEditingOpening(exceptionalOpeningHoursToEdit.opened, watchType)) {
      const prevStart = fromISO(exceptionalOpeningHoursToEdit.timestampFrom);
      const prevEnd = fromISO(exceptionalOpeningHoursToEdit.timestampTo);
      const newStart = getDateTimeFromDateTimeAndStandardTime(watchStart.date, watchStart.time);
      const newEnd = getDateTimeFromDateTimeAndStandardTime(watchEnd.date, watchEnd.time);
      const excludedIntervals = getExcludedIntervals(prevStart, prevEnd, newStart, newEnd);
      if (excludedIntervals.length === 0) {
        setBookingRange(undefined);
        setAdditionalBookingRange(undefined);
      }
      if (excludedIntervals.length === 1) {
        setBookingRange(
          excludedIntervals[0].start && excludedIntervals[0].end
            ? {
                from: excludedIntervals[0].start,
                to: excludedIntervals[0].end,
              }
            : undefined
        );
        setAdditionalBookingRange(undefined);
      } else if (excludedIntervals.length === 2) {
        setBookingRange(
          excludedIntervals[0].start && excludedIntervals[0].end
            ? {
                from: excludedIntervals[0].start,
                to: excludedIntervals[0].end,
              }
            : undefined
        );
        setAdditionalBookingRange(
          excludedIntervals[1].start && excludedIntervals[1].end
            ? {
                from: excludedIntervals[1].start,
                to: excludedIntervals[1].end,
              }
            : undefined
        );
      }
    } else if (isEditingClosingToOpening(exceptionalOpeningHoursToEdit.opened, watchType)) {
      setBookingRange(undefined);
      setAdditionalBookingRange(undefined);
    }
  };

  useEffect(() => {
    // https://react-hook-form.com/docs/useform#defaultValues
    // defaultValues are cached. To reset them, use the reset API.
    reset(exceptionalOpeningHoursFormDefaultValues);
  }, [exceptionalOpeningHoursFormDefaultValues]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      createGTMGAEvent(
        'ManageSpaces',
        'Create',
        watchType === ExceptionalOpeningHoursTypeEnum.Opening ? 'Exceptional Opening' : 'Exceptional Closing'
      );
    }
  }, [watchType]);

  useEffect(() => {
    if (!exceptionalOpeningHoursToEdit) {
      handleCreate();
    } else {
      handleEdit(exceptionalOpeningHoursToEdit);
    }
  }, [watchStart, watchEnd, watchType, exceptionalOpeningHoursToEdit]);

  const generatedForm = useGenerateExceptionalOpeningHoursForm(
    {
      register: register as unknown as UseFormRegister<FieldValues>,
      formState,
      getFieldState,
    },
    {
      schema,
      exceptionalOpeningHoursToEdit,
      resource,
    }
  );

  const onSuccessUpdate = (data: ExceptionalOpeningHours[]) => {
    const exceptionalOpeningHours = data[0];
    if (!exceptionalOpeningHours.id) {
      return;
    }
    const uniqueDay = sameDay(
      fromISO(exceptionalOpeningHours.timestampFrom),
      fromISO(exceptionalOpeningHours.timestampTo)
    );
    const startDate = ISOToLocalDate(exceptionalOpeningHours.timestampFrom, resource?.location.timezone);
    const endDate = ISOToLocalDate(exceptionalOpeningHours.timestampTo, resource?.location.timezone);
    const startTime = ISOToLocalTime(exceptionalOpeningHours.timestampFrom, resource?.location.timezone);
    const endTime = ISOToLocalTime(exceptionalOpeningHours.timestampTo, resource?.location.timezone);
    invalidateExceptionalOpeningHoursQueries([exceptionalOpeningHours.id]);
    offCanvasContext?.close();
    generateToast(
      exceptionalOpeningHours.opened
        ? t<string>('exceptional-opening-updated')
        : t<string>('exceptional-closing-updated'),
      false,
      exceptionalOpeningHours.opened
        ? uniqueDay
          ? t<string>('exceptional-opening-updated-message', {
              resourceName: resource?.name,
              date: startDate,
              startTime,
              endTime,
            })
          : t<string>('exceptional-opening-updated-message-with-end-date', {
              resourceName: resource?.name,
              startDate,
              endDate,
              startTime,
              endTime,
            })
        : uniqueDay
          ? t<string>('exceptional-closing-updated-message', {
              resourceName: resource?.name,
              date: startDate,
              startTime,
              endTime,
            })
          : t<string>('exceptional-closing-updated-message-with-end-date', {
              resourceName: resource?.name,
              startDate,
              endDate,
              startTime,
              endTime,
            })
    );
  };

  const onSuccessCreate = (data: ExceptionalOpeningHours[]) => {
    const exceptionalOpeningHours = data[0];
    if (!exceptionalOpeningHours.id) {
      return;
    }
    const uniqueDay = sameDay(
      fromISO(exceptionalOpeningHours.timestampFrom),
      fromISO(exceptionalOpeningHours.timestampTo)
    );
    const startDate = ISOToLocalDate(exceptionalOpeningHours.timestampFrom, resource?.location.timezone);
    const endDate = ISOToLocalDate(exceptionalOpeningHours.timestampTo, resource?.location.timezone);
    const startTime = ISOToLocalTime(exceptionalOpeningHours.timestampFrom, resource?.location.timezone);
    const endTime = ISOToLocalTime(exceptionalOpeningHours.timestampTo, resource?.location.timezone);
    invalidateExceptionalOpeningHoursQueries([exceptionalOpeningHours.id]);
    offCanvasContext?.close();
    generateToast(
      exceptionalOpeningHours.opened
        ? t<string>('exceptional-opening-created')
        : t<string>('exceptional-closing-created'),
      false,
      exceptionalOpeningHours.opened
        ? uniqueDay
          ? t<string>('exceptional-opening-created-message', {
              resourceName: resource?.name,
              date: startDate,
              startTime,
              endTime,
            })
          : t<string>('exceptional-opening-created-message-with-end-date', {
              resourceName: resource?.name,
              startDate,
              endDate,
              startTime,
              endTime,
            })
        : uniqueDay
          ? t<string>('exceptional-closing-created-message', {
              resourceName: resource?.name,
              date: startDate,
              startTime,
              endTime,
            })
          : t<string>('exceptional-closing-created-message-with-end-date', {
              resourceName: resource?.name,
              startDate,
              endDate,
              startTime,
              endTime,
            }),
      <JxtButton
        className="!p-0"
        size={ButtonSizeEnum.Large}
        variant={ButtonVariantEnum.Link}
        onClick={() => {
          exceptionalOpeningHoursFormContext?.showExceptionalOpeningHoursDetails(exceptionalOpeningHours);
        }}
      >
        {exceptionalOpeningHours.opened ? t('open-exceptional-opening') : t('open-exceptional-closing')}
      </JxtButton>
    );
  };

  const onError = (error: unknown) => {
    const axiosError = error as AxiosError;
    const response = axiosError.response?.data as { error: string; description: string }[];
    if (response.length > 0) {
      setAlert(<JxtAlert type={JxtAlertTypeEnum.Error} text={response[0].error} closeable />);
    }
  };

  const onSettled = () => {
    setLoading(false);
  };

  const onHideManageBookingModal = () => {
    setLoading(false);
  };

  const onSubmit = (data: IExceptionalOpeningHoursForm) => {
    if (!resource?.id) {
      return;
    }
    setLoading(true);
    setAlert(undefined);
    const result = schema.safeParse(data);
    if (!result.success) {
      return;
    }
    createGTMGAEvent('ManageSpaces', 'Create', 'Create Button');
    const exceptionalOpeningHours = exceptionalOpeningHoursFormToExceptionalOpeningHoursAdapter(data, resource?.id);
    if (!exceptionalOpeningHoursToEdit) {
      create(exceptionalOpeningHours, onSuccessCreate, onError, onSettled, onHideManageBookingModal);
    } else if (exceptionalOpeningHoursToEdit.id) {
      update(
        { id: exceptionalOpeningHoursToEdit.id, payload: exceptionalOpeningHours },
        onSuccessUpdate,
        onError,
        onSettled,
        onHideManageBookingModal
      );
    }
  };

  if (!exceptionalOpeningHoursFormDefaultValues || !resource) {
    return [];
  }

  return (
    <FormProvider<IExceptionalOpeningHoursForm> {...form}>
      <form
        key="form"
        className="w-full h-full flex flex-col justify-between overflow-hidden -mt-1 -ml-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-6 pb-6 px-1 pt-1 overflow-auto grow">
          <JxtResourceInfos
            name={resource.name}
            type={
              resource.resourceType.metaType
                ? JxtResourcesTypeEnum[resource.resourceType.metaType]
                : JxtResourcesTypeEnum.OTHER
            }
            typeName={resource.resourceType.name}
            locationName={resource.location.name}
            floor={resource.floor?.number}
            picture={resource.pictures?.length ? resource.pictures[0] : undefined}
            capacity={resource.capacity}
          />
          {alert}
          {generatedForm}
        </div>
        <footer className="pt-3 pb-3 max-sm:pb-4 pl-1 border-t border-neutral-20">
          <JxtButton
            variant={loading ? ButtonVariantEnum.OutlinePrimary : ButtonVariantEnum.Primary}
            className="w-full"
            type="submit"
            disabled={loading ? true : shouldDisableSubmitButton}
          >
            {loading
              ? children
              : exceptionalOpeningHoursToEdit
                ? t('manage-space-form.submit-edit')
                : t('manage-space-form.submit-create')}
          </JxtButton>
        </footer>
      </form>
    </FormProvider>
  );
};
