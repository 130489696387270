import { useTranslation } from 'react-i18next';
import JxtOnboardingLayout from '../../../layouts/OnboardingLayout';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton, JxtTypicalWeekDay } from '@jooxter/ui';
import { useNavigate } from 'react-router';
import { useFetchUser, useFetchWeek, useMutateUserTypicalWeek } from '@jooxter/core';
import { WorkDay } from '@jooxter/api';
import { useOnboardingNavigation } from '../hooks';
import { OnboardingPagesEnum } from '../types';
import { useState } from 'react';
import { createGTMGAEvent } from '@jooxter/utils';

export const OnboardingWorkHours = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getPrevLink, getNextLink } = useOnboardingNavigation(OnboardingPagesEnum.WORK_HOURS);
  const { user } = useFetchUser();
  const { week } = useFetchWeek(user?.id);
  const { mutate: mutateWeek } = useMutateUserTypicalWeek();
  const [shouldTriggerGTM, setShouldTriggerGTM] = useState(false);

  const updateTypicalWeek = (day: WorkDay) => {
    if (user?.id && week) {
      setShouldTriggerGTM(true);
      const clone = Object.fromEntries(Object.entries(week).map(([key]) => [key, day]));
      mutateWeek({
        id: user.id,
        payload: clone,
      });
    }
  };

  const handleClickRouteButton = (route: string) => {
    if (shouldTriggerGTM) {
      createGTMGAEvent('Onboarding', 'Work Hours', 'Update default work hours');
    }
    navigate(route);
  };

  if (!week?.monday) {
    return null;
  }

  return (
    <JxtOnboardingLayout
      title={t<string>('onboarding.work-hours.title')}
      instructions={t<string>('onboarding.work-hours.explanation')}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <p className="text-title-m text-neutral-140 font-medium">{t('typical-week')}</p>
          <JxtTypicalWeekDay
            dayName="monday"
            displayDayName={false}
            dayRange={week.monday}
            onChange={updateTypicalWeek}
          />
        </div>
        <div className="flex justify-between">
          <JxtButton
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => handleClickRouteButton(getPrevLink())}
          >
            {t('previous')}
          </JxtButton>
          <JxtButton size={ButtonSizeEnum.Large} onClick={() => handleClickRouteButton(getNextLink())}>
            {t('next')}
          </JxtButton>
        </div>
      </div>
    </JxtOnboardingLayout>
  );
};
