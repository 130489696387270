/* eslint-disable react/prop-types */
import clsx from 'clsx';
import { getJxtInputClass } from './styles';
import { IJxtInput } from './types';
import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Message } from 'react-hook-form';

const JxtInput = (
  {
    name,
    type = 'text',
    value,
    placeholder,
    label,
    helperText,
    disabled,
    readonly,
    showErrorMessages = true,
    leftIcon,
    multiLines,
    validationSchema,
    register,
    formState,
    getFieldState,
    onChange,
    errorStyle = false,
    className = '',
  }: IJxtInput,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref: ForwardedRef<HTMLElement>
) => {
  const eyeIconVisible = useMemo(() => type === 'password', [type]);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [classes, setClasses] = useState<string>('');
  const fieldState = getFieldState(name, formState);

  useEffect(() => {
    setHasErrors(Object.keys(fieldState.error ?? {}).length > 0);
  }, [fieldState.error]);

  useEffect(() => {
    setClasses(getJxtInputClass({ type, leftIcon, hasErrors: hasErrors || errorStyle, multiLines, readonly }));
  }, [hasErrors, type, leftIcon, errorStyle, multiLines, readonly]);

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  return (
    <div className={clsx(className, 'w-full')}>
      {label && <label className="block pb-1 text-neutral-120 text-title-m font-medium">{label}</label>}
      <div className="relative">
        {multiLines ? (
          <textarea
            className={classes}
            placeholder={placeholder}
            disabled={disabled}
            aria-invalid={hasErrors}
            {...register(name, validationSchema)}
            readOnly={readonly}
          />
        ) : (
          <>
            {leftIcon && (
              <div
                className={clsx(
                  disabled ? 'text-neutral-60' : 'text-neutral-120',
                  'pointer-events-none absolute inset-y-0 pl-2.5 flex items-center'
                )}
                onClick={togglePasswordVisibility}
                onKeyDown={togglePasswordVisibility}
                role="presentation"
                aria-hidden="true"
              >
                <i className={`fas fa-${leftIcon}`} />
              </div>
            )}
            <input
              className={classes}
              type={passwordVisible ? 'text' : type}
              defaultValue={value}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              aria-invalid={hasErrors}
              autoComplete="off"
              {...register(name, { ...validationSchema, onChange: onChange })}
            />
            {eyeIconVisible && (
              <div
                className={clsx(
                  disabled ? 'text-neutral-60 pointer-events-none' : 'text-neutral-120 cursor-pointer',
                  'absolute inset-y-0 right-0 flex pr-2.5 items-center'
                )}
                onClick={togglePasswordVisibility}
                onKeyDown={togglePasswordVisibility}
                role="presentation"
                aria-hidden="true"
              >
                <i className={clsx('fas fa-fw', passwordVisible ? 'fa-eye-slash' : 'fa-eye')} />
              </div>
            )}
          </>
        )}
      </div>
      {helperText && !hasErrors && (
        <p
          className={clsx(
            fieldState.isDirty && fieldState.isTouched && !fieldState.invalid ? 'text-green-80' : 'text-neutral-80',
            'text-body-xs',
            'pt-1'
          )}
        >
          {helperText}
          {fieldState.isDirty && fieldState.isTouched && !fieldState.invalid && (
            <i className="fas ml-1 fa-check text-green-80 fa-s" />
          )}
        </p>
      )}
      {showErrorMessages && fieldState?.error && (
        <ErrorMessage
          name={name}
          errors={{ [name]: fieldState.error }}
          render={({ message }: { message?: Message }) => <p className="text-red-100 pt-1">{message}</p>}
        />
      )}
    </div>
  );
};

export default forwardRef(JxtInput);
