import clsx from 'clsx';
import { JxtIconButtonSizeEnum } from './types';

export const getJxtIconButtonClass = (size: JxtIconButtonSizeEnum, disabled: boolean): string => {
  const baseStackClass = disabled
    ? 'fa-stack group shrink-0 cursor-not-allowed'
    : 'fa-stack cursor-pointer group shrink-0';

  const sizeClass = {
    [JxtIconButtonSizeEnum.S]: 'fa-sm !w-7',
    [JxtIconButtonSizeEnum.R]: 'text-lg leading-loose !w-9',
    [JxtIconButtonSizeEnum.L]: 'fa-lg !w-10',
  };

  return clsx(baseStackClass, sizeClass[size]);
};
