import { baseCustomCalendarConfig } from '../configs/base-custom-calendar.config';
//eslint-disable-next-line import/named
import { SpecificViewContentArg, sliceEvents } from '@fullcalendar/core';
import { JxtPaintCalendar } from '@jooxter/ui';
import { DateTime } from 'luxon';
import '../styles/header-toolbar.scss';
import { useCallback, useEffect } from 'react';
import { useDebouncedState } from '@mantine/hooks';
import { JxtCustomCalendarViewEnum } from '../types';

export const useCreatePaintMonthView = (timezone: string): { config: unknown } => {
  const [config, setConfig] = useDebouncedState<unknown>(null, 100);
  const getConfigContent = useCallback(
    (props: SpecificViewContentArg) => {
      const segs = sliceEvents({
        ...props,
        eventStore: props.eventStore,
        eventUiBases: props.eventUiBases,
        dateProfile: props.dateProfile,
      });

      if (!props.dateProfile.currentRange?.start) {
        return null;
      }

      /**
       * @see https://jooxter.atlassian.net/browse/ND-4953
       * If the user's timezone is negative, then the start of the month will be the previous month.
       * The currentRange returned by fullcalendar transforms the initial date and ignores the timezone given in input.
       * Exemple : Today is September 1st and my timezone is GMT-4, `props.dateProfile.currentRange.start` will be August.
       * (1st of September, 00:00 -4 => 1st of September, 00:00 UTC => 31st of August, 20:00 -4)
       * Workaround : Extract the correct month and year and build a new DateTime with the correct timezone.
       * https://github.com/fullcalendar/fullcalendar/issues/7892#issuecomment-2675885140
       */

      const currentISOString = props.dateProfile.currentRange.start.toISOString();
      const currentMonth = parseInt(currentISOString.slice(5, 7), 10);
      const currentYear = parseInt(currentISOString.slice(0, 4), 10);
      const currentDateTime = DateTime.fromObject({ year: currentYear, month: currentMonth, day: 1 }).setZone(timezone);

      return <JxtPaintCalendar date={currentDateTime} events={segs} />;
    },
    [timezone]
  );

  useEffect(() => {
    setConfig({
      ...baseCustomCalendarConfig(JxtCustomCalendarViewEnum.Month),
      // this method is called too many times even tho the above useEffect is called only once
      content: getConfigContent,
    });
  }, []);

  return { config };
};
