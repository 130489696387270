import { axiosManager } from '../config';
import { WorkTogetherDto } from '../model';
import { JxtMeetUpPeriodEnum } from '../types';

export interface IGetSlotsParameters {
  userIds: number[];
  length: JxtMeetUpPeriodEnum;
}

export const SlotsService = {
  path: 'users/workplaces/slots',

  getSlots(options: IGetSlotsParameters): Promise<WorkTogetherDto[]> {
    const params = this.createGetSlotsParams(options);
    const url = `${this.path}`;

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  createGetSlotsParams(options: IGetSlotsParameters): URLSearchParams {
    const params = new URLSearchParams();
    params.set('userIds', options.userIds.join(','));
    params.set('length', options.length);
    return params;
  },
};
