import { clsx } from 'clsx';
import { JxtSmartSlotContainerGapEnum } from './types';

export interface IGetJxtSmartSlotSelectorContainerClass {
  gap: JxtSmartSlotContainerGapEnum;
}

export const getJxtSmartSlotSelectorContainerClass = ({ gap }: IGetJxtSmartSlotSelectorContainerClass) => {
  const defaultClasses = 'flex flex-wrap';

  const gapClasses = { SMALL: 'gap-1', REGULAR: 'gap-2' };

  const classes = clsx(defaultClasses, gapClasses[gap]);

  return classes;
};
