import {
  DEFAULT_OCCURRENCE,
  MAXIMUM_OCCURRENCE,
  MonthlyMode,
  OccurrenceMode,
  RruleConstructorObject,
} from '@jooxter/utils';
import { isNull } from 'lodash-es';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { Frequency, RRule, rrulestr, Weekday } from 'rrule';
import { IRecurrenceForm, TByWeekday } from './types';

export const useRecurrenceFormDefaultValue = (date: DateTime, rrule?: string): { defaultValue: IRecurrenceForm } => {
  const monthDayIndex = date.day;
  const weekdayIndex = date.weekday - 1;

  const defaultValue = useMemo(() => {
    const baseDefaults: IRecurrenceForm = {
      freq: RRule.DAILY,
      count: DEFAULT_OCCURRENCE,
      interval: 1,
      bymonthday: null,
      occurrenceMode: OccurrenceMode.Until,
      dtstart: date.startOf('day').toJSDate(),
      until: date.plus({ month: 1 }).toJSDate(),
      monthlyMode: MonthlyMode.MonthDay,
      byWeekday: [false, false, false, false, false, false, false] as TByWeekday,
    };

    if (rrule) {
      const rruleOptions = rrulestr(rrule).options;
      const { freq, count, interval, bymonthday, until } = rruleOptions;
      const byweekday = rruleOptions.byweekday;

      let occurrenceMode = OccurrenceMode.Until;
      if (typeof count === 'number' && count > 1 && count < MAXIMUM_OCCURRENCE) {
        occurrenceMode = OccurrenceMode.Custom;
      } else if (count === MAXIMUM_OCCURRENCE) {
        occurrenceMode = OccurrenceMode.Max;
      }

      const enrichedDefaults: Partial<IRecurrenceForm> = {
        count,
        interval,
        freq,
        byWeekday: baseDefaults.byWeekday.map((_, i) => !!byweekday?.includes(i)) as TByWeekday,
        occurrenceMode,
        until: until ?? baseDefaults.until,
      };

      if (freq === Frequency.MONTHLY && bymonthday !== null && bymonthday.length === 1 && bymonthday[0] >= 0) {
        enrichedDefaults.bymonthday = bymonthday[0];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        enrichedDefaults.byWeekday![weekdayIndex] = true;

        if (bymonthday?.length === 1 && bymonthday[0] === monthDayIndex) {
          enrichedDefaults.monthlyMode = MonthlyMode.WeekDay;
        }
      }

      if (count === null) {
        enrichedDefaults.count = DEFAULT_OCCURRENCE;
      }

      return {
        ...baseDefaults,
        ...enrichedDefaults,
      };
    }

    return baseDefaults;
  }, [rrule, date.toISO(), monthDayIndex, weekdayIndex]);

  return { defaultValue };
};

export const useRecurrenceFormToRRuleAdapter = (data: IRecurrenceForm, date: DateTime) => {
  const timezone = date.zoneName;
  const daysInWeek = 7;
  const monthdayIndex = Math.ceil(date.day / daysInWeek);

  const adapt = useCallback(() => {
    const rrule = {
      freq: data.freq,
      dtstart: date.startOf('day').toJSDate(),
      interval: data.interval,
      tzid: timezone,
    } as RruleConstructorObject;

    switch (data.occurrenceMode) {
      case OccurrenceMode.Max:
        rrule.until = date.plus({ day: MAXIMUM_OCCURRENCE }).toJSDate();
        break;

      case OccurrenceMode.Custom:
        rrule.count = data.count;
        break;

      case OccurrenceMode.Until:
        rrule.until =
          data.until &&
          DateTime.fromJSDate(data.until, { zone: timezone ?? undefined })
            .endOf('day')
            .toJSDate();
        break;

      default:
        return null;
    }

    if (data.freq === Frequency.WEEKLY && data.byWeekday) {
      rrule.byweekday = data.byWeekday
        .map((selected: boolean, i: number) => (selected ? new Weekday(i) : null))
        .filter((item): item is Weekday => !!item);
    }

    if (data.freq === Frequency.MONTHLY) {
      if (!isNull(data.bymonthday) && !isNaN(data.bymonthday)) {
        rrule.bymonthday = data.bymonthday;
      } else {
        rrule.bysetpos = monthdayIndex;
        rrule.byweekday = data.byWeekday
          .map((selected: boolean, i: number) => (selected ? new Weekday(i) : null))
          .filter((item): item is Weekday => !!item);
      }
    }

    return new RRule(rrule);
  }, [data, date, timezone]);

  return { adapt };
};
