import { parseLinkHeader } from '@jooxter/utils';
// eslint-disable-next-line import/named
import { AxiosHeaders, AxiosResponse } from 'axios';
import { Floor, FloorCompressed, ResourceFloorDto } from '../model';
import { DateTime } from 'luxon';
import { axiosManager } from '../config';

export interface IGetFloorResourcesParameters {
  name?: string;
  capacity?: number;
  bookable?: boolean;
  visible?: boolean;
  resourceTypeId?: number[];
  ownerId?: number[];
  requestOwnerValidation?: boolean;
  from?: DateTime;
  to?: DateTime;
  labels?: string[];
  synchronizedOnly?: boolean;
}

export interface IGetFloorsParameters {
  floorName?: string;
  locationId?: number;
  page?: string;
  size?: number;
  sort?: GetFloorsSortParamsEnum[];
  desc?: GetFloorsSortParamsEnum[];
}

export enum GetFloorsSortParamsEnum {
  FloorNum = 'floorNum',
}

export const FloorService = {
  path: 'floors',

  getFloor(id: number): Promise<FloorCompressed> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getFloorPlan(id: number): Promise<Floor> {
    const url = `${this.path}/${id}/floor_plan`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getFloorPlanResources(id: number, options?: IGetFloorResourcesParameters): Promise<ResourceFloorDto[]> {
    const params = this.createGetFloorResourcesParams(options);
    const url = `${this.path}/${id}/resources`;

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  getFloorsWithPagination(options?: IGetFloorsParameters): Promise<{
    data: FloorCompressed[];
    nextPage: string;
  }> {
    const params = this.createGetFloorsParams(options);
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(this.onNextPage);
    }

    const url = `${this.path}`;

    return axiosManager.getInstance().get(url, { params }).then(this.onNextPage);
  },

  async onNextPage(res: AxiosResponse) {
    if (res.headers?.get && res.headers instanceof AxiosHeaders) {
      const linkHeader = res.headers.get('Link')?.toString() ?? null;
      const parsed = parseLinkHeader(linkHeader);
      const nextPage = parsed?.next.url ?? '';

      return {
        data: await res.data,
        nextPage,
      };
    }

    return {
      data: await res.data,
      nextPage: '',
    };
  },

  createGetFloorsParams(options?: IGetFloorsParameters): URLSearchParams {
    const params = new URLSearchParams();

    if (!options) {
      return params;
    }

    if (options.floorName) {
      params.set('floorName', options.floorName);
    }

    if (options.locationId) {
      params.set('locationId', options.locationId.toString());
    }

    if (options.page) {
      params.set('page', options.page);
    }

    if (options.size) {
      params.set('size', options.size.toString());
    }

    if (options.sort && options.sort.length > 0) {
      params.set('sort', options.sort.join(','));
    }

    if (options.desc && options.desc.length > 0) {
      params.set('desc', options.desc.join(','));
    }

    return params;
  },

  createGetFloorResourcesParams(options?: IGetFloorResourcesParameters): URLSearchParams {
    const params = new URLSearchParams();

    if (!options) {
      return params;
    }

    if (options.name) {
      params.set('name', options.name);
    }

    if (options.capacity) {
      params.set('capacity', options.capacity.toString());
    }

    if (typeof options.bookable === 'boolean') {
      params.set('bookable', options.bookable.toString());
    }

    if (typeof options.visible === 'boolean') {
      params.set('visible', options.visible.toString());
    }

    if (options.resourceTypeId && options.resourceTypeId.length > 0) {
      params.set('resourceTypeId', options.resourceTypeId.join(','));
    }

    if (options.ownerId && options.ownerId.length > 0) {
      params.set('ownerId', options.ownerId.join(','));
    }

    if (typeof options.requestOwnerValidation === 'boolean') {
      params.set('requestOwnerValidation', options.requestOwnerValidation.toString());
    }

    if (options.from) {
      params.set('from', options.from.toUTC().toISO() ?? 'Invalid Date');
    }

    if (options.to) {
      params.set('to', options.to.toUTC().toISO() ?? 'Invalid Date');
    }

    if (options.labels && options.labels.length > 0) {
      params.set('labels', options.labels.join(','));
    }

    if (typeof options.synchronizedOnly === 'boolean') {
      params.set('synchronizedOnly', options.synchronizedOnly.toString());
    }

    return params;
  },
};
