import { useTranslation } from 'react-i18next';
import JxtContainer from '../JxtContainer';
import { ContainerSizeEnum } from '../JxtContainer/types';
import { JxtClipboard } from '../JxtClipboard';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import { IJxtCalendarOverlayContent } from './types';
import { openInNewTab } from '@jooxter/utils';

const JxtCalendarOverlayContent = ({ value }: IJxtCalendarOverlayContent) => {
  const { t } = useTranslation();
  const onClick = () => {
    openInNewTab(t('ics-how-to-use-link'));
  };

  return (
    <JxtContainer size={ContainerSizeEnum.Medium} className="shadow-elevation-3">
      <div className="flex flex-col gap-4">
        <h2 className="text-title-m text-neutral-140 font-medium">{t('ics-title')}</h2>
        <p className="text-body-s text-neutral-120">{t('ics-subtitle')}</p>
        <p className="text-body-s text-neutral-100">{t('ics-copy-link')}</p>
        <JxtClipboard value={value} />
        <p className="text-body-xs text-neutral-100">{t('ics-public-disclaimer')}</p>
        <JxtButton variant={ButtonVariantEnum.Link} className="self-start" onClick={onClick}>
          {t('ics-how-to-use')}
        </JxtButton>
      </div>
    </JxtContainer>
  );
};

export default JxtCalendarOverlayContent;
