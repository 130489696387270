import { LoginForm } from '@jooxter/api';
import { createContext, useContext } from 'react';

export interface IAuthContext {
  login: ({ email, password }: LoginForm) => Promise<void>;
  loginSSO: () => Promise<void | Error>;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: ({ email, password }: LoginForm) => Promise.reject(new Error('No AuthProvider')),
  loginSSO: () => Promise.reject(new Error('No AuthProvider')),
  logout: () => Promise.reject(new Error('No AuthProvider')),
});

export const useAuthContext = (): IAuthContext => {
  return useContext(AuthContext);
};
