import { JxtMeetUpBookingStatusEnum, JxtTagColorsEnum } from '@jooxter/utils';

export const MeetUpBookingStatusTagProps = {
  [JxtMeetUpBookingStatusEnum.ALREADY_BOOKED]: {
    translationKey: 'together.already-booked',
    color: JxtTagColorsEnum.BLUE,
  },
  [JxtMeetUpBookingStatusEnum.SHOULD_BE_BOOKED]: {
    translationKey: 'together.booked-by-jooxter',
    color: JxtTagColorsEnum.NEUTRAL,
  },
  [JxtMeetUpBookingStatusEnum.BOOKED]: {
    translationKey: 'together.booked',
    color: JxtTagColorsEnum.GREEN,
  },
  [JxtMeetUpBookingStatusEnum.ERROR]: {
    translationKey: 'together.error',
    color: JxtTagColorsEnum.RED,
  },
};
