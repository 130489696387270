import { fromISO, IRange, isPastDay } from '@jooxter/utils';
import { BookingCompressed, BookingRequest, Preferences, WorkplaceDto, WorkplaceDtoTypeEnum } from '@jooxter/api';
import { DateTime, Interval } from 'luxon';

export const getExistingBookingsOverlappingRange = (
  range: IRange,
  bookings: BookingCompressed[] = []
): BookingCompressed[] => {
  return (
    bookings?.filter((e) => {
      const bookingInterval = Interval.fromISO(`${e.start.dateTime}/${e.end.dateTime}`);
      const automaticBookingInterval = Interval.fromDateTimes(range.from, range.to);

      return bookingInterval.overlaps(automaticBookingInterval);
    }) ?? []
  );
};

export const doesAutomaticBookingOverlapsExistingBooking = (
  automaticBooking: BookingRequest,
  bookings: BookingCompressed[] = []
): boolean => {
  const from = DateTime.fromISO(automaticBooking.start.dateTime);
  const to = DateTime.fromISO(automaticBooking.end.dateTime);

  return getExistingBookingsOverlappingRange({ from, to }, bookings).length > 0;
};

export const shouldPostAutomaticBooking = (
  workplace: WorkplaceDto,
  automaticBooking: BookingRequest,
  bookings: BookingCompressed[] = [],
  preferences?: Preferences | null
): boolean => {
  if (doesAutomaticBookingOverlapsExistingBooking(automaticBooking, bookings)) {
    return false;
  }

  if (isPastDay(fromISO(workplace.end))) {
    return false;
  }

  if (workplace.location?.id !== preferences?.resource?.locationId || workplace.type !== WorkplaceDtoTypeEnum.Office) {
    return false;
  }

  return true;
};
