import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IUseButtonStatus {
  children: React.ReactNode;
  loading: boolean;
  setLoading: (v: boolean) => void;
}

export const useButtonStatus = (): IUseButtonStatus => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const children: React.ReactNode = loading ? t('submit-btn-pending') : '';

  return { children, loading, setLoading };
};
