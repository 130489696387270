import { ResourceTimelineStatusDto, ResourceTimelineStatusDtoStatusEnum } from '@jooxter/api';
import { IQuickTimeSlot, DataPeriodEnum, fromISO, DateTimeToLocalTime } from '@jooxter/utils';

export const shouldReturnDefaultSlot = (
  timeline: ResourceTimelineStatusDto[],
  defaultSlot: IQuickTimeSlot,
  timezone: string
): boolean => {
  if (defaultSlot.period === DataPeriodEnum.H24 || defaultSlot.period === DataPeriodEnum.H48) {
    return true;
  }
  const hasOnlyOneSlot = timeline.length === 1;

  if (!hasOnlyOneSlot) {
    return false;
  }

  const timelineSlot = timeline[0];

  if (timelineSlot.status !== ResourceTimelineStatusDtoStatusEnum.Free) {
    return false;
  }

  const hasSameStart = fromISO(timelineSlot.start, timezone).equals(defaultSlot.start);
  const hasSameEnd = fromISO(timelineSlot.end, timezone).equals(defaultSlot.end);

  return hasSameStart && hasSameEnd;
};

export const extractFreeSlots = (slots: ResourceTimelineStatusDto[], timezone: string): IQuickTimeSlot[] => {
  return (
    slots
      ?.filter((timeslot) => timeslot.status === ResourceTimelineStatusDtoStatusEnum.Free)
      .map((value) => {
        const start = fromISO(value.start, timezone);
        const end = fromISO(value.end, timezone);
        const startTime = DateTimeToLocalTime(start);
        const endTime = DateTimeToLocalTime(end);

        return {
          text: `${startTime} - ${endTime}`,
          generatedByDefault: false,
          start,
          end,
        };
      }) ?? []
  );
};
