import { JxtSelect } from '@jooxter/ui';
import { BookingRecurrence, useGenerateRruleOptions } from '@jooxter/utils';
import { IJxtRecurrenceSelect } from './types';

const JxtRecurrenceSelect = ({
  onRecurrenceSelect,
  date,
  customizeLabel,
  value,
  label,
  helperText,
  className = '',
}: IJxtRecurrenceSelect) => {
  const { options } = useGenerateRruleOptions(date, customizeLabel);

  return (
    <JxtSelect
      className={className}
      value={options.find((o) => o.value === value)}
      defaultValue={options.find((o) => o.value === BookingRecurrence.Once)}
      label={label}
      helperText={helperText}
      isSearchable={false}
      options={options}
      handleSelection={(e) => e && onRecurrenceSelect(e.value)}
    />
  );
};

export default JxtRecurrenceSelect;
