export interface IJxtIconButton {
  onClick?: () => void;
  size?: JxtIconButtonSizeEnum;
  /**
   * @example "user"
   */
  icon: string;
  title?: string;
  type?: JxtIconButtonTypeEnum;
  disabled?: boolean;
}

export enum JxtIconButtonSizeEnum {
  S = 'small',
  R = 'regular',
  L = 'large',
}

export enum JxtIconButtonTypeEnum {
  Filled = 'filled',
  Light = 'light',
}
