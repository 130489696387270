import { BookingCompressed, BookingRequest } from '@jooxter/api';
import { AxiosError } from 'axios';
import { DataPeriodEnum } from '@jooxter/utils';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { IModalManageBookingsContext, ModalManageBookingsContext } from './ModalManageBookingsContext';
import { TransformedBookings } from '../components/JxtBookingModalContent/types';
import sleep from 'sleep-promise';
import { useBookingCompressedToBookingRequestAdapter } from '../adapters';
import { useBookingActions } from './bookings';
import { UseMutateAsyncFunction, UseMutateFunction } from '@tanstack/react-query';
import { useInvalidateWorkplaceQueries } from '../queries';

type TModalManageBookingsMutationData = unknown;
type TModalManageBookingsMutationVariables = unknown;
type TModalManageBookingsMutation = {
  mutation:
    | UseMutateFunction<TModalManageBookingsMutationData, AxiosError, TModalManageBookingsMutationVariables>
    | UseMutateAsyncFunction<TModalManageBookingsMutationData, AxiosError, TModalManageBookingsMutationVariables>;
  args: TModalManageBookingsMutationVariables | Array<TModalManageBookingsMutationVariables>;
  onSuccess?: (data: TModalManageBookingsMutationData, variables: TModalManageBookingsMutationVariables) => void;
  onError?: (error: AxiosError, variables: TModalManageBookingsMutationVariables) => void;
  onSettled?: (
    data: TModalManageBookingsMutationData,
    error: AxiosError | null,
    variables: TModalManageBookingsMutationVariables
  ) => void;
};

export const ModalManageBookingsProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState<boolean>(false);
  const [periodClicked, setPeriodClicked] = useState<DataPeriodEnum>(DataPeriodEnum.Day);
  const [onHide, setOnHide] = useState<() => void>();
  const [existingBookings, setExistingBookings] = useState<BookingCompressed[]>([]);
  const [transformedBookings, setTransformedBookings] = useState<TransformedBookings>({
    delete: [],
    update: [],
    refuse: [],
  });
  const { adapt: adaptBookingCompressedToBookingRequest } =
    useBookingCompressedToBookingRequestAdapter(existingBookings);
  const [mutationToExecuteAfterConfirmation, setMutationToExecuteAfterConfirmation] = useState<
    Array<TModalManageBookingsMutation> | TModalManageBookingsMutation
  >([]);
  const [title, setTitle] = useState<string>('');
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();

  const { refuse, deleteBookings, update } = useBookingActions();

  const deleteOrUpdateExistingBookings = useCallback(async () => {
    const mutation = Array.isArray(mutationToExecuteAfterConfirmation)
      ? mutationToExecuteAfterConfirmation
      : mutationToExecuteAfterConfirmation
        ? [mutationToExecuteAfterConfirmation]
        : [];
    await Promise.all([
      ...mutation.map((m) => {
        if (Array.isArray(m.args)) {
          return m.mutation(m.args, {
            onSuccess: m.onSuccess,
            onError: m.onError,
            onSettled: m.onSettled,
          });
        }
        return m.mutation(m.args, { onSuccess: m.onSuccess, onError: m.onError, onSettled: m.onSettled });
      }),
    ]);

    await sleep(100);

    if (transformedBookings.delete.length) {
      deleteBookings(transformedBookings.delete);
    }

    if (transformedBookings.refuse.length) {
      await Promise.all(transformedBookings.refuse.map((b) => refuse(b)));
    }

    if (transformedBookings.update.length) {
      const transformedBookingsIds = transformedBookings.update.map((tb) => tb.id);
      const requests = existingBookings
        .filter((b) => transformedBookingsIds.includes(b.id))
        .map((b) => ({
          id: b.id,
          ...adaptBookingCompressedToBookingRequest(b),
          start: {
            dateTime: transformedBookings.update.find((tb) => tb.id === b.id)?.newRange.from.toString(),
          },
          end: {
            dateTime: transformedBookings.update.find((tb) => tb.id === b.id)?.newRange.to.toString(),
          },
        }))
        .filter((b): b is BookingRequest & { id: number } => !!b);

      for (const req of requests) {
        await sleep(400).then(() => update({ id: req.id, payload: req }));
      }
    }

    invalidateWorkplaceQueries();
  }, [
    mutationToExecuteAfterConfirmation,
    existingBookings,
    adaptBookingCompressedToBookingRequest,
    transformedBookings,
    refuse,
    update,
    deleteBookings,
  ]);

  const showDeleteBookingsModal = (bookings: BookingCompressed[]) => {
    setExistingBookings(bookings);
    setShow(true);
  };

  const onConfirm = useCallback(() => {
    deleteOrUpdateExistingBookings();
    setMutationToExecuteAfterConfirmation([]);
  }, [deleteOrUpdateExistingBookings]);

  const onHideModal = useCallback(() => {
    if (onHide) {
      onHide();
    }
    setShow(false);
    setExistingBookings([]);
    setMutationToExecuteAfterConfirmation([]);
  }, [onHide]);

  // setters are supposed to be used by the component/hooks that modifies the context
  // getters are supposed to be used by the component/hooks that displays the modal (ie: App)
  const value: IModalManageBookingsContext = useMemo(
    () => ({
      setters: {
        showDeleteBookingsModal,
        setExistingBookings,
        setPeriodClicked,
        onChange: setTransformedBookings,
        setMutationToExecuteAfterConfirmation,
        setTitle,
        setOnHide,
      },
      getters: {
        bookings: existingBookings,
        period: periodClicked,
        show,
        onHide: onHideModal,
        onConfirm,
        transformedBookings,
        mutationToExecuteAfterConfirmation,
        title,
      },
    }),
    [
      show,
      existingBookings,
      periodClicked,
      mutationToExecuteAfterConfirmation,
      transformedBookings,
      onConfirm,
      title,
      onHideModal,
    ]
  );

  return <ModalManageBookingsContext.Provider value={value}>{children}</ModalManageBookingsContext.Provider>;
};
