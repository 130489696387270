import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import JxtCheckbox from '../JxtCheckbox';
import JxtModal from '../JxtModal';
import { JxtModalSizeEnum } from '../JxtModal/types';
import { IJxtExternalLinkModal } from './types';

const JxtExternalLinkModal = ({ show, externalLinks, onHide }: IJxtExternalLinkModal) => {
  const { t } = useTranslation();
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  return (
    <JxtModal
      show={show}
      size={JxtModalSizeEnum.S}
      onHide={onHide}
      header={{
        title: t<string>('booking.external-links.modal.title'),
        showCloseIcon: false,
        icon: 'link',
      }}
      footer={
        <div className="flex justify-end items-center grow flex-wrap">
          <div className="flex flex-nowrap">
            <JxtButton onClick={onHide} variant={ButtonVariantEnum.Primary} disabled={!isConfirmed}>
              {t('terminate-button')}
            </JxtButton>
          </div>
        </div>
      }
    >
      <div className="text-neutral-140 text-body-m">{t('booking.external-links.modal.description')}</div>
      {externalLinks.length > 0 && (
        <>
          <div className="mt-6 ">
            {externalLinks.map((link, index) => (
              <div key={`link-${index}`} className="flex flex-row mb-2.5">
                <div className="flex shrink text-neutral-140 text-body-s font-medium">{link.name} :</div>
                <a
                  className="flex-1 break-all truncate ml-3 text-primary-100 text-body-s"
                  href={link.href}
                  rel="noreferrer"
                  target="_blank"
                >
                  {link.href}
                </a>
              </div>
            ))}
          </div>
          <div className="mt-6">
            <JxtCheckbox
              name="confirmation"
              value={isConfirmed}
              onChange={() => setIsConfirmed(!isConfirmed)}
              label={t<string>('booking.external-links.modal.checkbox')}
            />
          </div>
        </>
      )}
    </JxtModal>
  );
};

export default JxtExternalLinkModal;
