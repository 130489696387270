import { IJxtWorkplacesDay } from './types';
import JxtWorkplacesIcon from '../JxtWorkplacesIcon';
import { disableIJxtWorkplaceDayReset, disableIJxtWorkplaceHalfDay } from '@jooxter/utils';
import JxtPopover from '../JxtPopover';
import JxtChooseWorkplaceOverlayContent from '../JxtChooseWorkplaceOverlayContent';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import { IJxtChooseWorkplaceOverlayContent } from '../JxtChooseWorkplaceOverlayContent/types';
import { useModalWorkplaceContext } from '../../hooks/ModalWorkplaceContext';

const JxtWorkplacesDay = ({ workplaceDay, user }: IJxtWorkplacesDay) => {
  const isSmallScreen = useIsSmallScreen();
  const modalWorkplaceContext = useModalWorkplaceContext();

  const openChooseWorkplaceModal = (props: IJxtChooseWorkplaceOverlayContent) => {
    if (modalWorkplaceContext) {
      modalWorkplaceContext.setChooseWorkplaceModalProps(props);
      modalWorkplaceContext.setShow(true);
    }
  };

  if (isSmallScreen) {
    return (
      <JxtWorkplacesIcon
        morningType={workplaceDay.morning.type}
        afternoonType={workplaceDay.afternoon.type}
        onClick={() =>
          openChooseWorkplaceModal({
            workplaceDay,
            onClose: () => modalWorkplaceContext?.onHide(),
            disabledMorning: disableIJxtWorkplaceHalfDay(workplaceDay.morning),
            disabledAfternoon: disableIJxtWorkplaceHalfDay(workplaceDay.afternoon),
            disabledReset: disableIJxtWorkplaceDayReset(workplaceDay),
            userInfos: { id: user.id, firstname: user.firstname, lastname: user.lastname },
          })
        }
      />
    );
  }

  return (
    <JxtPopover
      classNames="relative"
      button={() => (
        <JxtWorkplacesIcon morningType={workplaceDay.morning.type} afternoonType={workplaceDay.afternoon.type} />
      )}
      panel={({ onClose }) => (
        <JxtChooseWorkplaceOverlayContent
          workplaceDay={workplaceDay}
          onClose={onClose}
          disabledMorning={disableIJxtWorkplaceHalfDay(workplaceDay.morning)}
          disabledAfternoon={disableIJxtWorkplaceHalfDay(workplaceDay.afternoon)}
          disabledReset={disableIJxtWorkplaceDayReset(workplaceDay)}
          userInfos={{ id: user.id, firstname: user.firstname, lastname: user.lastname }}
        />
      )}
    />
  );
};

export default JxtWorkplacesDay;
