// public
export const LOGIN_PASSWORD_MIN_SIZE = 12;
export const LOGIN_PASSWORD_MAX_SIZE = 100;

/**
 * Check if password pass the jooxter password requirements.
 */
export const PasswordValidator = {
  validate(password: string): boolean {
    return !!password && password.length >= LOGIN_PASSWORD_MIN_SIZE && password.length <= LOGIN_PASSWORD_MAX_SIZE;
  },
};
