import { IJxtBookingDescription } from './types';
import useEncodeUriTeams from '../../hooks/useEncodeUriTeams';

const JxtBookingDescription = ({ text }: IJxtBookingDescription) => {
  const { result: linkifiedText } = useEncodeUriTeams(text);

  return (
    <div className="flex gap-3">
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-align-left fa-fw fa-lg text-neutral-60" />
      </div>
      <div
        className="text-body-m text-neutral-140 whitespace-pre-line break-words min-w-0"
        dangerouslySetInnerHTML={{ __html: linkifiedText }}
      />
    </div>
  );
};

export default JxtBookingDescription;
