const DISPLAY_VALUES_COUNT = 4;

export const convertValues = (values: string | string[]): string => {
  if (Array.isArray(values)) {
    return (
      values.slice(0, DISPLAY_VALUES_COUNT).join(', ') +
      (values.length > DISPLAY_VALUES_COUNT ? `, +${values.length - DISPLAY_VALUES_COUNT}` : '')
    );
  }
  return values;
};
