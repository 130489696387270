import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import {
  LOGIN_ROUTE,
  JxtLoginBase,
  useButtonStatus,
  useStartResetPassword,
  FORGOT_PASSWORD_ROUTE,
  useAuthContext,
} from '@jooxter/core';
import { JxtButton, ButtonVariantEnum, ButtonSizeEnum, JxtInput } from '@jooxter/ui';
import { AxiosError } from 'axios';
import { createGTMGAEvent, createGTMUpdateVirtualPathEvent } from '@jooxter/utils';
import { AuthError } from 'aws-amplify/auth';

const gtm: [string, string, string] = ['Login', 'Forgot Password', 'Forgot Password'];

export const LoginPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuthContext();
  const [email, setEmail] = useState<string>();
  const onStartResetPasswordSuccess = () => navigate(FORGOT_PASSWORD_ROUTE);
  const [disabled, setDisabled] = useState(true);
  const { startResetPassword, isButtonPasswordLoading, btnPasswordChildren } = useStartResetPassword(
    gtm,
    email,
    onStartResetPasswordSuccess
  );
  const { children: btnLogInChildren, setLoading: setBtnLogInLoading, loading: isBtnLoginLoading } = useButtonStatus();
  const { register, handleSubmit, setError, getFieldState, formState } = useForm({
    mode: 'onTouched',
  });

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('Check Password');
  }, []);

  useEffect(() => {
    setDisabled(!!formState.errors.password || !formState.dirtyFields['password']);
  }, [!!formState.errors.password, formState.dirtyFields]);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    } else {
      navigate(LOGIN_ROUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const onSettled = () => setBtnLogInLoading(false);
  const onError = (error: AuthError | AxiosError | Error) => {
    if (error instanceof AuthError) {
      if (error.message === 'Password attempts exceeded') {
        setError('password', { message: t<string>('login.too-many-attemps-wait') });
      } else {
        createGTMGAEvent('Login', 'Check Password', 'Wrong Password');
        setError('password', { message: t<string>('wrong-email-or-password') });
      }
    } else if (error instanceof AxiosError && error.response?.status) {
      switch (error.response?.status) {
        case 403:
          createGTMGAEvent('Login', 'Check Password', 'Wrong Password');
          setError('password', { message: t<string>('wrong-email-or-password') });
          break;
        case 429:
          setError('password', { message: t<string>('login.too-many-attemps-wait') });
          break;
        default:
          setError('password', { message: t<string>('unknown-error') });
          break;
      }
    } else {
      setError('password', { message: t<string>('unknown-error') });
    }
  };

  const onSubmit = (data: FieldValues) => {
    setBtnLogInLoading(true);
    login({ email, password: data.password }).catch(onError).finally(onSettled);
  };

  const form = (
    <form className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <JxtInput
          label={t<string>('email')}
          name="email"
          disabled
          placeholder={email}
          register={register}
          getFieldState={getFieldState}
          formState={formState}
        />
        <JxtInput
          label={t<string>('password')}
          name="password"
          placeholder={t<string>('password')}
          type="password"
          register={register}
          validationSchema={{
            required: {
              value: true,
              message: t('jooxter-connect-password-required'),
            },
          }}
          getFieldState={getFieldState}
          formState={formState}
        />
      </div>
      <div className="flex flex-col gap-3">
        <JxtButton
          variant={isBtnLoginLoading ? ButtonVariantEnum.OutlinePrimary : ButtonVariantEnum.Primary}
          type="submit"
          size={ButtonSizeEnum.Large}
          onClick={handleSubmit(onSubmit)}
          disabled={isBtnLoginLoading ? true : disabled}
        >
          {isBtnLoginLoading ? btnLogInChildren : t('jooxter-connect-btn-submit')}
        </JxtButton>
        <JxtButton
          variant={ButtonVariantEnum.Secondary}
          size={ButtonSizeEnum.Large}
          onClick={() => navigate(LOGIN_ROUTE)}
        >
          {t('jooxter-connect-btn-back')}
        </JxtButton>
        <JxtButton variant={ButtonVariantEnum.Link} onClick={startResetPassword} disabled={isButtonPasswordLoading}>
          {isButtonPasswordLoading ? btnPasswordChildren : t('jooxter-connect-btn-forgotpassword')}
        </JxtButton>
      </div>
    </form>
  );

  return <JxtLoginBase title={t('jooxter-connect-title')}>{form}</JxtLoginBase>;
};
