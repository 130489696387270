import * as React from 'react';
import { MutableRefObject, useState } from 'react';

const useIsOverflow = (ref: MutableRefObject<HTMLElement | null>) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      if (current) {
        const hasOverflow = current.scrollHeight > current.clientHeight;

        setIsOverflow(hasOverflow);
      }
    };

    if (current) {
      trigger();
    }
  }, [ref]);

  return isOverflow;
};

export default useIsOverflow;
