import { useTranslation } from 'react-i18next';
import { ErrorEnum } from './error.enum';
import { getApiErrorMessages } from './helpers';

export const useErrorMessage = (code?: ErrorEnum): { title: string; message: string } => {
  const { t } = useTranslation();
  const { title: titleKey, message: messageKey } = getApiErrorMessages(code);

  return { title: t(titleKey), message: t(messageKey) };
};
