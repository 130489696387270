import { IJxtToast, JxtToastVariantEnum } from './types';
import JxtCloseButton from '../JxtCloseButton';
import { JxtIconButtonSizeEnum, JxtIconButtonTypeEnum } from '../JxtIconButton/types';
import clsx from 'clsx';

const JxtToast = ({
  onHide,
  title,
  subtitle,
  variant = JxtToastVariantEnum.Success,
  closeable = true,
  children,
}: IJxtToast) => {
  return (
    <div className="shadow-elevation-3 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white border border-neutral-10">
      <div className="p-3">
        <div className={clsx('flex', subtitle ? 'items-start' : 'items-center')}>
          <div className="h-7 flex items-center flex-shrink-0">
            {variant === JxtToastVariantEnum.Success && (
              <i className="fas fa-fw fa-check-circle fa-lg text-green-100" />
            )}
            {variant === JxtToastVariantEnum.Failure && <i className="fas fa-fw fa-times-circle fa-lg text-red-100" />}
          </div>
          <div className="ml-3 flex-1 py-0.5 min-w-0 break-words">
            <h2 className="text-title-m font-medium text-neutral-140">{title}</h2>
            {subtitle && <p className="mt-2 text-body-s text-neutral-100">{subtitle}</p>}
            {children && <div className="mt-2 flex space-x-7">{children}</div>}
          </div>
          {closeable && (
            <div className="ml-4 flex flex-shrink-0">
              <JxtCloseButton size={JxtIconButtonSizeEnum.S} type={JxtIconButtonTypeEnum.Light} onClick={onHide} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JxtToast;
