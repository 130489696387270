import { DateTime, Settings } from 'luxon';
import { useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { formatDayText, formatWeekRangeText } from '@jooxter/utils';
import { IJxtRangeDatePicker } from './types';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';
import { flip, offset, useFloating } from '@floating-ui/react';
import JxtWeekDatePicker from '../JxtWeekDatePicker';
import { IJxtRangeDateFilterViewType } from '../JxtRangeDateFilter/types';
import JxtDatePicker from '../JxtDatePicker';
import { reverseLangMapping } from '@jooxter/i18n';
import clsx from 'clsx';

const JxtRangeDatePicker = ({
  range,
  onRangeChange,
  timezone = 'system',
  viewType = IJxtRangeDateFilterViewType.Week,
  excludeWeekEnds = false,
}: IJxtRangeDatePicker) => {
  const { t, i18n } = useTranslation();
  const [buttonText, setButtonText] = useState<string>('');
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [
      flip({
        fallbackPlacements: ['bottom-start', 'bottom-end', 'top-start', 'top', 'left'],
      }),
      offset(8),
    ],
  });

  useEffect(() => {
    const rangeFromIsoDate = range.from.toISODate();
    const rangeToIsoDate = range.to.toISODate();
    if (rangeFromIsoDate && rangeToIsoDate) {
      const newButtonText =
        viewType === IJxtRangeDateFilterViewType.Week
          ? formatWeekRangeText(rangeFromIsoDate, rangeToIsoDate, t('calendar.date.shortMonthName-year.react'))
          : formatDayText(rangeFromIsoDate, t('calendar.date.shortMonthName-year.react'));
      setButtonText(newButtonText);
    }
  }, [range, i18n.language, Settings.defaultLocale, viewType]);

  const handleChangeDate = (date: Date) => {
    const from = DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).startOf(viewType);
    let to = DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).endOf(viewType);
    if (viewType === IJxtRangeDateFilterViewType.Week && excludeWeekEnds) {
      to = to.minus({ days: 2 });
    }
    onRangeChange({ from, to });
  };

  return (
    <Popover className="relative flex">
      <Popover.Button as="div" role="combobox" ref={refs.setReference}>
        <JxtButton
          variant={ButtonVariantEnum.Secondary}
          className={clsx(
            'fc-datePicker-button h-full',
            viewType === IJxtRangeDateFilterViewType.Week ? 'min-w-[200px]' : 'min-w-[140px]'
          )}
          leftIcon="calendar"
          ariaLabel="Open date picker"
        >
          {buttonText}
        </JxtButton>
      </Popover.Button>
      <Popover.Panel ref={refs.setFloating} style={floatingStyles} className="absolute z-10">
        {({ close }) =>
          viewType === IJxtRangeDateFilterViewType.Week ? (
            <JxtWeekDatePicker
              initialDate={range.from.toJSDate()}
              excludeWeekEnds={excludeWeekEnds}
              onDateChange={(e: Date) => {
                handleChangeDate(e);
                close();
              }}
            />
          ) : (
            <JxtDatePicker
              onChange={(e: Date) => {
                handleChangeDate(e);
                close();
              }}
              defaultDate={range.from.toJSDate()}
              value={range.from.toJSDate()}
              withCellSpacing={false}
              locale={reverseLangMapping[i18n.language]}
              excludeDate={excludeWeekEnds ? (date) => date.getDay() === 0 || date.getDay() === 6 : undefined}
              weekendDays={[]}
            />
          )
        }
      </Popover.Panel>
    </Popover>
  );
};

export default JxtRangeDatePicker;
