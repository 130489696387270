import { useCallback } from 'react';
import { FoldedFilterContext, useStore } from '../store';
import { useShallow } from 'zustand/shallow';

export const useFoldedFilters = (
  scope: keyof FoldedFilterContext,
  filterName: keyof FoldedFilterContext[typeof scope]
) => {
  const [foldedFilters, setFoldedFilters] = useStore(
    useShallow((state) => [state.foldedFilters, state.setFoldedFilters])
  );
  const folded = foldedFilters[scope][filterName];

  const onFoldChange = useCallback(
    (changeFilter: boolean) => {
      setFoldedFilters({
        ...foldedFilters,
        [scope]: {
          ...foldedFilters[scope],
          ...{ [filterName]: changeFilter },
        },
      });
    },
    [foldedFilters]
  );
  return { folded, onFoldChange };
};
