import { IRange } from '@jooxter/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const getFormatter = (language: string) => {
  return new Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const useDateTimeFormatter = () => {
  const { i18n } = useTranslation();
  const formatter = useMemo(() => getFormatter(i18n.language), [i18n.language]);

  const formatRange = useCallback(
    ({ from, to }: IRange) => formatter.formatRange(from.toJSDate(), to.toJSDate()),
    [formatter]
  );

  return { formatRange };
};
