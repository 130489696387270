import { DateTime } from 'luxon';
import { ExceptionalOpeningHours } from '../model';
import { axiosManager } from '../config';

export interface IGetExceptionalOpeningHoursByResourceIdParameters {
  from?: DateTime;
  to?: DateTime;
}

export const ExceptionalOpeningHoursService = {
  path: 'resource_exceptional_opening_hours',

  getExceptionalOpeningHoursById(id: number): Promise<ExceptionalOpeningHours> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getExceptionalOpeningHoursByResourceId(
    id: number,
    options?: IGetExceptionalOpeningHoursByResourceIdParameters
  ): Promise<ExceptionalOpeningHours[]> {
    const params = this.createGetExceptionalOpeningHoursByIdParams(options);

    const url = `${this.path}/resources/${id}`;

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  create(exceptionalOpeningHours: ExceptionalOpeningHours): Promise<ExceptionalOpeningHours[]> {
    const url = `${this.path}`;

    return axiosManager
      .getInstance()
      .post(url, exceptionalOpeningHours)
      .then((res) => res.data);
  },

  update(id: number, exceptionalOpeningHours: ExceptionalOpeningHours): Promise<ExceptionalOpeningHours[]> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .put(url, exceptionalOpeningHours)
      .then((res) => res.data);
  },

  delete(id: number): Promise<never> {
    const url = `${this.path}/${id}`;

    return axiosManager.getInstance().delete(url);
  },

  createGetExceptionalOpeningHoursByIdParams(
    options?: IGetExceptionalOpeningHoursByResourceIdParameters
  ): URLSearchParams {
    const params = new URLSearchParams();

    if (!options) {
      return params;
    }

    if (options.from) {
      params.set('date_from', options.from.toISODate() ?? 'Invalid Date');
    }

    if (options.to) {
      params.set('date_to', options.to.toISODate() ?? 'Invalid Date');
    }

    return params;
  },
};
