import { Booking, BookingRequest, BookingRequestVisibilityEnum, WorkTogetherDto } from '@jooxter/api';
import { BOOKINGS_MINE_ROUTE, useBookingActions, useInvalidateWorkplaceQueries } from '@jooxter/core';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton, JxtToast, JxtToastVariantEnum } from '@jooxter/ui';
import { createGTMGAEvent, JxtEventColorsEnum, JxtMeetUpBookingStatusEnum } from '@jooxter/utils';
import { Dispatch, SetStateAction, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const useStartCreateAutomaticBookings = (
  slot: WorkTogetherDto,
  slotIndex: number,
  bookingStatus: Map<number, JxtMeetUpBookingStatusEnum>,
  setBookingStatus: Dispatch<SetStateAction<Map<number, JxtMeetUpBookingStatusEnum>>>,
  setDisableButton: Dispatch<SetStateAction<boolean>>,
  onRetry: () => void
) => {
  const { t } = useTranslation();
  const { create } = useBookingActions();
  const navigate = useNavigate();
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();

  const generateBookingRequest = (
    start: string,
    end: string,
    organizerId: number,
    resourceId: number
  ): BookingRequest => {
    return {
      summary: t('together.automatic-generated-booking.summary'),
      description: t<string>('together.automatic-generated-booking.description'),
      start: { dateTime: start },
      end: { dateTime: end },
      color: JxtEventColorsEnum.Primary,
      organizerId,
      resourceId,
      visibility: BookingRequestVisibilityEnum.Public,
      attendees: {
        internal: [],
        external: [],
      },
      options: [],
      physicalAttendees: 1,
    };
  };

  const setNewBookingStatus = useCallback(
    (result: PromiseSettledResult<Booking>[], bookings: BookingRequest[]) => {
      const newBookingStatus = new Map<number, JxtMeetUpBookingStatusEnum>();
      result.forEach((res, i) => {
        newBookingStatus.set(
          bookings[i].organizerId,
          res.status === 'fulfilled' ? JxtMeetUpBookingStatusEnum.BOOKED : JxtMeetUpBookingStatusEnum.ERROR
        );
      });
      setBookingStatus(new Map([...Array.from(bookingStatus.entries()), ...Array.from(newBookingStatus.entries())]));
    },
    [bookingStatus, setBookingStatus]
  );

  const onSuccessToast = useCallback(() => {
    toast.custom(
      (newToast) => (
        <JxtToast
          onHide={() => toast.remove(newToast.id)}
          title={t('together.booking-confirmed')}
          subtitle={t<string>('together.jooxter-planned-everything')}
          variant={JxtToastVariantEnum.Success}
        >
          <JxtButton
            className="!p-0"
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => {
              createGTMGAEvent('Together', 'My Reservations Button', 'Go to My Reservations');
              navigate(BOOKINGS_MINE_ROUTE);
              toast.remove(newToast.id);
            }}
          >
            {t('together.see-my-reservations')}
          </JxtButton>
        </JxtToast>
      ),
      {
        position: 'bottom-left',
        duration: Infinity,
      }
    );
  }, [navigate]);

  const onErrorToast = useCallback(() => {
    toast.custom(
      (newToast) => (
        <JxtToast
          onHide={() => toast.remove(newToast.id)}
          title={t('together.booking-failed')}
          subtitle={t<string>('together.error-bookings-not-created')}
          variant={JxtToastVariantEnum.Failure}
        >
          <JxtButton
            className="!p-0"
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => {
              onRetry();
              toast.remove(newToast.id);
            }}
          >
            {t('together.try-again')}
          </JxtButton>
        </JxtToast>
      ),
      {
        position: 'bottom-left',
        duration: Infinity,
      }
    );
  }, [onRetry]);

  const startCreateAutomaticBookings = useCallback(async () => {
    setDisableButton(true);
    createGTMGAEvent('Together', 'Option', `Choose Option ${slotIndex + 1}`);
    const bookings: BookingRequest[] = [];
    slot.usersToBook?.forEach((user, i) => {
      if (slot.interval?.start && slot.interval?.end && slot.desksToBook?.[i]?.id) {
        const bookingRequest: BookingRequest = generateBookingRequest(
          slot.interval.start,
          slot.interval.end,
          user.id,
          slot.desksToBook[i].id
        );
        bookings.push(bookingRequest);
      }
    });
    await Promise.allSettled(bookings.map((booking) => create(booking))).then((result) => {
      setNewBookingStatus(result, bookings);
      if (result.some((res) => res.status === 'rejected')) {
        onErrorToast();
      } else {
        onSuccessToast();
      }
      invalidateWorkplaceQueries();
    });
  }, [
    onSuccessToast,
    onErrorToast,
    create,
    slot,
    slotIndex,
    setDisableButton,
    bookingStatus,
    setBookingStatus,
    navigate,
    onRetry,
  ]);

  return { startCreateAutomaticBookings };
};
