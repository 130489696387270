// eslint-disable-next-line import/named
import { components, SingleValue } from 'react-select';
import {
  convert24To12Hour,
  fromStandardTime,
  generateLuxonRange,
  getStandardTime,
  smartTimeFormatter,
} from '@jooxter/utils';
import { DateTime, Settings } from 'luxon';
import { IJxtTimeSelect } from './types';
import { JxtSelect } from '../Selectors';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const validJxtTimeSelectRegexp = new RegExp(/^\d{0,2}?:?\d{1,2}?$/);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NoOptionsMessage = ({ ...innerProps }: any) => {
  const { t } = useTranslation();
  const [customOptionIndex, setCustomOptionIndex] = useState<number>(-1);
  const {
    selectProps: { noOptionsCustomMessage },
    options,
  } = innerProps;

  const validateValue = (option: SingleValue<{ label: string; value: string }>) => (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      innerProps.setValue(option);
    }
  };

  useEffect(() => {
    if (customOptionIndex !== -1) {
      const listener = validateValue(options[customOptionIndex]);
      window.addEventListener('keydown', listener);

      return () => window.removeEventListener('keydown', listener);
    }
  }, [customOptionIndex]);

  useEffect(() => {
    const index = options.findIndex((o: { label: string; value: string }) => o.value === noOptionsCustomMessage);
    setCustomOptionIndex(index);
  }, [options, noOptionsCustomMessage]);

  if (noOptionsCustomMessage) {
    return (
      <components.NoOptionsMessage {...innerProps} className="cursor-pointer">
        <button
          onClick={() => {
            if (customOptionIndex !== -1) {
              innerProps.setValue(options[customOptionIndex]);
            }
          }}
          className="w-full h-full"
        >
          {noOptionsCustomMessage}
        </button>
      </components.NoOptionsMessage>
    );
  }

  return <components.NoOptionsMessage {...innerProps}>{t('no-options')}</components.NoOptionsMessage>;
};

const JxtTimeSelect = ({ selectedHour, valueChange, invalid, onBlur }: IJxtTimeSelect) => {
  const hours = generateLuxonRange({});
  const [options, setOptions] = useState(
    hours.map((h: DateTime) => ({
      value: getStandardTime(h),
      label: h.toLocaleString(DateTime.TIME_SIMPLE),
    }))
  );
  const [customOptionInput, setCustomOptionInput] = useState<string>('');
  const [value, setValue] = useState<number>(options.findIndex((o) => o.value === selectedHour));
  const baseInputClasses =
    'text-center rounded-lg border text-body-s text-neutral-120 empty:text-neutral-80 bg-white disabled:border-neutral-10 disabled:text-neutral-60';
  const errorClasses = invalid ? 'border-red-100 hover:border-red-30' : 'hover:border-neutral-30 border-neutral-20';

  const handleTimeSelect = (v: SingleValue<{ value: string; label: string }>) => {
    if (v?.value) {
      const valueIndex = options.findIndex((o) => o.value === v.value);
      setValue(valueIndex);
    }
  };

  const onInputChange = (input: string) => {
    const formattedInput = smartTimeFormatter.format(input);
    const inputIndex = options.findIndex((o) => o.value === input);
    const formattedInputIndex = options.findIndex((o) => o.value === formattedInput);

    if (formattedInputIndex === -1 && formattedInput && validJxtTimeSelectRegexp.test(formattedInput)) {
      updateOptions({ value: formattedInput, label: convert24To12Hour(formattedInput) });
      setCustomOptionInput(formattedInput);
    } else if (
      inputIndex === -1 &&
      formattedInputIndex !== -1 &&
      formattedInput &&
      validJxtTimeSelectRegexp.test(formattedInput)
    ) {
      setValue(formattedInputIndex);
      setCustomOptionInput(formattedInput);
    }
  };

  const updateOptions = (newOption: { value: string; label: string }) => {
    const results = [...options];

    if (!results.map((r) => r.value).includes(newOption.value)) {
      results.push(newOption);
      results.sort((a, b) => {
        const aDate = fromStandardTime(a.value);
        const bDate = fromStandardTime(b.value);

        if (aDate < bDate) {
          return -1;
        }

        if (bDate > aDate) {
          return 1;
        }

        return 0;
      });

      setOptions(results);
    }
  };

  const updateOptionsWhenLangChange = () => {
    setOptions(
      hours.map((h: DateTime) => ({
        value: getStandardTime(h),
        label: h.toLocaleString(DateTime.TIME_SIMPLE),
      }))
    );
  };

  useEffect(() => {
    if (Settings.defaultLocale) {
      updateOptionsWhenLangChange();
    }
  }, [Settings.defaultLocale]);

  useEffect(() => {
    if (typeof value === 'number' && options[value]) {
      valueChange(options[value].value);
    }
  }, [value]);

  useEffect(() => {
    const selectedHourIndex = options.findIndex((o) => o.value === selectedHour);

    if (selectedHourIndex === -1 && selectedHour && validJxtTimeSelectRegexp.test(selectedHour)) {
      updateOptions({ value: selectedHour, label: convert24To12Hour(selectedHour) });
    }
  }, [selectedHour, options]);

  useEffect(() => {
    const selectedHourIndex = options.findIndex((o) => o.value === selectedHour);

    if (selectedHourIndex >= 0) {
      setValue(selectedHourIndex);
    }
  }, [options, selectedHour]);

  useEffect(() => {
    if (!customOptionInput) {
      return;
    }

    const customOptionIndex = options.findIndex((o) => o.value === customOptionInput);

    if (customOptionIndex >= 0) {
      setValue(customOptionIndex);
    }
  }, [customOptionInput, options]);

  return (
    <JxtSelect
      classNames={{
        control: (state) =>
          clsx(
            baseInputClasses,
            errorClasses,
            state.isFocused
              ? invalid
                ? 'ring-4 ring-red-50 border-red-100'
                : 'ring-4 ring-primary-30 border-primary-100'
              : ''
          ),
        menu: () => 'w-full',
        option: () => 'whitespace-nowrap text-center',
        dropdownIndicator: () => 'hidden',
        valueContainer: () => 'p-0',
      }}
      onInputChange={onInputChange}
      onBlur={onBlur}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      noOptionsCustomMessage={customOptionInput ?? t('no-options')}
      value={value ? options[value] : options[0]}
      options={options}
      components={{ NoOptionsMessage }}
      handleSelection={(e) => {
        setCustomOptionInput('');
        handleTimeSelect(e);
      }}
    />
  );
};

export default JxtTimeSelect;
