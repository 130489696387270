import { useCallback, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import {
  useCheckinBooking,
  useCheckoutBooking,
  useArriveBooking,
  useLeaveBooking,
  useDeleteBooking,
  useApproveBooking,
  useRefuseBooking,
  useUpdateParticipationBooking,
  useDeleteBookings,
  useUpdateBooking,
  useCreateBooking,
} from '../../mutations';
import toast from 'react-hot-toast';
import {
  IJxtEventSummary,
  JxtEventDate,
  JxtEventSummary,
  JxtDeleteEventModal,
  JxtLoader,
  JxtRadioButton,
  JxtResourceInfos,
  JxtToast,
  JxtToastVariantEnum,
} from '@jooxter/ui';
import { useTranslation } from 'react-i18next';
import { FloorQueryKeys, useInvalidateBookingQueries, useInvalidateWorkplaceQueries } from '../../queries';
import { IOffCanvas } from '../useOffCanvasContext';
import { useQueryClient } from '@tanstack/react-query';
import {
  fromISO,
  generateToast,
  ISOToLocalDate,
  ISOToLocalTime,
  JxtBookingParticipationStatusEnum,
  JxtResourcesTypeEnum,
  RecurrentBookingClosingResultEnum,
} from '@jooxter/utils';
import {
  Booking,
  BookingAttendeeParticipationStatusEnum,
  BookingRequest,
  RecurrenceDto,
  ResourceTypeDto,
} from '@jooxter/api';
import { defaultOnError } from '../../mutations/default-error';
import { useForm } from 'react-hook-form';

export interface IJxtDeleteBookingInformations {
  id: number;
  rrule?: string;
  summary: IJxtEventSummary;
  start: {
    dateTime: string;
  };
  end: {
    dateTime: string;
  };
  resourceName: string;
  resourceType?: ResourceTypeDto;
}

export const useBookingActions = (offCanvasContext?: IOffCanvas | null, onBookingDelete?: (id: number) => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { invalidateBookingQueries } = useInvalidateBookingQueries();
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();
  const { mutate: mutationCheckin } = useCheckinBooking();
  const { mutate: mutationCheckout } = useCheckoutBooking();
  const { mutate: mutationRefuse } = useRefuseBooking((data: Booking) => invalidateBookingQueries([data.id]));
  const { mutate: mutationArrive } = useArriveBooking();
  const { mutate: mutationLeave } = useLeaveBooking();
  const { mutate: mutationDelete } = useDeleteBooking();
  const { mutate: mutationUpdate } = useUpdateBooking();
  const { mutateAsync: mutationCreate } = useCreateBooking();
  const { mutate: mutationDeleteMultiple } = useDeleteBookings((_: Booking, variables: number[]) =>
    invalidateBookingQueries(variables)
  );
  const [isBookingDeleting, setIsBookingDeleting] = useState<boolean>(false);
  const { mutateAsync: mutationDeleteRecurrences } = useDeleteBooking(true);
  const { mutate: mutationApprove } = useApproveBooking();
  const { mutate: mutationUpdateParticipation } = useUpdateParticipationBooking();
  const [showDeleteBookingModal, setShowDeleteBookingModal] = useState<boolean>(false);
  const [bookingToDeleteInformation, setBookingToDeleteInformation] = useState<IJxtDeleteBookingInformations>();
  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      recurrence: RecurrentBookingClosingResultEnum.ONLY_SELF,
    },
  });

  const deleteBookings = useCallback(
    (ids: number[]) => {
      return mutationDeleteMultiple(ids);
    },
    [mutationDeleteMultiple]
  );

  const update = useCallback(
    (
      { id, payload }: { id: string | number; payload: BookingRequest },
      onSuccess?: (booking: Booking) => void,
      onError?: (error: unknown) => void,
      onSettled?: () => void
    ) => {
      const idInt = typeof id === 'string' ? parseInt(id, 10) : id;

      const success = (booking: Booking) => {
        generateToast(
          t('update-saved'),
          false,
          t<string>('booking-updated-message', {
            resourceName: booking.resource.name,
            bookingDate: ISOToLocalDate(booking.start.dateTime, booking.resource.timezone),
            startTime: ISOToLocalTime(booking.start.dateTime, booking.resource.timezone),
            endTime: ISOToLocalTime(booking.end.dateTime, booking.resource.timezone),
          })
        );
        invalidateBookingQueries([idInt]);
      };

      const error = (error: AxiosError) => {
        const axiosError = error;
        const response = axiosError.response?.data as { error: string; description: string }[];
        generateToast(t('booking-update-failed'), true, response.length > 0 ? response[0].error : undefined);
      };

      return mutationUpdate(
        { id: idInt, payload },
        {
          onSuccess: onSuccess || success,
          onError: onError || error,
          onSettled,
        }
      );
    },
    [invalidateBookingQueries, mutationUpdate]
  );

  const create = useCallback(
    (
      bookingRequest: BookingRequest,
      onSuccess?: (booking: Booking | RecurrenceDto) => void,
      onError?: (error: AxiosError, variables: BookingRequest) => void,
      onSettled?: () => void
    ) => {
      const error = (e: AxiosError) => {
        defaultOnError(e);
      };

      return mutationCreate(bookingRequest, {
        onSuccess: onSuccess,
        onError: onError || error,
        onSettled,
      });
    },
    [mutationCreate]
  );

  const checkin = useCallback(
    (id: string | number, isZone = false) => {
      const idInt = typeof id === 'string' ? parseInt(id, 10) : id;

      const success = () => {
        generateToast(t('booking-checkin-success'), false);
        invalidateBookingQueries([idInt]);
      };

      const error = () => {
        generateToast(t('booking-checkin-failed'), true);
      };

      if (isZone) {
        return mutationArrive(
          { id: idInt },
          {
            onSuccess: success,
            onError: error,
          }
        );
      }

      return mutationCheckin(
        { id: idInt },
        {
          onSuccess: success,
          onError: error,
        }
      );
    },
    [invalidateBookingQueries, mutationCheckin, mutationArrive]
  );

  const checkout = useCallback(
    (id: string | number, isZone = false) => {
      const idInt = typeof id === 'string' ? parseInt(id, 10) : id;

      const success = () => {
        invalidateBookingQueries([idInt]);
        generateToast(t('booking-checkout-success'), false);
      };

      const error = () => {
        generateToast(t('booking-checkout-failed'), true);
      };

      if (isZone) {
        return mutationLeave(
          { id: idInt },
          {
            onSuccess: success,
            onError: error,
          }
        );
      }

      return mutationCheckout(
        { id: idInt },
        {
          onSuccess: success,
          onError: error,
        }
      );
    },
    [invalidateBookingQueries, mutationCheckout, mutationLeave]
  );

  const arrive = useCallback((id: string | number) => checkin(id, true), [checkin]);
  const leave = useCallback((id: string | number) => checkout(id, true), [checkout]);

  const approve = useCallback(
    (id: string | number) => {
      const idInt = typeof id === 'string' ? parseInt(id, 10) : id;

      const success = () => {
        invalidateBookingQueries([idInt]);
        generateToast(
          t('booking-validation.approved-success'),
          false,
          t<string>('booking-validation.approved-success-subtitle')
        );
      };

      const error = () => {
        generateToast(t('booking-validation-failed'), true);
      };

      return mutationApprove(
        { id: idInt },
        {
          onSuccess: success,
          onError: error,
        }
      );
    },
    [mutationApprove, invalidateBookingQueries]
  );

  const refuse = useCallback(
    (id: string | number) => {
      const idInt = typeof id === 'string' ? parseInt(id, 10) : id;

      const success = () => {
        invalidateBookingQueries([idInt]);
        generateToast(
          t('booking-validation.refused-success'),
          false,
          t<string>('booking-validation.refused-success-subtitle')
        );
      };

      const error = () => {
        generateToast(t('booking-validation-failed'), true);
      };

      return mutationRefuse(
        { id: idInt },
        {
          onSuccess: success,
          onError: error,
        }
      );
    },
    [mutationRefuse, invalidateBookingQueries]
  );

  const cancel = useCallback((booking?: IJxtDeleteBookingInformations): void => {
    if (booking) {
      setBookingToDeleteInformation(booking);
      setShowDeleteBookingModal(true);
    }
  }, []);

  const updateParticipationStatus = (id: string | number, status: JxtBookingParticipationStatusEnum) => {
    const idInt = typeof id === 'string' ? parseInt(id, 10) : id;

    const success = () => {
      invalidateBookingQueries([idInt]);
      switch (status) {
        case JxtBookingParticipationStatusEnum.Accepted:
          generateToast(
            t('booking-participation.status.yes-success'),
            false,
            t<string>('booking-participation.status.yes-success-subtitle')
          );
          break;
        case JxtBookingParticipationStatusEnum.AcceptedRemotely:
          generateToast(
            t('booking-participation.status.remotely-success'),
            false,
            t<string>('booking-participation.status.remotely-success-subtitle')
          );
          break;
        case JxtBookingParticipationStatusEnum.Declined:
          generateToast(
            t('booking-participation.status.no-success'),
            false,
            t<string>('booking-participation.status.no-success-subtitle')
          );
          break;
      }
    };

    const error = () => {
      generateToast(t('booking-participation.status-failed'), true);
    };

    mutationUpdateParticipation(
      { id: idInt, status: status as unknown as BookingAttendeeParticipationStatusEnum },
      {
        onSuccess: success,
        onError: error,
      }
    );
  };

  const onConfirmDeletion = useCallback(
    (id: number, recurrence: boolean) => {
      const onSuccess = () => {
        queryClient.invalidateQueries({
          queryKey: [FloorQueryKeys.GetFloorPlanResources],
        });
        invalidateWorkplaceQueries();
        toast.custom(
          (newToast) => (
            <JxtToast
              variant={JxtToastVariantEnum.Success}
              onHide={() => toast.remove(newToast.id)}
              title={
                recurrence ? t<string>('booking-and-following-occurrences-cancelled') : t<string>('booking-cancelled')
              }
            />
          ),
          { position: 'bottom-left' }
        );

        onBookingDelete && onBookingDelete(id);
      };
      const onError = () => {
        toast.custom(
          (newToast) => (
            <JxtToast
              onHide={() => toast.remove(newToast.id)}
              variant={JxtToastVariantEnum.Failure}
              title={t<string>('booking-cancellation-failed')}
            />
          ),
          { position: 'bottom-left' }
        );
      };

      const onSettled = () => {
        invalidateBookingQueries([id]);
      };

      if (recurrence) {
        setIsBookingDeleting(true);
        mutationDeleteRecurrences(
          { id },
          {
            onSuccess,
            onError,
            onSettled: () => {
              setIsBookingDeleting(false);
              closeDeleteBookingModal();
              onSettled();
            },
          }
        );
      } else {
        closeDeleteBookingModal();
        mutationDelete(
          { id },
          {
            onSuccess,
            onError,
            onSettled,
          }
        );
      }
    },
    [
      invalidateBookingQueries,
      invalidateWorkplaceQueries,
      mutationDelete,
      mutationDeleteRecurrences,
      onBookingDelete,
      queryClient,
    ]
  );
  const onSubmit = (e: { recurrence: RecurrentBookingClosingResultEnum }) => {
    if (e && 'recurrence' in e && bookingToDeleteInformation?.id) {
      onConfirmDeletion(bookingToDeleteInformation?.id, e.recurrence === RecurrentBookingClosingResultEnum.FOLLOWING);
    } else {
      closeDeleteBookingModal();
    }
  };

  const closeDeleteBookingModal = () => {
    setShowDeleteBookingModal(false);
    offCanvasContext?.close();
  };

  const deleteBookingModal = useMemo(
    () => (
      <JxtDeleteEventModal
        titleKey="do-you-want-to-delete-this-booking"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeDeleteBookingModal}
        show={showDeleteBookingModal}
        showFooter={!isBookingDeleting}
      >
        {!isBookingDeleting ? (
          <>
            {bookingToDeleteInformation && (
              <>
                <div className="mb-4">
                  <JxtEventSummary {...bookingToDeleteInformation.summary} />
                </div>
                <div className="mb-4">
                  <JxtEventDate
                    range={{
                      from: fromISO(bookingToDeleteInformation.start.dateTime),
                      to: fromISO(bookingToDeleteInformation.end.dateTime),
                    }}
                  />
                </div>
                {bookingToDeleteInformation.resourceType?.metaType && (
                  <JxtResourceInfos
                    name={bookingToDeleteInformation.resourceName}
                    type={JxtResourcesTypeEnum[bookingToDeleteInformation.resourceType.metaType]}
                  />
                )}
              </>
            )}
            {bookingToDeleteInformation?.rrule && (
              <>
                <hr className="text-neutral-20 my-4" />
                <form className="flex flex-col gap-3">
                  <h3 className="text-neutral-140 font-semibold">{t('what-event-do-you-want-to-delete')}</h3>
                  <JxtRadioButton
                    value={RecurrentBookingClosingResultEnum.ONLY_SELF}
                    label={t<string>('this-booking-only')}
                    {...register('recurrence')}
                    checked={true}
                  />
                  <JxtRadioButton
                    value={RecurrentBookingClosingResultEnum.FOLLOWING}
                    label={t<string>('this-booking-and-the-following-occurrences')}
                    {...register('recurrence')}
                    checked={false}
                  />
                </form>
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-60">
            <JxtLoader />
          </div>
        )}
      </JxtDeleteEventModal>
    ),
    [bookingToDeleteInformation, showDeleteBookingModal, onConfirmDeletion, isBookingDeleting]
  );

  return {
    checkin,
    checkout,
    arrive,
    leave,
    cancel,
    approve,
    refuse,
    update,
    create,
    updateParticipationStatus,
    deleteBookingModal,
    deleteBookings,
  };
};
