import { Autolinker, HtmlTag } from 'autolinker';
import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

const useEncodeUriTeams = (value: string | undefined): { result: string } => {
  const regexp = new RegExp(/[\r\n|]([^_>\r\n|]*)<(http[^>]*)>/, 'g');
  const [result, setResult] = useState<string>('');
  const classes =
    'bg-transparent text-primary-100 hover:underline disabled:text-neutral-60 disabled:no-underline focus:outline-none rounded-lg text-body-m';

  useEffect(() => {
    if (!value) {
      return;
    }

    const matches = [];
    let tmpResult = result;
    let matchArray: RegExpExecArray | null;

    while ((matchArray = regexp.exec(value)) !== null) {
      matches.push([matchArray[1], matchArray[2]]);
    }

    if (!matches.length) {
      // even if no teams link is present, we want to linkifiy other links
      const linker = new Autolinker({
        sanitizeHtml: true,
        className: classes,
      });

      setResult(linker.link(value));
      return;
    }

    matches.forEach((match) => {
      if (!(match[0] && match[1])) {
        return;
      }

      const url = new URL(match[1]);
      const sanitizedUrl = DOMPurify.sanitize(url.toString(), { USE_PROFILES: { html: true } });

      const tag = new HtmlTag({
        tagName: 'a',
        attrs: {
          href: sanitizedUrl,
          class: classes,
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        innerHtml: match[0],
      });

      const withoutLinksInBrackets = tmpResult.replace(`<${match[1]}>`, '');
      tmpResult = withoutLinksInBrackets.replace(match[0], tag.toAnchorString());
    });

    setResult(tmpResult);
  }, [value]);

  return { result };
};

export default useEncodeUriTeams;
