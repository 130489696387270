import { ReactComponent as JooxterLogo } from '../../assets/images/jooxter-logo.svg';
import { JxtLanguageSelect } from '@jooxter/core';
import { useTranslation } from 'react-i18next';
import { IJxtLoginBase } from './types';
import { getJooxterLanguageSubdomain, reverseLangMapping } from '@jooxter/i18n';
import { useEffect, useState, useMemo } from 'react';
import { useLanguageSubdomainRedirect } from '../../hooks/useLanguageSubdomainRedirect';
import { UserRequestExtendedLang } from '@jooxter/api';

const JxtLoginBase = ({ title, children, instructions }: IJxtLoginBase) => {
  const defaultValue = useMemo(() => getJooxterLanguageSubdomain() ?? UserRequestExtendedLang.En, []);
  const [lang, setLang] = useState<UserRequestExtendedLang>(defaultValue);
  const { i18n } = useTranslation();
  const { redirect } = useLanguageSubdomainRedirect(lang);

  useEffect(() => {
    const currentLang = reverseLangMapping[i18n.language];

    if (currentLang !== defaultValue) {
      redirect();
    }
  }, []);

  useEffect(() => {
    if (lang !== defaultValue) {
      redirect();
    }
  }, [lang]);

  const onLanguageChange = (lang: UserRequestExtendedLang) => {
    setLang(lang);
  };

  return (
    <>
      <div data-testid="language-selector" className="flex justify-end">
        <div className="w-[136px]">
          <JxtLanguageSelect defaultValue={defaultValue} onLanguageChange={onLanguageChange} />
        </div>
      </div>

      <section className="m-auto w-[min(400px,_90%)] flex flex-col justify-center mb-5 flex-1 gap-6">
        <JooxterLogo data-testid="login-jooxter-logo-mobile" className="block lg:hidden mx-auto" />
        {title && <h2 className="font-semibold text-neutral-140	text-title-xl text-center">{title}</h2>}
        {instructions && <p className="text-body-m text-neutral-100 text-center">{instructions}</p>}
        {children}
      </section>
    </>
  );
};

export default JxtLoginBase;
