import { IJxtNotificationDevice } from './types';
import { MouseEvent } from 'react';
import clsx from 'clsx';

const JxtNotificationDevice = ({ icon, isActive = false, onClick, classNames }: IJxtNotificationDevice) => {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <button
      type="button"
      onClick={(event) => handleClick(event)}
      className={clsx(
        classNames,
        'h-10 w-10 border rounded-lg',
        isActive
          ? 'border-primary-100 bg-primary-10 text-primary-100 hover:bg-primary-20'
          : 'border-neutral-20 bg-white text-neutral-100 hover:bg-neutral-10'
      )}
    >
      <i className={clsx('fas fa-fw fa-lg', `fa-${icon}`)} />
    </button>
  );
};

export default JxtNotificationDevice;
