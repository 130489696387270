import { IPictoConfig, PictoTypeEnum } from '@jooxter/ui';
import { useCallback, useMemo } from 'react';
import { PlanConfig } from '../config';
import { IUsePictosGenerator } from './types';
import locationDot from '../../../assets/images/location-dot-solid.svg';
import { BookingCompressed } from '@jooxter/api';
import { useTranslation } from 'react-i18next';
import { useFetchUser } from '../../../queries';
import { shouldShowSensibleInformation } from '@jooxter/utils';

// Arbitrary value, we don't want to scale the picto with the resource type
const ARBITRARY_PICTO_SIZE = 75;

export const usePictosGenerators = ({
  width,
  resources,
  bookings,
  locateResourceId,
  floorPlan,
  backgroundImage,
  fixedPictoSize = false,
}: IUsePictosGenerator) => {
  const { t } = useTranslation();
  const { user } = useFetchUser();
  /**
   * To scale ResourceType.mapIconSize
   * This code is from J1.
   * ```
   * var scaledMapIconSize = resourceType.mapIconSize * $("#overlay-img img").width() / 900;
   * ```
   * On mobile, the code is :
   * ```
   * PIXELS_PER_METER / scalePixelsPerMeter * mapIconSize
   * ```
   * but this is a constant, which does not account for the windows' width
   * (does this induce weird sizes on tablet ?)
   *
   * Things to note: in J1, the constants PIXELS_PER_METER, PIXELS_PER_CASE and the variable
   * scalePixelsPerMeter are used only to calculate the variable `nodesize`, which is then used
   * to calculate :
   * - the coordinates of the tooltip
   * - the coordinates of a circle (what is it ?)
   * - the coordinates of an image
   * The constants are not used to calculate the size of the picto in J1
   */
  const scalePictoSize = useCallback(
    (mapIconSize: number) => {
      if (fixedPictoSize || !backgroundImage) {
        return ARBITRARY_PICTO_SIZE;
      }

      return 2 * mapIconSize * (backgroundImage?.naturalWidth / 900);
    },
    [backgroundImage, fixedPictoSize]
  );

  const getDerivativeCoordinates = useCallback(
    (mapIconSize: number, multiplier = 3) => {
      if (!backgroundImage) {
        return null;
      }

      const imgWidth =
        (backgroundImage.naturalWidth * PlanConfig.PIXELS_PER_METER) / (floorPlan.scalePixelsPerMeter ?? 1);
      const ratio = imgWidth / backgroundImage.width;
      const gridSquareSize = PlanConfig.PIXELS_PER_CASE / ratio;

      // I want the center of picto the be in the center of a square grid
      let tx: number;
      let ty: number;

      const size = scalePictoSize(mapIconSize * multiplier);

      if (size <= gridSquareSize) {
        tx = gridSquareSize / 2 - size / 2;
        ty = gridSquareSize / 2 - size / 2;
      } else {
        tx = -(size / 2 - gridSquareSize / 2);
        ty = -(size / 2 - gridSquareSize / 2);
      }

      return { tx, ty, size, gridSquareSize };
    },
    [backgroundImage, floorPlan.scalePixelsPerMeter, scalePictoSize]
  );

  const createPinPicto = useCallback(
    (id: number, resourceId?: number): IPictoConfig | undefined => {
      const coords = locateResourceId(resourceId ?? id);
      // Abort. I don't know where to place the pictogram
      if (!coords) {
        return;
      }

      const derivativeCoordinates = getDerivativeCoordinates(ARBITRARY_PICTO_SIZE, 1);

      if (!derivativeCoordinates) {
        return;
      }

      const { gridSquareSize } = derivativeCoordinates;

      // I want the tip of the pin the be in the center of a square grid
      const tx = -(ARBITRARY_PICTO_SIZE / 2 - gridSquareSize / 2);
      const ty = -(ARBITRARY_PICTO_SIZE - gridSquareSize / 2);

      return {
        size: ARBITRARY_PICTO_SIZE,
        x: tx + coords.x * gridSquareSize,
        y: ty + coords.y * gridSquareSize,
        url: locationDot,
        type: PictoTypeEnum.Booking,
        id,
        showMapIcon: true,
      };
    },
    [locateResourceId, getDerivativeCoordinates]
  );

  const getOccupantsCount = (occupantsCount: number, capacity: number): string => {
    if (occupantsCount > 0) {
      return `${occupantsCount} / ${capacity} ${t<string>('resource-busy', { occupantsCount })}`;
    }
    return '';
  };

  const getBookingInformation = (
    isZone: boolean,
    isUnbookable: boolean,
    capacity: number,
    bookings: BookingCompressed[] = [],
    occupantsCount?: number
  ) => {
    // if resources is zone unbookable, display occupantsCount
    // handle validation
    if (isZone && isUnbookable && occupantsCount && !isNaN(occupantsCount)) {
      return getOccupantsCount(occupantsCount, capacity);
    }

    let bookingInformation = '';

    if (bookings.length === 1) {
      const booking = bookings[0];
      const organizerName = shouldShowSensibleInformation(user, booking)
        ? `${booking.organizer.firstname} ${booking.organizer.lastname}`
        : t<string>('private-booking-fields');
      const peoples =
        booking.physicalAttendees && booking.physicalAttendees > 1
          ? ` (+${booking.physicalAttendees - 1} ${t<string>('resource-person')})`
          : '';
      bookingInformation = `${organizerName}${peoples}`;
    } else if (bookings.length > 1) {
      const peoples = bookings.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.physicalAttendees && currentValue.physicalAttendees > 0 ? currentValue.physicalAttendees : 1),
        0
      );

      if (peoples > 1) {
        bookingInformation = `${peoples.toString()} ${t<string>('resource-person')}`;
      }
    }

    return bookingInformation;
  };

  const resourcePictosGenerator = useMemo(
    () => ({
      generate(): IPictoConfig[] {
        if (!resources) {
          return [];
        }

        return (
          resources
            .map((r) => {
              const coords = locateResourceId(r.id);
              // Abort. I don't know where to place the pictogram
              if (!coords) {
                return false;
              }

              const derivativeCoordinates = getDerivativeCoordinates(
                r.resourceType.mapIconSize,
                floorPlan.mapIconScale ?? 1
              );

              if (!derivativeCoordinates) {
                return [];
              }

              const { tx, ty, size, gridSquareSize } = derivativeCoordinates;

              if (!r.state?.pictogram) {
                return false;
              }

              return {
                id: r.id,
                size,
                x: tx + coords.x * gridSquareSize,
                y: ty + coords.y * gridSquareSize,
                type: PictoTypeEnum.Resource,
                url: `images/${r.state?.pictogram.toLowerCase()}.svg`,
                title: r.name,
                subtitle: getBookingInformation(
                  r.resourceType.allowOverlap,
                  !r.bookable,
                  r.capacity,
                  r.bookings,
                  r.state?.occupantsCount
                ),
                showMapIcon: r.resourceType.showMapIcon,
              } as IPictoConfig;
            })
            // remove undefined values
            .filter((picto): picto is IPictoConfig => !!picto === true)
        );
      },
    }),
    [resources, bookings, locateResourceId, floorPlan, width, backgroundImage, getDerivativeCoordinates]
  );

  const userPictosGenerator = useMemo(
    () => ({
      generate(): IPictoConfig[] {
        if (!bookings?.length) {
          return [];
        }

        return (
          bookings
            .map((booking) => {
              const coords = locateResourceId(booking.resource.id);
              // Abort. I don't know where to place the pictogram
              if (!(coords && backgroundImage)) {
                return false;
              }

              const derivativeCoordinates = getDerivativeCoordinates(booking.resource.resourceType.mapIconSize);

              if (!derivativeCoordinates) {
                return false;
              }

              const { tx, ty, size, gridSquareSize } = derivativeCoordinates;

              return {
                size,
                x: tx + coords.x * gridSquareSize,
                y: ty + coords.y * gridSquareSize,
                userInitials: `${booking.organizer.firstname[0].toUpperCase()}${booking.organizer.lastname[0].toUpperCase()}`,
                firstName: booking.organizer.firstname,
                lastName: booking.organizer.lastname,
                url: booking.organizer.picture,
                type: PictoTypeEnum.User,
              } as IPictoConfig;
            })
            // remove undefined values
            .filter((picto): picto is IPictoConfig => !!picto === true)
        );
      },
    }),
    [
      resources.map((r) => r.id).toString(),
      bookings?.map((b) => b.id).toString(),
      locateResourceId,
      floorPlan.id,
      width,
      backgroundImage,
      getDerivativeCoordinates,
    ]
  );

  const bookingsPictosGenerator = useMemo(
    () => ({
      generate(): IPictoConfig[] {
        const pictos: IPictoConfig[] = [];

        if (resources.length) {
          const resourcePictos = resources
            .map((r) => createPinPicto(r.id))
            .filter((r): r is IPictoConfig => Boolean(r));
          pictos.push(...resourcePictos);
        }

        if (!bookings?.length) {
          return pictos;
        }

        const booking = bookings[0];
        const bookingPicto = createPinPicto(booking.id, booking.resource.id);

        if (bookingPicto) {
          pictos.push(bookingPicto);
        }

        return pictos;
      },
    }),
    [resources, bookings, locateResourceId, createPinPicto, floorPlan.id, width, backgroundImage]
  );

  return { resourcePictosGenerator, userPictosGenerator, bookingsPictosGenerator };
};
