import { IJxtEventDate } from './types';
import JxtEventDateSameDay from '../JxtEventDateSameDay';
import JxtEventDateDifferentDay from '../JxtEventDateDifferentDay';

const JxtEventDate = (props: IJxtEventDate) => {
  return props.range.from.hasSame(props.range.to, 'day') ? (
    <JxtEventDateSameDay {...props} />
  ) : (
    <JxtEventDateDifferentDay {...props} />
  );
};

export default JxtEventDate;
