import { ExceptionalOpeningHoursService, IGetExceptionalOpeningHoursByResourceIdParameters } from '@jooxter/api';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ExceptionalOpeningHoursQueryKeys } from '../queryKeys';
import { ExceptionalOpeningHoursStaleTimeEnum } from '../staleTimes';
import { useCallback } from 'react';

export const useFetchExceptionalOpeningHours = (id?: number) => {
  const fetchExceptionalOpeningHours = () =>
    id ? ExceptionalOpeningHoursService.getExceptionalOpeningHoursById(id) : Promise.resolve(null);

  const {
    data: exceptionalOpeningHours,
    isError,
    isFetching,
  } = useQuery({
    refetchInterval: 30000,
    queryKey: [ExceptionalOpeningHoursQueryKeys.GetExceptionalOpeningHours, id],
    queryFn: () => fetchExceptionalOpeningHours(),
    placeholderData: keepPreviousData,
    staleTime: ExceptionalOpeningHoursStaleTimeEnum.GetExceptionalOpeningHours,
  });

  return { exceptionalOpeningHours, isError, isFetching };
};

export const useFetchExceptionalOpeningHoursByResourceId = (
  id?: number,
  options?: IGetExceptionalOpeningHoursByResourceIdParameters
) => {
  const fetchExceptionalOpeningHours = () =>
    id ? ExceptionalOpeningHoursService.getExceptionalOpeningHoursByResourceId(id, options) : Promise.resolve(null);

  const {
    data: exceptionalOpeningHours,
    isError,
    isFetching,
  } = useQuery({
    refetchInterval: 30000,
    enabled: !!(options?.from && options?.to),
    queryKey: [ExceptionalOpeningHoursQueryKeys.GetExceptionalOpeningHoursByResourceId, id, options],
    queryFn: () => fetchExceptionalOpeningHours(),
    placeholderData: keepPreviousData,
    staleTime: ExceptionalOpeningHoursStaleTimeEnum.GetExceptionalOpeningHoursByResourceId,
  });

  return { exceptionalOpeningHours, isError, isFetching };
};

export const useInvalidateExceptionalOpeningHoursQueries = () => {
  const queryClient = useQueryClient();

  const invalidateExceptionalOpeningHoursQueries = useCallback(
    (ids: number[]) => {
      queryClient.invalidateQueries({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        predicate: (query: any): boolean => {
          return (
            query.queryKey[0] === ExceptionalOpeningHoursQueryKeys.GetExceptionalOpeningHoursByResourceId ||
            (query.queryKey[0] === ExceptionalOpeningHoursQueryKeys.GetExceptionalOpeningHours &&
              ids.includes(query.queryKey[1]))
          );
        },
      });
    },
    [queryClient]
  );

  return { invalidateExceptionalOpeningHoursQueries };
};
