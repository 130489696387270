import clsx from 'clsx';

export const getLabelClasses = (activated: boolean): string => {
  const base = 'cursor-pointer text-base font-medium w-8 h-8 flex items-center justify-center rounded-lg';

  if (activated) {
    return clsx(base, 'bg-primary-100 hover:bg-primary-110 text-white mb-0');
  }

  return clsx(base, 'bg-neutral-20 hover:bg-neutral-40 text-neutral-140 mb-0');
};
