import { Floor } from '@jooxter/api';
import { IFloorPlanBooking, IFloorPlanResource } from '@jooxter/ui';

export enum FloorPlanPictoTypeEnum {
  ResourceState = 'resource-state',
  Avatar = 'avatar',
  Pin = 'pin',
}

export interface IBaseUseFloorPlan {
  resources: IFloorPlanResource[];
  width: number;
  floorPlan: Floor;
  type: FloorPlanPictoTypeEnum;
  bookings?: IFloorPlanBooking[];
}
export interface IUseFloorPlanWithUsers extends IBaseUseFloorPlan {
  type: FloorPlanPictoTypeEnum.Avatar;
  bookings: IFloorPlanBooking[];
}
export interface IUseFloorPlanWithBookings extends IBaseUseFloorPlan {
  type: FloorPlanPictoTypeEnum.Pin;
  bookings: IFloorPlanBooking[];
}
export type IUseFloorPlan = IUseFloorPlanWithBookings | IUseFloorPlanWithUsers | IBaseUseFloorPlan;

export interface IVector2D {
  x: number;
  y: number;
}

export interface IUsePictosGenerator {
  width: number;
  resources: IFloorPlanResource[];
  bookings?: IFloorPlanBooking[];
  floorPlan: Floor;
  locateResourceId: (id: number) => IVector2D | undefined;
  backgroundImage?: HTMLImageElement;
  fixedPictoSize?: boolean;
}
