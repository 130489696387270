import { CookieOptions, CookieService } from '@jooxter/storage';
import { IAuthService } from './types';
const X_API_KEY_TOKEN = 'x-api-key';

export class AuthV3Service implements IAuthService {
  readonly #tokenKey: string = X_API_KEY_TOKEN;
  static #instance: IAuthService;

  private constructor() {
    // private constructor to allow singleton pattern
  }

  public static getInstance(): IAuthService {
    if (!AuthV3Service.#instance) {
      AuthV3Service.#instance = new AuthV3Service();
    }

    return AuthV3Service.#instance;
  }

  public async setToken(token: string, options?: CookieOptions): Promise<void> {
    // ensure no previous token is set in cookies
    const existingToken = await this.getToken();
    if (existingToken && existingToken !== token) {
      this.deleteToken(options);
    }

    CookieService.setCookie(this.#tokenKey, token, options);
  }

  public getToken(): Promise<string> {
    return Promise.resolve(CookieService.getCookie(this.#tokenKey));
  }

  public deleteToken(options?: CookieOptions): void {
    return CookieService.deleteCookie(this.#tokenKey, options);
  }
}
