import { ElementType, ReactNode } from 'react';
import { IJxtOffCanvasHeader } from '../JxtOffCanvasHeader/types';
import { IJxtOffCanvasFooter } from '../JxtOffCanvasFooter/types';
import { TabGroupProps } from '@headlessui/react';

export enum offCanvasContainerEnum {
  Main = 'main',
  Body = 'body',
}

export interface IJxtOffCanvas {
  children?: ReactNode;
  header?: Omit<IJxtOffCanvasHeader, 'onHide'>;
  footer?: IJxtOffCanvasFooter;
  show: boolean;
  onHide?: () => void;
  container?: offCanvasContainerEnum;
  bodyClass?: string;
  className?: string;
  tabConfiguration?: TabGroupProps<ElementType>;
}
