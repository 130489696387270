import { JxtTag, JxtTagSizeEnum } from '@jooxter/ui';
import { useTranslation } from 'react-i18next';
import { MeetUpBookingStatusTagProps } from './constants';
import { IMeetUpBookingStatusTag } from './types';

export const MeetUpBookingStatusTag = ({ status }: IMeetUpBookingStatusTag) => {
  const { t } = useTranslation();

  if (!status) {
    return null;
  }

  return (
    <JxtTag
      text={t(MeetUpBookingStatusTagProps[status].translationKey)}
      color={MeetUpBookingStatusTagProps[status].color}
      size={JxtTagSizeEnum.SMALL}
    />
  );
};
