import { useTranslation } from 'react-i18next';
import JxtButton from '../JxtButton';
import { IJxtNoSpaceAvailable } from './types';

const JxtNoSpaceAvailable = ({ onChangeView }: IJxtNoSpaceAvailable) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4 items-center sm:items-start bg-white rounded-xl flex-wrap sm:justify-between p-4">
      <i className="fas fa-search fa-fw fa-2xl text-neutral-60 sm:leading-10" />
      <div className="flex basis-4/5 shrink grow gap-4 justify-between flex-wrap items-center">
        <header>
          <h2 className="text-title-m text-neutral-140 font-medium sm:mb-2">
            {t('no-spaces-were-found-matching-these-criterias')}
          </h2>
          <h3 className="text-body-s text-neutral-80 hidden sm:block">
            {t('space-search.no-space-available.subtitle')}
          </h3>
        </header>
        <JxtButton className="hidden sm:block whitespace-nowrap" onClick={onChangeView}>
          {t('space-search.no-space-available.button')}
        </JxtButton>
      </div>
    </div>
  );
};

export default JxtNoSpaceAvailable;
