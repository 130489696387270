import { IJxtEventDate } from '../JxtEventDate/types';
import { DateTime } from 'luxon';
import JxtAlert from '../JxtAlert';
import { JxtAlertTypeEnum } from '../JxtAlert/types';
import { getGridStyles } from './styles';

const JxtEventDateDifferentDay = ({ range, timezoneWarning }: IJxtEventDate) => {
  const classes = getGridStyles(!!timezoneWarning);

  return (
    <div className={classes}>
      <div className="h-6 w-5 flex items-center justify-center">
        <i className="fas fa-clock fa-fw fa-lg text-neutral-60" />
      </div>
      <p className="whitespace-nowrap">{range.from.toLocaleString(DateTime.DATE_FULL)}</p>
      <p>{range.from.toLocaleString(DateTime.TIME_SIMPLE)}</p>
      <p className="whitespace-nowrap col-start-2 col-end-3">{range.to.toLocaleString(DateTime.DATE_FULL)}</p>
      <p className="col-start-3 col-end-4">{range.to.toLocaleString(DateTime.TIME_SIMPLE)}</p>
      {!!timezoneWarning && (
        <div className="col-start-2 col-end-4">
          <JxtAlert text={timezoneWarning} type={JxtAlertTypeEnum.Warning} closeable={false} />
        </div>
      )}
    </div>
  );
};

export default JxtEventDateDifferentDay;
