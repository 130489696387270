// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';
import { BookingCompressed } from '@jooxter/api';
import {
  BookingCompressedListItemAdapter,
  FloorPlanPictoTypeEnum,
  JxtFloorPlanPictos,
  useFetchFloor,
  useFetchLocation,
  useFetchFloorPlan,
  JxtMyBookingsCalendarList,
  useSearchBookings,
} from '@jooxter/core';
import { IJxtLocateUserInfos, JxtLocateUserInfos, JxtNoFloorPlan } from '@jooxter/ui';
import { now } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { getSelectedEventId } from './helpers';
import { ILocateUser } from './types';

export const LocateUser = ({ userId, width, height, range, timezone, date }: ILocateUser) => {
  const { bookings } = useSearchBookings({ ...range, participantId: userId });
  const [filteredBookings, setFilteredBookings] = useState<BookingCompressed[]>();
  const [bookingViewed, setBookingViewed] = useState<BookingCompressed | undefined>();
  const { location } = useFetchLocation(bookingViewed?.resource.locationId);
  const { floor } = useFetchFloor(bookingViewed?.resource.floorId);
  const [events, setEvents] = useState<EventInput[]>([]);
  const initialEvent = getSelectedEventId(events, date);
  const [selectedEventId, setSelectedEventId] = useState(initialEvent);
  const { floorPlan } = useFetchFloorPlan(bookingViewed?.resource.floorId);
  const bookingInfosPayload: IJxtLocateUserInfos | null = useMemo(() => {
    if (bookingViewed && location) {
      return {
        resourceName: bookingViewed.resource.name,
        bookingStartTime: DateTime.fromISO(bookingViewed.start.dateTime, {
          zone: bookingViewed.resource.timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE),
        bookingEndTime: DateTime.fromISO(bookingViewed.checkout?.at ?? bookingViewed.end.dateTime, {
          zone: bookingViewed.resource.timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE),
        locationName: location.name,
        floorName: floor?.name,
      };
    }

    return null;
  }, [bookingViewed, location, floor]);

  useEffect(() => {
    const b = bookings
      ?.filter((b: BookingCompressed) => now().startOf('day') < DateTime.fromISO(b.end.dateTime))
      .sort((a, b) => (a.start.dateTime < b.start.dateTime ? -1 : 1));
    setFilteredBookings(b);
    setBookingViewed(b?.[0]);
  }, [bookings]);

  useEffect(() => {
    if (filteredBookings) {
      setEvents(
        filteredBookings.map((b: BookingCompressed) => BookingCompressedListItemAdapter.adapt(b, { title: 'resource' }))
      );
    }
  }, [filteredBookings]);

  useEffect(() => {
    if (initialEvent) {
      handleOnClick(initialEvent);
    }
  }, [initialEvent]);

  const handleOnClick = (id: number) => {
    const bookingToView = filteredBookings?.find((b) => b.id === id);
    setSelectedEventId(id);
    setBookingViewed(bookingToView);
  };

  if (!filteredBookings?.length) {
    return null;
  }

  return (
    <div className="h-full flex max-md:flex-col gap-4 max-md:overflow-hidden">
      <JxtMyBookingsCalendarList
        className="md:basis-1/3 order-2 md:order-1 max-md:overflow-auto"
        events={events}
        selectedEventId={selectedEventId}
        readonly={true}
        range={range}
        onClick={handleOnClick}
        timezone={timezone}
      />
      <div className="flex flex-col grow item-center gap-4 order-1 md:order-2">
        {!!bookingViewed && <JxtLocateUserInfos {...bookingInfosPayload} />}
        {!!floorPlan && (
          <JxtFloorPlanPictos
            floorPlan={floorPlan}
            width={width}
            height={height}
            resources={[]}
            bookings={bookingViewed ? [bookingViewed] : []}
            type={FloorPlanPictoTypeEnum.Avatar}
          />
        )}
        {!floorPlan && <JxtNoFloorPlan />}
      </div>
    </div>
  );
};
