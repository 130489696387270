import clsx from 'clsx';
import { getJxtShareWorkplaceRangeClass } from './styles';
import { IJxtShareWorkplaceRange, JxtShareWorkplaceRangeSizeEnum } from './types';

const JxtShareWorkplaceRange = ({
  text,
  size = JxtShareWorkplaceRangeSizeEnum.Regular,
  className = '',
  onClick,
}: IJxtShareWorkplaceRange) => {
  const classes = getJxtShareWorkplaceRangeClass({ size });

  return (
    <button className={clsx(classes, className)} onClick={onClick}>
      {text}
    </button>
  );
};

export default JxtShareWorkplaceRange;
