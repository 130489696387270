import {
  BookingVisibilitySettingDeskEnum,
  BookingVisibilitySettingOtherEnum,
  Preferences,
  PreferencesRequest,
  PreferencesRequestLangEnum,
} from '@jooxter/api';
import { JxtEventColorsEnum } from '@jooxter/utils';

export const preferencesToPreferencesRequestAdapter = (
  preferences: Preferences
): { preferencesRequest: PreferencesRequest | undefined } => {
  if (!preferences.location) {
    return { preferencesRequest: undefined };
  }
  const preferencesRequest: PreferencesRequest = {
    lang: preferences.lang as unknown as PreferencesRequestLangEnum,
    location: preferences.location.id,
    resource: preferences.resource?.id,
    timezone: preferences.timezone,
    defaultBookingColor: {
      desk: preferences.defaultBookingColor ? preferences.defaultBookingColor.desk : JxtEventColorsEnum.Green,
      other: preferences.defaultBookingColor ? preferences.defaultBookingColor.other : JxtEventColorsEnum.Primary,
    },
    defaultBookingVisibility: {
      desk: preferences.defaultBookingVisibility
        ? preferences.defaultBookingVisibility.desk
        : BookingVisibilitySettingDeskEnum.Public,
      other: preferences.defaultBookingVisibility
        ? preferences.defaultBookingVisibility.other
        : BookingVisibilitySettingOtherEnum.Public,
    },
  };

  return { preferencesRequest };
};
