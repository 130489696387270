import { IJxtBookingFormContainer } from './types';
import { useBookingForm } from '../../forms';
import { useEffect } from 'react';
import { createGTMUpdateVirtualPathEvent } from '@jooxter/utils';

export const JxtBookingFormContainer = ({
  bookingToEdit,
  bookingFormPreset,
  slotToBookInstantly,
}: IJxtBookingFormContainer) => {
  const form = useBookingForm(bookingToEdit, bookingFormPreset, slotToBookInstantly);

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('/Slider Booking');
  }, []);

  return <div className="h-full max-sm:h-[calc(88vh-177px)]">{form}</div>;
};

export default JxtBookingFormContainer;
