import { CSSProperties, MouseEventHandler, ReactNode } from 'react';

export enum ButtonVariantEnum {
  Primary = 'primary',
  OutlinePrimary = 'outline-primary',
  Secondary = 'secondary',
  Link = 'link',
  LinkDark = 'link-dark',
  Light = 'light',
  Danger = 'danger',
  // Warning = 'warning',
}

export enum ButtonSizeEnum {
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
}

export interface IJxtButton {
  /**
   * Variant of the button, it can take the following options:
   * @default primary
   */
  variant?: ButtonVariantEnum;

  /**
   *  Size of the button, it can take the following options:
   *  @default regular
   */
  size?: ButtonSizeEnum;

  /**
   *  Defines if the button is disabled or not.
   *  @default false
   */
  disabled?: boolean;

  /**
   *  The icon to display on the left-hand side of the button.
   *  @default null
   *  @example <JxtButton leftIcon="smile" />
   */
  leftIcon?: string;

  /**
   *  Content of the button, it can be some text or React Node.
   */
  children?: ReactNode;

  /**
   * Triggers function when the button is clicked.
   * @default undefined
   */
  onClick?: MouseEventHandler<HTMLElement>;

  /**
   * Classname to add to the inner button
   * @default '''
   */
  className?: string;

  style?: CSSProperties;

  /**
   * The type of the button
   * @default "button"
   */
  type?: 'button' | 'submit' | 'reset';

  autoFocus?: boolean;

  ariaLabel?: string;
}
