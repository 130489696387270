import { UserRequestExtendedLang } from '@jooxter/api';

export const getJooxterLanguageSubdomain = (): UserRequestExtendedLang | undefined => {
  const subdomain = window.location.origin.replace('https://', '').split('.')[0];

  if (isUserLang(subdomain)) {
    return subdomain;
  }

  return void 0;
};

export const isUserLang = (subdomain: string): subdomain is UserRequestExtendedLang => {
  return Object.values<string>(UserRequestExtendedLang).includes(subdomain);
};
