/**
 * Will compute a ratio to adapt the font-size of the tooltip
 *
 * Case 1: the canvas is 500x200px, the image is 2400x800px, which makes a ratio of 2.5 for the
 * canvas and 3 for the image. As a result, the image will have margins on top and bottom because
 * it's reduced to fit the canvas.
 * Considering the canvas has a viewbox set to the width and height of the image, and that the
 * font-size is calculated relatively to this viewbox, we need to increase it inversely
 * proportionally to the ratio used to reduce it.
 *
 * If the image has been increased, then we return a ratio of 1 because we don't want to increase
 * the font-size.
 */
export const computeRatio = (imgRect: any, canvas?: any): number | undefined => {
  if (!canvas) {
    return;
  }

  // container where the svg will be drawn
  const canvasRect = {
    width: canvas.clientWidth,
    height: canvas.clientHeight,
  };
  const imgRatio = imgRect.naturalWidth / imgRect.naturalHeight;
  const canvasRatio = canvasRect.width / canvasRect.height;

  if (imgRatio > canvasRatio) {
    if (imgRect.naturalHeight < canvasRect.height) {
      return 1;
    }

    return imgRect.naturalHeight / canvasRect.height;
  } else {
    if (imgRect.naturalWidth < canvasRect.width) {
      return 1;
    }

    return imgRect.naturalWidth / canvasRect.width;
  }
};
