// eslint-disable-next-line import/named
import { AxiosHeaders, AxiosResponse } from 'axios';
import { Location, LocationCompressed, LocationMapDto } from '../model';
import { parseLinkHeader } from '@jooxter/utils';
import { axiosManager } from '../config';

export interface ILocationOptions {
  from?: string;
  to?: string;
  capacity?: number;
  labels?: string[];
  resourceTypeId?: number[];
  bookable?: boolean;
  visible?: boolean;
  name?: string;
  ownerId?: number;
  locationId?: number[];
  requestOwnerValidation?: boolean;
}

export const LocationService = {
  path: 'locations',

  getLocation(id: number): Promise<Location> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getLocationsWithPagination(params?: URLSearchParams): Promise<{
    data: LocationCompressed[];
    nextPage: string;
  }> {
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(this.onNextPage);
    }

    const url = `${this.path}`;

    return axiosManager.getInstance().get(url, { params }).then(this.onNextPage);
  },

  searchLocationMap(options: ILocationOptions): Promise<LocationMapDto[]> {
    const url = `${this.path}/map`;
    return axiosManager
      .getInstance()
      .get<LocationMapDto[]>(url, {
        params: this.createParams(options),
      })
      .then((res) => res.data);
  },

  createParams(options: ILocationOptions): URLSearchParams {
    const computedParams = new URLSearchParams();

    if (options.name) {
      computedParams.append('name', options.name);
    }

    if (options.from && options.to) {
      if (options.from > options.to) {
        throw new Error('The start date should be before the end date');
      }

      computedParams.append('from', options.from);
      computedParams.append('to', options.to);
    }

    if (options.capacity) {
      computedParams.append('capacity', options.capacity.toString());
    }

    if (options.labels?.length && options.labels.length > 0) {
      computedParams.append('labels', options.labels.join(','));
    }

    if (options.resourceTypeId?.length && options.resourceTypeId?.length > 0) {
      computedParams.append('resourceTypeId', options.resourceTypeId.toString());
    }

    if (options.locationId?.length && options.locationId?.length > 0) {
      computedParams.append('locationId', options.locationId.toString());
    }

    if (options.ownerId !== null && options.ownerId !== undefined) {
      computedParams.append('ownerId', options.ownerId.toString());
    }

    if ('bookable' in options && typeof options.bookable === 'boolean') {
      computedParams.append('bookable', options.bookable.toString());
    }

    if ('visible' in options && typeof options.visible === 'boolean') {
      computedParams.append('visible', options.visible.toString());
    }

    if ('requestOwnerValidation' in options && typeof options.requestOwnerValidation === 'boolean') {
      computedParams.append('requestOwnerValidation', options.requestOwnerValidation.toString());
    }

    return computedParams;
  },

  searchLocationsWithPagination(params?: URLSearchParams): Promise<{
    data: LocationCompressed[];
    nextPage: string;
  }> {
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(this.onNextPage);
    }

    const url = `${this.path}/search`;

    return axiosManager.getInstance().get(url, { params }).then(this.onNextPage);
  },

  async onNextPage(res: AxiosResponse) {
    if (res.headers?.get && res.headers instanceof AxiosHeaders) {
      const linkHeader = res.headers.get('Link')?.toString() ?? null;
      const parsed = parseLinkHeader(linkHeader);
      const nextPage = parsed?.next.url ?? '';

      return {
        data: await res.data,
        nextPage,
      };
    }

    return {
      data: await res.data,
      nextPage: '',
    };
  },
};
