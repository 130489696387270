import { DataPeriodEnum, fromISO, IQuickTimeSlot } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useWorkHours } from '../services/useWorkHours';
import { useFetchUser } from '../../queries';

export const useDefaultSlotGenerator = (deps: {
  start: string;
  end: string;
  timezone?: string;
}): { slots: IQuickTimeSlot[] } => {
  const { user } = useFetchUser();
  const { getDayHours, getMorningHours, getAfternoonHours, get24Hours, get48Hours } = useWorkHours(user?.id);

  const generateSameDaySlots = (date: DateTime): IQuickTimeSlot[] => {
    const result = [getDayHours(date), getMorningHours(date), getAfternoonHours(date)].filter(
      (slot): slot is IQuickTimeSlot => !!slot
    );

    if (result.length === 2) {
      const dayIndex = result.findIndex((s) => s.period === DataPeriodEnum.Day);

      if (dayIndex !== undefined) {
        result.splice(dayIndex, 1);
      }
    }

    return result;
  };

  const generateDifferentDaySlots = (date: DateTime): IQuickTimeSlot[] => {
    const result = [getDayHours(date), get24Hours(date), get48Hours(date)].filter(
      (slot): slot is IQuickTimeSlot => !!slot
    );
    return result;
  };

  const generate = (start: DateTime, end: DateTime): IQuickTimeSlot[] => {
    if (start.startOf('day').equals(end.startOf('day'))) {
      return generateSameDaySlots(start);
    }

    return generateDifferentDaySlots(start);
  };

  const slots = useMemo(() => {
    const { start, end, timezone } = deps;

    if (!timezone) {
      return [];
    }

    return generate(fromISO(start, timezone), fromISO(end, timezone));
  }, [deps.start, deps.end, deps.timezone, getDayHours, getMorningHours, getAfternoonHours, get24Hours, get48Hours]);

  return { slots };
};
