import { IGetLabelsParameters, LabelsService } from '@jooxter/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { LabelsQueryKeys } from '../queryKeys';
import { LabelsStaleTimeEnum } from '../staleTimes';

export const useFetchLabels = (options?: IGetLabelsParameters): { labels: Array<string> | undefined } => {
  const fetchLabels = useCallback(
    () => (options ? LabelsService.getLabels(options) : LabelsService.getLabels({})),
    [options]
  );

  const { data: labels } = useQuery({
    queryKey: [LabelsQueryKeys.GetLabels, options],
    queryFn: () => fetchLabels(),
    placeholderData: keepPreviousData,
    staleTime: LabelsStaleTimeEnum.GetLabels,
  });

  return { labels };
};
