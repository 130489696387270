// eslint-disable-next-line import/named
import { EventContentArg } from '@fullcalendar/core';
import { User } from '@jooxter/api';
import { BookingDisplayModeEnum } from '@jooxter/ui';
import { JxtResourcesTypeEnum } from '@jooxter/utils';
import { DateTime } from 'luxon';

export interface IFullCalendarConfig {
  user?: User;
  showHours?: boolean;
  showTitle?: boolean;
  showInfoIcons?: boolean;
  showOptions?: boolean;
  showActions?: boolean;
  showPopover?: boolean;
  bookingMode?: BookingDisplayModeEnum;
  view?: string;
  readonly?: boolean;
  selectedEventId?: number;
  callbacks?: {
    onClick?: (id: number) => void;
    goToResources?: (metaType?: JxtResourcesTypeEnum, date?: DateTime) => void;
    onClickCheckIn?: (id: string, _: any, arriveCapability: boolean) => void;
    onClickCheckOut?: (id: string, _: any, leaveCapability: boolean) => void;
    onClickUpdate?: (id: string) => void;
    onClickDelete?: (event: EventContentArg) => void;
  };
}

export enum JxtCustomCalendarViewEnum {
  Month = 'Month',
  Week = 'Week',
  Day = 'Day',
}

export enum FullCalendarViewEnum {
  ListMonth = 'listMonth',
  DayGridMonth = 'dayGridMonth',
  TimeGridWeek = 'timeGridWeek',
  ResourceTimelineDay = 'resourceTimelineDay',
  ResourceTimelineWeek = 'resourceTimelineWeek',
  ResourceTimelineMonth = 'resourceTimelineMonth',
}
