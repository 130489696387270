// eslint-disable-next-line import/named
import { DateSelectArg } from '@fullcalendar/core';
import {
  JxtAlert,
  JxtAlertTypeEnum,
  JxtExceptionalOpeningHoursDetails,
  JxtLoader,
  JxtOffCanvasHeaderHideIconEnum,
  offCanvasContainerEnum,
} from '@jooxter/ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOffCanvas } from '../useOffCanvasContext';
import { IRange, OffCanvasModeEnum, createGTMGAEvent, fromISO, isSameTimezoneAsTheUser, now } from '@jooxter/utils';
import { useFetchExceptionalOpeningHours } from '../../queries/queryFns/exceptional-opening-hours';
import { DateTime } from 'luxon';
import { useTimezoneNameFormatter } from '../useTimezoneNameFormatter';
import { useFetchResource } from '../../queries';
import { IExceptionalOpeningHoursFormPreset } from '../../forms/ExceptionalOpeningHours/types/exceptional-opening-hours-form.types';
import { JxtExceptionalOpeningHoursFormContainer } from '../../components';
import { useExceptionalOpeningHoursToIJxtExceptionalOpeningHoursDetailsAdapter } from '../../adapters/exceptional-opening-hours/useExceptionalOpeningHoursToIJxtExceptionalOpeningHoursDetailsAdapter';
import { useExceptionalOpeningHoursActions } from './useExceptionalOpeningHoursActions';
import { capitalize } from 'lodash-es';

export const useResourceManagerOffCanvas = () => {
  const { format: formatTimezoneName } = useTimezoneNameFormatter();
  const [mode, setMode] = useState<OffCanvasModeEnum>();
  const [container, setContainer] = useState<offCanvasContainerEnum>(offCanvasContainerEnum.Main);
  const { t } = useTranslation();
  const [resourceId, setResourceId] = useState<number | undefined>();
  const offCanvasContext = useOffCanvas();
  const [exceptionalOpeningHoursId, setExceptionalOpeningHoursId] = useState<number | undefined>();
  const { exceptionalOpeningHours } = useFetchExceptionalOpeningHours(exceptionalOpeningHoursId);
  const { resource } = useFetchResource(resourceId ?? exceptionalOpeningHours?.resourceId);
  const { exceptionalOpeningHoursDetails } =
    useExceptionalOpeningHoursToIJxtExceptionalOpeningHoursDetailsAdapter(exceptionalOpeningHours);
  const bookingRange: IRange | undefined = useMemo(() => {
    if (exceptionalOpeningHours?.opened && fromISO(exceptionalOpeningHours.timestampTo) > now()) {
      return { from: fromISO(exceptionalOpeningHours.timestampFrom), to: fromISO(exceptionalOpeningHours.timestampTo) };
    }
    return undefined;
  }, [exceptionalOpeningHours]);
  const { cancel, deleteEventModal } = useExceptionalOpeningHoursActions({
    resourceId,
    range: bookingRange,
    offCanvasContext,
    exceptionalOpeningHours,
    exceptionalOpeningHoursDetails,
  });
  const [preset, setPreset] = useState<IExceptionalOpeningHoursFormPreset>();
  const resourceTimezone = resource?.location?.timezone;
  const shouldShowTimezoneWarning = resourceTimezone ? !isSameTimezoneAsTheUser(resourceTimezone) : false;

  // Internal API
  const resetAndCloseOffCanvas = () => {
    if (mode) {
      createGTMGAEvent('ManageSpaces', capitalize(mode), 'Close Button');
    }
    setExceptionalOpeningHoursId(undefined);
    setResourceId(undefined);
    offCanvasContext?.close();
  };

  const goToEditView = () => {
    setMode(OffCanvasModeEnum.Update);
  };

  const goToExceptionalOpeningHoursView = (id: number, resourceId: number) => {
    setExceptionalOpeningHoursId(id);
    setResourceId(resourceId);
    setMode(OffCanvasModeEnum.View);
  };

  const getIconList = (): { iconName: string; title: string; onClick: () => void }[] => {
    const icons: { iconName: string; title: string; onClick: () => void }[] = [];

    if (OffCanvasModeEnum.View !== mode || !exceptionalOpeningHours) {
      return icons;
    }

    if (fromISO(exceptionalOpeningHours.timestampFrom).plus({ minutes: 5 }) > now()) {
      icons.push({
        iconName: 'pen',
        title: t<string>('update'),
        onClick: () => {
          goToEditView();
          createGTMGAEvent('ManageSpaces', 'Update', 'Modify Button');
        },
      });
    }

    if (fromISO(exceptionalOpeningHours.timestampTo) > now()) {
      icons.push({
        iconName: 'trash',
        title: t<string>('delete'),
        onClick: () => {
          cancel();
          createGTMGAEvent('ManageSpaces', 'Update', 'Delete Button');
        },
      });
    }

    return icons;
  };

  const offCanvasConfiguration = useMemo(() => {
    return {
      container,
      header: {
        hideIcon:
          mode === OffCanvasModeEnum.Update
            ? JxtOffCanvasHeaderHideIconEnum.Back
            : JxtOffCanvasHeaderHideIconEnum.Close,
        iconList: getIconList(),
        title:
          mode !== OffCanvasModeEnum.View ? (
            <header className="max-w-full text-center">
              <h3 className="text-neutral-140 font-medium truncate">{t('manage-space.offcanvas.header.title')}</h3>
            </header>
          ) : null,
        children: mode !== OffCanvasModeEnum.View && (
          <>
            {shouldShowTimezoneWarning && resourceTimezone && (
              <JxtAlert text={formatTimezoneName(resourceTimezone)} type={JxtAlertTypeEnum.Warning} closeable={false} />
            )}
          </>
        ),
      },
      onHide: () => {
        if (mode === OffCanvasModeEnum.Update) {
          setMode(OffCanvasModeEnum.View);
        } else {
          resetAndCloseOffCanvas();
        }
      },
      bodyClass: 'overflow-y-auto',
      children: (
        <>
          {mode === OffCanvasModeEnum.View && !!exceptionalOpeningHoursDetails && (
            <JxtExceptionalOpeningHoursDetails {...exceptionalOpeningHoursDetails} />
          )}
          {mode === OffCanvasModeEnum.Create && (
            <JxtExceptionalOpeningHoursFormContainer exceptionalOpeningHoursFormPreset={{ ...preset, resource }} />
          )}
          {mode === OffCanvasModeEnum.Update && (
            <JxtExceptionalOpeningHoursFormContainer exceptionalOpeningHoursToEdit={exceptionalOpeningHours} />
          )}
          {!(exceptionalOpeningHours || resource) && (
            <div className="absolute top-0 right-0 left-0 bottom-0 backdrop-blur h-full flex flex-1 items-center justify-center">
              <JxtLoader />
            </div>
          )}
          {deleteEventModal}
        </>
      ),
    };
  }, [
    mode,
    deleteEventModal,
    exceptionalOpeningHoursId,
    resource?.id,
    preset,
    exceptionalOpeningHours,
    exceptionalOpeningHoursDetails,
    shouldShowTimezoneWarning,
    cancel,
  ]);

  useEffect(() => {
    offCanvasContext?.setOffCanvasProps(offCanvasConfiguration);
  }, [offCanvasConfiguration]);

  // Public API
  const onEventClick = (id: number, resourceId: number) => {
    setExceptionalOpeningHoursId(id);
    setResourceId(resourceId);
    setMode(OffCanvasModeEnum.View);
  };

  const onSelection = (selectionInfo: DateSelectArg, selectedResourceId: number) => {
    setExceptionalOpeningHoursId(undefined);
    // define a preset
    let from = DateTime.fromISO(selectionInfo.startStr, { zone: selectionInfo.view.getOption('timeZone') });
    if (from < now()) {
      from = now();
    }
    const to = DateTime.fromISO(selectionInfo.endStr, { zone: selectionInfo.view.getOption('timeZone') })
      .minus({
        day: 1,
      })
      .endOf('day');
    setPreset({ from, to });
    setResourceId(selectedResourceId);
    setMode(OffCanvasModeEnum.Create);
  };

  return { offCanvasConfiguration, setContainer, onEventClick, onSelection, goToExceptionalOpeningHoursView };
};
