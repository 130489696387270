// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';
import { BookingCompressed, BookingCompressedVisibilityEnum } from '@jooxter/api';
import { JxtEventColorInfos, JxtEventColorsEnum } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { Adapter, EventTypeEnum, TAdapterOptions } from '../types';

export const BookingCompressedListItemAdapter: Adapter<BookingCompressed, EventInput> = {
  adapt(booking: BookingCompressed, options?: TAdapterOptions): EventInput {
    const start = DateTime.fromISO(booking.start.dateTime);
    let end = DateTime.fromISO(booking.end.dateTime);
    if (booking.checkout?.at) {
      const checkout = DateTime.fromISO(booking.checkout.at);
      end = start.startOf('minute').equals(checkout.startOf('minute')) ? checkout.plus({ seconds: 1 }) : checkout;
    }

    const getEventTitle = () => {
      switch (options?.title) {
        case 'organizer':
          return `${booking.organizer.firstname} ${booking.organizer.lastname}`;
        case 'summary':
          return booking.summary;
        default:
          return booking.resource.name;
      }
    };

    return {
      id: booking.id.toString(),
      title: getEventTitle(),
      start: start.toJSDate(),
      end: end.toJSDate(),
      backgroundColor: JxtEventColorInfos[JxtEventColorsEnum.parse(booking.color)].bgColor,
      borderColor: JxtEventColorInfos[JxtEventColorsEnum.parse(booking.color)].borderColor,
      textColor: JxtEventColorInfos[JxtEventColorsEnum.parse(booking.color)].textColor,
      constraint: 'bgClosedId',
      editable: options?.editable && booking.capabilities?.update,
      groupId: booking.id.toString(),
      extendedProps: {
        type: EventTypeEnum.BOOKING,
        isValidated: booking.approved || booking.checkout || booking.cancelled,
        nbAttendees: booking.physicalAttendees,
        capabilities: booking.capabilities,
        color: JxtEventColorsEnum.parse(booking.color),
        originalBooking: booking,
        icons: booking.icons?.filter((icon) => !!icon),
        organizer: booking.organizer,
        resourceName: booking.resource.name,
        floorId: booking.resource.floorId,
        isPrivate: booking.visibility === BookingCompressedVisibilityEnum.Private,
        physicalAttendees: booking.physicalAttendees,
        participationStatus: booking.participationStatus,
        resourceId: booking.resource.id,
        resourceTypeId: booking.resource.resourceTypeId,
        rrule: booking.rrule,
      },
      resourceId: booking.resource.id.toString(),
    };
  },

  revert() {
    throw new Error('Method not implemented');
  },
};
