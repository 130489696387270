import { useResourceCompressedToTResourceItemAdapter } from '../../hooks/api/useResourceCompressedToTResourceItemAdapter';
import { IJxtResourceList } from './types';
import { groupBy } from 'lodash-es';
import { JxtResourceListBloc } from '@jooxter/ui';

const JxtResourceList = ({
  resources,
  onSwitchToFloorPlan,
  onBook,
  onLocate,
  onClick,
  isAddin = false,
  selectedLocations,
  onToggleFavorite,
}: IJxtResourceList) => {
  const enrichedResources = useResourceCompressedToTResourceItemAdapter(resources);

  const groupAndSortedResources = groupBy(enrichedResources, (resource) =>
    resource.floorNumber !== undefined && !isNaN(resource.floorNumber)
      ? JSON.stringify({
          locationId: resource.locationId,
          locationName: resource.locationName,
          floorId: resource.floorId,
          floorName: resource.floorName,
        })
      : JSON.stringify({
          locationId: resource.locationId,
          locationName: resource.locationName,
          floorId: undefined,
          floorName: undefined,
        })
  );

  return (
    <>
      {Object.entries(groupAndSortedResources).map(([key, value]) => {
        const { locationId, locationName, floorName, floorId } = JSON.parse(key);
        return (
          <JxtResourceListBloc
            onClick={onClick}
            onBook={onBook}
            onLocate={onLocate}
            onToggleFavorite={onToggleFavorite}
            key={key}
            locationId={locationId}
            locationName={locationName}
            floorId={floorId}
            floorName={floorName}
            resourceList={value}
            onSwitchToFloorPlan={onSwitchToFloorPlan}
            isAddin={isAddin}
            selectedLocations={selectedLocations}
          />
        );
      })}
    </>
  );
};

export default JxtResourceList;
