import { ExceptionalOpeningHours, ExceptionalOpeningHoursService } from '@jooxter/api';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { defaultOnError } from './default-error';

export const useCreateExceptionalOpeningHours = (
  onSuccess?: (data: ExceptionalOpeningHours[]) => void,
  onError?: (error: AxiosError, variable: ExceptionalOpeningHours) => void,
  onSettled?: () => void
) => {
  const mutationCreate = useMutation<ExceptionalOpeningHours[], AxiosError, ExceptionalOpeningHours>({
    mutationFn: (exceptionalOpeningHours: ExceptionalOpeningHours) => {
      return ExceptionalOpeningHoursService.create(exceptionalOpeningHours);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationCreate;
};

export const useUpdateExceptionalOpeningHours = (
  onSuccess?: (data: ExceptionalOpeningHours[]) => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) => {
  const mutationUpdate = useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: ExceptionalOpeningHours }) => {
      return ExceptionalOpeningHoursService.update(id, payload);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationUpdate;
};

export const useDeleteExceptionalOpeningHours = (
  onSuccess?: () => void,
  onError?: (error: AxiosError) => void,
  onSettled?: () => void
) => {
  const mutationDelete = useMutation({
    mutationFn: (id: number) => {
      return ExceptionalOpeningHoursService.delete(id);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationDelete;
};
