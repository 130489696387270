import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { defaultOnError } from './default-error';
import { UserService, WorkplaceDto, WorkplaceRequestDto, WorkplaceService } from '@jooxter/api';
import { useInvalidateWorkplaceQueries } from '../queries';

export const useCreateWorkplace = (
  onSuccess?: (data: WorkplaceDto) => void,
  onError?: (error: AxiosError, variable: { userId: number; workplace: WorkplaceRequestDto }) => void,
  onSettled?: () => void
) => {
  const mutationCreate = useMutation<WorkplaceDto, AxiosError, { userId: number; workplace: WorkplaceRequestDto }>({
    mutationFn: ({ userId, workplace }: { userId: number; workplace: WorkplaceRequestDto }) => {
      return WorkplaceService.postWorkplace(userId, workplace);
    },
    onSuccess,
    onError: onError ?? defaultOnError,
    onSettled,
  });

  return mutationCreate;
};

export const useCreateWorkplaces = (
  onSuccess?: (data: WorkplaceDto[]) => void,
  onError?: (error: AxiosError, variable: { userId: number; workplaces: WorkplaceRequestDto[] }) => void,
  onSettled?: () => void
) => {
  const mutationCreate = useMutation<WorkplaceDto[], AxiosError, { userId: number; workplaces: WorkplaceRequestDto[] }>(
    {
      mutationFn: ({ userId, workplaces }: { userId: number; workplaces: WorkplaceRequestDto[] }) => {
        return WorkplaceService.postWorkplaces(userId, workplaces);
      },
      onSuccess,
      onError: onError ?? defaultOnError,
      onSettled,
    }
  );

  return mutationCreate;
};

export const useDeleteWorkplace = () => {
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();

  const mutationDelete = useMutation<void, AxiosError, { userId: number; id: number }>({
    mutationFn: ({ userId, id }: { userId: number; id: number }) => {
      return UserService.deleteWorkplace(id, userId);
    },
    onSettled: invalidateWorkplaceQueries,
  });

  return mutationDelete;
};
