// eslint-disable-next-line import/named
import { DateTime, Interval, DurationLike, Info, Duration, DurationLikeObject, DateTimeUnit, Settings } from 'luxon';
import { IDateTime, ILuxonGeneratorOptions, IRange } from './types';

const ONE_HOUR = 3_600_000;
export const MID_DAY = 13;
export const DEFAULT_START_TIME = 7;
export const DEFAULT_END_TIME = 19;

export const convert24To12Hour = (hour: string): string => {
  if (!hour.includes(':')) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid hour provided, missing colon: ${hour}, returning untouched input`);
    return hour;
  }

  const datetime = DateTime.fromISO(hour);

  return datetime.toLocaleString(DateTime.TIME_SIMPLE);
};

export const generateLuxonRange = ({
  start = DateTime.now().startOf('day'),
  end = DateTime.now().endOf('day'),
  stepAmount = 15,
  stepUnit = 'minutes',
}: ILuxonGeneratorOptions): DateTime[] => {
  const interval = Interval.fromDateTimes(start, end);
  const duration: DurationLike = {};
  duration[stepUnit] = stepAmount;

  return interval
    .splitBy(duration)
    .filter((i) => Boolean(i['start']))
    .map((i) => i.start) as DateTime[];
};

export const formatWeekRangeText = (from: string, to: string, format: string): string => {
  const start = fromISO(from);
  const end = fromISO(to);

  if (start.hasSame(end, 'month')) {
    return `${start.day} - ${end.day} ${start.toFormat(format)}`;
  } else {
    return `${start.day} ${start.toFormat('MMM')} - ${end.day} ${end.toFormat(format)}`;
  }
};

export const formatDayText = (date: string, format: string): string => {
  const dateTime = fromISO(date);
  return `${dateTime.day} ${dateTime.toFormat(format)}`;
};

export const now = (timezone?: string | null) => (timezone ? DateTime.now().setZone(timezone) : DateTime.now());

export const nowMinusFiveMinutes = (timezone?: string | null) => now(timezone).startOf('minute').minus({ minute: 5 });

export const isMorning = (date: DateTime) =>
  Interval.fromDateTimes(
    date.set({ hour: DEFAULT_START_TIME, minute: 0, second: 0 }),
    date.set({ hour: MID_DAY, minute: 0, second: 0 })
  ).contains(date);

export const isAfternoon = (date: DateTime) =>
  Interval.fromDateTimes(
    date.set({ hour: MID_DAY, minute: 0, second: 0 }),
    date.set({ hour: DEFAULT_END_TIME, minute: 0, second: 0 })
  ).contains(date);

export const isDay = (date: DateTime) =>
  Interval.fromDateTimes(
    date.set({ hour: DEFAULT_START_TIME, minute: 0, second: 0 }),
    date.set({ hour: DEFAULT_END_TIME, minute: 0, second: 0 })
  ).contains(date);

export const isMidDay = (date: DateTime) => date.hour === MID_DAY && date.minute === 0;

export const isPastDay = (date: DateTime): boolean => date.startOf('day') < now().startOf('day');

export const isPastDate = (date: DateTime): boolean => date < now();

export const isPastDateMinusFiveMinutes = (date: DateTime, timezone?: string): boolean =>
  date < nowMinusFiveMinutes(timezone);

export const goToNextMondayIf = (date: DateTime, condition: boolean): DateTime => {
  if (condition) {
    return date.plus({ week: 1 }).startOf('week');
  }

  return date;
};

export const addOneWeekIf = (date: DateTime, condition: boolean): DateTime => {
  if (condition) {
    return date.plus({ week: 1 });
  }

  return date;
};

export const dateIsTodayAndWeAreAfternoon = (date: DateTime): boolean =>
  date.hasSame(now(), 'day') && date.set({ hour: MID_DAY }) < now();

export const getWeekdayName = (date: DateTime, locale = 'en-US'): string => {
  return Info.weekdays(undefined, { locale })[date.weekday - 1];
};

export const getWeekdayNameByIndex = (index: number, locale = 'en-US'): string =>
  Info.weekdays('narrow', { locale })[index];

export const isToday = (day: DateTime): boolean => {
  return day.startOf('day').equals(now().setZone(day.zone).startOf('day'));
};

/*
 * Returns the time formatted as hh:mm
 */
export const getStandardTime = (date: DateTime): string => {
  // using date.toLocaleString(DateTime.TIME_24_SIMPLE)
  // returns "8:00" in Spanish, and maybe other locales.
  // We need ton consistently have "08:00"
  return date.toFormat('HH:mm');
};

export const dateToMidDay = (date: DateTime): DateTime => {
  return date.set({ hour: MID_DAY, minute: 0, second: 0 });
};

export const getDurationPrecision = (duration: Duration): keyof DateTime => {
  return (
    (['year', 'month', 'day', 'hour', 'minute', 'second', 'millisecond'] as unknown as Array<keyof DateTime>).find(
      (prec) => duration.as(prec as keyof DurationLikeObject) >= 1
    ) ?? 'millisecond'
  );
};

export const floor = (date: DateTime, duration: Duration): DateTime => {
  const precision = getDurationPrecision(duration);
  const field = date.get(precision);
  const r = field % duration.as(precision as keyof DurationLikeObject);
  return date.minus({ [precision]: r }).startOf(precision as DateTimeUnit);
};

export const splitSpanBy = (span: { start: Date; end: Date }, duration: Duration): { start: Date; end: Date }[] => {
  const slots = [] as { start: Date; end: Date }[];
  const msDuration = duration.as('millisecond');
  const spanLength = Math.abs(+span.end - +span.start);
  const parts = Math.floor(spanLength / msDuration);
  for (let i = 0; i < parts; i++) {
    slots.push({
      start: new Date(+span.start + msDuration * i),
      end: new Date(+span.start + msDuration * (i + 1)),
    });
  }
  return slots;
};

/**
 * If `mnt` is 00:00, then it will be set back to 23:59.000 and to the previous day.
 */
export function cutMidnight(mnt: DateTime): DateTime {
  if (mnt.hour === 0 && mnt.minute === 0) {
    return mnt.set({ millisecond: 0 }).minus({ minutes: 1 });
  }
  return mnt;
}

export const toISO = (date: DateTime): string => {
  return date.set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true }) as string;
};

export const fromISO = (date: string, timezone?: string): DateTime =>
  timezone
    ? DateTime.fromISO(date, { zone: timezone }).set({ millisecond: 0 })
    : DateTime.fromISO(date).set({ millisecond: 0 });

export const dateToISO = (date: Date, timezone?: string): string | null => {
  if (timezone) {
    return DateTime.fromJSDate(date, { zone: timezone }).set({ millisecond: 0 }).toISO({ suppressMilliseconds: true });
  }

  return DateTime.fromJSDate(date).set({ millisecond: 0 }).toISO({ suppressMilliseconds: true });
};

export const DateTimeToLocalTime = (date: DateTime) => {
  return date.toLocaleString(DateTime.TIME_SIMPLE);
};

export const ISOToLocalTime = (date: string, timezone?: string): string => {
  if (timezone) {
    return DateTime.fromISO(date, { zone: timezone }).set({ millisecond: 0 }).toLocaleString(DateTime.TIME_SIMPLE);
  }

  return DateTime.fromISO(date).set({ millisecond: 0 }).toLocaleString(DateTime.TIME_SIMPLE);
};

export const ISOToLocalDate = (date: string, timezone?: string): string => {
  if (timezone) {
    return DateTime.fromISO(date, { zone: timezone }).set({ millisecond: 0 }).toLocaleString(DateTime.DATE_FULL);
  }

  return DateTime.fromISO(date).set({ millisecond: 0 }).toLocaleString(DateTime.DATE_FULL);
};

export const fromStandardTime = (time: string, timezone?: string): DateTime => {
  if (timezone) {
    return DateTime.fromFormat(time, 'HH:mm', { zone: timezone }).set({ millisecond: 0 });
  }

  return DateTime.fromFormat(time, 'HH:mm').set({ millisecond: 0 });
};

export const getRemainingDaysInWeek = (date: DateTime): number =>
  Math.floor(date.endOf('week').minus({ day: 2 }).diff(date).as('day')) + 1;

export const getMonthDayIndex = (date: DateTime): number => Math.ceil(date.day / 7);

export const getDateTimeFromDateTimeAndStandardTime = (date: DateTime, time: string): DateTime => {
  const newTime = DateTime.fromFormat(time, 'HH:mm', { zone: date.zoneName ?? undefined }).set({ millisecond: 0 });

  return date.set({ hour: newTime.hour, minute: newTime.minute, second: 0, millisecond: 0 });
};

export const min = (date1: DateTime, date2: DateTime): DateTime => {
  return DateTime.min(date1, date2);
};

export const max = (date1: DateTime, date2: DateTime): DateTime => {
  return DateTime.max(date1, date2);
};

export const getMinBetweenNextHourAndEndOfDay = (date: DateTime): DateTime => {
  return min(date.plus({ hours: 1 }), date.endOf('day'));
};

export const isDateInRange = (date: DateTime, range: IRange): boolean => {
  return date >= range.from && date <= range.to;
};

export const sameDay = (date1: DateTime, date2: DateTime): boolean => {
  return date1.hasSame(date2, 'day');
};

export const isSameTimezoneAsTheUser = (timezone: string): boolean => {
  const dateNow = now();

  const userTimezoneOffset = Settings.defaultZone.offset(dateNow.toMillis());
  const otherTimezoneOffset = dateNow.setZone(timezone).offset;

  return userTimezoneOffset === otherTimezoneOffset;
};

export const cutTime = (date: DateTime): DateTime => {
  return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

// This is for an isostring coming from a system JS Date
export const convertISOStringToTimezone = (date: string, timezone: string): Date =>
  DateTime.fromISO(date, { zone: 'system' }).setZone(timezone, { keepLocalTime: true }).toJSDate();

export const convertJSDateToTimezone = (date: Date, timezone: string): Date =>
  DateTime.fromISO(date.toISOString(), { zone: 'system' }).setZone(timezone, { keepLocalTime: true }).toJSDate();

export const getIDateTimeFromISO = (date: string, timezone?: string): IDateTime => {
  const dateTime = fromISO(date, timezone);
  const time = getStandardTime(dateTime);

  return { date: dateTime, time };
};

export const getIDateTimeFromDateTime = (dateTime?: DateTime, timezone?: string): IDateTime => {
  const date = dateTime ? dateTime.setZone(timezone, { keepLocalTime: true }) : now();
  return {
    date,
    time: getStandardTime(date),
  };
};

/*
 * Check if the difference between two datetime is one hour
 * is loose on the seconds, i.e 10:44:12 and 11:44:17 will return true
 */
export const isDiffOneHour = (from: DateTime, to: DateTime): boolean =>
  to.startOf('minute').diff(from.startOf('minute')).toMillis() === ONE_HOUR;
