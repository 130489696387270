import { useTranslation } from 'react-i18next';
import JxtAvatar from '../JxtAvatar';
import { JxtAvatarSizeEnum } from '../JxtAvatar/types';
import JxtLocateButton from '../JxtLocateButton';
import { IJxtColleagueWorkplace } from './types';
import JxtWorkplacesIcon from '../JxtWorkplacesIcon';
import JxtStar from '../JxtStar';
import { JxtStarSizeEnum, JxtStarTypeEnum } from '../JxtStar/types';
import { useMemo } from 'react';
import { debounce } from 'lodash-es';
import { FAVORITE_DEBOUNCE_DELAY } from '@jooxter/utils';

const JxtColleagueWorkplace = ({
  user,
  onLocate,
  workplaceDay,
  canBeLocated,
  onToggleFavorite,
  onClick,
  disableFavorite,
}: IJxtColleagueWorkplace) => {
  const { t } = useTranslation();
  const locationName = useMemo(() => {
    if (workplaceDay.morning.locationName === workplaceDay.afternoon.locationName) {
      return workplaceDay?.morning?.locationName;
    }

    if (workplaceDay.morning.locationName && !workplaceDay.afternoon.locationName) {
      return workplaceDay.morning.locationName;
    }

    if (workplaceDay.afternoon.locationName && !workplaceDay.morning.locationName) {
      return workplaceDay.afternoon.locationName;
    }

    if (workplaceDay.morning.locationName && workplaceDay.afternoon.locationName) {
      return `${workplaceDay.morning.locationName}/${workplaceDay.afternoon.locationName}`;
    }
  }, [workplaceDay]);

  if (!user) {
    return null;
  }

  const { firstname, lastname, picture } = user;

  return (
    <div className="flex gap-2 min-w-0 items-center py-2 px-3">
      <span className="shrink-0">
        <JxtStar
          checked={!!user.favorite}
          type={JxtStarTypeEnum.Light}
          size={JxtStarSizeEnum.Medium}
          disabled={disableFavorite}
          onClick={debounce(onToggleFavorite, FAVORITE_DEBOUNCE_DELAY)}
        />
      </span>
      <div className="flex gap-2 items-center grow overflow-hidden" onClick={onClick}>
        <JxtAvatar
          picture={picture}
          text={`${firstname.charAt(0)}${lastname.charAt(0)}`}
          alt={t('avatar-of', {
            name: `${firstname} ${lastname}`,
          })}
          size={JxtAvatarSizeEnum.M}
          className="shrink-0"
        />
        <div className="grow text-body-s text-neutral-120 overflow-hidden">
          <p className="truncate font-medium text-neutral-140 text-body-m">
            {firstname}&nbsp;{lastname}
          </p>
          {!!locationName && <p className="truncate text-body-s text-neutral-100">{locationName}</p>}
        </div>
        <JxtWorkplacesIcon morningType={workplaceDay.morning.type} afternoonType={workplaceDay.afternoon.type} />
      </div>
      <JxtLocateButton
        disabledMessage={t('user-not-locatable-reason')}
        title={t('locate-user')}
        onClick={onLocate}
        disabled={!canBeLocated}
      />
    </div>
  );
};

export default JxtColleagueWorkplace;
