// eslint-disable-next-line import/named
import { OptionProps, SingleValue, components } from 'react-select';
import { useEffect, useState } from 'react';
import { FloorCompressed, FloorService, GetFloorsSortParamsEnum, IGetFloorsParameters } from '@jooxter/api';
import { JxtAsyncSelect, SelectOption } from '@jooxter/ui';
import { IJxtFloorSelect } from './types';
import { TextHighlight } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash-es';

const Option = (props: OptionProps<{ value: number; label: string }>) => {
  const userInput = props?.selectProps?.inputValue || '';
  const label = props?.data?.label || '';
  return (
    <components.Option {...props}>
      <div>
        {userInput?.length ? (
          label?.split(' ').length ? (
            <span>
              <TextHighlight text={label} searchTerm={userInput} />
            </span>
          ) : (
            label
          )
        ) : (
          label
        )}
      </div>
    </components.Option>
  );
};

export const JxtFloorSelect = ({
  locationId,
  onFloorSelect,
  selectedOptions,
  label,
  helperText,
  isDisabled = false,
  isMulti = false,
}: IJxtFloorSelect) => {
  const { t } = useTranslation();
  const [floors, setFloors] = useState<Array<FloorCompressed>>([]);
  const [initOptions, setInitOptions] = useState<{ value: number; label: string }[] | undefined>();

  const findFloor = (floorId: number) => floors?.find((floor) => floor.id === floorId);

  const handleFloorSelection = (option: SingleValue<SelectOption<number>>) => {
    let result: FloorCompressed[] | null = null;
    if (option) {
      if (Array.isArray(option)) {
        result = option
          .map((opt) => findFloor(opt.value))
          .filter((floor): floor is FloorCompressed => floor !== undefined);
      } else {
        const floor = findFloor(option.value);
        if (floor) {
          result = [floor];
        }
      }
      if (result && onFloorSelect) {
        onFloorSelect(result);
      }
    }
  };

  async function loadOptions(search: string, loadedOptions: unknown, additional: { page?: string } | undefined) {
    const options: IGetFloorsParameters = {
      floorName: search,
      locationId: locationId ?? undefined,
      size: 25,
      page: additional?.page,
      sort: [GetFloorsSortParamsEnum.FloorNum],
    };

    const response = await FloorService.getFloorsWithPagination(options).then(async (res) => {
      const filteredNewFloors = uniqBy([...floors, ...res.data], 'id');
      setFloors(filteredNewFloors);

      return {
        data: res.data,
        nextPage: res.nextPage,
      };
    });

    return {
      options: response.data.map((l) => ({
        value: l.id!,
        label: l.name,
      })),
      hasMore: !!response.nextPage,
      additional: {
        page: response.nextPage,
      },
    };
  }

  useEffect(() => {
    let options: { value: number; label: string }[] = [];

    if (floors && floors.length > 0) {
      if (selectedOptions && selectedOptions.length > 0) {
        options = floors
          .filter((floor) => selectedOptions?.includes(floor.id))
          .map((floor: FloorCompressed) => {
            return { value: floor.id, label: floor.name };
          });
      }
    }
    setInitOptions(options);
  }, [floors, selectedOptions]);

  useEffect(() => {
    if (isDisabled) {
      setInitOptions([]);
    }
  }, [isDisabled]);

  return (
    <JxtAsyncSelect
      keyString={`floors-${locationId}`}
      placeholder={t<string>('select-floor-placeholder')}
      isDisabled={isDisabled}
      value={initOptions}
      isMulti={isMulti}
      label={label}
      noOptionsMessage={t<string>('no-matching-floors')}
      helperText={helperText}
      loadOptions={loadOptions}
      handleSelection={handleFloorSelection}
      components={{ Option }}
    />
  );
};
