import { ExceptionalOpeningHoursTypeEnum } from '@jooxter/utils';
import { DateTime, Interval } from 'luxon';

export const getExcludedIntervals = (prevStart: DateTime, prevEnd: DateTime, newStart: DateTime, newEnd: DateTime) => {
  const prevInterval = Interval.fromDateTimes(prevStart, prevEnd);
  const newInterval = Interval.fromDateTimes(newStart, newEnd);
  return prevInterval.difference(newInterval);
};

export const isEditingOpening = (opened: boolean, newType: ExceptionalOpeningHoursTypeEnum) => {
  return opened && newType === ExceptionalOpeningHoursTypeEnum.Opening;
};

export const isEditingClosing = (opened: boolean, newType: ExceptionalOpeningHoursTypeEnum) => {
  return !opened && newType === ExceptionalOpeningHoursTypeEnum.Closing;
};

export const isEditingOpeningToClosing = (opened: boolean, newType: ExceptionalOpeningHoursTypeEnum) => {
  return opened && newType === ExceptionalOpeningHoursTypeEnum.Closing;
};

export const isEditingClosingToOpening = (opened: boolean, newType: ExceptionalOpeningHoursTypeEnum) => {
  return !opened && newType === ExceptionalOpeningHoursTypeEnum.Opening;
};
