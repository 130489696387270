import { ContainerSizeEnum, JxtContainer } from '@jooxter/ui';
import JooxterLogoNavbar from '../../assets/JooxterLogo.svg';
import { version } from '../../../package.json';
import { useTranslation } from 'react-i18next';

export const Version = () => {
  const { t } = useTranslation();
  return (
    <JxtContainer size={ContainerSizeEnum.Small}>
      <div className="flex flex-col items-center p-4">
        <img src={JooxterLogoNavbar} alt="Jooxter logo" className="h-32" />
        <div>
          <p className="mt-6 text-title-l font-light"> {t('app-version', { version })}</p>
        </div>
      </div>
    </JxtContainer>
  );
};
