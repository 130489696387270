import { Location, WorkplaceCompressedDto } from '@jooxter/api';
import { IJxtWorkplaceHalfDay, JxtWorkplaceTypeMapping } from '@jooxter/utils';
import { useCallback } from 'react';

export const useWorkplaceCompressedDtoToIJxtWorkplaceHalfDayAdapter = (locations: Location[]) => {
  const adapt = useCallback(
    (workplaceHalfDay: WorkplaceCompressedDto): IJxtWorkplaceHalfDay => {
      const location = workplaceHalfDay.locationId
        ? locations?.find((location) => location.id === workplaceHalfDay.locationId)
        : undefined;

      return {
        id: workplaceHalfDay.id,
        type: JxtWorkplaceTypeMapping[workplaceHalfDay.type],
        locationName: location?.name,
        locationId: workplaceHalfDay.locationId,
        capabilities: {
          ...workplaceHalfDay.capabilities,
        },
      };
    },
    [locations]
  );

  return { adapt };
};
