import { ErrorEnum } from './error.enum';

export const apiErrorMessages = {
  [ErrorEnum.BAD_REQUEST]: { title: 'error.bad-request', message: 'error.bad-request-reason' },
  [ErrorEnum.NOT_AUTHORIZED]: { title: 'error.not-allowed', message: 'error.not-allowed-reason' },
  [ErrorEnum.FORBIDDEN]: { title: 'error.not-allowed', message: 'error.not-allowed-reason' },
  [ErrorEnum.NOT_FOUND]: { title: 'error.page-not-found', message: 'error.page-not-found-reason' },
  [ErrorEnum.SERVER_INTERNAL_ERROR]: {
    title: 'error.server-internal-error',
    message: 'error.server-internal-error-reason',
  },
};
