import { ResourceTimelineStatusDto, ResourceTimelineStatusDtoStatusEnum, SlotsTypeEnum } from '@jooxter/api';
import { fromISO, IQuickTimeSlot } from '@jooxter/utils';
// eslint-disable-next-line import/named
import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useFetchTimelines } from '../../queries';
import { shouldReturnDefaultSlot } from './helpers';
import { IOptionsToGenerateResourceSlots } from './types';
import { useDefaultDurationSlotGenerator } from './useDefaultDurationSlotGenerator';

export const useResourceDurationSlotGenerator = ({
  date,
  options,
}: {
  date: string;
  options: IOptionsToGenerateResourceSlots;
}) => {
  const { durationSlots: defaultDurationSlots } = useDefaultDurationSlotGenerator(
    fromISO(date, options.timezone),
    options.slots
  );
  const { timelines } = useFetchTimelines({
    ...options,
    id: options.resourceId,
    ranges: defaultDurationSlots.map((s) => ({
      start: s?.start,
      end: s.end,
    })),
  });

  const durationSlots = useMemo(() => {
    let skipAll = false;

    if (options.slots?.type !== SlotsTypeEnum.Duration) {
      return [];
    }

    const timelinesNoUndefined = timelines?.filter(
      (timeline: ResourceTimelineStatusDto[]) => !!timeline && Array.isArray(timeline)
    );

    return timelinesNoUndefined
      .map((timeline: ResourceTimelineStatusDto[], i) => {
        if (!options.timezone) {
          return undefined;
        }

        if (timeline) {
          // from react-query doc "The order returned is the same as the input order."
          // https://tanstack.com/query/latest/docs/react/reference/useQueries
          const currentDefaultSlot = defaultDurationSlots[i];

          if (shouldReturnDefaultSlot(timeline, currentDefaultSlot, options.timezone)) {
            return currentDefaultSlot;
          } else if (i !== timelinesNoUndefined.length - 1 || skipAll) {
            if (i === 0) {
              skipAll = true;
            }

            if (i !== 0 || (i === 0 && timeline[0]?.status !== ResourceTimelineStatusDtoStatusEnum.Free)) {
              return undefined;
            }
          }

          return timeline
            .filter((value) => value.status === ResourceTimelineStatusDtoStatusEnum.Free)
            .map((value) => {
              const start = fromISO(value.start, options.timezone);
              const end = fromISO(value.end, options.timezone);
              let duration = end.startOf('minute').diff(start.startOf('minute')).shiftTo('hours', 'minutes');

              if (duration.minutes === 0) {
                duration = duration.shiftTo('hours');
              }

              if (duration.hours === 0) {
                duration = duration.shiftTo('minutes');
              }

              return {
                generatedByDefault: false,
                start,
                end,
                text: duration.toHuman() ?? '',
              };
            })
            .shift();
        }
      })
      .filter((s): s is IQuickTimeSlot => !!s)
      .flat();
  }, [defaultDurationSlots, options.slots?.type, options.timezone, timelines]);

  return { durationSlots };
};
