import { User } from '@jooxter/api';
import { getJooxterLanguageSubdomain, langMapping } from '@jooxter/i18n';
import { HardRedirectService } from '@jooxter/utils';

export const redirectToSubdomain = (user: User, pathname: string, changeLanguage: (lang: string) => void): void => {
  if (!user.lang) {
    return;
  }

  if (getJooxterLanguageSubdomain() !== user.lang && HardRedirectService.shouldRedirect()) {
    HardRedirectService.redirect(HardRedirectService.getRedirectUrl(user.lang, pathname));
  }

  changeLanguage(langMapping[user.lang]);
};
