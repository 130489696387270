import { Booking } from '@jooxter/api';
import { useBookingActions, useBookingPresenter } from '@jooxter/core';
import { JxtBookingParticipationStatusEnum } from '@jooxter/utils';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

export const useAutomaticBookingAction = (booking: Booking | null | undefined) => {
  const [searchParams] = useSearchParams();
  const { checkin, checkout, arrive, leave, approve, refuse, updateParticipationStatus } = useBookingActions();
  const { presenter } = useBookingPresenter(booking);

  useEffect(() => {
    if (!booking || searchParams.size === 0) {
      return;
    }

    if (searchParams.get('autoCheckin') === 'true') {
      if (presenter.shouldDisplayCheckinButton()) {
        checkin(booking.id);
      } else if (presenter.shouldDisplayArriveButton()) {
        arrive(booking.id);
      }
    } else if (searchParams.get('autoCheckout') === 'true') {
      if (presenter.shouldDisplayCheckoutButton()) {
        checkout(booking.id);
      } else if (presenter.shouldDisplayLeaveButton()) {
        leave(booking.id);
      }
    } else if (searchParams.get('autoValidate') && presenter.showApprove()) {
      switch (searchParams.get('autoValidate')) {
        case 'true':
          approve(booking.id);
          break;
        case 'false':
          refuse(booking.id);
          break;
      }
    } else if (searchParams.get('participationStatus') && presenter.showParticipate()) {
      switch (searchParams.get('participationStatus')) {
        case JxtBookingParticipationStatusEnum.Accepted:
          updateParticipationStatus(booking.id, JxtBookingParticipationStatusEnum.Accepted);
          break;
        case JxtBookingParticipationStatusEnum.Declined:
          updateParticipationStatus(booking.id, JxtBookingParticipationStatusEnum.Declined);
          break;
        case JxtBookingParticipationStatusEnum.AcceptedRemotely:
          updateParticipationStatus(booking.id, JxtBookingParticipationStatusEnum.AcceptedRemotely);
          break;
      }
    }
  }, [presenter, searchParams]);
};
