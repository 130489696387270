import { BookingSettingsContainer } from './BookingSettings';
import { NotificationSettingsContainer } from './NotificationSettings';
import { ProfilePictureContainer } from './ProfilePicture';
import { useFetchUser, useFetchUserNotifications, useFetchUserPreferences, useFetchWeek } from '@jooxter/core';
import { UserInformationContainer } from './UserInformation';
import { TypicalWeekContainer } from './TypicalWeek';
import { useEffect, useState } from 'react';
import { createGTMUpdateVirtualPathEvent } from '@jooxter/utils';
import { useLocation } from 'react-router';
import { JxtLoader } from '@jooxter/ui';

export const UserContainer = () => {
  const { user } = useFetchUser();
  const { preferences } = useFetchUserPreferences(user?.id);
  const { week } = useFetchWeek(user?.id);
  const { notifications } = useFetchUserNotifications(user?.id);
  const { hash } = useLocation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('My profile');
  }, []);

  useEffect(() => {
    if (user && preferences && week && notifications) {
      setIsReady(true);
    }
  }, [user, preferences, week, notifications]);

  useEffect(() => {
    if (hash && isReady) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash, isReady]);

  if (!user?.id || !preferences || !week || !notifications) {
    return (
      <div className="absolute inset-0 flex z-[999] items-center justify-center">
        <JxtLoader />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="flex flex-col gap-8 w-[920px] py-8 mx-4">
        <ProfilePictureContainer user={user} />
        <UserInformationContainer user={user} />
        <BookingSettingsContainer userId={user.id} preferences={preferences} />
        <TypicalWeekContainer userId={user.id} week={week} />
        <NotificationSettingsContainer userId={user.id} notifications={notifications} />
      </div>
    </div>
  );
};
