import { useMemo } from 'react';
import { RRule } from 'rrule';
import { useBookingRecurrenceToRruleOptionsAdapter } from './useBookingRecurrenceToRruleOptions';
import { useRruleI18nService } from './useRrulei18nService';
import { DateTime } from 'luxon';
import { BookingRecurrence } from '../types';
import { useGenerateRruleOptions } from './useGenerateRruleOptions';

export const useGenerateRruleLabel = (date: DateTime, rrulestr?: string) => {
  const { revert } = useBookingRecurrenceToRruleOptionsAdapter(date);
  const { generateInfoMessage } = useRruleI18nService();
  const rrule = revert(rrulestr);
  const isCustomize = rrule === BookingRecurrence.Customize;

  const customizeOptionLabel = useMemo(() => {
    if (rrulestr && isCustomize) {
      return generateInfoMessage(RRule.fromString(rrulestr));
    }

    return undefined;
  }, [rrulestr, generateInfoMessage]);

  const { options: rruleOptions } = useGenerateRruleOptions(date, isCustomize ? customizeOptionLabel : undefined);

  const rruleLabel = useMemo((): string | undefined => {
    return rruleOptions.find((o) => o.value === rrule)?.label;
  }, [rruleOptions]);

  return { rruleLabel };
};
