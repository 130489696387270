import { Booking, BookingAttendee, BookingTypeEnum } from '@jooxter/api';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchUser } from '../../queries';
import { IBookingPresenter } from './types';
import {
  AUTO_CHECKOUT_USER_ID,
  PRIVATE_TOKEN,
  isSameTimezoneAsTheUser,
  shouldShowSensibleInformation,
} from '@jooxter/utils';

export const useBookingPresenter = (booking?: Booking | null): { presenter: IBookingPresenter } => {
  const { t } = useTranslation();
  const { user } = useFetchUser();

  const displayOwnerValidationStatus = useCallback((): boolean => {
    if (booking?.resource.requestOwnerValidation) {
      if (booking.rejected || booking.approved) {
        return true;
      }
    }
    return false;
  }, [booking]);

  const getStatusMessage = useCallback((): string => {
    if (booking?.rejected) {
      return t('this-booking-is-refused');
    }
    return t('this-booking-is-validated');
  }, [booking]);

  const displayOwnerValidationWaiting = useCallback((): boolean => {
    if (booking?.resource.requestOwnerValidation) {
      if (!booking.rejected && !booking.approved && !booking.cancelled && !booking.checkout) {
        return true;
      }
    }
    return false;
  }, [booking]);

  const displayCancelled = useCallback((): boolean => !!booking?.cancelled, [booking]);
  const displayAutoCheckOut = useCallback(
    (): boolean => !!(booking?.checkout && booking.lastModified?.by?.id === AUTO_CHECKOUT_USER_ID),
    [booking]
  );
  const showNotUpdatableAlert = useCallback((): boolean => booking?.type === BookingTypeEnum.External, [booking]);

  const cancelMessage = useCallback((): string => {
    if (!booking?.checkout) {
      return '';
    }

    return t('this-booking-is-auto-cancelled', {
      cancelDate: DateTime.fromISO(booking.checkout.at, {
        zone: booking.resource.timezone,
      }).toFormat('DDD t'),
    });
  }, [booking]);

  const userCancelMessage = useCallback((): string => {
    if (!booking?.cancelled) {
      return '';
    }

    return t('this-booking-is-cancelled', {
      cancelDate: DateTime.fromISO(booking.cancelled.at, {
        zone: booking.resource.timezone,
      }).toFormat('DDD t'),
      cancelBy: `${booking.cancelled.by.firstname} ${booking.cancelled.by.lastname}`,
    });
  }, [booking]);

  const shouldDisplayCheckinButton = useCallback((): boolean => !!booking?.capabilities?.checkin, [booking]);
  const shouldDisplayCheckoutButton = useCallback((): boolean => !!booking?.capabilities?.checkout, [booking]);
  const shouldDisplayArriveButton = useCallback((): boolean => !!booking?.capabilities?.arrive, [booking]);
  const shouldDisplayLeaveButton = useCallback((): boolean => !!booking?.capabilities?.leave, [booking]);

  const shouldShowTimezoneWarning = useCallback((): boolean => {
    return booking?.resource.timezone ? !isSameTimezoneAsTheUser(booking.resource.timezone) : false;
  }, [booking]);

  const getPrincipalAttendee = useCallback((): BookingAttendee | undefined => {
    return booking?.attendees.internal.find((attendee) => attendee.id === user?.id);
  }, [booking, user]);

  const showParticipate = useCallback(() => !!booking?.capabilities?.participate, [booking]);
  const showApprove = useCallback(() => {
    if (booking?.resource.requestOwnerValidation) {
      if (!booking?.rejected && !booking?.approved && !booking?.cancelled && !booking?.checkout) {
        return !!booking?.capabilities?.approve;
      }
    }
    return false;
  }, [booking]);
  const participationStatus = useMemo(() => {
    if (getPrincipalAttendee) {
      const principalAttendee = getPrincipalAttendee();

      if (principalAttendee) {
        return principalAttendee.participationStatus;
      }
    }
  }, [booking, user, getPrincipalAttendee]);

  const showSensibleInformation = useCallback((): boolean => {
    return shouldShowSensibleInformation(user, booking);
  }, [user, booking]);

  const getWaitingApprovalMessage = (): string => {
    return t('this-booking-is-waiting-for-owner-approval');
  };

  const getNotUpdatableText = (): string => {
    return t('this-event-has-not-been-created-in-jooxter-update-or-delete-it-from-your-calendar-provider');
  };

  const presenter = useMemo(
    () => ({
      ...booking,
      ownerName: showSensibleInformation()
        ? `${booking?.organizer.firstname} ${booking?.organizer.lastname}`
        : t<string>(PRIVATE_TOKEN),
      attendees: showSensibleInformation()
        ? booking?.attendees
        : {
            internal: [],
            external: [],
          },
      description: showSensibleInformation() ? booking?.description : t<string>(PRIVATE_TOKEN),
      displayOwnerValidationStatus,
      getStatusMessage,
      displayOwnerValidationWaiting,
      displayCancelled,
      displayAutoCheckOut,
      showNotUpdatableAlert,
      cancelMessage,
      userCancelMessage,
      getWaitingApprovalMessage,
      getNotUpdatableText,
      shouldDisplayCheckinButton,
      shouldDisplayCheckoutButton,
      shouldDisplayArriveButton,
      shouldDisplayLeaveButton,
      shouldShowTimezoneWarning,
      showParticipate,
      showApprove,
      getPrincipalAttendee,
      participationStatus,
    }),
    [booking]
  );

  return {
    presenter,
  };
};
