import { Fragment, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { DateTime } from 'luxon';
import JxtEventSummary from '../JxtEventSummary';
import { BookingCompressed, Floor, Location } from '@jooxter/api';
import { JxtEventColorsEnum, fromISO, now, useGenerateRruleLabel } from '@jooxter/utils';
import JxtBookingLocation from '../JxtBookingLocation';
import JxtBookingOrganizer from '../JxtBookingOrganizer';
import JxtBookingAttendeesList from '../JxtBookingAttendeesList';
import { useTranslation } from 'react-i18next';
import { IJxtSchedulerCalendarTooltip } from './types';
import { useFloating, flip, offset, FloatingPortal, arrow } from '@floating-ui/react';
import JxtBookingRecurrence from '../JxtBookingRecurrence';
import { useDebouncedValue } from '@mantine/hooks';
import './style.scss';
import JxtEventDate from '../JxtEventDate';

const SHOW_TOOLTIP_DELAY = 500;

const JxtSchedulerCalendarTooltip = ({ children, eventInfo }: IJxtSchedulerCalendarTooltip) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [debouncedShowTooltip] = useDebouncedValue(showTooltip, SHOW_TOOLTIP_DELAY);
  const arrowRef = useRef(null);
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [
      flip({
        fallbackPlacements: ['bottom-start', 'top', 'top-start', 'left'],
      }),
      offset(4),
      arrow({ element: arrowRef }),
    ],
  });

  const { event } = { ...eventInfo };
  const {
    originalBooking: booking = null,
    location,
    floor,
  }: { originalBooking?: BookingCompressed | null; location?: Location; floor?: Floor } = {
    ...event.extendedProps,
  };

  const { rruleLabel } = useGenerateRruleLabel(
    booking?.start.dateTime
      ? fromISO(booking?.start.dateTime, booking.resource.timezone)
      : now(booking?.resource.timezone),
    booking?.rrule
  );

  if (!booking) {
    return <></>;
  }

  return (
    <div
      className="z-10 w-full"
      onPointerOver={(e) => {
        // avoid trigger popover on touch devices
        if (e.pointerType === 'mouse') {
          setShowTooltip(true);
        }
      }}
      onPointerOut={() => setShowTooltip(false)}
    >
      <div ref={refs.setReference}>{children}</div>
      <FloatingPortal id="planning-scheduler">
        <Transition
          // we want the tooltip to be displayed after the delay, but to be hidden immediately after the user mouse out
          show={debouncedShowTooltip && showTooltip}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div ref={refs.setFloating} style={floatingStyles}>
            <div className="bg-white border border-neutral-20 p-4 w-96 rounded-lg">
              <div className="flex flex-col gap-4">
                <div className="text-neutral-100 capitalize">
                  {event.start &&
                    DateTime.fromJSDate(event.start).toLocaleString({
                      month: 'long',
                      day: 'numeric',
                      weekday: 'long',
                    })}
                </div>
                <JxtEventSummary title={booking.summary} color={JxtEventColorsEnum.parse(booking.color)} />
                {booking.approved && <div className="text-neutral-100 text-xs">{t('this-booking-is-validated')}</div>}
                <JxtBookingLocation location={booking.resource.name} />
                {location?.name && (
                  <div className="flex items-center gap-2">
                    <div className="h-6 w-5 flex items-center justify-center">
                      <i className="fas fa-building fa-fw fa-lg text-neutral-60" />
                    </div>
                    <span className="text-body-m text-neutral-140 truncate">
                      {location.name}
                      {(!!floor || floor === 0) &&
                        ' - ' + t('resource-short-card-floor-number', { floorNumber: floor.number })}
                    </span>
                  </div>
                )}
                <JxtEventDate
                  range={{
                    from: fromISO(booking.start.dateTime),
                    to: fromISO(booking.end.dateTime),
                  }}
                />
                {rruleLabel && <JxtBookingRecurrence recurrence={rruleLabel} />}
                <JxtBookingOrganizer name={`${booking.organizer.firstname} ${booking.organizer.lastname}`} />
                {event.extendedProps.nbAttendees > 0 && (
                  <JxtBookingAttendeesList physicalAttendees={event.extendedProps.nbAttendees} />
                )}
              </div>
            </div>
          </div>
        </Transition>
      </FloatingPortal>
    </div>
  );
};

export default JxtSchedulerCalendarTooltip;
