import { usePaintCalendarBuilder } from './hooks';
import { IJxtPaintCalendar } from './types';
import './styles.scss';
import { useRef } from 'react';
import useIsOverflow from '../../hooks/useIsOverflow';
import clsx from 'clsx';

const JxtPaintCalendar = ({ date, events = [] }: IJxtPaintCalendar) => {
  const { headers, dates, periodSelectors } = usePaintCalendarBuilder(events, date);
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflowing = useIsOverflow(ref);
  const baseClasses = 'grid calendar-grid month gap-2 grid-cols-[auto_repeat(5,_1fr)] text-center';

  return (
    <div ref={ref} className={clsx(baseClasses, isOverflowing ? '-mr-3' : '')}>
      {headers}
      {periodSelectors}
      {dates}
    </div>
  );
};

export default JxtPaintCalendar;
