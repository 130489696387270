import JxtTimeSelect from '../JxtTimeSelect';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { startBeforeEndSuperRefinerFactory } from './helper';
import { useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { Info } from 'luxon';
import { capitalize, isEqual } from 'lodash-es';
import JxtButton, { ButtonSizeEnum, ButtonVariantEnum } from '../JxtButton';
import { IJxtTypicalWeekDay } from './types';

const JxtTypicalWeekDay = ({
  dayName,
  displayDayName = true,
  dayRange,
  displayApplyAll = false,
  onChange,
  onClickApplyAll,
}: IJxtTypicalWeekDay) => {
  const { t, i18n } = useTranslation();

  const {
    control: innerControl,
    formState: { errors, isValid },
    trigger,
    reset,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: dayRange,
    resolver: zodResolver(
      z
        .object({
          morning: z.object({
            start: z.string(),
            end: z.string(),
          }),
          afternoon: z.object({
            start: z.string(),
            end: z.string(),
          }),
        })
        .superRefine(startBeforeEndSuperRefinerFactory())
    ),
  });

  const watchMorning = watch('morning');
  const watchAfternoon = watch('afternoon');

  useEffect(() => {
    reset(dayRange);
  }, [dayRange]);

  useEffect(() => {
    trigger('morning');
    trigger('afternoon');

    if (isValid && (!isEqual(dayRange.morning, watchMorning) || !isEqual(dayRange.afternoon, watchAfternoon))) {
      onChange(
        {
          morning: watchMorning,
          afternoon: watchAfternoon,
        },
        dayName
      );
    }
  }, [watchMorning.start, watchMorning.end, watchAfternoon.start, watchAfternoon.end]);

  const getDayNameToString = (name: string) => {
    const daysInEnglish = Info.weekdays('long', { locale: 'en' });
    const daysInLocal = Info.weekdays('long', { locale: i18n.language });

    const translationMap: Record<string, string> = daysInEnglish.reduce<Record<string, string>>((map, day, index) => {
      map[capitalize(day)] = capitalize(daysInLocal[index]);
      return map;
    }, {});
    return translationMap[capitalize(name)] ?? 'Day not found';
  };

  const applyAll = () => {
    if (onClickApplyAll) {
      onClickApplyAll();
    }
    onChange(
      {
        morning: watchMorning,
        afternoon: watchAfternoon,
      },
      dayName,
      true
    );
  };

  return (
    <div className="flex flex-row items-start">
      <div className="flex flex-row flex-wrap gap-3 md:gap-6">
        {displayDayName && (
          <div className="text-neutral-140 text-body-m font-medium w-28 mt-1.5">{getDayNameToString(dayName)}</div>
        )}
        <div className="flex flex-col gap-3">
          <div className="flex flew-row flex-wrap items-center gap-3 md:gap-6">
            <div className="text-neutral-100 text-body-s font-norm w-28">{t('morning')}</div>
            <div className="flex flew-row items-center gap-4">
              <Controller
                control={innerControl}
                name="morning.start"
                render={({ field: { onChange, value } }) => (
                  <div className="w-20">
                    <JxtTimeSelect invalid={!!errors.morning?.start} selectedHour={value} valueChange={onChange} />
                  </div>
                )}
              />
              <i className="fas fa-arrow-right text-neutral-120" />
              <Controller
                control={innerControl}
                name="morning.end"
                render={({ field: { onChange, value } }) => (
                  <div className="w-20">
                    <JxtTimeSelect invalid={!!errors.morning?.end} selectedHour={value} valueChange={onChange} />
                  </div>
                )}
              />
            </div>
          </div>
          <ErrorMessage
            errors={errors}
            name="morning.start"
            render={({ message }) => {
              return <div className="text-red-110 text-body-s">{t(message)}</div>;
            }}
          />
          <div className="flex flex-row flex-wrap items-center gap-3 md:gap-6">
            <div className="text-neutral-100 text-body-s font-normal w-28">{t('afternoon')}</div>
            <div className="flex flew-row items-center gap-4">
              <Controller
                control={innerControl}
                name="afternoon.start"
                render={({ field: { onChange, value } }) => (
                  <div className="w-20">
                    <JxtTimeSelect invalid={!!errors.afternoon?.start} selectedHour={value} valueChange={onChange} />
                  </div>
                )}
              />
              <i className="fas fa-arrow-right text-neutral-120" />
              <Controller
                control={innerControl}
                name="afternoon.end"
                render={({ field: { onChange, value } }) => (
                  <div className="w-20">
                    <JxtTimeSelect invalid={!!errors.afternoon?.end} selectedHour={value} valueChange={onChange} />
                  </div>
                )}
              />
            </div>
          </div>
          <ErrorMessage
            errors={errors}
            name="afternoon.start"
            render={({ message }) => {
              return <div className="text-red-110 text-body-s">{t(message)}</div>;
            }}
          />
          {displayApplyAll && (
            <div>
              <JxtButton
                variant={ButtonVariantEnum.Link}
                size={ButtonSizeEnum.Large}
                disabled={!isValid}
                onClick={applyAll}
              >
                {t('copy-day-to-week')}
              </JxtButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JxtTypicalWeekDay;
