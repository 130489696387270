import {
  JxtEventFormRange,
  JxtInput,
  JxtInputNumber,
  JxtSmartSlotSelectorContainer,
  JxtTag,
  JxtTagSizeEnum,
  JxtRecurrenceModal,
  JxtBookingOptionsList,
} from '@jooxter/ui';
import {
  UseFormRegister,
  FieldValues,
  FormState,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormSetValue,
  Control,
  Controller,
  useWatch,
} from 'react-hook-form';
import { z, ZodTypeAny } from 'zod';
import {
  JxtAttendeeCombobox,
  JxtAttendeeOption,
  JxtAttendeeOptionTypeEnum,
  JxtColorSelect,
  JxtRecurrenceSelect,
  JxtResourceSelect,
  JxtUserSelect,
  JxtVisibilitySelect,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import {
  BookingRecurrence,
  IBookingForm,
  JxtEventColorInfos,
  JxtEventColorsEnum,
  JxtBookingVisibilityEnum,
  JxtTagColorsEnum,
  fromISO,
  now,
  IQuickTimeSlot,
  toISO,
  getMinBetweenNextHourAndEndOfDay,
  isPastDateMinusFiveMinutes,
  createGTMGAEvent,
  getStandardTime,
  useBookingRecurrenceToRruleOptionsAdapter,
  useGenerateRruleLabel,
  useGenerateRrule,
} from '@jooxter/utils';
import {
  Booking,
  ExternalAttendee,
  ExternalAttendeesService,
  ResourceStateDto,
  ResourceTypeDtoMetaTypeEnum,
  SlotsTypeEnum,
} from '@jooxter/api';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { Settings } from 'luxon';
import { TResourceConfiguration } from '../types/booking-form.types';
import { useZoneSpaceAvailability } from './useZoneSpaceAvailability';
import { useZoneSpaceAvailabilityColor } from './useZoneSpaceAvailabilityColor';
import { useFetchUser, useFetchUserById } from '../../../queries';
import { useResourceDurationSlotGenerator, useResourceSlotGenerator } from '../../../hooks';
import { useDebouncedValue } from '@mantine/hooks';
import { AutoSelectFirstSlotContext } from '../../../hooks/bookings/AutoSelectFirstSlotContext';

export const useGenerateBookingForm = (
  {
    register,
    formState,
    getFieldState,
    getValues,
    setValue,
    control,
  }: {
    register: UseFormRegister<FieldValues>;
    formState: FormState<FieldValues>;
    getFieldState: UseFormGetFieldState<FieldValues>;
    getValues: UseFormGetValues<IBookingForm>;
    setValue: UseFormSetValue<IBookingForm>;
    control: Control<IBookingForm>;
  },
  {
    minPhysicalAttendees,
    maxPhysicalAttendees,
    schema,
    resourceConfiguration,
    bookingToEdit,
  }: {
    minPhysicalAttendees: number;
    maxPhysicalAttendees: number;
    schema?: z.ZodObject<
      {
        [x: string]: ZodTypeAny;
      },
      'strip',
      ZodTypeAny,
      {
        [x: string]: unknown;
      },
      {
        [x: string]: unknown;
      }
    >;
    resourceConfiguration?: TResourceConfiguration;
    bookingToEdit?: Booking | null;
  },
  attendees: JxtAttendeeOption[],
  setAttendees: Dispatch<SetStateAction<JxtAttendeeOption[]>>,
  resourceState?: ResourceStateDto
) => {
  const { t } = useTranslation();
  const autoSelectFirstSlot = useContext<boolean>(AutoSelectFirstSlotContext);
  const { user } = useFetchUser();
  const [resourceTimezone, setResourceTimezone] = useState<string>(Settings.defaultZone.name);
  const [showRecurrenceModal, setShowRecurrenceModal] = useState<boolean>(false);
  const [selectRecurrenceValue, setSelectRecurrenceValue] = useState<BookingRecurrence>(BookingRecurrence.Once);
  const [previousRecurrenceSelectValue, setPreviousRecurrenceSelectValue] = useState<BookingRecurrence>();
  const watchResource = useWatch({ control, name: 'resource' });
  const watchStart = useWatch({ control, name: 'start' });
  const watchPhysicalAttendees = useWatch({ control, name: 'physicalAttendees' });
  const watchRrule = useWatch({ control, name: 'rrule' });
  const { rruleLabel } = useGenerateRruleLabel(watchStart.date, watchRrule);
  const watchEnd = useWatch({ control, name: 'end' });
  const watchOrganizerId = useWatch({ control, name: 'organizerId' });
  const { user: organizer } = useFetchUserById(watchOrganizerId);

  const { adapt, revert } = useBookingRecurrenceToRruleOptionsAdapter(watchStart.date);

  const range = useMemo(() => {
    if (watchStart && watchEnd && resourceTimezone) {
      if (bookingToEdit) {
        return {
          from: watchStart.date,
          to: watchEnd.date,
        };
      }
      let fromISODateTime = watchStart.date;
      let toISODateTime = watchEnd.date;

      if (isPastDateMinusFiveMinutes(fromISODateTime, resourceTimezone)) {
        fromISODateTime = now(resourceTimezone);
        toISODateTime = getMinBetweenNextHourAndEndOfDay(now(resourceTimezone));
      }

      return {
        from: fromISODateTime,
        to: toISODateTime,
      };
    }
  }, [watchStart, watchEnd, resourceTimezone]);

  const { generate: generateRrule } = useGenerateRrule(watchStart.date, resourceTimezone);
  const { remainingSeats } = useZoneSpaceAvailability(
    resourceConfiguration,
    watchPhysicalAttendees,
    resourceState,
    bookingToEdit
  );
  const { zoneSpaceAvailabilityColor } = useZoneSpaceAvailabilityColor(remainingSeats, resourceConfiguration);
  const initialRange = useMemo(() => {
    const defaultRange = {
      from: now(resourceTimezone),
      to: getMinBetweenNextHourAndEndOfDay(now(resourceTimezone)),
    };
    const startAndEndAreDefaults =
      schema?.shape.start instanceof z.ZodDefault && schema?.shape.end instanceof z.ZodDefault;

    if (!startAndEndAreDefaults) {
      return defaultRange;
    }

    return {
      from: fromISO(schema.shape.start._def.defaultValue(), resourceTimezone),
      to: fromISO(schema.shape.end._def.defaultValue(), resourceTimezone),
    };
  }, [schema?.shape.start, schema?.shape.end, resourceTimezone]);

  const { slots: classicSlots } = useResourceSlotGenerator({
    start: toISO(watchStart.date),
    end: toISO(watchEnd.date),
    options: {
      resourceId: watchResource?.id,
      timezone: watchResource?.timezone,
      seatRequested: watchPhysicalAttendees,
      bookingMask: bookingToEdit?.id,
    },
  });
  const { durationSlots } = useResourceDurationSlotGenerator({
    date: toISO(watchStart.date),
    options: {
      resourceId: watchResource?.id,
      slots: resourceConfiguration?.slots,
      timezone: watchResource?.timezone,
      seatRequested: watchPhysicalAttendees,
      bookingMask: bookingToEdit?.id,
    },
  });
  const memoSlots = useMemo(
    () => (resourceConfiguration?.slots?.type === SlotsTypeEnum.Duration ? durationSlots : classicSlots),
    [resourceConfiguration, JSON.stringify(durationSlots), JSON.stringify(classicSlots)]
  );
  const [slots] = useDebouncedValue(memoSlots, 300);

  useEffect(() => {
    if (watchResource?.timezone) {
      setResourceTimezone(watchResource.timezone);
    }
  }, [watchResource]);

  useEffect(() => {
    const defaultRrule = schema?.shape.rrule
      ? schema?.shape.rrule instanceof z.ZodDefault
        ? schema?.shape.rrule._def.defaultValue()
        : null
      : null;

    const defaultRecurrenceSelectValue = revert(defaultRrule);

    if (defaultRecurrenceSelectValue) {
      setSelectRecurrenceValue(defaultRecurrenceSelectValue);
    }
  }, [schema?.shape.rrule, revert]);

  const setStartEndFromSlot = (slot: IQuickTimeSlot) => {
    setValue('start', { date: slot.start, time: getStandardTime(slot.start) });
    setValue(
      'end',
      { date: slot.end, time: getStandardTime(slot.end) },
      {
        shouldTouch: true,
        shouldDirty: true,
      }
    );
  };

  const onRecurrenceSelectChange = (recurrence: BookingRecurrence) => {
    if (recurrence === BookingRecurrence.Customize) {
      setShowRecurrenceModal(true);
    } else {
      setValue('rrule', generateRrule(adapt(recurrence))?.toString().split('RRULE:')[1]);
    }

    setPreviousRecurrenceSelectValue(selectRecurrenceValue);
    setSelectRecurrenceValue(recurrence);
  };

  const onCloseRecurrenceModal = (value?: string) => {
    if (value) {
      setValue('rrule', value);
    } else if (previousRecurrenceSelectValue) {
      setSelectRecurrenceValue(previousRecurrenceSelectValue);
      setPreviousRecurrenceSelectValue(undefined);
    }

    setShowRecurrenceModal(false);
  };

  if (!schema?.shape || !user?.id) {
    return null;
  }

  const onAttendeeSelect = (option: JxtAttendeeOption) => {
    if (!attendees.some((a) => a.id === option.id && a.type === option.type)) {
      if (option.type === JxtAttendeeOptionTypeEnum.NEW_EXTERNAL) {
        ExternalAttendeesService.postExternalAttendee({
          email: option.text,
          //Name is required for backwards compatibility with the Angular/mobile application.
          //We may delete this field when the name is no longer required.
          name: option.text,
          disabled: false,
        }).then((attendee: ExternalAttendee) => {
          setAttendees([
            ...attendees,
            { type: JxtAttendeeOptionTypeEnum.EXTERNAL, id: attendee.id, text: attendee.email },
          ]);
          setValue('externalAttendees', [...(getValues('externalAttendees') as number[]), attendee.id as number], {
            shouldValidate: true,
          });
          createGTMGAEvent('Booking', 'Create Booking', 'Add External Attendees');
        });
      } else if (option.type === JxtAttendeeOptionTypeEnum.EXTERNAL) {
        setAttendees([...attendees, option]);
        setValue('externalAttendees', [...(getValues('externalAttendees') as number[]), option.id as number], {
          shouldValidate: true,
        });
        createGTMGAEvent('Booking', 'Create Booking', 'Add External Attendees');
      } else {
        setAttendees([...attendees, option]);
        setValue('internalAttendees', [...(getValues('internalAttendees') as number[]), option.id as number], {
          shouldValidate: true,
        });
        createGTMGAEvent('Booking', 'Create Booking', 'Add Internal Attendees');
      }
    }
  };

  const onAttendeeDelete = (option: JxtAttendeeOption) => {
    setAttendees(attendees.filter((a) => a !== option));
    if (option.type === JxtAttendeeOptionTypeEnum.EXTERNAL) {
      setValue(
        'externalAttendees',
        getValues('externalAttendees')?.filter((attendeeId: number) => attendeeId !== option.id),
        { shouldValidate: true }
      );
    } else {
      setValue(
        'internalAttendees',
        getValues('internalAttendees')?.filter((attendeeId: number) => attendeeId !== option.id),
        { shouldValidate: true }
      );
    }
  };

  return (
    <>
      {/* Mandatory inputs, always in shape */}
      <div className="grid grid-cols-[min-content_auto] items-center gap-x-3">
        <i className="fas fa-fw fa-lg fa-quote-left text-neutral-60 col-start-1 row-start-1" />
        <div className="col-start-2 row-start-1">
          <JxtInput
            name="summary"
            placeholder={
              resourceConfiguration?.metaType === ResourceTypeDtoMetaTypeEnum.Vehicle
                ? t<string>('booking-form.summary.vehicle.placeholder')
                : t<string>('booking-form.summary-input-placeholder')
            }
            register={register}
            formState={formState}
            getFieldState={getFieldState}
            showErrorMessages={false}
          />
        </div>
        {getFieldState('summary').error?.message && typeof getFieldState('summary').error?.message === 'string' && (
          <p className="text-red-100 pt-1 col-start-2 row-start-2">
            {getFieldState('summary').error?.message as string}
          </p>
        )}
      </div>
      <JxtEventFormRange
        sameDay={resourceConfiguration?.metaType !== ResourceTypeDtoMetaTypeEnum.Vehicle}
        timezone={resourceTimezone}
        isEditing={!!bookingToEdit}
      />
      <div className="flex items-center gap-3">
        <div className="size-[25px]" />
        <JxtSmartSlotSelectorContainer
          range={range || initialRange}
          slots={slots}
          onSlotSelection={setStartEndFromSlot}
          autoSelectFirstSlot={autoSelectFirstSlot}
        />
      </div>

      {'rrule' in schema.shape && (
        <div className="flex items-center gap-3">
          <i className="fas fa-fw fa-lg fa-arrows-rotate text-neutral-60" />
          <JxtRecurrenceSelect
            className="grow"
            date={watchStart.date}
            value={selectRecurrenceValue}
            onRecurrenceSelect={onRecurrenceSelectChange}
            customizeLabel={selectRecurrenceValue === BookingRecurrence.Customize ? rruleLabel : undefined}
          />
        </div>
      )}
      <Controller
        name="resource"
        control={control}
        render={({ field: { onChange, value } }) => (
          <JxtResourceSelect
            className="hidden"
            onResourceSelect={(val) => {
              onChange(val);
            }}
            value={
              value
                ? {
                    value: value.id,
                    label: value.name,
                  }
                : undefined
            }
            searchOptions={{ bookable: true }}
          />
        )}
      />

      {'physicalAttendees' in schema.shape && (
        <div className="grid grid-cols-[min-content_auto] items-center gap-x-3">
          <i className="fas fa-fw fa-lg fa-users text-neutral-60 col-span-1 col-start-1 row-start-1" />
          <div className="flex items-center gap-3 col-start-2 row-start-1">
            {resourceConfiguration?.isZone ? (
              <>
                <span className="text-body-s text-neutral-120">
                  {t('booking-form.physical-attendees.how-many-seats')}
                </span>
                <div className="flex-1">
                  <JxtInputNumber
                    name="physicalAttendees"
                    min={minPhysicalAttendees}
                    max={maxPhysicalAttendees}
                    showErrorMessages={false}
                    register={register}
                    formState={formState}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </div>
              </>
            ) : (
              <>
                <span className="text-body-s text-neutral-120 capitalize">{t('for')}</span>
                <div className="w-[119px]">
                  <JxtInputNumber
                    name="physicalAttendees"
                    min={minPhysicalAttendees}
                    max={maxPhysicalAttendees}
                    showErrorMessages={false}
                    register={register}
                    formState={formState}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </div>
                <span className="text-body-s text-neutral-120">
                  {t('booking-form-physical-attendees-label', {
                    physicalAttendeesValue: watchPhysicalAttendees || 0,
                  })}
                </span>
              </>
            )}
          </div>
          {getFieldState('physicalAttendees').error?.message &&
          typeof getFieldState('physicalAttendees').error?.message === 'string' ? (
            <p className="text-red-100 pt-1 col-start-2 row-start-2">
              {getFieldState('physicalAttendees').error?.message as string}
            </p>
          ) : (
            resourceConfiguration?.isZone && (
              <div className="pt-2 col-start-2 row-start-2">
                <JxtTag
                  text={t<string>('zone-availability-booking-slider', { remainingSeats })}
                  color={zoneSpaceAvailabilityColor}
                  size={JxtTagSizeEnum.SMALL}
                />
              </div>
            )
          )}
        </div>
      )}

      <div className="flex items-center gap-3">
        <i className="fas fa-fw fa-lg fa-user text-neutral-60" />
        <div className="grow">
          <Controller
            name="organizerId"
            control={control}
            render={({ field: { onChange } }) => (
              <JxtUserSelect
                placeholder={t<string>('select-owner-placeholder')}
                value={organizer}
                onUserSelect={(value) => {
                  onChange(value.id);
                  createGTMGAEvent('Booking', 'Create Booking', 'Change Organizer');
                }}
                maxMenuHeight={200}
              />
            )}
          />
        </div>
      </div>
      {'internalAttendees' in schema.shape && 'externalAttendees' in schema.shape && (
        <div className="grid grid-cols-[min-content_auto] items-center gap-x-3">
          <i className="fas fa-fw fa-lg fa-user-plus text-neutral-60 col-span-1 col-start-1 row-start-1" />
          <JxtAttendeeCombobox
            placeholder={t<string>('booking.attendees.search.placeholder')}
            onAttendeeSelect={onAttendeeSelect}
            hasErrors={getFieldState('internalAttendees').invalid}
          />
          {getFieldState('internalAttendees').error?.message &&
            typeof getFieldState('internalAttendees').error?.message === 'string' && (
              <p className="text-red-100 pt-1 col-start-2 row-start-3">
                {getFieldState('internalAttendees').error?.message as string}
              </p>
            )}
          {attendees.length > 0 && (
            <div className="flex flex-wrap gap-2 col-start-2 row-start-4 pt-3">
              {attendees.map((a) => (
                <JxtTag
                  key={a.type + a.id}
                  text={a.text}
                  color={JxtTagColorsEnum.BLUE}
                  size={JxtTagSizeEnum.LARGE}
                  closeable
                  onClose={() => onAttendeeDelete(a)}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {'options' in schema.shape && resourceConfiguration?.options && resourceConfiguration?.options?.length > 0 && (
        <div className="grid grid-cols-[min-content_auto] items-start gap-3">
          <div className="h-5 flex items-center justify-center">
            <i className="fas fa-cogs fa-lg fa-align-left text-neutral-60" />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <div className="text-body-s text-neutral-120 font-medium">{t('booking-form-options')}</div>
              {resourceConfiguration.options.some((option) => option.mandatory) && (
                <div className="text-body-xs text-neutral-80">{t('booking-form-options-required-description')}</div>
              )}
            </div>
            <JxtBookingOptionsList
              options={resourceConfiguration.options}
              register={register as unknown as UseFormRegister<IBookingForm>}
              getFieldState={getFieldState}
              formState={formState}
              control={control}
            />
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 items-center gap-4">
        <div className="flex items-center gap-3">
          <i className="fas fa-fw fa-lg fa-tint text-neutral-60" />
          <div className="grow">
            <Controller
              name="color"
              control={control}
              render={({ field: { onChange, value } }) => (
                <JxtColorSelect
                  onColorSelect={(value) => {
                    onChange(value);
                    createGTMGAEvent('Booking', 'Create Booking', 'Change Color');
                  }}
                  value={
                    value
                      ? {
                          value: value as JxtEventColorsEnum,
                          label: t<string>(JxtEventColorInfos[value as JxtEventColorsEnum]?.translationKey),
                        }
                      : undefined
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="flex items-center gap-3">
          <i className="fas fa-fw fa-lg fa-lock text-neutral-60" />
          <div className="grow">
            <Controller
              name="visibility"
              control={control}
              render={({ field: { onChange, value } }) => (
                <JxtVisibilitySelect
                  onVisibilitySelect={(value) => {
                    onChange(value);
                    createGTMGAEvent('Booking', 'Create Booking', 'Change Visibility');
                  }}
                  value={
                    value
                      ? {
                          value: value as unknown as JxtBookingVisibilityEnum,
                          label: t('visibility-key-select-public-public-private-private', { visibility: value }),
                        }
                      : undefined
                  }
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="flex items-start gap-3">
        <div className="h-5 my-2 flex items-center justify-center">
          <i className="fas fa-fw fa-lg fa-align-left text-neutral-60" />
        </div>
        <JxtInput
          name="description"
          placeholder={t<string>('booking-form.description-input-placeholder')}
          multiLines={true}
          register={register}
          formState={formState}
          getFieldState={getFieldState}
        />
      </div>

      <JxtRecurrenceModal
        date={watchStart?.date || now(resourceTimezone)}
        show={showRecurrenceModal}
        value={watchRrule}
        onHide={onCloseRecurrenceModal}
      />
    </>
  );
};
