export enum JxtAlertTypeEnum {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export const JxtAlertTypeInfo = {
  [JxtAlertTypeEnum.Info]: {
    iconName: 'fa-info-circle',
    colors: 'text-primary-150 bg-primary-10 border-primary-20',
    closeIconStates: 'hover:text-primary-100 active:text-primary-60',
  },
  [JxtAlertTypeEnum.Success]: {
    iconName: 'fa-check-circle',
    colors: 'text-green-150 bg-green-10 border-green-20',
    closeIconStates: 'hover:text-green-100 active:text-green-60',
  },
  [JxtAlertTypeEnum.Warning]: {
    iconName: 'fa-warning',
    colors: 'text-orange-150 bg-orange-10 border-orange-20',
    closeIconStates: 'hover:text-orange-100 active:text-orange-60',
  },
  [JxtAlertTypeEnum.Error]: {
    iconName: 'fa-circle-exclamation',
    colors: 'text-red-150 bg-red-10 border-red-20',
    closeIconStates: 'hover:text-red-100 active:text-red-60',
  },
};

export interface IJxtAlert {
  text: string;
  type: JxtAlertTypeEnum;
  closeable?: boolean;
}
