import { FloorPlanPictoTypeEnum, JxtFloorPlanPictos, useFetchFloorPlan } from '@jooxter/core';
import { JxtNoFloorPlan } from '@jooxter/ui';
import { IJxtLocateBooking } from './types';

const JxtLocateBooking = ({ booking, width, height }: IJxtLocateBooking) => {
  const { floorPlan } = useFetchFloorPlan(booking.resource.floorId);

  if (!booking) {
    return null;
  }

  return (
    // this extra-markup is required to avoid becoming epileptic (ie the panzoom constantly to move the svg into the bounds while panning with min zoom)
    (<div className="rounded-lg border border-neutral-20">
      {floorPlan?.image ? (
        <JxtFloorPlanPictos
          floorPlan={floorPlan}
          width={width}
          height={height}
          resources={[]}
          bookings={[booking]}
          type={FloorPlanPictoTypeEnum.Pin}
        />
      ) : (
        <JxtNoFloorPlan />
      )}
    </div>)
  );
};

export default JxtLocateBooking;
