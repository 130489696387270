import { IJxtBookingDetailsContainer } from './types';
import { useEffect } from 'react';
import { JxtBookingDetails } from '@jooxter/ui';
import { createGTMUpdateVirtualPathEvent } from '@jooxter/utils';

export const JxtBookingDetailsContainer = ({ bookingDetails, id }: IJxtBookingDetailsContainer) => {
  useEffect(() => {
    createGTMUpdateVirtualPathEvent('/BookingDetailsPanel');
  }, [id]);

  return <JxtBookingDetails {...bookingDetails} />;
};

export default JxtBookingDetailsContainer;
