import { IJxtExceptionalOpeningHoursFormContainer } from './types';
import { useExceptionalOpeningHoursForm } from '../../forms/ExceptionalOpeningHours/hooks/useExceptionalOpeningHoursForm';

export const JxtExceptionalOpeningHoursFormContainer = ({
  exceptionalOpeningHoursFormPreset,
  exceptionalOpeningHoursToEdit,
}: IJxtExceptionalOpeningHoursFormContainer) => {
  const form = useExceptionalOpeningHoursForm(exceptionalOpeningHoursToEdit, exceptionalOpeningHoursFormPreset);

  return <div className="h-full max-sm:h-[calc(88vh-177px)]">{form}</div>;
};

export default JxtExceptionalOpeningHoursFormContainer;
