import { GetResourcesSortParamsEnum, ResourceCompressed, UserTypeEnum } from '@jooxter/api';
import {
  ExceptionalOpeningHoursToEventInputAdapter,
  JxtResourceSelect,
  useExceptionalOpeningHoursCallbacks,
  useExceptionalOpeningHoursFormContext,
  useFetchExceptionalOpeningHoursByResourceId,
  useFetchUser,
  useOffCanvas,
  useResourceManagerOffCanvas,
} from '@jooxter/core';
import { createGTMGAEvent, createGTMUpdateVirtualPathEvent, IRange, now, SCREEN_SIZE } from '@jooxter/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ManageMyResourcesCalendar } from './Calendar';
// eslint-disable-next-line import/named
import { DateSelectArg } from '@fullcalendar/core';
import { useIsSmallScreen } from '@jooxter/ui';

export const ManageMyResources = () => {
  const { t } = useTranslation();
  const { user } = useFetchUser();
  const isSmallScreen = useIsSmallScreen(SCREEN_SIZE.MD);
  const isAdmin = user?.type === UserTypeEnum.Admin;
  const [resource, setResource] = useState<ResourceCompressed | null>(null);
  const [range, setRange] = useState<IRange>({
    from: now().startOf('month'),
    to: now().plus({ month: 1 }).startOf('month'),
  });
  const { exceptionalOpeningHours } = useFetchExceptionalOpeningHoursByResourceId(resource?.id, range);
  const exceptionalOpeningHoursFormContext = useExceptionalOpeningHoursFormContext();
  const { onShowExceptionalOpeningHoursDetailsClick } = useExceptionalOpeningHoursCallbacks();
  const [navbarOffset, setNavbarOffset] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const filterSidebarRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setNavbarOffset(node.getBoundingClientRect().top);
    }
  }, []);
  const { onSelection, onEventClick, offCanvasConfiguration } = useResourceManagerOffCanvas();
  const offCanvasContext = useOffCanvas();

  const events = useMemo(() => {
    if (exceptionalOpeningHours) {
      return exceptionalOpeningHours.map((e) => ExceptionalOpeningHoursToEventInputAdapter.adapt(e));
    }
    return [];
  }, [exceptionalOpeningHours]);

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('Manage Resources');
  }, []);

  useEffect(() => {
    if (exceptionalOpeningHoursFormContext?.showExceptionalOpeningHours?.id) {
      onShowExceptionalOpeningHoursDetailsClick(
        exceptionalOpeningHoursFormContext?.showExceptionalOpeningHours.id,
        exceptionalOpeningHoursFormContext?.showExceptionalOpeningHours.resourceId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceptionalOpeningHoursFormContext?.showExceptionalOpeningHours]);

  const onSelect = (selectionInfo: DateSelectArg, gtmTag?: [string, string, string]) => {
    if (resource?.id) {
      onSelection(selectionInfo, resource.id);
      if (gtmTag) {
        createGTMGAEvent(...gtmTag);
      } else {
        createGTMGAEvent('ManageSpaces', 'Create', 'Open Slider Exception From Selection');
      }
      offCanvasContext?.open(offCanvasConfiguration);
    }
  };

  const onEventSelect = (id: number, resourceId: number) => {
    onEventClick(id, resourceId);
    createGTMGAEvent('ManageSpaces', 'Update', 'Click On Exception');
    offCanvasContext?.open(offCanvasConfiguration);
  };

  const onResourceSelect = (value: ResourceCompressed | null) => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      createGTMGAEvent('ManageSpaces', 'Select Space', 'Choose Space');
    }
    setResource(value);
  };

  if (!user?.id) {
    return null;
  }

  return (
    <div
      ref={filterSidebarRef}
      className="flex max-lg:flex-col bg-white"
      style={{
        height: `calc(100vh - ${navbarOffset}px)`,
      }}
    >
      {isSmallScreen ? (
        <div className="bg-background px-6 py-4 h-full grow">
          <div className="flex gap-4 items-start bg-white rounded-xl flex-wrap sm:justify-between p-4">
            {t('resource-manager.not-supported')}
          </div>
        </div>
      ) : (
        <>
          <div className="lg:w-[320px] z-[4] flex flex-col max-lg:border-r-0 max-lg:border-b border-r border-neutral-20">
            <div className="max-lg:hidden h-[70px] border-b border-neutral-20" />
            <div className="flex flex-col gap-2 px-6 py-4 border-neutral-20 max-w-[320px]">
              <span className="text-body-s text-neutral-140 font-medium">{t('manage-space-label-select-space')}</span>
              <JxtResourceSelect
                autoSelectFirstOption={true}
                onResourceSelect={onResourceSelect}
                placeholder={t<string>('choose-a-space')}
                noOptionsMessage={t<string>('no-matching-spaces')}
                searchOptions={{
                  ownerId: !isAdmin ? [user.id] : undefined,
                  sort: [GetResourcesSortParamsEnum.ResourceName],
                }}
              />
            </div>
          </div>
          <div className="px-6 pt-4 grow">
            {resource?.timezone && (
              <ManageMyResourcesCalendar
                user={user}
                timezone={resource.timezone}
                events={events}
                onRangeChange={setRange}
                onSelect={onSelect}
                onEventSelect={onEventSelect}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
