import { Booking } from '@jooxter/api';
import { IJxtAlert, JxtAlertTypeEnum } from '@jooxter/ui/src/components/JxtAlert/types';
import { useMemo } from 'react';
import { useBookingPresenter } from '../../presenters/booking';

export const useGenerateBookingAlert = (booking?: Booking | null): { alerts: IJxtAlert[] } => {
  const { presenter } = useBookingPresenter(booking);
  const alerts: IJxtAlert[] = useMemo(() => {
    const results: IJxtAlert[] = [];

    if (!presenter) {
      return results;
    }

    if (presenter.displayOwnerValidationStatus()) {
      results.push({
        type: JxtAlertTypeEnum.Info,
        text: presenter.getStatusMessage(),
        closeable: false,
      });
    }

    if (presenter.displayOwnerValidationWaiting()) {
      results.push({
        type: JxtAlertTypeEnum.Warning,
        text: presenter.getWaitingApprovalMessage(),
        closeable: false,
      });
    }

    if (presenter.displayCancelled()) {
      results.push({
        type: JxtAlertTypeEnum.Error,
        text: presenter.userCancelMessage(),
        closeable: false,
      });
    }

    if (presenter.displayAutoCheckOut()) {
      results.push({
        type: JxtAlertTypeEnum.Info,
        text: presenter.cancelMessage(),
        closeable: false,
      });
    }

    if (presenter.showNotUpdatableAlert()) {
      results.push({
        type: JxtAlertTypeEnum.Info,
        text: presenter.getNotUpdatableText(),
        closeable: false,
      });
    }

    return results;
  }, [presenter]);

  return { alerts };
};
