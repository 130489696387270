import { useRef } from 'react';
import JxtButton, { ButtonSizeEnum, ButtonVariantEnum } from '../JxtButton';
import { IJxtInputFile } from './types';

const JxtInputFile = ({
  variant = ButtonVariantEnum.Primary,
  size = ButtonSizeEnum.Regular,
  disabled = false,
  leftIcon = '',
  children,
  onClick,
  className = '',
  autoFocus = false,
  accept,
  handleFileChange,
}: IJxtInputFile) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <JxtButton
        variant={variant}
        size={size}
        disabled={disabled}
        leftIcon={leftIcon}
        onClick={(event) => {
          handleButtonClick();
          if (onClick) {
            onClick(event);
          }
        }}
        type="button"
        className={className}
        autoFocus={autoFocus}
      >
        {children}
      </JxtButton>
      <input type="file" ref={fileInputRef} accept={accept} onChange={handleFileChange} className="hidden" />
    </>
  );
};

export default JxtInputFile;
