import { useTranslation } from 'react-i18next';

export const AddInSSOError = () => {
  const { t } = useTranslation();

  return (
    <p>
      <strong>
        {t('saml-auth-fail-1')}
        <br />
        {t('saml-auth-fail-2')}
      </strong>
      <br />
      <br />
      {t('problem-persist-contact-us')}
    </p>
  );
};
