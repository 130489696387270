// eslint-disable-next-line import/named
import { OptionProps, SingleValue, components } from 'react-select';
import { useEffect, useMemo, useState } from 'react';
import { LocationCompressed, LocationCompressedTypeEnum, LocationService } from '@jooxter/api';
import { JxtAsyncSelect, SelectOption } from '@jooxter/ui';
import { IJxtLocationSelect } from './types';
import { SearchLocationOptionsToParamsAdapter } from '../../adapters';
import { TextHighlight } from '@jooxter/utils';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { uniqBy } from 'lodash-es';

const getOptionClasses = (type: LocationCompressedTypeEnum) => {
  const classes = 'truncate';
  if (type === LocationCompressedTypeEnum.Site) {
    return clsx(classes, 'text-body-m font-semibold');
  }
  return classes;
};

const Option = (
  props: OptionProps<{ value: number; label: string; type: LocationCompressedTypeEnum; site?: LocationCompressed }>
) => {
  const userInput = props?.selectProps?.inputValue || '';
  const label = props?.data?.label || '';
  const type = props?.data?.type;
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-2" title={label}>
        {type === LocationCompressedTypeEnum.Site && <i className="fas fa-map-pin text-neutral-60 shrink-0" />}
        {props.data.site && <div className="size-2.5 shrink-0" />}
        {userInput?.length ? (
          label?.split(' ').length ? (
            <span className={getOptionClasses(type)}>
              <TextHighlight text={label} searchTerm={userInput} />
            </span>
          ) : (
            <span className={getOptionClasses(type)}>{label}</span>
          )
        ) : (
          <span className={getOptionClasses(type)}>{label}</span>
        )}
      </div>
    </components.Option>
  );
};

export const JxtLocationSelect = ({
  onLocationSelect,
  selectedOptions,
  defaultValue,
  label,
  helperText,
  isMulti = false,
  disabled,
}: IJxtLocationSelect) => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState<Array<LocationCompressed>>([]);
  const [initOptions, setInitOptions] = useState<{ value: number; label: string }[]>();

  const findLocation = (locationId: number) => locations?.find((location) => location.id === locationId);

  const handleLocationSelection = (option: SingleValue<SelectOption<number>>) => {
    let result: LocationCompressed[] | null = null;
    if (option) {
      if (Array.isArray(option)) {
        result = option
          .map((opt) => findLocation(opt.value))
          .filter((loc) => loc !== undefined) as LocationCompressed[];
      } else {
        const loc = findLocation(option.value);
        if (loc) {
          result = [loc];
        }
      }
      if (result && onLocationSelect) {
        onLocationSelect(result);
      }
    }
  };

  useEffect(() => {
    if (!defaultValue) {
      const options = locations
        .filter((location) => location.id && selectedOptions?.includes(location.id))
        .map((loc: LocationCompressed) => {
          return { value: loc.id!, label: loc.name };
        });
      setInitOptions(options);
    } else {
      setInitOptions(defaultValue);
    }
  }, [locations, selectedOptions, defaultValue]);

  async function loadOptions(search: string, loadedOptions: unknown, additional: { page?: string } | undefined) {
    const params = SearchLocationOptionsToParamsAdapter.adapt({ q: search });

    if (additional?.page) {
      params.append('page', additional.page);
    }

    const response = await LocationService.searchLocationsWithPagination(params).then(async (res) => {
      const filteredNewLocations = uniqBy([...locations, ...res.data], 'id');
      setLocations(filteredNewLocations);

      return {
        data: res.data,
        nextPage: res.nextPage,
      };
    });

    return {
      options: response.data
        .map((l) => {
          return {
            value: l.id!,
            label: l.name,
            type: l.type,
            site: l.site,
          };
        })
        .filter((l) => {
          if (!l.site || (l.site.id && !selectedOptions?.includes(l.site.id))) {
            return l;
          }
        }),
      hasMore: !!response.nextPage,
      additional: {
        page: response.nextPage,
      },
    };
  }

  const getUniqueKey = useMemo(() => {
    return selectedOptions?.length ? `locations-${selectedOptions.join('-')}` : 'locations';
  }, [selectedOptions]);

  return (
    <JxtAsyncSelect
      placeholder={t<string>('select-building-placeholder')}
      defaultValue={defaultValue}
      value={initOptions}
      isMulti={isMulti}
      label={label}
      noOptionsMessage={t<string>('no-matching-buildings')}
      helperText={helperText}
      loadOptions={loadOptions}
      handleSelection={handleLocationSelection}
      components={{ Option } as any}
      cacheUniqs={[getUniqueKey]}
      isDisabled={disabled}
    />
  );
};
