import { ITextHighlight } from './types';

const TextHighlight = ({ text, searchTerm }: ITextHighlight) => {
  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);

  const highlightedText = parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="font-bold">
        {part}
      </span>
    ) : (
      part
    )
  );

  return <>{highlightedText}</>;
};

export default TextHighlight;
