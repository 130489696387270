import { JxtLoader, JxtResourceDetails } from '@jooxter/ui';
import { createGTMUpdateVirtualPathEvent, now } from '@jooxter/utils';
import { useFetchResourceDetails } from '../../hooks';
import DisplayResourceOpeningHours from '../DisplayResourceOpeningHours';
import { IResourceInformation } from './types';
import { useEffect } from 'react';

const ResourceInformation = ({ resourceId }: IResourceInformation) => {
  const resourceDetails = useFetchResourceDetails(resourceId);

  useEffect(() => {
    createGTMUpdateVirtualPathEvent('/ResourceViewPanel');
  }, []);

  if (!resourceDetails) {
    return null;
  }

  if (resourceDetails.isLoading) {
    return (
      <div className="h-full flex flex-1 items-center justify-center">
        <JxtLoader />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <JxtResourceDetails {...resourceDetails} />
      <DisplayResourceOpeningHours
        range={{ from: now().startOf('week'), to: now().startOf('week').plus({ week: 1 }) }}
        resourceId={resourceDetails.resource.id}
        timezone={resourceDetails.resource.location.timezone}
      />
    </div>
  );
};

export default ResourceInformation;
