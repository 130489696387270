import { ILocationOptions } from '@jooxter/api';
import { JooxterState } from '../../store';

export const FiltersToLocationMapAdapter = {
  adapt(filters?: JooxterState['filter']['resource']): ILocationOptions {
    if (!filters) {
      return {};
    }

    const options: ILocationOptions = {
      from: filters.date.from,
      to: filters.date.to,
      capacity: filters.capacity,
      labels: filters.labels,
      resourceTypeId: filters.resourceTypesIds.map((rt) => rt),
      visible: true,
      name: filters.resourceName,
      locationId: filters.location.locationIds,
    };

    return options;
  },

  revert() {
    throw new Error('Method not implemented');
  },
};
