import { ForwardedRef, forwardRef } from 'react';
import { IJxtDropdownItem } from './types';

const JxtDropdownItem = (
  { children, onClick }: IJxtDropdownItem,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref: ForwardedRef<HTMLElement>
) => {
  return (
    <button
      className="h-10 w-full px-4 py-2 text-body-m text-neutral-140 hover:bg-neutral-10 text-left cursor-pointer"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default forwardRef(JxtDropdownItem);
