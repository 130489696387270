import { UserRequestExtendedLang } from '@jooxter/api';
import { getLanguageList } from '@jooxter/i18n';
import { JxtSelect } from '@jooxter/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//eslint-disable-next-line import/named
import { SingleValue } from 'react-select';
import { useBrowserLanguage } from '../../hooks';
import { IJxtLanguageSelect } from './types';

export const JxtLanguageSelect = ({ defaultValue, onLanguageChange, label, helperText }: IJxtLanguageSelect) => {
  const { t } = useTranslation();
  const browserLanguage = useBrowserLanguage();
  const options: {
    value: UserRequestExtendedLang;
    label: string;
  }[] = getLanguageList().map((language) => ({
    value: language.lang,
    label: `${language.icon} ${t(language.label)}`,
  }));

  const findDefaultOption = (option: UserRequestExtendedLang) => {
    return options.find((o) => o.value === option) ?? options[0];
  };

  const defaultOption = useMemo(
    () => findDefaultOption(defaultValue ?? (browserLanguage as UserRequestExtendedLang)),
    [defaultValue]
  );

  const handleLanguageSelect = (e: SingleValue<{ value: UserRequestExtendedLang; label: string }>) => {
    if (e?.value) {
      onLanguageChange(e.value);
    }
  };

  return (
    <JxtSelect
      defaultValue={defaultOption}
      label={label}
      helperText={helperText}
      isSearchable={false}
      options={options}
      handleSelection={handleLanguageSelect}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
      }}
    />
  );
};
