import './styles.scss';
import { IJxtMenuToggle } from './types';

const JxtMenuToggle = ({ expanded }: IJxtMenuToggle) => {
  return (
    <div className="d-flex align-items-center">
      <div id="burger" className={expanded ? 'burger active' : 'burger'}>
        <span />
      </div>
    </div>
  );
};

export default JxtMenuToggle;
