import { useParams } from 'react-router';
import { JxtLoader } from '@jooxter/ui';
import { useResetPassword } from './hooks';

export const ResetPassword = () => {
  const { userId, token, code } = useParams();
  const { page, isReady } = useResetPassword({ code, userId, token });

  return isReady ? (
    page
  ) : (
    <div className="flex flex-1 items-center justify-center">
      <JxtLoader />
    </div>
  );
};
