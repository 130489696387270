import { WorkplaceRulesService } from '@jooxter/api';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { WorkplaceRulesQueryKeys } from '../queryKeys';
import { WorkplaceRulesStaleTimeEnum } from '../staleTimes';

export const useFetchUserPresenceStrategy = (id?: number) => {
  const fetchUserPresenceStrategy = useCallback(() => {
    if (id) {
      return WorkplaceRulesService.getWorkplaceRules(id);
    }

    return Promise.resolve(null);
  }, [id]);

  const { data: presenceStrategy } = useQuery({
    queryKey: [WorkplaceRulesQueryKeys.GetWorkplaceRules, id],
    queryFn: () => fetchUserPresenceStrategy(),
    staleTime: WorkplaceRulesStaleTimeEnum.GetWorkplaceRules,
  });

  return { presenceStrategy };
};
