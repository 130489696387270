import { Booking } from '@jooxter/api';
import { ButtonVariantEnum, IJxtButton } from '@jooxter/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBookingActions } from '../../hooks';
import { useBookingPresenter } from '../../presenters/booking';
import { createGTMGAEvent } from '@jooxter/utils';

export const useGenerateBookingButtons = (booking?: Booking | null): { buttons: IJxtButton[] } => {
  const { presenter } = useBookingPresenter(booking);
  const { checkin, checkout, arrive, leave } = useBookingActions();
  const { t } = useTranslation();
  const buttons = useMemo(() => {
    const results: IJxtButton[] = [];

    if (!presenter) {
      return results;
    }

    if (presenter.shouldDisplayCheckinButton()) {
      results.push({
        children: t('checkin-button'),
        variant: ButtonVariantEnum.Primary,
        onClick: () => {
          booking?.id && checkin(booking.id);
          createGTMGAEvent('Booking', 'Checkin', 'Confirm booking');
        },
      });
    }

    if (presenter.shouldDisplayCheckoutButton()) {
      results.push({
        children: t('checkout-button'),
        variant: ButtonVariantEnum.Primary,
        onClick: () => {
          booking?.id && checkout(booking.id);
          createGTMGAEvent('Booking', 'Checkout', 'Free booking');
        },
      });
    }

    if (presenter.shouldDisplayArriveButton()) {
      results.push({
        children: t('arrive-button'),
        variant: ButtonVariantEnum.Primary,
        onClick: () => booking?.id && arrive(booking.id),
      });
    }

    if (presenter.shouldDisplayLeaveButton()) {
      results.push({
        children: t('leave-button'),
        variant: ButtonVariantEnum.Primary,
        onClick: () => booking?.id && leave(booking.id),
      });
    }

    return results;
  }, [presenter]);

  return { buttons };
};
