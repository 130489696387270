import { useCreatePaintMonthView, useFullCalendarLocale } from '@jooxter/fullcalendar';
import { IJxtWorkplaceCalendar } from './types';
import { createPlugin, DatesSetArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import { fromISO, getButtonText, prependCalendarIconToDatePickerBtn } from '@jooxter/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { JxtMonthPicker } from '@jooxter/ui';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { useClickOutside } from '@mantine/hooks';
import luxonPlugin from '@fullcalendar/luxon3';

const JxtWorkplaceCalendar = ({ range, rangeChange, timezone = 'system', events = [] }: IJxtWorkplaceCalendar) => {
  const { config } = useCreatePaintMonthView(timezone);
  const calendarRef = useRef<FullCalendar>(null);
  const { i18n } = useTranslation();
  const { locale } = useFullCalendarLocale(i18n.language);
  const calendarApi = calendarRef.current?.getApi();
  const [datePickerButtonText, setDatePickerButtonText] = useState<string>();
  const [calendarRendered, setCalendarRendered] = useState(false);
  const [monthPickerValue, setMonthPickerValue] = useState<Date>(range.from.toJSDate());
  const [showMonthPicker, setShowMonthPicker] = useState<boolean>(false);
  const monthPickerRef = useClickOutside(() => setShowMonthPicker(false));
  const toggleDatePicker = useCallback(() => setShowMonthPicker(!showMonthPicker), [showMonthPicker]);

  const CustomViewPlugin = useMemo(() => {
    if (config) {
      return createPlugin({
        views: {
          paintMonthView: config,
        },
        name: 'custom-plugin',
      });
    }
  }, [config]);

  const onDateChange = useCallback(
    (newDate: DatesSetArg) => {
      const from = fromISO(newDate.startStr).startOf('month');
      const to = fromISO(newDate.startStr).endOf('month');
      rangeChange({ from, to });
      setCalendarRendered(true);
    },
    [rangeChange]
  );

  const handleMonthChange = (date: Date) => {
    setMonthPickerValue(date);
    if (calendarApi) {
      calendarApi.gotoDate(date);
      toggleDatePicker();
    }
  };

  useEffect(() => {
    setDatePickerButtonText(getButtonText(range, 'paintMonthView'));
    setMonthPickerValue(range.from.toJSDate());
  }, [range]);

  useEffect(() => {
    if (datePickerButtonText && calendarRendered) {
      prependCalendarIconToDatePickerBtn();
    }
  }, [datePickerButtonText, calendarRendered]);

  return (
    <div className="py-4 grow relative">
      <Popover>
        {showMonthPicker && (
          <Popover.Panel ref={monthPickerRef} static className="absolute top-14 right-0 z-10">
            <JxtMonthPicker initialDate={monthPickerValue} onDateChange={handleMonthChange} />
          </Popover.Panel>
        )}
      </Popover>
      {!!CustomViewPlugin && (
        <FullCalendar
          ref={calendarRef}
          locale={locale}
          plugins={[CustomViewPlugin, luxonPlugin]}
          initialView="paintMonthView"
          height="auto"
          initialDate={range.from.toJSDate()}
          timeZone={timezone}
          displayEventTime={false}
          headerToolbar={{
            left: 'prev,next today',
            center: '',
            right: 'datePickerButton',
          }}
          customButtons={{
            datePickerButton: {
              text: datePickerButtonText,
              click: toggleDatePicker,
            },
          }}
          weekNumbers={true}
          showNonCurrentDates={false}
          navLinks={true}
          firstDay={1}
          datesSet={onDateChange}
          events={events}
        />
      )}
    </div>
  );
};

export default JxtWorkplaceCalendar;
