export enum UserStaleTimeEnum {
  GetMe = 60000,
  GetTypicalWeek = 60000,
  GetWorkplaces = 60000,
  GetOldScheduler = 60000,
  GetMyPreferences = 60000,
  GetMyNotifications = 60000,
  GetUserById = 60000,
  GetUsersSearch = 60000,
  PostUserIcsCalendar = 60000,
  GetUserLoginDetails = 60000,
}
