
/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The account
 */
export interface AccountCompressedDto { 
    /**
     * account id
     */
    id?: number;
    /**
     * The name of the account
     */
    name?: string;
    /**
     * The csm code of the account
     */
    code?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountRequestDto { 
    /**
     * the name of account
     */
    name: string;
    /**
     * the code of account
     */
    code: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountResponseDto { 
    id?: number;
    /**
     * the name of account
     */
    name?: string;
    xApiKey?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddUserToUserGroupRequest { 
    /**
     * Id of user
     */
    id?: number;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttendeeParticipationDto { 
    participationStatus: AttendeeParticipationDtoParticipationStatusEnum;
}
export enum AttendeeParticipationDtoParticipationStatusEnum {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    AcceptedRemotely = 'ACCEPTED_REMOTELY',
    NeedsAction = 'NEEDS_ACTION',
    Tentative = 'TENTATIVE',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Detailed representation of the AuthenticationProtocol
 */
export interface AuthenticationProtocol { 
    /**
     * The internal id.
     */
    id?: number;
    /**
     * The human-readable name of the protocol.
     */
    name?: string;
    /**
     * The technical type of protocol, from those supported by Jooxter.
     */
    type?: AuthenticationProtocolTypeEnum;
    /**
     * Entity id of the SAML2 protocol
     */
    entityId?: string;
    /**
     * Provider name cognito
     */
    cognitoFederationName?: string;
}
export enum AuthenticationProtocolTypeEnum {
    Jooxter = 'JOOXTER',
    Saml2 = 'SAML2'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Summary representation of the AuthenticationProtocol
 */
export interface AuthenticationProtocolCompressed { 
    /**
     * The internal id.
     */
    id?: number;
    /**
     * The human-readable name of the protocol.
     */
    name?: string;
    /**
     * The technical type of protocol, from those supported by Jooxter.
     */
    type?: AuthenticationProtocolCompressedTypeEnum;
    /**
     * Entity id of the SAML2 protocol
     */
    entityId?: string;
    /**
     * Provider name cognito
     */
    cognitoFederationName?: string;
}
export enum AuthenticationProtocolCompressedTypeEnum {
    Jooxter = 'JOOXTER',
    Saml2 = 'SAML2'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Contains necessary data to continue as an authenticated user
 */
export interface AuthenticationSuccess { 
    key?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




export interface BestPlace { 
    location?: LocationCompressed;
    floor?: FloorCompressed;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BatchResultDto { 
    added?: number;
    removed?: number;
    error?: number;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */









/**
 * Represent a booking in Jooxter.
 */
export interface Booking { 
    /**
     * The summary
     */
    summary: string;
    /**
     * The detailed description
     */
    description?: string;
    /**
     * The booking color. Hexadecimal format
     */
    color: string;
    /**
     * The visibility
     */
    visibility: BookingVisibilityEnum;
    /**
     * The rrule defining the recurrence if any
     */
    rrule?: string;
    start: BookingTimeBound;
    end: BookingTimeBound;
    resource: ResourceCompressed;
    organizer: UserCompressed;
    /**
     * Number of physical attendees
     */
    physicalAttendees?: number;
    checkin?: BookingState;
    checkout?: BookingState;
    approved?: BookingState;
    rejected?: BookingState;
    cancelled?: BookingState;
    /**
     * Booking\'s ID
     */
    readonly id: number;
    /**
     * Booking type
     */
    readonly type?: BookingTypeEnum;
    /**
     * id of the recurrence source.
     */
    recurrenceSourceId?: number;
    capabilities?: BookingCapabilities;
    attendees: BookingAttendees;
    /**
     * List of selected options
     */
    options: Array<BookingOption>;
    created: BookingState;
    lastModified?: BookingState;
}
export enum BookingVisibilityEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
};
export enum BookingTypeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    AvailabilityRange = 'AVAILABILITY_RANGE',
    RecurringBooking = 'RECURRING_BOOKING',
    External = 'EXTERNAL',
    ExternalUserEvent = 'EXTERNAL_USER_EVENT'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Booking request approval.
 */
export interface BookingApproval { 
    /**
     * Whether the booking is approved or rejected by the resource owner
     */
    approve: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a booking attendee.
 */
export interface BookingAttendee { 
    /**
     * Attendee\'s ID
     */
    id?: number;
    /**
     * Attendee\'s email
     */
    email: string;
    /**
     * Attendee\'s name
     */
    name: string;
    /**
     * Attendee\'s corporation
     */
    corporation?: string;
    /**
     * Whether the attendee has been deactivated or not
     */
    disabled: boolean;
    participationStatus?: BookingAttendeeParticipationStatusEnum;
    checkin?: string;
    checkout?: string;
    picture?: string;
}
export enum BookingAttendeeParticipationStatusEnum {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    NeedsAction = 'NEEDS_ACTION',
    Tentative = 'TENTATIVE',
    AcceptedRemotely = 'ACCEPTED_REMOTELY'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The list of attendees ids
 */
export interface BookingAttendeesRequest { 
    /**
     * Internal attendees (people in your Jooxter account).
     */
    internal: Array<number>;
    /**
     * External attendees.
     */
    external: Array<number>;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a set of capabilities on a booking.
 */
export interface BookingCapabilities { 
    /**
     * Whether the principal can checkin the booking or not
     */
    checkin?: boolean;
    /**
     * Whether the principal can checkout the booking or not
     */
    checkout?: boolean;
    /**
     * Whether the principal can cancel the booking or not
     */
    cancel?: boolean;
    /**
     * Whether the principal can update the booking or not
     */
    update?: boolean;
    /**
     * Whether the principal can approve the booking or not
     */
    approve?: boolean;
    /**
     * Whether the principal can answer to a booking invitation
     */
    participate?: boolean;
    /**
     * Whether the principal can confirm his seat in a zone
     */
    arrive?: boolean;
    /**
     * Whether the principal can liberate his seat in a zone
     */
    leave?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BookingColorSetting { 
    desk?: string;
    other?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * Represent a compressed view of a booking in Jooxter.
 */
export interface BookingCompressed { 
    /**
     * The summary
     */
    summary: string;
    /**
     * The detailed description
     */
    description?: string;
    /**
     * The booking color. Hexadecimal format
     */
    color: string;
    /**
     * The visibility
     */
    visibility: BookingCompressedVisibilityEnum;
    /**
     * The rrule defining the recurrence if any
     */
    rrule?: string;
    start: BookingTimeBound;
    end: BookingTimeBound;
    resource: ResourceCompressed;
    organizer: UserCompressed;
    /**
     * Number of physical attendees
     */
    physicalAttendees?: number;
    checkin?: BookingState;
    checkout?: BookingState;
    approved?: BookingState;
    rejected?: BookingState;
    cancelled?: BookingState;
    /**
     * Booking\'s ID
     */
    readonly id: number;
    /**
     * Booking type
     */
    readonly type?: BookingCompressedTypeEnum;
    /**
     * id of the recurrence source.
     */
    recurrenceSourceId?: number;
    capabilities?: BookingCapabilities;
    icons?: Array<string>;
    participationStatus?: BookingCompressedParticipationStatusEnum;
}
export enum BookingCompressedVisibilityEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
};
export enum BookingCompressedTypeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    AvailabilityRange = 'AVAILABILITY_RANGE',
    RecurringBooking = 'RECURRING_BOOKING',
    External = 'EXTERNAL',
    ExternalUserEvent = 'EXTERNAL_USER_EVENT'
};
export enum BookingCompressedParticipationStatusEnum {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    NeedsAction = 'NEEDS_ACTION',
    Tentative = 'TENTATIVE',
    AcceptedRemotely = 'ACCEPTED_REMOTELY'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An external action of a booking option.
 */
export interface BookingExternalAction { 
    /**
     * The external action URL
     */
    href: string;
    /**
     * The external action name
     */
    name: string;
    /**
     * The external action type
     */
    type: BookingExternalActionTypeEnum;
}
export enum BookingExternalActionTypeEnum {
    Link = 'LINK'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface BookingNotificationSettings { 
    created?: NotificationChannel;
    validationRequested?: NotificationChannel;
    validationAccepted?: NotificationChannel;
    validationRefused?: NotificationChannel;
    update?: NotificationChannel;
    cancel?: NotificationChannel;
    startReminder?: NotificationChannel;
    checkinReminder?: NotificationChannel;
    checkoutNotification?: NotificationChannel;
    checkoutSuggestion?: NotificationChannel;
    endReminder?: NotificationChannel;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * End time of the booking
 */
export interface BookingDateTime { 
    /**
     * The date time. In UTC format.
     */
    dateTime: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The list of selected options
 */
export interface BookingOptionRequest { 
    /**
     * Option ID in the resource
     */
    optionOverloadedId: number;
    /**
     * Option\'s value
     */
    value: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * Represent the value of the option selected in a booking.
 */
export interface BookingOption { 
    /**
     * ID of this option\'s value
     */
    id?: number;
    /**
     * Option ID, as defined in the resource
     */
    optionId?: number;
    /**
     * Option\'s name
     */
    name?: string;
    picture?: string;
    value?: BookingOptionValue;
    link?: BookingExternalAction;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Option\'s value
 */
export interface BookingOptionValue { 
    /**
     * Option value content
     */
    content: string;
    /**
     * Option value type
     */
    type: BookingOptionValueTypeEnum;
}
export enum BookingOptionValueTypeEnum {
    Text = 'TEXT',
    Checkbox = 'CHECKBOX',
    List = 'LIST',
    Textarea = 'TEXTAREA'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The rules of the resource to respect to validate a booking
 */
export interface BookingPolicy { 
    latestPolicy?: string;
    earliestPolicy?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






export interface BookingRequest { 
    /**
     * The summary
     */
    summary: string;
    /**
     * The detailed description
     */
    description?: string;
    /**
     * The booking color. Hexadecimal format
     */
    color: string;
    /**
     * The visibility
     */
    visibility: BookingRequestVisibilityEnum;
    /**
     * The rrule defining the recurrence if any
     */
    rrule?: string;
    start: BookingDateTime;
    end: BookingDateTime;
    /**
     * The resource where the booking takes place
     */
    resourceId: number;
    /**
     * Booking organizer
     */
    organizerId: number;
    /**
     * Number of physical attendees
     */
    physicalAttendees?: number;
    attendees: BookingAttendeesRequest;
    /**
     * The list of attendees : email and participation status
     */
    participationStatusAttendees?: Array<ParticipationStatusAttendeeDto>;
    /**
     * The list of selected options
     */
    options: Array<BookingOptionRequest>;
}
export enum BookingRequestVisibilityEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * End time of the booking
 */
export interface BookingTimeBound { 
    /**
     * Strict time
     */
    dateTime: string;
    /**
     * Buffered time
     */
    buffer?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Represent a counter V2 with the resource/timezone and account related to it.
 */
export interface CSMCounterV2 { 
    /**
     * The internal ID of the counter
     */
    id?: number;
    /**
     * The hardware unique identifier
     */
    hardwareId?: string;
    /**
     * The resource ID associated to the device
     */
    resourceId?: number;
    timezone?: string;
    account?: AccountCompressedDto;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Corporation { 
    /**
     * The internal ID
     */
    id?: number;
    /**
     * The name of the corporation
     */
    name: string;
    /**
     * The (postal) address of the corporation
     */
    address: string;
    picture?: string;
    daily_quota?: string;
    readonly remaining_time?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The daily workplace
 */
export interface DailyWorkplaceDto { 
    /**
     * The date
     */
    date: string;
    morning: WorkplaceCompressedDto;
    afternoon: WorkplaceCompressedDto;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * List of attendees
 */
export interface BookingAttendees { 
    /**
     * Internal attendees (people in your Jooxter account).
     */
    internal: Array<BookingAttendee>;
    /**
     * External attendees.
     */
    external: Array<ExternalAttendee>;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Returned in case an error occurred
 */
export interface ErrorResponse { 
    /**
     * Short description
     */
    error?: string;
    /**
     * Full description
     */
    description?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Device { 
    /**
     * The FCM token
     */
    token: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * an exceptional opening or closing period
 */
export interface ExceptionalOpeningHours { 
    /**
     * identifier of the exception
     */
    readonly id?: number;
    /**
     * title of the exception
     */
    title?: string;
    /**
     * start of the exception period
     */
    timestampFrom: string;
    /**
     * end of the exception period
     */
    timestampTo: string;
    /**
     * rrule if the exception is a recurrence
     */
    recurrencyRule?: string;
    /**
     * identifier of the first occurence of the exception
     */
    recurrencySource?: number;
    /**
     * the resource id for which the period is defined
     */
    resourceId: number;
    /**
     * true if the period is an opening one, false for a closing one
     */
    opened: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent an External Attendee in Jooxter.
 */
export interface ExternalAttendee { 
    /**
     * Attendee\'s ID
     */
    id?: number;
    /**
     * Attendee\'s email
     */
    email: string;
    /**
     * Attendee\'s name
     */
    name: string;
    /**
     * Attendee\'s corporation
     */
    corporation?: string;
    /**
     * Whether the attendee has been deactivated or not
     */
    disabled: boolean;
    participationStatus?: ExternalAttendeeParticipationStatusEnum;
    checkin?: string;
    checkout?: string;
}
export enum ExternalAttendeeParticipationStatusEnum {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    NeedsAction = 'NEEDS_ACTION',
    Tentative = 'TENTATIVE',
    AcceptedRemotely = 'ACCEPTED_REMOTELY'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a Floor in Jooxter.
 */
export interface Floor { 
    /**
     * Floor unique identifier
     */
    id: number;
    /**
     * Floor elevator number
     */
    number: number;
    /**
     * Floor name
     */
    name: string;
    image?: string;
    /**
     * Scale Pixels Per Meter
     */
    scalePixelsPerMeter?: number;
    /**
     * Scale for a Pictogram
     */
    mapIconScale?: number;
    /**
     * Degrees Relative To North
     */
    degreesRelativeToNorth?: number;
    /**
     * Matrix
     */
    matrix?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a Floor in Jooxter.
 */
export interface FloorCompressed { 
    /**
     * Floor unique identifier
     */
    id: number;
    /**
     * Floor name
     */
    name: string;
    /**
     * Floor elevator number
     */
    number: number;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FloorRequestDto { 
    /**
     * Building unique identifier
     */
    locationId: number;
    /**
     * Floor name
     */
    name: string;
    /**
     * Floor elevator number
     */
    number: number;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Represent a state change in a booking.
 */
export interface BookingState { 
    /**
     * The date time of the change. In UTC format.
     */
    at: string;
    by: UserCompressed;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BookingVisibilitySetting { 
    desk?: BookingVisibilitySettingDeskEnum;
    other?: BookingVisibilitySettingOtherEnum;
}
export enum BookingVisibilitySettingDeskEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
};
export enum BookingVisibilitySettingOtherEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject { 
    file: Blob;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FeatureCapabilities { 
    collaborators?: boolean;
    resourceManager?: boolean;
    meetup?: boolean;
    changePassword?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject1 { 
    metadata: Blob;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Interval { 
    start?: string;
    end?: string;
    empty?: boolean;
    unboundedStart?: boolean;
    unboundedEnd?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface InvitationNotificationSettings { 
    invite?: NotificationChannel;
    update?: NotificationChannel;
    cancel?: NotificationChannel;
    checkOut?: NotificationChannel;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * Represent a Location in Jooxter.
 */
export interface Location { 
    /**
     * Floors
     */
    readonly floors?: Array<FloorCompressed>;
    readonly buildings?: Array<LocationCompressed>;
    site?: LocationCompressed;
    /**
     * Jooxter internal unique id for a location
     */
    readonly id?: number;
    /**
     * The name of the building
     */
    name: string;
    /**
     * The external ID of the building
     */
    externalId?: string;
    /**
     * Path representing a csm localization
     */
    csmLocalizationPath?: string;
    /**
     * The url of the BI for the location
     */
    biLocationUrl?: string;
    /**
     * The full address of the location.
     */
    address: string;
    timezone: string;
    /**
     * Full description
     */
    description?: string;
    /**
     * World latitude, set it or your building will sink in the middle of atlantic ocean.
     */
    latitude?: number;
    /**
     * World longitude, set it or your building will sink in the middle of atlantic ocean.
     */
    longitude?: number;
    /**
     * List of pictures of the location
     */
    pictures?: Array<string>;
    /**
     * Type of the location
     */
    type: LocationTypeEnum;
    url?: string;
    managers?: Array<number>;
}
export enum LocationTypeEnum {
    Building = 'BUILDING',
    Site = 'SITE'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a Location in Jooxter.
 */
export interface LocationCompressed { 
    /**
     * Id floors
     */
    floors: Array<number>;
    site?: LocationCompressed;
    /**
     * Jooxter internal unique id for a location
     */
    readonly id?: number;
    /**
     * The name of the building
     */
    name: string;
    /**
     * The external ID of the building
     */
    externalId?: string;
    /**
     * Path representing a csm localization
     */
    csmLocalizationPath?: string;
    /**
     * The url of the BI for the location
     */
    biLocationUrl?: string;
    /**
     * The full address of the location.
     */
    address: string;
    timezone: string;
    /**
     * Full description
     */
    description?: string;
    /**
     * World latitude, set it or your building will sink in the middle of atlantic ocean.
     */
    latitude?: number;
    /**
     * World longitude, set it or your building will sink in the middle of atlantic ocean.
     */
    longitude?: number;
    /**
     * List of pictures of the location
     */
    pictures?: Array<string>;
    /**
     * Type of the location
     */
    type: LocationCompressedTypeEnum;
}
export enum LocationCompressedTypeEnum {
    Building = 'BUILDING',
    Site = 'SITE'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject2 { 
    metadata: Blob;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface LocationCsmDto { 
    /**
     * The ID of the building
     */
    id?: number;
    /**
     * The path representing the building in the csm environment
     */
    csmLocalizationPath?: string;
    account?: AccountCompressedDto;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface LocationMapDto { 
    /**
     * Jooxter internal unique id for a location
     */
    readonly id?: number;
    /**
     * The name of the building
     */
    name: string;
    /**
     * The external ID of the building
     */
    externalId?: string;
    /**
     * Path representing a csm localization
     */
    csmLocalizationPath?: string;
    /**
     * The url of the BI for the location
     */
    biLocationUrl?: string;
    /**
     * The full address of the location.
     */
    address: string;
    timezone: string;
    /**
     * Full description
     */
    description?: string;
    /**
     * World latitude, set it or your building will sink in the middle of atlantic ocean.
     */
    latitude?: number;
    /**
     * World longitude, set it or your building will sink in the middle of atlantic ocean.
     */
    longitude?: number;
    /**
     * List of pictures of the location
     */
    pictures?: Array<string>;
    /**
     * Type of the location
     */
    type: LocationMapDtoTypeEnum;
    status?: LocationMapDtoStatusEnum;
    site?: LocationCompressed;
}
export enum LocationMapDtoTypeEnum {
    Building = 'BUILDING',
    Site = 'SITE'
};
export enum LocationMapDtoStatusEnum {
    Busy = 'BUSY',
    Closed = 'CLOSED',
    Free = 'FREE',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocationDto { 
    url?: string;
    managers?: Array<number>;
    /**
     * Jooxter internal unique id for a location
     */
    readonly id?: number;
    /**
     * The name of the building
     */
    name: string;
    /**
     * The external ID of the building
     */
    externalId?: string;
    /**
     * Path representing a csm localization
     */
    csmLocalizationPath?: string;
    /**
     * The url of the BI for the location
     */
    biLocationUrl?: string;
    /**
     * The full address of the location.
     */
    address: string;
    timezone: string;
    /**
     * Full description
     */
    description?: string;
    /**
     * World latitude, set it or your building will sink in the middle of atlantic ocean.
     */
    latitude?: number;
    /**
     * World longitude, set it or your building will sink in the middle of atlantic ocean.
     */
    longitude?: number;
    /**
     * List of pictures of the location
     */
    pictures?: Array<string>;
    /**
     * Type of the location
     */
    type: LocationDtoTypeEnum;
}
export enum LocationDtoTypeEnum {
    Building = 'BUILDING',
    Site = 'SITE'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a Location in Jooxter.
 */
export interface LocationRequest { 
    siteId?: number;
    /**
     * Jooxter internal unique id for a location
     */
    readonly id?: number;
    /**
     * The name of the building
     */
    name: string;
    /**
     * The external ID of the building
     */
    externalId?: string;
    /**
     * Path representing a csm localization
     */
    csmLocalizationPath?: string;
    /**
     * The url of the BI for the location
     */
    biLocationUrl?: string;
    /**
     * The full address of the location.
     */
    address: string;
    timezone: string;
    /**
     * Full description
     */
    description?: string;
    /**
     * World latitude, set it or your building will sink in the middle of atlantic ocean.
     */
    latitude?: number;
    /**
     * World longitude, set it or your building will sink in the middle of atlantic ocean.
     */
    longitude?: number;
    /**
     * List of pictures of the location
     */
    pictures?: Array<string>;
    /**
     * Type of the location
     */
    type: LocationRequestTypeEnum;
    url?: string;
    managers?: Array<number>;
}
export enum LocationRequestTypeEnum {
    Building = 'BUILDING',
    Site = 'SITE'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Json payload for login
 */
export interface LoginForm { 
    email?: string;
    password?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Used to reset user password
 */
export interface NewPassword { 
    /**
     * The new password
     */
    newPassword?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaResponseDto { 
    /**
     * Media\'s ID
     */
    id?: number;
    /**
     * The link to the media
     */
    link?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationChannel { 
    push?: boolean;
    mail?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */








export interface NotificationSettings { 
    global?: NotificationChannel;
    booking?: BookingNotificationSettings;
    invitation?: InvitationNotificationSettings;
    workplace?: WorkplaceNotificationSettings;
    owner?: OwnerNotificationSettings;
    workflow?: WorkflowNotificationSettings;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OAuthUserRequestDto { 
    /**
     * The cognito username from user pool
     */
    username: string;
    /**
     * The cognito email from user pool
     */
    email: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * a rule defining the opening hours of a resource for a set of days
 */
export interface OpeningHoursRule { 
    /**
     * the id of the resource associated
     */
    resourceId: number;
    timeFrom: string;
    timeTo: string;
    /**
     * list of the days concerned by the rule (short form, case insensitive)
     */
    dayList: OpeningHoursRuleDayListEnum;
    /**
     * date of activation
     */
    startDate: string;
    /**
     * date of deactivation
     */
    endDate: string;
    /**
     * unique identifier of the rule
     */
    readonly ruleId?: number;
}
export enum OpeningHoursRuleDayListEnum {
    Mon = 'mon',
    Tue = 'tue',
    Wed = 'wed',
    Thu = 'thu',
    Fri = 'fri',
    Sat = 'sat',
    Sun = 'sun'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Represent an option in Jooxter. One option is available on one resource and rely on an OptionDescription.
 */
export interface Option { 
    definition?: OptionDefinitionCompressed;
    id?: number;
    beforeBookingTime?: number;
    afterBookingTime?: number;
    cancellationPolicyDelayBeforeBooking?: number;
    externalLink?: string;
    defaultValue?: string;
    available?: boolean;
    mandatory?: boolean;
    editable?: boolean;
    selectedByDefault?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a OptionDefinition in Jooxter.
 */
export interface OptionDefinition { 
    id?: number;
    type?: OptionDefinitionTypeEnum;
    name?: string;
    impactBeforeBookingTime?: boolean;
    beforeBookingTime?: number;
    impactAfterBookingTime?: boolean;
    afterBookingTime?: number;
    impactCancellationPolicy?: boolean;
    cancellationPolicyDelayBeforeBooking?: number;
    externalActionType?: OptionDefinitionExternalActionTypeEnum;
    externalActionLabel?: string;
    possibleValues?: Array<string>;
    icon?: string;
}
export enum OptionDefinitionTypeEnum {
    Text = 'TEXT',
    Checkbox = 'CHECKBOX',
    List = 'LIST',
    Textarea = 'TEXTAREA'
};
export enum OptionDefinitionExternalActionTypeEnum {
    Link = 'LINK'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a OptionDefinition in Jooxter.
 */
export interface OptionDefinitionCompressed { 
    id?: number;
    type?: OptionDefinitionCompressedTypeEnum;
    name?: string;
    impactBeforeBookingTime?: boolean;
    beforeBookingTime?: number;
    impactAfterBookingTime?: boolean;
    afterBookingTime?: number;
    impactCancellationPolicy?: boolean;
    cancellationPolicyDelayBeforeBooking?: number;
    externalActionType?: OptionDefinitionCompressedExternalActionTypeEnum;
    externalActionLabel?: string;
    possibleValues?: Array<string>;
    icon?: string;
}
export enum OptionDefinitionCompressedTypeEnum {
    Text = 'TEXT',
    Checkbox = 'CHECKBOX',
    List = 'LIST',
    Textarea = 'TEXTAREA'
};
export enum OptionDefinitionCompressedExternalActionTypeEnum {
    Link = 'LINK'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface OwnerNotificationSettings { 
    createRequest?: NotificationChannel;
    updateRequest?: NotificationChannel;
    reminder?: NotificationChannel;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface Page { 
    size?: number;
    content?: Array<LocationCompressed>;
    pageNumber?: number;
    full?: boolean;
    lastPage?: boolean;
    expectedSize?: number;
    nextPageRequest?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The list of attendees : email and participation status
 */
export interface ParticipationStatusAttendeeDto { 
    email: string;
    participationStatus: ParticipationStatusAttendeeDtoParticipationStatusEnum;
}
export enum ParticipationStatusAttendeeDtoParticipationStatusEnum {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    AcceptedRemotely = 'ACCEPTED_REMOTELY',
    NeedsAction = 'NEEDS_ACTION',
    Tentative = 'TENTATIVE',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






export interface Preferences { 
    lang?: PreferencesLangEnum;
    location?: LocationCompressed;
    resource?: ResourceCompressed;
    timezone?: string;
    defaultBookingColor?: BookingColorSetting;
    defaultBookingVisibility?: BookingVisibilitySetting;
}
export enum PreferencesLangEnum {
    Fr = 'fr',
    En = 'en',
    De = 'de',
    Pt = 'pt',
    Es = 'es',
    It = 'it'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




export interface PreferencesRequest { 
    lang?: PreferencesRequestLangEnum;
    location?: number;
    resource?: number;
    timezone?: string;
    defaultBookingColor?: BookingColorSetting;
    defaultBookingVisibility?: BookingVisibilitySetting;
}
export enum PreferencesRequestLangEnum {
    Fr = 'fr',
    En = 'en',
    De = 'de',
    Pt = 'pt',
    Es = 'es',
    It = 'it'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */












/**
 * hold data about a booking recurrence
 */
export interface RecurrenceDto { 
    /**
     * The summary
     */
    summary: string;
    /**
     * The detailed description
     */
    description?: string;
    /**
     * The booking color. Hexadecimal format
     */
    color: string;
    /**
     * The visibility
     */
    visibility: RecurrenceDtoVisibilityEnum;
    /**
     * The rrule defining the recurrence if any
     */
    rrule?: string;
    start: BookingTimeBound;
    end: BookingTimeBound;
    resource: ResourceCompressed;
    organizer: UserCompressed;
    /**
     * Number of physical attendees
     */
    physicalAttendees?: number;
    checkin?: BookingState;
    checkout?: BookingState;
    approved?: BookingState;
    rejected?: BookingState;
    cancelled?: BookingState;
    /**
     * Booking\'s ID
     */
    readonly id: number;
    /**
     * Booking type
     */
    readonly type?: RecurrenceDtoTypeEnum;
    /**
     * id of the recurrence source.
     */
    recurrenceSourceId?: number;
    capabilities?: BookingCapabilities;
    attendees: BookingAttendees;
    /**
     * List of selected options
     */
    options: Array<BookingOption>;
    created: BookingState;
    lastModified?: BookingState;
    /**
     * List of all booking occurrences
     */
    occurrences: Array<BookingCompressed>;
    /**
     * show time slots where occurrence can not be created.
     */
    violations: { [key: string]: Array<string>; };
    cancelledOccurrences?: Array<number>;
}
export enum RecurrenceDtoVisibilityEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
};
export enum RecurrenceDtoTypeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    AvailabilityRange = 'AVAILABILITY_RANGE',
    RecurringBooking = 'RECURRING_BOOKING',
    External = 'EXTERNAL',
    ExternalUserEvent = 'EXTERNAL_USER_EVENT'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */










export interface RecurrenceDtoAllOf { 
    /**
     * The summary
     */
    summary?: string;
    /**
     * The detailed description
     */
    description?: string;
    /**
     * The booking color. Hexadecimal format
     */
    color?: string;
    /**
     * The visibility
     */
    visibility?: RecurrenceDtoAllOfVisibilityEnum;
    /**
     * The rrule defining the recurrence if any
     */
    rrule?: string;
    start?: BookingTimeBound;
    end?: BookingTimeBound;
    resource?: ResourceCompressed;
    organizer?: UserCompressed;
    /**
     * Number of physical attendees
     */
    physicalAttendees?: number;
    checkin?: BookingState;
    checkout?: BookingState;
    approved?: BookingState;
    rejected?: BookingState;
    cancelled?: BookingState;
    /**
     * Booking\'s ID
     */
    readonly id?: number;
    /**
     * Booking type
     */
    readonly type?: RecurrenceDtoAllOfTypeEnum;
    /**
     * id of the recurrence source.
     */
    recurrenceSourceId?: number;
    capabilities?: BookingCapabilities;
    attendees?: BookingAttendees;
    /**
     * List of selected options
     */
    options?: Array<BookingOption>;
    created?: BookingState;
    lastModified?: BookingState;
    /**
     * List of all booking occurrences
     */
    occurrences?: Array<BookingCompressed>;
    /**
     * show time slots where occurrence can not be created.
     */
    violations?: { [key: string]: Array<string>; };
    cancelledOccurrences?: Array<number>;
}
export enum RecurrenceDtoAllOfVisibilityEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
};
export enum RecurrenceDtoAllOfTypeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    AvailabilityRange = 'AVAILABILITY_RANGE',
    RecurringBooking = 'RECURRING_BOOKING',
    External = 'EXTERNAL',
    ExternalUserEvent = 'EXTERNAL_USER_EVENT'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Json payload to reset user password with the cognito code
 */
export interface ResetMyPasswordWithCognitoCode { 
    email?: string;
    code?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Json payload to reset user password
 */
export interface ResetPasswordForm { 
    email?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a Reset Password Token, shows the token value and its current state
 */
export interface ResetPasswordToken { 
    /**
     * Reset token value
     */
    token?: string;
    isTokenValid?: boolean;
    tokenValid?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a set of capabilities on a resource.
 */
export interface ResourceCapabilities { 
    /**
     * Whether the principal can book the resource or not
     */
    book: boolean;
    /**
     * Whether the principal can use recurrence on the resource or not
     */
    recurrence: boolean;
    /**
     * Whether the principal can update the resource or not
     */
    update: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * Represent a Resource in Jooxter.
 */
export interface ResourceCompressed { 
    /**
     * the resource internal unique id
     */
    readonly id: number;
    /**
     * The id of the resource type
     */
    resourceTypeId: number;
    /**
     * The id of the resource owner
     */
    ownerId?: number;
    /**
     * The id of the resource location
     */
    locationId: number;
    /**
     * The id of the resource floor
     */
    floorId?: number;
    capabilities?: ResourceCapabilities;
    readonly timezone?: string;
    /**
     * the resource name
     */
    name: string;
    /**
     * the number of people the resource can accept for a booking
     */
    capacity: number;
    /**
     * True if the resource can be booked
     */
    bookable: boolean;
    /**
     * True if the resource is visible on the map or in the search results
     */
    visible: boolean;
    /**
     * True if a booking on the resource must be confirmed by the resource owner
     */
    requestOwnerValidation: boolean;
    /**
     * this fields is deprecated, use bookingPolicy.earliestPolicy instead. The maximum number of minutes allowed before the start of the booking created or modified
     */
    reservationPolicy?: number;
    bookingPolicy?: BookingPolicy;
    /**
     * resource labels
     */
    labels?: Array<string>;
    /**
     * the list of pictures
     */
    pictures?: Array<string>;
    /**
     * email address of resource for cronofy synchronisation
     */
    email?: string;
    /**
     * Resource added to current user\'s favorites
     */
    favorite?: boolean;
    /**
     * True is the checkin is required for a booking on the resource. If it is checked in, the resource will be released
     */
    checkin: boolean;
    state?: ResourceStateDto;
    /**
     * True if a booking must have at least 1 guest
     */
    guestsRequired: boolean;
    /**
     * True if the number of physical attendees is required
     */
    physicalAttendeesRequired: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Represent an object Resource with state information 
 */
export interface ResourceFloorDto { 
    /**
     * the resource internal unique id
     */
    id: number;
    /**
     * the resource name
     */
    name: string;
    /**
     * the number of people the resource can accept for a booking
     */
    capacity: number;
    /**
     * the id of resourceType
     */
    resourceTypeId: number;
    state?: ResourceStateDto;
    /**
     * is the resource bookable
     */
    bookable?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a ResourceGroup in Jooxter.
 */
export interface ResourceGroupCompressed { 
    /**
     * The name of the resource group
     */
    name: string;
    /**
     * The description of the resource group
     */
    description: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceGroupUpdateRequestDto { 
    /**
     * The name of the resource group
     */
    name: string;
    /**
     * The description of the resource group
     */
    description: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent an date when the resource has open times
 */
export interface ResourceOpenDay { 
    day: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceGroupRequestDto { 
    /**
     * The name of the resource group
     */
    name: string;
    /**
     * The description of the resource group
     */
    description: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent an date/time interval when the resource is opened
 */
export interface ResourceOpenInterval { 
    start: string;
    end: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceRequestDto { 
    /**
     * The resource name
     */
    name: string;
    /**
     * The number of people the resource can accept for a booking, Defaults to 1 if not specified.
     */
    capacity?: number;
    /**
     * Indicates whether the resource can be booked. Defaults to true if not specified.
     */
    bookable?: boolean;
    /**
     * Indicates whether the resource is visible. Defaults to true if not specified.
     */
    visible?: boolean;
    /**
     * Indicates if a booking on the resource must be confirmed by the resource owner.  Defaults to false if not specified.
     */
    requestOwnerValidation?: boolean;
    /**
     * Indicate if a checkin is required for a booking on the resource. Defaults to false if not specified.
     */
    checkin?: boolean;
    /**
     * The description of the resource
     */
    description?: string;
    /**
     * Indicate if a guest is mandatory for a booking on the resource. Defaults to false if not specified.
     */
    guestsRequired?: boolean;
    /**
     * Indicate if a physical attendees is mandatory for a booking on the resource. Defaults to false if not specified.
     */
    physicalAttendeesRequired?: boolean;
    /**
     * Indicate if emails must be sent when booking actions are made on the resource. Defaults to true if not specified.
     */
    sendMailToGuests?: boolean;
    /**
     * The owner id  of the resource
     */
    ownerId?: number;
    /**
     * The location id of the resource
     */
    locationId: number;
    /**
     * The floor id of the resource
     */
    floorId?: number;
    /**
     * The resource type id of the resource
     */
    resourceTypeId?: number;
    /**
     * The group ids assigned to the resource
     */
    groups?: Array<number>;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a resource current status
 */
export interface ResourceStateDto { 
    occupantsCount?: number;
    /**
     * status of the resource at the present time (BUSY, CLOSED, FREE, UNKNOW
     */
    status?: ResourceStateDtoStatusEnum;
    pictogram?: string;
}
export enum ResourceStateDtoStatusEnum {
    Busy = 'BUSY',
    Closed = 'CLOSED',
    Free = 'FREE',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Represent a Resource Tag in Jooxter.
 */
export interface ResourceTagCompressed { 
    /**
     * The internal ID
     */
    id?: number;
    /**
     * The device type
     */
    type?: ResourceTagCompressedTypeEnum;
    /**
     * The hardware unique identifier
     */
    hardwareIdentifier?: string;
    /**
     * The resource ID associated to the device
     */
    resourceId?: number;
    resource: ResourceCompressed;
}
export enum ResourceTagCompressedTypeEnum {
    Tag = 'TAG',
    Pirio = 'PIRIO',
    AlloraBtn = 'ALLORA_BTN',
    Targus = 'TARGUS',
    Sensor = 'SENSOR',
    PeopleCounter = 'PEOPLE_COUNTER',
    Enocean = 'ENOCEAN',
    GwOrange = 'GW_ORANGE',
    GwAxians = 'GW_AXIANS',
    CsmCountingCeilingWirelessV2 = 'CSM_COUNTING_CEILING_WIRELESS_V2',
    CsmPresenceCeilingWirelessV2 = 'CSM_PRESENCE_CEILING_WIRELESS_V2',
    CsmPresenceDeskWirelessV2 = 'CSM_PRESENCE_DESK_WIRELESS_V2',
    PresenceDeskWirelessCi = 'PRESENCE_DESK_WIRELESS_CI',
    PresenceCeilingWirelessCi = 'PRESENCE_CEILING_WIRELESS_CI',
    PresenceDeskWirelessHe = 'PRESENCE_DESK_WIRELESS_HE',
    PresenceCeilingWirelessHe = 'PRESENCE_CEILING_WIRELESS_HE',
    Wizzipark = 'WIZZIPARK'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */










/**
 * Represent a Resource in Jooxter.
 */
export interface Resource { 
    /**
     * the resource internal unique id
     */
    readonly id: number;
    /**
     * The description of the resource
     */
    description: string;
    /**
     * True if emails must be sent when booking actions are made on the resource
     */
    sendMailToGuests: boolean;
    /**
     * Defines the rule to compute the logistic time needed before and after a booking
     */
    logisticTimeRule: ResourceLogisticTimeRuleEnum;
    /**
     * True if the check in can be done remotely
     */
    remoteCheckinAllowedForBookingOwner: boolean;
    /**
     * The number of minutes to check in after the beginning of the booking, otherwise the resource will be released
     */
    delayBeforeAutocancel: number;
    /**
     * A list of emails that must be informed of the bookings actions on the resource
     */
    readonly informationList?: Array<string>;
    resourceType: ResourceTypeDto;
    owner?: UserCompressed;
    location: LocationCompressed;
    floor?: FloorCompressed;
    capabilities?: ResourceCapabilities;
    slots?: Slots;
    /**
     * the resource name
     */
    name: string;
    /**
     * the number of people the resource can accept for a booking
     */
    capacity: number;
    /**
     * True if the resource can be booked
     */
    bookable: boolean;
    /**
     * True if the resource is visible on the map or in the search results
     */
    visible: boolean;
    /**
     * True if a booking on the resource must be confirmed by the resource owner
     */
    requestOwnerValidation: boolean;
    /**
     * this fields is deprecated, use bookingPolicy.earliestPolicy instead. The maximum number of minutes allowed before the start of the booking created or modified
     */
    reservationPolicy?: number;
    bookingPolicy?: BookingPolicy;
    /**
     * resource labels
     */
    labels?: Array<string>;
    /**
     * the list of pictures
     */
    pictures?: Array<string>;
    /**
     * email address of resource for cronofy synchronisation
     */
    email?: string;
    /**
     * Resource added to current user\'s favorites
     */
    favorite?: boolean;
    /**
     * True is the checkin is required for a booking on the resource. If it is checked in, the resource will be released
     */
    checkin: boolean;
    state?: ResourceStateDto;
    /**
     * True if a booking must have at least 1 guest
     */
    guestsRequired: boolean;
    /**
     * True if the number of physical attendees is required
     */
    physicalAttendeesRequired: boolean;
}
export enum ResourceLogisticTimeRuleEnum {
    Sum = 'SUM',
    Max = 'MAX'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceTagCompressedDto { 
    /**
     * The device type
     */
    type?: ResourceTagCompressedDtoTypeEnum;
    /**
     * The hardware unique identifier
     */
    hardwareIdentifier?: string;
    /**
     * The resource ID associated to the device
     */
    resourceId?: number;
}
export enum ResourceTagCompressedDtoTypeEnum {
    Tag = 'TAG',
    Pirio = 'PIRIO',
    AlloraBtn = 'ALLORA_BTN',
    Targus = 'TARGUS',
    Sensor = 'SENSOR',
    PeopleCounter = 'PEOPLE_COUNTER',
    Enocean = 'ENOCEAN',
    GwOrange = 'GW_ORANGE',
    GwAxians = 'GW_AXIANS',
    CsmCountingCeilingWirelessV2 = 'CSM_COUNTING_CEILING_WIRELESS_V2',
    CsmPresenceCeilingWirelessV2 = 'CSM_PRESENCE_CEILING_WIRELESS_V2',
    CsmPresenceDeskWirelessV2 = 'CSM_PRESENCE_DESK_WIRELESS_V2',
    PresenceDeskWirelessCi = 'PRESENCE_DESK_WIRELESS_CI',
    PresenceCeilingWirelessCi = 'PRESENCE_CEILING_WIRELESS_CI',
    PresenceDeskWirelessHe = 'PRESENCE_DESK_WIRELESS_HE',
    PresenceCeilingWirelessHe = 'PRESENCE_CEILING_WIRELESS_HE',
    Wizzipark = 'WIZZIPARK'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceTagRequestDto { 
    /**
     * Datetime of last message received by censor
     */
    lastMessageTimestamp?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceTimelineStatusDto { 
    /**
     * start
     */
    start: string;
    /**
     * end
     */
    end: string;
    /**
     * status of the event
     */
    status: ResourceTimelineStatusDtoStatusEnum;
}
export enum ResourceTimelineStatusDtoStatusEnum {
    Busy = 'BUSY',
    Closed = 'CLOSED',
    Free = 'FREE',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * the type of the resource
 */
export interface ResourceTypeDto { 
    /**
     * Resource type unique identifier
     */
    readonly id: number;
    /**
     * Resource name
     */
    name: string;
    /**
     * Max allowed booking duration
     */
    minDuration?: number;
    /**
     * Minimum allowed booking duration
     */
    maxDuration?: number;
    /**
     * Does the icon should be displayed on map
     */
    showMapIcon: boolean;
    /**
     * Map icon size, 11 is the default
     */
    mapIconSize: number;
    /**
     * Does the recurrence is allowed on this resource type
     */
    recurrency: boolean;
    /**
     * The kind of the pictogram displayed on the map
     */
    picto: ResourceTypeDtoPictoEnum;
    /**
     * the inner type of the type
     */
    metaType?: ResourceTypeDtoMetaTypeEnum;
    /**
     * Tell if the type allows to book simultaneously
     */
    allowOverlap: boolean;
}
export enum ResourceTypeDtoPictoEnum {
    Classic = 'CLASSIC',
    Counter = 'COUNTER',
    Bookable = 'BOOKABLE',
    Unbookable = 'UNBOOKABLE'
};
export enum ResourceTypeDtoMetaTypeEnum {
    Bubble = 'BUBBLE',
    Booth = 'BOOTH',
    Desk = 'DESK',
    Meeting = 'MEETING',
    Parking = 'PARKING',
    Vehicle = 'VEHICLE',
    Visio = 'VISIO',
    Other = 'OTHER'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceTypeRequestDto { 
    /**
     * Resource name
     */
    name: string;
    /**
     * the inner type of the resource type
     */
    metaType: ResourceTypeRequestDtoMetaTypeEnum;
    /**
     * Tell if the type allows to book simultaneously
     */
    allowOverlap: boolean;
}
export enum ResourceTypeRequestDtoMetaTypeEnum {
    Bubble = 'BUBBLE',
    Booth = 'BOOTH',
    Desk = 'DESK',
    Meeting = 'MEETING',
    Parking = 'PARKING',
    Vehicle = 'VEHICLE',
    Visio = 'VISIO',
    Other = 'OTHER'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Timeslots for the given resource
 */
export interface Slots { 
    type?: SlotsTypeEnum;
    durations?: Array<number>;
}
export enum SlotsTypeEnum {
    Day = 'DAY',
    Duration = 'DURATION'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TeamsAnswer { 
    userHash?: string;
    langUrl?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * Represent a Jooxter User
 */
export interface User { 
    id?: number;
    /**
     * User profile
     */
    profile?: string;
    /**
     * User department
     */
    department?: string;
    /**
     * User phone
     */
    telephone?: string;
    /**
     * User registration date
     */
    registrationDate?: string;
    /**
     * User last connexion
     */
    lastConnection?: string;
    timezone?: string;
    location?: LocationCompressed;
    corporation?: Corporation;
    /**
     * user groups
     */
    groups?: Array<UserGroupCompressed>;
    capabilities?: UserCapabilitiesDto;
    picture?: string;
    isFavorite?: boolean;
    iCalendarId?: string;
    /**
     * User has completed the onboarding
     */
    setupDone?: boolean;
    /**
     * User email
     */
    email: string;
    /**
     * User firstname
     */
    firstname: string;
    /**
     * User lastname
     */
    lastname: string;
    lang?: UserRequestExtendedLang;
    /**
     * User type
     */
    type: UserTypeEnum;
    /**
     * User activation state. Optional. Default to true.
     */
    validation?: boolean;
    /**
     * User ID in an external system
     */
    externalId?: string;
    /**
     * Authentication protocol for the user. Default to account\'s one if not provided
     */
    authenticationProtocolId?: number;
    favorite?: boolean;
    icalendarId?: string;
}
export enum UserTypeEnum {
    Manager = 'MANAGER',
    User = 'USER',
    Admin = 'ADMIN',
    OmniVision = 'OMNI_VISION'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface UserCapabilitiesDto { 
    features?: FeatureCapabilities;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * Represent a Jooxter User
 */
export interface UserCompressed { 
    /**
     * User unique ID
     */
    id: number;
    /**
     * User Location ID
     */
    locationId: number;
    /**
     * User Corporation ID
     */
    corporationId: number;
    /**
     * user groups
     */
    groups: Array<UserGroupCompressed>;
    picture?: string;
    timezone?: string;
    isFavorite?: boolean;
    /**
     * User email
     */
    email: string;
    /**
     * User firstname
     */
    firstname: string;
    /**
     * User lastname
     */
    lastname: string;
    lang?: UserRequestExtendedLang;
    /**
     * User type
     */
    type: UserCompressedTypeEnum;
    /**
     * User activation state. Optional. Default to true.
     */
    validation?: boolean;
    /**
     * User ID in an external system
     */
    externalId?: string;
    /**
     * Authentication protocol for the user. Default to account\'s one if not provided
     */
    authenticationProtocolId?: number;
    favorite?: boolean;
}
export enum UserCompressedTypeEnum {
    Manager = 'MANAGER',
    User = 'USER',
    Admin = 'ADMIN',
    OmniVision = 'OMNI_VISION'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a Jooxter User Group
 */
export interface UserGroupCompressed { 
    id?: number;
    /**
     * User group name
     */
    name: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface UserRequestExtended { 
    /**
     * User email
     */
    email: string;
    /**
     * User firstname
     */
    firstname: string;
    /**
     * User lastname
     */
    lastname: string;
    lang?: UserRequestExtendedLang;
    /**
     * User type
     */
    type: UserRequestExtendedTypeEnum;
    /**
     * User activation state. Optional. Default to true.
     */
    validation?: boolean;
    /**
     * User ID in an external system
     */
    externalId?: string;
    /**
     * Authentication protocol for the user. Default to account\'s one if not provided
     */
    authenticationProtocolId?: number;
    /**
     * User phone number. Default to null.
     */
    telephone?: string;
    /**
     * User job profile. Default to null.
     */
    profile?: string;
    /**
     * User department. Default to null.
     */
    department?: string;
    /**
     * User Location ID
     */
    locationId: number;
    /**
     * User Corporation ID
     */
    corporationId: number;
    /**
     * User picture id
     */
    pictureId?: number;
    /**
     * Timezone of the user
     */
    timezone?: string;
    /**
     * List of groups ID containing the user. Existing groups are replaced with this list.
     */
    groupIds?: Array<number>;
}
export enum UserRequestExtendedTypeEnum {
    Manager = 'MANAGER',
    User = 'USER',
    Admin = 'ADMIN',
    OmniVision = 'OMNI_VISION'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Used to get user login details
 */
export interface UserLoginDetail { 
    type?: UserLoginDetailTypeEnum;
    shouldBeMigratedToCognito?: boolean;
    redirect?: string;
    apiVersion?: UserLoginDetailApiVersionEnum;
    cognitoFederatedIdentityProvider?: string;
}
export enum UserLoginDetailTypeEnum {
    Jooxter = 'JOOXTER',
    Saml2 = 'SAML2'
};
export enum UserLoginDetailApiVersionEnum {
    V3 = 'V3',
    V4 = 'V4'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * User language. Default to en.
 */
export enum UserRequestExtendedLang {
    De = 'de',
    Es = 'es',
    En = 'en',
    Fr = 'fr',
    It = 'it',
    Pt = 'pt'
};


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * Represent a Jooxter User with workplaces and bookings
 */
export interface UserScheduler { 
    workplaces?: Array<WorkplaceDto>;
    bookings?: Array<BookingCompressed>;
    timezone?: string;
    /**
     * User email
     */
    email: string;
    /**
     * User firstname
     */
    firstname: string;
    /**
     * User lastname
     */
    lastname: string;
    lang?: UserRequestExtendedLang;
    /**
     * User type
     */
    type: UserSchedulerTypeEnum;
    /**
     * User activation state. Optional. Default to true.
     */
    validation?: boolean;
    /**
     * User ID in an external system
     */
    externalId?: string;
    /**
     * Authentication protocol for the user. Default to account\'s one if not provided
     */
    authenticationProtocolId?: number;
    /**
     * User unique ID
     */
    id: number;
    /**
     * User Location ID
     */
    locationId: number;
    /**
     * User Corporation ID
     */
    corporationId: number;
    /**
     * user groups
     */
    groups: Array<UserGroupCompressed>;
    picture?: string;
    favorite?: boolean;
}
export enum UserSchedulerTypeEnum {
    Manager = 'MANAGER',
    User = 'USER',
    Admin = 'ADMIN',
    OmniVision = 'OMNI_VISION'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * Represent a Jooxter User with workplaces
 */
export interface UserWorkplaces { 
    /**
     * list of daily Workplace
     */
    workplaces: Array<DailyWorkplaceDto>;
    /**
     * User email
     */
    email: string;
    /**
     * User firstname
     */
    firstname: string;
    /**
     * User lastname
     */
    lastname: string;
    lang?: UserRequestExtendedLang;
    /**
     * User type
     */
    type: UserWorkplacesTypeEnum;
    /**
     * User activation state. Optional. Default to true.
     */
    validation?: boolean;
    /**
     * User ID in an external system
     */
    externalId?: string;
    /**
     * Authentication protocol for the user. Default to account\'s one if not provided
     */
    authenticationProtocolId?: number;
    /**
     * User unique ID
     */
    id: number;
    /**
     * User Location ID
     */
    locationId: number;
    /**
     * User Corporation ID
     */
    corporationId: number;
    /**
     * user groups
     */
    groups: Array<UserGroupCompressed>;
    picture?: string;
    timezone?: string;
    favorite?: boolean;
}
export enum UserWorkplacesTypeEnum {
    Manager = 'MANAGER',
    User = 'USER',
    Admin = 'ADMIN',
    OmniVision = 'OMNI_VISION'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The workday of Sunday
 */
export interface WorkDay { 
    morning: WorkHourRange;
    afternoon: WorkHourRange;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The work range of afternoon
 */
export interface WorkHourRange { 
    start: string;
    end: string;
    workplace?: WorkHourRangeWorkplaceEnum;
    readonly generatedByDefault?: boolean;
}
export enum WorkHourRangeWorkplaceEnum {
    WorkFromHome = 'WORK_FROM_HOME',
    Mission = 'MISSION',
    Office = 'OFFICE',
    Off = 'OFF',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Represent a Jooxter UserGroup
 */
export interface UserGroup { 
    id?: number;
    /**
     * User group name
     */
    name: string;
    /**
     * User group description
     */
    description?: string;
    /**
     * Managers of the Group
     */
    managers?: Set<UserCompressed>;
    /**
     * Related resource groups
     */
    resourceGroups?: Array<number>;
    /**
     * The url of the BI for the user group
     */
    biUserGroupUrl?: string;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface WorkHoursWeekDto { 
    monday?: WorkDay;
    tuesday?: WorkDay;
    wednesday?: WorkDay;
    thursday?: WorkDay;
    friday?: WorkDay;
    saturday?: WorkDay;
    sunday?: WorkDay;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface WorkHoursWeekRequest { 
    monday: WorkDay;
    tuesday: WorkDay;
    wednesday: WorkDay;
    thursday: WorkDay;
    friday: WorkDay;
    saturday: WorkDay;
    sunday: WorkDay;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface WorkflowNotificationSettings { 
    requested?: NotificationChannel;
    accepted?: NotificationChannel;
    refused?: NotificationChannel;
    create?: NotificationChannel;
    update?: NotificationChannel;
    cancel?: NotificationChannel;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Capabilities of the principal on the workplace.
 */
export interface WorkplaceCapabilitiesDto { 
    /**
     * Whether the principal can cancel the workplace or not
     */
    cancel?: boolean;
    /**
     * Whether the principal can update the workplace or not
     */
    update?: boolean;
    /**
     * Whether the principal can create the workplace or not
     */
    create?: boolean;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The workplace compressed
 */
export interface WorkplaceCompressedDto { 
    /**
     * The id of the workplace
     */
    id?: number;
    /**
     * The workplace type
     */
    type: WorkplaceCompressedDtoTypeEnum;
    /**
     * The location of workplace
     */
    locationId?: number;
    capabilities: WorkplaceCapabilitiesDto;
}
export enum WorkplaceCompressedDtoTypeEnum {
    WorkFromHome = 'WORK_FROM_HOME',
    Mission = 'MISSION',
    Office = 'OFFICE',
    Off = 'OFF',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







export interface WorkTogetherDto { 
    interval?: Interval;
    bestPlace?: BestPlace;
    alreadyBooked?: Array<BookingCompressed>;
    desksToBook?: Array<ResourceCompressed>;
    usersToBook?: Array<UserCompressed>;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




export interface WorkplaceDto { 
    id?: number;
    /**
     * The start date of workplace (format ISO-8601)
     */
    start: string;
    /**
     * The end date of workplace (format ISO-8601)
     */
    end: string;
    type: WorkplaceDtoTypeEnum;
    location?: LocationCompressed;
    capabilities?: WorkplaceCapabilitiesDto;
}
export enum WorkplaceDtoTypeEnum {
    WorkFromHome = 'WORK_FROM_HOME',
    Mission = 'MISSION',
    Office = 'OFFICE',
    Off = 'OFF',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface WorkplaceNotificationSettings { 
    bookingMorning?: NotificationChannel;
    workplaceMorning?: NotificationChannel;
    weeklySuggestion?: NotificationChannel;
    monthlySummary?: NotificationChannel;
}


/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkplaceRequestDto { 
    /**
     * The start date of workplace (format ISO-8601)
     */
    start: string;
    /**
     * The end date of workplace (format ISO-8601)
     */
    end: string;
    /**
     * WORK_FROM_HOME,MISSION,OFFICE,OFF, UNKNOWN
     */
    type: WorkplaceRequestDtoTypeEnum;
    /**
     * The rrule defining the recurrence if any
     */
    rrule?: string;
    /**
     * The location id of workplace (if, and only if workplace type is OFFICE
     */
    locationId?: number;
}
export enum WorkplaceRequestDtoTypeEnum {
    WorkFromHome = 'WORK_FROM_HOME',
    Mission = 'MISSION',
    Office = 'OFFICE',
    Off = 'OFF',
    Unknown = 'UNKNOWN'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkplaceRuleDto { 
    /**
     * The unique identifier of the rule
     */
    id: number;
    /**
     * Type of workplaces on which the rule applies
     */
    workplaceType: WorkplaceRuleDtoWorkplaceTypeEnum;
    /**
     * Granularity of the rule
     */
    rulePeriod: WorkplaceRuleDtoRulePeriodEnum;
    /**
     * Maximum value of the rule
     */
    max: number;
    /**
     * The location of the rule, in case it is an office rule
     */
    locationId?: number;
}
export enum WorkplaceRuleDtoWorkplaceTypeEnum {
    WorkFromHome = 'WORK_FROM_HOME',
    Mission = 'MISSION',
    Office = 'OFFICE',
    Off = 'OFF',
    Unknown = 'UNKNOWN'
};
export enum WorkplaceRuleDtoRulePeriodEnum {
    Week = 'WEEK',
    Month = 'MONTH',
    Year = 'YEAR'
};




/**
 * Jooxter REST API V3
 * Documentation of Jooxter API. Current version 3.X.X
 *
 * The version of the OpenAPI document: v4
 * Contact: developer@jooxter.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkplaceRuleRemainingDays { 
    /**
     * The unique identifier of the rule
     */
    workplaceRuleId: number;
    /**
     * Granularity of the rule
     */
    rulePeriod: WorkplaceRuleRemainingDaysRulePeriodEnum;
    /**
     * The location of the rule, in case it is an office rule
     */
    locationId?: number;
    remainingDays: number;
}
export enum WorkplaceRuleRemainingDaysRulePeriodEnum {
    Week = 'WEEK',
    Month = 'MONTH',
    Year = 'YEAR'
};




