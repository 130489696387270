import { useTranslation } from 'react-i18next';
import JxtOnboardingLayout from '../../../layouts/OnboardingLayout';
import { ButtonSizeEnum, JxtButton } from '@jooxter/ui';
import { useNavigate } from 'react-router';
import { useOnboardingNavigation } from '../hooks';
import { OnboardingPagesEnum } from '../types';
import { createGTMGAEvent } from '@jooxter/utils';

export const OnboardingStart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getNextLink } = useOnboardingNavigation(OnboardingPagesEnum.START);

  const handleClickRouteButton = (route: string) => {
    createGTMGAEvent('Onboarding', 'Start', 'Start onboarding');
    navigate(route);
  };

  return (
    <JxtOnboardingLayout
      title={t<string>('configure-your-account')}
      surtitle={t<string>('welcome-to-jooxter')}
      instructions={t<string>('jooxter-configuration-explanation-1')}
    >
      <div className="flex flex-col gap-6">
        <p className="text-body-m text-neutral-100 text-center">{t('jooxter-configuration-explanation-2')}</p>
        <JxtButton size={ButtonSizeEnum.Large} onClick={() => handleClickRouteButton(getNextLink())}>
          {t('configure-my-account')}
        </JxtButton>
      </div>
    </JxtOnboardingLayout>
  );
};
