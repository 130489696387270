import { MediaResponseDto, MediaService } from '@jooxter/api';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useCreateImage = () => {
  const mutationCreate = useMutation<MediaResponseDto, AxiosError, File>({
    mutationFn: (media: File) => {
      return MediaService.postImage(media);
    },
  });

  return mutationCreate;
};
