import { RecurrenceDto } from '@jooxter/api';
import { ReactNode, useCallback, useState } from 'react';
import { ModalViolationsContext } from './ModalViolationsContext';

export const ModalViolationsProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState<boolean>(false);
  const [recurrence, setRecurrence] = useState<RecurrenceDto | null>(null);

  const showViolations = useCallback((recurrence: RecurrenceDto) => {
    setRecurrence(recurrence);
    setShow(true);
  }, []);

  return (
    <ModalViolationsContext.Provider value={{ show, setShow, showViolations, recurrence }}>
      {children}
    </ModalViolationsContext.Provider>
  );
};
