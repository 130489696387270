import { axiosManager } from '../config';

export interface IGetLabelsParameters {
  keyword?: string;
  pageSize?: number;
}

export const LabelsService = {
  path: 'labels',

  getLabels(options: Partial<IGetLabelsParameters>): Promise<Array<string>> {
    const url = `${this.path}`;
    const params = this.createGetLabelsParams(options);

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  createGetLabelsParams(options: Partial<IGetLabelsParameters>): URLSearchParams {
    const params = new URLSearchParams();

    if (options?.keyword) {
      params.append('keyword', options.keyword);
    }

    if (options?.pageSize) {
      params.append('size', options.pageSize.toString());
    }

    return params;
  },
};
