import { UserWorkplaces } from '@jooxter/api';
import { useMemo } from 'react';
import { pick } from 'lodash-es';
import { IJxtWorkplaceDay } from '@jooxter/utils';
import { useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter } from './useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter';

export type TCalendarInput = {
  id: number;
  firstname: string;
  lastname: string;
  picture?: string;
  canBeLocated: boolean;
  favorite?: boolean;
  workplaces: Map<string, IJxtWorkplaceDay>;
};

export const useUserWorkplacesToCalendarInputAdapter = (
  userWorkplaces: UserWorkplaces[],
  canBeLocatedMap: Map<number, boolean>
): TCalendarInput[] => {
  const { adapt: adaptWorkplaces } = useDailyWorkplaceDtoToIJxtWorkplaceDayAdapter(userWorkplaces);

  const events = useMemo(() => {
    const result: TCalendarInput[] = userWorkplaces.map((u) => {
      const workplaceMap = new Map<string, IJxtWorkplaceDay>();

      u?.workplaces?.forEach((w) => {
        if (w?.date) {
          workplaceMap.set(w.date, adaptWorkplaces(w));
        }
      });
      return {
        ...pick(u, ['id', 'firstname', 'lastname', 'picture', 'favorite']),
        workplaces: workplaceMap,
        canBeLocated: !!canBeLocatedMap.get(u.id),
      };
    });

    return result;
  }, [userWorkplaces, canBeLocatedMap, adaptWorkplaces]);

  return events;
};
