import { JxtToast, JxtToastVariantEnum } from '@jooxter/ui';
import toast from 'react-hot-toast';

export const generateToast = (title: string, isError: boolean, subtitle?: string, children?: React.ReactNode) => {
  toast.custom(
    (newToast) => (
      <JxtToast
        variant={isError ? JxtToastVariantEnum.Failure : JxtToastVariantEnum.Success}
        onHide={() => toast.remove(newToast.id)}
        title={title}
        subtitle={subtitle}
      >
        {children}
      </JxtToast>
    ),
    {
      position: 'bottom-left',
    }
  );
};
