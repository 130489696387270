import { JxtEventColorsEnum } from '@jooxter/utils';

export enum JxtEventColorSizeEnum {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
}

export interface IJxtEventColor {
  color: JxtEventColorsEnum;
  size?: JxtEventColorSizeEnum;
}
