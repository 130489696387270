import { parseLinkHeader, toISO } from '@jooxter/utils';
// eslint-disable-next-line import/named
import { AxiosHeaders, AxiosResponse } from 'axios';
import {
  Option,
  Resource,
  ResourceCompressed,
  ResourceGroupCompressed,
  ResourceOpenInterval,
  ResourceStateDto,
} from '../model';
import { DateTime } from 'luxon';
import { axiosManager } from '../config';

export interface IGetOpeningHoursParameters {
  resourceId: number;
  from: DateTime;
  to: DateTime;
  byDay?: boolean;
}

export interface IGetResourceStatusParameters {
  resourceId: number;
  start: DateTime;
  end: DateTime;
}

export interface IGetResourcesParameters {
  floorId?: number[];
  resourceGroups?: number[];
  name?: string;
  capacity?: number;
  bookable?: boolean;
  visible?: boolean;
  resourceTypeId?: number[];
  ownerId?: number[];
  requestOwnerValidation?: boolean;
  from?: DateTime;
  to?: DateTime;
  labels?: string[];
  locationId?: number[];
  synchronizedOnly?: boolean;
  favoritesOnly?: boolean;
  page?: string;
  size?: number;
  sort?: GetResourcesSortParamsEnum[];
  desc?: GetResourcesSortParamsEnum[];
}

export enum GetResourcesSortParamsEnum {
  LocationName = 'building.locationName',
  FloorNum = 'floor.floorNum',
  ResourceName = 'name',
}

export const ResourceService = {
  path: 'resources',

  getResourcesWithPagination(options?: IGetResourcesParameters): Promise<{
    data: ResourceCompressed[];
    nextPage: string;
  }> {
    const params = this.createGetResourcesParams(options);
    const page = params?.get('page');

    if (page) {
      return axiosManager.getInstance().get(page).then(this.onNextPage);
    }

    const url = `${this.path}`;

    return axiosManager.getInstance().get(url, { params }).then(this.onNextPage);
  },

  getById(id: number): Promise<Resource> {
    const url = `${this.path}/${id}`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  /**
   * @param dateFrom day precision
   * @param dateTo day precision
   */
  getOpeningHours(options: IGetOpeningHoursParameters): Promise<Required<ResourceOpenInterval>[]> {
    const params = this.createOpeningHoursParams(options);
    const url = `${this.path}/${options.resourceId}/open_hours`;

    return axiosManager
      .getInstance()
      .get<Required<ResourceOpenInterval>[]>(url, {
        params,
      })
      .then((res) => res.data);
  },

  getResourceOptions(id: number): Promise<Option[]> {
    const params = new URLSearchParams();
    params.append('available', 'true');
    const url = `${this.path}/${id}/options`;

    return axiosManager
      .getInstance()
      .get(url, { params })
      .then((res) => res.data);
  },

  getResourceGroups(id: number): Promise<ResourceGroupCompressed[]> {
    const url = `${this.path}/${id}/groups`;

    return axiosManager
      .getInstance()
      .get(url)
      .then((res) => res.data);
  },

  getResourceStatus(options: IGetResourceStatusParameters): Promise<ResourceStateDto> {
    const params = this.createResourceOptionsParams(options);
    const url = `${this.path}/${options.resourceId}/status`;

    return axiosManager
      .getInstance()
      .get(url, {
        params,
      })
      .then((res) => res.data);
  },

  putFavorite(resourceId: number): Promise<void> {
    const url = `${this.path}/favorites/${resourceId}`;

    return axiosManager.getInstance().put(url);
  },

  deleteFavorite(resourceId: number): Promise<void> {
    const url = `${this.path}/favorites/${resourceId}`;

    return axiosManager.getInstance().delete(url);
  },

  async onNextPage(res: AxiosResponse) {
    if (res.headers?.get && res.headers instanceof AxiosHeaders) {
      const linkHeader = res.headers.get('Link')?.toString() ?? null;
      const parsed = parseLinkHeader(linkHeader);
      const nextPage = parsed?.next.url ?? '';

      return {
        data: await res.data,
        nextPage,
      };
    }

    return {
      data: await res.data,
      nextPage: '',
    };
  },

  createOpeningHoursParams(options: IGetOpeningHoursParameters): URLSearchParams {
    const params = new URLSearchParams();
    const from = options.from.toISODate();
    const to = options.to.toISODate();

    if (from && to) {
      params.append('date_from', from);
      params.append('date_to', to);
    }

    if (params.get('date_from') === params.get('date_to')) {
      params.delete('date_to');
    }

    if (options.byDay) {
      params.append('by_day', options.byDay.toString());
    }

    return params;
  },

  createResourceOptionsParams(options: IGetResourceStatusParameters): URLSearchParams {
    const params = new URLSearchParams();
    const start = toISO(options.start);
    const end = toISO(options.end);

    if (start && end) {
      params.append('start', start);
      params.append('end', end);
    }

    return params;
  },

  createGetResourcesParams(options?: IGetResourcesParameters): URLSearchParams {
    const params = new URLSearchParams();

    if (!options) {
      return params;
    }

    if (options.floorId && options.floorId.length > 0) {
      params.set('floorId', options.floorId.join(','));
    }

    if (options.resourceGroups && options.resourceGroups.length > 0) {
      params.set('resourceGroups', options.resourceGroups.join(','));
    }

    if (options.name) {
      params.set('name', options.name);
    }

    if (options.capacity) {
      params.set('capacity', options.capacity.toString());
    }

    if (typeof options.bookable === 'boolean') {
      params.set('bookable', options.bookable.toString());
    }

    if (typeof options.visible === 'boolean') {
      params.set('visible', options.visible.toString());
    }

    if (options.resourceTypeId && options.resourceTypeId.length > 0) {
      params.set('resourceTypeId', options.resourceTypeId.join(','));
    }

    if (options.ownerId && options.ownerId.length > 0) {
      params.set('ownerId', options.ownerId.join(','));
    }

    if (typeof options.requestOwnerValidation === 'boolean') {
      params.set('requestOwnerValidation', options.requestOwnerValidation.toString());
    }

    if (options.from) {
      params.set('from', options.from.toUTC().toISO() ?? 'Invalid Date');
    }

    if (options.to) {
      params.set('to', options.to.toUTC().toISO() ?? 'Invalid Date');
    }

    if (options.labels && options.labels.length > 0) {
      params.set('labels', options.labels.join(','));
    }

    if (options.locationId && options.locationId.length > 0) {
      params.set('locationId', options.locationId.join(','));
    }

    if (typeof options.synchronizedOnly === 'boolean') {
      params.set('synchronizedOnly', options.synchronizedOnly.toString());
    }

    if (typeof options.favoritesOnly === 'boolean') {
      params.set('favoritesOnly', options.favoritesOnly.toString());
    }

    if (options.page) {
      params.set('page', options.page);
    }

    if (options.size) {
      params.set('size', options.size.toString());
    }

    if (options.sort && options.sort.length > 0) {
      params.set('sort', options.sort.join(','));
    }

    if (options.desc && options.desc.length > 0) {
      params.set('desc', options.desc.join(','));
    }

    return params;
  },
};
