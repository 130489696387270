import { ReactNode } from 'react';

export enum JxtOffCanvasHeaderHideIconEnum {
  Close = 'close',
  Back = 'back',
}

export type JxtOffCanvaHeaderIcon = {
  iconName: string;
  title?: string;
  onClick: () => void;
};

export interface IJxtOffCanvasHeader {
  title?: string | ReactNode;
  subtitle?: string;
  hideIcon?: JxtOffCanvasHeaderHideIconEnum;
  onHide?: () => void;
  iconList?: JxtOffCanvaHeaderIcon[];
  children?: ReactNode;
}
