import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  ChooseWorkplaceOverlayContext,
  IChooseWorkplaceOverlayContext,
  JxtButton,
  JxtToast,
  JxtToastVariantEnum,
  TUserChooseWorkplaceModalInfos,
} from '@jooxter/ui';
import toast from 'react-hot-toast';
import {
  DataPeriodEnum,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  getStandardTime,
  IJxtWorkplaceDay,
  IJxtWorkplaceHalfDay,
  ISOToLocalDate,
  ISOToLocalTime,
  JxtWorkplaceStatusButtonTypeEnum,
  JxtWorkplaceTypeEnum,
  MID_DAY,
  toISO,
} from '@jooxter/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModalManageBookingsContext } from './ModalManageBookingsContext';
import { DateTime } from 'luxon';
import { useCreateAutomaticBooking } from '../components/JxtWorkplaceCalendarContainer/hooks';
import { getDeskOrParkingResourceTypes } from '../utils/resource-types';
import {
  Booking,
  BookingCompressed,
  BookingRequest,
  RecurrenceDto,
  WorkplaceDto,
  WorkplaceRequestDtoTypeEnum,
} from '@jooxter/api';
import JxtWorkplaceSelector from '../components/JxtWorkplaceSelector';
import { useWorkplaceActions } from './workplaces/useWorkplaceActions';
import { useTranslation } from 'react-i18next';
import {
  useFetchExistingBookings,
  useFetchLocation,
  useFetchUserById,
  useFetchUserPreferences,
  useInvalidateBookingQueries,
  useInvalidateWorkplaceQueries,
} from '../queries';
import { uniq } from 'lodash-es';
import { getExistingBookingsOverlappingRange, shouldPostAutomaticBooking } from '../utils/bookings';
import { useBookingActions } from './bookings';

const dateFormat = 'yyyy-MM-dd';

interface IChooseWorkplaceOverlayProvider {
  children: React.ReactNode;
  onToastActionClicked: (id: number) => void;
}

export const ChooseWorkplaceOverlayProvider = ({ children, onToastActionClicked }: IChooseWorkplaceOverlayProvider) => {
  const { t } = useTranslation();
  // beware that useState can take a function as a parameter with the previous state, we don't want that here
  // ie: useState(() => {}) would make onClose undefined because it could be writter useState((previousState) => {// expect to return a value here})
  const [onClose, setOnClose] = useState<() => void>(() => {
    return () => {
      // noop
    };
  });
  const [date, setDate] = useState<DateTime>();
  const [userInfos, setUserInfos] = useState<TUserChooseWorkplaceModalInfos>();
  const { user } = useFetchUserById(userInfos?.id);
  const { preferences } = useFetchUserPreferences(userInfos?.id);
  const [disabledMorning, setDisabledMorning] = useState<boolean>(false);
  const [disabledAfternoon, setDisabledAfternoon] = useState<boolean>(false);
  const [originalMorningType, setOriginalMorningType] = useState<JxtWorkplaceTypeEnum>();
  const [originalAfternoonType, setOriginalAfternoonType] = useState<JxtWorkplaceTypeEnum>();
  const [originalMorningLocation, setOriginalMorningLocation] = useState<number | undefined>();
  const [originalAfternoonLocation, setOriginalAfternoonLocation] = useState<number | undefined>();
  const [morningStatusType, setMorningStatusType] = useState<JxtWorkplaceTypeEnum | undefined>(originalMorningType);
  const [afternoonStatusType, setAfternoonStatusType] = useState<JxtWorkplaceTypeEnum | undefined>(
    originalAfternoonType
  );
  const [morningLocationId, setMorningLocationId] = useState<number | undefined>(originalMorningLocation);
  const { location: morningLocation } = useFetchLocation(morningLocationId);
  const [afternoonLocationId, setAfternoonLocationId] = useState<number | undefined>(originalAfternoonLocation);
  const { location: afternoonLocation } = useFetchLocation(afternoonLocationId);
  const [halfDay, setHalfDay] = useState<boolean>(
    originalMorningType !== originalAfternoonType || originalMorningLocation !== originalAfternoonLocation
  );
  const workplacesHaveNotChanged = useMemo(
    () =>
      morningStatusType === originalMorningType &&
      morningLocationId === originalMorningLocation &&
      afternoonStatusType === originalAfternoonType &&
      afternoonLocationId === originalAfternoonLocation,
    [
      morningStatusType,
      originalMorningType,
      morningLocationId,
      originalMorningLocation,
      afternoonStatusType,
      afternoonLocationId,
      originalAfternoonType,
      originalAfternoonLocation,
    ]
  );
  const morningAndAfternoonWorkplacesAreTheSame = useMemo(
    () => morningStatusType === afternoonStatusType && morningLocationId === afternoonLocationId,
    [morningStatusType, afternoonStatusType, morningLocationId, afternoonLocationId]
  );
  const onlyTheAfternoonWorkplaceHasChanged = useMemo(
    () => morningStatusType === originalMorningType && morningLocationId === originalMorningLocation,
    [morningStatusType, originalMorningType, morningLocationId, originalMorningLocation]
  );
  const onlyTheMorningWorkplaceHasChanged = useMemo(
    () => afternoonStatusType === originalAfternoonType && afternoonLocationId === originalAfternoonLocation,
    [originalAfternoonType, afternoonStatusType, originalAfternoonLocation, afternoonLocationId]
  );
  const modalManageBookingsContext = useModalManageBookingsContext();
  const dateString = date ? toISO(date) : '';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const range = useMemo(() => (date ? { from: date.startOf('day'), to: date.endOf('day') } : undefined), [dateString]);
  const { createAutomaticBooking, resourceTypes } = useCreateAutomaticBooking(user, preferences);
  const deskOrParkingResourceTypes = useMemo(() => getDeskOrParkingResourceTypes(resourceTypes), [resourceTypes]);
  // Get all bookings of the month
  const { bookings } = useFetchExistingBookings(deskOrParkingResourceTypes, range, user);
  const { invalidateBookingQueries } = useInvalidateBookingQueries();
  const { invalidateWorkplaceQueries } = useInvalidateWorkplaceQueries();
  const {
    create: createWorkplace,
    createMany: createWorkplaceMany,
    deleteMany: deleteWorkplaces,
  } = useWorkplaceActions();
  const { create: createBooking } = useBookingActions();
  const defaultLocation = preferences?.location
    ? { id: preferences.location.id, name: preferences.location.name }
    : undefined;

  const initProvider = useCallback(
    (onClose: () => void, workplaceDay: IJxtWorkplaceDay, disabledMorning = false, disabledAfternoon = false) => {
      setDate(workplaceDay.date);
      setOnClose(() => onClose);
      setDisabledMorning(disabledMorning);
      setDisabledAfternoon(disabledAfternoon);
      const originalMorning = workplaceDay.morning.type;
      const originalAfternoon = workplaceDay.afternoon.type;
      setOriginalMorningType(originalMorning);
      setMorningStatusType(originalMorning);
      setOriginalAfternoonType(originalAfternoon);
      setAfternoonStatusType(originalAfternoon);
      setOriginalMorningLocation(workplaceDay.morning.locationId);
      setOriginalAfternoonLocation(workplaceDay.afternoon.locationId);
      setMorningLocationId(workplaceDay.morning.locationId);
      setAfternoonLocationId(workplaceDay.afternoon.locationId);
      setHalfDay(
        originalMorning !== originalAfternoon || workplaceDay.morning.locationId !== workplaceDay.afternoon.locationId
      );
    },
    []
  );

  const cleanupProvider = useCallback(() => {
    setOriginalMorningType(undefined);
    setOriginalAfternoonType(undefined);
    setMorningStatusType(undefined);
    setAfternoonStatusType(undefined);
    setOriginalMorningLocation(undefined);
    setOriginalAfternoonLocation(undefined);
    setMorningLocationId(undefined);
    setAfternoonLocationId(undefined);
  }, []);

  const onBookingSuccess = (booking: Booking | RecurrenceDto) => {
    invalidateBookingQueries([booking.id]);
    invalidateWorkplaceQueries();
    const start = DateTime.fromISO(booking.start.dateTime, { zone: booking.resource.timezone });
    const end = DateTime.fromISO(booking.end.dateTime, { zone: booking.resource.timezone });
    const startTime = getStandardTime(start);
    const endTime = getStandardTime(end);

    toast.custom(
      (newToast) => (
        <JxtToast
          onHide={() => toast.remove(newToast.id)}
          title={t<string>('booking-created')}
          subtitle={t<string>('booking.favorite-desk.success', {
            date: start.toLocaleString(DateTime.DATE_FULL),
            startTime,
            endTime,
          })}
          variant={JxtToastVariantEnum.Success}
        >
          <JxtButton
            className="!p-0"
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => {
              onToastActionClicked(booking.id);
              toast.remove(newToast.id);
            }}
          >
            {t('open-booking')}
          </JxtButton>
        </JxtToast>
      ),
      {
        position: 'bottom-left',
      }
    );
  };

  const onBookingError = (_: unknown, booking: BookingRequest) => {
    const startDate = ISOToLocalDate(booking.start.dateTime, preferences?.resource?.timezone);
    const startTime = ISOToLocalTime(booking.start.dateTime, preferences?.resource?.timezone);
    const endTime = ISOToLocalTime(booking.end.dateTime, preferences?.resource?.timezone);

    toast.custom(
      (newToast) => (
        <JxtToast
          onHide={() => toast.remove(newToast.id)}
          title={t<string>('booking.favorite-desk.error', {
            date: startDate,
            startTime,
            endTime,
          })}
          variant={JxtToastVariantEnum.Failure}
        />
      ),
      {
        position: 'bottom-left',
      }
    );
  };

  const onCreateWorkplaceSuccess = (workplace: WorkplaceDto) => {
    invalidateWorkplaceQueries();
    const automaticBooking = createAutomaticBooking(workplace.start, workplace.end);

    if (automaticBooking && shouldPostAutomaticBooking(workplace, automaticBooking, bookings, preferences)) {
      createBooking(automaticBooking, onBookingSuccess, onBookingError);
    }
  };

  const onCreateWorkplacesSuccess = (workplaces: WorkplaceDto[]) => {
    workplaces.forEach((workplace) => {
      const automaticBooking = createAutomaticBooking(workplace.start, workplace.end);

      if (automaticBooking && shouldPostAutomaticBooking(workplace, automaticBooking, bookings, preferences)) {
        createBooking(automaticBooking, onBookingSuccess, onBookingError);
      }
    });
  };

  const createUniqueWorkplace = useCallback(
    (date: string, period: DataPeriodEnum) => {
      if (!modalManageBookingsContext?.getters || !modalManageBookingsContext?.setters) {
        return;
      }
      if (!user?.id) {
        return;
      }

      modalManageBookingsContext?.setters?.setExistingBookings([]);
      modalManageBookingsContext?.setters?.setPeriodClicked(period);
      modalManageBookingsContext?.setters?.setTitle(t('manage-closing.what-to-do-when-out-of-office'));
      const start = DateTime.fromFormat(date, dateFormat)
        .set({ hour: [DataPeriodEnum.Am, DataPeriodEnum.Day].includes(period) ? DEFAULT_START_TIME : MID_DAY })
        .toUTC()
        .toISO();
      const end = DateTime.fromFormat(date, dateFormat)
        .set({ hour: period === DataPeriodEnum.Am ? MID_DAY : DEFAULT_END_TIME })
        .toUTC()
        .toISO();

      if (start && end) {
        const from = DateTime.fromISO(start);
        const to = DateTime.fromISO(end);
        const tmpExistingBookings = getExistingBookingsOverlappingRange({ from, to }, bookings);
        const statusType = period === DataPeriodEnum.Am ? morningStatusType : afternoonStatusType;
        const location = period === DataPeriodEnum.Am ? morningLocationId : afternoonLocationId;

        if (statusType !== JxtWorkplaceTypeEnum.Office && tmpExistingBookings.length) {
          modalManageBookingsContext?.setters?.setMutationToExecuteAfterConfirmation([
            ...(modalManageBookingsContext?.getters?.mutationToExecuteAfterConfirmation ?? []),
            {
              mutation: createWorkplace.mutateAsync,
              args: {
                userId: user.id,
                workplace: {
                  start,
                  end,
                  type: statusType as unknown as WorkplaceRequestDtoTypeEnum,
                },
              },
            },
          ]);
          modalManageBookingsContext?.setters?.showDeleteBookingsModal([
            ...(modalManageBookingsContext?.getters?.bookings ?? []),
            ...tmpExistingBookings,
          ]);
        } else {
          createWorkplace.mutateAsync(
            {
              userId: user.id,
              workplace: {
                start,
                end,
                type: statusType as unknown as WorkplaceRequestDtoTypeEnum,
                locationId: location,
              },
            },
            { onSuccess: onCreateWorkplaceSuccess }
          );
          onClose();
        }
      }
    },
    [
      afternoonLocationId,
      afternoonStatusType,
      createWorkplace.mutateAsync,
      getExistingBookingsOverlappingRange,
      modalManageBookingsContext?.getters,
      modalManageBookingsContext?.setters,
      morningLocationId,
      morningStatusType,
      user?.id,
      onClose,
      bookings,
    ]
  );

  const createMultipleWorkplaces = useCallback(
    (date: string, period: DataPeriodEnum) => {
      if (!modalManageBookingsContext?.getters || !modalManageBookingsContext?.setters) {
        return;
      }
      if (!user?.id) {
        return;
      }
      modalManageBookingsContext?.setters?.setExistingBookings([]);
      modalManageBookingsContext?.setters?.setPeriodClicked(period ?? DataPeriodEnum.Day);
      modalManageBookingsContext?.setters?.setTitle(t('manage-closing.what-to-do-when-out-of-office'));
      const startDay = DateTime.fromFormat(date, dateFormat).set({ hour: DEFAULT_START_TIME }).toUTC().toISO();
      const midDay = DateTime.fromFormat(date, dateFormat).set({ hour: MID_DAY }).toUTC().toISO();
      const endDay = DateTime.fromFormat(date, dateFormat).set({ hour: DEFAULT_END_TIME }).toUTC().toISO();

      if (startDay && midDay && endDay) {
        let tmpExistingBookings: BookingCompressed[] | undefined = undefined;
        if (
          originalMorningType === JxtWorkplaceTypeEnum.Office &&
          originalAfternoonType === JxtWorkplaceTypeEnum.Office
        ) {
          tmpExistingBookings = getExistingBookingsOverlappingRange(
            {
              from: DateTime.fromISO(startDay),
              to: DateTime.fromISO(endDay),
            },
            bookings
          );
        } else if (
          originalMorningType === JxtWorkplaceTypeEnum.Office &&
          morningStatusType !== JxtWorkplaceTypeEnum.Office
        ) {
          tmpExistingBookings = getExistingBookingsOverlappingRange(
            {
              from: DateTime.fromISO(startDay),
              to: DateTime.fromISO(midDay),
            },
            bookings
          );
        } else if (
          originalAfternoonType === JxtWorkplaceTypeEnum.Office &&
          afternoonStatusType !== JxtWorkplaceTypeEnum.Office
        ) {
          tmpExistingBookings = getExistingBookingsOverlappingRange(
            {
              from: DateTime.fromISO(midDay),
              to: DateTime.fromISO(endDay),
            },
            bookings
          );
        }

        const newWorkplaces =
          morningStatusType === JxtWorkplaceTypeEnum.Unknown
            ? [
                {
                  start: midDay,
                  end: endDay,
                  type: afternoonStatusType as unknown as WorkplaceRequestDtoTypeEnum,
                  locationId: afternoonLocationId,
                },
              ]
            : afternoonStatusType === JxtWorkplaceTypeEnum.Unknown
              ? [
                  {
                    start: startDay,
                    end: midDay,
                    type: morningStatusType as unknown as WorkplaceRequestDtoTypeEnum,
                    locationId: morningLocationId,
                  },
                ]
              : [
                  {
                    start: startDay,
                    end: midDay,
                    type: morningStatusType as unknown as WorkplaceRequestDtoTypeEnum,
                    locationId: morningLocationId,
                  },
                  {
                    start: midDay,
                    end: endDay,
                    type: afternoonStatusType as unknown as WorkplaceRequestDtoTypeEnum,
                    locationId: afternoonLocationId,
                  },
                ];

        if (
          (originalMorningType === JxtWorkplaceTypeEnum.Office ||
            originalAfternoonType === JxtWorkplaceTypeEnum.Office) &&
          tmpExistingBookings?.length
        ) {
          modalManageBookingsContext.setters.setExistingBookings([
            ...modalManageBookingsContext.getters.bookings,
            ...tmpExistingBookings,
          ]);
          modalManageBookingsContext.setters.setMutationToExecuteAfterConfirmation(
            {
              mutation: createWorkplaceMany.mutateAsync,
              args: {
                userId: user.id,
                workplaces: newWorkplaces,
              },
            },
            { onSuccess: onCreateWorkplacesSuccess, onSettled: invalidateWorkplaceQueries }
          );
          modalManageBookingsContext?.setters?.showDeleteBookingsModal([
            ...(modalManageBookingsContext?.getters?.bookings ?? []),
            ...tmpExistingBookings,
          ]);
        } else {
          createWorkplaceMany
            .mutateAsync(
              {
                userId: user.id,
                workplaces: newWorkplaces,
              },
              { onSuccess: onCreateWorkplacesSuccess, onSettled: invalidateWorkplaceQueries }
            )
            .then(invalidateWorkplaceQueries);
          onClose();
        }
      }
    },
    [
      afternoonLocationId,
      afternoonStatusType,
      createWorkplaceMany.mutateAsync,
      getExistingBookingsOverlappingRange,
      modalManageBookingsContext?.getters,
      modalManageBookingsContext?.setters,
      morningLocationId,
      morningStatusType,
      originalAfternoonType,
      originalMorningType,
      user?.id,
      onClose,
      bookings,
    ]
  );

  const deleteMultipleWorkplaces = useCallback(
    (date: string, workplaceIds: number[]) => {
      if (!user?.id) {
        return;
      }
      const start = DateTime.fromFormat(date, dateFormat).set({ hour: DEFAULT_START_TIME }).toUTC().toISO();
      const end = DateTime.fromFormat(date, dateFormat).set({ hour: DEFAULT_END_TIME }).toUTC().toISO();

      if (start && end) {
        const from = DateTime.fromISO(start);
        const to = DateTime.fromISO(end);
        const tmpExistingBookings = getExistingBookingsOverlappingRange({ from, to }, bookings);

        if (tmpExistingBookings.length) {
          modalManageBookingsContext?.setters.setPeriodClicked(DataPeriodEnum.Day);
          modalManageBookingsContext?.setters.setTitle(t('manage-closing.what-to-do-when-out-of-office'));
          modalManageBookingsContext?.setters.setMutationToExecuteAfterConfirmation([
            {
              mutation: deleteWorkplaces,
              args: { userId: user.id, ids: workplaceIds },
            },
          ]);
          modalManageBookingsContext?.setters?.showDeleteBookingsModal(tmpExistingBookings);
        } else {
          deleteWorkplaces({ userId: user.id, ids: workplaceIds });
          onClose();
        }
      }
    },
    [user?.id, modalManageBookingsContext, getExistingBookingsOverlappingRange, deleteWorkplaces, onClose]
  );

  const onReset = useCallback(
    (morning: IJxtWorkplaceHalfDay, afternoon: IJxtWorkplaceHalfDay) => {
      if (!date) {
        return;
      }

      const ids = uniq([morning, afternoon].map((w) => w?.id).filter((i): i is number => !!i));
      deleteMultipleWorkplaces(date.toFormat(dateFormat), ids);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateString, deleteMultipleWorkplaces]
  );

  const onSubmit = useCallback(() => {
    if (!date) {
      return;
    }

    if (workplacesHaveNotChanged) {
      onClose();
    } else if (morningAndAfternoonWorkplacesAreTheSame) {
      createUniqueWorkplace(date.toFormat(dateFormat), DataPeriodEnum.Day);
    } else if (onlyTheAfternoonWorkplaceHasChanged) {
      createMultipleWorkplaces(date.toFormat(dateFormat), DataPeriodEnum.Pm);
    } else if (onlyTheMorningWorkplaceHasChanged) {
      createMultipleWorkplaces(date.toFormat(dateFormat), DataPeriodEnum.Am);
    } else {
      createMultipleWorkplaces(date.toFormat(dateFormat), DataPeriodEnum.Day);
    }
  }, [
    dateString,
    workplacesHaveNotChanged,
    morningAndAfternoonWorkplacesAreTheSame,
    onlyTheAfternoonWorkplaceHasChanged,
    onlyTheMorningWorkplaceHasChanged,
    createUniqueWorkplace,
    createMultipleWorkplaces,
    onClose,
  ]);

  useEffect(() => {
    if (morningStatusType === JxtWorkplaceTypeEnum.Office) {
      setMorningLocationId(originalMorningLocation ?? preferences?.location?.id);
    } else {
      setMorningLocationId(undefined);
    }
  }, [morningStatusType]);

  useEffect(() => {
    if (afternoonStatusType === JxtWorkplaceTypeEnum.Office) {
      setAfternoonLocationId(originalAfternoonLocation ?? preferences?.location?.id);
    } else {
      setAfternoonLocationId(undefined);
    }
  }, [afternoonStatusType]);

  useEffect(() => {
    if (!halfDay) {
      setAfternoonStatusType(morningStatusType);
      setAfternoonLocationId(morningLocationId);
    }
  }, [morningStatusType, morningLocationId, halfDay]);

  const value: IChooseWorkplaceOverlayContext = useMemo(
    () => ({
      methods: {
        onReset,
        onSubmit,
        initProvider,
        cleanupProvider,
      },
      setters: {
        setHalfDay,
        setUserInfos,
        setMorningStatusType,
        setMorningLocation: setMorningLocationId,
        setAfternoonStatusType,
        setAfternoonLocation: setAfternoonLocationId,
      },
      getters: {
        halfDay,
        userInfos,
        morningStatusType,
        morningLocation: morningLocationId,
        afternoonStatusType,
        afternoonLocation: afternoonLocationId,
      },
      components: {
        morningWorkplaceSelector: (
          <JxtWorkplaceSelector
            statusType={morningStatusType as unknown as JxtWorkplaceStatusButtonTypeEnum}
            onChangeStatusType={(e) => setMorningStatusType(e as unknown as JxtWorkplaceTypeEnum)}
            defaultLocation={morningLocationId ? morningLocation : defaultLocation}
            onChangeLocation={(l: { id?: number }) => l.id && setMorningLocationId(l.id)}
            disabled={disabledMorning}
          />
        ),
        afternoonWorkplaceSelector: (
          <JxtWorkplaceSelector
            statusType={afternoonStatusType as unknown as JxtWorkplaceStatusButtonTypeEnum}
            onChangeStatusType={(e) => setAfternoonStatusType(e as unknown as JxtWorkplaceTypeEnum)}
            defaultLocation={afternoonLocationId ? afternoonLocation : defaultLocation}
            onChangeLocation={(l: { id?: number }) => l.id && setAfternoonLocationId(l.id)}
            disabled={disabledAfternoon}
          />
        ),
      },
    }),
    [
      onReset,
      onSubmit,
      initProvider,
      cleanupProvider,
      halfDay,
      userInfos,
      morningStatusType,
      morningLocationId,
      afternoonStatusType,
      afternoonLocationId,
      disabledMorning,
      disabledAfternoon,
      morningLocation,
      afternoonLocation,
      defaultLocation,
    ]
  );

  return <ChooseWorkplaceOverlayContext.Provider value={value}>{children}</ChooseWorkplaceOverlayContext.Provider>;
};
