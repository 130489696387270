import { getThemeColorHex } from '@jooxter/tailwind-config';
import { IJxtStar, JxtStarSizeEnum, JxtStarTypeEnum } from './types';
import clsx from 'clsx';
import { MouseEvent } from 'react';

const starSizeMapping = {
  [JxtStarSizeEnum.Small]: {
    size: 28,
    path: 'M14.875 6.5625C14.7188 6.21875 14.375 6 14 6C13.5938 6 13.25 6.21875 13.0938 6.5625L11.0938 10.7188L6.59375 11.375C6.21875 11.4375 5.90625 11.6875 5.78125 12.0625C5.6875 12.4062 5.78125 12.8125 6.03125 13.0625L9.28125 16.2812L8.53125 20.8438C8.46875 21.2188 8.625 21.5938 8.9375 21.8125C9.25 22.0625 9.65625 22.0625 9.96875 21.9062L14 19.75L18 21.9062C18.3438 22.0625 18.75 22.0625 19.0625 21.8125C19.375 21.5938 19.5312 21.2188 19.4688 20.8438L18.6875 16.2812L21.9375 13.0625C22.2188 12.8125 22.3125 12.4062 22.1875 12.0625C22.0625 11.6875 21.75 11.4375 21.375 11.375L16.9062 10.7188L14.875 6.5625Z',
  },
  [JxtStarSizeEnum.Medium]: {
    size: 36,
    path: 'M19.0938 8.70312C18.8984 8.27344 18.4688 8 18 8C17.4922 8 17.0625 8.27344 16.8672 8.70312L14.3672 13.8984L8.74219 14.7188C8.27344 14.7969 7.88281 15.1094 7.72656 15.5781C7.60938 16.0078 7.72656 16.5156 8.03906 16.8281L12.1016 20.8516L11.1641 26.5547C11.0859 27.0234 11.2812 27.4922 11.6719 27.7656C12.0625 28.0781 12.5703 28.0781 12.9609 27.8828L18 25.1875L23 27.8828C23.4297 28.0781 23.9375 28.0781 24.3281 27.7656C24.7188 27.4922 24.9141 27.0234 24.8359 26.5547L23.8594 20.8516L27.9219 16.8281C28.2734 16.5156 28.3906 16.0078 28.2344 15.5781C28.0781 15.1094 27.6875 14.7969 27.2188 14.7188L21.6328 13.8984L19.0938 8.70312Z',
  },
  [JxtStarSizeEnum.Large]: {
    size: 40,
    path: 'M21.0938 10.7031C20.8984 10.2734 20.4688 10 20 10C19.4922 10 19.0625 10.2734 18.8672 10.7031L16.3672 15.8984L10.7422 16.7188C10.2734 16.7969 9.88281 17.1094 9.72656 17.5781C9.60938 18.0078 9.72656 18.5156 10.0391 18.8281L14.1016 22.8516L13.1641 28.5547C13.0859 29.0234 13.2812 29.4922 13.6719 29.7656C14.0625 30.0781 14.5703 30.0781 14.9609 29.8828L20 27.1875L25 29.8828C25.4297 30.0781 25.9375 30.0781 26.3281 29.7656C26.7188 29.4922 26.9141 29.0234 26.8359 28.5547L25.8594 22.8516L29.9219 18.8281C30.2734 18.5156 30.3906 18.0078 30.2344 17.5781C30.0781 17.1094 29.6875 16.7969 29.2188 16.7188L23.6328 15.8984L21.0938 10.7031Z',
  },
};

const JxtStar = ({ type, size = JxtStarSizeEnum.Medium, checked = false, disabled = false, onClick }: IJxtStar) => {
  const { size: svgSize, path } = starSizeMapping[size];
  const circleClass = type === JxtStarTypeEnum.Filled ? 'fill-neutral-10' : 'invisible group-hover:visible';

  const handleClick = (event: MouseEvent<SVGSVGElement>) => {
    if (onClick) {
      onClick(!checked);
    }
    stopEvent(event);
  };

  const stopEvent = (event: MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <svg
      className={clsx('group', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
      width={svgSize}
      height={svgSize}
      viewBox={`0 0 ${svgSize} ${svgSize}`}
      xmlns="http://www.w3.org/2000/svg"
      onClick={disabled ? (event) => stopEvent(event) : (event) => handleClick(event)}
    >
      <circle
        className={clsx(disabled ? 'group-hover:fill-none' : 'group-hover:fill-neutral-20', circleClass)}
        r={svgSize / 2}
        cx={svgSize / 2}
        cy={svgSize / 2}
      />
      <path
        textAnchor="middle"
        alignmentBaseline="central"
        d={path}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={checked ? getThemeColorHex(disabled ? 'orange40' : 'orange100') : 'none'}
        stroke={getThemeColorHex(
          checked ? (disabled ? 'orange40' : 'orange100') : disabled ? 'neutral40' : 'neutral120'
        )}
      />
    </svg>
  );
};

export default JxtStar;
