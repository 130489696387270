import { useTranslation } from 'react-i18next';
import JxtOnboardingLayout from '../../../layouts/OnboardingLayout';
import { ButtonSizeEnum, ButtonVariantEnum, JxtButton } from '@jooxter/ui';
import { useNavigate } from 'react-router';
import {
  JxtResourceSelect,
  preferencesToPreferencesRequestAdapter,
  useFetchResourceTypes,
  useFetchUser,
  useFetchUserPreferences,
  useMutatePutPreferences,
} from '@jooxter/core';
import { ResourceCompressed, ResourceTypeDtoMetaTypeEnum } from '@jooxter/api';
import { useOnboardingNavigation } from '../hooks';
import { OnboardingPagesEnum } from '../types';
import { useState } from 'react';
import { createGTMGAEvent } from '@jooxter/utils';

export const OnboardingDesk = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useFetchUser();
  const { getPrevLink, getNextLink } = useOnboardingNavigation(OnboardingPagesEnum.DESK);
  const { preferences } = useFetchUserPreferences(user?.id);
  const { resourceTypes } = useFetchResourceTypes({ metaTypes: [ResourceTypeDtoMetaTypeEnum.Desk] });
  const { mutate: mutatePutPreferences } = useMutatePutPreferences(user?.id);
  const [shouldTriggerGTM, setShouldTriggerGTM] = useState(false);

  if (!preferences?.location?.id) {
    return null;
  }

  const onResourceChange = (resource: ResourceCompressed | null) => {
    if (resource?.id === preferences.resource?.id || !user?.id) {
      return;
    }
    setShouldTriggerGTM(true);
    const { preferencesRequest } = preferencesToPreferencesRequestAdapter(preferences);
    if (preferencesRequest) {
      preferencesRequest.resource = resource?.id;
      mutatePutPreferences(preferencesRequest);
    }
  };

  const handleClickRouteButton = (route: string) => {
    if (shouldTriggerGTM) {
      createGTMGAEvent('Onboarding', 'Favorite Desk', 'Set favorite desk');
    }
    navigate(route);
  };

  return (
    <JxtOnboardingLayout
      title={t<string>('onboarding.desk.title')}
      instructions={t<string>('onboarding.desk.explanation')}
    >
      <div className="flex flex-col gap-6">
        <JxtResourceSelect
          placeholder={t<string>('onboarding.desk.placeholder')}
          noOptionsMessage={t<string>('user-update-profile-building.no-options')}
          defaultValue={
            preferences.resource?.id && preferences.resource?.name
              ? { value: preferences.resource.id, label: preferences.resource.name }
              : undefined
          }
          searchOptions={{
            locationId: [preferences.location.id],
            resourceTypeId: resourceTypes?.map((resourceType) => resourceType.id),
            bookable: true,
          }}
          onResourceSelect={(value) => onResourceChange(value)}
          isClearable
        />
        <div className="flex justify-between">
          <JxtButton
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Link}
            onClick={() => handleClickRouteButton(getPrevLink())}
          >
            {t('previous')}
          </JxtButton>
          <JxtButton size={ButtonSizeEnum.Large} onClick={() => handleClickRouteButton(getNextLink())}>
            {t('next')}
          </JxtButton>
        </div>
      </div>
    </JxtOnboardingLayout>
  );
};
