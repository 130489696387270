import { useCallback, useMemo } from 'react';
import { Matrix } from '@jooxter/ui/src/components/JxtFloorPlan/types';
import { IVector2D } from './types';
import { parseRawMatrix } from '../helpers';

export const useFloorMatrix = (rawMatrix: string) => {
  const matrix = useMemo(() => parseRawMatrix(rawMatrix), [rawMatrix]);

  const locateResourceId = useCallback<(resId: number) => IVector2D | undefined>(
    (resId: number): IVector2D | undefined => {
      //  Return the last matching occurrence
      let pos: IVector2D | undefined;
      let isDevice = false;

      if (!matrix?.length) {
        return void 0;
      }

      matrix.forEach((row, rowIndex) => {
        row.forEach((col: any, colIndex: any) => {
          if (typeof col === 'object' && 'device' in col && col.device.idResource === resId) {
            isDevice = true;
            pos = { x: colIndex, y: rowIndex };
          }

          if (typeof col === 'object' && 'resource' in col && !isDevice && parseInt(col.resource.id, 10) === resId) {
            pos = { x: colIndex, y: rowIndex };
          }
        });
      });

      return pos;
    },
    [matrix]
  );

  const get = useCallback(
    (coords: IVector2D): Matrix[number][number] | undefined => {
      if (!matrix?.length) {
        return void 0;
      }

      const row = matrix[coords.y];

      if (!row) {
        return undefined;
      }

      return row[coords.x];
    },
    [matrix]
  );

  return { matrix, locateResourceId, get };
};
