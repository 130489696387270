import {
  DataPeriodEnum,
  disableIJxtWorkplaceHalfDay,
  goToNextMondayIf,
  IJxtWorkplaceHalfDay,
  now,
  sameDay,
} from '@jooxter/utils';
import clsx from 'clsx';
import { capitalize } from 'lodash-es';
import { DateTime, Info, Interval, WeekdayNumbers } from 'luxon';
import { ReactElement, useCallback, useContext, useMemo } from 'react';
import JxtShareWorkplaceDay from '../JxtShareWorkplaceDay';
import { IJxtShareWorkplaceDay } from '../JxtShareWorkplaceDay/types';
import JxtShareWorkplaceRange from '../JxtShareWorkplaceRange';
import { JxtWorkplaceCalendarDayHeaderStateEnum } from '../JxtWorkplaceCalendarDayHeader/types';
// eslint-disable-next-line import/named
import { EventInput } from '@fullcalendar/core';
import { WorkplaceCalendarContext } from './context';

export const usePaintCalendarBuilder = (events: EventInput[], date: DateTime) => {
  const year = date.year;
  const month = date.month;
  const { callbacks } = useContext(WorkplaceCalendarContext);

  const getDates = useCallback((): ReactElement => {
    // 5 days * 6 weeks + 1 for the empty cell
    const gridsize = 31;
    // Day of the week on which the month starts
    const firstDay = DateTime.fromObject({ year, month }).weekday;
    // Total number of days in the month
    const totalDaysInMonth = DateTime.fromObject({ year, month }).daysInMonth;
    // keep track of total cells filled to know if we need to next month cells
    let filledCells = 0;

    // Total number of days in the previous month
    let totalDaysInPrevMonth: number | undefined;

    if (month === 1) {
      totalDaysInPrevMonth = DateTime.fromObject({ year: year - 1, month: 12 }).daysInMonth;
    } else {
      totalDaysInPrevMonth = DateTime.fromObject({ year, month: month - 1 }).daysInMonth;
    }

    if (!(totalDaysInPrevMonth && totalDaysInMonth)) {
      throw new Error('No days in prev month or in current month');
    }

    // Days from prev month to show in the grid
    const prevMonthDates = [];

    if (![6, 7].includes(firstDay)) {
      for (let i = 1; i <= firstDay; i++) {
        const prevMonthDate = totalDaysInPrevMonth - firstDay + i;

        let tmpDate: DateTime;

        if (month === 1) {
          tmpDate = DateTime.fromObject({ year: year - 1, month: 12, day: prevMonthDate });
        } else {
          tmpDate = DateTime.fromObject({ year, month: month - 1, day: prevMonthDate });
        }

        if ([6, 7].includes(tmpDate.weekday)) {
          continue;
        }

        prevMonthDates.push({
          dataDate: tmpDate.toISODate(),
        });
        filledCells++;
      }
    }

    // Days of the current month to show in the grid
    const currentMonthDates: IJxtShareWorkplaceDay[] = [];
    for (let i = 1; i <= totalDaysInMonth; i++) {
      const date = DateTime.fromObject({ year, month, day: i }).startOf('day');
      const event = events.find((e) => sameDay(e.def?.extendedProps?.date, date));

      if ([6, 7].includes(date.weekday)) {
        continue;
      }

      const morningWorkplace = event?.def?.extendedProps?.morning as IJxtWorkplaceHalfDay;
      const afternoonWorkplace = event?.def?.extendedProps?.afternoon as IJxtWorkplaceHalfDay;
      const isDisabledMorning = morningWorkplace ? disableIJxtWorkplaceHalfDay(morningWorkplace) : true;
      const isDisabledAfternoon = afternoonWorkplace ? disableIJxtWorkplaceHalfDay(afternoonWorkplace) : true;
      const isActive = date.hasSame(now(), 'day');
      const state =
        isDisabledMorning && isDisabledAfternoon
          ? JxtWorkplaceCalendarDayHeaderStateEnum.Disabled
          : isActive
            ? JxtWorkplaceCalendarDayHeaderStateEnum.Active
            : JxtWorkplaceCalendarDayHeaderStateEnum.Default;

      const result: IJxtShareWorkplaceDay = {
        dataDate: date.toISODate(),
        isCurrent: true,
        header: {
          text: i < 10 ? '0' + i.toString() : i.toString(),
          dataDate: date.toISODate(),
          state,
        },
        morning: {
          dataPeriod: DataPeriodEnum.Am,
          type: event?.def?.extendedProps?.morning?.type,
          disabled: isDisabledMorning,
        },
        afternoon: {
          dataPeriod: DataPeriodEnum.Pm,
          type: event?.def?.extendedProps?.afternoon?.type,
          disabled: isDisabledAfternoon,
        },
      };

      currentMonthDates.push(result);
      filledCells++;
    }

    // If there is space left over in the grid, then show the dates for the next month
    const nextMonthDates = [];
    if ((gridsize % filledCells) - 5 > 0 && (gridsize % filledCells) - 5 < 5) {
      const count = (gridsize % filledCells) - 5;

      for (let i = 1; i <= count; i++) {
        const date = DateTime.fromObject({ year, month: month + 1, day: i });

        if ([0, 6].includes(date.day)) {
          continue;
        }

        nextMonthDates.push({
          dataDate: date.toISODate(),
        });
      }
    }

    return (
      <>
        <div className="empty-cell" />
        {prevMonthDates.map((prevMonthDate, i) => (
          <div key={`prev-month-${i}`} className="prev" data-date={prevMonthDate.dataDate} />
        ))}
        {currentMonthDates.map((date, i) => (
          <JxtShareWorkplaceDay key={`current-month-${i}`} {...date} />
        ))}
        {nextMonthDates.map((nextMonthDate, i) => (
          <div key={`next-month-${i}`} className="next" data-date={nextMonthDate.dataDate} />
        ))}
      </>
    );
  }, [events, year, month]);

  const getHeaders = (): ReactElement[] => {
    const daysOfWeek = Info.weekdays('short').slice(0, 5);

    return daysOfWeek.map((day, i) => {
      const dow = (i + 1) as WeekdayNumbers;

      return (
        <JxtShareWorkplaceRange
          key={`day-header-${i}`}
          text={capitalize(day).replace('.', '')}
          className={`calendar-grid-header day-${dow}`}
          onClick={() => callbacks.onJxtShareWorkplaceRangeDayClick(dow, date.startOf('month'))}
        />
      );
    });
  };

  const getPeriodSelectors = useCallback((): ReactElement => {
    let firstDay = DateTime.fromObject({ year, month }).startOf('month');
    firstDay = goToNextMondayIf(firstDay, [6, 7].includes(firstDay.weekday));
    const endDay = DateTime.fromObject({ year, month }).endOf('month');
    const monthRange = Interval.fromDateTimes(firstDay, endDay);
    const weeks: number[] = [];

    monthRange
      .splitBy({ day: 1 })
      .map((interval: Interval) => interval.start)
      .filter((day): day is DateTime => !!day)
      .forEach((day) => {
        const weekNumber: number = day.weekNumber;
        if (!weeks.includes(weekNumber)) {
          weeks.push(weekNumber);
        }
      });

    const weekRows: {
      classes: string;
      dataWeek: string;
    }[] = [];
    for (let i = 0; i < weeks.length; i++) {
      weekRows.push({
        classes: `week-${i}`,
        dataWeek: i.toString(),
      });
    }

    return (
      <>
        {weekRows.map((row) => (
          <div key={JSON.stringify(row)} className={clsx('week-row w-9 flex justify-end gap-2 flex-col', row.classes)}>
            <div className="empty-header" />
            <JxtShareWorkplaceRange
              text="am"
              onClick={() => {
                callbacks.onJxtShareWorkplaceRangePeriodClick(
                  parseInt(row.dataWeek, 10),
                  DataPeriodEnum.Am,
                  date.startOf('month')
                );
              }}
            />
            <JxtShareWorkplaceRange
              text="pm"
              onClick={() => {
                callbacks.onJxtShareWorkplaceRangePeriodClick(
                  parseInt(row.dataWeek, 10),
                  DataPeriodEnum.Pm,
                  date.startOf('month')
                );
              }}
            />
          </div>
        ))}
      </>
    );
  }, [year, month]);

  const headers = useMemo<ReactElement[]>(() => getHeaders(), [month, year]);
  const dates = useMemo<ReactElement | null>(() => getDates(), [month, year]);
  const periodSelectors = useMemo<ReactElement | null>(() => getPeriodSelectors(), [month, year]);

  return { headers, dates, periodSelectors };
};
