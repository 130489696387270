import { BookingCompressed, ResourceTypeDto } from '@jooxter/api';
import { IFloorPlanBooking } from '@jooxter/ui';
import { useCallback } from 'react';
import { useFetchResourceTypes } from '../../queries';

const DEFAULT_MAP_ICON_SIZE = 1;

export const useBookingCompressedToIFloorPlanBookingAdapter = (bookingsCompressed: BookingCompressed[] = []) => {
  const { resourceTypes } = useFetchResourceTypes();

  const adapt = useCallback((): IFloorPlanBooking[] => {
    if (!resourceTypes) {
      return [];
    }

    return bookingsCompressed.map((bc: BookingCompressed) => ({
      ...bc,
      resource: {
        id: bc.resource.id,
        resourceType: resourceTypes.find((rt: ResourceTypeDto) => rt.id === bc.resource.resourceTypeId) ?? {
          mapIconSize: DEFAULT_MAP_ICON_SIZE,
        },
        state: bc.resource.state,
      },
    }));
  }, [resourceTypes, JSON.stringify(bookingsCompressed)]);

  return { adapt };
};
