import JxtModal from '../JxtModal';
import { IJxtDeleteEventModal } from './types';
import { useTranslation } from 'react-i18next';
import { JxtModalSizeEnum } from '../JxtModal/types';
import JxtButton, { ButtonVariantEnum } from '../JxtButton';

const JxtDeleteEventModal = ({
  show,
  onCancel,
  onSubmit,
  children,
  titleKey,
  showFooter = true,
}: IJxtDeleteEventModal) => {
  const { t } = useTranslation();

  return (
    <JxtModal
      show={show}
      size={JxtModalSizeEnum.S}
      onHide={onCancel}
      header={{
        icon: 'trash',
        title: t(titleKey),
      }}
      footer={
        showFooter ? (
          <>
            <JxtButton onClick={onCancel} variant={ButtonVariantEnum.Secondary}>
              {t('cancel')}
            </JxtButton>
            <JxtButton onClick={onSubmit} variant={ButtonVariantEnum.Danger}>
              {t('delete')}
            </JxtButton>
          </>
        ) : null
      }
    >
      {children}
    </JxtModal>
  );
};

export default JxtDeleteEventModal;
