import { Menu } from '@headlessui/react';
import { IJxtDropdown } from './types';

const JxtDropdown = ({ children, dropdownButton, showChevron = false }: IJxtDropdown) => {
  return (
    <Menu as="li" className="relative lg:inline-block flex-shrink-0 list-none text-left">
      {({ close }) => (
        <>
          <Menu.Button>
            {dropdownButton}
            {showChevron && <i className="fas fa-chevron-down -mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />}
          </Menu.Button>
          <Menu.Items
            as="ul"
            className="w-full overflow-auto lg:w-max lg:absolute right-0 z-50 mt-[-8px] mb-[10px] max-lg:ml-[1px] lg:mt-3 origin-top-right lg:rounded-lg bg-white lg:shadow-elevation-1 lg:ring-1 ring-neutral-10 py-2 focus:outline-none"
          >
            <div onClick={() => close()}>{children}</div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default JxtDropdown;
