// eslint-disable-next-line import/named
import { AxiosHeaders, AxiosResponse } from 'axios';
import { parseLinkHeader } from '@jooxter/utils';

export const onNextPage = async (res: AxiosResponse) => {
  if (res.headers?.get && res.headers instanceof AxiosHeaders) {
    const linkHeader = res.headers.get('Link')?.toString() ?? null;
    const parsed = parseLinkHeader(linkHeader);
    const nextPage = parsed?.next.url ?? '';

    return {
      data: await res.data,
      nextPage,
    };
  }

  return {
    data: await res.data,
    nextPage: '',
  };
};
