import { BookingCompressed } from '@jooxter/api';
// eslint-disable-next-line import/named
import { EventInput, ViewApi } from '@fullcalendar/core';
import { hex2rgba } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

export const useBookingCompressedLogisticTimesAdapter = (currentView?: ViewApi) => {
  const adapt = useCallback(
    (booking: BookingCompressed): EventInput[] => {
      const logisticTimes: EventInput[] = [];

      const color = hex2rgba(booking.color, 0.5);
      const baseLogisticTime = {
        resourceId: booking.resource.id.toString(),
        backgroundColor: color,
        borderColor: color,
        // so that the logistic times get resized/drag with the main event
        editable: true,
        // prevent logistic times from being resized
        startEditable: true,
        durationEditable: false,
        groupId: booking.id.toString(),
        classNames: ['not-allowed'],
      };

      if (!booking.start.buffer) {
        return logisticTimes;
      }

      const diffInMinutes = DateTime.fromISO(booking.start.buffer)
        .diff(DateTime.fromISO(booking.start.dateTime), 'minutes')
        .toObject()?.minutes;
      const isTiny = diffInMinutes && currentView && diffInMinutes <= 5 && currentView.type === 'resourceTimelineDay';

      if (booking.start.buffer && booking.start.dateTime !== booking.start.buffer) {
        logisticTimes.push({
          ...baseLogisticTime,
          id: `start-${booking.id.toString()}`,
          start: booking.start.buffer,
          end: booking.start.dateTime,
          classNames: isTiny ? ['not-allowed', 'tiny-lt-left'] : ['not-allowed'],
        });
      }

      if (booking.end.dateTime !== booking.end.buffer && booking.end.buffer) {
        // handling early checkouts
        const start = booking.checkout?.at || booking.end.dateTime;
        const endDuration = DateTime.fromISO(booking.end.buffer).diff(DateTime.fromISO(booking.end.dateTime));
        const end = DateTime.fromISO(start).plus(endDuration).toISO() ?? undefined;

        logisticTimes.push({
          ...baseLogisticTime,
          id: `end-${booking.id.toString()}`,
          start,
          end,
          classNames: isTiny ? ['not-allowed', 'tiny-lt-right'] : ['not-allowed'],
        });
      }

      return logisticTimes;
    },
    [currentView]
  );

  return { adapt };
};
