import { IMeetUpPlaceholder } from './types';

export const MeetUpPlaceholder = ({ icon, title, description }: IMeetUpPlaceholder) => {
  return (
    <div className="lg:max-w-[500px] flex flex-col px-8 lg:px-4 lg:py-8 text-center mx-auto gap-6">
      <i className={`fas fa-${icon} fa-2x text-neutral-60`} aria-hidden="true" />
      <p className="text-title-xl text-neutral-150 font-semibold">{title}</p>
      <p className="text-title-m text-neutral-100 font-medium">{description}</p>
    </div>
  );
};
