import { now } from '@jooxter/utils';
import { DateTime } from 'luxon';
import { z } from 'zod';

export const startBeforeEndSuperRefinerFactory =
  () =>
  (
    data: { morning: { start: string; end: string }; afternoon: { start: string; end: string } },
    ctx: z.RefinementCtx
  ) => {
    const morning_start = getDateTimeFromJSDateAndStandardTime(data.morning.start);
    const morning_end = getDateTimeFromJSDateAndStandardTime(data.morning.end);
    const afternoon_start = getDateTimeFromJSDateAndStandardTime(data.afternoon.start);
    const afternoon_end = getDateTimeFromJSDateAndStandardTime(data.afternoon.end);

    if (morning_start > morning_end) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'space-search-form.error.to-before-from',
        path: ['morning', 'start'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: ' ',
        path: ['morning', 'end'],
      });
    }
    if (afternoon_start > afternoon_end) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'space-search-form.error.to-before-from',
        path: ['afternoon', 'start'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: ' ',
        path: ['afternoon', 'end'],
      });
    }
    if (morning_end > afternoon_start) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'error.workHours.overlap',
        path: ['afternoon', 'start'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: ' ',
        path: ['morning', 'end'],
      });
    }
  };

export const getDateTimeFromJSDateAndStandardTime = (time: string): DateTime => {
  const dateTime = now();
  const newTime = DateTime.fromFormat(time, 'HH:mm');

  return dateTime.set({ hour: newTime.hour, minute: newTime.minute, second: 0 });
};
