/**
 * Return domain name from location.hostname
 * Useful for cookies domain
 */
export const getDomain = (): string => {
  const parts = location.hostname.split('.');
  if (parts.length > 1) {
    parts.shift();
  }
  return parts.join('.');
};
