import { debounce } from 'lodash-es';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

export const ViewportContext = createContext<{ width: number; height: number }>({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const ViewportProvider = ({ children }: { children?: ReactNode }) => {
  // This is the exact same logic that we previously had in our hook

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    const debouncedHandleWindowResize = debounce(handleWindowResize);

    window.addEventListener('resize', debouncedHandleWindowResize);
    return () => window.removeEventListener('resize', debouncedHandleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
};

const useViewport = () => {
  const { width, height } = useContext(ViewportContext);
  return { width, height };
};

export default useViewport;
